.wrap-input-color{
    position: relative;
    display: flex;

    .triger-overlay{
        width: 25px;
        height: 25px;
        outline: none;
        border: 1px solid #171717;
        border-radius: 50%;
    }

    p-overlayPanel{
        position: absolute;
        z-index: 2;
        margin-left: 5px;
    }
}

.wrap-color-picker{
    display: inline-block;
    background-color: #171717;
    max-width: 180px;
    position: relative;
    left: 62px;

    .overlay-disable{
        background-color: rgba(0, 0, 0, 0.6);
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        z-index: 99;
    }

    .input-panel{
        padding: 5px 5px 10px;
        margin-top: -18px;

        .set-color-name{
            display: flex;
            align-items: center;

            .active-color{
                width: 40px;
                height: 25px;
                display: inline-block;
                margin-right: 6px;
            }

            span{
                margin-bottom: -5px;
                font-size: 14px;
            }
    
            .form-control{
                max-width: 88px;
                height: 25px;
                margin-left: 6px;
                font-size: 14px;
                padding: 6px 5px 2px;
                height: 25px;
                align-items: center;
                display: flex;
                text-transform: uppercase;
    
                &.bg-dark{
                    background-color: #292929 !important;
                    border-color: #292929 !important;
                }
            }
        }

        .line{
            display: block;
            width: 100%;
            border-top: 1px solid #292929;
            margin: 10px 0;
        }

        .wrap-recent-color{
            display: flex;
            flex-wrap: nowrap;
        
            .btn-add-color{
                background-color: transparent;
                border: none;
                padding: 0;
                width: 15px;
                height: 15px;
                display: flex;
                align-items: center;
                justify-content: center;

                
                @-moz-document url-prefix() {
                    padding: 0;
                }
                
                i{
                    font-size: 15px;
                    color: #979797;
                }
                
                &:hover{
                    i{
                        color: #E7E7E7;
                    }
                }

                .hide {
                    display: none;
                }
            }
        
            .recent-item{
                width: 15px;
                height: 15px;
                background-color: aquamarine;
                margin-left: 10px;
                border: 0;
            }
        }
    }
}

.loading-button{
    position: relative;
    
    &::after{
        content: '';
        display: block;
        width: 13px;
        height: 13px;
        position: absolute;
        left: 1px;
        top: 1px;
        border: 2px solid transparent;
        border-right-color: #e7e7e7;
        border-bottom-color: #e7e7e7;
        border-radius: 50%;
        animation: button-anim 0.7s linear infinite;
        opacity: 1;
    }

    @keyframes button-anim {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
}