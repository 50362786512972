import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FontSizeComponent } from './font-size/font-size.component';
import { FontFamilyComponent } from './font-family/font-family.component';
import { SplashScreen } from '@interfaces';
import { _isNumberValue } from '@angular/cdk/coercion';


@Component({
  selector: 'app-font-style',
  standalone: true,
  templateUrl: './font-style.component.html',
  styleUrl: './font-style.component.scss',
  imports: [
    FontSizeComponent,
    FontFamilyComponent
  ],
})
export class FontStyleComponent {
  public fontSize: number;
  public lineHeight: number;
  public fontFamily: string;
  public fontWeight: string;
  private _fontStyle: any = {}

  @Input() splashScreen: SplashScreen;
  @Input() type: string;

  @Output() onChange: EventEmitter<any> = new EventEmitter();


  constructor() {}

  ngOnChanges() {
    switch (this.type) {
      case 'header':
          this.fontSize = this.splashScreen.header_font_size;
          this.lineHeight = this.splashScreen.header_line_height;
          this.fontFamily = this.splashScreen.header_font_family;
          this.fontWeight = this.splashScreen.header_font_weight;
        break;
      case 'body':
          this.fontSize = this.splashScreen.body_font_size;
          this.lineHeight = this.splashScreen.body_line_height;
          this.fontFamily = this.splashScreen.body_font_family;
          this.fontWeight = this.splashScreen.body_font_weight;
        break;
    }
  }

  public editSize(event:any) {
    switch (this.type) {
      case 'header':
        this._fontStyle.header_font_size = event.fontSize;
        this._fontStyle.header_line_height = event.lineHeight;
        break;
      case 'body':
        this._fontStyle.body_font_size = event.fontSize;
        this._fontStyle.body_line_height = event.lineHeight;
        break;
    }
    this.onChange.emit(this._fontStyle);
  }

  public editStyle(event:any) {
    switch (this.type) {
      case 'header':
        this._fontStyle.header_font_family = event.fontFamily;
        this._fontStyle.header_font_weight = event.fontWeight;
        break;
      case 'body':
        this._fontStyle.body_font_family = event.fontFamily;
        this._fontStyle.body_font_weight = event.fontWeight;
        break;
    }
    this.onChange.emit(this._fontStyle);
  }
}
