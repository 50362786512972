@import '../../../../assets/scss/common/variables';

.wrap-virtual-gallery{
    height: var(--vh);
    position: relative;
    display: flex;
    overflow: hidden;

    // ---
    // SIDEBAR
    // ============================
    .sidebar {
        width: 362px;
        min-width: 362px;
        overflow-y: scroll;
        background-color: $color-dark3;
        position: relative;
        height: var(--vh);
        padding: 0 10px;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar{
            display: none;
        }

        &::-webkit-scrollbar{
            width: 0;
        }

        // STYLES MAIN MENU ICON
        // ===================================
        .main-menu-icon{
            display: flex;
            justify-content: space-between;
            padding: 9px 4px 8px;

            .left-menu,
            .right-menu{
                display: flex;
                align-items: center;

                > ul{
                    padding-left: 0;
                    margin-bottom: 0;
                    display: flex;
            
                    .item-menu-icon{
                        list-style: none;
                        display: block;
            
                        &:last-child{
                            margin-right: 0;
                        }
            
                        .item-btn{
                            background-color: transparent;
                            border: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 30px;
                            height: 30px;

                            &:hover{
                                .vi{
                                    color: $color-gray3;
                                }

                                .pi{
                                    color: $color-gray3;
                                }
                            }
                            
                            .vi{
                                font-size: 18px;
                                color: $color-gray1;
                            }

                            .pi{
                                font-size: 18px;
                                color: $color-gray1;
                            }

                            .pi-spin{
                                font-size: 18px;
                                color: $color-gray1;
                                padding: 0 3px;
                            }
                        }
                    }
                }
            }
        }
        
        // TITLE EXHIBITION
        .wrap-title{
            background-color: $color-dark1;
            padding: 10px 13px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title{
                font-size: 19px;
                line-height: 130.3%;
                color: $color-gray3;
                font-family: $font-whyte;
                width: 100%;

                > input{
                    background-color: transparent;
                    border: 0 none;
                    color: $color-gray3;
                    font-size: 19px;
                    line-height: 130.3%;
                    padding: 0 !important;
                    width: 100%;
                    font-family: $font-whyte;

                    &:focus{
                        border: 0 none;
                        outline: none;
                        box-shadow: none;
                    }
                }
            }
        }

        // ---
        // LIST EXHIBITION
        // ================================
        .wrap-list-exhibition{
            padding: 5px 13px 0;

            .list-exhibition{
                padding-left: 0;
                margin-bottom: 0;

                .item-exhibition{
                    list-style: none;
                    padding: 11px 13px 9px;
                    display: flex;
                    align-items: flex-start;
                    position: relative;
                    margin-right: -13px;
                    margin-left: -13px;

                    &:hover{
                        background-color: $color-dark4;
                    }

                    &.active{
                        background-color: $color-gray2;

                        &:hover{
                            background: none;

                            .wrap-exhibition-name{
                                h6{
                                    color: $color-gray3;
                                }

                                span{
                                    color: $color-gray1;
                                }
                            }
                        }

                        .wrap-exhibition-name{
                            h6{
                                color: $color-dark3;
                            }

                            span{
                                color: #565656;
                            }
                        }
                    }

                    .img-check{
                        width: 18px;
                        height: 18px;
                        object-fit: contain;
                        margin-right: 7px;
                        margin-top: 3px;
                    }

                    .wrap-exhibition-name{
                        h6{
                            font-size: 16px;
                            color: $color-gray3;
                            line-height: 109.8%;
                            margin-bottom: 1px;
                            line-height: 109.8%;

                            > i{
                                font-size: 16px;
                                font-weight: 400;
                            }
                        }
                        
                        span{
                            color: $color-gray1;
                            font-size: 12px;
                            line-height: 130.3%;
                            display: table-cell;
                            line-height: 130.3%;
                        }
                    }

                    .link-exhibition{
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                    }
                }
            }   
        }

        // ---
        // MENU
        // =========================
        .menu{
            .nav-tabs{
                border-bottom: solid 2px #000000;
                border-top: solid 1px #000000;
                white-space: nowrap;
                
                .nav-item{
                    &:last-child{
                        border-right: solid 1px #000000;
                    }

                    &.active{
                        border-radius: 0;

                        .nav-link{
                            background-color: $color-dark3;
                            color: $color-gray3;
                        }
                    }

                    .nav-link{
                        padding: 11px 5px 6px;
                        display: block;
                        font-size: 16px;
                        color: $color-gray1;
                        border-radius: 0;
                        text-align: center;
                        background-color: $color-dark1;
                        border: 0;
                        border-left: solid 1px #000000;

                        &:hover{
                            border-left: solid 1px #000000;
                            text-decoration: none;
                        }

                        &:focus{
                            border-left: solid 1px #000000;
                        }
                    }
                }
            }
        }
    }

    // VIRTUAL GALLERY STYLES
    .wrap-canvas{
        width: 100%;
        height: 100vh;
        position: relative;
        flex: 1 1 auto;

        .wrap-log-activity{
            position: absolute;
            right: 0;
            top: 0;
            display: inline-block;
            background-color: rgba(23,23,23,0.7);
            padding: 10px;
            padding-bottom: 7px;
            z-index: 100;

            p{
                font-size: 12px;
                margin-bottom: 5px;
                color: #ffffff;

                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
        
        canvas{
            width: 100%;
            height: 100%;
            background-color: #ffffff;

            &:focus{
                outline: none;
                box-shadow: none;
            }
        }

        .wrap-not-selected{
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top:0px;
            width: 100%;
            height: 100%;
            background-color: #e7e6e6;
            
            h3{
                color: $color-dark3 ;
            }
        }

        .loading-assets{
            position: absolute;
            top: 5px;
            left: 5px;
        }

        .overlay-loading-assets {
            height: 100vh;
            background: red;
            position: absolute;
            top: 0px;
            width: 100%;
            background-color: rgba(21, 21, 21, 0.3);
            display: flex;
            justify-content: center;
            align-items: center;

            .wrap-content {
                display: flex;
                flex-direction: column;
                align-items: center;
                color: #fff;
            }
        }
    }

    .wrap-screen{
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 99;
        width: 100%;
        height: 0;

        .overlay-canvas{
            background-color: rgba(21, 21, 21, 0.7);
            flex: 1 1 auto;
            height: 100vh;
            pointer-events: none;
        }

        .display{
            height: 0;
        }
    }

    .view-screen-menu{
        position: absolute;
        left: 50%;
        bottom: 30px;
        margin-left: -90px;
 
        > ul{
            padding-left: 0;
            margin-bottom: 0;

            .item-menu-screen{
                list-style: none;
                float: left;
                margin-right: 30px;

                .btn-screen{
                    background-color: $color-dark1;
                    border-radius: 50%;
                    border: 0;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &.active{
                        .vi{
                            color: $color-primary;
                        }
                    }

                    .vi {
                        font-size: 22px;
                        color: $color-gray1;
                        font-weight: 600;

                        &.vi-monitor{
                            font-size: 18px;
                            margin-top: 1px;
                        }
                    }
                }
            }
        }
    }
}

.block-content{
    position: fixed;
    width: 100%;
    height: var(--vh);
    top: 0;
    left: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.7);
    display: none;
    z-index: 100;
    cursor: pointer;

    &.on-block-content{
        display: block;
    }
}

.tour{
    position: absolute;

    &.add-artwork {
        top: 175px;
        left: 180px;
    }

    &.tab-menu {
        top: 125px;
        left: 180px;
    }

    &.gallery-name {
        top: 75px;
        left: 180px;
    }
}