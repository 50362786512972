<div class="input-url right">
  <div class="right-content text-center">
      <span class="label">Video:</span>
      <div class="wrap-input-url">
          <input 
            type="text" 
            class="form-control"
            [formControl]="videoUrl"
            placeholder="Enter a Video URL">
          <button 
              (click)="processVideoUrl()"
              [disabled]="videoUrl.invalid || onFetcing" 
              [class.loading]="onFetcing"
              class="btn btn-light btn-sm">
              <p-progressSpinner 
                  *ngIf="onFetcing"
                  strokeWidth="5" 
                  [style]="{width: '25px', height: '25px'}">
              </p-progressSpinner>
              <i *ngIf="!onFetcing" class="vi vi-arrow-right"></i>
          </button>
      </div>
      <span class="label-sub">YouTube</span>
  </div>
</div>