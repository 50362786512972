import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MainService } from '@services';
import { SplashScreenService } from '../splash-screen.service';
import { CommonModule } from '@angular/common';
import { SplashScreen } from '@interfaces';

@Component({
    selector: 'app-splash-screen-preview',
    templateUrl: './splash-screen-preview.component.html',
    styleUrls: ['./splash-screen-preview.component.scss'],
    standalone: true,
    imports: [CommonModule]
})
export class SplashScreenPreviewComponent {

  @Input() headerText: string;
  @Input() bodyText: string;
  @Input() colorTone: string;
  @Input() alignHeader: string;
  @Input() alignBody: string;
  @Input() positionText: string;
  @Input() buttonText: string;
  @Input() mobileView: boolean;
  @Input() backgroundImage: string;
  @Input() zoomValue: number;
  @Input() undoRedoChanges: number;
  @Input() mobileBackgroundPosition: string;

  @Input() splashScreen: SplashScreen;

  @Output() zoomValueChange = new EventEmitter<number>();
  @Output() backgroundPositionChange = new EventEmitter<string>();
  @Output() onUndoRedo = new EventEmitter<string>();  

  @ViewChild('bgMobile') bgMobile: ElementRef;
  @ViewChild('bgDesktop') bgDesktop: ElementRef;
  @ViewChild('wrapperMobile') wrapperMobile: ElementRef;
  @ViewChild('containerHeader') containerHeader: ElementRef;
  @ViewChild('elHeaderDesktop') elHeader: ElementRef;
  @ViewChild('elHeaderDesktop3') elHeader3: ElementRef;
  @ViewChild('elHeaderMobile') elHeaderMobile: ElementRef;

  private _disableSelection: HTMLStyleElement;

  constructor(
    public mainService: MainService,
    private _splashScreenService: SplashScreenService
  ) {}

  ngOnInit() {
    if (this.mainService.isBrowser) {
      this._disableSelection = document.createElement('style');
      this._disableSelection.textContent = '* { user-select: none; }';
      this.changeViewerTheme();
      this.setBackgroundImage();
      this._setupFontStyleHeader();
      this._setupFontStyleDesc();
      this.handleBlinkingText();

      window.addEventListener('mouseup', this.mouseUp);
      window.addEventListener('mousemove', this.mouseMove);
    }
  }

  ngOnChanges(changes: any) {
    if (changes.backgroundImage) this.setBackgroundImage();
    if (changes.zoomValue) this.setSliderBackgroundSize(changes.zoomValue.currentValue);
    if (changes.colorTone) { this.changeViewerTheme(); this.setBackgroundImage(); }
    if (changes.mobileView) {
      this.setBackgroundImage();
      this.changeViewerTheme();
    }
    if (changes.mobileBackgroundPosition && changes.undoRedoChanges) this.setBackgroundImage();

    this._setupFontStyleHeader();
    this._setupFontStyleDesc();
    this.handleBlinkingText();
    if (!changes.undoRedoChanges) this.updateUndoRedoState();
  }

  ngOnDestroy() {
    window.removeEventListener('mouseup', this.mouseUp);
    window.removeEventListener('mousemove', this.mouseMove);
  }

  private mouseUp = (event) => {
    if (this.keyDown)  this.setBackgroundPositionMobile('up', event);
  }

  private mouseMove = (event) => {
    if (this.keyDown) this.setBackgroundPositionMobile('move', event)
  }

  private changeViewerTheme() {
    setTimeout(() => {
      const text = document.querySelectorAll('.splash-text');
      if (this.colorTone === 'dark') {
        text.forEach((el: HTMLElement) => {
          el.classList.remove('light');
          el.classList.add('dark');
        });
      } else {
        text.forEach((el: HTMLElement) => {
          el.classList.remove('dark');
          el.classList.add('light');
        });
      }
    }, 100);
  }

  /**
   * ANCHOR SET BACKGROUND IMAGE
   * Todo: to set background image
   */
  private setBackgroundImage() {
    setTimeout(() => {
      this.setSliderBackgroundSize(this.zoomValue);
      const background = this.colorTone === 'dark' ? '#171717':'#F7F7F7';
      const overlayEl = document.querySelector('.overlay') as HTMLElement;
      const overlayBlock = document.querySelectorAll('.overlay-block');
      
      
      if (this.mobileView) {
        if (this.backgroundImage) {
          this.bgMobile.nativeElement.style.backgroundImage = `url(${this.backgroundImage})`;
          this.bgMobile.nativeElement.style.backgroundPosition = this.mobileBackgroundPosition ? this.mobileBackgroundPosition:'auto 100%';
          this.wrapperMobile.nativeElement.style.backgroundImage = `url(${this.backgroundImage})`;
          overlayEl.style.background = `linear-gradient(180deg, ${this.colorTone==='dark'?'#000':'#F8F8F8'} 0.13%, ${this.colorTone==='dark'?'rgba(41, 41, 41, 0.00)':'rgba(255, 255, 255, 0.00)'} 99.76%)`
        } else {
          overlayBlock.forEach((el:HTMLElement) => {
            el.style.background = '#000'
          })  
          overlayEl.style.background = background;
          this.bgMobile.nativeElement.style.background = background;
          this.wrapperMobile.nativeElement.style.background = '#000';
        }
      } else {
        if (this.backgroundImage) {
          this.bgDesktop.nativeElement.style.backgroundImage = `url(${this.backgroundImage})`;
          this.bgDesktop.nativeElement.style.backgroundPosition = 'center';
        } else {
          this.bgDesktop.nativeElement.style.background = background;
        }
      }
    }, 100);
  }

  /**
   * ANCHOR SET BACKGROUND SIZE
   */
  private setSliderBackgroundSize(value: number) {
    if (value <= 0) value = 0;
    if (value >= 100) value = 100;
    if (this.mobileView && this.bgMobile) {
      this.bgMobile.nativeElement.style.backgroundSize = `auto ${100 + value*2.5}%`;
      this.zoomValueChange.emit(value);
    } else if (this.bgDesktop) {
      this.bgDesktop.nativeElement.style.backgroundSize = `auto ${100 + value*1.5}%`;
      this.zoomValueChange.emit(value);
    }
  }

  /**
   * ANCHOR SET BACKGROUND POSITION
   */
  private clientX = 0;
  private clientY = 0;
  private positionX = 50;
  private positionY = 50;
  private keyDown = false;
  public setBackgroundPositionMobile(action:'up' | 'down' | 'move', event:any) {
    const overlay = document.querySelector('.content-device') as HTMLElement;
    let bgPosX = 0;
    let bgPosY = 0;

    if (action === 'down') {
      overlay.style.cursor = 'grabbing';

      const style = getComputedStyle(this.bgMobile.nativeElement);
      const bgPosX = style.getPropertyValue('background-position-x');
      const bgPosY = style.getPropertyValue('background-position-y');
      bgPosX.replace('%', '');
      bgPosY.replace('%', '');
      this.positionX = parseInt(bgPosX);
      this.positionY = parseInt(bgPosY);

      document.head.appendChild(this._disableSelection);

      this.clientX = event.clientX;
      this.clientY = event.clientY;
      this.keyDown = true;
    }

    if (this.keyDown && action === 'move') {
      bgPosX = this.positionX + ((this.clientX - event.clientX) / 640 * 48);
      bgPosY = this.positionY + ((this.clientY - event.clientY) / 320 * 48);

      if (bgPosX > 100) bgPosX = 100;
      else if (bgPosX < 0) bgPosX = 0;
      if (bgPosY > 100) bgPosY = 100;
      else if (bgPosY < 0) bgPosY = 0;

      this.bgMobile.nativeElement.style.backgroundPosition = `${bgPosX}% ${bgPosY}%`;
      this.backgroundPositionChange.emit(`${bgPosX.toFixed(2)}% ${bgPosY.toFixed(2)}%`);
    }

    if (action === 'up') {
      overlay.style.cursor = 'grab';
      if (this.keyDown) document.head.removeChild(this._disableSelection);

      this.clientY = 0;
      this.clientX = 0;
      this.keyDown = false;
    }
  }

  /**
   * ANCHOR DEBOUNCE NATIVE FUNCTION
   * @param func function to debounce
   * @param delay debounce delay
   */
  private _debounce(func: () => void, delay: number): () => void {
    let timeoutId: ReturnType<typeof setTimeout>;

    return function() {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func();
      }, delay);
    };
  }

  /**
	 * ANCHOR Update Undo Redo State
	 * @description : to update undo redo state
	 */
	private updateUndoRedoState = this._debounce(() => {
    this._splashScreenService.undoRedoStates = [...this._splashScreenService.undoRedoStates.slice(0, this._splashScreenService.undoRedoIndex + 1), {
			headerText: this.headerText,
      bodyText: this.bodyText,
      colorTone: this.colorTone,
      alignHeader: this.alignHeader,
      alignBody: this.alignBody,
      textPlacement: this.positionText.toString(),
      buttonText: this.buttonText,
      backgroundImage: this.backgroundImage,
      backgroundPosition: this.mobileBackgroundPosition,
      mobileView: this.mobileView,
      zoomValue: this.zoomValue,
    }];
    this._splashScreenService.undoRedoIndex = this._splashScreenService.undoRedoStates.length-1;
	}, 1000);

  public undoRedo(action: 'undo' | 'redo') {
    if (action === 'undo') {
      if (this._splashScreenService.undoRedoIndex > 0) {
        this._splashScreenService.undoRedoIndex--;
        const state = this._splashScreenService.undoRedoStates[this._splashScreenService.undoRedoIndex];
        this.onUndoRedo.emit(state);
      }
    } else if (action === 'redo') {
      if (this._splashScreenService.undoRedoIndex < this._splashScreenService.undoRedoStates.length) {
        this._splashScreenService.undoRedoIndex++;
        const state = this._splashScreenService.undoRedoStates[this._splashScreenService.undoRedoIndex];
        this.onUndoRedo.emit(state);
      }
    }
  }

  public clearUndoRedo() {
    this._splashScreenService.undoRedoStates = [];
    this._splashScreenService.undoRedoIndex = 0;
    this.updateUndoRedoState();
  }
  private _adjustHeaderFontSize() {
    if (this.mainService.isBrowser) {
      let header, textLength;
      setTimeout(() => {
        if (this.mobileView) {
          header = this.elHeaderMobile.nativeElement;
          textLength = header.textContent.length;
        } else {
          header = this.elHeader?.nativeElement || this.elHeader3?.nativeElement;
          textLength = header.textContent.length;
        }

        if (textLength <= 40) {
          header.style.fontSize = this.mobileView?`${26/2}px`:`${70/4}px`;
          header.style.lineHeight = this.mobileView?`${28/2}px`:`${74/4}px`;
        }

        if (textLength > 40 && textLength <= 60) {
          header.style.fontSize = this.mobileView?`${24/2}px`:`${54/4}px`;
          header.style.lineHeight = this.mobileView?`${26/2}px`:`${57/4}px`;
        }

        if (textLength > 60 && textLength <= 80) {
          header.style.fontSize = this.mobileView?`${22/2}px`:`${42/4}px`;
          header.style.lineHeight = this.mobileView?`${24/2}px`:`${44/4}px`;
        }

        if (textLength > 80 && textLength <= 110) {
          header.style.fontSize = this.mobileView?`${19/2}px`:`${38/4}px`;
          header.style.lineHeight = this.mobileView?`${22/2}px`:`${40/4}px`;
        }

        if (textLength > 110) {
          header.style.fontSize = this.mobileView?`${16/2}px`:`${33/4}px`;
          header.style.lineHeight = this.mobileView?`${18/2}px`:`${34/4}px`;
          }
      }, 100);
    }
  }

  /**
   * ANCHOR HANDLE BLINKING TEXT
   * @description : to handle blinking text
   */
  handleBlinkingText() {
    if (this.mainService.isBrowser) {
      setTimeout(() => {
        const textBody = document.querySelectorAll('.splash-text');
        const wrapContent = document.querySelector('.center.pos') as HTMLElement;

        if (wrapContent) wrapContent.style.visibility = 'visible';
        textBody.forEach((el: HTMLElement) => {
          el.style.visibility = 'visible';
        });

        this._setupFontStyleHeader();
        this._setupFontStyleDesc();
        this._setButtonPosition();
      }, 200);
    }
  }

  /**
   * ANCHOR SETUP FONT HEADER
   * @description : to setup font style header
   */
  private _setupFontStyleHeader() {
		if (this.mainService.isBrowser) {
			const textHeader = document.querySelectorAll('.header.splash-text') as NodeListOf<HTMLElement>;
			this._fontStyle(textHeader, 'header');
		}
	}

  /**
   * ANCHOR SETUP FONT DESC
   * @description : to setup font style desc
   */
	private _setupFontStyleDesc() {
		if (this.mainService.isBrowser) {
			const textDesc = document.querySelectorAll('.body.splash-text') as NodeListOf<HTMLElement>;
			this._fontStyle(textDesc, 'body');
		}
	}

  /**
   * ANCHOR SETUP FONT STYLE
   * @description : to setup font style
   */
  private _fontStyle(element:NodeListOf<HTMLElement>, type:string) {
		element.forEach((el: HTMLElement) => {
			el.style.fontSize = this.splashScreen[type + '_font_size'] / 4 + 'px';
			el.style.lineHeight = this.splashScreen[type + '_line_height'] / 4 + 'px';
			el.style.fontFamily = this.splashScreen[type + '_font_family'].value;
			switch (this.splashScreen[type + '_font_weight'].name) {
				case 'Regular':
					el.style.fontWeight = '400';
					el.style.fontStyle = 'normal';
					break;
				case 'Bold':
					el.style.fontWeight = '700';
					el.style.fontStyle = 'normal';
					break;
				case 'Italic':
					el.style.fontWeight = '400';
					el.style.fontStyle = 'italic';
					break;
				case 'Bold Italic':
					el.style.fontWeight = '700';
					el.style.fontStyle = 'italic';
					break;
			}
		})
	}

  /**
   * ANCHOR SET BUTTON POSITION
   * @description : to set button position
   */
  private _setButtonPosition() {
    if (this.positionText == '3') return;
			let topDesc:number;
			let topButton:number;
			let bottomDesc:number;
			let bottomButton:number;

      const textDesc = document.querySelector('.item-desc') as HTMLElement;
      const button = document.querySelector('.btn.not-center') as HTMLElement;
			
      if (!textDesc) return;
      if (!button) return;

      // initial value
      button.style.top = '50%';
      button.style.bottom = '50%';
      topDesc = bottomDesc = textDesc.offsetHeight + 80/4;

      const onTop = this.positionText=='1'||this.positionText=='4';
      const onBottom = this.positionText=='2'||this.positionText=='5';

      // set default value
      if (button.style.top == '50%' || button.style.top == 'auto' && onTop) {
        topButton = 162;
      } else {
        topButton = Number(button.style.top.slice(0,button.style.top.length-2));
      }

      if (button.style.bottom == '50%' || button.style.bottom == 'auto' && onBottom) {
        bottomButton = 176;
      } else {
        bottomButton = Number(button.style.bottom.slice(0,button.style.bottom.length-2));
      }

      // set position button
      if (topDesc > topButton && onTop) {
        console.log('EXECUTE TOP', topButton, topDesc);
        button.style.top = String(topDesc+40/4)+'px';
        button.style.bottom = 'auto';
        button.style.translate = '-50% 0%';
      }

      if (bottomDesc > bottomButton && onBottom) {
        console.log('EXECUTE BOTTOM', bottomButton, bottomDesc);
        button.style.top = 'auto';
        button.style.bottom = String(bottomDesc+40/4)+'px';
        button.style.translate = '-50% 0%';
      }
	}
}
