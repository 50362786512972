<div class="wrap-login">

    <form name="form" [formGroup]="form">

        <!--  -->
        <div class="form-group">
            <input 
                type="text" 
                class="form-control lg bg-gray" 
                placeholder="Username/Email"
                [ngClass]="{'is-invalid':form.controls.email_username.invalid&&form.controls.email_username.touched}"
                formControlName="email_username"
                [disabled]="loading" />

            <!-- FEEDBACK EMAIL INVALID -->
            <div class="invalid-feedback" *ngIf="form.controls.email_username.invalid&&form.controls.email_username.touched">
                <ng-container *ngIf="form.controls.email_username.errors.required">
                    Email or Username required
                </ng-container>
            </div>
        </div>

        <!-- PASSWORD -->
        <div class="form-group">
            <div class="d-flex align-items-center position-relative" [ngClass]="{'is-invalid':form.controls.password.invalid&&form.controls.password.touched}">
                <input 
                    [type]="showPassword?'text':'password'" 
                    class="form-control lg bg-gray"
                    [style]="{'padding-right': '52px'}"
                    placeholder="Password"
                    [ngClass]="{'is-invalid':form.controls.password.invalid&&form.controls.password.touched}"
                    formControlName="password"
                    [disabled]="loading" />

                <!-- IMAGE SHOW PASSWORD -->
                <div class="field-icon" (click)="showPassword = !showPassword">
                    <i class="vi vi-eye" *ngIf="showPassword"></i>
                    <i class="vi vi-eye-closed" *ngIf="!showPassword"></i>
                </div>
            </div>

            <!-- FEEDBACK PASSWORD INVALID -->
            <div class="invalid-feedback" *ngIf="form.controls.password.invalid&&form.controls.password.touched">
                <ng-container *ngIf="form.controls.password.value.length == 0">
                    Password required
                </ng-container>
                <ng-container *ngIf="form.controls.password.value.length > 0">
                    Password must be at least 6 characters
                </ng-container>
            </div>

        </div>

        <a *ngIf="!mainService.loginForExpiredToken"  href="javascript:;" class="forgot-password" [style.color]="'#171717'" (click)="forgotPassword()">Forgot your password?</a>

        <!-- ACTOPN BUTTON -->
        <button class="btn btn-primary mt20" (click)="login()" [class.loading-button-primary]="loading" [disabled]="form.invalid || loading">
            Continue <i class="vi vi-arrow-right"></i>
        </button>
    </form>
</div>