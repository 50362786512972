import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgIf, NgStyle } from '@angular/common';

@Component({
    selector: 'input-number',
    template: `
		<div class="wrap-input" [class.wrap-input-button]="button">
			<input
				#inputNumber
				type="text"
				[class]="styleInput"
				[ngStyle]="{'height.px': height ? height : 25}"
				[formControl]="number"
				(keyup)="formatValue()"
				(focus)="onFocus.emit(true)"
				(blur)="onBlur.emit(false);this.setNumberValue(this.value);"
				(keypress)="blockChars($event)"
				(change)="onChangeHandler(); inputNumber.blur(); inputNumber.focus();"
				[maxlength]="maxLength ? maxLength:''"/>
			<div *ngIf="button" class="wrap-button">
				<button 
					class="button"
					[ngStyle]="{'height.px': height ? height/2 : 12.5}"
					(mousedown)="buttonAction('up',true)"
					(mouseup)="buttonAction('up', false)" 
					(mouseleave)="buttonAction('up',false)">
					<i class="arrow arrow-up"></i>
				</button>
				<button 
					class="button"
					[ngStyle]="{'height.px': height ? height/2 : 12.5}"
					(mousedown)="buttonAction('down',true)"
					(mouseup)="buttonAction('down', false)" 
					(mouseleave)="buttonAction('down',false)">
					<i class="arrow arrow-down"></i>
				</button>
			</div>
		</div>
	`,
    styleUrls: ['./input-number.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, NgStyle]
})
export class InputNumberComponent implements OnInit {

	public number = new FormControl();
	private autochange: any;
	private autoChangeDelay: any;

	@Input() value: number = 0;
	@Input() min: number = 0;
	@Input() max: number = 0;
	@Input() disabled: boolean = false;
	@Input() maxLength: number = 0;
	@Input() button: boolean = false;
	@Input() styleInput: string = 'editor-input';
	@Input() height: number = 0;
  
	@Output() onChange = new EventEmitter();
	@Output() onBlur = new EventEmitter();
	@Output() onFocus = new EventEmitter();
  
	constructor() {}
  
	ngOnInit() {
		this.setNumberValue(this.value);
		if(this.disabled) this.number.disable();
		else this.number.enable()

	}

	ngOnChanges(changes: SimpleChanges) {
		const value = changes?.value?.currentValue
		if (value == 0 || value) this.setNumberValue(value);
		if(this.disabled) this.number.disable();
		else this.number.enable()
	}
	
	setNumberValue(value){
		this.number.setValue(String(value));
	}

	formatValue() {
		let hasMinus = this.number.value.includes('-');
		let tmpValue = this.number.value.replace(/-/g, '').replace(/,/g, '');
		let newValue = '';
		
		if (tmpValue) newValue = isNaN(Number(tmpValue)) ? '' : Number(tmpValue).toLocaleString('en-US');
		else newValue = '';
	
		if (hasMinus) newValue = '-' + newValue;
	
		this.setNumberValue(newValue);
	}
  
	onChangeHandler() {
    const newValue = this.getValue();
		this.onChange.emit(newValue);
		this.setNumberValue(newValue);
	}

	getValue() {
		let tmpValue = this.number.value.replace(/,/g, '');
    let newValue = 0;
		if (tmpValue && tmpValue != "-") {
			newValue = Number(tmpValue);
			if (newValue < this.min) newValue = this.min;
			if (newValue > this.max) newValue = this.max;
		} else {
      newValue = this.min;
		}

		if (Number.isNaN(newValue)) newValue = this.min;
		return newValue;
	}

	buttonAction(action, condition){
		if (condition) {
			switch (action) {
				case "up":
					this.value++;
					this.setNumberValue(this.value);
					this.onChangeHandler();
					this.autoChangeDelay = setTimeout(() => {
						this.autochange = setInterval(()=>{
							this.value++;
							this.setNumberValue(this.value);
							this.onChangeHandler();
						}, 50)
					}, 500);
					break;
					
				case "down":
					this.value--;
					this.setNumberValue(this.value);
					this.onChangeHandler();
					this.autoChangeDelay = setTimeout(() => {
						this.autochange = setInterval(()=>{
							this.value--;
							this.setNumberValue(this.value);
							this.onChangeHandler();
						}, 50)
					}, 500);
					break;
			}
		} else {
			clearInterval(this.autochange);
			clearTimeout(this.autoChangeDelay);
		}
	}
  
	blockChars(e) {
		const allowedKeyPress = ["Enter","1","2","3","4","5","6","7","8","9","0","-",","];
		return allowedKeyPress.includes(e.key)
	}

}
