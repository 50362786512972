<footer class="footer">
    <div *ngIf="!whiteLabelService.whiteLabel" class="content-footer" #contentHeight>
        <div class="container-fluid">
            <div class="row">

                <!-- WIDGET
                ======================================= -->
                <div class="col col16-3 col-iped-2 offset16-5 offset-iped-0 col-mobile-2 order-1 pl-0 set-width-responsive-menu-1">
                    <div class="widget menu-footer">
                        <ul>
                            <ng-container *ngIf="!whiteLabelService.whiteLabel">
                                <li>
                                    <a href="javascript:;" routerLink="/pricing" (click)="mainService.closeHeaderMessage()" routerLinkActive="active">Pricing</a>
                                </li>
                                <li>
                                    <a href="javascript:;" routerLink="/catalogue" (click)="mainService.closeHeaderMessage()" routerLinkActive="active">Galleries</a>
                                </li>
                                <li>
                                    <a href="javascript:;" routerLink="/tutorials" (click)="mainService.closeHeaderMessage()" routerLinkActive="active">Tutorials</a>
                                </li>
                            </ng-container>
                            <!-- white label -->
                            <ng-container *ngIf="whiteLabelService.whiteLabel">
                                <li>
                                    <a [href]="environment.base_host+'/pricing'">Pricing</a>
                                </li>
                                <li>
                                    <a [href]="environment.base_host+'/catalogue'">Galleries</a>
                                </li>
                                <li>
                                    <a [href]="environment.base_host+'/tutorials'">Tutorials</a>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </div>

                <!-- WIDGET
                ======================================= -->
                <div class="col col16-3 col-iped-2 col-mobile-2 order-2 set-width-responsive-menu-2">
                    <div class="widget">
                        <ul>
                            <ng-container *ngIf="!whiteLabelService.whiteLabel">
                                <li>
                                    <a href="javascript:;" routerLink="/about" (click)="mainService.closeHeaderMessage()" routerLinkActive="active" [translate]="'t_footer.t_widget_1.t_link_about'">About Us</a>
                                </li>
                                <li>
                                    <a href="javascript:;" routerLink="/careers" (click)="mainService.closeHeaderMessage()" routerLinkActive="active" [translate]="'t_footer.t_widget_1.t_link_career'">Careers</a>
                                </li>
                                <li>
                                    <a href="javascript:;" routerLink="/contact" (click)="mainService.closeHeaderMessage()" routerLinkActive="active" [translate]="'t_footer.t_widget_1.t_link_contat'">Contact Us</a>
                                </li>
                            </ng-container>
                            <!-- white label -->
                            <ng-container *ngIf="whiteLabelService.whiteLabel">
                                <li>
                                    <a [href]="environment.base_host+'/about'" [translate]="'t_footer.t_widget_1.t_link_about'">About Us</a>
                                </li>
                                <li>
                                    <a [href]="environment.base_host+'/careers'" [translate]="'t_footer.t_widget_1.t_link_career'">Careers</a>
                                </li>
                                <li>
                                    <a [href]="environment.base_host+'/contact'" [translate]="'t_footer.t_widget_1.t_link_contat'">Contact Us</a>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                    <div class="widget widget-off-responsive">
                        <ul>
                            <ng-container *ngIf="!whiteLabelService.whiteLabel">
                                <li class="item-menu-info">
                                    <a href="javascript:;" routerLink="/term-of-service" (click)="mainService.closeHeaderMessage()" routerLinkActive="active" [translate]="'t_footer.t_widget_2.t_link_term'">Terms of Service</a>
                                </li>
                                <li class="item-menu-info">
                                    <a href="javascript:;" routerLink="/privacy-cookie-policy" (click)="mainService.closeHeaderMessage()" routerLinkActive="active" [translate]="'t_footer.t_widget_2.t_link_privacy'">Privacy & Cookie Policy</a>
                                </li>
                            </ng-container>
                            <ng-container *ngIf="whiteLabelService.whiteLabel">
                                <li class="item-menu-info">
                                    <a [href]="environment.base_host+'/term-of-service'" [translate]="'t_footer.t_widget_2.t_link_term'">Terms of Service</a>
                                </li>
                                <li class="item-menu-info">
                                    <a [href]="environment.base_host+'/privacy-cookie-policy'" [translate]="'t_footer.t_widget_2.t_link_privacy'">Privacy & Cookie Policy</a>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </div>
                <div class="col col-iped-2 widget-on-responsive order-3">
                    <div class="widget">
                        <ul>
                            <ng-container *ngIf="!whiteLabelService.whiteLabel">
                                <li>
                                    <a href="javascript:;" routerLink="/term-of-service" (click)="mainService.closeHeaderMessage()" routerLinkActive="active" [translate]="'t_footer.t_widget_2.t_link_term'">Terms of Service</a>
                                </li>
                                <li>
                                    <a href="javascript:;" routerLink="/privacy-cookie-policy" (click)="mainService.closeHeaderMessage()" routerLinkActive="active" [translate]="'t_footer.t_widget_2.t_link_privacy'">Privacy & Cookie Policy</a>
                                </li>
                            </ng-container>
                            <ng-container *ngIf="whiteLabelService.whiteLabel">
                                <li>
                                    <a [href]="environment.base_host+'/term-of-service'" [translate]="'t_footer.t_widget_2.t_link_term'">Terms of Service</a>
                                </li>
                                <li>
                                    <a [href]="environment.base_host+'/privacy-cookie-policy'" [translate]="'t_footer.t_widget_2.t_link_privacy'">Privacy & Cookie Policy</a>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </div>
                <div class="col col16-4 col-iped-4 col-mobile-4 order-4 set-width-responsive-desc">
                    <div class="desc">
                        <span [translate]="'t_footer.t_widget_3.t_contant_us_1'">
                            We will be happy to hear your feedback.
                        </span>
                        <span [translate]="'t_footer.t_widget_3.t_contant_us_2'">
                            Don’t hesitate to send us your ideas
                        </span>
                        <span [translate]="'t_footer.t_widget_3.t_contant_us_3'">
                            and comments — <a href="mailto:admin@villume.com">info&#64;villume.com</a>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="sub-footer" [class.white]="whiteLabelService.whiteLabel">
        <p>&copy; 2024 All Rights Reserved.</p>
    </div>
</footer>