import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { MainService } from '@services';
import { LayoutsComponent } from 'src/app/layouts/layouts.component';

@Component({
  selector: 'app-payment-success',
  standalone: true,
  templateUrl: './payment-success.component.html',
  styleUrl: './payment-success.component.scss',
  imports: [
    LayoutsComponent,
    RouterLink
  ],
})
export class PaymentSuccessComponent {

  constructor(public mainService:MainService) { }
}
