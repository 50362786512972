<div class="general">
    <!-- ICON FEATURE
    ==================================== -->
    <div class="general__feature">
        <ul class="general__list">
            <li class="general__item" pTooltip="Field of View" tooltipPosition="top" placeholder="top">
                <button 
                    class="general__btn" (click)="editorService.showFieldOfView=true">
                    <i class="vi vi-fov general__icon" style="font-size: 22px;"></i>
                    <span>Field of View</span>
                </button>
            </li>
            <li class="general__item" pTooltip="Horizontal View Movement" tooltipPosition="top" placeholder="top">
                <button 
                    href="javascript:;"
                    [disabled]="editorService.blockUserAction"
                    [class.disable-icon]="editorService.blockUserAction"
                    (click)="setHorizontalCameraMovementObs()"
                    class="general__btn">
                    <app-icon 
                        name="axis-position" 
                        className="general__axis" 
                        size="24"
                        [class.general__axis--xy]="!editorService.exhibition.horizontal_view_movement">
                    </app-icon>
                    <span>{{ editorService.exhibition.horizontal_view_movement ? "Horizontal" : "Free" }} View Movement</span>
                </button>
            </li>
            <li class="general__item"  [class.disable-icon]="editorService.blockUserAction" pTooltip="Set Start Position" tooltipPosition="top" placeholder="top">
                <button 
                    href="javascript:;"
                    [disabled]="editorService.blockUserAction"
                    [class.disable-icon]="editorService.blockUserAction"
                    (click)="setDefaultCameraPositon()" 
                    class="general__btn">
                    <i class="vi vi-maps general__icon"></i>
                    <span>Set Start Position</span>
                </button>
            </li>
            <li class="general__item" pTooltip="Take Screenshot" tooltipPosition="top" placeholder="top"> 
                <button 
                    href="javascript:;"
                    (click)="takeScreenshot()" 
                    class="general__btn"
                    [class.disable-icon]="editorService.blockUserAction"
                    [disabled]="editorService.blockUserAction">
                    <i class="vi vi-camera general__icon" *ngIf="!takingScreenshot"></i>
                    <i *ngIf="takingScreenshot" class="pi pi-spin pi-spinner general__icon" style="font-size: 18px"></i>
                    <span>Take a Screenshot</span>
                </button>
            </li>
        </ul>
    </div>

    <div class="content-settings">
        <!-- Start Gallery Adjusment -->
        <div class="adjusment">
            <h3 class="adjusment__title">Gallery Adjusments</h3>
            <!-- SETTINGS EXHIBITION SELECTION
            ==================================== -->
            <app-exhibition-selection
                [modelType]="editorService.activeExhibitionNode.modelType">
            </app-exhibition-selection>

            <!-- SETTINGS COLOR
            ==================================== -->
            <ng-container *ngIf="editorService.activeExhibitionNode?.color_config?.length">
                <div *ngFor="let color of editorService.activeExhibitionNode.color_config" class="form-group side-by-side mb20">
                    <input-color
                        pTooltip="{{color.label}}" 
                        tooltipPosition="bottom" 
                        placeholder="Bottom" 
                        (onChange)="color.color=$event;changeColor(color)"
                        (onHide)="hideColorOverlay = true;mainService.userInfo.user_details[0].fav_colors=$event"
                        (onShow)="hideColorOverlay = false"
                        [hideColorOverlay]="hideColorOverlay"
                        [inline]="false"
                        [disabled]="editorService.blockUserAction"
                        [color]="color.color"
                        [favoriteColor]="mainService.userInfo.user_details[0].fav_colors">
                    </input-color>
                    <label class="adjusment__label mt5">{{color.label}}</label>
                </div>
            </ng-container>
        </div>
        <!-- End Gallery Adjusment -->

        <!-- SETTINGS LIGHT GALLERY INTENSITY
        ==================================== -->
        <div class="form-group content-slide mb40">
            <div class="title-content">
                <label>Light Intensity Gallery</label>
                <input-number 
                    [value]="lightValue"
                    (onChange)="updateGalleryLighting($event)"
                    [disabled]="editorService.blockUserAction"
                    [min]="1"
                    [max]="100">
                </input-number>
            </div>
            <p-slider 
                (dblclick)="resetGalleryLighting()"
                [(ngModel)]="lightValue"
                (onChange)="updateGalleryLighting(lightValue);"
                [min]="1"
                [max]="100"
                [step]="1" 
                [disabled]="editorService.blockUserAction"
                [styleClass]="editorService.blockUserAction ? 'disable-slider ml15' : 'ml15'">
            </p-slider>
        </div>
        
        <!-- SETTINGS LIGHT OBJECT INTENSITY
        ==================================== -->
        <div class="form-group content-slide mb40">
            <div class="title-content">
                <label>Light Intensity 3D-Objects</label>
                <input-number l
                    [value]="lightValueObjects"
                    (onChange)="updateObjectLighting($event)"
                    [disabled]="editorService.blockUserAction"
                    [min]="1"
                    [max]="80">
                </input-number>
            </div>
            <p-slider 
                (dblclick)="resetObjectLighting()"
                [(ngModel)]="lightValueObjects"
                (onChange)="updateObjectLighting(lightValueObjects);"
                [min]="1"
                [max]="80"
                [step]="1" 
                [disabled]="editorService.blockUserAction"
                [styleClass]="editorService.blockUserAction ? 'disable-slider ml15' : 'ml15'">
            </p-slider>
        </div>

        <!-- SETTINGS CAMERA HEIGHT
        ==================================== -->
        <div class="form-group content-slide mb30">
            <div class="title-content">
                <label>Camera Height</label>
                <input-number 
                    [value]="cameraHighValue"
                    (onChange)="updateCameraHigh($event)"
                    [disabled]="editorService.blockUserAction"
                    [min]="50"
                    [max]="200">
                </input-number>
            </div>
            <p-slider 
                (dblclick)="resetCameraHigh()"
                [(ngModel)]="cameraHighValue"
                (onChange)="updateCameraHigh(cameraHighValue);"
                [disabled]="editorService.blockUserAction"
                [styleClass]="editorService.blockUserAction ? 'disable-slider ml15' : 'ml15'"
                [step]="1" 
                [min]="50" 
                [max]="200">
            </p-slider>
        </div>

        <div class="outline-bottom"></div>

        <!-- SHOW/HIDE ADVANCE SETTING
        ==================================== -->
        <div class="form-group side-by-side mb20">
            <p-inputSwitch 
                (onChange)="undoRedoService.removeUndoRedoInputEvent()"
                [(ngModel)]="editorService.advanceSettingSetting" 
                styleClass="switch-lg">
            </p-inputSwitch>
            <label [style.color]="editorService.advanceSettingSetting ? '#e7e7e7' : '#979797'" class="ml10">Advanced Settings</label>
        </div>

        <ng-container *ngIf="editorService.advanceSettingSetting">
            <!-- SETTINGS MOTION SPEED OF CAMERA
            ==================================== -->
            <div class="form-group content-slide mb30">
                <div class="title-content">
                    <label>Camera Speed</label>
                    <input-number 
                        [value]="moveSpeedValue"
                        (onChange)="updateMovementSpeed($event)"
                        [disabled]="editorService.blockUserAction"
                        [min]="1"
                        [max]="10">
                    </input-number>
                </div>
                <p-slider 
                    (dblclick)="resetMovementSpeed()"
                    [(ngModel)]="moveSpeedValue"
                    (onChange)="updateMovementSpeed(moveSpeedValue);"
                    [disabled]="editorService.blockUserAction" 
                    [styleClass]="editorService.blockUserAction? 'disable-slider ml15' : 'ml15'"
                    [step]="1" 
                    [min]="1" 
                    [max]="10">
                </p-slider>
            </div>

            <!-- SETTINGS SPEED CAMERA ROTATION
            ==================================== -->
            <div class="form-group content-slide mb30">
                <div class="title-content">
                    <label>Camera Rotation Speed</label>
                    <input-number 
                        [value]="rotateSpeedValue"
                        (onChange)="updateRotationSpeed($event)"
                        [disabled]="editorService.blockUserAction"
                        [min]="1"
                        [max]="10">
                    </input-number>
                </div>
                <p-slider 
                    (dblclick)="resetRotationSpeed()"
                    [(ngModel)]="rotateSpeedValue"
                    [step]="1"
                    (onChange)="updateRotationSpeed(rotateSpeedValue);" 
                    [disabled]="editorService.blockUserAction"
                    [styleClass]="editorService.blockUserAction ? 'disable-slider ml15' : 'ml15'"
                    [min]="1" 
                    [max]="10">
                </p-slider>
            </div>

            <!-- SETTINGS DONUT SIZE SIZE
            ==================================== -->
            <div class="form-group content-slide mb35">
                <div class="title-content">
                    <label>Donut Size</label>
                    <input-number 
                        [value]="donutSize"
                        (onChange)="updateDonutSize($event)"
                        [disabled]="editorService.blockUserAction"
                        [min]="10"
                        [max]="50">
                    </input-number>
                </div>
                <p-slider 
                    (dblclick)="resetDonutSize()"
                    [(ngModel)]="donutSize" 
                    (onChange)="updateDonutSize($event.value);" 
                    [disabled]="editorService.blockUserAction"
                    [styleClass]="editorService.blockUserAction? 'disable-slider ml15' : 'ml15'"
                    [step]="1" 
                    [min]="10" 
                    [max]="50">
                </p-slider>
            </div>

            <!-- SETTINGS SHOW HIDE DONUT ARTWORK
            ==================================== -->
            <div class="d-flex align-items-center mb10">
                <p-inputSwitch 
                    [disabled]="editorService.blockUserAction"
                    (onChange)="updateShowDonutStatus()"
                    [(ngModel)]="editorService.exhibition.show_donut"
                    styleClass="switch-lg">
                </p-inputSwitch>
                <h5 class="mr-2 ml-3 mb0">{{ editorService.exhibition.show_donut ? "Show" : "Hide" }} Donuts</h5>
            </div>
        </ng-container>
    </div>
</div>

<p-dialog 
    [(visible)]="editorService.showFieldOfView" 
    [style]="{width: '960px'}" 
    [modal]="true" 
    [closable]="false"
    [draggable]="false" 
    appendTo="body"
    (onShow)="fieldOfView.renderScene()"
    (onHide)="fieldOfView.cancel()"
    [showHeader]="false"
    styleClass="field-of-View">
    <div class="close-popup">
        <button (click)="editorService.showFieldOfView=false">
            <i class="vi vi-x"></i>
        </button>
    </div>

    <app-field-of-view #fieldOfView></app-field-of-view>
</p-dialog>