<div class="wrap-objects">
    <ng-container *ngIf="!editorService.activeOrdinaryObject">
        <!-- BUTTON ADD FIGURE
        ============================== -->
        <button
            (click)="uploadNewObject.click()"
            [class.disable-button]="loadingGalleryService.show||editorService.blockUserAction"
            [disabled]="loadingGalleryService.show||editorService.blockUserAction"
            pTooltip="Click here to add ordinary object to your gallery."
            tooltipPosition="right"
            placeholder="Right"
            class="btn btn-add">
            Add New
            <i class="vi vi-plus"></i>
        </button>
        <input type="file" #uploadNewObject (change)="uploadObject($event)" hidden />

        <!-- INFO UPLOAD
        ============================== -->
        <div class="wrap-info">
            <p class="desc-info ml5">We support GLB format for 3D objects</p>
        </div>

        <div class="wrap-item-figure">
            <!-- LIST WHEN ANIMATION SEQUENCE IS NOT ACTIVE
            ============================================================ -->
            <ul class="pl-0">
                <li
                    [class.disable-item]="loadingGalleryService.show || !objectsService.loadedOrdinaryObjects.includes(object.id)"
                    class="item-figure"
                    *ngFor="let object of getObjects()">
                    <div class="wrap-title-figure">
                        <div class="position-relative">
                            <img src="{{env.staticAssets}}images/other/ordinary-object.png?t={{mainService.appVersion}}" class="img-figure" />
                            <img src="{{env.staticAssets}}images/other/badge3D.png?t={{mainService.appVersion}}" alt="" class="badge"/>
                        </div>
                        <div class="title-figure">{{object.name}}</div>
                    </div>
                    <a href="javascript:;" (click)="selectObject(object)" class="link-figure"></a>
                </li>

                <li *ngIf="!getObjects().length && !mainService.onLoadingData" class="empty-object text-center">
                    No object uploaded
                </li>
            </ul>
        </div>
    </ng-container>

    <ng-container *ngIf="editorService.activeOrdinaryObject">
        <div [style]="{'padding': '10px'}">
            <button
                (click)="unselectObject()"
                class="btn btn-outline-default2 btn-sm btn-back-to">
                <i class="pi pi-arrow-left" style="font-size: 14px;"></i>
                Back to Objects
            </button>
        </div>

        <!-- START ARTWORK NAME & THUMBNAIL
        ============================================================ -->
        <div class="button-name-figure">
            <div class="contant-name-figure">
                <div class="wrap-title-figure">
                    <!-- ARTWORK THUMBNAIL --> 
                    <div class="wrap-image-figure">
                        <img src="{{env.staticAssets}}images/other/ordinary-object.png?t={{mainService.appVersion}}" class="img-figure">
                        <!-- <a href="javascript:;" class="edit-image"> Edit </a> -->
                        <input type="file" hidden>
                    </div>
                    <!-- OBJRCT NAME -->
                    <input
                        type="text"
                        (focus)="editorService.onInput=true"
                        (blur)="editorService.onInput=false"
                        (input)="renameOrdninaryObject()"
                        #renameInput
                        (keydown)="mainService.blockChars($event); mainService.blockSpaceAtStart($event, renameInput)"
                        [formControl]="objectName"
                        [readonly]="editorService.blockUserAction"
                        type="text"
                        class="name-figure">
                </div>
            </div>
            <div *ngIf="objectName.errors" class="validation-error">
                <p *ngIf="objectName.errors?.required">Name is required.</p>
            </div>
            <div *ngIf="objectName.errors" class="validation-error">
                <p *ngIf="objectName.errors?.pattern">Special characters are not allowed </p>
            </div>
        </div>
        <!-- END ARTWORK NAME & THUMBNAIL -->

        <!-- START MAIN MENU
        ==================================== -->
        <div class="wrap-menu-icon">
            <!-- LEFT MENU MAIN
            ============================== -->
            <div class="left-menu">
                <ul>
                    <!-- ROTATE VIA AXIS
                    ============================== -->
                    <li class="item-menu-icon" pTooltip="Rotate Axis" tooltipPosition="top" placeholder="top">
                        <button
                            [disabled]="editorService.blockUserAction"
                            [class.disable-icon]="editorService.blockUserAction"
                            (click)="adjustPositionOrRotationByAxis('rotation')"
                            class="item-btn">
                            <i class="vi vi-global"></i>
                        </button>
                    </li>
                    <!-- MOVE VIA AXIS
                    ============================== -->
                    <li class="item-menu-icon" pTooltip="Moving Axis" tooltipPosition="top" placeholder="top">
                        <button
                            [disabled]="editorService.blockUserAction"
                            [class.disable-icon]="editorService.blockUserAction"
                            (click)="adjustPositionOrRotationByAxis('position')"
                            class="item-btn">
                            <i class="vi vi-axis"></i>
                        </button>
                    </li>
                    <!-- LOCK CAMERA
                    ============================== -->
                    <li class="item-menu-icon" pTooltip="{{!editorService.lockCameraWhenDragOrdinaryObject ? 'Lock Camera': 'Unlock Camera'}}" tooltipPosition="top" placeholder="top">
                        <button
                            (click)="toggleLockCamera()"
                            [disabled]="editorService.blockUserAction"
                            [class.disable-icon]="editorService.blockUserAction"
                            class="item-btn">
                            <i class="vi vi-locked" *ngIf="editorService.lockCameraWhenDragOrdinaryObject"></i>
                            <i class="vi vi-unlocked" *ngIf="!editorService.lockCameraWhenDragOrdinaryObject"></i>
                        </button>
                    </li>
                </ul>
            </div>
            <!-- RIGHT MENU MEIN
            ============================== -->
            <div class="right-menu">
                <ul>
                    <!-- DELETE ORDINARY OBJECT -->
                    <li class="item-menu-icon" pTooltip="Delete Ordinary Object" tooltipPosition="top" placeholder="top">
                        <button
                            (click)="displayConfirmDelete=true"
                            [class.disable-icon]="editorService.blockUserAction"
                            [disabled]="editorService.blockUserAction"
                            class="item-btn" >
                            <i class="vi vi-delete"></i>
                        </button>
                    </li>
                </ul>
            </div>
        </div>

        <div class="content-settings">

            <!-- SCALING ARTWORK
            ============================== -->
            <div class="form-group content-slide" [style]="{'margin-bottom': '33px'}">
                <div class="title-content">
                    <label>Scaling</label>
                    <input-number
                        [value]="scalingValue"
                        (onChange)="updateInputValue($event,'scaling',1,30)"
                        [min]="1"
                        [max]="30"
                        (onFocus)="editorService.onInput=$event"
                        (onBlur)="editorService.onInput=$event"
                        [disabled]="editorService.blockUserAction">
                    </input-number>
                </div>
                <p-slider
                    (pointerdown)="adjustScaling('start')"
                    (onChange)="adjustScaling('on')"
                    (onSlideEnd)="adjustScaling('end')"
                    [disabled]="editorService.blockUserAction"
                    [(ngModel)]="editorService.activeOrdinaryObjectNode.scaling.x"
                    [min]="0.1"
                    [max]="3"
                    [step]="0.000001"
                    [styleClass]="editorService.blockUserAction? 'disable-slider ml15' : 'ml15'">
                </p-slider>
            </div>

            <!-- ALIGN OBJECT
            ============================== -->
            <div class="outline-bottom"></div>
            <app-align-object 
                [alignLimit]="[editorService.topLimit.position.y,editorService.bottomLimit.position.y]"
                [exhibitionHeight]="editorService.activeExhibitionNode?.dimensions.height"
                [disabled]="editorService.blockUserAction"
                [inputFocus]="editorService.onInput"
                [showAlignLimit]="editorService.showAlignLimit"
                [activeNodes]="[editorService.activeOrdinaryObjectNode]"
                (onChangeDisplay)="showAlignLimitMesh($event)"
                (onAdjustLimit)="adjustAlignLimit($event)"
                (onChangePosition)="alignOrdinaryObject($event)"
                (onInput)="editorService.onInput=$event">
            </app-align-object>

            <!-- ROTATE X VIA SLIDER
            ============================== -->
            <div class="form-group content-slide mb30">
                <div class="outline-bottom"></div>
                <div class="title-content">
                    <label>Rotation X</label>
                    <input-number
                        [value]="rotateX"
                        (onChange)="updateInputValue($event,'rotateX',-180,180)"
                        (onBlur)="editorService.onInput=$event"
                        (onFocus)="editorService.onInput=$event"
                        [min]="-180"
                        [max]="180">
                    </input-number>
                </div>
                <p-slider
                    [min]="-3.14159"
                    [max]="3.14159"
                    [step]="0.000001"
                    (onChange)="initInputsValue()"
                    (onSlideEnd)="adjustRotation()"
                    [(ngModel)]="editorService.activeOrdinaryObjectNode.rotation.x"
                    [styleClass]="editorService.blockUserAction? 'disable-slider ml15' :'ml15'">
                </p-slider>
            </div>
            <!-- ROTATION Y VIA SLIDER
            ============================== -->
            <div class="form-group content-slide mb30">
                <div class="title-content">
                    <label>Rotation Y</label>
                    <input-number
                        [value]="rotateY"
                        (onChange)="updateInputValue($event, 'rotateY',-180,180)"
                        (onBlur)="editorService.onInput=$event"
                        (onFocus)="editorService.onInput=$event"
                        [min]="-180"
                        [max]="180">
                    </input-number>
                </div>
                <p-slider
                    [min]="-3.14159"
                    [max]="3.14159"
                    (onChange)="initInputsValue()"
                    (onSlideEnd)="adjustRotation()"
                    [step]="0.000001"
                    [(ngModel)]="editorService.activeOrdinaryObjectNode.rotation.y"
                    [styleClass]="editorService.blockUserAction? 'disable-slider ml15' :'ml15'">
                </p-slider>
            </div>

            <!-- ROTATION Z VIA SLIDER
            ============================== -->
            <div class="form-group content-slide mb40" [style]="{'margin-bottom': '18px'}">
                <div class="title-content">
                    <label>Rotation Z</label>
                    <input-number
                        [value]="rotateZ"
                        (onChange)="updateInputValue($event,'rotateZ',-180,180)"
                        (onBlur)="editorService.onInput=$event"
                        (onFocus)="editorService.onInput=$event"
                        [min]="-180"
                        [max]="180">
                    </input-number>
                </div>
                <p-slider
                    [min]="-3.14159"
                    [max]="3.14159"
                    (onChange)="initInputsValue()"
                    (onSlideEnd)="adjustRotation()"
                    [step]="0.000001"
                    [(ngModel)]="editorService.activeOrdinaryObjectNode.rotation.z"
                    [styleClass]="editorService.blockUserAction? 'disable-slider ml15' :'ml15'">
                </p-slider>
            </div>

            <div class="form-group content-slide mb40">
                <div class="title-content">
                    <label>Light Intensity</label>
                    <input-number
                        [value]="lightIntensityValue"
                        (onChange)="ajudstLightIntensity($event)"
                        (onBlur)="editorService.onInput=$event"
                        (onFocus)="editorService.onInput=$event"
                        [disabled]="editorService.blockUserAction"
                        [min]="1"
                        [max]="80">
                    </input-number>
                </div>
                <p-slider 
                    [(ngModel)]="lightIntensityValue"
                    (onChange)="ajudstLightIntensity(lightIntensityValue)"
                    [min]="1"
                    [max]="80"
                    [step]="1" 
                    [disabled]="editorService.blockUserAction"
                    [styleClass]="editorService.blockUserAction ? 'disable-slider ml15' : 'ml15'">
                </p-slider>
            </div>
        </div>
    </ng-container>
</div>

<!-- COMFIRM DELETE
============================== -->
<p-dialog
    [(visible)]="displayConfirmDelete"
    [modal]="true"
    [style]="{maxWidth: '725px'}"
    [baseZIndex]="10000"
    [closable]="!onDelete"
    [draggable]="false"
    [resizable]="false"
    styleClass="confirm-delete-dark">
    <div class="wrap-content-confirm">
        <div class="desc">
            <h3>Are you sure you want to delete this object?</h3>
            <p>If you press “Yes”, your object will be deleted immediately. You can’t undo this action.</p>
        </div>
        <div class="wrap-btn">
            <button
                [disabled]="onDelete"
                (click)="deleteOrdinaryObject()"
                class="btn btn-light btn-sm"
                [class.loading-button-primary]="onDelete">
                Yes
            </button>
            <button [disabled]="onDelete" class="btn btn-primary btn-sm mr0" (click)="displayConfirmDelete=false">No</button>
        </div>
    </div>
</p-dialog>
