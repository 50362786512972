<app-layouts>
    <div class="wrap-promocode-form" content>
        <h3 class="title">Promo Code Data</h3>

        <div class="wrap-form form" [formGroup]="promoCodeForm">
            <!-- name coupon -->
            <div class="form__name" *ngIf="promoCodeForm.get('name') as name">
                <label class="form__label">Promo Code Name</label>
                <input
                    formControlName="name" 
                    [class.is-invalid]="name.touched && name.invalid" 
                    type="text"
                    (input)="handleSpaceAtStart('name')"
                    class="form-control" 
                    placeholder="Name">
                
                <ng-container *ngIf="name.touched">
                    <small class="invalid-feedback" *ngIf="name.errors?.required">
                        Name required
                    </small>
                    <small class="invalid-feedback" *ngIf="name.errors?.maxlength">
                        Name cannot be more than 20 characters long.
                    </small>
                    <small class="invalid-feedback" *ngIf="name.errors?.pattern">
                        Name must contain only letters and numbers.
                    </small>
                </ng-container>
            </div>

            <!-- type of product -->
            <div class="form__name" *ngIf="promoCodeForm.get('productType') as productType">
                <label class="form__label">Product Type</label>
                <p-dropdown 
                    formControlName="productType"
                    [options]="productTypes"
                    optionLabel="name"
                    [class.is-invalid]="productType.touched && productType.invalid"
                    (onChange)="updateValidators()"
                    placeholder="Product Type" 
                    styleClass="coupon-type">
                </p-dropdown>

                <ng-container *ngIf="productType.touched">
                    <small class="invalid-feedback" *ngIf="productType.errors?.notEmptyObject">
                        Product type required
                    </small>
                </ng-container>
            </div>

            <!-- product interval -->
            <div class="form__name" *ngIf="promoCodeForm.get('billingPeriod') as billingPeriod">
                <label class="form__label">Product Billing Period</label>
                <p-dropdown 
                    formControlName="billingPeriod"
                    [options]="productInterval"
                    optionLabel="label"
                    [class.is-invalid]="billingPeriod.touched && billingPeriod.invalid"
                    (onChange)="handleBillingPeriodChange()"
                    placeholder="Product Billing Period" 
                    styleClass="coupon-type">
                </p-dropdown>

                <ng-container *ngIf="billingPeriod.touched">
                    <small class="invalid-feedback" *ngIf="billingPeriod.errors?.notEmptyObject">
                        Discount type required
                    </small>
                </ng-container>
            </div>

            <!-- discount type -->
            <div class="form__name" *ngIf="promoCodeForm.get('discountType') as discountType">
                <label class="form__label">Type of Discount</label>
                <p-dropdown 
                    formControlName="discountType"
                    [options]="discountTypes"
                    optionLabel="label"
                    [class.is-invalid]="discountType.touched && discountType.invalid"
                    (onChange)="handleDiscountTypeChange($event)"
                    placeholder="Type of Discount" 
                    styleClass="coupon-type">
                </p-dropdown>

                <ng-container *ngIf="discountType.touched">
                    <small class="invalid-feedback" *ngIf="discountType.errors?.notEmptyObject">
                        Discount type required
                    </small>
                </ng-container>
            </div>

            <!-- discount -->
            <div class="form__name" *ngIf="promoCodeForm.get('discount') as discount">
                <label class="form__label">Discount</label>
                <input 
                    type="text"
                    id="discountInput"
                    [class.is-invalid]="discount.touched && discount.invalid"
                    formControlName="discount"
                    (input)="processDiscountValue()"
                    [placeholder]="discountPlaceholder"
                    class="form-control">

                <ng-container *ngIf="discount.touched">
                    <small class="invalid-feedback" *ngIf="discount.errors?.required">
                        Discount required
                    </small>
                    <small class="invalid-feedback" *ngIf="discount.errors?.min">
                        Discount must be greater than or equal {{discount.errors?.min.min}}.
                    </small>
                    <small class="invalid-feedback" *ngIf="discount.errors?.max">
                        Discount must be less than or equal {{discount.errors?.max.max}}.
                    </small>
                </ng-container>
            </div>

            <!-- duration -->
            <div class="form__name">
                <label class="form__label duration">Duration</label>
                <small class="text-sub">How long this promo code should be applied to the subscription?</small>
                <div class="selected-option">
                    <div class="duration__item">
                        <input 
                            (click)="changeDuration('once')"
                            [checked]="promoCodeForm?.get('durationOnce').value"
                            type="radio"/>
                        <label 
                            [class.disabled]="false"
                            (click)="changeDuration('once')" 
                            class="text-nowrap">
                            One Time
                        </label>
                    </div>
                    <div class="duration__item ">
                        <input 
                            (click)="changeDuration('forever')"
                            [checked]="promoCodeForm?.get('durationForever').value"
                            type="radio"/>
                        <label 
                            [class.disabled]="false"
                            (click)="changeDuration('forever')"
                            class="text-nowrap">
                            Forever
                        </label>
                    </div>
                    <div class="duration__item">
                        <input
                            (click)="changeDuration('repeating')"
                            [checked]="promoCodeForm?.get('durationRepeating').value"
                            type="radio" />
                        <label 
                            [class.disabled]="false"
                            (click)="changeDuration('repeating')"
                            class="text-nowrap">
                            Limited Period
                        </label>

                        <ng-container *ngIf="promoCodeForm.get('durationRepeatingValue') as repeatingPeriod">
                            <input 
                                type="number"
                                [class.is-invalid]="repeatingPeriod.touched && repeatingPeriod.invalid"
                                formControlName="durationRepeatingValue" 
                                class="form-control input-month">
                            <span 
                                (click)="changeDuration('repeating')" 
                                class="month">
                                {{limitedPeriod}}
                            </span>

                            <ng-container *ngIf="repeatingPeriod.touched">
                                <div class="wrap-invalid">
                                    <small class="invalid-feedback d-block" *ngIf="repeatingPeriod.errors?.required">
                                        {{limitedPeriod}} required
                                    </small>
                                    <small class="invalid-feedback d-block" *ngIf="repeatingPeriod.errors?.min">
                                        The limited period must be a minimum of one {{limitedPeriod}} or more.
                                    </small>
                                    <small class="invalid-feedback d-block" *ngIf="repeatingPeriod.errors?.max">
                                        The limited period cannot be more than 999
                                    </small>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>

            <!-- expires -->
            <div class="form__name" *ngIf="promoCodeForm.get('expires') as expires">
                <label class="form__label">Expires On</label>
                <input 
                    type="text"
                    formControlName="expires"
                    class="form-control"
                    [class.is-invalid]="expires.touched && expires.invalid"
                    placeholder="DD-MM-YYYY">
                <small class="text-sub mt10">Date up to which the promo code can be applied to new subscriptions.</small>
                <ng-container *ngIf="expires.touched">
                    <small class="invalid-feedback" *ngIf="expires.errors?.validDate">
                        Invalid date! please enter the date in the format "DD-MM-YYYY".
                    </small>
                    <small class="invalid-feedback" *ngIf="expires.errors?.dateLessThanToday">
                        Date must be greater than or equal to today.
                    </small>
                    <small class="invalid-feedback" *ngIf="expires.errors?.dateGreaterThan">
                        Date must be less than or equal to {{expires.errors?.maxDate}}.
                    </small>
                </ng-container>
            </div>

             <!-- maximum redeem -->
             <div class="form__name redeem" *ngIf="promoCodeForm.get('maxRedemptions') as maxRedemptions">
                <label class="form__label">Maximum Redemptions</label>
                <input 
                    type="number"
                    (input)="removeLeadingZero(maxRedemptions)"
                    (keydown)="handleNotAllowedValue($event)"
                    [class.is-invalid]="maxRedemptions.touched && maxRedemptions.invalid"
                    formControlName="maxRedemptions"
                    class="form-control">
                
                <ng-container *ngIf="maxRedemptions.touched">
                    <small class="invalid-feedback" *ngIf="maxRedemptions.errors?.max">
                        Max redemption cannot be more than 100,000,000
                    </small>
                </ng-container>
            </div>

            <!-- allowed user -->
            <div class="form__name user-select" *ngIf="promoCodeForm.get('allowedUsers') as allowedUsers">
                <label class="form__label">Select a User (optional)</label>
                <div class="wrap-user-select select">
                    <p-dropdown 
                        [options]="originalListUsers"
                        (onChange)="selectUserForPromoCode($event)"
                        [filter]="true" 
                        formControlName="allowedUsers"
                        optionLabel="username"
                        placeholder="Search by username" 
                        styleClass="coupon-type"></p-dropdown>
                </div>
            </div>

            <div class="form__name submit">
                <button (click)="backToList()"  [disabled]="loading" class="btn btn-light button-back">
                    <i class="vi vi-arrow-left"></i>
                    Back
                </button>
                <button
                    [disabled]="loading || promoCodeForm.invalid"
                    (click)="savePromoCode()"
                    class="btn btn-primary">
                    {{  loading ? 'Saving...' : 'Save Changes' }}
                </button>
            </div>
        </div>
    </div>
</app-layouts>
