<!-- BUTTON ADD FIGURE
============================== -->
<button 
    pTooltip="Click here to add artworks to your gallery." 
    tooltipPosition="right" 
    placeholder="Right"
    class="btn btn-add"
    (click)="openAddArtworkModal()"
    [class.disable-button]="isAddArtworkDisabled()"
    [disabled]="isAddArtworkDisabled()">
    Add New <i class="vi vi-plus"></i>
</button> 

<!-- add artwork -->
<p-dialog
    [(visible)]="addArtworkService.displayAddPopup"
    [modal]="true"
    [style]="{maxWidth: '960px'}"
    [baseZIndex]="10000"
    [closable]="!onFetchVideoData"
    appendTo="body"
    (onShow)="onOpenAddArtwork()"
    [draggable]="false"
    [resizable]="false"
    closeIcon="vi vi-x"
    styleClass="add-artworks edit-frame">
    <div class="wrap-content">
        <div class="wrap-title">
            <h2 class="title">Add Your Artworks</h2>
        </div>

        <div [ngSwitch]="addArtworkSection">
            <!-- Add Artwork or Video -->
            <div class="content-add" *ngSwitchCase="'select-files'">
                <files-handler
                    [disabled]="onFetchVideoData"
                    (onSelectFiles)="onSelectFiles($event)" 
                    [isOpened]="addArtworkService.displayAddPopup">
                </files-handler>
                <add-video
                    #addVideo
                    (onFetch)="onFetchVideoData = $event"
                    (onSubmited)="onVideoSubmited($event)">
                </add-video>
            </div>

            <!-- Preview for single artwork -->
            <single-preview
                *ngSwitchCase="'single-preview'"
                (onBack)="backToSelectFiles()"
                (onValidate)="invalidNames = !$event"
                (onRename)="artworkFiles[0] = $event"
                [isVideo]="isVideo"
                [videoData]="videoData"
                [file]="artworkFiles[0]">
            </single-preview>

            <!-- List preview artwork -->
            <multi-preview 
                *ngSwitchCase="'multiple-preview'"
                (onBack)="backToSelectFiles()"
                (onValidate)="invalidNames = !$event"
                (onRename)="artworkFiles = $event"
                [files]="artworkFiles">
            </multi-preview>
        </div>

        <div class="footer text-right">  
            <button 
                [disabled]="onFetchVideoData"
                (click)="addArtworkService.displayAddPopup = false"
                class="btn btn-light2">
                Close
            </button>
            <submit
                *ngIf="addArtworkSection !== 'select-files'"
                [disabled]="invalidNames"
                [artworkFiles]="artworkFiles"
                [isVideo]="isVideo"
                [videoData]="videoData"
                (onFinished)="onFinished($event)">
            </submit>
        </div>
    </div>
</p-dialog>
