<div class="wrap-date">
    <label class="label-date" *ngIf="label">{{label}}</label>
    <div class="mr10 input-date" *ngIf="!hideDays">
        <p-dropdown 
            [disabled]="disabledDate"
            appendTo="body"
            [options]="days"
            [(ngModel)]="day"
            (onChange)="onSelectDate()" 
            styleClass="bg-dark calender"
            panelStyleClass="input-date"
            [style]="{'min-width': '48px', 'width':'48px'}">
            <ng-template let-item pTemplate="item">
                {{item.label}}
            </ng-template>
        </p-dropdown>
        <div *ngIf="blank" class="blank-overlay"></div>
    </div>
    <div class="input-date" [ngClass]="yearType == 'invoice-date' ? 'mr-1' : 'mr10'">
        <p-dropdown 
            [disabled]="disabledDate"
            appendTo="body"
            [options]="months"
            [(ngModel)]="month" 
            (onChange)="onSelectDate()" 
            [styleClass]="'bg-dark calender' + (yearType == 'invoice-date' ? ' invoices' : '')"
            panelStyleClass="input-date"
            [style]="{'min-width': '110px', 'width':'110px'}">
            <ng-template let-item pTemplate="item">
                {{item.label}}
            </ng-template>
        </p-dropdown>
        <div *ngIf="blank" class="blank-overlay"></div>
    </div>
    <div class="mr10 input-date">
        <p-dropdown 
            [disabled]="disabledDate"
            appendTo="body"
            [options]="years"
            [(ngModel)]="year" 
            (onChange)="onSelectDate()" 
            [styleClass]="'bg-dark calender' + (yearType == 'invoice-date' ? ' invoices' : '')"
            panelStyleClass="input-date"
            [style]="{'min-width': '67px', 'width':'68px'}">
            <ng-template let-item pTemplate="item">
                {{item.label}}
            </ng-template>
        </p-dropdown>
        <div *ngIf="blank" class="blank-overlay"></div>
    </div>
</div>