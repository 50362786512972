import { Component } from '@angular/core';
import { InputNumberComponent } from 'src/app/components/input-number/input-number.component';
import { TextService } from '../../../service/text-service.service';
import { EditorService } from '../../../../editor.service';
import { TextLoaderService } from '../../../service/text-loader.service';

@Component({
  selector: 'app-font-size',
  standalone: true,
  templateUrl: './font-size.component.html',
  styleUrl: './font-size.component.scss',
  imports: [
    InputNumberComponent,
  ],
})
export class FontSizeComponent {

  constructor(
    public textService: TextService,
    public editorService: EditorService,
    private _textLoader: TextLoaderService
  ) { }

  /**
   * ANCHOR: Update Input Value
   * @description: to update input value
  */
  public updateInputValue(value, type): void {
    switch (type) {
      case 'fontSize':
        this._textLoader.activeText.font_size = value * 2;
        this._textLoader.activeText.line_height = value * 2;
        this.editFontSize();
        break;
      case 'lineHeight':
        this._textLoader.activeText.line_height = (value * 2) / 1.12;
        this.editLineHeight();
        break;
    }
    this.textService.initInputsValue()
  }

  /**
   * * EDIT LINE HEIGHT *
   * ANCHOR Edit line height
   * @description for updated line height
   */
  public editLineHeight(): void {
    if (this.editorService.selectedExhibitAssets.length > 1) {
      this.editorService.selectedExhibitAssets.forEach((node: any) => {
        const textWallData = this.textService.getTextWallData(node);
        textWallData.line_height = this._textLoader.activeText.line_height;
        let textSize = textWallData.font_size / textWallData.scaling.scaling_x * this._textLoader.textWallQuality;
        textSize += 0.001;
        node.textGUI.fontSize = textSize;
        this.textService.updateLineheight(node);
        textSize -= 0.001;
        node.textGUI.fontSize = textSize;
        node.changed = true;
      })
    } else {
      let textSize = this._textLoader.activeText.font_size / this._textLoader.activeText.scaling.scaling_x * this._textLoader.textWallQuality;
      textSize += 0.001;
      this._textLoader.activeTextNode.textGUI.fontSize = textSize;
      this.textService.updateLineheight(this._textLoader.activeTextNode);
      textSize -= 0.001;
      this._textLoader.activeTextNode.textGUI.fontSize = textSize;
      this._textLoader.activeTextNode.changed = true;
    }

    this.editorService.updateLogActivity("Update text line height");
    this.editorService.updateUndoRedoState()
  }


  /**
 * * EDIT FONT SIZE *
 * ANCHOR Edit font size
 * @description for updated font size
 */
  public editFontSize(): void {
    if (this.editorService.selectedExhibitAssets.length > 1) {
      this.editorService.selectedExhibitAssets.forEach((node: any) => {
        const textWallData = this.textService.getTextWallData(node);
        textWallData.font_size = this._textLoader.activeText.font_size;
        textWallData.line_height = this._textLoader.activeText.line_height;
        node.textGUI.fontSize = textWallData.font_size / textWallData.scaling.scaling_x * this._textLoader.textWallQuality;
        this.textService.updateLineheight(node);
        node.changed = true;
      })
    } else {
      this._textLoader.activeTextNode.textGUI.fontSize = this._textLoader.activeText.font_size / this._textLoader.activeText.scaling.scaling_x * this._textLoader.textWallQuality;
      this.textService.updateLineheight(this._textLoader.activeTextNode);
      this._textLoader.activeTextNode.changed = true;
    }

    this.editorService.updateLogActivity("Update text font size");
    this.editorService.updateUndoRedoState()
  }
}
