<app-layouts>
    <div content class="wrap-profile-setting text-center" *ngIf="!infoUser">
        Loading...
    </div>
    <div content class="wrap-profile-setting" *ngIf="infoUser">
        <div class="wrap-form-group" [formGroup]="form">
            <div class="wrap-image-set">
                <img [src]="setDefaultImg" alt="" />
                <button (click)="chooseFile.click()" class="btn btn-basic btn-sm">Choose Your Profile Picture</button>
                <input type="file" hidden accept="image/*" #chooseFile (change)="setAvatar($event)">
            </div>
            <div class="form-group">
                <label>Profile URL</label>
                <input 
                    type="text" 
                    [class.is-invalid]="form.get('username').invalid||usernameNotAvailable||availableRoutes.includes(form.get('username').value.toLowerCase())" 
                    class="form-control"
                    (input)="validateData()"
                    maxlength="25"
                    formControlName="username">
                <div class="simulation-user">
                    villume.com/<span class="text-white">{{form.get('username').value}}</span>
                </div>
                <div class="invalid-feedback" *ngIf="form.get('username').invalid">
                    <ng-container *ngIf="form.get('username')?.errors?.required">
                        Username required
                    </ng-container>
                    <ng-container *ngIf="form.get('username')?.errors?.pattern">
                        Username invalid
                    </ng-container>
                </div>
                <div class="invalid-feedback" *ngIf="usernameNotAvailable">
                    Username not available
                </div>
                <div class="invalid-feedback" *ngIf="!(form.get('username')?.errors?.required)&&availableRoutes.includes(form.get('username').value.toLowerCase())">
                    Username cannot be used
                </div>
            </div>
            <div class="form-group">
                <label>Your Birth Date</label>
                <app-input-date 
                    (selectDate)="changeDate($event)"
                    [day]="birth_date" 
                    [month]="birth_month" 
                    [year]="birth_year">
                </app-input-date>
                <small class="text-danger" *ngIf="birthDateInvalid">
                    The date of birth cannot be more than the current date
                </small>
            </div>
            
            <!-- gender -->
            <div class="form-group mb25">
                <label>Gender</label>
                <div class="selected-option">
                    <div class="item-gender">
                        <input type="radio" [checked]="form.value.male"/>
                        <label (click)="setGender('male')" for="male" class="text-nowrap gender">male</label>
                    </div>
                    <div class="item-gender ">
                        <input type="radio" [checked]="form.value.female"/>
                        <label (click)="setGender('female')" for="female" class="text-nowrap gender">female</label>
                    </div>
                    <div class="item-gender">
                        <input type="radio"  [checked]="form.value.nonBinary"/>
                        <label (click)="setGender('nonBinary')" for="non-binary" class="text-nowrap gender">non-binary</label>
                    </div>
                </div>
            </div>

            <!-- system measurement -->
            <div class="form-group mb25">
                <label>Measurement System</label>
                <div class="selected-option">
                    <div class="item-gender" (click)="useMetric = true">
                        <input type="radio" name="measurement" [checked]="useMetric"/>
                        <label for="male" class="text-nowrap">metric</label>
                    </div>
                    <div class="item-gender" (click)="useMetric = false">
                        <input type="radio" name="measurement" [checked]="!useMetric"/>
                        <label for="female" class="text-nowrap">imperial</label>
                    </div>
                </div>
            </div>

            <div class="form-group confirm mb40" (click)="subscribeEmail = !subscribeEmail">
                <input
                    type="checkbox"
                    class="checkbox"
                    [checked]="subscribeEmail">
                <label class="subscribe-email">Keep me informed with the latest art news and Villume updates</label>
            </div>

            <div class="d-flex">
                <button 
                    class="btn btn-primary btn-sm btn-submit" 
                    (click)="updateProfile()" 
                    [disabled]="dataInvalid || loading || availableRoutes.includes(form.get('username').value.toLowerCase())" 
                    [style]="{'min-width': '113px'}" [class.loading-button-primary]="loading">
                    Continue <i class="pi pi-arrow-right"></i>
                </button>
            </div>
        </div>
    </div>
    <!-- END PROFILE SETTING -->
</app-layouts>
