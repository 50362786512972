<div class="wrapper-media-support">
    <div 
        class="media__input"
        tooltipPosition="right"
        pTooltip="Here, you can add video and audio files to complement your artwork information. These media files will be accessible by left-clicking on the artwork within the gallery viewer.">
        <a 
            *ngIf="!deleting && !fileExisted" 
            [class.disabled]="editorService.blockUserAction"
            class="media__button-input text-left"
            (click)="fileExisted ? '': editorService.blockUserAction ? '':file.click()">
            Upload Video or Audio files for Artwork Info.
        </a>
        <input 
            *ngIf="!deleting && fileExisted"
            type="text" 
            [readonly]="editorService.blockUserAction"
            (focus)="editorService.onInput = true"
            (input)="renameMediaSupport()"
            (blur)="editorService.onInput = false"
            #renameInput
            [formControl]="mediaName" 
            (keydown)="mainService.blockSpaceAtStart($event, renameInput)"
            class="form-control">
        <div 
            *ngIf="fileExisted && !deleting"
            pTooltip="Edit"
            tooltipPosition="top"
            class="media__action  media__action--left">
            <a 
                href="javascript:;" 
                class="button" 
                (click)="editorService.blockUserAction ? '':file.click()"
                [class.disabled]="editorService.blockUserAction">
                <i class="vi vi-modify"></i>
            </a>
        </div>
        <div 
            *ngIf="fileExisted && !deleting"
            pTooltip="Delete"
            tooltipPosition="top"
            class="media__action">
            <a 
                href="javascript:;" 
                (click)="editorService.blockUserAction ? '':deleteMediaSupport()" 
                class="button"
                [class.disabled]="editorService.blockUserAction">
                <i class="vi vi-delete"></i>
            </a>
        </div>
        <p-progressSpinner
            *ngIf="deleting" 
            [style]="{width: '35px', height: '35px', marginTop: '10px'}" 
            styleClass="custom-spinner" 
            strokeWidth="5" 
            fill="transparent" 
            animationDuration=".5s">
        </p-progressSpinner>
        <input #file (change)="insertMediaSupport($event)" accept="audio/*, video/*" type="file" hidden>
    </div>
    <div *ngIf="mediaName.errors" class="validation-error">
        <p *ngIf="mediaName.errors?.required && mediaName.touched">Name is required.</p>
    </div>
</div>

<!-- SECTION Dialog add video or audio -->
<p-dialog
    [(visible)]="showPreview"
    [modal]="true"
    [style]="{maxWidth: '960px'}"
    [baseZIndex]="10000"
    appendTo="body"
    [closable]="!loading"
    [draggable]="false"
    [resizable]="false"
    (onHide)="onHideMediaSupportPreview();this.editorService.onInput = false;"
    (onShow)="this.editorService.onInput = true;"
    closeIcon="vi vi-x"
    styleClass="add-artworks edit-frame media-support">
    <div class="wrap-content">
        <div class="wrap-title">
            <h2 class="title">Preview</h2>
        </div>

        <div class="content-add" [class.audio]="fileType=='audio'">
            <div class="media-name">
                <input 
                    type="text" 
                    class="form-control" 
                    [class.border-0]="fileName.length > 0" 
                    [(ngModel)]="fileName"
                    placeholder="Add a title">
            </div>

            <!-- ANCHOR Add Video layout -->
            <div class="preview__video" *ngIf="fileType == 'video'">
                <video *ngIf="videoUrl" class="video-player" crossorigin="anonymous" controls>
                    <source [src]="sanitizer.bypassSecurityTrustResourceUrl(videoUrl)" type="video/mp4">
                    Your browser does not support HTML video.
                </video>
            </div>

            <!-- ANCHOR Add Audio layout -->
            <div class="preview__audio text-center" *ngIf="fileType == 'audio'">
                <div class="audio-player" *ngIf="audioFile">
                    <!-- button play or pause -->
                    <div class="button">
                        <button *ngIf="audioFile?.paused" class="button__play" (click)="playAudio()" [disabled]="!loadedAudio">
                            <i class="vi vi-play-solid"></i>
                        </button>
                        <button *ngIf="!audioFile?.paused" class="button__pause" (click)="playAudio()" [disabled]="!loadedAudio">
                            <i class="vi vi-pause-solid"></i>
                        </button>
                    </div>

                    <!-- Progress audio -->
                    <div class="timer-bar">
                        <p-slider 
                            [(ngModel)]="audioFile.currentTime"
                            [min]="0"
                            [step]="0.05"
                            [max]="audioFile.duration"
                            [disabled]="!loadedAudio"
                            [styleClass]="!loadedAudio ? 'disable-slider':''"
                        ></p-slider>
                    </div>
                    
                    <!-- duration time -->
                    <div class="duration">
                        <div class="text-right">
                            <span class="timer">{{ timerAudio }}</span>
                            <span class="timer"> / {{ durationAudio }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="footer text-right">  
            <div>
                <button 
                    [disabled]="loading" 
                    (click)="showPreview=false" 
                    class="btn btn-light2 mr20">
                    Close
                </button>
                <button 
                    (click)="file.click()"
                    [disabled]="loading" 
                    class="btn btn-light2">
                    Choose Another File
                </button>
            </div>
            <button 
                class="btn btn-primary" 
                [class.loading-button-primary]="loading" 
                [disabled]="loading || fileName == ''" 
                (click)="uploadMediaSupport()">
                Done
            </button>
        </div>
    </div>
</p-dialog>
