import { Component } from '@angular/core';
import { MainService } from '@services';
import { ProfileService } from '../profile.service';
import { Router } from '@angular/router';
import { CreateExhibitionService } from 'src/app/components/create-exhibition/services/create-exhibition.service';
import { CreateExhibitionComponent } from '../../../components/create-exhibition/create-exhibition.component';
import { NgIf } from '@angular/common';
import { LayoutsComponent } from '../../../layouts/layouts.component';

@Component({
    selector: 'app-create',
    templateUrl: './create.component.html',
    styleUrls: ['./create.component.scss'],
    standalone: true,
    imports: [LayoutsComponent, NgIf, CreateExhibitionComponent]
})
export class CreateComponent {

  constructor(
    public mainService: MainService,
    private _profileService: ProfileService,
    private router: Router,
    private _createService: CreateExhibitionService,
  ) {this._createService.stepWizard = 1; }

  ngOnInit(): void {
    if (this.mainService.isBrowser) {
      this._detectScreenSize();
    }
  }

  ngOnDestroy(): void {
    if (this.mainService.isBrowser) {
      window.removeEventListener('resize', this.resize);
    }
  }

  private _detectScreenSize(): void {
      window.addEventListener('resize', this.resize);
  }

  private resize = (e: KeyboardEvent): void => {
    if(window.innerWidth < 1200) {
      this.router.navigate([this.mainService.userInfo.username]);
    }
  }

  
  /**
   * *START CREATING*
   */
  public startCreating():void {
    this.mainService.closeHeaderMessage();
    this._profileService.startCreating(() => {
      this.router.navigate([this.mainService.userInfo.username+'/create']);
    });
  }

}
