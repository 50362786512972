import { NgIf, NgFor } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from '@environments';
import { CreateExhibitionService } from '../services/create-exhibition.service';
import { MainService } from '@services';
import { SsrCookieService as CookieService } from 'ngx-cookie-service-ssr';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ScrollPanelModule } from 'primeng/scrollpanel';

@Component({
    selector: 'app-select-exhibition',
    templateUrl: './select-exhibition.component.html',
    styleUrls: ['./select-exhibition.component.scss'],
    standalone: true,
    imports: [
			NgIf,
			NgFor,
			LazyLoadImageModule,
			ProgressSpinnerModule,
			ScrollPanelModule
		]
})
export class SelectExhibitionComponent {
	@Output() exhibitionData: EventEmitter<any> = new EventEmitter<any>();
	@Output() nextStep: EventEmitter<any> = new EventEmitter<any>();
	@Output() loading: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Input() fromSideMenu: boolean = false;
	@Input() isProfile: boolean = false;

	public onLoading: boolean = false;
	public catalogues: any = [];
	public selected = false;
	public env = environment;

	constructor(
		public mainService: MainService,
		public createExhibitionService: CreateExhibitionService,
		private _cookieService: CookieService,
	) {}

	ngOnInit(): void {
		this.getCatalogue();
	}

	/**
		 * * GET CATALOGUE *
		 * Todo: for get list of catalogue
		 */
	getCatalogue() {
		this.loading.emit(true);
		this.onLoading = true;
		const renderer = this.mainService.isBrowser ? 'browser' : 'server';
		this.createExhibitionService.getCatalogue(renderer).subscribe((res: any) => {
			this.loading.emit(false);
			this.onLoading = false;
			this.catalogues = res.data.room;
			this.catalogues = this.catalogues.map((room) => {
				room['thumbnail'] = environment.exhibition_path + room.folder_name + "/thumbnail.png";
				room['selected'] = false
				return room;
			});

			this._handleScrollPanel();
			this._selectExhibitionAfterLogin(this.catalogues);
		}, err => {
			if (err.name == "TimeoutError") {
				this.getCatalogue()
			}
		});
	}

	/**
	 * * ANCHOR Select Exhibition *
	 * Todo: for select exhibition
	 * @param dataExhibition: any
	 */
	selectExhibition(dataExhibition: any) {
		this.catalogues = this.catalogues.map((room) => {
			room['selected'] = false
			return room;
		})
		dataExhibition['selected'] = true;
		this.exhibitionData.emit(dataExhibition);

		if (this.isProfile) {
			this.nextStep.emit(2);
		}
	}

	/**
	 * * ANCHOR Select Exhibition After Login *
	 * Todo: for select exhibition after login
	 * @param catalogues: any
	 */
	private _selectExhibitionAfterLogin(catalogues: any) {
		if (this.mainService.isBrowser) {
			const dataExhibition = this._cookieService.get('createExhibition');
			if (!dataExhibition) return;
			catalogues.map((room) => {
				if (room.folder_name == dataExhibition) {
					this.exhibitionData.emit(room);
					this.createExhibitionService.stepWizard = 2;
					this._cookieService.delete('createExhibition');
				}
			});
		}
	}

	/**
	 * * ANCHOR Handle scrollPanel *
	 */
	private _handleScrollPanel(): void {
		if (this.mainService.isBrowser) {
			setTimeout(() => {
				const scrollPanel = document.getElementsByClassName('p-scrollpanel-content');
				scrollPanel[scrollPanel.length-1].scrollTo(0,1);
			}, 200);
		}
	}
}
