<div #pricingPlan class="wrapper pricing-plan">
    <div class="pricing-plan--package">
        <p class="pricing-plan__label">Pricing Plan</p>
        <!-- Product -->
        <h2 class="pricing-plan__title">
            <ng-container *ngIf="!onFetchingSubscriptionOverview">
                {{subscriptionOverview?.product}}
                <span
                    *ngIf="subscriptionOverview?.product !== 'Experimental'"
                    class="pricing-plan__payment">{{subscriptionOverview?.interval}}</span>
            </ng-container>
            <ng-container *ngIf="onFetchingSubscriptionOverview">
                ...
            </ng-container>
        </h2>

        <!-- Description -->
        <button (click)="showDetail=!showDetail" class="pricing-plan__detail">
            <i *ngIf="!showDetail" class="vi vi-chevron-right pricing-plan__detail__icon"></i>
            <i *ngIf="showDetail" class="vi vi-chevron-down pricing-plan__detail__icon"></i>
            Details
        </button>

        <!-- List detail -->
        <div class="pricing-plan__wrap-list">
            <ul *ngIf="showDetail" class="pricing-plan__list">
                <li  
                    *ngFor="let item of subscriptionOverview?.description"
                    class="pricing-plan__list__item">
                    {{item}}
                </li>
            </ul>
        </div>

        <!-- Upgrade Plan -->
        <div class="pricing-plan__button">
            <button routerLink="/pricing" class="btn btn-light btn-sm">
                {{subscriptionOverview?.product === 'Experimental' ? 'Upgrade Plan' : 'Change Plan'}}
            </button>
        </div>
    </div>

    <!-- Next billing -->
    <div class="pricing-plan--monthly">
        <h2 class="pricing-plan__title">
            <ng-container *ngIf="!onFetchingSubscriptionOverview">
                {{subscriptionOverview?.amount.split(',')[0]}}<span
                    *ngIf="subscriptionOverview?.product !== 'Experimental'" 
                    class="pricing-plan__month">
                    / {{subscriptionOverview?.interval.slice(0, -2).toLowerCase()}}
                </span>
            </ng-container>
            <ng-container *ngIf="onFetchingSubscriptionOverview">
                ...
            </ng-container>
        </h2>
        <span class="pricing-plan__detail">
            {{subscriptionOverview?.unsubs && subscriptionOverview?.product !== 'Experimental' ? 'Will end on:' : 'Next billing:'}} 
            <ng-container *ngIf="!onFetchingSubscriptionOverview">
                {{ subscriptionOverview?.product !== 'Experimental' ? subscriptionOverview?.period_end : '-'}}
            </ng-container>
            <ng-container *ngIf="onFetchingSubscriptionOverview">
                ...
            </ng-container>
        </span>

        <!-- Button action -->
        <div class="pricing-plan__button">
            <button [disabled]="subscriptionOverview?.unsubs || subscriptionOverview?.product === 'Experimental'" class="btn btn-outline-default2 btn-sm" (click)="manageSubscription=true">Manage Subscription</button>
        </div>
    </div>
</div>

<p-dialog
    [(visible)]="manageSubscription" 
    [modal]="true" 
    [baseZIndex]="5000"
    [draggable]="false" 
    [resizable]="false" 
    closeIcon="vi vi-x"
    styleClass="manage-subscrib"> 
    <p-header>
        <h2 class="title">Manage Subscription</h2>
    </p-header>
    <div class="wrap-content">
        <div class="d-flex justify-content-between flex-wrap">
            <span class="text price-plan">
                {{subscriptionOverview?.product}} Plan
                <ng-container *ngIf="!onFetchingSubscriptionOverview">
                    {{subscriptionOverview?.amount.split(',')[0]}}<span
                        *ngIf="subscriptionOverview?.product !== 'Experimental'" 
                        class="pricing-plan__month">/{{subscriptionOverview?.interval.slice(0, -2).toLowerCase()}}
                    </span>
                </ng-container>
            </span>
            <span class="text text--right next-billing">
                Next billing: 
                <ng-container *ngIf="!onFetchingSubscriptionOverview">
                    {{ subscriptionOverview?.product !== 'Experimental' ? subscriptionOverview?.period_end : '-'}}
                </ng-container>
            </span>
        </div>
        <div class="wrap-progress">
            <small class="text text--sm">
                {{subscriptionOverview?.product !== 'Experimental' ? subscriptionOverview?.days_left : '-'}} days left
            </small>
            <p-progressBar [value]="subscriptionOverview?.product !== 'Experimental' ? subscriptionOverview?.progress : 100" [showValue]="false" styleClass="billing-progress"></p-progressBar>
        </div>
        <div class="wrap-button">
            <button routerLink="/pricing" class="button mr20">Change Your Pricing Plan</button>
            <button 
                (click)="unsubscribeConfirm = true"
                [disabled]="subscriptionOverview?.product === 'Experimental'" 
                class="button">
                Stop Your Subscription
            </button>
        </div>
    </div>
    <p-footer>
        <button class="btn btn-light btn-sm" (click)="manageSubscription=false">Cancel</button>
    </p-footer>
</p-dialog>


<!-- START ALERT DIALOG -->
<p-dialog
    [(visible)]="unsubscribeConfirm"
    [modal]="true" 
    [baseZIndex]="5000"
    [closable]="!onUnsubscribing"
    [draggable]="false" 
    [resizable]="false" 
    closeIcon="vi vi-x"
    styleClass="p-dialog alert-dialog">
    <div class="wrap-content">
        <div class="alerts">
            <h1 class="alerts__title">Are you sure you want to stop your subscription?</h1>
            <p class="alerts__desc">If you press “Yes” our Pricing Plan will not renew in the next month and all your exhibitions will go offline on {{subscriptionOverview?.period_end}}.</p>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="alerts__button">
            <button 
                (click)="unsubcription()"
                [disabled]="onUnsubscribing"
                class="btn btn-light btn-sm">
                {{onUnsubscribing ? 'Process...' : 'Yes'}}
            </button>
            <button 
                (click)="unsubscribeConfirm=false"
                [disabled]="onUnsubscribing"
                class="btn btn-light btn-sm">
                No
            </button>
        </div>
    </ng-template>
</p-dialog>

<p-dialog
    [(visible)]="unsubcriptionSuccess"
    [modal]="true" 
    [baseZIndex]="5000"
    [draggable]="false" 
    [resizable]="false" 
    closeIcon="vi vi-x"
    styleClass="p-dialog alert-dialog">
    <div class="wrap-content">
        <div class="alerts">
            <h1 class="alerts__title">You have successfully unsubscribed</h1>
            <p class="alerts__desc">We are sorry to see you go, but we respect your choice! Looking forward to your return.</p>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="alerts__button">
            <button 
                (click)="unsubcriptionSuccess = false"
                class="btn btn-light btn-sm">
                Ok
            </button>
        </div>
    </ng-template>
</p-dialog>
<!-- END ALERT DIALOG -->