import { Component, OnInit } from '@angular/core';
import { LayoutsComponent } from '../../layouts/layouts.component';

@Component({
    selector: 'app-privacy',
    templateUrl: './privacy.component.html',
    styleUrls: ['./privacy.component.scss'],
    standalone: true,
    imports: [LayoutsComponent]
})
export class PrivacyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
