import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments';
import { MainService } from 'src/app/shared/services';

@Injectable({
	providedIn: 'root'
})
export class InputColorService {

	constructor(
		public mainService:MainService,
		private http:HttpClient
	) { }

	// ======================== //
	// Save Favorit Color
	// ======================= //
	saveFavoriteColor(data) {
		return this.http.post(`${environment.baseURL}/users/fav-colors`, data)
	}

}
