<app-layouts>
    <div class="wrap-service" content>
        <!-- START DESCRIPTION
        ==================================== -->
        <div class="wrap-title">
            <h1 class="title">Terms of Use</h1>
            <span class="info-date">Effective Date: January 2022</span>
        </div>

        <div class="row">
            <div class="col col16-12 col-iped-7 offset-iped-1 col-mobile-4 offset-mobile-0 m-auto">
                <!-- START DESCRIPTION
                ==================================== -->
                <div class="wrap-description">

                    <p>This Terms of Use (the “TOU”) is entered into between you and Galleria Media Inc. (“Company”) and applies to any product, website or service provided by Company, including but not limited to an online art gallery platform currently titled “Villume” (“Villume”), the website villume.com and subdomains (the “Website”) and any other product or service to which Company applies the TOU (collectively, the “Service”).</p>
                    <p>BY CLICKING “I AGREE” OR SIMILAR CONFIRMATION OR BY USING THE SERVICE YOU ARE AGREEING TO BE BOUND BY THE TOU.</p>

                    <!-- TITLE DESCRIPTION 
                    ================================ -->
                    <h1 class="title-desc">1. Description of the Service; Access</h1>

                    <p><span class="sub-title-desc">1.1 Scope of Service.</span> The Service allows you to create and host online exhibitions (“Exhibitions”), which may be accessed and viewed by attendees (each an “Attendee”).  Company hereby grants you a: (a) non-transferrable, limited subscription to access and use free portions of the Website and paid portions of the Service pursuant to a paid subscription, in each case subject to the TOU (altogether, the “Subscription”); and (b) non-sublicensable, non-transferrable, revocable, limited license to publicly display parts of the Service on online video streaming websites, such as YouTube and Twitch, and on other social media platforms, such as Twitter or Instagram. A paid Subscription may allow you to create, store and make publicly available the number of Exhibitions and receive additional features as permitted by the terms of your Subscription (collectively, the “Features”).</p>

                    <p><span class="sub-title-desc">1.2 Age of Access.</span> The Service is provided for people of all ages.  If you are under the age of consent in your jurisdiction, you shall: (a) if required by the laws of your jurisdiction, obtain and provide to Company the consent of your parent or guardian to use the Service; and (b) if requested by Company, provide additional verifiable parental consent.</p>

                    <p><span class="sub-title-desc">1.3 Accounts and Login Information.</span> Access to the Service may require you to register an account with Company (“Account”).  In order to access an Account, you shall create a user ID and password and provide additional information requested by Company (collectively, “Login Information”).  You shall manage and ensure the security, conﬁdentiality and authorized use of Login Information. You are prohibited from sharing Login Information. Company strongly recommends that you keep Login Information confidential.  You agree to be bound by any use of the Account, including charges and purchases, whether or not authorized and shall notify Company promptly of unauthorized access or use of the Account.</p>

                    <p><span class="sub-title-desc">1.4 Early Release.</span> The Service, including the creation of and access to Exhibitions, may be provided to you in a beta or other in-development, trial or demonstrator version (“Beta Version”). If you use or access a Beta Version of the Service, you acknowledge and agree that the Service may not work as intended and Exhibitions, editing capabilities, or other features (collectively, “Beta Features”) may be very different from future releases of the Service.  Bugs, incomplete Beta Features, or faults may be present in a Beta Version, and you use any Beta Version at your own risk. Company does not guarantee that the Service will be completed and may significantly alter or remove Beta Features at any time. Given the developmental nature of Beta Version software and services in general, you agree that it is your sole responsibility to back-up data and take other appropriate measures to protect data you use in connection with the Service. </p>

                    <p><span class="sub-title-desc">1.5 Privacy Policy and Privacy Law Compliance.</span> Use of the Service is governed by a Privacy Policy detailing how Company collects, uses and discloses personal and anonymous data about you and is available at <a routerLink="/privacy-cookie-policy" class="policy-link">[Privacy policy link]</a>. You must agree to the Privacy Policy in order to use the Service.  By agreeing to the TOU and/or using the Service, you represent and warrant that your use of the Service complies with applicable privacy laws and are permitted to share the personal data that you choose to share with Company, including but not limited to any information includes the personal data of Attendees and your employees, contractors, agents. If at any time you disagree with the Privacy Policy, you shall immediately stop use of the Service and contact Company at info&#64;villume.com.</p>
                    
                    <!-- TITLE DESCRIPTION 
                    ================================ -->
                    <h1 class="title-desc">2. Subscription</h1>

                    <p><span class="sub-title-desc">2.1 Subscription Fees.</span> Current Subscription types and associated terms are available at villume.com/pricing. The price of Subscriptions is displayed to you at the time of purchase and may include a monthly recurring fee (the “Fee”).  The Fee is charged in advance on the day of the month in which your Subscription commences (or such other date agreed to by the parties). Company may increase the Fee upon at least 14 days’ notice, such increase to take effect on the subsequent Fee billing cycle. </p>
                    
                    <p><span class="sub-title-desc">2.2 Payment.</span> You shall provide Company with a valid credit card or other method acceptable by Company, to be charged for the Fee on a recurring monthly basis (on the anniversary of your original purchase). You shall immediately notify Company of any change to your payment information. Company, or any third party acting on Company’s behalf, is authorized and has the right to automatically charge the Fee.</p>

                    <p><span class="sub-title-desc">2.3 Upgrading or Downgrading.</span> Your Subscription type may be upgraded by paying an additional Fee (prorated to the date of the month in which you upgraded and payable on the subsequent Fee billing cycle) or downgraded (with the downgraded Fee taking effect on the subsequent Fee billing cycle).</p>

                    <p><span class="sub-title-desc">2.4 Cancellation.</span> You may cancel a paid Subscription by providing Company at least 14 business days written notice to info&#64;villume.com, with “Cancellation” as the subject line. Upon cancellation: (a) you remain liable for all charges accrued up to the date of cancellation and you shall not receive a refund for any Fee already paid; and (b) access to your paid Subscription shall terminate.</p>

                    <p><span class="sub-title-desc">2.5 Taxes.</span> The Fee excludes taxes, duties and charges, which you shall pay as well. If Company pays or collects taxes on your behalf, you shall pay Company the applicable amount, which you shall pay on the same terms as the Fee.</p>

                    <p><span class="sub-title-desc">2.6 Failure to Pay.</span> Failure to timely pay the Fee will result in Company ceasing your access to your paid Subscription until you pay any outstanding Fee in arrears.  Company reserves the right to charge interest on any overdue Fee.</p>
                    
                    <!-- Exhibition Terms 
                    ================================ -->
                    <h1 class="title-desc">3. Exhibition Terms</h1>

                    <p><span class="sub-title-desc">3.1 Creation.</span> With a paid Subscription, the Service may allow you to directly, or with Company’s assistance, create Exhibitions and draft Exhibitions by adding images, photos, videos, text and other content that you submit, transmit or upload to the Service (“Content”).</p>

                    <p><span class="sub-title-desc">3.2 Access.</span> The Service may allow you access to Exhibitions created and hosted by other users.  Company does not guarantee that you shall have access to all Exhibitions or that Exhibitions provided in the past shall remain accessible in the future. </p>

                    <p><span class="sub-title-desc">3.3 Third Party Integrations.</span> You may integrate third party links and tools with Exhibitions to facilitate sale of art and other related goods (“Third Party Integrations”). You acknowledge and hereby agree that Company is not liable for any damage related to and arising from any Third Party Integrations and the use thereof. </p>

                    <p><span class="sub-title-desc">3.4 Representations and Warranties.</span> You represent and warrant, with respect to Content and Third Party Integrations, that such rights granted herein are free and clear, and that you have the full power to grant such rights, and that the Content and Third Party Integrations do not violate intellectual property rights, or any other right of any third party, nor will the use thereof by Company violate any such right.</p>


                    <!-- Limitations
                    ================================ -->
                    <h1 class="title-desc">4. Limitations on Use of the Service</h1>

                    <p><span class="sub-title-desc">4.1 Authorized Use.</span> You agree not to use the Service in the following manner:</p>
                    
                    <ol type="a">
                        <li>decompile, disassemble or reverse engineer the Service or otherwise attempt to derive the Service source code or gain unauthorized access to the Service; </li>
                        <li>alter, change or circumvent security related aspects of the Service;</li>
                        <li>use any automated system (robot, spider, etc.) to access the Service;</li>
                        <li>unless expressly permitted by Company, reproduce, rearrange, modify, change, alter, translate, create derivative works from, display, perform, publish, or distribute the Service;</li>
                        <li>break, disrupt or attempt to break or disrupt any software or system used to support the Service or experience of another person or knowingly exploit a flaw or bug in the Service;</li>
                        <li>use the Service in any manner that violates the TOU;</li>
                        <li>store or transmit material that is infringing, libellous, unlawful or in violation of any person’s rights;</li>
                        <li>harass, dox, abuse, stalk, threaten or impersonate any person or users on or with the Service;</li>
                        <li>promote, encourage or undertake illegal activity or communicate, link to, post, submit or upload content that contains objectionable or offensive conduct; or</li>
                        <li>infringe or violate third-party rights including but not limited to: (i) contractual rights; (ii) copyright, patent, trademark or trade secret rights; (iii) privacy rights; (iv) publicity rights; or (v) confidential information,</li>
                    </ol>

                    <p>as determined by Company in its sole discretion.</p>

                    <p><span class="sub-title-desc">4.2 DMCA.</span> Company responds to notices alleging copyright infringement that comply with the United States Digital Millennium Copyright Act (the “DMCA”).  If a party wishes to make a DMCA claim, the requesting party shall provide the following information in writing in the DMCA notice: </p>

                    <ol type="a">
                        <li>identify the copyrighted work being infringed;</li>
                        <li>identify the material claimed to be infringing and where it is located;</li>
                        <li>provide reasonably sufficient information to allow Company to contact the requesting party, such as an address, a phone number and an e-mail address;</li>
                        <li>provide a statement that the requesting party has a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent or law;</li>
                        <li>provide a statement, made under penalty of perjury, that the above information is accurate and that the requesting party is the copyright owner or are authorized to act on behalf of the owner; and</li>
                        <li>provide an electronic or physical signature of a person authorized to act on behalf of the copyright owner.</li>
                    </ol>

                    <p>Company may provide you notice if its communication, post, submission or upload was removed as a result of Company receiving a DMCA notice from a copyright owner.  If you receive notice from Company, you may provide a counter-notification in writing to Company’s designated DMCA agent through the means provided below.</p>

                    <p>DMCA notices can be sent to Company by emailing info&#64;villume.com or by mail to: VR Galleria Inc., 50 Halsey Ave, M4B 1A5, East York, Ontario, Canada.</p>

                    <!-- Limitations
                    ================================ -->
                    <h1 class="title-desc">5. Termination</h1>
                    
                    <p><span class="sub-title-desc">5.1 Term.</span>Unless otherwise specified in writing by Company, this TOU is effective beginning on the date accepted (the “Effective Date”) and continues until terminated (the “Term”).</p>

                    <p><span class="sub-title-desc">5.2 Suspension.</span>Company may suspend or terminate your access to the Service and/or Account, without notice or refund, at any time for any reason (or for no reason) including but not limited to technical issues or non-compliance with the TOU. Whether a suspension will be lifted is at the sole decision of Company. </p>

                    <p><span class="sub-title-desc">5.3 Termination.</span>The Term terminates upon the earliest of:</p>

                    <ol type="a">
                        <li>your failure to pay within seven days after receipt of notice for amounts overdue by 14 days; </li>
                        <li>your non-compliance with the TOU and Privacy Policy and failure to remedy such non-compliance after receiving notice;</li>
                        <li>at your option, pursuant to Section 2.4;</li>
                        <li>immediately, upon Company ceasing to offer the Service and that may occur at any time without notice; or</li>
                        <li>in the case your access to free aspects of the Service, at your option upon written notice delivered to info&#64;villume.com.</li>
                    </ol>

                    <p><span class="sub-title-desc">5.4 Effect of Suspension or Termination.</span> Upon suspension or termination, you acknowledge and agree that Company shall immediately cease your access to the Service. Company may store data related to your Account and associated data, including Content and Exhibitions that are hosted on the Service, for up to one year after the date of Termination after which Company may permanently delete all data related to your Account. Notwithstanding the foregoing, you acknowledge and agree that you are not entitled to a refund or other compensation of any kind resulting from such suspension or termination, including any loses sustained by the deletion of Content, Exhibitions and other data associated with your Account, regardless of whether effected by you or Company, and that Company shall not be liable to you or any third party for such suspension or termination and any loss related thereto.</p>

                    <!-- Ownership
                    ================================ -->
                    <h1 class="title-desc">6. Ownership Rights</h1>

                    <p><span class="sub-title-desc">6.1 Company’s Rights.</span> All right, title and interest in and to the Service including but not limited to copyrights, patents, trademarks, trade secrets, trade names, computer code (source and object) and Accounts, as well as templates, gallery templates, concepts, settings, and themes are owned by or licensed to Company. Company reserves all rights in the Service. For clarity, where Content is used to create an Exhibition, all right title and interest in such Exhibition (as a derivative work that includes such Content) belongs to Company. The TOU does not convey any right, title or interest in, or constitute the sale of any right to, the Service, any related products or an Account.</p>

                    <p><span class="sub-title-desc">6.2 Subscriber Rights; License.</span> You retain ownership of all right, title and interest in Content you provide to Company, subject to Company’s rights in Exhibitions pursuant to Section 6.1. By providing Content, you represent and warrant that you have all consents, licenses and rights necessary to provide and license Content and you grant Company a non-exclusive, irrevocable, fully-paid, royalty-free, transferrable, worldwide license to Content under all copyright, trademark, trade secret, patent, privacy and publicity rights and any other intellectual or industrial property rights you own or control to use, broadcast, disclose, display, distribute, publish, transmit or modify and arrange Content for implementing Content into Exhibitions and for the Service. Company reserves the right to review, edit, delete or block access to Content without notice. Company is under no obligation to review or act upon any Content that you may provide.</p>

                    <!-- Limitation of Liability and Indemnity
                    ================================ -->
                    <h1 class="title-desc">7. Disclaimer, Limitation of Liability and Indemnity</h1>

                    <p><span class="sub-title-desc">7.1 DISCLAIMER. </span> THE SERVICE IS PROVIDED TO YOU “AS IS” AND COMPANY, ITS SUBSIDIARIES, AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES, REPRESENTATIVES OR ASSIGNEES (COLLECTIVELY, “COMPANY PARTIES”), DISCLAIM ALL WARRANTIES AND CONDITIONS, EXPRESS, IMPLIED OR STATUTORY, INCLUDING WITHOUT LIMITATION MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT OF THIRD-PARTY RIGHTS, TO THE EXTENT PERMITTED BY LAW. WITHOUT LIMITING THE FOREGOING, COMPANY AND COMPANY PARTIES MAKE NO REPRESENTATIONS OR WARRANTIES THAT THE SERVICE WILL MEET YOUR REQUIREMENTS, COMPLY WITH THE SPECIFICATIONS OF YOUR COMPUTER OR MOBILE DEVICE, WILL NOT CONTAIN A VIRUS OR ALTERED CONTENT, OR THAT OPERATION OF THE SERVICE WILL BE UNINTERRUPTED, CONTINUOUSLY AVAILABLE, ERROR FREE, WILL NOT HARM COMPUTERS OR MOBILE DEVICES, RESULT IN LOST DATA, OR BE SECURE AGAINST UNAUTHORIZED ACCESS.  NO ORAL ADVICE OR WRITTEN INFORMATION PROVIDED BY COMPANY OR THE COMPANY PARTIES SHALL CREATE ANY WARRANTY AND YOU SHALL NOT RELY UPON SUCH ADVICE OR INFORMATION.  YOU BEAR THE ENTIRE RISK AS TO THE PERFORMANCE, OPERATION AND QUALITY OF THE SERVICE. </p>

                    <p><span class="sub-title-desc">7.2 LIMITATION OF LIABILITY. </span> COMPANY AND COMPANY PARTIES SHALL NOT BE LIABLE TO YOU FOR ANY CLAIM, LOSS OR DAMAGE OF ANY KIND ARISING OUT OF OR RELATING TO THE SERVICE OR THIRD-PARTY SERVICES INCLUDING WITHOUT LIMITATION DIRECT, CONSEQUENTIAL, INCIDENTAL OR SPECIAL DAMAGES, OR LOSS OF YOUR DATA, EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE OR LOSS.</p>

                    <p><span class="sub-title-desc">7.3 MAXIMUM AGGREGATE LIABILITY.</span> NOTE THAT SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OF CERTAIN TERMS OR WARRANTIES OR EXCLUSION OF LIABILITY FOR CERTAIN DAMAGES AND, IF ANY DISCLAIMER OR LIMITATION OF LIABILITY IS FOUND UNENFORCEABLE, VOID OR DOES NOT FULLY SHIELD COMPANY FROM LIABILITY, YOU AGREE THAT MAXIMUM AGGREGATE LIABILITY OF COMPANY AND COMPANY PARTIES IN ANY CASE WHATSOEVER WILL BE THE TOTAL AMOUNT PAID BY YOU TO COMPANY IN THE 12 MONTHS IMMEDIATELY PRECEDING THE DATE OF THE HARM IN QUESTION.</p>

                    <p><span class="sub-title-desc">7.4 INDEMNITY.</span> YOU REPRESENT AND WARRANT, WITH RESPECT TO CONTENT, THAT SUCH RIGHTS GRANTED TO COMPANY ARE FREE AND CLEAR, AND THAT YOU HAVE THE FULL POWER TO GRANT SUCH RIGHTS, AND THAT CONTENT DOES NOT VIOLATE INTELLECTUAL PROPERTY RIGHTS OR ANY OTHER RIGHT OF ANY THIRD PARTY, NOR WILL THE USE OF CONTENT BY COMPANY VIOLATE ANY SUCH RIGHT. YOU SHALL INDEMNIFY AND HOLD HARMLESS COMPANY AND COMPANY PARTIES FROM AND AGAINST ALL CLAIMS, DAMAGES, LOSSES AND EXPENSES, INCLUDING COURT COSTS AND REASONABLE ATTORNEYS’ FEES, ARISING OUT OF OR RESULTING FROM, AND, AT COMPANY’S OPTION, YOU WILL DEFEND COMPANY AND COMPANY PARTIES AGAINST ANY ACTION BY A THIRD PARTY AGAINST COMPANY THAT IS BASED ON A CLAIM THAT YOU, CONTENT, OR COMPANY OR COMPANY PARTIES’ USE THEREOF, INFRINGED, MISAPPROPRIATED OR VIOLATED A THIRD PARTY’S RIGHTS, INCLUDING BUT NOT LIMITED TO INTELLECTUAL PROPERTY RIGHTS.</p>

                    <!-- Limitation of Liability and Indemnity
                    ================================ -->
                    <h1 class="title-desc">8. General</h1>

                    <p><span class="sub-title-desc">8.1 No Joint Relationship.</span> Nothing in the TOU shall be construed to create any joint partnership, joint venture, employer-employee or agency relationship between you and Company.</p>

                    <p><span class="sub-title-desc">8.2 Governing Law.</span> The TOU and Privacy Policy are governed by the laws of the province of Ontario and the laws of Canada, without reference to principles of conflicts of laws. The parties irrevocably attorn to the jurisdiction of the appropriate provincial and federal courts of the province of Ontario to hear any proceedings related to the TOU or Privacy Policy.</p>

                    <p><span class="sub-title-desc">8.3 Severability and Waiver.</span> If any provision of the TOU or Privacy Policy is held by a court of competent jurisdiction to be invalid or unenforceable, such provision shall be changed and interpreted so as to best accomplish the objectives of the original provision to the fullest extent allowed by law with remaining provisions of the TOU in full force and effect.  No failure or delay by a party in exercising any right, power or remedy under the TOU constitutes a waiver.</p>

                    <p><span class="sub-title-desc">8.4 Force Majeure.</span> Except for any Fee payment obligations, neither you nor Company shall be liable for failure to perform any obligation under the TOU to the extent such failure is caused by a force majeure event (including acts of God, pandemics (including government-imposed recommendations and restrictions due to a pandemic), natural disasters, war, civil disturbance, action by a government entity, strike, and other causes beyond reasonable control).  The party affected by the force majeure event shall provide notice to the other party within a commercially reasonable time and shall use commercially reasonable efforts to resume performance as soon as practicable.  Obligations not performed due to a force majeure event shall be performed as soon as reasonably possible when the force majeure event concludes.</p>

                    <p><span class="sub-title-desc">8.5 Assignment.</span> Company may assign the TOU without your consent or notice to you. You cannot assign the TOU.</p>

                    <p><span class="sub-title-desc">8.6 Survival.</span> Sections 1.5, 5.3, 6, 7, and 8 survive termination of the TOU.</p>

                    <p><span class="sub-title-desc">8.7 Entire Agreement.</span> The TOU, together with the Privacy Policy, constitute the entire agreement between you and Company with respect to the subject matter hereof and supersedes any prior oral or written agreements, communications, representations or undertakings provided.</p>

                    <p class="mb50"><span class="sub-title-desc">8.8 Updates. </span> Company reserves the right to modify the TOU at any time (each, an “Update”) and shall make each Update available on the Website.  You are deemed to accept any Update by continuing to use the Service.  Unless Company states otherwise, an Update is automatically effective 30 days after posting on the Website.</p>
                </div>
                <!-- TITLE DESCRIPTION -->
            </div>
        </div>
        <div class="text-center mt50">
            <button class="btn btn-primary" onclick="history.back()"><i class="vi vi-arrow-left"></i> Back</button>
        </div>
    </div>
</app-layouts>