// Angular packages
import { Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';

// User-defined services
import { EditorService } from '../editor.service';
import { AlertMessageService } from 'src/app/components/alert-message/alert-message.service';
import { ImagesService } from './images.service';
import { ArtworkShadowsService, MainService } from 'src/app/shared/services';

// Messages
import { messages } from '@data';

// Third-party packages (npm)
import { cloneDeep } from "lodash";

// User-defined interfaces
import { Artwork } from 'src/app/shared/interfaces';

// User-defined components
import { MenuIconsComponent } from './menu-icons/menu-icons.component';
import { UndoRedoService } from 'src/app/shared/services/undo-redo.service';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { EditFrameComponent } from './edit-frame/edit-frame.component';
import { DialogModule } from 'primeng/dialog';
import { ImageInfoComponent } from './image-info/image-info.component';
import { RequestImageComponent } from './request-image/request-image.component';
import { RotationImageComponent } from './rotation-image/rotation-image.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputSwitchModule } from 'primeng/inputswitch';
import { AlignImageComponent } from './align-image/align-image.component';
import { LightingSettingComponent } from './lighting-setting/lighting-setting.component';
import { ScalingImageComponent } from './scaling-image/scaling-image.component';
import { MediaSupportComponent } from './media-support/media-support.component';
import { EditImageComponent } from './edit-image/edit-image.component';
import { ListImagesComponent } from './list-images/list-images.component';
import { AnimationSequanceComponent } from './animation-sequance/animation-sequance.component';
import { AddImageComponent } from './add-image/add-image.component';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'app-images',
    templateUrl: './images.component.html',
    styleUrls: ['./images.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgIf,
        AddImageComponent,
        AnimationSequanceComponent,
        ListImagesComponent,
        EditImageComponent,
        MediaSupportComponent,
        MenuIconsComponent,
        ScalingImageComponent,
        LightingSettingComponent,
        AlignImageComponent,
        NgClass,
        InputSwitchModule,
        FormsModule,
        RotationImageComponent,
        RequestImageComponent,
        ImageInfoComponent,
        DialogModule,
        EditFrameComponent,
        ReactiveFormsModule,
        ProgressSpinnerModule,
    ],
})
export class ImagesComponent implements OnInit {
	@ViewChild('menuIcons') private _menuIcons: MenuIconsComponent;

	constructor(
		public editorService: EditorService,
		public mainService: MainService,
		private _artworkShadowsService: ArtworkShadowsService,
		private _messageService: AlertMessageService,
		private _artworkService: ImagesService,
		private _undoRedoService: UndoRedoService
	) { }

	ngOnInit(): void {
		this._registerKeyboardEvent();
	}



	/**
	 * * UNSELECT ARTWORK *
	 * Todo: for unselect artwork
	 */
	public unselectArtwork(): void {
		if(this.editorService.artworkDataValid){
			this.editorService.showAlignLimit = false;
			this.editorService.bottomLimit.visibility = this.editorService.topLimit.visibility = 0;
			this._undoRedoService.clearInvalidStates();
			if (this.editorService.selectedExhibitAssets.length > 1) this.editorService.unselectMultiExhibitAssets();
			else this.editorService.unselectExhibitAsset();
		}else{
			this._messageService.add({
				severity: 'warn',
				summary: 'Warning',
				detail: messages.editor.global.invalidData.artwork
			})
		}
	}

	/**
	 * * OPEN FRAME EDITOR *
	 * Todo: for open frame editor
	 */
	public openEditFrame(): void {
		this.editorService.applyToAllFrameArtwork = false;
		this.editorService.editFrameMode = true;
		this.editorService.blockUserAction = true;
	}

	/**
	 *	* RE-CREATE ARTWORK AFTER EDIT FRAME *
	 * Todo: to re-create artwork after edit frame 
	 */
	public async recreateArtworkNode(artworkData: Artwork, oldFrame: any):Promise<void> {
		this.editorService.unselectExhibitAsset();
		const oldArtworkNode = this.editorService.scene.getTransformNodeByID('artwork-'+artworkData.id);
		const metadata = oldArtworkNode.metadata;
		const donut = oldArtworkNode["donut"];
		oldArtworkNode.dispose();

		this.editorService.artworkNodes = this.editorService.artworkNodes.filter((artworkNode:any)=> !artworkNode.isDisposed())

		const newArtworkNode = await this.editorService.createArtworkImageVideo(artworkData);
		newArtworkNode["metadata"] = metadata;
		newArtworkNode["donut"] = donut;
		newArtworkNode['donutHasInitialized'] = true;
		this._artworkShadowsService.addShadowCaster(newArtworkNode);
		this.editorService.correctingArtworkPosition(newArtworkNode, oldFrame);
		this.editorService.updateArtworkData(newArtworkNode, artworkData, false)
		this.editorService.markForUpdate(artworkData, 'shape');
	}

	/**
	 * * UPDATE ARTWORK FRAME *
	 * Todo: for update artwork frame after editing frame
	 * @param newArtworkData : Artwork
	 */
	public async updateArtworkFrame(newArtworkData: Artwork): Promise<void> {
		await Promise.all(this.editorService.artworks.map(async (artworkData: Artwork) => {
			if(artworkData.file_type != 'figure-object') {
				// Apply current frame setting to all artwork
				if(this.editorService.applyToAllFrameArtwork){
					const oldFrame = cloneDeep(artworkData.frame) 
					artworkData.frame = cloneDeep(newArtworkData.frame);
					artworkData.frame.id = oldFrame.id;
					await this.recreateArtworkNode(artworkData, oldFrame);
				}
				
				else{
					// Update frame setting of active artwork
					if(artworkData.id == newArtworkData.id){
						const oldFrame = cloneDeep(artworkData.frame) 
						artworkData.frame = cloneDeep(newArtworkData.frame);
						await this.recreateArtworkNode(artworkData, oldFrame);
					}
					
					else{
						// Update frame to default frame setting for frame has connetion to deleted frame template
						if(this.editorService.sidDeletedFrameTemplates.includes(artworkData.frame.sid_frame)){
							const oldFrame = cloneDeep(artworkData.frame) 
							artworkData.frame = cloneDeep(this.editorService.defaultFrameTemplate);
							artworkData.frame.id =  oldFrame.id;
							await this.recreateArtworkNode(artworkData, oldFrame);  
						}
	
						// Update frame setting for frame that has connection with edited frame template
						const editedFrameTemplate = this.editorService.editedFrameTemplates.find((x :any) => {
							return x.oldSidTemplate == artworkData.frame.sid_frame
						});
						if(editedFrameTemplate){
							const oldFrame = cloneDeep(artworkData.frame) 
							artworkData.frame = cloneDeep(editedFrameTemplate.frameData)
							artworkData.frame.sid_frame = editedFrameTemplate.newSidTemplate;
							artworkData.frame.id = oldFrame.id;
							await this.recreateArtworkNode(artworkData, oldFrame); 
						}
					}
				}
			}
		}));
		
		this._artworkService.selectArtwork(newArtworkData);
		this.editorService.dataHasChanges = true;
		this.editorService.editedFrameTemplates = [];
		this.editorService.sidDeletedFrameTemplates = [];
		this.editorService.updateUndoRedoState()
	}

	/**
	 * * REGISTER KEYBOARD EVENT FOR ARTWORK *
	 * Todo: for register keyboard event for artwork
	 */
	private _registerKeyboardEvent(): void {
		if (this.mainService.isBrowser) {
			window.addEventListener('keydown', this._keydownHandler.bind(this));
		}
	}

	/**
	 * * KEYDOWN HANDLER *
	 * Todo: for keydown handler
	 * @param event : KeyboardEvent
	 */
	private _keydownHandler(event: KeyboardEvent): void {
		if (!this.editorService.onInput && this.editorService.activeTab == 'images') {
			const isDeleteKeys = ['Backspace', 'Delete'].includes(event.key);
			const isEnterKey = event.key == 'Enter';

			if (isDeleteKeys && this.editorService.activeArtwork) {
				this._menuIcons.displayConfirmDelete = true;
			} 

			if (isEnterKey && this._menuIcons?.displayConfirmDelete) {
				if (!this._menuIcons?.onDeleting) {
					this._menuIcons.deleteArtwork();
				}
			} 
				
		}
	}
}
