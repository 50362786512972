// Angular packages
import { Component } from '@angular/core';

// User-defined services
import { EditorService } from '../../editor.service';
import { LoadingGalleryService } from 'src/app/components/loading-gallery/loading-gallery.service';
import { FormsModule } from '@angular/forms';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TooltipModule } from 'primeng/tooltip';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-animation-sequance',
    templateUrl: './animation-sequance.component.html',
    styleUrls: ['./animation-sequance.component.scss'],
    standalone: true,
    imports: [NgIf, TooltipModule, InputSwitchModule, FormsModule]
})

export class AnimationSequanceComponent {
  constructor(
    public editorService: EditorService,
    private _loadingGalleryService: LoadingGalleryService
  ) { }

  /**
   * * IS ANIMATION SEQUANCE DISABLED *
   * Todo: to check if animation sequance function is disabled
   * @returns 
   */
  public isAnimationSequanceDisabled(): boolean {
    return (
      this.editorService.blockUserAction || 
      this._loadingGalleryService.show
    )
  }
}
