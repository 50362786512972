<div 
    *ngIf="editorService.artworks.length"
    class="wrap-set-animation" 
    pTooltip="This function allows you to define the order of your automated virual gallery walk. When selected you can sort them (by pressing the play button in the viewer the visitor will be guided in a row from the top artwork to the bottom artwork in the end)." 
    tooltipPosition="right" 
    placeholder="Right">
    <p-inputSwitch 
        [disabled]="isAnimationSequanceDisabled()"
        [(ngModel)]="editorService.animationSequence"
        [styleClass]="isAnimationSequanceDisabled() ? 'mt0 input-switch-disable switch-lg' :  'mt0 switch-lg'">
    </p-inputSwitch>
    <div class="ml15">
        <h5 class="mb0" [style.color]="!editorService.animationSequence ? '#979797' : '#e7e7e7'">Set Animation Sequence <i class="vi vi-arrow-down ml5" [style]="{'font-size': '18px'}"></i></h5>
        <!-- <p class="desc" *ngIf="editorService.animationSequence">Sort your artworks from the top to the bottom = from the first to the last in your exhibition guide.</p> -->
    </div>
</div>