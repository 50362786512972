// Angular built-in components/methods
import { Component, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Router, RouterLink } from '@angular/router';

// User-defined services
import { MainService, store } from "src/app/shared/services";
import { LayoutsService } from '../layouts.service';
import { AlertMessageService } from 'src/app/components/alert-message/alert-message.service';
import { RegisterUserParams } from 'src/app/shared/interfaces';
import { NgClass, NgIf } from '@angular/common';


@Component({
    selector: 'app-sign-up',
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgClass,
        NgIf,
        RouterLink,
    ],
})
export class SignUpComponent implements AfterViewInit {
	public loading: boolean = false;
    public showPassword: boolean = false;
    public captchaVerifed: boolean = false;
    private grecaptchaToken: string = '';
    
    public form = new FormGroup({
        first_name: new FormControl('', [
            Validators.required,
            Validators.pattern('[a-zA-Z0-9 ]*')
        ]),
        last_name: new FormControl('', [
            Validators.required,
            Validators.pattern('[a-zA-Z0-9 ]*')
        ]),
        email: new FormControl('', [Validators.required,Validators.email]),
        password: new FormControl('', [
            Validators.required,
            Validators.minLength(6)
        ])
    });
    
    constructor(
        private alertMessageService: AlertMessageService,
        public mainService:MainService,
		private router:Router,
		public layoutsService:LayoutsService,
    ) {}

    ngAfterViewInit(): void {
        const recaptchaScript = `https://www.google.com/recaptcha/api.js?render=explicit`
        this.mainService.loadScripts([recaptchaScript]).then(() => {
            if (this.mainService.isBrowser) {
                this.mainService.initGrecaptcha({
                    elementId: 'captchaSignUp',
                    callback: (e) => {
                        this.captchaVerifed = true;
                        this.grecaptchaToken = e;
                    },
                    expiredCallback: () => {
                        this.captchaVerifed = false;
                    }
                });
            }
        });
    }

    /**
     * * REGISTER MANUAL *
     * Todo: register manual
     */
    register() {
        if (this.captchaVerifed) {
            this.loading = true;
            this.layoutsService.loadingProcess = true;
            const values = this.form.value;
            const params: RegisterUserParams = {
                email: values.email.toLowerCase(),
                first_name: values.first_name,
                last_name: values.last_name,
                password: values.password,
                token: this.grecaptchaToken
            }
            this.mainService.register(params).subscribe(response => {
                this.loading = false;
                this.layoutsService.loadingProcess = false;
                this.mainService.displayLoginDialog = false;
                
                localStorage.setItem("temporary_token", response['data'].token);
                localStorage.setItem('loginFrom', this.layoutsService.loginFrom);
                localStorage.setItem('productName', this.layoutsService.productName);
                localStorage.setItem('paymentType', this.layoutsService.paymentType);
                
                this.mainService.resetGrecaptcha();
                store.dispatch({type:"UPDATE_REGISTER_STATE", userRegisterState: Date.now()});
                
                window.location.href = "/welcome";
            },(error) => {
                this.mainService.resetGrecaptcha();
                this.loading = false;
                this.layoutsService.loadingProcess = false;
                this.alertMessageService.add({severity:'error', summary:'Register Failed', detail: error.error.data.errors.messages});
            });
        }
    }

    /**
     * * GET INPUT ERRORS *
     * todo: to get input errors
     * @param formControlName: string
     * @returns : Object
     */
    getErrors(formControlName: string) {
        return this.form.controls[formControlName].errors;
    }

    /**
     * * REMOVE SPACE AT BEGIN *  
     * @param formControlName 
     */
    removeSpaceAtBegin(formControlName: string) {
        const value = this.form.controls[formControlName].value;
        this.form.controls[formControlName].setValue(value.trimStart());
    }

	formControl() {return this.form.controls}
}
