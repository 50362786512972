<div class="wrap-name">
	<div class="wrap-title" [class.profile]="isProfile">
		<button *ngIf="isProfile" class="btn btn-light2 btn-sm" (click)="backOnProfile()">
			<i class="vi vi-arrow-left"></i>
		</button>
		<h2 class="title">
			Name your gallery
		</h2>
		<span class="step">{{createService.stepWizard}} step of 3</span>
	</div>

	<!-- EXHIBITION NAME -->
  <div class="wrap-input" [class.profile]="isProfile">
    <input
			#nameInput
			[(ngModel)]="name"
			(ngModelChange)="changeName()"
			(keydown)="createService.blockChars($event); createService.blockSpaceAtStart($event, nameInput)"
			type="text"
			class="form-control"
			placeholder="Add a name">
  </div>
</div>