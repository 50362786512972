<div class="wrapper-create" *ngIf="!previewGallery" [class.profile]="isProfile">
	<!-- navigation -->
	<div class="btn-nav">
		<button *ngIf="!isProfile" class="btn btn-light2 btn-sm" (click)="backAction()" [disabled]="onCreating">
			<i class="vi vi-arrow-left"></i>
			Back
		</button>
		<span class="step">
			{{createService.stepWizard}} step of 3
		</span>
	</div>

	<!-- content -->
	<div class="wrap-content offset16-2 col16-12 col-iped-8" [class.profile]="isProfile">
		<ng-container *ngIf="createService.stepWizard==1">
			<app-select-exhibition
				(exhibitionData)="getSelectedExhibition($event)"
				[isProfile]="isProfile"
				(loading)="loading=$event"
				(nextStep)="nextStep()">
			</app-select-exhibition>
		</ng-container>

		<ng-container *ngIf="createService.stepWizard==2">
			<app-name-exhibition
				[name]="exhibitionName"
				[isProfile]="isProfile"
				(exhibitionName)="exhibitionName=$event">
			</app-name-exhibition>
		</ng-container>

		<ng-container *ngIf="createService.stepWizard==3">
			<ng-container *ngIf="artworkData.length==0">
				<app-add-artwork
					(artworks)="artworkData=$event"
					[isProfile]="isProfile"
					[onCreating]="onCreating"
					[config]="exhibitionData?.config">
				</app-add-artwork>
			</ng-container>
			<ng-container *ngIf="artworkData.length>0">
				<app-list-artwork
					[artworks]="artworkData"
					[onCreating]="onCreating"
					[isProfile]="isProfile"
					(onValidate)="artworkDataValid=$event"
					(onRename)="artworkData = $event"
					(clearArtworks)="artworkData = $event">
				</app-list-artwork>
			</ng-container>
		</ng-container>
	</div>

	<!-- action general-->
	<div *ngIf="artworkData.length==0&&!loading" class="btn-action" [class.profile]="isProfile">
    <div class="gradiesnt"></div>
		<div class="wrap-btn-create">
			<small class="hint" *ngIf="onCreating">Please wait your gallery is being created</small>
			<!-- skip button on profile -->
			<button
				*ngIf="isProfile&&createService.stepWizard!=1"
				class="btn btn-light2 btn-sm"
				(click)="skipAction()"
				[disabled]="onCreating"
				[class.loading-button-light]="onCreating">
				Skip
			</button>
			<!-- skip button on widget -->
			<button
				*ngIf="!isProfile"
				class="btn btn-light2 btn-sm"
				(click)="skipAction()"
				[disabled]="onCreating"
				[class.loading-button-light]="onCreating">
				Skip
			</button>
			<button
				class="btn btn-primary btn-sm ml30"
				[hidden]="createService.stepWizard==3||(isProfile&&createService.stepWizard==1)"
				[disabled]="isExhibitionEmpty()||!exhibitionName && createService.stepWizard==2"
				(click)="nextStep()">
				Continue
				<i class="vi vi-arrow-right ml5"></i>
			</button>
		</div>
	</div>

	<!-- uploaded artworks -->
	<div *ngIf="artworkData.length>0" class="btn-action create" [class.profile]="isProfile">
		<div class="gradient"></div>
		<div class="wrap-btn-create">
			<small class="hint" *ngIf="onCreating">Please wait your gallery is being created</small>
			<button
				(click)="createExhibition()"
				[class.loading-button-primary]="onCreating"
				[disabled]="onCreating||!artworkDataValid && !onCreating"
				class="btn btn-primary btn-sm">
				Create My Gallery
			</button>
		</div>
	</div>
</div>

<ng-container *ngIf="previewGallery && shareString">
	<app-preview-exhibition
		[isProfile]="isProfile"
		[exhibitionID]="exhibitionID"
		[shareString]="shareString"
		[exhibitionName]="exhibitionName">
	</app-preview-exhibition>
</ng-container>

<canvas id="renderObject"></canvas>