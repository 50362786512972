<app-layouts #layouts>
    <div class="wrapper" content>
        <div class="carreer">
            <h3 class="carreer__title">Sorry, we are not hiring at the moment.</h3>
            <button class="btn btn-primary" (click)="back()">
                <i class="vi vi-arrow-left"></i>
                Back
            </button>
        </div>
    </div>
</app-layouts>