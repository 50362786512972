<div class="wrap-pagination">
    <ng-container *ngIf="page > 3 && totalPage > 4">
        <a class="page" (click)="changePage(1)">1</a>
        <span class="page">...</span>
    </ng-container>

    <a 
        *ngFor="let item of pages"
        class="page" 
        (click)="changePage(item)"
        [class.active]="item === page" >
        {{item}}
    </a>

    <ng-container *ngIf="page < totalPage - 2 && totalPage > 4">
        <span class="page">...</span>
        <a (click)="changePage(totalPage)" class="page">{{totalPage}}</a>
    </ng-container>
</div>