import { NgStyle } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';

@Component({
  selector: 'app-font-family',
  standalone: true,
  templateUrl: './font-family.component.html',
  styleUrl: './font-family.component.scss',
  imports: [
    NgStyle,
    DropdownModule,
    FormsModule,
  ],
})
export class FontFamilyComponent {

  @Input() fontFamily: string;
  @Input() fontWeight: string;

  @Output() fontFamilyChange: EventEmitter<any> = new EventEmitter();

  public selectedFontFamily: any = {};
  public selectedFontStyle: any = {};
  public fontStyle: any = [
    { name: 'Regular' },
    { name: 'Bold' },
    { name: 'Italic' },
    { name: 'Bold Italic' },
  ];

  public textFontFamilies = [
    { name:'Times New Roman', value: 'times_new_roman_cyrregular' },
    { name:'Arial', value: 'arial_narrowregular'  },
    { name:'Sora', value: 'soraregular' },
    { name:'Hahmlet', value: 'hahmletregular' },
    { name:'JetBrains Mono', value: 'jetbrains_monoregular' },
    { name:'Andada Pro', value: 'andada_proregular' },
    { name:'Epilogue', value: 'epilogueregular' },
    { name:'Inter', value: 'interregular' },
    { name:'Encode Sans', value: 'encode_sanscondensed_thin' },
    { name:'Manrope', value: 'manropeextralight' },
    { name:'Lora', value: 'loraregular' },
    { name:'BioRhyme', value: 'biorhymeextrabold' },
    { name:'Playfair Display', value: 'playfair_displayregular' },
    { name:'Archivo', value: 'archivosemibold' },
    { name:'Roboto', value: 'robotoregular' },
    { name:'Cormorant', value: 'cormorantlight' },
    { name:'Spectral', value: 'spectralregular' },
    { name:'Raleway', value: 'ralewaythin' },
    { name:'Work Sans', value: 'work_sansregular' },
    { name:'Lato', value: 'latoregular' },
    { name:'Anton', value: 'antonregular' },
    { name:'Old Standard TT', value: 'old_standard_ttregular' },
    { name:'Oswald', value: 'oswaldregular' },
    { name:'Montserrat', value: 'montserratthin' },
    { name:'Poppins', value: 'poppinsregular' },
    { name:'Nunito', value: 'nunitoextralight' },
    { name:'Source Sans Pro', value: 'source_sans_probold' },
    { name:'Oxygen', value: 'oxygenregular' },
    { name:'Open Sans', value: 'open_sansregular' },
    { name:'Space Mono', value: 'space_monoregular' },
    { name:'IBM Plex Sans', value: 'ibm_plex_sansregular' },
    { name:'Inconsolata', value: 'inconsolataregular' },
    { name:'Merriweather', value: 'merriweatherregular' },
    { name:'Quattrocento', value: 'quattrocentoregular' },
  ];

  ngOnInit() {
    this.selectedFontFamily = this.textFontFamilies.find((x) => x.value == this.fontFamily);
    this.selectedFontStyle = this.fontStyle.find((x:any) => x.name == this.fontWeight);
    this.editFontStyle();
  }

  ngOnChange() {
    this.selectedFontFamily = this.textFontFamilies.find((x) => x.value == this.fontFamily);
    this.selectedFontStyle = this.fontStyle.find((x:any) => x.name == this.fontWeight);
    this.editFontStyle();
  }

  ngAfterViewInit() {
    this.selectedFontFamily = this.textFontFamilies.find((x) => x.value == this.fontFamily);
    this.selectedFontStyle = this.fontStyle.find((x:any) => x.name == this.fontWeight);
    this.editFontStyle();
  }

  public editFontStyle() {
    this.fontFamilyChange.emit({fontFamily:this.selectedFontFamily, fontWeight: this.selectedFontStyle});
  }
}
