import { Component, OnInit, Input } from '@angular/core';
import { MainService } from 'src/app/shared/services';

@Component({
    selector: 'app-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.scss'],
    standalone: true
})
export class HelpComponent implements OnInit {
  public visibleSidebar:boolean = false;

  @Input () showHelp: boolean = false;

  constructor(
    public mainService: MainService
  ) { }

  ngOnInit(): void {}

}
