<div class="button-text-definition">
  <div class="wrap-title-figure">
    <input 
      type="text" 
      class="name-text"
      (focus)="editorService.onInput=true"
      (blur)="editorService.onInput=false"
      maxlength="50" 
      (input)="editTextDefinition()" 
      [formControl]="textService.textDefinition"
      [readonly]="editorService.blockUserAction"
      (keydown)="mainService.blockChars($event)">
  </div>
  <div *ngIf="textService.textDefinition.errors" class="validation-error">
    <p *ngIf="textService.textDefinition.errors?.required">Text definition is required.</p>
    <p *ngIf="textService.textDefinition.errors?.pattern">Special characters are not allowed</p>
  </div>
</div>