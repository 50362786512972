<div *ngIf="showNotSubs" class="dialog-mask"></div>
<p-dialog 
    [(visible)]="showNotSubs"
    [style]="{ width: '725px' }"
    [draggable]="false"
    [resizable]="false"
    [modal]="true"
    [baseZIndex]="10000"
    [closable]="false"
    styleClass="notSubsDialog">
    <ng-template pTemplate="header">
        <span class="text-xl font-bold">{{textContent[subs].title}}</span>
        <button class="btn-close"  (click)="closeDialog()"><i class="vi vi-x"></i></button>
    </ng-template>
    <p>
        {{textContent[subs].message}}
    </p>
    <ng-template pTemplate="footer">
        <button class="button button-cancel" (click)="closeDialog()">Cancel</button>
        <button class="button button-subs" (click)="subscribe()">Subscribe</button>
    </ng-template>
</p-dialog>
