import { Tips } from '../interfaces';

export const tips: Tips[] = [
  {
    featureName: 'Feature 1',
    position: 'bottom-middle-aligned',
    title: 'Tip 1 of 6',
    selector: '.add-artwork',
    intro: 'Click here to upload an artwork',
  },
  {
    featureName: 'Feature 2',
    position: 'bottom-middle-aligned',
    title: 'Tip 2 of 6',
    selector: '.tab-menu',
    intro: 'To add artworks, 3D-objects, and text, as well as to change gallery styles and settings, simply switch between the different tabs.',
  },
  {
    featureName: 'Feature 3',
    position: 'bottom-middle-aligned',
    title: 'Tip 3 of 6',
    selector: '.gallery-name',
    intro: 'Click here to change the gallery name',
  },
  {
    featureName: 'Feature 4',
    position: 'bottom-middle-aligned',
    title: 'Tip 4 of 6',
    selector: '.save-gallery',
    intro: "Click the 'Save' button to save any updates you've made to your gallery. It will remain hidden from other users until you click the 'Publish' button to make it visible online.",
  },
  {
    featureName: 'Feature 5',
    position: 'bottom-middle-aligned',
    title: 'Tip 5 of 6',
    selector: '.publish-gallery',
    intro: "To make your gallery accessible to others, click the 'Publish' button. After publishing, any changes made to the gallery will be automatically updated when you press the 'Save' button.",
  },
  {
    featureName: 'Feature 6',
    position: 'top-middle-aligned',
    title: 'Tip 6 of 6',
    selector: '.responsive-gallery',
    intro: 'Click here to check how your gallery looks on different devices',
  }
];

