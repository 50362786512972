import { Routes } from "@angular/router";
import { HomeComponent } from "./layouts/main/main.component";
import { ModelTesterComponent } from "./pages/virtual-gallery/model-tester/model-tester.component";
import { WelcomeComponent } from "./pages/profile/welcome/welcome.component";
import { UserUpdateComponent } from "./pages/profile/user-update/user-update.component";
import { AuthGuard } from "./shared/route-guards/auth.guard";
import { UserUpdateGuardService } from "./shared/route-guards/user-update.guard";
import { VerificationComponent } from "./pages/profile/verification/verification.component";
import { DeleteAccountComponent } from "./pages/profile/delete-account/delete-account.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { CareersComponent } from "./pages/careers/careers.component";
import { AboutComponent } from "./pages/about/about.component";
import { TutorialsComponent } from "./pages/tutorials/tutorials.component";
import { PrivacyComponent } from "./pages/privacy/privacy.component";
import { ServiceComponent } from "./pages/service/service.component";
import { ForgotPasswordComponent } from "./pages/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./pages/reset-password/reset-password.component";
import { ResetTokenComponent } from "./pages/reset-password/reset-token/reset-token.component";
import { SupportComponent } from "./pages/support/support.component";
import { AuthSupportGuard } from "./shared/route-guards/auth-support.guard";
import { SaveInvoicesComponent } from "./pages/pricing/save-invoices/save-invoices.component";
import { GenerateTokenEmailComponent } from "./pages/settings/billing/generate-token-email/generate-token-email.component";
import { CreateExhibitionComponent } from "./components/create-exhibition/create-exhibition.component";
import { PromocodeListComponent } from "./pages/promocodes/promocode-list/promocode-list.component";
import { PromocodeFormComponent } from "./pages/promocodes/promocode-form/promocode-form.component";
import { SettingsComponent } from "./pages/settings/settings.component";
import { ProfileSettingComponent } from "./pages/settings/profile-setting/profile-setting.component";
import { AccountSettingComponent } from "./pages/settings/account-setting/account-setting.component";
import { NotificationsSettingComponent } from "./pages/settings/notifications-setting/notifications-setting.component";
import { BillingComponent } from "./pages/settings/billing/billing.component";
import { CatalogueComponent } from "./pages/catalogue/catalogue.component";
import { PricingComponent } from "./pages/pricing/pricing.component"
import { InformationComponent } from "./pages/profile/information/information.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { GalleryMaintenanceComponent } from "./pages/gallery-maintenance/gallery-maintenance.component";
import { EditorComponent } from "./pages/virtual-gallery/editor/editor.component";
import { ProfileComponent } from "./pages/profile/profile.component";
import { CreateComponent } from "./pages/profile/create/create.component";
import { environment } from "@environments";
import { SubscribeFromEmailComponent } from "./pages/pricing/subscribe-from-email/subscribe-from-email.component";
import { PaymentSuccessComponent } from "./pages/pricing/payment-success/payment-success.component";


export const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "model-tester", component: ModelTesterComponent },
  { path: "welcome", component: WelcomeComponent },
  { path: "user-update", component: UserUpdateComponent, canActivate: [AuthGuard, UserUpdateGuardService] },
  { path: "verification", component: VerificationComponent },
  { path: "delete-account", component: DeleteAccountComponent },
  { path: "contact", component: ContactComponent },
  { path: "careers", component: CareersComponent },
  { path: "about", component: AboutComponent },
  { path: "help", component: TutorialsComponent, data: { sideMenu: true } },
  { path: "tutorials", component: TutorialsComponent },
  { path: "privacy-cookie-policy", component: PrivacyComponent },
  { path: "term-of-service", component: ServiceComponent },
  { path: "forgot-password", component: ForgotPasswordComponent },
  { path: "reset-password", component: ResetPasswordComponent },
  { path: "resettoken", component: ResetTokenComponent },
  { path: "support", component: SupportComponent, canActivate: [AuthSupportGuard] },
  { path: "save-invoices", component: SaveInvoicesComponent },
  { path: "redirect-to-billing", component: GenerateTokenEmailComponent },
  { path: "confirm-payment", component: SubscribeFromEmailComponent },
  { path: "create-exhibition", component: CreateExhibitionComponent, data: { sideMenu: true } },
  {
    path: "promocodes", canActivate: [AuthSupportGuard], children: [
      { path: "", component: PromocodeListComponent },
      { path: "add", component: PromocodeFormComponent, data: { action: 'add' } },
      { path: "edit/:code/:promo_id", component: PromocodeFormComponent, data: { action: 'edit' } },
    ]
  },
  {
    path: "settings", component: SettingsComponent, data: { sideMenu: true }, children: [
      { path: "", redirectTo: "profile", pathMatch: "full" },
      { path: "profile", component: ProfileSettingComponent, canActivate: [AuthGuard] },
      { path: "account", component: AccountSettingComponent, canActivate: [AuthGuard] },
      { path: "notifications", component: NotificationsSettingComponent, canActivate: [AuthGuard] },
      { path: "billing", component: BillingComponent, canActivate: [AuthGuard] },
    ]
  },
  { path: "catalogue", component: CatalogueComponent },
  { 
    path: "pricing", children: [
        { path: "", component: PricingComponent },
        { path: "success", component: PaymentSuccessComponent },
    ] 
  },
  { path: "404", component: NotFoundComponent },
  { path: "maintenance", component: GalleryMaintenanceComponent },
  { path: "editor/:id", component: EditorComponent, canActivate: [AuthGuard] },
  { path: ":username", component: ProfileComponent, data: { sideMenu: true } },
  { path: ":username/info", component: InformationComponent, data: { sideMenu: true } },
  { path: ":username/create", component: CreateComponent, data: { sideMenu: true } },
  { path: "**", redirectTo: environment.base_host+"/404", pathMatch: 'full' }
];
