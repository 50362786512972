// Angular built-in components/methods
import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from "@angular/router";

// User-defined services
import { SsrCookieService as CookieService } from 'ngx-cookie-service-ssr';
import { MainService } from "src/app/shared/services";
import { AlertMessageService } from 'src/app/components/alert-message/alert-message.service';
import { EditorService } from 'src/app/pages/virtual-gallery/editor/editor.service';
import { LayoutsService } from '../layouts.service';
import { ProfileService } from 'src/app/pages/profile/profile.service';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgClass,
        NgIf,
    ],
})
export class LoginComponent {
  public loading: boolean = false;
  public isSubmit:boolean = false;
  public auth2:any;
  public formType: string;
  public showPassword:boolean = false;
  public form = new FormGroup({
    email_username: new FormControl('', [
      Validators.required
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(6)
    ])
  });


  constructor(
    public alertMessageService: AlertMessageService,
    public mainService: MainService,
    public layoutsService: LayoutsService,
    private _router: Router,
    private _editorService: EditorService,
    private _profileService: ProfileService,
    private _cookieService: CookieService,
  ) {}

  /**
   * * LOGIN  *
   * Todo: to login
   */
  login() {
    localStorage.removeItem("temporary_token");

    this.enableLoading(true);
    this.mainService.auth(this.form.value).subscribe( response => {
      if (response['data'].is_email_verified){
        this.handleVerifedAccount(response['data']);
      } else {
        this.handleUnverifedAccount(response['data'].token);
      }
    }, (err) => {
      if (err.name == "TimeoutError") {
        this.login();
      } else {
        this.enableLoading(false);
        this.alertMessageService.add({severity:'error', summary:'Login failed', detail: err.error.data.errors.messages});
      }
    });
  }  
  
  /**
   * * HANDLE UNVERIFIED ACCOUNT *
   * Todo: to handle unverified account
   */
  handleUnverifedAccount(tempToken: string) {
    localStorage.setItem("temporary_token", tempToken);
    localStorage.setItem('loginFrom', this.layoutsService.loginFrom);
    localStorage.setItem('productName', this.layoutsService.productName);
    localStorage.setItem('paymentType', this.layoutsService.paymentType);
    
    this.enableLoading(false);
    this.mainService.displayLoginDialog = false;
    window.location.href = "/welcome";
  } 

  /**
   * * HANDLE VERIFED ACCOUNT *
   * Todo: to handle verifed account
   */
  handleVerifedAccount(responseData: any) {
    this.layoutsService.updateToken(responseData.token, responseData.exp).subscribe(async (resToken:any) =>{
      if(responseData.is_welcome){
        localStorage.setItem('loginFrom', this.layoutsService.loginFrom);
        localStorage.setItem('productName', this.layoutsService.productName);
        localStorage.setItem('paymentType', this.layoutsService.paymentType);
        window.location.href = "/user-update";
      }else{
        const isSameUser = await this.layoutsService.checkIsSameUser()
        this._editorService.onInput = false;
        if(isSameUser){
          this.mainService.displayLoginDialog = false;
          this.enableLoading(false);
        }else{
          if(this.layoutsService.loginFrom == 'pricing-page') {
            this.handleLoginViaPricingPage();
          } else if (this.layoutsService.loginFrom == 'catalogue-page') {
            this.handleLoginViaCatalogue(responseData.username);
          } else {
            window.location.href = "/" + responseData.username;
          }
        }
      }
    })
  }

  /**
   * * HANDLE LOGIN VIA PRICING PAGE *
   * Todo: to handle login via pricing page
   */
  handleLoginViaPricingPage() {
    if (this.layoutsService.productName !== 'experimental') {
      window.location.href = `/pricing/${this.layoutsService.productName.replace('_test','')}?pay=${this.layoutsService.paymentType}`;
    }else {
      window.location.href = "/settings/billing";
    }
  }

  /**
   * * HANDLE LOGIN VIA CATALOGUE PAGE *
   * Todo: to handle login via catalogue page
   */
  handleLoginViaCatalogue(username:string): void {
    const dataExhibition = this.layoutsService.dataExhibition;
    this._profileService.validateCreateAfterLogin().then(() => {
      if (this.mainService.isBrowser) {
        this._cookieService.set('createExhibition', dataExhibition.folder_name);
        window.location.href = "/"+username+"/create";
      }
    }).catch((err) => {
      if (err.error.statusCode === 403) {
        if (this.mainService.isBrowser) {
          this._cookieService.set('validateCreate', 'true')
          window.location.href = '/'+username;
        }
      }
    });
  }

  /**
   * * ENABLE/DISABLE LOADING *
   * Todo: to enable/disable loading
   */
  enableLoading(enable: boolean) {
    this.loading = enable;
    this.layoutsService.loadingProcess = enable;
  }


  forgotPassword(){
    this._router.navigate(['forgot-password']);
    this.mainService.displayLoginDialog = false;
    this.layoutsService.showMenu = false;
  }
}
