import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LayoutsService } from 'src/app/layouts/layouts.service';
import { MainService } from '@services';

@Component({
    selector: 'app-generate-token-email',
    templateUrl: './generate-token-email.component.html',
    styleUrls: ['./generate-token-email.component.scss'],
    standalone: true
})
export class GenerateTokenEmailComponent implements OnInit {
  private _tvParams: string;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _layoutsService: LayoutsService,
    private _mainService: MainService
  ) { 
    this._tvParams = this._route.snapshot.queryParams?.tv;
  }
  
  ngOnInit() {
    this.loginFromToken();
  }

  async loginFromToken() {
    const body = { 'e&': this._tvParams }
    this._mainService.getUserInfoWithToken(body).subscribe({
      next: (res: any) => {
        const token = res.data.token;

        this._layoutsService.updateToken(token).subscribe(() =>{
          window.location.href = "/settings/billing";
        });
      },
      error: (err: any) => {
        this._router.navigate(['/404']);
      }
    });
  }
}
