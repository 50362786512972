@import "../../../../assets/scss/common/variables";

.wrap-set-profile{
    max-width: 514px;
    padding: 80px 0 214.5px;
    margin: 0 auto;

    @media (max-width: $desktop-screen){
        padding: 60px 0 276.5px;
        max-width: 514px;
    }

    @media (max-width: $large-screen){
        padding: 50px 0 301.5px;
    }

    @media (max-width: $medium-screen){
        margin: 0 auto !important;
        padding: 57px 0 198.5px;
    }

    @media (max-width: $small-screen){
        padding: 40px 15px 143px 15px;
        max-width: 100%;
    }

    .wrap-image-set{
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        @media (max-width: $small-screen) {
            flex-direction: column;
            row-gap: 30px;
        }

        img{
            width: 98px;
            height: 98px;
            margin-right: 20px;
            border-radius: 100%;
            object-fit: cover;

            @media (max-width: $small-screen) {
                margin-right: 0;
            }
        }

        button{
            padding: 3px 30px;
            padding-top: 5px;
            height: 50px;

            @media (max-width: $small-screen){
                font-size: 14px;
                height: 40px;
            }
        }
    }

    .form-group{
        margin-bottom: 0;

        .form-control {
            &:focus{
                background-color: $color-gray3;
            }
        }

        &.w-50{
            width: 52% !important;

            @media (max-width: $small-screen){
                width: 75% !important;
            }

            &.mr20{
                @media (max-width: $small-screen){
                    margin-right: 10px !important;
                }
            }
        }

        .checkmark {
            position: relative;
            display: inline-block;
            background-color: #777;
            height: 12px;
            width: 12px;
            border-radius: 2px;
        }

        .action-social{
            display: flex;
            align-items: center;
            margin-right: 20px;
        }

        .info-url{
            color: $color-gray4;
            font-size: 16px;
            line-height: 125%;
            margin-top: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .selected-option{
            display: flex;
            align-items: center;

            >ul {
                padding-left: 10px;
                display: flex;
                margin: auto 0;
        
                >li{
                    list-style: none;
                    margin: 0 10px;

                    label{
                        margin-left: 5px;
                        margin-bottom: 0;
                    }
                }
            }
        }

        .info {
            font-size: 16px;
            color: $color-gray1;

            @media (max-width: $small-screen){
                display: none;
            }
        }

        label {
            @media (max-width: $small-screen){
                font-size: 14px;
            }
        }
    }

    .submit {
        padding: 3px 30px 0;
        height: 50px;

        @media (max-width: $small-screen){
            font-size: 14px;
            height: 40px;
        }
    }
}

.form-control {
    height: 45px;

    @media (max-width: $small-screen){
        height: 40px;
        font-size: 14px;
    }

    &.textarea {
        height: 140px;
        resize: none;

        @media (max-width: $small-screen){
            height: 112px;
        }
    }
}