<div class="wrap-create" [class.from-side-menu]="fromSideMenu">
	<div class="wrap-title">
		<h2 class="title">
			Choose the perfect space for your artworks
			<span class="sub"> and start creating:</span>
		</h2>
		<span *ngIf="!fromSideMenu"class="step">1 step of 3</span>
	</div>

	<!-- EXHIBITION CATALOGUE -->
	<div *ngIf="!onLoading" class="wrap-item" [class.loading]="onLoading">
		<p-scrollPanel [styleClass]="isProfile?'profile':''+'select-exhibition'">
		<div class="wrap-exhibition" [ngStyle]="{paddingBottom:isProfile?'230px':'0px'}">
			<div class="item-exhibition" *ngFor="let item of catalogues">
				<!-- START ITEM EXHIBITON
					=================================== -->
				<div class="wrap-image">
					<a href="javascript:;" (click)="selectExhibition(item)">
						<div class="overlay" [class.active]="item.selected" [class.profile]="isProfile"></div>
						<img src="{{env.staticAssets}}images/other/skeleton-landscape.svg?t={{mainService.appVersion}}"
							[lazyLoad]="item.thumbnail + '?t='+ mainService.appVersion" class="img-exhibition">
					</a>
				</div>
				<div class="content-exhibition">
					<p class="title">
						<a href="javascript:;" (click)="selectExhibition(item)">
							{{item.name}}
						</a>
					</p>
					<p class="desc">
						{{item.description}}
					</p>
				</div>
			</div>
		</div>
		</p-scrollPanel>
		<!-- END ITEM EXHIBITON -->

	</div>
	<!-- MESSAGE DATA NOT FOUND -->
	<div class="col-12 text-center" *ngIf="!catalogues?.length && !onLoading">
		<div>Data Not Found</div>
	</div>

	<!-- LOADING CONTENT -->
	<div class="col-12 wrap-loading" *ngIf="onLoading">
		<p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="5"
			animationDuration=".5s"></p-progressSpinner>
	</div>
</div>