import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments';
import { GenerateStripeTokenParams, SubscribePricingParams } from 'src/app/shared/interfaces';
import { MainService } from 'src/app/shared/services';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubscribePricingService {

  public subscribeData;
  constructor(
    private _http: HttpClient,
    private mainService: MainService
  ) {}

  /**
   * * GET PRODUCT DETAIL *
   * Todo: to get product detail
   * @param type : 'basic' | 'professional' | 'premium' -> product type
   * @returns : Observable<any> -> http observable
   */
  public getProductDetail(type: 'basic' | 'professional' | 'premium'): Observable<any> {
    return this._http.get(`${environment.baseURL}/billing/product-detail`, {
      params: { type }
    });
  }

  /**
   * * GET CARD LIST *
   * Todo: to get card list
   * @returns : Observable<any> -> http observable
   */
  public getCards(): Observable<any> {
    return this._http.get(`${environment.baseURL}/billing/list-card`);
  }

  /**
   * * GET COUNTRIES *
   * Todo: to get countries
   * @returns : Observable<any> -> http observable
   */
  public getCountries(): Observable<any> {
    const baseUrl = window.location.origin;
    return this._http.get(`${environment.staticAssets}/json/countries.json?t=${this.mainService.appVersion}`);
  }
  
  /**
   * * GET STATES *
   * Todo: to get states
   * @returns : Observable<any> -> http observable
   */
  public getStates(countryCode: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.get(`${environment.staticAssets}/json/states.json?t=${this.mainService.appVersion}`).subscribe((states: any) => {
        const findState = states.filter((state: any) => state.country_code === countryCode);
        resolve(findState);
      }, err => {
        reject(err);
      });
    })
  }

  /**
   * * GENERATE STRIPE TOKEN *
   * Todo: to generate stripe token
   * @param data: GenerateStripeTokenParams
   * @returns : Http Observables
   */
  public generateStripeToken(data: GenerateStripeTokenParams) {
    return this._http.post(environment.base_host.concat('/api/generate-stripe-token'), data)
  }

  /**
   * * ADD CUSTOMER *
   * @param data : SubscribePricingParams
   * @param product : 'basic' | 'professional' | 'premium' -> product type
   * @returns : Http Observables
   */
  public addCustomer(data: SubscribePricingParams, product: 'basic' | 'professional' | 'premium') {
    return this._http.post(`${environment.baseURL}/billing/add-customer?type=${product}`, data)
  }

  public validateCoupon(coupon: string, product: 'basic' | 'professional' | 'premium') {
    return this._http.post(`${environment.baseURL}/billing/check-coupon`, { coupon, type: product })
  }

  public subscribe() {
    return this._http.post(`${environment.baseURL}/billing/subscribe`, this.subscribeData)
  }

  /**
   * ANCHOR Save Invoice To Server
   * @description save invoice from stripe to server
   * @returns : Http Observables
   */
  public saveInvoiceToServer(): Observable<any> {
    return this._http.post(`${environment.baseURL}/billing/download-invoice`, {})
  }
}
