import { Injectable } from '@angular/core';
import { MainService } from 'src/app/shared/services';

@Injectable({
  providedIn: 'root'
})
export class AddImageService {
  public displayAddPopup: boolean = false;

  constructor(private _mainService: MainService) { }
  
  /**
   * ANCHOR Get Artwork Type
   * @description to get artwork type
   * @param file : File -> artwork file
   * @returns : 'image' | 'object'
   */
  public getArtworkType(file: File): 'image' | 'object' | 'other' {
    const extension = this._mainService.getFileExtension(file);
    const imageFormat = ['png', 'jpg', 'jpeg'];
    if (extension == 'glb') return 'object';
    if (imageFormat.includes(extension)) return 'image';
    return 'other';
  }

  /**
   * ANCHOR Generate Random String
   * @param length : number -> length of string
   * @returns : string -> random string
   */
  public generateRandomString(length): string {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let result = '';
  
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
  
    return result;
  }
}
