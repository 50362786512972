<div 
    @fadeAnimation
    *ngIf="showMessage" 
    class="action-message" 
    [class.error]="type=='error'"
    [class.warning]="type=='warn'"
    [class.success]="type=='success'"
    [class.info]="type=='info'"
    [class.suggest]="type=='suggest'">
    <a href="javascript:;" class="close-icon" (click)="showMessage=false"><i class="vi vi-x"></i></a>
    <h5 *ngIf="type!='suggest'" class="title-message">
        {{title}}
    </h5>
    <div *ngIf="type=='suggest'" class="wrap-message">
        <img class="icon" src="{{env.staticAssets}}images/icon/chrome.svg?t={{mainService.appVersion}}" alt="">
        <p class="message">{{message}}</p>
    </div>
    <p *ngIf="type!='suggest'" class="message">{{message}}</p>
</div>