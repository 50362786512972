<ng-container *ngIf="editorService.activeArtwork.request_artwork as requestData">

    <div class="switch-artwork-settings" [ngClass]="requestData.request_button ? 'mb30' : 'mb15'">
        <p-inputSwitch 
            [disabled]="editorService.blockUserAction"
            [styleClass]="editorService.blockUserAction ? 'input-switch-disable switch-lg mt0' : 'switch-lg mt0'"
            (onChange)="updateRequestVisibility()" 
            [(ngModel)]="requestData.request_button">
        </p-inputSwitch>
        <label class="ml15 mb0">Artwork Info Button</label>
    </div>
    
    <div class="wrap-info-artwork" *ngIf="requestData.request_button">
        <!-- BUTTON REQUEST
        ========================= -->
        <div class="text-center mb25">
            <button 
                [disabled]="isRequestDisabled()" 
                (click)="requestArtwork()"
                class="btn btn-outline-primary btn-sm">
                {{ requestData.request_text_button }}
            </button>
        </div>
        <div class="content-info-artwork">
            <div class="mb20">
                <!-- EDIT TEXT BUTTON REQUEST
                =================================== -->
                <div class="form-group side-by-side">
                    <label class="label-content-info">Your Text: </label>
                    <input 
                        #inputName
                        (focus)="editorService.onInput = true" 
                        (blur)="editorService.onInput = false" 
                        maxlength="20"
                        [formControl]="requestArtworkText" 
                        (input)="handleValueChangesInputText('buttonText')"
                        type="text" 
                        class="form-control bg-dark md flex-fill" 
                        (keydown)="mainService.blockChars($event);mainService.blockSpaceAtStart($event, inputName)" />
                </div>
    
                <!-- ERROR MESSAGE FOR TEXT BUTTON INPUT
                ================================================= -->
                <div *ngIf="requestArtworkText.errors as errors" class="validation-error">
                    <p *ngIf="errors?.required">Text is required.</p>
                    <p *ngIf="errors?.maxlength">Maximum length is 20</p>
                    <p *ngIf="errors?.pattern">Text cannot contain special characters.</p>
                </div>
            </div>
    
            <!-- REQUEST VIA LINK
            =========================== -->
            <div class="form-group side-by-side" [class.mb20]="!requestLink.errors">
                <div class="label-content-info d-flex align-items-center">
                    <input [checked]="requestData.request_via_link" type="checkbox">
                    <label (click)="changeRequestType('link')">Link:</label>
                </div>
                <input 
                    (focus)="editorService.onInput=true" 
                    (blur)="editorService.onInput=false"
                    (input)="handleValueChangesInputText('website')"
                    [formControl]="requestLink" 
                    type="text"
                    class="form-control bg-dark md flex-fill" 
                    (keydown)="mainService.blockChars($event)" />
            </div>
            <div *ngIf="requestLink.errors as errors" class="validation-error mb15">
                <p *ngIf="errors.required">Link is required</p>
                <p *ngIf="!errors.required && errors.pattern">Link is invalid</p>
            </div>
            <div class="mb20">
                <!-- REQUEST VIA EMAIL
                =========================== -->
                <div class="form-group side-by-side">
                    <div class="label-content-info d-flex align-items-center">
                        <input type="checkbox" [checked]="requestData.request_via_email">
                        <label (click)="changeRequestType('email')">Email:</label>
                    </div>
                    <input 
                        (focus)="editorService.onInput=true" 
                        (blur)="editorService.onInput=false"
                        (input)="handleValueChangesInputText('email')"
                        [formControl]="requestEmail" 
                        type="text"
                        class="form-control bg-dark md flex-fill" 
                        (keydown)="mainService.blockChars($event)" />
                </div>
                <!-- ERROR MESSAGE FOR TEXT BUTTON INPUT
                ================================================== -->
                <div *ngIf="requestEmail.errors as errors" class="validation-error">
                    <p *ngIf="errors.required">Email is required</p>
                    <p *ngIf="!errors.required && errors.email">Invalid Email</p>
                </div>
            </div>
        </div>
    
        <!-- APPLY TO ALL ARTWORK 
      ============================== -->
        <div class="text-right">
            <button [disabled]="isApplyAllChangesDisabled()" (click)="applyChangesToAll()"
                class="btn btn-outline-primary btn-sm btn-apply">
                Apply to All Artworks
            </button>
        </div>
    </div>
</ng-container>