<div class="content-list">
  <button 
      [disabled]="disabled" 
      (click)="onBack.emit()" 
      class="btn btn-light2 btn-sm">
      <i class="vi vi-arrow-left"></i>
  </button>
  <p-scrollPanel [style]="{height: '383px'}">
    <div class="wrap-multi">
        <ul class="wrap-item" [formGroup]="artworkNames">
            <li *ngFor="let controlName of getControlNames(); let i = index" class="list-item">
                <div class="item" *ngIf="artworkNames.get(controlName) as control">
                    <img class="thumb" [src]="files[i]?.thumbnailUrl">
                    <img
                        *ngIf="false"
                        class="badge3d"
                        src="{{env.staticAssets}}images/other/badge3D.png?t={{mainService.appVersion}}" alt="">
                    <input
                        [class.border-0]="control.value"
                        type="text"
                        [class.is-invalid]="control.touched && control.invalid"
                        [formControlName]="controlName"
                        (input)="renameArtwork(controlName)"
                        class="form-control"
                        placeholder="Add a title">
                </div>
            </li>
        </ul>
    </div>
</p-scrollPanel>
</div>