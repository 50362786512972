import { Component, OnInit } from '@angular/core';
import { LayoutsService } from 'src/app/layouts/layouts.service';
import { NgIf } from '@angular/common';
import { LayoutsComponent } from '../../layouts/layouts.component';

@Component({
    selector: 'app-tutorials',
    templateUrl: './tutorials.component.html',
    styleUrls: ['./tutorials.component.scss'],
    standalone: true,
    imports: [LayoutsComponent, NgIf]
})
export class TutorialsComponent implements OnInit {

  constructor(
    public layoutsService: LayoutsService
  ) { }

  ngOnInit(): void {
  }

  back(): void {
    window.history.back();
  }
}
