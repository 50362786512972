import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { EditorService } from '../../editor/editor.service';
import { MainService, UtilsService, store } from 'src/app/shared/services';

import * as _ from 'lodash'
import watch from 'redux-watch'
import { AlertMessageService } from 'src/app/components/alert-message/alert-message.service';
import { ObjectsService } from '../objects/objects.service';
import { UndoRedoService } from 'src/app/shared/services/undo-redo.service';
import { FieldOfViewComponent } from './field-of-view/field-of-view.component';
import { DialogModule } from 'primeng/dialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FormsModule } from '@angular/forms';
import { SliderModule } from 'primeng/slider';
import { InputNumberComponent } from '../../../../components/input-number/input-number.component';
import { InputColor } from '../../../../components/input-color/input-color.component';
import { ExhibitionSelectionComponent } from './exhibition-selection/exhibition-selection.component';
import { NgIf, NgFor } from '@angular/common';
import { IconComponent } from '../../../../components/icon/icon.component';

declare const BABYLON: any;
declare var $: any;


@Component({
    selector: 'app-general',
    templateUrl: './general.component.html',
    styleUrls: ['./general.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [IconComponent, NgIf, ExhibitionSelectionComponent, NgFor, InputColor, InputNumberComponent, SliderModule, FormsModule, InputSwitchModule, DialogModule, FieldOfViewComponent]
})
export class GeneralComponent implements OnInit {
	public takingScreenshot: boolean = false;
	public cameraHighValue: number = 0;
	public lightValue:number  = 0;
	public lightValueObjects: number = 0;
	public fogValue:number  = 0;
	public rotateSpeedValue: number = 0;
	public moveSpeedValue: number = 0;
	public donutSize: number = 0;
	public hideColorOverlay:boolean = true;
	private _undoRedoWatchSubscription: any;

  constructor(
		public editorService: EditorService,
		public mainService:MainService,
		public undoRedoService: UndoRedoService,
		private alertMessageService: AlertMessageService,
		private _objectService: ObjectsService,
		private _utilsService: UtilsService
  ) {
		const undoRedo = watch(store.getState, "editor.undoRedo")
		this._undoRedoWatchSubscription = store.subscribe(undoRedo(e => {
				this._setValueDisplayManualInputs();
		}));
	 }

  ngOnInit(): void { 
		this._setValueDisplayManualInputs()
	}

	ngAfterViewInit(){
		$(".sidebar").on("scroll",()=>{
			if(!this.hideColorOverlay) this.hideColorOverlay = true;
		})
	}

	ngOnDestroy(){
		this._undoRedoWatchSubscription();
	}


	/**
	 * * ================================================================================================ *
	 *   SECTION Resize Donut Functions
	 * * ================================================================================================ *
	 */

	//#region 
	private _donutFadeOut:any = [];


	/**
	 * ANCHOR Resize Donut
	 * @description to resize donut artowrk 
	 */
	private _resizeDonut(): void {
		this._stopFadeOutAnimations();
		this.editorService.artworkDonuts.map((donut:any)=>{
			if(donut['onTheFloor']) {
				donut.setEnabled(true);

				donut.scaling.x = this.editorService.exhibition.placeholder_size;
				donut.scaling.y = this.editorService.exhibition.placeholder_size;

				this._runFadeOutAnimation(donut);
			}
		})
		this.editorService.updateUndoRedoStateWithDelay();

	}

	/**
	 * ANCHOR Stop Fade Out Animations (donut)
	 * @description to stop fade out animations
	 */
	private _stopFadeOutAnimations(): void {
		if(this._donutFadeOut.length != 0) {
			this._donutFadeOut.map(fadeOutAnimation=>{
				fadeOutAnimation.pause();
			})
			this._donutFadeOut = [];
		}
	}

	/**
	 * ANCHOR Run Fade Out Animation (donut)
	 * @description to run fade out animation
	 * @param donut : BABYLON.Mesh
	 */
	private _runFadeOutAnimation(donut: any): void {
		let fadeOut = BABYLON.Animation.CreateAndStartAnimation('setPlaceholder', donut, 'visibility', 1, 1, 1, 0, 0, null, () => {
			donut.setEnabled(false);
			donut.visibility = 1;
			this.editorService.updateLogActivityWithDelay("Update donut size")
			this._donutFadeOut = [];
		});
		this._donutFadeOut.push(fadeOut)
	}

	//#endregion
	//!SECTION


	/**
	 * * ================================================================================================ *
	 *   SECTION Update Slider/Input Settings Value Functions
	 * * ================================================================================================ *
	 */

	//#region 

	/**
	 * ANCHOR Update Donut Size
	 * @description to handle change value of donut size
	 * @param value : number
	 */
	public updateDonutSize(value: number): void {
		this.donutSize = value;
		this.editorService.exhibition.placeholder_size = value / 50; 
		this._resizeDonut();
	}

	/**
	 * ANCHOR Update Camera Movement Speed
	 * @description to handle change value of camera movement speed
	 * @param value : number
	 */
	public updateMovementSpeed(value: number): void {
		this.moveSpeedValue = value;
		this.editorService.mainCameraNode.speed = value / 50 ;
		this._changeSpeed('move'); 
	}

	/**
	 * ANCHOR Update Camera Rotation Speed
	 * @description to handle change value of camera rotation speed
	 * @param value : number
	 */
	public updateRotationSpeed(value: number): void {
		this.rotateSpeedValue = value;
		this.editorService.camera.rotate_speed = value / 2000; 
		this._changeSpeed('rotate');
	}


	/**
	 * ANCHOR Update Gallery Lighting 
	 * @description to handle change value of gallery lighting
	 * @param value: number
	 */
	public updateGalleryLighting(value: number): void {
		this.lightValue = value;
		this.editorService.exhibition.light_intensity = (value / 100 * 1.25) + 0.25;
		this._adjustGalleryLighting();
	}

	/**
	 * ANCHOR Update Object Lighting 
	 * @description to handle change value of object lighting
	 * @param value : number
	 */
	public updateObjectLighting(value: number): void {
		this.lightValueObjects = value;
		this.editorService.exhibition.light_intensity_object = value / 20;
		this._adjustObjectLighting();
	}

	/**
	 * ANCHOR Update Camera Speed
	 * @description to handle change value of camera speed
	 * @param value : number
	 */
	public updateCameraHigh(value: number): void {
		this.cameraHighValue = value;
		const footingPosition: any = this._utilsService.getFootingPosition(
			this.editorService.mainCameraNode.position,
			this.editorService.scene
		);
		const realHighValue = value / 100;
	
		this.editorService.mainCameraNode.position.y = footingPosition.y + realHighValue;
		this.editorService.mainCameraNode.ellipsoid.y = 49.75124378109452/100 * realHighValue; 
		this._changeHeightCamera();
	}

	//#endregion
	//!SECTION


	/**
	 * * ================================================================================================ *
	 *   SECTION Lighting Settings Functions
	 * * ================================================================================================ *
	 */

	//#region 

	/**
	 * ANCHOR Adjust Gallery Lighting
	 * @description Adjust the lighting of the gallery
	 */
	private _adjustGalleryLighting(): void {
		this.editorService.setExhibitionLighting();
		this.editorService.dataHasChanges = true;
		this.editorService.updateUndoRedoStateWithDelay();
	}

	/**
	 * ANCHOR Adjust Object Lighting
	 * @description Adjust the lighting of the 3D objects
	 */
	private _adjustObjectLighting(): void {
		this._objectService.adjustLightingForAll(this.editorService.exhibition.light_intensity_object);
		this.editorService.dataHasChanges = true;
		this.editorService.updateLogActivityWithDelay("Update light intensity 3D-objects");
		this.editorService.updateUndoRedoStateWithDelay();
	}


	//#endregion
	//!SECTION


	/**
	 * * ================================================================================================ *
	 *   SECTION Reset General Settings Functions
	 * * ================================================================================================ *
	 */

	//#region 

	/**
	 * ANCHOR Reset Camera Movement Speed
	 * @description to reset camera movement speed to default
	 */
	public resetMovementSpeed(): void {
		this.editorService.mainCameraNode.speed = 0.1;
		this._changeSpeed('move');
		this._setValueDisplayManualInputs();
	}

	/**
	 * ANCHOR Reset Camera High
	 * @description to reset camera high to default
	 */
	public resetCameraHigh(): void {
		const footingPosition: any = this._utilsService.getFootingPosition(
			this.editorService.mainCameraNode.position,
			this.editorService.scene
		);
		this.editorService.mainCameraNode.position.y = footingPosition.y + 1.6;
		this.editorService.mainCameraNode.ellipsoid.y = 0.7960199004975124;
		this._changeHeightCamera();
		this._setValueDisplayManualInputs();
	}

	/**
	 * ANCHOR Reset Camera Rotation
	 * @description to reset camera rotation to default
	 */
	public resetRotationSpeed(): void {
		this.editorService.camera.rotate_speed = 0.0025;
		this._changeSpeed('rotate');
		this._setValueDisplayManualInputs();
	}

	/**
	 * ANCHOR Reset Donut Size
	 * @description to reset donut size to default
	 */
	public resetDonutSize(): void {
		this.editorService.exhibition.placeholder_size = 0.3;
		this._resizeDonut();
		this._setValueDisplayManualInputs();
	}

	/**
	 * ANCHOR Reset Gallery Lighting
	 * @description to reset gallery lighting to default
	 */
	public resetGalleryLighting(): void {
		this.editorService.exhibition.light_intensity = 1;
		this._adjustGalleryLighting();
		this._setValueDisplayManualInputs();
	}

	/**
	 * ANCHOR Reset Object Lighting
	 * @description to reset object lighting to default
	 */
	public resetObjectLighting(): void {
		this.editorService.exhibition.light_intensity_object = this.editorService.exhibition.config.defaultIntensityObject;
		this._adjustObjectLighting();
		this._setValueDisplayManualInputs();
	}

	//#endregion
	//!SECTION
	

	/**
	 * * ================================================================================================ *
	 *   SECTION Set Value Display for Manual Input Functions
	 * * ================================================================================================ *
	 */

	//#region 

	/**
	 * ANCHOR Set Value Display Manual Inputs
	 * @description to set value display for manual inputs
	 */
	private _setValueDisplayManualInputs(): void {
		this.lightValue = this._setValueDisplayGalleryLighting();
		this.lightValueObjects = Math.round(this.editorService.exhibition.light_intensity_object * 20);
		this.cameraHighValue = this._setValueDisplayCameraHeight();
		this.rotateSpeedValue = Math.round(this.editorService.camera.rotate_speed * 2000);
		this.moveSpeedValue = Math.round(this.editorService.camera.movement_speed * 50);
		this.donutSize = Math.round(this.editorService.exhibition.placeholder_size * 50);
	}

	/**
	 * ANCHOR Set Vlaue Display for Gallery Lighting
	 * @description to set value display for gallery lighting manual input
	 * @returns : number
	 */
	private _setValueDisplayGalleryLighting(): number {
		const min = 0.25;
		const max = 1.5;
		const range = max-min;
		const value = this.editorService.exhibition.light_intensity;
		return Math.round(((value-min)/range)*100)
	}

	/**
	 * ANCHOR Set Value Display for Camera Height
	 * @description to set value display for camera height manual input
	 * @returns : number
	 */
	private _setValueDisplayCameraHeight(){
		const footingPosition: any = this._utilsService.getFootingPosition(
			this.editorService.mainCameraNode.position,
			this.editorService.scene
		);
		return Math.round((this.editorService.mainCameraNode.position.y - footingPosition.y)*100)
	}


	//#endregion
	//!SECTION

	/**
	 * * ================================================================================================ *
	 *   SECTION Uncategorized Functions
	 * * ================================================================================================ *
	 */

	//#region 

	/**
	 * ANCHOR Take Screenshot
	 * @description to take a screenshot of the exhibition
	 */
	public takeScreenshot(): void {
		this.takingScreenshot = true;
		this.editorService.blockUserAction = true;
		setTimeout(() => {
			this.editorService.takeScreenshot(
				this.editorService.mainCameraNode,
				this.editorService.engine,
				this.editorService.canvas
			).then((data)=>{
				const fileName = this.editorService.exhibition.name.replace(/[^a-zA-Z0-9 ]/g, '');
				this.editorService.downloadFileFromBase64(data, fileName + "_" + new Date().getTime())
				this.takingScreenshot = false;
				this.editorService.blockUserAction = false;
				this.editorService.updateLogActivity("Take a screenshoot of exhibition")
				this.alertMessageService.add({severity: "success", summary: "Success", detail: "The screenshot was successfully captured."})
			})
		}, 700)
	}

	/**
	 * ANCHOR Set Default Camera Position
	 * @description to set default camera position
	 */
	public setDefaultCameraPositon(): void {
		this.editorService.camera.position = {
			position_x : this.editorService.mainCameraNode.position.x,
			position_y : this.editorService.mainCameraNode.position.y,
			position_z : this.editorService.mainCameraNode.position.z,
		}
		this.editorService.camera.target = {
			target_x : this.editorService.mainCameraNode.rotation.x,
			target_y : this.editorService.mainCameraNode.rotation.y,
			target_z : this.editorService.mainCameraNode.rotation.z
		}
		this.alertMessageService.add({severity: "success", summary: "Success", detail: "The start position for your gallery has been successfully set. Please, click the save button to save the changes."})
		this.editorService.updateLogActivity("Update default camera position")
		this.editorService.updateUndoRedoState();
	}


	/**
	 * ANCHOR Change Camera Height
	 * @description to change camera height 
	 */
	private _changeHeightCamera(): void {
		this.editorService.changeHeightCamera();
		this.editorService.updateLogActivityWithDelay("Update camera high")
		this.editorService.updateUndoRedoStateWithDelay();
	}

	/**
	 * ANCHOR Change Camera Speed
	 * @description to change camera speed such as move and rotate
	 */
	private _changeSpeed = _.debounce((type:string) => {
		switch(type){
			// set the motion speed of the camera
			case "move":
				this.editorService.camera.movement_speed = this.editorService.mainCameraNode.speed;
				this.editorService.updateLogActivity("Update camera speed movement")
				break;

			// set the rotation speed of the camera
			case "rotate":
				this.editorService.updateLogActivity("Update camera speed rotation")
				this.editorService.mainCameraNode.inputs.attached.keyboardRotate.sensibility = this.editorService.camera.rotate_speed;
				break;
		}

		this.editorService.updateUndoRedoState();
	},500)

	/**
	 * ANCHOR Update Donut Display Status
	 * @description to update donut display status
	 */
	public updateShowDonutStatus(): void {
		// Update log activity
		if(this.editorService.exhibition.show_donut){
			this.editorService.updateLogActivity("Show donut artwork")
		}else{
			this.editorService.updateLogActivity("Hide donut artwork")
		}

		// Update state
		this.editorService.updateUndoRedoState();
	}

	/**
	 * ANCHOR Set Horizontal Camera Movement
	 * @description to set horizontal camera movement
	 */
	public setHorizontalCameraMovementObs(): void {
		this.editorService.dataHasChanges = true;
		this.editorService.exhibition.horizontal_view_movement = !this.editorService.exhibition.horizontal_view_movement;
		this.editorService.setHorizontalCameraMovementObs(this.editorService.exhibition.horizontal_view_movement);
		this.editorService.updateUndoRedoState();
	}

	/**
	 * ANCHOR Change Color of the Exhibition
	 * @description to change color of the exhibition
	 * @param color : any
	 */
	public changeColor = _.debounce((color)=>{
		color.meshes.map(mesh=>mesh.material.albedoColor = BABYLON.Color3.FromHexString(color.color));
		color.used_original = false;

		const selectedColor = this.editorService.exhibition.color_config.find((x)=>x.label==color.label);
		selectedColor.color = color.color;
		selectedColor.used_original = false;

		this.editorService.updateLogActivity("Update color "+ selectedColor.label)
		this.editorService.updateUndoRedoState();
	},500)


	//#endregion
	//!SECTION
}