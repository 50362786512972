<app-layouts>
    <div content>
        <div class="wrapper">
            <!-- START SEARCH
            ========================== -->
            <div class="wrap-search">
                <div class="offset16-1 offset-iped-0">
                    <h2 class="title">
                        Clients 
                        <span class="client">({{total_clients}})</span>
                    </h2>
                </div>
                <div class="form-input">
                    <div class="wrap-form">
                        <!-- search -->
                        <input 
                            type="text" 
                            [(ngModel)]="searchValue" 
                            (input)="searchUser($event)" 
                            class="form-control radius bg-transparent" 
                            placeholder="Search">

                        <!-- filter -->
                        <div class="filter-desktop">
                            <ng-template [ngTemplateOutlet]="dropdownFilter"></ng-template>
                        </div>

                        <!-- sort by -->
                        <div class="sort-desktop">
                            <ng-template [ngTemplateOutlet]="dropdownSort"></ng-template>
                        </div>
                    </div>
                </div>
            </div>

            <div class="filter-responsive">
                <div>
                    <ng-template [ngTemplateOutlet]="dropdownFilter"></ng-template>
                    <ng-template [ngTemplateOutlet]="dropdownSort"></ng-template>
                </div>
                
                <pagination
                    *ngIf="listUsers.length"
                    [page]="page"
                    (onPageChange)="changePage($event)"
                    [totalData]="total_clients"
                    [limit]="limit">
                </pagination>
            </div>

            <!-- END SEARCH
            ========================== -->
            <div class="filter-desktop">
                <pagination
                    *ngIf="listUsers.length"
                    [page]="page"
                    (onEndPage)="hasMore = false"
                    (onPageChange)="changePage($event)"
                    [totalData]="total_clients"
                    [limit]="limit">
                </pagination>
            </div>

            <!-- START LIST CONTENT
            ========================== -->
            <div class="wrap-content">
                <ng-container *ngFor="let user of listUsers">
                    <div class="wrap-list">
                        <div class="col16-11 col-iped-6 offset16-1 offset-iped-0 col-mobile-4 wrap-detail-user">
                            <img class="cover-img" [src]="user.avatar" alt="">
                            <div class="user">
                                <p class="user-name">{{user.first_name}} {{user.last_name}}<p>
                                <p class="email">{{user?.email_profile}}</p>
                                <p class="last-login created">Member since: {{user.created_at}}</p>
                                <p class="last-login">Last Login: {{user.last_login}}</p>
                            </div>

                            <div class="history">
                                <p class="last-login created">Member since: {{user.created_at}}</p>
                                <p class="last-login">Last Login: {{user.last_login}}</p>
                            </div>
                        </div>

                        <div class="col16-4 col-iped-2 col-mobile-4 wrap-button">
                            <button (click)="loginAsUser(user)" class="btn btn-outline-primary button" [class.loading-button-primary]="onLogin&&user.on_login" [disabled]="onLogin">
                                <i class="vi vi-eye"></i>
                                Login
                            </button>
                        </div>
                    </div>
                </ng-container>

                <div class="message" *ngIf="onFetchingUsers || total_clients === 0">
                    <p>{{onFetchingUsers ?  'Fetching data ...' : total_clients === 0 ? 'Data not found' : '' }}</p>
                </div>

                <!-- LOAD MORE -->
                <div class="wrap-loadmore" *ngIf="!onFetchingUsers&&total_clients > 0">
                    <p class="text">You've viewed {{listUsers.length}} of {{total_clients}} clients</p>
                    <button *ngIf="hasMore" class="btn-loadmore" (click)="loadMore()">Load More</button>
                </div>
            </div>
            <!-- END LIST CONTENT
            ========================== -->
        </div>
    </div>
</app-layouts>

<ng-template #dropdownFilter>
    <div class="dropdown dropdown-filter">
        <button class="wrap-label" (click)="openfilterPanel()">
            <label class="label">Filter</label>
            <div class="icon">
                <i class="vi vi-filter"></i>
            </div>
        </button>

        <div class="dropdown-panel filter" aria-expanded="false">
            <ul>
                <ng-container *ngFor="let item of filterValue">
                    <li class="dropdown-item" (click)="onFilterChange(item)">
                        <input type="checkbox" [checked]="item.selected">
                        <label class="label">{{item.label}}</label>
                    </li>
                </ng-container>
            </ul>
        </div>
    </div>
</ng-template>

<ng-template #dropdownSort>
    <div class="dropdown dropdown-sort">
        <button class="wrap-label" (click)="openSortPanel()">
            <label class="label">{{ sortLabel }}</label>
            <div class="icon">
                <i class="vi vi-sort-by"></i>
            </div>
        </button>

        <div class="dropdown-panel sort" aria-expanded="false">
            <ul>
                <li class="dropdown-item" (click)="sortByField('last_registered')">
                    <label class="label">Last Registered</label>
                </li>
                <li class="dropdown-item" (click)="sortByField('first_registered')">
                    <label class="label">First Registered</label>
                </li>
                <li class="dropdown-item" (click)="sortByField('last_active')">
                    <label class="label">Last Active</label>
                </li>
            </ul>
        </div>
    </div>
</ng-template>