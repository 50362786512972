import { Component, OnInit, PLATFORM_ID, ViewEncapsulation, inject } from '@angular/core';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';
import { Router, RouterLink } from "@angular/router";
import { MainService } from 'src/app/shared/services';

import * as _ from "lodash";
import { LayoutsService } from '../layouts.service';
import { environment } from '@environments';
import { WhiteLabelService } from 'src/app/components/white-label/white-label.service';
import { FaqComponent } from '../../components/faq/faq.component';
import { NgIf, NgFor, NgSwitch, NgSwitchCase } from '@angular/common';
import { LayoutsComponent } from '../layouts.component';
import { TranslateDirective, TranslateModule } from '@ngx-translate/core';

declare var $: any;
declare var jwplayer: any;

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
    animations: [
        trigger('slideUp', [
            state('void', style({
                height: '*'
            })),
            transition('void => *', [
                animate(500, keyframes([
                    style({ opacity: 0, offset: 0, height: 0 }),
                    style({ opacity: 0.2, offset: 0.2, height: '*' }),
                    style({ opacity: 1, offset: 1.0, height: 'auto' })
                ]))
            ]),
            transition('* => void', [
                animate(200, keyframes([
                    style({ opacity: 1, offset: 0, height: 'auto' }),
                    style({ opacity: 0.2, offset: 0.2, height: '*' }),
                    style({ opacity: 0, offset: 1.0, height: 0 })
                ]))
            ])
        ])
    ],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
			LayoutsComponent,
			NgIf,
			NgFor,
			NgSwitch,
			NgSwitchCase,
			RouterLink,
			FaqComponent,
			TranslateModule
		]
})

export class HomeComponent implements OnInit {
	public showMenu: boolean = false;
	public contentCreatifity: number = 1;
	public iframeLoaded: boolean = false;
	public imagesPhotorealistic = [
		"img-slide-1",
		"img-slide-2",
		"img-slide-3",
		"img-slide-4",
		"img-slide-5",
	];
	public mainCarousel = [
		{
			name: 'Dark Valleys',
			author: 'Georg Katstaller', 
			image: environment.staticAssets+'images/img-carousel-exhibition/img-1.png?t='+this.mainService.appVersion, 
			link: 'https://gallery.villume.com/pDbChMcH'
		},
		{
			name: 'From Stone to Silicone',
			author: 'Villume', 
			image: environment.staticAssets+'images/img-carousel-exhibition/img-2.png?t='+this.mainService.appVersion, 
			link: 'https://gallery.villume.com/qeHlyk9B'
		},
		{
			name: 'Stranded',
			author: 'Polina Shubkina', 
			image: environment.staticAssets+'images/img-carousel-exhibition/img-3.png?t='+this.mainService.appVersion, 
			link: 'https://gallery.villume.com/JApHNEPb'
		},
		{
			name: 'The Studio Is Opening',
			author: 'Michael Babenkov', 
			image: environment.staticAssets+'images/img-carousel-exhibition/img-4.png?t='+this.mainService.appVersion, 
			link: 'https://gallery.villume.com/jZsT89d6'
		},
		{
			name: 'Laurel Valley Plantation',
			author: 'Philip M. Denman', 
			image: environment.staticAssets+'images/img-carousel-exhibition/img-5.png?t='+this.mainService.appVersion, 
			link: 'https://gallery.villume.com/eIcupqtx'
		},
		{
			name: 'Once I Was 7 Years Old',
			author: 'Victoria Usachova', 
			image: environment.staticAssets+'images/img-carousel-exhibition/img-6.png?t='+this.mainService.appVersion, 
			link: 'https://gallery.villume.com/E2n1JAjU'
		},
		{
			name: 'Things',
			author: 'Márton Martos' , 
			image: environment.staticAssets+'images/img-carousel-exhibition/img-7.png?t='+this.mainService.appVersion, 
			link: 'https://gallery.villume.com/S1hOmBsL'
		}
	];
	public owlElement: any = null;
	public owlElementAbout: any = null;
	public intervalMain: any = null;
	public intervalPhotorealistic: any = null;
	public mousePosition: string = null;
	public label: any = new Array(12);
	public isMobile: boolean = false;
	public env = environment;

	constructor(
		private router: Router,
		public mainService: MainService,
		private layoutsService: LayoutsService,
		public whiteLabelService: WhiteLabelService
	) {}

	ngOnInit(): void {
	}

	ngAfterViewInit() {
		this.mainService.loadScripts([
			environment.staticAssets+'plugins/jquery/jquery-3.4.1.min.js?t='+this.mainService.appVersion,
			environment.staticAssets+'plugins/owl-carousel/owl.carousel.min.js?t='+this.mainService.appVersion,
			environment.staticAssets+'plugins/jwplayer/jwplayer.js?t='+this.mainService.appVersion,
		]).then(() => {
			if (this.mainService.isBrowser) {
				if (window.innerWidth <= 1024) this.isMobile = true;
				else this.isMobile = false;

				this.initVideo();

				setTimeout(() => {
					this.initMainCarousel();
					this.initPhotorealisticCarousel();
				}, 1000);
			}

		});
	}


	public videoPlayed: boolean = false;
	initVideo() {
		if (!this.whiteLabelService.whiteLabel) {
			const player = jwplayer("villume-video");
			player.setup({
				playlist: 'https://cdn.jwplayer.com/v2/playlists/AJsbxRT1?format=json'
			});


			player.on('play', () => {
				this.videoPlayed = true;
			});

			player.on('time', (e) => {
				const percentage = Math.round(e.currentTime / e.duration * 100);
				if (percentage > 92) {
					player.seek(0);
				}
			})
		}
	}

	initMainCarousel() {
		this.owlElement = $('.carousel-main').owlCarousel({
			dots: false,
			navigation: false,
			margin: 15,
			nav: false,
			loop: true,
			autoplayHoverPause: true,
			responsiveClass: true,
			autoWidth: true,
			smartSpeed: 1000,
			items: 6,
			responsive: {
				0: {
					// items: 2,
					margin: 10
				},
				600: {
					// items: 3,
					margin: 15
				},
				1200: {
					// items: 6
				},
				1441: {
					// items: 6,
					margin: 20
				}
			},
			onDragged: this.clearIntervalCarousel,
			onChanged: ()=>{
				setTimeout(() => {
					document.body.scrollTop += 1;
					document.body.scrollTop -= 1;
				}, 600);
			}
		});
	}

	initPhotorealisticCarousel() {
		this.owlElementAbout = $('.images-photorealistic').owlCarousel({
			items: 2,
			dots: false,
			navigation: false,
			nav: false,
			autoWidth: true,
			autoplay: false,
			autoplayTimeout: 5000,
			autoplayHoverPause: true,
			responsive: {
				0: {
					margin: 10,
					autoplay: false,
					autoplayTimeout: 9999
				},
				601: {
					margin: 18,
					autoplay: false,
					autoplayTimeout: 9999
				},
				993: {
					margin: 20,
					autoplay: false,
					autoplayTimeout: 9999
				},
				1200: {
					margin: 16,
					autoplay: false,
					autoplayTimeout: 9999
				},
				1441: {
					margin: 20
				}
			},
			onDrag: this.clearIntervalCarousel
		});
		if (window.innerWidth > 1200) this.startAnimationPhotorealistic();
	}

	stopAutoplayCarousel() {
		this.owlElementAbout.trigger('stop.owl.autoplay');
	}

	public direction: string = "right";
	public position: number = 0;
	startAnimationPhotorealistic() {
		this.intervalPhotorealistic = setInterval(() => {
			this.owlElementAbout.trigger("to.owl.carousel", [this.position, 1000]);

			switch (this.direction) {
				case "right": this.position++; break;
				case "left": this.position--; break;
			}

			switch (this.position) {
				case 0: this.direction = "right"; break;
				case this.imagesPhotorealistic.length - 1: this.direction = "left"; break;
			}
		}, 2500)
	}

	creatifity(e:number) {
		this.contentCreatifity = e;
	}

	updateMousePosition(e) {
		let pointWidth = window.innerWidth / 2;
		if (e.clientX > pointWidth) {
			this.mousePosition = 'right';
		} else {
			this.mousePosition = 'left';
		}
	};

	hoverCarousel() {
		this.intervalMain = setInterval(() => {
			if (this.mousePosition == 'right') {
				this.owlElement.trigger('next.owl.carousel');
			} else {
				this.owlElement.trigger('prev.owl.carousel');
			}
		}, 4000);
	}

	clearIntervalCarousel() {
		clearInterval(this.intervalMain);
		clearInterval(this.intervalPhotorealistic);

		const owl = $('.images-photorealistic');
		const owlMain = $('.carousel-main');

		owl.trigger('stop.owl.autoplay');
		owlMain.trigger('stop.owl.autoplay');
	}

	getStarted() {
		if (this.mainService.userInfo) {
			this.router.navigate(['/' + this.mainService.userInfo.username]);
		} else {
			this.mainService.displayLoginDialog = true;
			this.layoutsService.formType = 'sign up';
		}
	}
}
