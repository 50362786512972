<div class="wrapper billing">
    <div class="billing__content">
        <!-- Billing header -->
        <div class="billing__content__header">
            <div class="billing__content__pricing">
                <app-pricing-plan></app-pricing-plan>
            </div>
            <div class="billing__content__info">
                <app-billing-info
                    (refetchPaymentMethod)="paymentMethodComp.getCurrentPaymentMethod()"
                    [addCardParams]="paymentMethodComp.AddPaymentMethodParamsForBilingInfo" 
                    #billingInfoComp>
                </app-billing-info>
            </div>
            <div class="billing__content__method">
                <app-payment-method
                    (displayBilingInfoPopup)="billingInfoComp.editBillingInfo = true"
                    [billingInfoData]="billingInfoComp.billingInfo"
                    #paymentMethodComp>
                </app-payment-method>
            </div>
        </div>

        <!-- Invoices -->
        <div class="billing__content__invoices">
            <app-invoices></app-invoices>
        </div>
    </div>
</div>