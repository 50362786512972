// Common Angular modules/components
import { NgIf, NgStyle } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';

// User-defined services
import { EditorService } from '../editor.service';
import { MainService, store } from 'src/app/shared/services';
import { SplashScreen } from '@interfaces';
import { environment } from '@environments';
import { AlertMessageService } from 'src/app/components/alert-message/alert-message.service';
import { UndoRedoService } from 'src/app/shared/services/undo-redo.service';
import { SplashScreenService } from './splash-screen/splash-screen.service';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';


// Third party plugins
import { debounce } from 'lodash';
import moment from 'moment';
import { CropperDialogComponent } from '../../../../components/cropper-dialog/cropper-dialog.component';
import 'moment-timezone';
import * as _ from 'lodash';
import watch from 'redux-watch'
import { InputSwitchModule } from 'primeng/inputswitch';
import { LazyLoadImageModule } from 'ng-lazyload-image';


@Component({
    selector: 'app-publish',
    templateUrl: './publish.component.html',
    styleUrls: ['./publish.component.scss'],
    standalone: true,
    imports: [NgIf, LazyLoadImageModule, InputSwitchModule, FormsModule, NgStyle, SplashScreenComponent, CropperDialogComponent]
})
export class PublishComponent implements OnInit {

	// Exhibition timer
	public startDateDay:any;
	public startDateMonth:any;
	public startDateYear:any;
	public endDateDay:any;
	public endDateMonth:any;
	public endDateYear:any;
	public timeZones: any[] = [];
	public selectedTimeZone: any;

	// Cover image
	public coverImageTmp: string = "";
	public displayCropperImage: boolean = false;
	public conditionDragImage:boolean = false;
	public croppingImage:boolean = false;

	public publishDesc: string = "";
	public iframe:string;
	public shareableLink:string;

  public published:boolean = false;
	public onPublish:boolean = false;
	public onUpdate:boolean = false;
	public deleteCover:boolean = false;
	public canExportExhibition: boolean = false;

	public exhibitionLinks:any;
	public website_url:string;
	public facebook_url:string;
	public instagram_url:string;

	public websiteInvalid:boolean = false;
	public facebookInvalid:boolean = false;
	public instagramInvalid:boolean = false;

	public websiteUrl:string;
	public unsubscribeStore:any;
	public showSplashScreen:boolean = false;
	public splashScreenData:SplashScreen;
	public env = environment;

	@Input() enableSplashScreen: boolean = false;
	@ViewChild('cropper', { static: false }) private cropperDialog;

	constructor(
		public editorService: EditorService,
		private alertMessageService: AlertMessageService,
		public mainService: MainService,
		public _undoRedoService: UndoRedoService,
		private _splashScreenService: SplashScreenService,
	) { 
		const undoRedo = watch(store.getState, 'editor.undoRedo');
    this.unsubscribeStore = store.subscribe(undoRedo((e) =>  {
			if(this.editorService.exhibitionTimerHasChanges){
				// Set new exhibition date
				this.editorService.setNewExhibitionDate(this.editorService.exhibition.started,'start');
				this.editorService.setNewExhibitionDate(this.editorService.exhibition.ended,'end');
	
				// Validate exhibtion date
				this.editorService.validateExhibitionDate();
			}

			// Set selected exhibition timer
			this.setSelectedExhibtionDateOptions();

			// Set default timezone
			moment.tz.setDefault(this.editorService.exhibition.timezone);

			//  Set selected timezone
			this.setSelectedTimezone()

			// Set value for input switch viewer theme
			this.viewerLightMode = this.editorService.exhibition.viewer_theme == 'light';

			// Set exhibtion link
			this.setExhibitionLink();

			// validate website url
			this._validateWebsiteUrl(this.editorService.exhibition.exhibition_link.website_url);
		}));
	}

	ngOnDestroy(){
		this.unsubscribeStore();
	}

    ngOnInit(): void {
		// Create timezone data for input options
		// this.createTimezoneData();

		// Init drag and drop
		if(!this.editorService.exhibition.cover_image) this.initDragDrop();

		// Set selected exhibtion timer options
		this.setSelectedExhibtionDateOptions();

		// Check whether the user can export the model or not
		this.canExportExhibition = this.userCanExportModel();
		
		// Set exhibtion link
		this.setExhibitionLink();

		// Assign exhibition description and publish status to local property 
		this.publishDesc = this.editorService.exhibition.description;
		this.published = this.editorService.exhibition.published;
		
		// Set input switch value for adjust viewer theme
		this.viewerLightMode = this.editorService.exhibition.viewer_theme == 'light';

		// Create value share input 
		const viewerLink = environment.viewer_path + '/' +this.editorService.exhibition.share_string
		this.iframe = `<iframe width="560" height="315" src="${viewerLink}" title="${this.editorService.exhibition.name}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
		this.shareableLink = viewerLink;
	}

	/**
	 * * ================================ *
	 * * FUNCTIONS LIST 
	 * * ================================ *
	 * - INIT OPTIONS EXHIBITION TIMER
	 * - VALIDATE EXHIBITION TIMER
	 * - SET EXHIBITION DATE
	 * - SET EXHIBITION LINK
	 * - DELETE EXHIBITION
	 * - INIT DRAG AND DROP COVER IMAGE
	 * - OPEN CROPPER IMAGE DIALOG
	 * - CLOSE CROPPER IMAGE DIALOG
	 * - VALIDATE IMAGE
	 * - CHOOSE IMAGE BY CLICK
	 * - TAKE COVER IMAGE FROM EXHIBITION
	 * - EDIT COVER IMAGE
	 * - DELETE COVER IMAGE
	 * - SET COVER IMAGE
	 * - UPDATE STATE UNDO REDO COVER IMAGE
	 * - CHANGE VIEWER THEME
	 * - SET UNLIMITED TIME
	 * - SET COUNTDOWN TIMER
	 * - SET SELECTED TIMEZONE
	 * - PUBLISH EXHIBITION
	 * - COPY TEXT INPUT
	 * - CHANE TIMEZONE
	 * - CHANGE EXHIBITION LINK
	 */


	/**
	 * * SET UNLIMITED TIME *
	 * Todo: to turn on/off unlimited time
	 */
	setUnlimitedTime(){
		if(!this.editorService.blockUserAction){
			this.editorService.exhibition.unlimited_time = !this.editorService.exhibition.unlimited_time;		
			this.editorService.setNewExhibitionDate(this.editorService.exhibition.started,'start');
			this.editorService.setNewExhibitionDate(this.editorService.exhibition.ended,'end');

			// Validate exhibtion date
			this.editorService.validateExhibitionDate();

			// Set selected exhibition timer
			this.setSelectedExhibtionDateOptions();
		
			// Update log activity and undo redo state
			this.editorService.updateUndoRedoState();
			const logMessage = this.editorService.exhibition.unlimited_time ? 'Enable unlimited time' : 'Disable unlimited time'
			this.editorService.updateLogActivity(logMessage)

			this.editorService.exhibitionTimerHasChanges = true;
			this.editorService.dataHasChanges = true;
		}
	}

	
	/**
	 * * SET EXHIBITION LINK *
	 * Todo: to set exhibition link
	 */
	setExhibitionLink() {
		this.exhibitionLinks = this.editorService.exhibition.exhibition_link;
		this.website_url = this.exhibitionLinks?.website_url ? this.exhibitionLinks.website_url : '';
		this.facebook_url = this.exhibitionLinks?.facebook_url ? this.exhibitionLinks.facebook_url : '';
		this.instagram_url = this.exhibitionLinks?.instagram_url ? this.exhibitionLinks.instagram_url : '';
	}

	/**
	 * * USER CAN EXPORT MODEL *
	 * Todo: to check whether the user can export the model or not
	 */
	userCanExportModel(){
		// List of users who have export model permission
		const usersCanExportExhibition = [
			'albugsp@gmail.com',
			'testvillume@gmail.com',
			'villumedemos@gmail.com',
			'vrillume@gmail.com',
			'office@georgkatstaller.com',
			'qatester093@gmail.com',
			'santhiagobernie13@gmail.com',
			'villumesupport@gmail.com',
			'muhamad210501@gmail.com'
		]

		// Check if this user has access to export models
		if(usersCanExportExhibition.includes(this.mainService.userInfo.email)) return true;
		else return false;
	}



	/**
	 * * =============================================================== *
	 * * EXHIBTION TIMEZONE FUNCTIONS SECTION
	 * * =============================================================== *
	 * - CREATE TIMEZONE DATA OPTIONS
	 * - SET SELECTED TIMEZONE
	 */

	/**
	 * * CREATE TIMEZONE DATA OPTIONS *
	 * Todo: to creating timezone data options
	 */
	createTimezoneData(){
		this.editorService.getTimezoneList().subscribe((timezones:any) => {
			timezones.map((timezone: any)=>{
				this.timeZones.push({
					label: timezone,
					value: timezone
				});
			})
			this.setSelectedTimezone();
		})
	}

	/**
	 * * SET SELECTED TIMEZONE *
	 * Todo: to set selected timezone
	 */
	setSelectedTimezone() {
		this.selectedTimeZone = this.editorService.exhibition.timezone || this.editorService.exhibition.timezone || 'Europe/London';
	}





	/**
	 * * =============================================================== *
	 * * EXHIBTION DATE FUNCTIONS SECTION
	 * * =============================================================== *
	 * - SET SELECTED EXHIBTION DATE OPTIONS
	 * - GET EXHIBITION DATE VALUE
	 * - SPLIT EXHIBITION DATE
	 * - SET EXHIBITION DATE
	 * - VALIDATE EXHIBITION DATE
	 */

	/**
	 * * SET SELECTED EXHIBTION DATE OPTIONS *
	 * Todo: to set selected exhibition date options
	 */
	setSelectedExhibtionDateOptions(){
		// Init start date & end date moment object 
		const startDate = moment(this.getExhibitionDateValue('started'),'YYYY-MM-DD');
		const endDate = moment(this.getExhibitionDateValue('ended'),'YYYY-MM-DD');

		// Split exhibtion date
		this.splitExhibitionDate('started',startDate);
		this.splitExhibitionDate('ended',endDate);

	}

	/**
	 * * GET EXHIBITION DATE VALUE *
	 * Todo: to getting exhibition value from datadate if value is null, set date as current date
	 */
	getExhibitionDateValue(type: 'started' | 'ended'){
		return this.editorService.exhibition[type] || moment().format('YYYY-MM-DD')
	}

	/**
	 * * SPLIT EXHIBITION DATE *
	 * Todo: to split exhibition date from moment object
	 */
	splitExhibitionDate(type: 'started' | 'ended', momentDateObject){
		switch (type) {
			case 'started':
				this.startDateDay = momentDateObject.format('DD');
				this.startDateMonth = momentDateObject.format('MM');
				this.startDateYear = momentDateObject.format('YYYY');
			break;
			case 'ended':
				this.endDateDay = momentDateObject.format('DD');
				this.endDateMonth = momentDateObject.format('MM');
				this.endDateYear = momentDateObject.format('YYYY');
			break;
		}
	}

	/**
	 * * SET EXHIBITION DATE *
	 * Todo: for set new exhibition date
	 */
	setExhibitionDate(date,type){
		// Set new exhibition date
		this.editorService.setNewExhibitionDate(date,type);

		// Validate exhibtion date
		this.editorService.validateExhibitionDate();

		// Set selected exhibition timer
		this.setSelectedExhibtionDateOptions();
	
		// Update log activity and undo redo state
		this.editorService.updateLogActivity(`Update ${type} date`);
		this.editorService.updateUndoRedoState();

		this.editorService.exhibitionTimerHasChanges = true;
		this.editorService.dataHasChanges = true;
	}




	/**
	 * * DELETE EXHIBITION *
	 * Todo: for deleting exhibition
	 */
    deleteExhibition(){
      	store.dispatch({ type: 'UPDATE_DELETE_EXIHINITION_STAT', displayDeleteExhibition: true });
    }

	/**
	 * * INIT DRAG AND DROP COVER IMAGE *
	 * Todo: to init drag and drop function
	 */
    initDragDrop(){
		setTimeout(()=>{
			const dropArea = document.getElementById('uploadCover');
		  	const handles = {
				ondrop: (e) => {
					if(!this.editorService.blockUserAction){
						const files = Array.from(e.dataTransfer.files);
						const file:any = files[0];
						if(this.validateImage(file)){
							const url = this.mainService.sanitize(URL.createObjectURL(file))
							this.openCropedImage(url)
						}
					}
				},
				ondragenter: () => this.conditionDragImage = true,
				ondragleave: () => this.conditionDragImage = false
			}
			this.editorService.initDragAndDropFuction(dropArea,handles)
		},100)
    }

	/**
	 * * OPEN CROPPER IMAGE DIALOG *
	 * Todo: for open the cropper image dialog
	 * @param url : url of cover image
	 */
	openCropedImage(url){
		this.displayCropperImage = true;
		this.coverImageTmp = url;
	}

	/**
	 * * CLOSE CROPPER IMAGE DIALOG *
	 * Todo : for close the cropper image dialog 
	 */
	closeCropedImage(e){
		this.displayCropperImage = e;
		this.conditionDragImage = false;
	}

	/**
	 * * VALIDATE IMAGE *
	 * Todo: for validating uploaded file 
	 * @param file : file object
	 */
	validateImage(file){
		if(file){
			// Get file extension & set allowed file extension
			const extension = file.name.split('.').slice(-1)[0].toLowerCase();
			const allowedExtention = ['png','jpeg','jpg'];
			// Validate file extension 
			if(allowedExtention.includes(extension)){
				// Validate file size
				if(file.size  / 1024 / 1024 <= 2){
					return true;
				}else{
					this.conditionDragImage = false;
					this.alertMessageService.add({severity:'warn', summary:'Warning', detail:'The file size is too large. Please only upload files less than 2 MB for stable performance.'});
					return false;
				}
			}else{
				this.conditionDragImage = false;
				this.alertMessageService.add({severity:'warn', summary:'Warning', detail:'This file format is not supported. Please upload .png, or .jpeg'});
				return false;
			}	
		}else{
			return false;
		}
	}

	/**
	 * * CHOOSE IMAGE BY CLICK *
	 * Todo : for choosing image by clicking button
	 * @param e : event
	 */
	chooseImageByClick(e){
		const file = e.target.files[0];
		if(this.validateImage(file)){
			const url = this.mainService.sanitize(URL.createObjectURL(file))
			this.openCropedImage(url)
		}
		e.target.value = "";
	}

	/** 
	 * * TAKE COVER IMAGE FROM EXHIBITION *
	 * Todo: for taking cover image from exhibition thumbnail
	 */
	takeCoverImageFromExhibit(){
		this.openCropedImage(this.editorService.canvas.toDataURL());
	}

	/** 
	 * * EDIT COVER IMAGE *
	 * Todo: for edit cover image exhibition
	 */
	editCover(){
		this.openCropedImage(this.editorService.exhibition.cover_image);
	}

	/**
	 * * DELETE COVER IMAGE *
	 * Todo: deleting cover image
	 */
	deleteCoverImage(){
		this.editorService.blockUserAction = true;
		this.cropperDialog.loadingDelete = true;
		this.editorService.deleteCoverImage().subscribe((res:any)=>{
			this.cropperDialog.loadingDelete = false;
			this.editorService.blockUserAction = false;
			this.editorService.exhibition.cover_image = '';

			this.editorService.updateLogActivity('Delete cover image');
			this.updateStateCoverImage();

			this.displayCropperImage = false;
			this.alertMessageService.add({severity:'success', summary: 'Success', detail: 'The cover image has been successfully updated.'})
			this.initDragDrop();
		},err=>{
			this.cropperDialog.loadingDelete = false;
			this.editorService.blockUserAction = false;
			if(err.error.statusCode==401){
				this.mainService.expiredSesionPopup = true;
			}else{
				this.alertMessageService.add({severity:'error', summary: 'Failed', detail: 'Something went wrong. Please try again.'})
			}
		})
	}

	/**
	 * * SET COVER IMAGE *
	 * Todo: to set cover image and upload it to server
	 * @param croppedImg: Base64
	 */
	setCoverImage(croppedImg){
  
		// convert base64 to file
		const file = this.mainService.dataURLtoFile(croppedImg,'cover.png');
		
		// create form data
		const formData = new FormData();
		formData.append('cover_image', file);
		formData.append('exhibition_id', this.editorService.exhibition.id);

		// upload file to server
		this.cropperDialog.loadingCropping = true
		this.editorService.blockUserAction = true;
		this.editorService.uploadCoverImage(formData).subscribe(res=>{
			this.editorService.exhibition.cover_image = this.mainService.convertPathImage(res['data'].path);
			this.editorService.updateLogActivity('Set cover image')
			this.updateStateCoverImage();

			this.displayCropperImage = false;
			this.editorService.blockUserAction = false;
			this.cropperDialog.loadingCropping = false;
			this.alertMessageService.add({severity:'success', summary: 'Success', detail: 'The cover image has been successfully updated.'})
		},err=>{
			this.cropperDialog.loadingCropping = false;
			this.editorService.blockUserAction = false;
			if(err.error.statusCode==401){
				this.mainService.expiredSesionPopup = true;
			}else{
				this.alertMessageService.add({severity:'error', summary: 'Error', detail: 'Something went wrong. Please try again.'})
			}
		})
	}

	/**
	 * * UPDATE STATE UNDO REDO COVER IMAGE *
	 * Todo: to updating state undo redo when set or delete cover image
	 */
	updateStateCoverImage(){
		this._undoRedoService.states.map((state)=>{
			if (state.exhibition) {
				state.exhibition.cover_image = this.editorService.exhibition.cover_image
			}
		})
	}

	/**
	 * * CHANGE VIEWER THEME *
	 * Todo: to change viewer theme 'dark' or 'light'
	 */
	public viewerLightMode: boolean = false;
	changeViewerTheme(){
		if(!this.editorService.blockUserAction){
			this.viewerLightMode = !this.viewerLightMode;
			this.editorService.dataHasChanges = true;
			this.editorService.exhibition.viewer_theme = this.viewerLightMode ? 'light' : 'dark';
			this.editorService.updateLogActivity('Update Viewer Theme');
			this.editorService.updateUndoRedoState();
		}
	}


	/**
	 * * SET COUNTDOWN TIMER *
	 * Todo: for adjust countdown timer
	 */
	setCountdownTimer(){
		if(this.editorService.exhibition.countdown_timer){
			this.editorService.updateLogActivity('Enable countdown timer')
		}else{
			this.editorService.updateLogActivity('Disabled countdown timer')
		}
	}

	/**
	 * * PUBLISH EXHIBITION *
	 * Todo: to publish or unpublish data to viewer
	 */
	publishExhibition(isUpdate: boolean = false){
		if(isUpdate) this.onUpdate = true;
		else this.onPublish = true;

		// Set default date for exhibition date, if started and ended does not exist
		if(!this.editorService.exhibition.started){
			this.editorService.setNewExhibitionDate(this.editorService.exhibition.started,'start')
			this.editorService.setNewExhibitionDate(this.editorService.exhibition.ended,'end')
		}

		// Validate exhibtion date
		if(isUpdate||!this.editorService.exhibition.published){
			this.editorService.validateExhibitionDate();
		} 


		this.editorService.publishExhibition(!this.editorService.exhibition.published,isUpdate).then(()=>{
			this.onUpdate = false;
			this.onPublish = false;
		}).catch(()=>{
			this.onUpdate = false;
			this.onPublish = false;
		})
	}

	/**
	 * * COPY TEXT INPUT *
	 * Todo: to copy text in input  
	 * @param id : String -> id of input element
	 */
	copyText(id){
		const copyText:any = document.getElementById(id);
		copyText.select();
		copyText.setSelectionRange(0, 99999);
		document.execCommand('copy');
		if (id === "sharelink") {
      this.alertMessageService.add({severity:"success", detail: "The link has been copied."})
    } else {
      this.alertMessageService.add({severity:"success", detail: "The code has been copied."})
    }
	}

	public onExportingGlb: boolean = false;
	public onExportingBabylon: boolean = false;
	exportScene(type:string){
		const filename = this.editorService.exhibition.name + ' - ' + this.editorService.exhibition.share_string;
		if(type=='glb'){
			this.onExportingGlb = true
			this.editorService.blockUserAction = true;
			this.editorService.exportToGLB(filename).then((res:any)=>{
				this.editorService.blockUserAction = false;
				this.onExportingGlb = false
			})
		}
		if(type=='babylon'){
			// Turn on export loading & block user action
			this.onExportingBabylon = true
			this.editorService.blockUserAction = true;

			setTimeout(()=>{
				this.editorService.exportToBabylon(filename);
				
				this.editorService.blockUserAction = false;
				this.onExportingBabylon = false
			},500)
		}

		this.editorService.updateLogActivity('Export scene to '+type)

    }

	/**
	 * * CHANGE TIMEZONE *
	 * Todo: to change timezone  
	 */
	changeTimezone() {
		this.editorService.exhibition.timezone = this.selectedTimeZone;
		moment.tz.setDefault(this.editorService.exhibition.timezone);
		this.editorService.updateLogActivity('Update Timezone');
		this.editorService.updateUndoRedoState();
	}

	/**
	 * * CHANGE EXHIBITION LINK *
	 * Todo: to change exhibition link
	 */
	changeExhibitionLink = debounce(()=>{
		let website_url = '';
		if(this.website_url) {
			const protocol = this.website_url.includes('https://') || this.website_url.includes('http://') ? '' : 'https://';
			website_url = protocol + this.website_url;
		}
		
		this._validateWebsiteUrl(website_url);

		const exhibitionLink = {
			website_url: this.website_url,
			facebook_url: this.facebook_url,
			instagram_url: this.instagram_url
		}
			
		// Add Exhibition Link
		this.editorService.exhibition.exhibition_link = exhibitionLink;
		
		// Update log activity and undo redo state
		this.editorService.updateLogActivity('Update Exhibition Link');
		this.editorService.updateUndoRedoState();
	},1000)

	/**
	 * * VALIDATE EXHIBITION LINK WEBSITE *
	 * Todo: to validating exhibition link website
	 */
	isUrlValid(userInput): boolean {
		const expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
		const regex = new RegExp(expression);
		
		if (userInput && userInput.match(regex)) return true;
        else return false;
	}

	/**
	 * * VALIDATE WEBSITE URL *
	 * Todo: to validate website url
	 * @param website_url : string
	 */
	private _validateWebsiteUrl(website_url: string) {
		if (website_url) {
			const protocol = website_url.includes('https://') || website_url.includes('http://') ? '' : 'https://';
			website_url = protocol + website_url;

			this.websiteInvalid = !this.isUrlValid(website_url);
			this.editorService.publishDataValid = !this.websiteInvalid;
		} else {
			this.websiteInvalid = false;
			this.editorService.publishDataValid = true;
		}
	}

	public changeExhibitionDescription() :void{
		this.editorService.updateUndoRedoStateWithDelay();
		this.editorService.exhibition.edited_description = true;
	}

	public triggerSplashScreen() {
		this.editorService.exhibition['enableSplashScreen'] = this.enableSplashScreen;
		this.editorService.updateUndoRedoState();
	}
}
