import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from '@services';
import { LayoutsService } from 'src/app/layouts/layouts.service';
import { PricingService } from '../pricing.service';
import { environment as env } from '@environments';
import { AlertMessageService } from 'src/app/components/alert-message/alert-message.service';

@Component({
    selector: 'app-subscribe-from-email',
    templateUrl: './subscribe-from-email.component.html',
    styleUrls: ['./subscribe-from-email.component.scss'],
    standalone: true
})
export class SubscribeFromEmailComponent  {
  private _tvParams: string;
  private _prParams: string;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _mainService: MainService,
    private _layoutsService: LayoutsService,
    private _pricingService: PricingService,
  ) {
    this._tvParams = this._activatedRoute.snapshot.queryParams?.tv;
    this._prParams = this._activatedRoute.snapshot.queryParams?.pr;
  }

  ngOnInit() {
    if (this._mainService.isBrowser) this._confirmSubscription();
  }

  private _confirmSubscription() {
    const body = { 'e&': this._tvParams }
    // get user info with token
    this._mainService.getUserInfoWithToken(body).subscribe({
      next: (res: any) => {
        const token = res.data.token;
        
        // update token
        this._layoutsService.updateToken(token).subscribe(() =>{
          let product: string;
          try {
            product = atob(this._prParams);
          } catch (error) {
            window.location.href = `${env.base_host}/404`;
            return;
          }

          const productData = {
            payment: product,
            success_url: `${env.base_host}/save-invoices`,
            cancel_url: `${env.base_host}/pricing`,
          }

          // create stripe checkout session
          this._pricingService.subscribeProduct(productData).subscribe({
            next: (res: any) => {
              const url = res.data.link;
              window.open(url, '_self');
            },
            error: (err: any) => {
              window.location.href = `${env.base_host}/404`;
            }
          });
        });
      },
      error: (err: any) => {
        window.location.href = `${env.base_host}/404`;
      }
    });
  }
}
