import { Component } from '@angular/core';
import { EditorService } from '../../editor.service';
import { UndoRedoService } from 'src/app/shared/services/undo-redo.service';
import { AlertMessageService } from 'src/app/components/alert-message/alert-message.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MainService, store } from '@services';
import { InputNumberComponent } from 'src/app/components/input-number/input-number.component';
import { CommonModule } from '@angular/common';
import { InputColor } from 'src/app/components/input-color/input-color.component';
import { SliderModule } from 'primeng/slider';
import { DropdownModule } from 'primeng/dropdown';
import { TextService } from '../service/text-service.service';
import { TextTitleComponent } from './text-title/text-title.component';
import { TextMenuEditorComponent } from "./text-menu-editor/text-menu-editor.component";
import { TextContentComponent } from "./text-content/text-content.component";
import { FontFamilyComponent } from "./text-style/font-family/font-family.component";
import { FontSizeComponent } from "./text-style/font-size/font-size.component";
import { TextScalingComponent } from "./text-scaling/text-scaling.component";
import { TextColorComponent } from "./text-color/text-color.component";
import { messages } from '@data';
import watch from "redux-watch";
import { TextLoaderService } from '../service/text-loader.service';

@Component({
  selector: 'app-text-editor',
  standalone: true,
  templateUrl: './text-editor.component.html',
  styleUrl: './text-editor.component.scss',
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    InputColor,
    InputNumberComponent,
    SliderModule,
    DropdownModule,
    TextTitleComponent,
    TextMenuEditorComponent,
    TextContentComponent,
    FontFamilyComponent,
    FontSizeComponent,
    TextScalingComponent,
    TextColorComponent
  ],
})
export class TextEditorComponent {
  public activeText: any; a
  public isAddText: boolean = false;
  public color: string;
  public onInput: boolean = false;
  private _reduxWatchSubscription: any[] = [];

  constructor(
    public editorService: EditorService,
    public mainService: MainService,
    private _undoRedoService: UndoRedoService,
    public alertMessageService: AlertMessageService,
    public textService: TextService,
    private _textLoader: TextLoaderService
  ) { this._initReduxWatch() }

  ngOnInit(): void {
    if (this._textLoader.activeTextNode) {
      this._initKeyboardEvent()
      this.textService.initInputsValue()
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeStore();
  }

  /**
  * * UNSUBSCRIBE WATCH *
  * ANCHOR Unsubscribe Watch
  * @description to unsubscribe all
  */
  private _unsubscribeStore(): void {
    this._reduxWatchSubscription.forEach(watchSubscription => {
      watchSubscription() // unsubscribe the watch
    });
  }

  /**
   * * UNSELECT TEXT *
   * ANCHOR Unselect Text
   * @description unselect text
   */
  public unselectText(): void {
    if (this._textLoader.textDataValid) {
      this._undoRedoService.clearInvalidStates();
      if (this.editorService.selectedExhibitAssets.length > 1) this.editorService.unselectMultiExhibitAssets()
      else this.editorService.unselectExhibitAsset();
      this.editorService.updateLogActivity("Unselect text")
    } else {
      this.alertMessageService.add({
        severity: "warn",
        summary: "Warning",
        detail: messages.editor.global.invalidData.text
      })
    }
  }

  /**
   * ANCHOR Init Keyboad Event
   * @description initialize keyboard event
   */
  private _initKeyboardEvent(): void {
    window.onkeydown = (e) => {
      this._deleteArtworkViaKeyboard(e);
    }
  }

  /**
   * * DELETE ARTWORK VIA KEYBOARD *
   * ANCHOR Delete Artwork Via Keyboard
   * @description: to delete artwork via keyboard
   */
  private _deleteArtworkViaKeyboard(e: KeyboardEvent): void {
    const canDelete = !this.editorService.onInput && !this.editorService.onSavingData;
    const showConfimDialog = (e.key == "Delete" || e.key == "Backspace") && this._textLoader.activeTextNode;
    const deleteText = e.key == "Enter" && this.textService.displayConfirmDelete;

    if (canDelete) {
      if (showConfimDialog) this.textService.displayConfirmDelete = true;
      if (deleteText) this.textService.deleteText();
    }
  }

  /**
   * * INIT REDUX WATCH *
   * ANCHOR Init Redux Watch
   * @description to initialize Redux Watch
   */
  private _initReduxWatch() {
    this._reduxWatchSubscription = [
      this._subscribeWatch('objectHasSelected'),
      this._subscribeWatch('undoRedo'),
    ]
  }

  /**
  * * SUBSCRIBE WATCH *
  * ANCHOR Subscribe Watch
  * @description Subscribe Watch
  * @param stateKey: String
  * @returns: Subscription Object
  */
  private _subscribeWatch(stateKey: string) {
    const watchInstance = watch(store.getState, "editor." + stateKey);
    return store.subscribe(watchInstance(e => {
      if (e && this._textLoader.activeTextNode) {
        this._initKeyboardEvent()
        this.textService.initInputsValue()
      }
    }));
  }
}
