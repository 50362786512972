import { AbstractControl, ValidatorFn } from '@angular/forms';
import moment from 'moment';

export function dateGreaterThan(maxDate: moment.Moment, format: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) return null;
    const selectedDate = moment(control.value, format);

    if (selectedDate.isValid() && selectedDate.isAfter(maxDate, 'day')) {
      return { 
        'dateGreaterThan': true,
        'maxDate': maxDate.format(format)
      };
    }

    return null;
  };
}
