<div class="switch-artwork-settings" [ngClass]="editorService.advanceSettingArtworks ? 'mb30' : 'mb15'">
    <p-inputSwitch
        [disabled]="editorService.blockUserAction" 
        [(ngModel)]="editorService.activeArtwork.artwork_info"
        (onChange)="handleOnChangeSwitchArtworkInfo()" 
        [styleClass]="(editorService.blockUserAction)? 'input-switch-disable switch-lg mt0' : 'switch-lg mt0'">
    </p-inputSwitch>
    <label class="ml15 mt0">Artwork Info</label>
</div>
<ng-container *ngIf="editorService.activeArtwork.artwork_info">
    <div [style]="{'margin-bottom': '17px'}">
        <div class="form-group content-input">
            <label class="mr15">Set Price:</label>
            <input 
                type="text" 
                [readonly]="editorService.blockUserAction" 
                (focus)="editorService.onInput=true"
                (blur)="editorService.onInput=false" 
                (input)="updateArtworkInfo('pricing_amount',artworkPricing.value)"  
                [formControl]="artworkPricing" 
                class="form-control bg-dark md"
                (keydown)="mainService.blockChars($event)">
        </div>
        <div *ngIf="artworkPricing.errors" class="validation-error">
            <p *ngIf="artworkPricing.errors?.maxlength">Maximum character length for Price is 20</p>
        </div>
    </div>
    <!-- DIMENSION INFO
    ============================== -->
    <div [style]="{'margin-bottom': '17px'}">
        <div class="form-group content-input" >
            <label>Year Created:</label>
            <input 
                class="form-control year-input" 
                [(ngModel)]="editorService.activeArtwork.year"
                type="text" 
                (change)="updateYear(editorService.activeArtwork.year)"
                (blur)="editorService.onInput=false"
                [disabled]="editorService.blockUserAction"
                (focus)="editorService.onInput=true"
                (keypress)="blockAlphabet($event)" 
                maxlength="4">
        </div>
    </div>
    <!-- DIMENSION INFO
    ============================== -->
    <div [style]="{'margin-bottom': '17px'}">
        <div class="form-group content-input" >
            <label>Dimension Info:</label>
            <input
                (focus)="editorService.onInput=true"
                (blur)="editorService.onInput=false" 
                type="text"
                (input)="updateArtworkInfo('dimension',artworkDimension.value)"
                [readonly]="editorService.blockUserAction" 
                [formControl]="artworkDimension" 
                class="form-control bg-dark md"
                (keydown)="mainService.blockChars($event)">
        </div>
        <div *ngIf="artworkDimension.errors" class="validation-error">
            <p *ngIf="artworkDimension.errors?.maxlength">Maximum character length for dimension is 20</p>
        </div>
    </div>
    <div [style]="{'margin-bottom': '17px'}">
        <div class="form-group content-input" >
            <label>Materials:</label>
            <input
                (focus)="editorService.onInput=true"
                (blur)="editorService.onInput=false" 
                type="text"
                (input)="updateArtworkInfo('material',artworkMaterial.value)"
                [readonly]="editorService.blockUserAction" 
                [formControl]="artworkMaterial"  
                class="form-control bg-dark md"
                (keydown)="mainService.blockChars($event)">
        </div>
    </div>
    <!-- DESCRIPTION
    ============================== -->
    <div class="form-group content-description">
        <label>Description:</label>
        <textarea
            (focus)="editorService.onInput=true"
            (blur)="editorService.onInput=false" 
            [formControl]="artworkDesc"
            class="form-control bg-dark"
            [readonly]="editorService.blockUserAction"
            (input)="updateArtworkInfo('description',artworkDesc.value)" 
            [ngStyle]="{'height.px': 110, 'resize': 'none'}"
            (keydown)="mainService.blockChars($event)">
        </textarea>
    </div>
</ng-container>