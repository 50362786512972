<div class="form-group">
  <label>Font Family</label>
  <div class="wrap-font-style">
    <div class="wrap-item-font">
      <p-dropdown 
        [options]="textFontFamilies" 
        [(ngModel)]="selectedFontFamily" 
        (onChange)="editFontStyle()" 
        dropdownIcon="" 
        optionLabel="name" 
        styleClass="bg-dark text-style splash">
        <ng-template let-item pTemplate="item">
          <div class="item-dropdown">
            <div [ngStyle]="{'fontFamily': item.value}">
              {{item.name}}
            </div>
          </div>
        </ng-template>
        <ng-template let-item pTemplate="selectedItem">
          <div class="item-selected" [ngStyle]="{'fontFamily': item.value}">{{item.name}}</div>
        </ng-template>
      </p-dropdown>
    </div>
    <div class="font-style">
      <p-dropdown 
        [options]="fontStyle" 
        (onChange)="editFontStyle()"
        [(ngModel)]="selectedFontStyle" 
        dropdownIcon=""
        optionLabel="name" 
        styleClass="bg-dark text-style splash">
        <ng-template let-item pTemplate="item">
          <div class="item-dropdown">
            <div>{{item.name}}</div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
  </div>
</div>