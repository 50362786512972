<div class="input-file left">
  <div class="left-content">
      <span class="label">Images or 3D objects:</span>
      <div id="dragArea" class="drop-area" (click)="inputFiles.click()">
          <div class="drop-area--dash">   
              <span>Drag and drop<br>or click to upload</span>
          </div>
      </div>
      <input 
        type="file"
        #inputFiles
        [disabled]="disabled"
        hidden
        accept=".jpg, .jpeg, .png, .glb" 
        (change)="handleInputFileChange($event)"
        multiple>
      <span class="label-sub">We support JPG, PNG, GLB files</span>
  </div>
</div>