.wrap-item-figure {
	>ul {
		padding-left: 0;
		margin-bottom: 0;
	}

	.empty-artwork {
		font-size: 14px;
		padding: 30px 0;
		margin: 80px 0;
		list-style: none;
	}
}