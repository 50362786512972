import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CreateExhibitionService } from './services/create-exhibition.service';
import { Router } from '@angular/router';
import { MainService, UtilsService } from '@services';
import { AllowedFile, UploadArtworkPayload } from 'src/app/pages/virtual-gallery/editor/images/images.interfaces';
import { ArtworkDataAndTypeParam } from 'src/app/pages/virtual-gallery/editor/images/add-image/submit/submit.interfaces';
import { ImagesService } from 'src/app/pages/virtual-gallery/editor/images/images.service';
import { AlertMessageService } from '../alert-message/alert-message.service';
import { Scaling } from '@interfaces';
import { environment } from '@environments';
import { PreviewExhibitionComponent } from './preview-exhibition/preview-exhibition.component';
import { ListArtworkComponent } from './add-artwork/list-artwork/list-artwork.component';
import { AddArtworkComponent } from './add-artwork/add-artwork/add-artwork.component';
import { NameExhibitionComponent } from './name-exhibition/name-exhibition.component';
import { SelectExhibitionComponent } from './select-exhibition/select-exhibition.component';
import { NgIf } from '@angular/common';
declare var BABYLON : any;

@Component({
    selector: 'app-create-exhibition',
    templateUrl: './create-exhibition.component.html',
    styleUrls: ['./create-exhibition.component.scss'],
    standalone: true,
    imports: [NgIf, SelectExhibitionComponent, NameExhibitionComponent, AddArtworkComponent, ListArtworkComponent, PreviewExhibitionComponent]
})
export class CreateExhibitionComponent {
	public exhibitionData: any = {};
	public exhibitionName: string = '';
	public artworkData: AllowedFile[] = [];
	public onCreating: boolean = false;
  public previewGallery: boolean = false;
	public exhibitionID: string = '';
	public shareString: string = '';
  public artworkDataValid: boolean = false;
  public loading: boolean = false;
  private canvas;
  private scene;

  @Input() public isProfile: boolean = false;

	constructor(
		public createService: CreateExhibitionService,
		private _router: Router,
		private _mainService: MainService,
    private _artworkService: ImagesService,
    private _messageService: AlertMessageService,
    private _utilsService: UtilsService
	) {
    if (this.createService.fromCatalogue) {
      this.createService.fromCatalogue = false;
      this.createService.stepWizard = 2;
      this.exhibitionData = this.createService.exhibitionData;
    }
  }

  ngOnInit(): void {
    if (this._router.url != '/') this._mainService.createExhibitionWidget = false;
    if (this._mainService.createExhibitionWidget && this._mainService.isBrowser) {
      const body = document.getElementsByTagName('body')[0];
      body.classList.add('p-overflow-hidden');
    }
  }

  ngOnDestroy(): void {
    if (this._mainService.isBrowser) {
      const body = document.getElementsByTagName('body')[0];
      body.classList.remove('p-overflow-hidden');
    }
  }

   /**
   * ANCHOR get exhibition data
   */
   public getSelectedExhibition(exhibition): void {
    this.exhibitionData = { ...exhibition };
  }

	/**
	 * * ANCHOR Continue Button *
	 * Todo: for continue to next step
	 */
	public nextStep() {
    document.getElementsByClassName('p-scrollpanel-content')[0].scrollTop = 0;
		switch (this.createService.stepWizard) {
			case 1:
				this.createService.stepWizard++
				break;
			case 2:
				if (!this.exhibitionName) this.exhibitionName = 'Exhibition Name';
				this.createService.stepWizard++
				break;
		}
	}

  public backAction() {
    switch (this.createService.stepWizard) {
      case 1:
        this._mainService.createExhibitionWidget = false;
        this._router.navigate([`/${this._mainService.userInfo.username}`]);
        break;
      case 2:
        this.exhibitionName = '';
        this.exhibitionData = {};
        this.createService.stepWizard--
        break;
      case 3:
        this.artworkData = [];
        if (this.exhibitionName == 'Exhibition Name') this.exhibitionName = '';
        this.createService.stepWizard--
        break;
    }
  }

	public skipAction() {
		switch (this.createService.stepWizard) {
			case 1:
        this._mainService.createExhibitionWidget = false;
				this._router.navigate([`/${this._mainService.userInfo.username}`]);
				break;
			case 2:
				this.exhibitionName = 'Exhibition Name';
				this.createService.stepWizard++
				break;
			case 3:
        this.createExhibition();
				break;
		}
	}

	/**
	 * ANCHOR CREATE EXHIBITION *
	 * Todo: to create exhibition
	 */
	public createExhibition(): void {
    this.onCreating = true;
    this.exhibitionData.name = this.exhibitionName;

    setTimeout(() => {
      this.createService.createExhibitionUser(this.exhibitionData).then((res: any) => {
        this.exhibitionID = res.data.exhibition_id;
        this.createService.getExhibition(this.exhibitionID).subscribe((res: any) => {
          this.shareString = res.data.exhibition_detail[0]['share_string'];
          if (this.artworkData.length > 0) {
            this._uploadArtworkImageObject();
          } else {
            this.onCreating = false;
            this.previewGallery = true;
          }
        });
      }).catch((err) => {
        this.onCreating = false;
        this._messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Something went wrong.'
        });
      });
    }, 200);
	}

	/**
   * ANCHOR Submit Artwork Image Object
   * @description to submit artwork image object
   */
  private _uploadArtworkImageObject(): void {
    const requests = [];
    this.artworkData.forEach((file: AllowedFile) => {
      const type = this._getArtworkType(file.file);
      switch (type) {
        case 'image':
          requests.push(this._createUploadArtworkImageRequest(file));
        break;

        case 'object':
          requests.push(this._createUploadArtworkObjectRequest(file));
        break;
      }
    })

    this._runRequestsSequentially(requests).then(() => {
      this.createService.updateExhibitionViewer(this.shareString).subscribe({
        next: (res: any) => {
          this.onCreating = false;
          this.previewGallery = true;
          if (this.scene) this.scene.dispose();
        }
      });
    }).catch((err) => {
      this.onCreating = false;
      this._messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Something went wrong.'
      });
      this.createService.deleteExhibition(this.exhibitionID).subscribe();
    });
  }

  /**
   * ANCHOR Run Requests Sequentially
   * @description to run requests sequentially
   * @param requests : any[] -> requests to be run
   */
  private _runRequestsSequentially(requests: any[], index: number = 0): Promise<void> {
    if (index >= requests.length) return Promise.resolve();
    return requests[index].then(() => {
      return this._runRequestsSequentially(requests, index + 1);
    });
  }

	/**
   * ANCHOR Get Artwork Type
   * @description to get artwork type
   * @param file : File -> artwork file
   * @returns : 'image' | 'object'
   */
  private _getArtworkType(file: File): 'image' | 'object' | 'other' {
    const extension = this._mainService.getFileExtension(file);
    const imageFormat = ['png', 'jpg', 'jpeg'];
    if (extension == 'glb') return 'object';
    if (imageFormat.includes(extension)) return 'image';
    return 'other';
  }

	/**
   * ANCHOR Create Upload Artwork Image Request
   * @description to create upload artwork image request
   * @param allowedFile : AllowedFile -> allowed file
   * @returns : Promise<any> -> upload artwork image request
   */
  private _createUploadArtworkImageRequest(allowedFile: AllowedFile): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const payload = await this._createUploadPayload({
        allowedFile,
        type: 'figure-image'
      });
      this._artworkService.uploadArtworkImage(payload).subscribe((res: any) => {
        if(res['status'] == "response") {
          resolve(res.data.data.data_figure[0]);
        }
      }, err => {
        if (err.status == 401) this._mainService.expiredSesionPopup = true;
        reject(err);
      })
    })
  }

	/**
   * ANCHOR Create Upload Artwork Payload
   * @description to create upload artwork payload
   * @param allowedFile : AllowedFile -> allowed file
   * @param type : 'figure-image' | 'figure-object' | 'video' -> type
   * @returns : Promise<UploadArtworkPayload> -> upload artwork payload
   */
  private async _createUploadPayload(params: ArtworkDataAndTypeParam): Promise<UploadArtworkPayload> {
    const { allowedFile, type } = params;
    const name = allowedFile.name;
    const sequence = this._getArtworkSequence(allowedFile);

    await this._setInitialPosition(allowedFile);

    const payload: UploadArtworkPayload = {
      exhibition_id: this.exhibitionID,
      data_figure: {
        rotation: allowedFile?.rotation,
        position: allowedFile?.position,
        width: allowedFile?.length_unit == 'inch' ? this.createService.convertInchToCm(allowedFile?.width) : allowedFile?.width,
        height: allowedFile?.length_unit == 'inch' ? this.createService.convertInchToCm(allowedFile?.height) : allowedFile?.height,
        real_height: allowedFile?.real_height,
        real_width: allowedFile?.real_width,
        file_type: type,
        name,
        sequence
      },
      v: 1
    };

    switch (type) {
      case 'figure-image':
        payload.file_image = allowedFile.file;
        payload.data_figure.light_intensity = 1;
      break;
      
      case 'figure-object':
        payload.file_image = allowedFile.thumbnail;
        payload.file_object = allowedFile.file;
        payload.data_figure.light_intensity = this.exhibitionData.config.defaultIntensityObject;
        payload.data_figure.scaling = await this._getArtworkObjectScaling(allowedFile.file).catch(err => { throw err });
      break;
    }

    return payload;
  }

	/**
   * ANCHOR Create Upload Artwork Object Request
   * @description to create upload artwork object request
   * @param allowedFile : AllowedFile -> allowed file
   * @returns : Promise<any> -> upload artwork object request
   */
  private _createUploadArtworkObjectRequest(allowedFile: AllowedFile): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const payload = await this._createUploadPayload({
        allowedFile,
        type: 'figure-object'
      });
      this._artworkService.uploadArtworkObject(payload).subscribe((res: any) => {
        if(res['status']=="response") {
          resolve(res.data.data.data_figure[0]);
        }
      }, err => {
        reject(err);
      })
    })
  }

	/**
   * ANCHOR Get Artwork Sequence
   * @description to get artwork sequence
   * @param allowedFile : AllowedFile -> allowed file
   * @returns : number -> artwork sequence
   */
  private _getArtworkSequence(allowedFile: AllowedFile | null): number {
    const latestSequence = 0;
    let newSequence: number;
    if(allowedFile == null) newSequence = 1;
    else {
      newSequence = this.artworkData.findIndex((file: AllowedFile) => file.id == allowedFile.id) + 1;
    }
    return latestSequence + newSequence;
  }

	/**
   * ANCHOR Set Initial Position
   * @description to set initial position
   * @param allowedFile : AllowedFile -> allowed file
   * @param params : SetTransformValuesParam
   * @returns : Promise<void>
   */
  private async _setInitialPosition(allowedFile: AllowedFile): Promise<void> {
		return new Promise(async (resolve, reject) => {
			for (let i = 0; i < this.artworkData.length; i++) {
				const artwork = this.artworkData[i];
				if (allowedFile.id == artwork.id && this.exhibitionData.config.positionPlaceholders[i]) {
					const position = {
            position_x: this.exhibitionData.config.positionPlaceholders[i].position_x,
            position_y: this.exhibitionData.config.positionPlaceholders[i].position_y,
            position_z: this.exhibitionData.config.positionPlaceholders[i].position_z
					}

					const rotation = {
						rotation_x: this.exhibitionData.config.positionPlaceholders[i].rotation_x,
            rotation_y: this.exhibitionData.config.positionPlaceholders[i].rotation_y,
            rotation_z: this.exhibitionData.config.positionPlaceholders[i].rotation_z
					}
					
					allowedFile.position = position;
					allowedFile.rotation = rotation;
          resolve();
        } else if (allowedFile.id == artwork.id && !this.exhibitionData.config.positionPlaceholders[i]) {
          allowedFile.position = this.exhibitionData.config.centerPosition;
          allowedFile.position.position_y = 1.7;
          allowedFile.rotation = {
            rotation_x: 0,
            rotation_y: 0,
            rotation_z: 0
          }
          resolve();
				}
			}
		});
  }

  

	/**
   * ANCHOR Get Artwork Object Scaling
   * @description to get artwork object scaling
   * @param file: File
   * @returns : Promise(Scaling)
   */
  private _getArtworkObjectScaling(file: File): Promise<Scaling> {
    return new Promise((resolve, reject) => {
      const babylonjs = [environment.staticAssets + 'plugins/babylonjs/babylonjs-editor.js?t=' + this._mainService.appVersion];
      this._mainService.loadScripts(babylonjs).then(() => {
        this.canvas = document.getElementById('renderObject');
        const engine = new BABYLON.Engine(this.canvas, true);
        this.scene = new BABYLON.Scene(engine);

        const objectUrl = URL.createObjectURL(file);
        BABYLON.SceneLoader.LoadAssetContainerAsync(objectUrl, '', this.scene, null, '.glb').then((container) => {
          const wrapNode = new BABYLON.TransformNode('wrapNode', this.scene);
          container.meshes.forEach((mesh) => {
            if(mesh.name != '__ROOT___') {
              mesh.setParent(wrapNode);
            }
          });
          const { width, height, depth } = this._utilsService.getNodeDimension(wrapNode);
          resolve({
            scaling_x: width,
            scaling_y: height,
            scaling_z: depth
          })
        }).catch((err) => {
          reject(err);
        })
      })
    })
  }

  public isExhibitionEmpty(): boolean {
    return Object.keys(this.exhibitionData).length === 0;
  }
}
