import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments';
import { User } from '@interfaces';

@Injectable({
  providedIn: 'root'
})
export class WhiteLabelService {
  public whiteLabel: boolean = false;
  public whiteLabelUsername: string = '';
  public whiteLabelDomain: string = '';
  public infoUser: User;
  public infoPage: boolean = false;

  constructor(
    private http: HttpClient
  ) { }

  /**
   * * GET EXHIBITION WHITE LABEL *
   * Todo: to get exhibition white label
   * @param domain: String
   */
  getExhibitionWhiteLabel(domain: string, offset: number) {
    const params = {
      domain: domain,
      type: 'outside',
      offset: offset
    }
    return this.http.get(`${environment.baseURL}/white-label/profile`, { params: params });
  }
}
