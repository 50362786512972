import { Component, Input } from '@angular/core';
import { User } from '@interfaces';
import { NgIf, NgTemplateOutlet } from '@angular/common';

@Component({
    selector: 'app-info-white-label',
    templateUrl: './information.component.html',
    styleUrls: ['./information.component.scss'],
    standalone: true,
    imports: [NgIf, NgTemplateOutlet]
})
export class InformationComponent {
  @Input() infoUser: User;

  public navigateToSosmed(username: string, sosmed: 'instagram' | 'facebook'): void {
    const path = 'https://www.' + sosmed + '.com/' + username;
    window.open(path, '_blank');
  }
}
