import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddPaymentMethodParams, CurrentPaymentMethod } from '../billing.interfaces';
import { BillingService } from '../billing.service';
import { PrimeTemplate } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-payment-method',
    templateUrl: './payment-method.component.html',
    styleUrls: ['./payment-method.component.scss'],
    standalone: true,
    imports: [NgIf, DialogModule, FormsModule, ReactiveFormsModule, PrimeTemplate]
})
export class PaymentMethodComponent implements OnInit {
  public editPaymentMethod: boolean = false;
  public deleteConfirm: boolean = false;
  public paymentMethod: CurrentPaymentMethod;
  public paymentMethodForm: FormGroup;
  public onSaving: boolean = false;
  public onDeleting: boolean = false;
  public onFetching: boolean = false;
  public AddPaymentMethodParamsForBilingInfo: AddPaymentMethodParams;

  @Input() public billingInfoData: boolean = false;

  @Output() public displayBilingInfoPopup: EventEmitter<void> = new EventEmitter<void>();
  
  constructor(
    private _billingService: BillingService
  ) { }

  ngOnInit(): void {
    this.getCurrentPaymentMethod();
  }

  
  /**
   * * ================================================== *
   * * GET CURRENT PAYMENT METHOD *
   * * ================================================== *
   * * - GET CURRENT PAYMENT METHOD 
   * * - CREATE DISPLAY CARD NUMBER 
   * * - SET PAYMENT METHOD FORM VALUE 
   * * ================================================== *
   */

  //#region 

  /**
   * * GET CURRENT PAYMENT METHOD *
   * Todo: Add interface for response
   */
  public getCurrentPaymentMethod(): void {
    this.onFetching = true;
    this._billingService.getCurrentPaymentMethod().subscribe((res: any) => {
      this.onFetching = false;
      if (!Array.isArray(res.data.card_detail)) {
        this.paymentMethod = res.data.card_detail;
        this.paymentMethod.exp_year = this.paymentMethod.exp_year.toString().slice(-2);
        this.paymentMethod.display_card_number = this._createDisplayCardNumber(this.paymentMethod.card_number);
        this.setPaymentMethodFormValue();
      } else {
        this.paymentMethod = null;
      }
    }, err => {
      this.onFetching = false;
    })
  }

  /**
   * * CREATE DISPLAY CARD NUMBER *
   * Todo: to create a display card number with last 4 digits and rest of the digits as *
   * @param cardNumber
   */
  private _createDisplayCardNumber(cardNumber: string): string {
    return cardNumber.slice(-4).padStart(cardNumber.length/2, '*');
  }

  /**
   * * SET PAYMENT METHOD FORM VALUE *
   * Todo: to set payment method form value
   */
  public setPaymentMethodFormValue(): void {
    this.paymentMethodForm.patchValue({
      cardNumber: this.paymentMethod.card_number,
      expMonthYear: `${this.paymentMethod.exp_month}/${this.paymentMethod.exp_year}`,
      cvc: '',
      name: this.paymentMethod.name,
    });

    this._proccesingPaymentMethodFormValues();
  }

  //#endregion





  /**
   * * =========================================== *
   * * PROSESING INPUT VALUES FUNCTIONS 
   * * =========================================== *
   * * - PROCCESING PAYMENT METHOD FORM VALUES
   * * - PROCCESING CARD NUMBER VALUE
   * * - PROCCESING EXP MONTH YEAR VALUE
   * * - PROCCESING CVC VALUE
   * * - PROCCESING NAME VALUE
   * * =========================================== *
   */

  //#region 

  /**
   * * PROCESSING PAYMENT METHOD FORM VALUES *
   * Todo: to proccesing payment method form values
   */
  private _proccesingPaymentMethodFormValues(): void {
    const cardNumber = this._proccesingCardNumberValue();
    const expMonthYear = this._proccesingExpMonthYearValue();
    const cvc = this._proccesingCvcValue();
    const name = this._proccesingNameValue();

    this.paymentMethodForm.get('cardNumber').setValue(cardNumber);
    this.paymentMethodForm.get('expMonthYear').setValue(expMonthYear);
    this.paymentMethodForm.get('cvc').setValue(cvc);
    this.paymentMethodForm.get('name').setValue(name);
  }

  /**
   * * PROCESSING CARD NUMBER VALUE *
   * Todo: to convert all card number value to xxxx xxxx xxxx xxxx format
   * @returns : string
   */
  private _proccesingCardNumberValue(): string {
    let value = this.paymentMethodForm.get('cardNumber').value;
    if (value) {
      value = value.replace(/[^0-9]/g, ''); // remove all non-digit character
      value = value.replace(/(.{4})/g, '$1 '); // add space after 4 digit
      value = value.trim(); // remove all space at the end of string
      value = value.substring(0, 19); // limit to 19 digit
    }
    return value;
  }

  /**
   * * PROCESSING EXP MONTH YEAR VALUE *
   * Todo: to convert all exp month year value to xx/xx format
   * @returns : string
   */
  private _proccesingExpMonthYearValue(): string {
    let value = this.paymentMethodForm.get('expMonthYear').value;
    if (value) {
      value = value.replace(/[^0-9]/g, ''); // remove all non-digit character
      if (value.length > 2 ) value = value.replace(/(.{2})/g, '$1/'); // add slash after 2 digit
      else {
        if (value.length === 2 && parseInt(value) > 12) value = '12';
      }
      value = value.trim(); // remove all space at the end of string
      value = value.substring(0, 5); // limit to 5 digit
    }
    return value;
  }

  /**
   * * PROCESSING CVC VALUE *
   * Todo: to convert all cvc value to xxx format
   * @returns : string
   */
  private _proccesingCvcValue(): string {
    let value = this.paymentMethodForm.get('cvc').value;
    if (value) {
      value = value.replace(/[^0-9]/g, ''); // remove all non-digit character
      value = value.trim(); // remove all space at the end of string
      value = value.substring(0, 3); // limit to 3 digit
    }
    return value;
  }

  /**
   * * PROCESSING NAME VALUE *
   * Todo: to convert all name value to latter only format
   * @returns 
   */
  private _proccesingNameValue(): string {
    let value = this.paymentMethodForm.get('name').value;
    if (value) {
      value = value.replace(/[^a-zA-Z ]/g, ''); // remove all non-letter character except space
      value = value.substring(0, 30); // limit to 30 character
    }
    return value;
  }

  //#endregion
}
