@import "../../../../../assets/scss/common/variables";

.wrap-objects{
    .wrap-info {
        padding: 6px 81px 20px 27px;
    }

    .btn-back-to{
        i{
            font-size: 14px;
            font-weight: 600;
            margin-top: 0px;
        }
    }
    
    .button-name-figure{

        .contant-name-figure{
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: solid 1px $color-dark1;
            padding: 15px 10px;
            margin-top: 1px;

            .wrap-title-figure{
                display: flex;
                align-items: center;
                width: 100%;

                .wrap-image-figure{
                    position: relative;
                    
                    .img-figure{
                        width: 44px;
                        height: 44px;
                    }

                    .edit-image{
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        opacity: 0;
                        background: rgba(55, 55, 55, 0.78);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 16px;
                        line-height: 94.3%;
                        color: $color-gray3;
        
                        &:hover{
                            opacity: 1;
                            text-decoration: none;
                        }

                        &.procces{
                            opacity: 1;
                            text-decoration: none;
                        }
                    }
                }

                .name-figure{
                    color: $color-gray3;
                    font-size: 17px;
                    line-height: 130.3%;
                    margin-bottom: 0;
                    margin-left: 10px;
                    background-color: transparent;
                    border: 0 none;
                    width: 100%;
                    font-family: $font-whyte;

                    &:focus{
                        border: 0 none;
                        outline: none;
                        box-shadow: none;
                    }
                }
            }

            .btn-close{
                background-color: transparent;
                margin: 0 11px;
                border: 0 none;
                padding: 0;

                i{
                    font-size: 24px;
                    color: $color-gray1;
                }
            }
        }
    }

    .empty-object {
        font-size: 14px;
        padding: 30px 0;
        margin: 23px 0;
        list-style: none;
    }
}