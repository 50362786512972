<div class="wrap-item-figure" *ngIf="!editorService.activeArtwork">
  <!-- LIST WHEN ANIMATION SEQUENCE IS ACTIVE -->
  <ul 
    cdkDropList 
    *ngIf="editorService.animationSequence && !editorService.previewMode" 
    (cdkDropListDropped)="dropArtwork($event)">
        <li 
            cdkDrag
            [class.disable-item]="loadingGalleryService.show" 
            *ngFor="let artwork of sortArtworkBy('sequence')" 
            class="item-figure drag-list">
            <div class="drag-placeholder" *cdkDragPlaceholder></div>
            <div class="wrap-title-figure">
                <div class="position-relative">
                    <img [defaultImage]="env.staticAssets+'images/other/skeleton.svg?t='+mainService.appVersion" [lazyLoad]="artwork.image" class="img-figure">
                    <img *ngIf="artwork.file_type === 'figure-object'" class="badge" src="{{env.staticAssets}}images/other/badge3D.png?t={{mainService.appVersion}}" alt="">
                </div>
                <div class="title-figure">{{artwork.name}}</div>
            </div>
        </li>
  </ul>

  <!-- LIST WHEN ANIMATION SEQUENCE IS NOT ACTIVE -->
  <ul *ngIf="!editorService.animationSequence || editorService.previewMode">
      <ng-container *ngIf="isArtworksNotEmpty()">
          <li 
            class="item-figure" 
            *ngFor="let artwork of sortArtworkBy('sequence')" 
            [class.disable-item]="isItemDisabled(artwork.id)">
                <div class="wrap-title-figure">
                    <div class="position-relative">
                        <img 
                            [defaultImage]="env.staticAssets+'images/other/skeleton.svg?t='+mainService.appVersion" 
                            [lazyLoad]="artwork.image" 
                            class="img-figure">
                        <img 
                            *ngIf="artwork.file_type === 'figure-object'" 
                            src="{{env.staticAssets}}images/other/badge3D.png?t={{mainService.appVersion}}" 
                            class="badge"/>
                    </div>  
                    <div class="title-figure">{{artwork.name}}</div>
                </div>
                
                <button 
                    class="btn-modify" 
                    pTooltip="Edit Artwork" 
                    tooltipPosition="bottom" 
                    placeholder="Bottom">
                    <i class="vi vi-modify"></i>
                </button>

                <a href="javascript:;" (click)="selectArtwork(artwork)" class="link-figure" [class.cursor-disable]="isItemDisabled(artwork.id)"></a>
          </li>
      </ng-container>
      
      <!-- ARTWORK NOT FOUND MESSAGE
      ============================== -->
      <li *ngIf="!isArtworksNotEmpty() && !loadingGalleryService.show" class="text-center empty-artwork">
        No artwork uploaded
      </li>
      <!-- FECTHING ARTWORK MESSAGE
      ============================== -->
      <li *ngIf="!isArtworksNotEmpty() && loadingGalleryService.show" class="text-center empty-artwork">
          Collecting artwork data...
      </li>
  </ul>
</div>