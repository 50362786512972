@import '../../../assets/scss/common/variables';

.section{
    position: relative;

    @media (max-width: $small-screen) {
        padding: 80px 15px;
    }
}

.wrap-main{
    position: relative;
    width: 100%;

    // MACT HEADER
    // ================================================
    .hero-section{
        background-color: $color-dark3;
        padding: 130px 30px 150px;
        position: relative;

        @media (max-width: $desktop-screen) {
            padding: 140px 25px 190px;
        }

        @media (max-width: $large-screen) {
            padding: 185px 25px 160px;
        }

        @media (max-width: $medium-screen){
            padding: 140px 25px 100px;
            
        }

        @media (max-width: $small-screen) {
            padding: 68px 16px 75px 14px;
        }

        .iframe-overlay {
            position:absolute;
            width: 100%; 
            height: 100%; 
            background: black;
        }

        .row > .col{
            &:first-child{
                @media (max-width: 650px) 
                and (min-width: $small-screen){
                    margin-left: 35px !important;
                }
            }
        }

        .hero-content {
            display: flex;
            align-items: center;
            padding: 0 8px;

            @media (max-width: $small-screen) {
                padding: 0;
            }
        }

        .content{
            @media (max-width: $large-screen) {
                margin-bottom: 150px;
                margin-top: 0;
            }

            @media (max-width: $small-screen) {
                margin-bottom: 42px;
            }

            .title{
                font-weight: 400;
                margin-bottom: 33px;
                font-size: 96px;
                line-height: 91.3%;
                white-space: nowrap;

                @media (max-width: 1570px){
                    font-size: 76px;
                }
    
                @media (max-width: $desktop-screen) {
                    margin-bottom: 31px;
                }
    
                @media (max-width: $large-screen) {
                    margin-bottom: 40px;
                    font-size: 96px;
                }
    
                @media (max-width: $medium-screen) {
                    margin-bottom: 35px;
                    font-size: 84px;
                    margin-left: -15px;
                }
    
                @media (max-width: $small-screen) {
                    font-size: 44px;
                    line-height: 99.8%;
                    margin-bottom: 20px;
                    margin-left: 0;
                }
            }
    
            .desc{
                margin-left: 115px;

                @media (max-width: 1570px) {
                    margin-left: 85px;
                }

                @media (max-width: $large-screen) {
                    margin-left: 100px;
                }

                @media (max-width: $medium-screen) {
                    margin-left: 96px;
                    margin-right: 18px;
                }

                @media (max-width: 630px){
                    margin-left: 65px;
                }
    
                @media (max-width: $small-screen) {
                    margin-left: 24px;
                    margin-right: 0;
                }
    
                p{
                    margin-bottom: 44px;
                    width: 100%;
                    text-align: left;
                    color: $color-gray2;

                    @media (max-width: 1570px){
                        font-size: 18px;
                    }
    
                    @media (max-width: $desktop-screen) {
                        margin-bottom: 35px;
                    }
    
                    @media (max-width: $large-screen) {
                        margin-bottom: 55px;
                        font-size: 20px;
                    }
    
                    @media (max-width: $medium-screen) {
                        margin-bottom: 59px;
                    }

                    @media (max-width: 660px){
                        font-size: 18px;
                    }
    
                    @media (max-width: $small-screen) {
                        font-size: 14px;
                        line-height: 22px;
                        margin-bottom: 30px;
                    }
                }
    
                .wrap-button{
                    display: flex;
                    align-items: center;
    
                    .btn{
                        font-size: 20px;
                        padding: 9px 48px 5px;
                        
                        @media (min-width: $large-screen-up)
                        and (max-width: $desktop-screen){
                            height: 91px;
                            font-size: 18px;
                        }

                        @media (max-width: $small-screen) {
                            height: 55px;
                            font-size: 12px;
                            line-height: 112.8%;
                            padding: 20.5px 26px;
                        }
                    }
    
                    .icon-start{
                        display: flex;
                        margin-top: 2px;
                    }

                    .wrap-btn-info{
                        display: flex;
                        margin-left: 20px;

                        i{
                            font-size: 8px;
                            height: 10px;
                            margin-top: 5px;
                            color: $color-gray3;
                        }

                        .info-button{
                            font-size: 14px;
                            color: $color-gray3;
                            line-height: 20px;
                            margin-left: 5px;
                            margin-bottom: 0;
                            width: 100px;
        
                            @media (max-width: $small-screen) {
                                font-size: 10px;
                                line-height: 112.8%;
                                width: 70%;
                                margin-left: 3px;
                                margin-top: 4px;
                            }
                        }
                    }
                }
            }
        }

        .wrap-video-header{
            position: relative;
            padding-left: 97px;

            @media (max-width: $desktop-screen) {
                padding-left: 79px;
            }

            @media (max-width: $large-screen) {
                padding-left: 0;
            }

            @media (max-width: $small-screen) {
                width: 100%;
            }

            .img-section{
                object-fit: cover;
                width: 100%;
            }

            .overlay-video {
                height: 100%;
                width: auto;
                aspect-ratio: 16/9;

                img {
                    position: absolute;
                    width: auto;
                    height: 100%;
                    z-index: 1;
                }
            }
        }
    }

    .about-section{
        background-color: $color-dark1;
        padding: 180px 30px 143px;
        margin-top: -1px;
        position: relative;

        @media (max-width: $desktop-screen) {
            padding: 133px 25px 0;
        }

        @media (max-width: $large-screen) {
            padding: 150px 25px 0;
        }

        @media (max-width: $medium-screen){
            padding: 110px 25px 0;
        }

        @media (max-width: $small-screen) {
            padding: 60px 15px 0;
        }

        .wrap-desc{
            @media (min-width: $large-screen-up)
            and  (max-width: $desktop-screen){
                margin-left: 0 !important;
                -ms-flex: 0 0 50%;
                flex: 0 0 50%;
                max-width: 50%;
                margin-top: 35px;
            }

            .content{
                width: 100%;
                min-width: 722px;
                margin-top: 37px;

                @media (max-width: $desktop-screen) {
                    margin-bottom: 30px;
                    margin-top: 0;
                    min-width: unset;
                } 

                @media (max-width: $large-screen) {
                    margin-bottom: 40px;
                }

                @media (max-width: $medium-screen) {
                    margin-bottom: 0;
                } 

                .title{
                    margin-bottom: 20px;
                    color: $color-gray3;

                    @media (min-width: 1320px) 
                    and (max-width: $desktop-screen) {
                        min-width: 720px;
                    }
                    
                    @media (max-width: $small-screen) {
                        font-size: 24px;
                        line-height: 30px;
                        margin-bottom: 15px;
                    }
                }

                .desc{
                    margin-left: 118px;

                    @media (min-width: $large-screen-up) 
                    and (max-width: $desktop-screen) {
                        padding-left: 0;
                        margin-left: 88px;
                        font-size: 18px;
                    }

                    @media (max-width: $medium-screen) {
                        margin-bottom: 55px;
                    }

                    @media (max-width: $small-screen) {
                        margin-left: 20px !important;
                        margin-top: 0;
                        margin-bottom: 25px;
                        padding-left: 5px;
                    }

                    p{
                        line-height: 130.5%;

                        @media (max-width: $large-screen) {
                            line-height: 130.5%;
                        }

                        @media (max-width: $small-screen) {
                            font-size: 14px;
                            line-height: 22px;
                        }
                    }

                    img{
                        @media (max-width: $large-screen) {
                            width: 100%;
                        }
                    }
                }
            }
        }

        .info-Photorealistic{
            color: $color-gray1;
            font-size: 12px;
            line-height: 112.8%;
            width: 145px;

            @media (max-width: $desktop-screen){
                margin-bottom: 16px;
            }

            @media (max-width: $large-screen) {
                text-align: right;
                width: 100%;
            }

            @media (max-width: $small-screen) {
                margin-bottom: 10px;
                font-size: 10px;
            }
        }

        .wrap-animated-image{
            padding: 86px 0 86px 80px;
            background-color: $color-dark3;
            position: relative;
            overflow: hidden;

            @media (max-width: $desktop-screen)
            and (min-width: $large-screen-up){
                padding: 65px 0 65px 60px;
            }

            @media (max-width: $medium-screen){
                padding: 65px 0 65px 60px;
            }

            @media (max-width: $small-screen) {
                padding: 30px 0px 30px 30px;
            }
            
            .owl-carousel{
                max-width: 850px;

                .owl-stage-outer{
                    overflow: visible;

                    @media (max-width: $small-screen) {
                        padding: 0;
                    }

                    .item{
                        width: auto;
        
                        .img-Photorealistic{
                            width: auto;
                            height: 268px;
                            object-fit: cover;

                            @media (max-width: $desktop-screen)
                            and (min-width: $large-screen-up){
                                height: 200px;
                            }

                            @media (max-width: $medium-screen){
                                height: 200px;
                            }

                            @media (max-width: $small-screen){
                                height: 100px;
                            }
                        }
                    }
                }
            }
        }
    }

    .creativity-section{
        background-color: $color-dark1;
        padding: 100px 30px 0;
        margin-top: -1px;

        @media (max-width: $desktop-screen) {
            padding: 224px 25px 0;
        }

        @media (max-width: $large-screen) {
            padding: 150px 25px 0;
        }

        @media (max-width: $small-screen) {
            padding: 100px 15px 0;
        }

        .title{
            margin-bottom: 20px;
            color: $color-gray3;

            @media (max-width: $desktop-screen) {
                margin-bottom: 17px;
            }

            @media (max-width: $small-screen) {
                font-size: 24px;
                line-height: 130%;
                margin-bottom: 14px;
            }
        }

        .wrap-content{
            @media (max-width: $desktop-screen){
                -ms-flex: 0 0 50%;
                flex: 0 0 50%;
                max-width: 50%;
            }

            @media (max-width: $large-screen){
                -ms-flex: 0 0 100%;
                flex: 0 0 100%;
                max-width: 100%;
            }

            .content{
                @media (min-width: $large-screen-up) 
                and (max-width: $desktop-screen) {
                    padding-left: 0;
                    margin-left: 85px !important;
                }

                @media (max-width: $small-screen){
                    margin-left: 24px !important;
                }

                .desc{
                    
                    p{
                        margin-bottom: 30px;
                        line-height: 130.5%;
                        padding-left: 17px;

                        @media (max-width: $desktop-screen){
                            padding-left: 0;
                            font-size: 18px;
                        }

                        @media (max-width: $large-screen) {
                            font-size: 20px;
                            line-height: 130.5%;
                            margin-bottom: 38px;    
                        }

                        @media (max-width: $medium-screen){
                            margin-bottom: 25px;
                        }

                        @media (max-width: $small-screen) {
                            font-size: 14px;
                            padding-left: 0;
                            line-height: 22px;
                            margin-bottom: 15px;
                        }
                    }
                }
            }
        }

        .image-content{
            width: 100%;
            margin-left: 52px;

            @media (max-width: $desktop-screen){
                margin-left: 20px;
                width: 105%;
            }

            @media (max-width: $large-screen){
                margin-left: 40px;
                width: 95%;
            }

            @media (max-width: $medium-screen){
                margin-left: 25px;
                width: calc(100% - 25px);
            }
            
            @media (max-width: $small-screen) {
                margin-top: 0;
                margin-left: 0;
                width: 100%;
            }
        }

        .wrap-option{
            @media (max-width: $desktop-screen){
                -ms-flex: 0 0 43.7500000003%;
                flex: 0 0 43.7500000003%;
                max-width: 43.7500000003%;
            }

            @media (max-width: $large-screen){
                -ms-flex: 0 0 100%;
                flex: 0 0 100%;
                max-width: 100%;
            }

            .option{
                position: relative;

                @media (max-width: $large-screen) {
                    margin-top: 80px;
                }

                @media (max-width: $medium-screen) {
                    margin-top: 78px;
                    padding: 5px;
                }

                @media (max-width: $small-screen) {
                    margin-top: 30px;
                }

                .item-option{
                    position: relative;
                    margin-bottom: 40px;

                    &:last-child{
                        margin-bottom: 0;

                        .desc{
                            @media (max-width: $small-screen) {
                                transform: translateY(-100%);
                                margin-top: 26px;
                            }
                        }
                    }

                    &:nth-last-of-type(2){
                        .desc{
                            @media (max-width: $small-screen) {
                                transform: translateY(-100%);
                                margin-top: 41px;
                            }
                        }
                    }

                    .action-option{
                        color: $color-gray3;
                        display: inherit;
                        max-width: 352px;
                        position: relative;
                        z-index: 2;
                        display: initial;
                        align-items: center;
                        background-color: transparent;
                        border: none;

                        @media (max-width: $desktop-screen) {
                            width: 176px;
                            font-size: 18px;
                        }

                        @media (max-width: $large-screen) {
                            width: 247px;
                            font-size: 20px;
                            line-height: 130.5%;
                        }

                        @media (max-width: $medium-screen) {
                            width: 182px;
                        }
        
                        @media (max-width: $small-screen) {
                            width: 120px;
                            font-size: 14px;
                            display: inline-block
                        }
        
                        &.active{
                            color: $color-primary;
                        }
        
                        &:hover{
                            color: $color-primary;
                            text-decoration: none;
                        }
        
                        .pi {
                            position: relative;
                            top: 1px;
                            margin-left: 5px;
                            font-size: 16px;

                            @media (max-width: $small-screen) {
                                font-size: 12px;
                            }
                        }
        
                        .hidden-text{
                            @media (max-width: 1440px) {
                                display: none;
                            }
                        }
                    }

                    .desc{
                        position: absolute;
                        z-index: 1;
                        width: 100%;
                        top: 0;
                        padding-left: 352px;
                        padding-right: 30px;

                        @media (max-width: $desktop-screen) {
                            padding-left: 255px;
                            padding-right: 0;
                            font-size: 18px;
                        }

                        @media (max-width: $large-screen) {
                            padding-left: 275px;
                            font-size: 20px;
                            line-height: 130.5%;
                        }

                        @media (max-width: $medium-screen) {
                            padding-left: 289px;
                        }

                        @media (max-width: $small-screen) {
                            padding-left: 137px;
                            font-size: 14px;
                            line-height: 22px;
                        }
                    }
                }
            }
        }
    }

    .artworks-section{
        background-color: $color-dark1;
        padding: 286px 30px 200px;
        margin-top: -1px;
        position: relative;

        @media (max-width: $desktop-screen) {
            padding: 262px 25px 234px;
        }

        @media (max-width: $large-screen) {
            padding: 150px 25px 200px;
        }

        @media (max-width: $small-screen) {
            padding: 100px 15px;
        }

        .title-section{
            @media (min-width: $large-screen-up)
            and  (max-width: $desktop-screen){
                margin-left: 0 !important;
                -ms-flex: 0 0 50%;
                flex: 0 0 50%;
                max-width: 50%;
            }
        }

        .content{
            width: 100%;

            @media (max-width: $large-screen) {
                margin-bottom: 30px;
            } 

            @media (max-width: $medium-screen) {
                margin-bottom: 34px;
            } 

            .title{
                color: $color-gray3;

                @media (max-width: $medium-screen) {
                    margin-bottom: 20px;
                }
                
                @media (max-width: $small-screen) {
                    font-size: 24px;
                    line-height: 130%;
                    margin-bottom: 15px;
                }
            }

            .desc{
                margin-left: 118px;

                @media (min-width: $large-screen-up) 
                and (max-width: $desktop-screen) {
                    padding-left: 0;
                    margin-left: 85px !important;
                    font-size: 18px;
                }

                @media (max-width: $small-screen) {
                    margin-left: 24px !important;
                }

                p{
                    @media (max-width: $large-screen) {
                        font-size: 22px;
                        line-height: 130.5%;
                    }

                    @media (max-width: $small-screen) {
                        font-size: 14px;
                        line-height: 22px;
                    }
                }

                img{
                    @media (max-width: $large-screen) {
                        width: 100%;
                    }
                }
            }
        }

        .img-section{
            margin-top: 10px;
            object-fit: cover;
            width: 100%;
        }
    }

    .explore-section {
        background-color: $color-dark3;
        padding: 73px 0 232px;

        @media (max-width: $large-screen) {
            padding: 95px 0 94px;
        }

        @media (max-width: $large-screen) {
            padding: 150px 0 0;
        }

        @media (max-width: $small-screen) {
            padding: 70px 0 0;
        }

        .title-more-axhibition{
            font-weight: 400;
            margin-left: 30px;
            margin-bottom: 50px;

            @media (max-width: $medium-screen) {
                margin-bottom: 33px;
            }

            @media (max-width: $small-screen) {
                font-size: 24px;
                margin-left: 15px;
                line-height: 130%;
                margin-bottom: 10px;
            }

            span,a{
                @media (max-width: $medium-screen) {
                    display: block;
                }  
            }

            a{
                &:hover{
                    color: $color-primary;
                    text-decoration: none;

                    i{
                        color: $color-white;
                    }
                }
            }
            
            i{
                font-size: 35px;
                margin-left: 13px;
                color: $color-primary;

                @media(max-width:$small-screen){
                    font-size: 18px;
                    margin-left: 5px;
                }
            } 
        }

        .wrap-carousel{
            position: relative;

            .card {
                display: block;
                width: 100%;
                border-radius: 0;
                border: 0;
                position: relative;
                background: transparent;

                .card-link {
                    text-decoration: none;
                    display: block;
                    position: absolute;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;

                    &:hover{
                        & ~ .card-body {
                            background-color: $color-black2;

                            .title{
                                color: $color-gray2;
                            }
                            
                            .subtitle{
                                color: $color-white;
                            }
                        }

                        & ~ .wrap-image {
                            .overlay {
                                display: none;
                            }
                        }
                    }   
                }

                .wrap-image {
                    width: 100%;
                    height: 100%;
                    position: relative;
                    
                    .overlay {
                        position: absolute;
                        background: rgba(159, 159, 159, 0.1);
                        top: 0;
                        width: 100%;
                        height: 100%;
                    }

                    .card-image {
                        border-radius: 0;
                        width: 332px;
                        object-fit: cover;
                        object-position: 0 0;

                        @media (max-width: $desktop-screen) {
                            width: 248px;
                        }

                        @media (max-width: $large-screen) {
                            width: 356px;
                        }

                        @media (max-width: $medium-screen) {
                            width: 259px;
                        }

                        @media (max-width: $small-screen) {
                            width: 213px;
                        }
                    }
                }

                .card-body{
                    background-color: $color-dark1;
                    padding: 9px 20px;

                    @media (max-width: $small-screen){
                        padding: 5px 10px;
                    }

                    .title {
                        margin: 0;
                        color: $color-gray1;
                        font-size: 16px;
                        line-height: 20px;
                        text-decoration: none;
    
                        @media (max-width: $small-screen){
                            font-size: 14px;
                        }
                    }

                    .subtitle {
                        color: $color-gray3;
                        font-size: 16px;
                        text-decoration: none;
    
                        @media (max-width: $small-screen){
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        .wrap-learn-more{
            position: relative;
            padding: 267px 30px 0;

            @media (max-width: $large-screen) {
                padding: 237px 25px 230px;
            }

            @media (max-width: $large-screen) {
                padding: 150px 25px 200px;
            }

            @media (max-width: $medium-screen) {
                padding: 150px 25px 150px;
            }

            @media (max-width: $small-screen) {
                padding: 85px 15px 100px;
            }

            .wrap-content{
                @media (min-width: $desktop-screen-up){
                    margin-left: 6.25%;
                    -ms-flex: 0 0 37.4999999995%;
                    flex: 0 0 37.4999999995%;
                    max-width: 37.4999999995%;
                }

                .content{
                    @media (min-width: $desktop-screen-up){
                        padding-top: 45px;
                    }

                    @media (max-width: $large-screen) {
                        margin-bottom: 44px;
                    }

                    @media (max-width: $small-screen) {
                        margin-bottom: 20px;
                    }
                    
                    .title{
                        margin-bottom: 0;
                        color: $color-gray3;

                        @media (max-width: $small-screen) {
                            font-size: 24px;
                            margin-bottom: 0;
                        }
                    }

                    .desc{
                        margin-top: 18px;
                        margin-left: 105px;

                        @media (max-width: $desktop-screen) {
                            padding-left: 0;
                            margin-left: 88px;
                        }

                        @media (max-width: $small-screen) {
                            margin-left: 24px !important;
                            margin-top: 0;
                        }

                        p{
                            color: $color-gray2;
                            
                            @media (max-width: $large-screen) {
                                font-size: 22px;
                                line-height: 130.5%;
                            }

                            @media (max-width: $small-screen) {
                                font-size: 14px;
                                line-height: 22px;
                            }
                        }
                    }
                }
            }

            .img-button{
                position: relative;

                @media (max-width: $desktop-screen){
                    margin-top: 10px;
                }

                .img-section{
                    object-fit: cover;

                    @media(max-width: $desktop-screen){
                        height: 186px;
                    }

                    @media(max-width: $large-screen){
                        height: 263px;
                    }

                    @media (max-width: $medium-screen){
                        height: 194px;
                    }

                    @media (max-width: $small-screen) {
                        height: 90px;
                    }
                }

                .link-img{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    font-size: 25px;
                    z-index: 9;
                    top: 0;
                    left: 0;
                    color: $color-gray3;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: all 0.2s ease-in-out;

                    @media (max-width: $small-screen) {
                        font-size: 14px;
                    }

                    &:hover{
                        text-decoration: none;
                        color: $color-primary;
                        background: rgba(41, 40, 36, 0.80);

                        // &~ img{
                        //     opacity: 20%;
                        // }
                    }

                    &:active{
                        background: rgba(41, 40, 36, 0.80);
                    }

                    i{
                        margin-left: 5px;
                    }
                }
            }
        }
    }

    .cta-promo{
        position: relative;
        text-align: center;
        background-color: $color-gray2;
        padding: 58px 0 49px;
        
        @media (max-width: $desktop-screen) {
            padding: 58px 170px 49px;

            span {
                display: block;
            }
        } 

        @media (max-width: $large-screen) {
            padding: 58px 110px 49px;
        } 

        @media (max-width: $medium-screen) {
            padding: 72px 50px 61px;
        }

        @media (max-width: $small-screen) {
            padding: 58px 15px;
        }

        &:hover{
            background-color: $color-gray3;
        }

        h2{
            font-size: 40px;
            margin-bottom: 0;
            line-height: 120%;
            color: $color-dark1;
            line-height: 111.5%;

            @media (max-width: $medium-screen) {
                font-size: 32px;
            }

            @media (max-width: $small-screen) {
                font-size: 22px;
                text-align: center;
                line-height: 30px;
            }

            .pi{
                font-size: 26px;
                display: contents;

                @media (max-width: $small-screen) {
                    font-size: 18px;
                }
            }
        }

        a{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .promo-section{
        background-color: #9f9f9f;
        padding: 148px 30px 220px;
        position: relative;

        @media (max-width: $large-screen) {
            padding: 300px 25px 310px;
        }

        @media (max-width: $medium-screen) {
            padding: 250px 25px 260px;
        }

        @media (max-width: $small-screen) {
            padding: 137px 15px 147px;
        } 

        .content{
            text-align: center;

            .title{
                color: $color-dark1;
                font-family: $font-whyte;
                font-size: 96px;
                font-weight: 400;
                line-height: 130%;
                margin-bottom: 30px;

                @media (max-width: $small-screen) {
                    font-size: 56px;
                    margin-bottom: 8px;
                    margin-left: 0;
                    padding-left: 0;
                }
            }

            .desc{
                text-align: center;

                p{
                    color: $color-dark1; 
                    line-height: 23px;
                    margin-bottom: 40px;

                    @media (max-width: $medium-screen) {
                        font-size: 20px;
                    }

                    @media (max-width: $small-screen) {
                        font-size: 14px;
                        line-height: 22px;
                    }
                }

                .btn {
                    padding: 9px 70px 5px;

                    @media (max-width: $desktop-screen) {
                        padding: 9px 50px 5px;
                    }

                    @media (max-width: $small-screen) {
                        font-size: 14px;
                        padding: 9px 26px 5px;
                        height: 55px;
                    }
                }
            }
        }

        .desc-text{
            border-top: solid 1px $color-white;
            border-bottom: solid 1px $color-white;
            position: absolute;
            margin-top: 30px;
            bottom: 195px;
            left: 0;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            padding: 27px 30px 19px;

            @media (max-width: $desktop-screen) {
                bottom: 215px;
            }

            @media (max-width: $large-screen) {
                bottom: 300px;
            }

            @media (max-width: $medium-screen) {
                bottom: 250px;
            }

            @media (max-width: $small-screen) {
                bottom: 138px;
                padding: 6px 30px 6px;
                margin-top: 0;
            }
        
            &:hover {
                border-top: solid 1px $color-dark1;
                border-bottom: solid 1px $color-dark1;
                
                >div{
                    animation-play-state: paused;

                    a{
                        color: $color-dark1;
                        text-decoration: none;
                    }
                }
            }
        
            > div{
                margin-bottom: 0;
                font-size: 40px;
                font-family: $font-whyte;
                display: inline-block;
                will-change: transform;
                animation: marquee 30s linear infinite;
                color: $color-white;
                margin: 0 2px;

                @media (max-width: $small-screen) {
                    font-size: 22px;
                }

                a{
                    color: $color-gray3;

                    .label{
                        margin: 0 10px;
                    }
                }
            }
        
            @keyframes marquee {
                0%   { transform: translateX(0); }
                100% { transform: translateX(-100%); }
            }
        }
    }

    .faq-section{
        background-color: $color-dark1;
        padding: 113px 30px 345px;

        @media (max-width: $desktop-screen) {
            padding: 124px 25px 300px;
        }

        @media (max-width: $large-screen) {
            padding: 200px 25px 290px;
        }

        @media (max-width: $medium-screen) {
            padding: 150px 25px;
        }

        @media (max-width: $small-screen) {
            padding: 102px 15px 66px;
        }

        .wrap-title{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-left: auto;
            margin-right: 6.5%;

            @media (max-width: $large-screen) {
                justify-content: flex-end;
            }

            @media (max-width: $medium-screen) {
                margin-right: 0;
                justify-content: space-between;
            }
            
            .title{
                font-weight: normal;
                font-family: $font-whyte;
                font-size: 96px;

                @media (max-width: $large-screen) {
                    margin-left: 203px;
                }
    
                @media (max-width: $medium-screen) {
                    margin-left: 0;
                }

                @media (max-width: $small-screen) {
                    font-size: 57px;
                    margin-bottom: 0;
                }
            }
        }
    }
}

.carousel-main{
    padding-left: 30px;

    @media(max-width: $small-screen){
        padding-left: 15px;
    }
    
    .item{
        width: 100%;
        display: block;

        img{
            width: 100%;
        }
    }
}