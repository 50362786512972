import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from '@environments';
import { MainService } from "src/app/shared/services";
import { NgIf } from '@angular/common';
import { PrimeTemplate } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
declare const $:any;

@Component({
    selector: 'app-cropper-dialog',
    templateUrl: './cropper-dialog.component.html',
    styleUrls: ['./cropper-dialog.component.scss'],
    standalone: true,
    imports: [DialogModule, PrimeTemplate, NgIf]
})
export class CropperDialogComponent implements OnInit {

	@Input() show:boolean = false;
	@Input() image:string = "";
	@Input() type:string = "cover"; // "cover", "avatar", "splash"
	@Input() closable:boolean = true;
	@Input() disableDelete: boolean = false;

	@Output() onHide = new EventEmitter()
	@Output() onCropped = new EventEmitter();
	@Output() onDelete = new EventEmitter();
	@Output() onUpload = new EventEmitter();
	
	private cropper:any;
	public loadingCropping: boolean = false;
	public loadingDelete:boolean = false;

	constructor(
        public mainService:MainService,
    ){}
	
	ngOnInit(): void {}

	ngOnChanges(): void {
		const scriptPaths: string[] = [
			environment.staticAssets+'plugins/jquery/jquery-3.4.1.min.js?t='+this.mainService.appVersion,
			environment.staticAssets+'plugins/cropper/cropper.min.js?t='+this.mainService.appVersion,
			environment.staticAssets+'plugins/cropper/jquery-cropper.min.js?t='+this.mainService.appVersion
        ];
        this.mainService.loadScripts(scriptPaths).then(() => {
			this.initCropper();
		});
	}

	/**
	 * * ============================ *
	 * * LIST FUNCTIONS
	 * * ============================ *
	 * - EVENT ON HIDE DIALOG
	 * - CROPPING IMAGE
	 */


	initCropper(){
		setTimeout(()=>{
			if(this.mainService.isBrowser){
				if(this.cropper) this.cropper.cropper('destroy');
				this.cropper = $("#cropperImage");
				this.cropper.cropper(this.configCropper())
			}
		},100)
	}

	configCropper(): any {
		const square = {
			aspectRatio: 1 / 1,
			checkCrossOrigin: false,
			background:false,
			movable:false,
			zoomable:false,
			viewMode:2
		}
		const splah = {
			aspectRatio: 1.41 / 1,
			checkCrossOrigin: false,
			background:false,
			movable:false,
			zoomable:false,
			viewMode:2
		}

		if (this.type == "cover" || this.type == "avatar") {
			return square;
		} else if (this.type == "splash") {
			return splah;
		}
	}

	/**
	 * * EVENT ON HIDE DIALOG *
	 * Todo: event on hide dialog
	 */
	onHideDialog(){
		this.cropper.cropper('destroy');
		this.onHide.emit(false);
	}

	/**
	 * * CROPPING IMAGE *
	 * Todo: for cropping image 
	 */
	cropping(){
		const croppedImage = this.cropper.cropper('getCroppedCanvas',{maxWidth: 2000, maxHeight: 2000}).toDataURL('image/jpeg');
		this.onCropped.emit(croppedImage);
	}

	/**
	 * * DELETE CROPPER *
	 * Todo: delete cropper image
	 */
	delete(){
		this.onDelete.emit()
	}

	/**
	 * * UPLOAD NEW IMAGE *
	 * Todo: upload new image
	 */
	uploadNew(){
		this.onUpload.emit()
	}
}
