import { AbstractControl, ValidatorFn } from '@angular/forms';
import moment from 'moment';

export function validDate(format: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) return null;
    const dateString = control.value;
    if (dateString && !moment(dateString, format, true).isValid()) {
      return { 
        'validDate': true,
        'format': format
      };
    }
    return null;
  };
}
