<!-- Sidebar -->
<div class="sidebar">
    <div class="profile-avatar">
        <img [src]="userData()?.avatar ? convertPathImage(userData()?.avatar) : defaultAvatar ">
        <div class="user">
            <div class="username" #container>
                <span>{{(userData()?.first_name || '') +' '+ (userData()?.last_name || '')}}</span>
            </div>
            <p *ngIf="userData()?.medium">{{userData().medium}}</p>
        </div>
    </div>

    <div class="upgrade-plan" *ngIf="isInside && layoutsService.isExperimental && !whiteLabel">
        <button (click)="toPricingPage()" class="btn btn-primary btn-sm">Upgrade Your Plan</button>
    </div>

    <div class="menu" [class.outside]="!isInside">
        <ul>
            <li>
                <!-- general -->
                <a 
                    *ngIf="!whiteLabel"
                    [routerLink]="'/'+ userData()?.username"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}"
                    class="item-menu">
                    {{ isInside ? 'My Galleries' : 'Galleries'}}
                </a>

                <!-- white label -->
                <a 
                    *ngIf="whiteLabel"
                    (click)="whiteLabelService.infoPage=false"
                    routerLink="/"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}"
                    class="item-menu">
                    Galleries
                </a>
            </li>
            <li>
                <!-- general -->
                <a
                    *ngIf="!whiteLabel"
                    (click)="mainService.closeHeaderMessage();"
                    [routerLink]="'/'+ userData()?.username +'/info'"
                    routerLinkActive="active"
                    class="item-menu">
                    Information
                </a>

                <!-- white label -->
                <a
                    *ngIf="whiteLabel"
                    (click)="mainService.closeHeaderMessage();"
                    routerLink="/information"
                    routerLinkActive="active"
                    class="item-menu">
                    Information
                </a>
            </li>

            <ng-container *ngIf="isInside && !whiteLabel">
                <li class="creating">
                    <a
                        (click)="createExhibition()"
                        [class.active]="isCreateExhibition()"
                        class="item-menu">
                        Start Creating
                    </a>
                </li>
                <li>
                    <a
                        (click)="mainService.closeHeaderMessage()"
                        routerLink="/help"
                        routerLinkActive="active"
                        [class.active]="false"
                        class="item-menu">
                        Help
                    </a>
                </li>
                <li>
                    <a
                        (click)="toggleSettingsMenu(true)"
                        [class.active]="isActiveMenu('/settings/profile') || isActiveMenu('/settings/account') || isActiveMenu('/settings/billing')"
                        class="item-menu settings">
                        Settings
                    </a>
                </li>
            </ng-container>
        </ul>

        <!-- Sub menu settings -->
        <ul *ngIf="layoutsService.settingsMenu">
            <li>
                <a
                    (click)="mainService.closeHeaderMessage()"
                    routerLink="/settings/profile"
                    routerLinkActive="active"
                    class="item-menu item-menu-sub">
                    Profile Settings
                </a>
            </li>
            <li>
                <a
                    (click)="mainService.closeHeaderMessage()"
                    routerLink="/settings/account"
                    routerLinkActive="active"
                    class="item-menu item-menu-sub">
                    Account Settings
                </a>
            </li>
            <!-- <li>
                <a
                    (click)="mainService.closeHeaderMessage()"
                    [class.active]="false"
                    class="item-menu item-menu-sub">
                    Gallery Archive
                </a>
            </li> -->
            <li>
                <a
                    (click)="mainService.closeHeaderMessage()"
                    routerLink="/settings/billing"
                    routerLinkActive="active"
                    class="item-menu item-menu-sub">
                    Billing
                </a>
            </li>
        </ul>
    </div>
</div>