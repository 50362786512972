import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { messages } from '@data';
import { AlertMessageService } from 'src/app/components/alert-message/alert-message.service';
import { SubscriptionOverview } from '../billing.interfaces';
import { BillingService } from '../billing.service';
import moment from 'moment';
import { MainService } from 'src/app/shared/services';

import { debounce } from 'lodash';
import { ProgressBarModule } from 'primeng/progressbar';
import { Header, Footer, PrimeTemplate } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { RouterLink } from '@angular/router';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-pricing-plan',
    templateUrl: './pricing-plan.component.html',
    styleUrls: ['./pricing-plan.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, RouterLink, DialogModule, Header, ProgressBarModule, Footer, PrimeTemplate]
})
export class PricingPlanComponent implements AfterViewInit {
  public showDetail: boolean = false;
  public subscriptionOverview: SubscriptionOverview;
  public onFetchingSubscriptionOverview: boolean = false;
  public onUnsubscribing: boolean = false;
  public unsubscribeConfirm: boolean = false;
  public unsubcriptionSuccess: boolean = false;
  public manageSubscription: boolean = false;

  constructor(
    private _billingService: BillingService,
    private _messageService: AlertMessageService,
    public mainService: MainService,
    private cdRef: ChangeDetectorRef
  ) { }

  ngAfterViewInit(): void {
    this._getSubscriptionOverview();
  }

  /**
   * * GET SUBSCRIPTION OVERVIEW *
   * Todo: to get subscription overview
   */
  private _getSubscriptionOverview(refetch:boolean=false): void {
    const alert = messages.billing.subscriptionOverview;
    this.onFetchingSubscriptionOverview = true;
    this._billingService.getSubscriptionOverview(refetch).subscribe((response: any) => {
      if(this.mainService.isBrowser) {
        this._processSubscriptionOverviewData(response);
        this.onFetchingSubscriptionOverview = false;
        if (this.mainService.subscribeExperiment && !this.subscriptionOverview.unsubs) {
          this.unsubscribeConfirm = true;
        }
      }
    }, err => {
      this.onFetchingSubscriptionOverview = false;
      this._messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: alert.failed
      })
    })
  }

  /**
   * * PROCESS SUBSCRIPTION OVERVIEW DATA *
   * Todo: to process subscription overview data
   * @param response : any -> response from api
   */
  private _processSubscriptionOverviewData(response): void {
    this.subscriptionOverview = {...response.data.product_detail};
    this.subscriptionOverview.period_end = this._formatDate(this.subscriptionOverview.period_end);
    this.cdRef.detectChanges();
    this.subscriptionOverview.period_start = this._formatDate(this.subscriptionOverview.period_start);
    this.cdRef.detectChanges();
    this.subscriptionOverview.total_days = this._getTotalDays();
    this.subscriptionOverview.days_left = this._getDaysLeft();
    this.subscriptionOverview.progress = this._getSubscriptionProgress();
  }

  /**
   * * GET TOTAL DAYS *
   * Todo: to get total days
   * @returns : number
   */
  private _getTotalDays(): number {
    const start = moment(this.subscriptionOverview.period_start, 'MMM DD, YYYY');
    const end = moment(this.subscriptionOverview.period_end, 'MMM DD, YYYY');
    return end.diff(start, 'days');
  }

  /**
   * * GET DAYS LEFTS *
   * Todo: to get days left
   * @returns : number
   */
  private _getDaysLeft(): number {
    const start = moment().format('MMM DD, YYYY');
    const end = moment(this.subscriptionOverview.period_end, 'MMM DD, YYYY');
    return end.diff(start, 'days');
  }

  /**
   * * GET SUBSCRIPTION PROGRESS *
   * Todo: to get subscription progress
   * @returns : number
   */
  private _getSubscriptionProgress(): number {
    const totalDays = this._getTotalDays();
    const daysLeft = this._getDaysLeft();
    return 100 - Math.round((daysLeft / totalDays) * 100);
  }

  /**
   * * COVERT DATE FORMAT
   * Todo: to convert date format from YYYY-MM-DD HH:mm:ss to MM DD, YYYY
   * @param date : string -> YYYY-MM-DD HH:mm:ss
   * @returns : string -> MM DD, YYYY
   */
  private _formatDate(date: string): string {
    return moment(date, 'YYYY-MM-DD').format('MMM DD, YYYY');
  }

  /**
   * * UNSUBSCRIBE PRODUCT *
   * Todo: to unsubribe product
   */
  unsubcription = debounce(() => {
    this.onUnsubscribing = true;
    this._billingService.unsubscribe().subscribe((res) => {
      this._getSubscriptionOverview(true);
      this.onUnsubscribing = false;
      this.unsubscribeConfirm = false;
      this.manageSubscription = false;
      this.unsubcriptionSuccess = true;
    }, err => {
      this.onUnsubscribing = false;  
    });
  }, 500)
}
