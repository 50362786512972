<app-layouts>
    <div class="wrap-virtual-gallery" content-editor>
        <div class="sidebar">
            <!-- START MAIN MENU
            ==================================== -->
            <div class="main-menu-icon">
                <!-- LEFT MENU MAIN -->
                <div  
                    class="left-menu">
                    <ul>
                        <!-- SAVE ICON -->
                        <li 
                            data-tips-feature="Feature 4"
                            class="item-menu-icon save-gallery"
                            pTooltip="Save"
                            tooltipPosition="bottom"
                            placeholder="Bottom">
                            <button
                                [disabled]="isSaveChangesDisable()"
                                (click)="editorService.exhibition?.published ? publishExhibition(true) : saveAllChanges()"
                                [class.disable-icon]="isSaveChangesDisable()"
                                class="item-btn">
                                <i *ngIf="!onUpdate && !editorService.onSavingData" class="vi vi-save"></i>
                                <i *ngIf="onUpdate || editorService.onSavingData" class="pi pi-spin pi-spinner"></i>
                            </button>
                        </li>

                        <!-- PUBLISH ICON -->
                        <li 
                            data-tips-feature="Feature 5"
                            class="item-menu-icon publish-gallery" 
                            [pTooltip]="editorService.exhibition?.published ? 'Unpublish' : 'Publish'"
                            tooltipPosition="bottom" 
                            placeholder="Bottom">
                            <button
                                [disabled]="isPublishDisable()"
                                (click)="publishExhibition()"
                                [class.disable-icon]="isPublishDisable()"
                                class="item-btn">
                                <ng-container *ngIf="!onPublish">
                                    <i *ngIf="editorService.exhibition?.published" class="vi vi-unuploading"></i>
                                    <i *ngIf="!editorService.exhibition?.published" class="vi vi-upload"></i>
                                </ng-container>
                                <i *ngIf="onPublish" class="pi pi-spin pi-spinner"></i>
                            </button>
                        </li>

                        <!-- HELP ICON -->
                        <li 
                            class="item-menu-icon"
                            pTooltip="Help"
                            tooltipPosition="bottom" 
                            placeholder="Bottom">
                            <button
                                [disabled]="isHelpDisable()"
                                [class.disable-icon]="isHelpDisable()"
                                class="item-btn" 
                                (click)="showHelp = !showHelp">
                                <i class="vi vi-help-circle"></i>
                            </button>
                        </li>

                        <!-- PREVIEW ICON -->
                        <li 
                            class="item-menu-icon" 
                            pTooltip="Preview" 
                            tooltipPosition="bottom" 
                            placeholder="Bottom">
                            <button
                                [disabled]="isPreviewDisable()"
                                class="item-btn"
                                [class.disable-icon]="isPreviewDisable()"
                                (click)="previewMode()">
                                <i class="vi vi-play" *ngIf="!editorService.previewMode"></i>
                                <i class="pi pi-pause" *ngIf="editorService.previewMode"></i>
                            </button>
                        </li>

                        <!-- UNDO ICON -->
                        <li 
                            class="item-menu-icon" 
                            pTooltip="Undo" 
                            tooltipPosition="bottom" 
                            placeholder="Bottom">
                            <button
                                [disabled]="isUndoDisable()||undoRedoService.onRedo"
                                [class.disable-icon]="isUndoDisable()"
                                (click)="editorService.undoRedo('undo')"
                                class="item-btn">
                                <i *ngIf="!undoRedoService.onUndo" class="vi vi-undo"></i>
                                <i *ngIf="undoRedoService.onUndo" class="pi pi-spin pi-spinner"></i>
                            </button>
                        </li>

                        <!-- REDO ICON -->
                        <li 
                            class="item-menu-icon" 
                            pTooltip="Redo" 
                            tooltipPosition="bottom" 
                            placeholder="Bottom">
                            <button
                                [disabled]="isRedoDisable()||undoRedoService.onUndo"
                                [class.disable-icon]="isRedoDisable()"
                                (click)="editorService.undoRedo('redo')"
                                class="item-btn">
                                <i *ngIf="!undoRedoService.onRedo" class="vi vi-redo"></i>
                                <i *ngIf="undoRedoService.onRedo" class="pi pi-spin pi-spinner"></i>
                            </button>
                        </li>
                    </ul>
                </div>
                <!-- RIGHT MENU MEIN -->
                <div class="right-menu">
                    <ul>
                        <!-- DELETE ICON -->
                        <li
                            class="item-menu-icon"
                            pTooltip="Delete Gallery"
                            tooltipPosition="bottom"
                            placeholder="Bottom">
                            <button
                                [disabled]="isDeleteDisable()"
                                (click)="displayDeleteExhibition = true"
                                [class.disable-icon]="isDeleteDisable()"
                                class="item-btn">
                                <i class="vi vi-delete"></i>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- END MAIN MANU -->

            <!-- TITLE EXHIBITION
            ============================================ -->
            <div 
                class="wrap-title"
                pTooltip="Exhibition Title"
                tooltipPosition="right"
                placeholder="Right">
                <div class="title">
                    <input
                        type="text"
                        placeholder="Exhibition"
                        #renameInput
                        (focus)="editorService.onInput=true"
                        (blur)="editorService.onInput=false"
                        (keydown)="mainService.blockChars($event); mainService.blockSpaceAtStart($event, renameInput)"
                        (input)="renameExhibition()"
                        [ngModel]="editorService.exhibition && editorService.exhibition.name" (ngModelChange)="editorService.exhibition && (editorService.exhibition.name = $event)"
                        [readonly]="isRenameExhibitionDisable()">
                </div>
            </div>

            <ng-container *ngIf="editorService.activeTab as activeTab">
                <!-- START MENU
                ========================== -->
                <div class="menu">
                    <!-- WRAP LIST MENU -->
                    <ul class="nav nav-tabs">
                        <li class="nav-item flex-fill" [class.active]="activeTab=='images'">
                            <a 
                                class="nav-link" 
                                (click)="openEditorSection('images')" 
                                href="javascript:;">
                                Artworks
                            </a>
                        </li>
                        <li class="nav-item flex-fill" [class.active]="activeTab=='objects'">
                            <a 
                                class="nav-link" 
                                (click)="openEditorSection('objects')" 
                                href="javascript:;">
                                Objects
                            </a>
                        </li>
                        <li class="nav-item flex-fill" [class.active]="activeTab=='text'">
                            <a 
                                class="nav-link" 
                                (click)="openEditorSection('text')" 
                                href="javascript:;">
                                Text
                            </a>
                        </li>
                        <li class="nav-item flex-fill" [class.active]="activeTab=='general'">
                            <a 
                                class="nav-link" 
                                (click)="openEditorSection('general')" 
                                href="javascript:;">
                                Settings
                            </a>
                        </li>
                        <li class="nav-item flex-fill" [class.active]="activeTab=='publish'">
                            <a 
                                class="nav-link" 
                                (click)="openEditorSection('publish')" 
                                href="javascript:;">
                                Publish
                            </a>
                        </li>
                    </ul>
                </div>
                <!-- END MENU -->

                <!-- ITEM PAGE -->
                <div>
                    <!-- GENERAL COMPONENT -->
                    <app-general *ngIf="activeTab == 'general'"></app-general>
                    <!-- OBJECTS  COMPONENT -->
                    <app-objects *ngIf="activeTab == 'objects'"></app-objects>
                    <!-- IMAGE COMPONENT -->
                    <app-images *ngIf="activeTab == 'images'"></app-images>
                    <!-- TEXT COMPONENT -->
                    <app-text *ngIf="activeTab == 'text'"></app-text>
                    <!-- PUBLISH COMPONENT -->
                    <app-publish
                        *ngIf="activeTab == 'publish'"
                        [enableSplashScreen]="editorService.exhibition.enableSplashScreen">
                    </app-publish>
                </div>
            </ng-container>
        </div>

        <!-- WRAP VIRTUAL GALLERY
        ======================================== -->
        <div class="wrap-canvas">
            <!-- VIRTUAL GALLERY -->
            <canvas touch-action="none" id="editorCanvas"></canvas>

            <!-- SCREEN VIEW -->
            <div class="view-screen-menu" *ngIf="!loadingGalleryService.show">
                <ul *ngIf="editorService.deviceScreen as device">
                    <li class="item-menu-screen">
                        <button 
                            class="btn-screen" 
                            [class.active]="device == 'mobile'" 
                            (click)="getWidthDisplay('mobile', 498)">
                            <i class="vi vi-phone"></i>
                        </button>
                    </li>
                    <li class="item-menu-screen">
                        <button 
                            data-tips-feature="Feature 6"
                            class="btn-screen responsive-gallery" 
                            [class.active]="device == 'tablet'" 
                            (click)="getWidthDisplay('tablet')">
                            <i class="vi vi-ipad"></i>
                        </button>
                    </li>
                    <li class="item-menu-screen">
                        <button 
                            class="btn-screen" 
                            [class.active]="device == 'desktop'" 
                            (click)="getWidthDisplay('desktop')">
                            <i class="vi vi-monitor"></i>
                        </button>
                    </li>
                </ul>
            </div>
            <div class="wrap-screen">
                <div class="overlay-canvas" [hidden]="!editorService.showOverlayDisplay"></div>
                <div class="display" [ngStyle]="{'width.px': editorService.widthDisplay}"></div>
                <div class="overlay-canvas" [hidden]="!editorService.showOverlayDisplay"></div>
            </div>
            
            <loading-gallery></loading-gallery>
        </div>
    </div>


    <p-dialog
        [(visible)]="displayDeleteExhibition"
        [modal]="true"
        [style]="{maxWidth: '725px'}"
        [baseZIndex]="10000"
        appendTo="body"
        [closable]="!onDeleting"
        [draggable]="false"
        [resizable]="false"
        styleClass="confirm-delete-dark">
        <div class="wrap-content-confirm">
            <div class="desc">
                <h3>Are you sure you want to delete this gallery?</h3>
                <p>If you press “Yes”, your gallery will be deleted permanently and will not be recoverable.</p>
            </div>
            <div class="wrap-btn">
                <button
                    [disabled]="onDeleting"
                    class="btn btn-light btn-sm"
                    (click)="deleteExhibition()"
                    [class.loading-button-primary]="onDeleting">
                    Yes
                </button>
                <button [disabled]="onDeleting" class="btn btn-primary btn-sm mr0" (click)="displayDeleteExhibition=false">No</button>
            </div>
        </div>
    </p-dialog>

</app-layouts>

<app-not-subs-dialog
    [showNotSubs]="editorService.showNotSubscribePopup"
    (onHide)="editorService.showNotSubscribePopup=$event">
</app-not-subs-dialog>

<!-- HELP -->
<app-help [showHelp]="showHelp"></app-help>
<div class="block-content" [class.on-block-content]="showHelp" (click)="showHelp = !showHelp"></div>

<div class="tour add-artwork" data-tips-feature="Feature 1"></div>
<div class="tour tab-menu" data-tips-feature="Feature 2"></div>
<div class="tour gallery-name" data-tips-feature="Feature 3"></div>
