import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MainService } from '@services';
import { AlertMessageService } from '../alert-message/alert-message.service';
import { environment } from '@environments';
import { PricingService } from 'src/app/pages/pricing/pricing.service';
import { Router } from '@angular/router';
import { PrimeTemplate } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';

// Third-party packages (cdn)
declare const pdfjsLib: any;

@Component({
    selector: 'app-pending-payment',
    templateUrl: './pending-payment.component.html',
    styleUrls: ['./pending-payment.component.scss'],
    standalone: true,
    imports: [DialogModule, PrimeTemplate]
})
export class PendingPaymentComponent implements OnInit {
  private _CANVAS: HTMLCanvasElement;
  private _PDF_DOC: any;

  @Input() urlInvoice: string = '';
  @Input() showAuthDialog: boolean = false;
  @Input() productDetail: string = '';
  @Output() onHide = new EventEmitter();

  public showInvoiceDialog: boolean = false;
  public cancelAuthDialog: boolean = false;
  public noInvoice: boolean = false;

  constructor(
    public mainService: MainService,
    private _messageService: AlertMessageService,
    private _pricingService: PricingService,
    private _router: Router,
  ) {}

  ngOnInit(): void {
  }

  /**
   * * ON CALCEL AUTH DIALOG *
   * Todo: to handle cancel auth dialog
   */
  public onCancel(close:boolean) {
    this.onHide.emit();

    if (close) return;
    else this.showInvoiceDialog = true;
  }

  /**
   * * CHECK INVOICE *
   * Todo: to check invoice
   */
  checkInvoice() {
    if (!this.urlInvoice) this.noInvoice = true;
  }

  /**
   * * INITIALIZE AND LOAD THE PDF *
   * Todo: initialize and load the pdf file
   */
  public showPDF() {
    this.mainService.loadScripts([environment.staticAssets + 'plugins/pdf-viewer/pdf.min.js?t=' + this.mainService.appVersion]).then(async () => {
      this._CANVAS = document.querySelector('#pdf-canvas') as HTMLCanvasElement;

      // get handle of pdf document
      try {
        this._PDF_DOC =  await pdfjsLib.getDocument({ url: this.urlInvoice });
        this._showInvoice();
      }
      catch(error) {
        this.showInvoiceDialog = false;
        this._messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to load pdf'
        });
      }
    });
  }

  /**
   * * SHOW INVOICE *
   * Todo: to view invoice file
   */
  private async _showInvoice() {
    try {
      const page = await this._PDF_DOC.getPage(1);
      const pdf_original_width = page.getViewport(1).width;
      const scale_required = this._CANVAS.width / pdf_original_width;
      const viewport = page.getViewport(scale_required);

      this._CANVAS.height = viewport.height;
      const render_context = {
          canvasContext: this._CANVAS.getContext('2d'),
          viewport: viewport
      };

      await page.render(render_context);
    }
    catch(error) {
      this._messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to load PDF'
      });
    }
  }

  /**
   * * CREATE CHECKOUT STRIPE *
   * Todo: to cteate checkout stripe
   */
  public AuthentificationSubscribe() {
    this.showAuthDialog = false;
    
    const productData = {
      payment: this.productDetail,
      success_url: `${environment.base_host}/save-invoices`,
      cancel_url: environment.base_host + this._router.url,
    }

    // create stripe checkout session
    this._pricingService.subscribeProduct(productData).subscribe((res)=>{
      const url = res['data'].link;
      window.open(url, '_self');
    }, (err) => {
      this._messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Something went wrong. Please try again.'
      });
    })
  }
}
