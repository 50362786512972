<p-dialog
    [(visible)]="show"
    [modal]="true" 
    [baseZIndex]="500"
    (onHide)="onHideDialog()"
    [closable]="false"
    [style]="{width: '960px'}"
    [draggable]="false" 
    styleClass="edit-cover-image"
    [resizable]="false">
    <ng-template pTemplate="header">
        <button class="btn-close" (click)="show=false"><i class="vi vi-x"></i></button>
    </ng-template>
    <div class="content-cover-image">   
        <h2 class="title">Crop Image</h2>
    
        <div class="wrap-cover-image">
            <img width="100%" id="cropperImage" crossorigin="anonymous" [src]="image">
        </div>

        <div class="wrap-btn-cover">
            <button *ngIf="type=='avatar'"
                (click)="show=false"
                [disabled]="loadingDelete||loadingCropping"
                class="btn btn-primary btn-xs">
                Cancel
            </button>
            <div *ngIf="type=='cover'||'splash'" class="d-flex">
                <button 
                    (click)="delete()"
                    [disabled]="loadingDelete||loadingCropping||disableDelete"
                    class="btn btn-light btn-xs btn-delete mr30" 
                    [style]="{'width': '78px'}"
                    [class.flex-center]="loadingDelete"
                    [class.loading-button-primary]="loadingDelete">
                    Delete
                </button>
                <button 
                    (click)="uploadNew()"
                    [disabled]="loadingDelete||loadingCropping"
                    class="btn btn-light btn-xs" >
                    Upload New
                </button>
            </div>
            <button 
                (click)="cropping()"
                [disabled]="loadingDelete||loadingCropping"
                class="btn btn-primary btn-xs"
                [style]="{'width': '113px'}"
                [class.loading-button-primary]="loadingCropping">
                Continue <i class="pi pi-arrow-right" style="font-size: 12px"></i>
            </button>
        </div>
    </div>
</p-dialog>