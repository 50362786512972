// Angular packages
import { Component, OnDestroy, OnInit } from '@angular/core';

// Third-party packages (npm)
import watch from 'redux-watch';

// User-defined services
import { store } from 'src/app/shared/services';
import { EditorService } from '../../editor.service';
import { FormsModule } from '@angular/forms';
import { SliderModule } from 'primeng/slider';
import { InputNumberComponent } from '../../../../../components/input-number/input-number.component';

@Component({
    selector: 'app-lighting-setting',
    templateUrl: './lighting-setting.component.html',
    styleUrls: ['./lighting-setting.component.scss'],
    standalone: true,
    imports: [InputNumberComponent, SliderModule, FormsModule]
})
export class LightingSettingComponent implements OnInit, OnDestroy {
  public lightIntensityValue: number = 2;
  private _stateWatcher: any;

  constructor(
    public editorService: EditorService
  ) { 
    this._initReduxStateWatchers();
  }

  ngOnInit(): void {
    this._setLightIntensityValue();
  }

  ngOnDestroy(): void { 
    this._stateWatcher();
  }

  /**
   * ANCHOR Set Light Intensity Value
   * @description Set Light Intensity Value
   */
  private _setLightIntensityValue(): void {
    this.lightIntensityValue = Math.round(this.editorService.activeArtwork.light_intensity * 20);
  }

  /**
   * ANCHOR Adjust Light Intensity Value
   * @description Adjust Light Intensity Value
   */
  public adjustLightIntensityValue(value: number): void {
    this.lightIntensityValue = value;
    const realValue = this.lightIntensityValue / 20;
    this.editorService.activeArtwork.light_intensity = realValue;
    this.editorService.activeArtworkNode.getChildren().forEach((child: any) => {
      child.material.environmentIntensity = realValue / 2;
    });

    this.editorService.updateUndoRedoStateWithDelay();
  }

  /**
   * ANCHOR Initialize Redux State Watchers
   * @description Initialize Redux State Watchers
   */
  private _initReduxStateWatchers(): void {
    const selectArtworkWatcher = watch(store.getState, 'editor.objectHasSelected')
		this._stateWatcher = store.subscribe(selectArtworkWatcher((e: boolean) => {
      if (e && this.editorService.activeArtworkNode) {
        this._setLightIntensityValue()
      }
		}));
  }

}
