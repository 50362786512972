<app-layouts #layouts>
    <div class="wrapper" content>
        <div class="tutorials">
            <h3 class="tutorials__title">Creating an Exhibition</h3>
            <button *ngIf="!layoutsService.sideMenu" class="btn btn-light2 btn-sm tutorials__button" (click)="back()">
                <i class="vi vi-arrow-left"></i>
            </button>
        </div>

        <div class="video offset16-4 offset-iped-1 offset-mobile-0 col16-8 col-iped-6 col-mobile-4">
            <div class="video__item">
                <iframe src="https://cdn.jwplayer.com/players/PrSP2x4E-GiW59uqF.html" width="100%" height="100%" frameborder="0" scrolling="auto" title="Getting Started with Villume" style="position:absolute;" allowfullscreen></iframe>
            </div>
        </div>
    </div>
</app-layouts>