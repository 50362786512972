<div class="benner-first-time" *ngIf="false">
    <div class="row">
        <div class="col col16-14 m-auto">
            <div class="d-flex align-items-center justify-content-between">
                <div class="desc">
                    We use cookies on this website to enhance your user expirience.<br />
                    By continuing to use this website, you consent to the use of cookies in accordance with our <a href="javascript:;">Privacy & Cookie Policy.</a>
                </div>
                <button class="btn btn-primary">Accept</button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="mainService.showMSG" class="beta-message">
    {{headerMsg}}
    <a (click)="mainService.closeHeaderMessage()" href="javascript:;" class="close-massage">
        <i class="vi vi-x"></i>
    </a>
</div>
<div class="header-holder"></div>
<nav class="wrap-navigation fixed" [style.top.px]="mainService.showMSG?40:0" [class.widget]="this.mainService.createExhibitionWidget">
    <!-- START BRAND -->
    <div class="brand">
        <ng-container *ngIf="!whiteLabelService.whiteLabel">
            <a href="javascript:;" routerLink="/" (click)="layoutsService.showMenu=false;mainService.closeHeaderMessage()">
                <img src="{{env.staticAssets}}images/other/logo.svg?t={{mainService.appVersion}}" alt="" />
                <!-- <span class="logo-beta-vesion-responsive">beta</span> -->
            </a>
        </ng-container>
    </div>
    <div class="row">
        <div *ngIf="!whiteLabelService.whiteLabel" class="col col16-1">
            <a href="javascript:;" class="main-box" routerLink="/" (click)="layoutsService.showMenu=false;mainService.closeHeaderMessage()">
                <img src="{{env.staticAssets}}images/other/yellow-box.png?t={{mainService.appVersion}}" alt="" />
                <!-- <span class="logo-beta-vesion">beta</span> -->
            </a>
        </div>
        <!-- START MENU -->
        <div class="col col16-6">
            <ul class="main-menu">
                <ng-container *ngTemplateOutlet="menu"></ng-container>
            </ul>
        </div>
        <div class="col col16-5 offset16-4" *ngIf="!mainService.userInfo && !whiteLabelService.whiteLabel">
            <div class="wrap-attr">
                <!-- START SEARCH -->
                <div class="flex-fill wrap-search">
                    <ng-container *ngIf="showSearch">
                        <ng-container *ngTemplateOutlet="search; context:{method:onSearch}"></ng-container>
                    </ng-container>
                </div>
                <!-- START SINGLE LINK -->
                <div class="pt-2 pl-3" class="single-link">
                    <a *ngIf="!layoutsService.loadingProcess && !whiteLabelService.whiteLabel" href="javascript:;" class="login-menu" (click)="login()">Log in</a>
                    <a *ngIf="layoutsService.loadingProcess" href="javascript:;" class="login-menu">Loading...</a>
                    <a href="javascript:;" class="toggle-bar" (click)="layoutsService.showMenu = !layoutsService.showMenu">
                        <img *ngIf="!layoutsService.showMenu" src="{{env.staticAssets}}images/icon/icon-bar.png?t={{mainService.appVersion}}" alt=""/>
                        <img *ngIf="layoutsService.showMenu" src="{{env.staticAssets}}images/icon/icon-close.png?t={{mainService.appVersion}}" alt=""/>
                    </a>
                </div>
                <!-- START LANGUAGE -->
                <!-- <div class="header-language">
                    <ng-container *ngTemplateOutlet="tlanguage"></ng-container>
                </div> -->
            </div>
        </div>
        <div class="col wrap-dropdown" *ngIf="mainService.userInfo && !whiteLabelService.whiteLabel">
            <div class="dropdown">
                <a *ngIf="!onLogout && !whiteLabelService.whiteLabel" href="javascript:;" class="dropdown-toggle">
                    {{mainService.userInfo.username}}
                </a>
                <a *ngIf="onLogout" href="javascript:;" class="dropdown-toggle">
                    Loading...
                </a>
                <a href="javascript:;" class="toggle-bar" (click)="layoutsService.showMenu = !layoutsService.showMenu; op.hide($event)">
                    <img *ngIf="!layoutsService.showMenu" src="{{env.staticAssets}}images/icon/icon-bar.png?t={{mainService.appVersion}}" alt=""/>
                    <img *ngIf="layoutsService.showMenu" src="{{env.staticAssets}}images/icon/icon-close.png?t={{mainService.appVersion}}" alt=""/>
                </a>

                <ul class="user-menu">
                    <li class="user-item">
                        <a
                            class="user-item--link"
                            [class.active]="page=='profile'" 
                            [routerLink]="['/',mainService.userInfo.username]">
                            Profile
                        </a>
                    </li>
                    <li class="user-item">
                        <a 
                            class="user-item--link" 
                            [class.active]="page=='account'"
                            (click)="mainService.closeHeaderMessage()"
                            routerLink="/settings/profile">
                            Settings
                        </a>
                    </li>
                    <li class="user-item">
                        <a 
                            class="user-item--link"
                            (click)="logout();mainService.closeHeaderMessage()">
                            {{ onLogout ? 'loading...' : 'Sign Out'}}
                        </a>
                    </li>
                </ul>

                <!-- <p-overlayPanel #op [baseZIndex]="1100" styleClass="show-menu-user">
                    <ng-template pTemplate="content">
                        <div class="user_menu">
                            <a class="user_menu__item" [class.active]="page=='profile'" routerLink="/{{mainService.userInfo.username}}" href="javascript:;">Profile</a>
                            <a class="user_menu__item" [class.active]="page=='account'" routerLink="/settings/profile" href="javascript:;">Settings</a>
                            <a class="user_menu__item" href="javascript:;" (click)="logout()">
                                {{ onLogout ? 'loading...' : 'Sign Out'}}
                            </a>
                        </div>
                    </ng-template>
                </p-overlayPanel> -->
            </div>
        </div>

        <!-- white label -->
        <div class="col wrap-dropdown" *ngIf="whiteLabelService.whiteLabel">
            <div class="dropdown">
                <a *ngIf="!onLogout" href="javascript:;" class="dropdown-toggle">
                    {{whiteLabelService.whiteLabelUsername}}
                </a>
                <a href="javascript:;" class="toggle-bar" (click)="layoutsService.showMenu = !layoutsService.showMenu; op.hide($event)">
                    <img *ngIf="!layoutsService.showMenu" src="{{env.staticAssets}}images/icon/icon-bar.png?t={{mainService.appVersion}}" alt=""/>
                    <img *ngIf="layoutsService.showMenu" src="{{env.staticAssets}}images/icon/icon-close.png?t={{mainService.appVersion}}" alt=""/>
                </a>
            </div>
        </div>
    </div>

    <!-- START OVERLAY RESPONSIVE -->
    <div class="overlay-responsive" [style]="{'margin-left': layoutsService.showMenu ? 0 : '-105%'}">
        <div class="main-content">
            <div class="d-flex">
                <!-- <div class="overlay-language">
                    <ng-container *ngTemplateOutlet="tlanguage; context:{styleclass:'lg'}"></ng-container>
                </div> -->
            </div>
            <div class="overlay-menu">
                <ul>
                    <ng-container *ngTemplateOutlet="menu"></ng-container>
                </ul>
            </div>
            <!-- <div class="overlay-language-small">
                <ng-container *ngTemplateOutlet="tlanguage"></ng-container>
            </div> -->
        </div>
        <div class="footer-content">
            <ul>
                <li>
                    <a href="javascript:;" (click)="mainService.closeHeaderMessage()" class="link-social">
                        <i class="vi vi-facebook"></i>
                    </a>
                </li>
                <li>
                    <a href="javascript:;" (click)="mainService.closeHeaderMessage()" class="link-social">
                        <i class="vi vi-instagram"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <!-- END OVERLAY RESPONSIVE -->
</nav>

<ng-template #menu>
    <li *ngIf="!mainService.userInfo">
        <a *ngIf="!layoutsService.loadingProcess && !whiteLabelService.whiteLabel" href="javascript:;" (click)="login()" class="login-on-responsive">Log In</a>
        <a *ngIf="layoutsService.loadingProcess" href="javascript:;"  class="login-on-responsive">Loading...</a>
    </li>
    <li *ngIf="!mainService.userInfo && !whiteLabelService.whiteLabel">
        <a 
            href="javascript:;" 
            routerLink="/catalogue" 
            routerLinkActive="active" 
            [translate]="'t_menu.t_templates'" 
            (click)="layoutsService.showMenu=false;mainService.closeHeaderMessage()">
            Templates</a>
    </li>
    <li *ngIf="mainService.userInfo || !mainService.userInfo && whiteLabelService.whiteLabel">
        <a 
            *ngIf="!whiteLabelService.whiteLabel"
            href="javascript:;" 
            routerLink="/{{mainService.userInfo.username}}" 
            routerLinkActive="active" 
            [translate]="'t_menu.t_galleries'"
            (click)="layoutsService.showMenu=false">
            My Galleries</a>

        <!-- white label -->
        <a 
            *ngIf="whiteLabelService.whiteLabel"
            href="javascript:;" 
            class="active pl-0"
            [translate]="'t_menu.t_galleries'"
            (click)="layoutsService.showMenu=false">
            My Galleries</a>
    </li>
    <li *ngIf="!whiteLabelService.whiteLabel">
        <a 
            href="javascript:;" 
            routerLink="/pricing" 
            routerLinkActive="active" 
            [translate]="'t_menu.t_pricing'"
            (click)="layoutsService.showMenu=false;mainService.closeHeaderMessage()">
            Pricing</a>
    </li>
    <li *ngIf="!whiteLabelService.whiteLabel">
        <a 
            href="https://news.villume.com" 
            (click)="mainService.closeHeaderMessage()"
            target="_blank">
            News</a>
    </li>
    <li 
        class="support-menu"
        *ngIf="mainService.userInfo?.user_type=='support'||(mainService.userInfo?.user_type=='user'&&(supportToken!=null&&supportToken!=''))">
        <!-- for support account only-->
        <a  
            class="parent-menu"
            href="javascript:;" 
            [translate]="'t_menu.t_support'">
            Support</a>

        <ul class="support-menu sub">
            <li class="support-item sub">
                <a *ngIf="mainService.userInfo?.user_type=='support'"
                    class="menu__item"
                    href="javascript:;"
                    routerLink="/support"
                    (click)="layoutsService.showMenu=false;mainService.closeHeaderMessage()">
                    Clients</a>
                <a *ngIf="mainService.userInfo?.user_type=='user'&&(supportToken!=null&&supportToken!='')"
                    class="menu__item"
                    href="javascript:;" 
                    (click)="backToSupport('support');layoutsService.showMenu=false;mainService.closeHeaderMessage()">
                    Clients</a>
            </li>
            <li class="support-item">
                <a 
                    *ngIf="mainService.userInfo?.user_type=='support'"
                    class="menu__item" 
                    routerLink="/promocodes" 
                    (click)="layoutsService.showMenu=false;mainService.closeHeaderMessage()"
                    href="javascript:;">
                    Promo Codes</a>
                <a 
                    *ngIf="mainService.userInfo?.user_type=='user'&&(supportToken!=null&&supportToken!='')"
                    class="menu__item"
                    href="javascript:;" 
                    (click)="backToSupport('promocodes');layoutsService.showMenu=false;mainService.closeHeaderMessage()">
                    Promo Codes</a>
            </li>
        </ul>
    </li>
    <li *ngIf="mainService.userInfo">
        <a *ngIf="!onLogout" href="javascript:;" (click)="logout()" class="menu-logout">Sign Out</a>
        <a *ngIf="onLogout" href="javascript:;" class="menu-logout">Loading...</a>
    </li>
</ng-template>

<ng-template #search let-method="method">
    <div class="flex-fill">
        <!-- <input type="text" class="search" placeholder="{{ 't_menu.t_search' | translate }}" [(ngModel)]="keyword" (keyup)="method($event)"> -->
    </div>
</ng-template>

<ng-template #tlanguage let-styleclass="styleclass">
    <!-- <p-dropdown [options]="mainService.arrLanguages2" [(ngModel)]="selectedLanguage" (onChange)="changeLanguage($event)" optionLabel="name" dropdownIcon="vi vi-arrow-down" styleClass="{{styleclass}}">
        <ng-template let-item pTemplate="item">
            <div class="item-dropdown">
                <div>{{item.name}}</div>
                <i class="vi vi-arrow-left"></i>
            </div>
        </ng-template>
    </p-dropdown> -->
</ng-template> 