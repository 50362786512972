import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { MainService, store } from 'src/app/shared/services';
import { environment } from '@environments';

import { UpdateUserParams } from 'src/app/shared/interfaces';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SettingsService {

    constructor(
        public mainService:MainService,
        private http:HttpClient,
    ) {}

    fetchUser(refetch: boolean = false) {
        return new Promise((resolve, reject) => {
            this.mainService.getUserProfile(refetch).subscribe((res: any) => {
                this.mainService.userInfo = res?.data?.result[0];
                resolve(this.mainService.userInfo);
            });
        });
    }

    fetchPricing() {
        this.mainService.getBillingDetail().subscribe((res: any) => {
            store.dispatch({ type:"UPDATE_DETAIL_PRICING", detailPricing: res?.data?.product_detail });
        });
    }

	// ======================== //
	// Update data Password
	// ====================== //
    updatePassword(encriptPassword) {
        // Hit to Database
        let query = `
            mutation {
                update_users(
                    _set: {
                        password: "${encriptPassword}"
                    }
                ) {
                    username,
                    password
                }
            }
        `;
        return this.mainService.queryGraphql(query);
    }

	// ======================== //
	// Update data Password
	// ====================== //
    getBillingIdByUser() {

        // Hit to Database
        let query = `
            {
                billing_address(where: {user_id: {_eq: "${this.mainService.userInfo.id}"}}) {
                    id
                }
            }          
        `;
        return this.mainService.queryGraphql(query)
    }

    
	// ======================== //
	// Change Card
	// ====================== //
    changeCard(card_id, cardNumber, source, billingId) {
           
        const body = {
            card_number : cardNumber,
            source : source,
            billing_id : billingId,
            card_id: card_id,
        }

        return this.http.post(`${environment.baseURL}/billing/change-card`, body);
    }

    // ======================== //
	// Change Card
	// ====================== //
    deleteCard(cardId, billingId) {
        const body = {
            card_id : cardId,
            billing_id : billingId,
        }

        return this.http.post(`${environment.baseURL}/billing/delete-card`, body);
    }

	// ======================== //
	// Change Card
	// ====================== //
    unsubcription() {
		return this.http.post(`${environment.baseURL}/billing/unsubs`, null);
    }
    
	// ======================== //
	// Save Account
	// ====================== //
    saveAccount(email, gender) {
        let query = `
            mutation {
                update_users(where: {id: {_eq: "${this.mainService.userInfo.id}"}}, _set: {email: "${email}", gender: "${gender}"}) {
                    affected_rows
                }
            }
        `;
        
        return this.mainService.queryGraphql(query);
    }

	// ======================== //
	// List Invoice
	// ====================== //
    getHistoryInvoice(fromDate, toDate, limit, page) {
        const options = {
            params: new HttpParams({
                fromObject: {
                    from_date: fromDate,
                    to_date: toDate,
                    limit: limit,
                    page: page,
                }
            })
        };
        let base_Url = this.mainService.isBrowser ? environment.baseURL : environment.baseURL_GET;
        return this.http.get(`${base_Url}/billing/history-invoice`, options);
    }

    // ======================== //
	// Delete Account
	// ====================== //
    deleteAccount() {
        let base_Url = this.mainService.isBrowser ? environment.baseURL : environment.baseURL_GET;
        return this.http.get(`${base_Url}/users/deactivate`);
    }

    /**
     * * UPDATE USER PROFILE *
     * Todo: query for updating user profile
     */
    updateUserProfile(newData: UpdateUserParams){
        return this.http.post(`${environment.baseURL}/users/edit-profile`, newData);
    }
    
	/**
     * * GET TIMEZONE LIST *
     * Todo: to get timezone list
     */
    getTimezoneList() {
		return this.http.get(`${environment.staticAssets}/json/timezone.json?t=${this.mainService.appVersion}`);
    }












    /**
     * * ============================================================== *
     * * REFACOTRED CODE
     * * ============================================================== *
     */


    /**
     * * UPDATE USER GENDER *
     * @param gender: 'male' | 'female' | 'non-binary'
     * @returns: Observable<any>
     */
    updateUserGender(gender: 'male' | 'female' | 'non-binary'): Observable<any> {
        return this.mainService.queryGraphql(`
            mutation {
                update_users(
                    where: { email: { _eq: "${this.mainService.userInfo.email}" }},
                    _set: { gender: "${gender === 'non-binary'? '' : gender}"}
                ) {
                    affected_rows
                }
            }
        `);       
    }

    /**
     * * UPDATE USER PASSWORD *
     * Todo: to update user password
     * @param password : string
     * @returns : Observable<any>
     */
    updateUserPassword(password: string): Observable<any> {
        return this.mainService.queryGraphql(`
            mutation {
                update_users(
                    where: { email: { _eq: "${this.mainService.userInfo.email}" }},
                    _set: { password: "${password}" }
                ) {
                    affected_rows
                }
            }
        `);       
    }

    /**
     * * CHECK USER HAVE PASSWORD *
     * Todo: to check user have password or not
     * @returns : Observable<any>
     */
    checkUserHavePassword(refetch:boolean=false): Observable<any> {
        return this.http.get(this.mainService.fetchDataFromApi({
            host: 'users/check-password',
            refetch: refetch,
        }))
    }

    /**
     * ANCHOR Update Account Setting
     * @description: to update account setting
     * @param data : any
     * @returns : Observable<any>
     */
    public updateAccountSetting(data: any): Observable<any> {
        return this.http.post(`${environment.baseURL}/users/account-setting`, data);
    }
}
