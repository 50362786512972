<div class="wrapper">
    <div class="payment">
        <p class="payment__label">Payment Method</p>
        <div class="payment__detail">
            <ng-container *ngIf="!onFetching">
                <div *ngIf="paymentMethod" class="payment__logo"></div>
                <ul class="payment__list">
                    <ng-container *ngIf="paymentMethod">
                        <li class="payment__list__item">{{paymentMethod.brand}} {{paymentMethod.display_card_number}}</li>
                        <li class="payment__list__item">Expiry {{paymentMethod.exp_month}}/{{paymentMethod.exp_year}}</li>
                    </ng-container>
                    <li *ngIf="!paymentMethod" class="payment__list__item no-payment">No Payment Methods Added</li>
                </ul>
            </ng-container>
            <ng-container *ngIf="onFetching">
                ...
            </ng-container>
        </div>
        <div class="payment__button">
            <!-- <button class="btn btn-light btn-sm" (click)="editPaymentMethod=true">Edit Payment Method</button> -->
        </div>
    </div>
</div>