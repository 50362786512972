// Angular built-in components/methods
import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { environment } from '@environments';

// Translate service
import { WhiteLabelService } from 'src/app/components/white-label/white-label.service';

// User-defined services
import { MainService, store } from "src/app/shared/services";
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgIf,
        RouterLink,
        RouterLinkActive
    ]
})
export class FooterComponent implements OnInit {
    public selectedCountry = this.mainService.arrLanguages[0];
    public selectedCurrency = this.mainService.currency[0];
    public environment = environment;

    @ViewChild('contentHeight') contentHeight: ElementRef;

    constructor(
        public mainService:MainService,
        public whiteLabelService: WhiteLabelService,
    ) { }

    ngOnInit(): void {
        if(this.mainService.isBrowser) {
            this.setHeightFooter();
            window.addEventListener('resize', () => {
                this.setHeightFooter();
            })
        }
    }

    setHeightFooter(){
        if(this.contentHeight){
            document.documentElement.style.setProperty('--contentHeight', `${this.contentHeight.nativeElement.offsetHeight}px`);
        }
    }
}
