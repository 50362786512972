// Angular built-in components/methods
import { Component, OnInit } from '@angular/core';
import { NgIf } from '@angular/common';
import { Router } from '@angular/router';

// User-defined services
import { MainService, store } from "src/app/shared/services";
import { ProfileService } from '../profile.service';
import { AlertMessageService } from 'src/app/components/alert-message/alert-message.service';

// Third party plugins
import watch from 'redux-watch';
import { environment } from '@environments';
import { LayoutsComponent } from '../../../layouts/layouts.component';

@Component({
    selector: 'app-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.scss'],
    standalone: true,
    imports: [LayoutsComponent, NgIf],
})
export class WelcomeComponent implements OnInit {
    public loading: boolean = false;
    public name: string;
    private email: string;
    private pref_token: any;
    public loadingSendOTP: boolean = false;
    private grecaptchaToken: string = '';
	public captchaVerifed: boolean = false;
    public env = environment;

	constructor(
        public mainService:MainService,
        private alertMessageService:AlertMessageService,
        private router:Router,
        private profileService:ProfileService,
    ){
        let updateUserRegisterState = watch(store.getState, 'main.userRegisterState');
        store.subscribe(updateUserRegisterState((newState) => {
            this.getUserInfo();
        }));

        this.mainService.loadScripts(['https://www.google.com/recaptcha/api.js?render=explicit'])
	}

	ngOnInit(): void {
        this.getUserInfo();
	}

    /**
     * * GET USER INFO *
     * Todo: to getting user info 
     */
    getUserInfo(){
        if(this.mainService.isBrowser){ 
            this.pref_token = localStorage.getItem("temporary_token");

            if(!this.pref_token){
                this.router.navigate(["/404"]);
            }else{
                this.loading = true;
                this.profileService.getUserInfoWelcome(this.pref_token).subscribe(res => {
                    if(res['data']){
                        this.email = res['data'].users[0].email;
                        this.name = res['data'].users[0].username;
                        this.loading = false;
                        this.mainService.initGrecaptcha({
                            elementId: 'captchaWelcome',
                            callback: (e) => {
                                this.captchaVerifed = true;
                                this.grecaptchaToken = e;
                            },
                            expiredCallback: () => {
                                this.captchaVerifed = false;
                            }
                        });
                    }else{
                        this.router.navigate(["/404"]);
                    }
                });
            }
        }
    }

    /**
     * * RESEND OTP USER *
     * Todo: to resend otp user
     */
    resendOtpUser(){
        if (this.captchaVerifed) {
            this.loadingSendOTP = true;
            this.mainService.resendOTP({
                email: this.email,
                token: this.grecaptchaToken
            }).subscribe(res =>{
				this.mainService.resetGrecaptcha();
                this.alertMessageService.add({ severity: 'success', summary: 'Success', detail:  'Request OTP successfully, please check your email' });
                this.loadingSendOTP = false;
            }, err =>{
				this.mainService.resetGrecaptcha();
                this.alertMessageService.add({ severity: 'error', summary: 'Failed', detail:  'Request OTP is failed' });
                this.loadingSendOTP = false;
            });
        }
    }
}
