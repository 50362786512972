<div class="wrap-publish">
    <div class="wrap-button-cover-image">
        <ng-container *ngIf="!editorService.exhibition.cover_image">
            <label>Choose Cover Image:</label>
            <div class="d-flex">
                <div class="item-button" id="uploadCover">
                    <button 
                        class="btn-cover-image action-button" 
                        [disabled]="editorService.blockUserAction" 
                        [class.is-drag-image]="conditionDragImage" 
                        [class.disable-button]="editorService.blockUserAction" 
                        (click)='file.click()'>
                        Press to upload or drag and drop here
                    </button>
                </div>
                <div class="item-button">
                    <button 
                        class="btn-cover-image action-button" 
                        [disabled]="editorService.blockUserAction"
                        [class.disable-button]="editorService.blockUserAction" 
                        (click)="takeCoverImageFromExhibit()">
                        Take a picture of the exhibition
                    </button>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="editorService.exhibition.cover_image">
            <label>Cover Image</label>
            <div class="wrap-image mb30">
                <img defaultImage="{{env.staticAssets}}images/other/skeleton.svg?t={{mainService.appVersion}}" [lazyLoad]="editorService.exhibition.cover_image"  alt="" class="cover-image">
                <a href="javascript:;" *ngIf="!editorService.blockUserAction" (click)='editCover()' class="edit-image">Edit</a>
            </div>
        </ng-container>
    </div>

    <div class="content-settings">
        <div class="form-group content-color">
            <label class="mr10">Viewer Color Mode </label>
            <div class="switch-button" (click)="changeViewerTheme()" [class.light]="viewerLightMode" [class.dark]="!viewerLightMode">
                <input id="pay-item" class="switch-button-checkbox" [checked]="viewerLightMode" type="checkbox">
                <label class="switch-button-label" for="pay-item">
                    <span class="switch-button-label-span">Dark</span>
                </label>
            </div>  
        </div>

        <div class="form-group splash-screen">
            <div class="wrap-toggle">
                <p-inputSwitch
                    [disabled]="editorService.onSavingData || editorService.onPublishData || editorService.blockUserAction"
                    [styleClass]="editorService.onSavingData || editorService.onPublishData || editorService.blockUserAction? 'input-switch-disable switch-lg' : 'switch-lg'"
                    (onChange)="triggerSplashScreen()"
                    [(ngModel)]="enableSplashScreen">
                </p-inputSwitch>
                <label class="ml15">Splash Screen</label>
            </div>
            <button class="btn btn-sm" (click)="showSplashScreen=true">
                Edit Splash Screen
                <i class="vi vi-modify"></i>
            </button>
        </div>

        <div class="form-group content-description">
            <label>Exhibition Description</label>
            <textarea 
                [readonly]="editorService.blockUserAction"
                [(ngModel)]="editorService.exhibition.description" 
                [ngStyle]="{'height.px': 110, resize:'none'}" 
                (input)="changeExhibitionDescription()"
                (keydown)="mainService.blockChars($event)"
                class="form-control bg-dark">
            </textarea>
        </div>

        <div class="outline-bottom mt15 mb15"></div>

        <div class="form-group side-by-side">
            <label [style.color]="'#e7e7e7'">Add Links</label>
        </div>

        <ng-container *ngIf="editorService.addLinksPublish">
            <div class="form-group mb15 mt20">
                <div class="d-flex">
                    <div class="wrap-icon-link mb10 p-0">
                        <i class="vi vi-global"></i>
                    </div>
                    <label class="label-link ml-2 mb0">Website URL</label>
                </div>
                <div class="wrap-input-social">
                    <input [(ngModel)]="website_url" (input)="changeExhibitionLink()" type="text" class="form-control bg-dark md" />
                    <small class="text-danger d-block mt5" *ngIf="websiteInvalid">
                        Website url is invalid.
                    </small>
                </div>
            </div>
            <div class="form-group mb15">
                <div class="d-flex">
                    <div class="wrap-icon-link mb10">
                        <i class="vi vi-facebook"></i>
                    </div>
                    <label class="label-link ml-2 mb0">Facebook</label>
                </div>
                <div class="wrap-input-social">
                    <input [(ngModel)]="facebook_url" (input)="changeExhibitionLink()" type="text" class="form-control bg-dark md"/>
                    <div class="info-url">
                        https://www.facebook.com/<span class="text-white">{{facebook_url}}</span>
                    </div>
                    <small class="text-danger" *ngIf="facebookInvalid">
                        Link cannot contains quote or double quote
                    </small>
                </div>
            </div>
            <div class="form-group mb15">
                <div class="d-flex">
                    <div class="wrap-icon-link mb10">
                        <i class="vi vi-instagram"></i>
                    </div>
                    <label class="label-link ml-2 mb0">Instagram</label>
                </div>
                <div class="wrap-input-social">
                    <input [(ngModel)]="instagram_url" (input)="changeExhibitionLink()" type="text" class="form-control bg-dark md"/>
                    <div class="info-url">
                        https://www.instagram.com/<span class="text-white">{{instagram_url}}</span>
                    </div>
                    <small class="text-danger" *ngIf="instagramInvalid">
                        Link cannot contains quote or double quote
                    </small>
                </div>
            </div>
        </ng-container>
        <div class="outline-bottom mt10 mb15"></div>

        <!--  ================== START EXHIBITION TIME ============================
              ================== DISABLE FOR NOW ============================
        <div class="form-group">
            <div class="time-zone">
                <label class="mb10">Time Zone</label>
                <p-dropdown
                    [options]="timeZones"
                    [(ngModel)]="selectedTimeZone"
                    (onChange)="changeTimezone()"
	                [filter]="true"
                    styleClass="bg-dark xs dropdown-filter dropdown-filter-dark xs-publish">
                </p-dropdown>
            </div>
            <div class="wrap-date-picker">
                <label class="mb10">Exhibition Time</label>
                <app-input-date
                    [yearType]="'editor-date'"
                    [label]="'Start:'"
                    [blank]="editorService.exhibition.unlimited_time"
                    [disabledDate]="editorService.blockUserAction"
                    (selectDate)="setExhibitionDate($event.date,'start')"
                    [month]="startDateMonth"
                    [year]="startDateYear"
                    [day]="startDateDay">
                </app-input-date>
                <small class="text-danger" *ngIf="!editorService.startDateValid&&!editorService.exhibition.unlimited_time">
                    Start date cannot be less than the current date
                </small>
            </div>
            <div class="wrap-date-picker">
                <app-input-date
                    [yearType]="'editor-date'"
                    [label]="'End:'"
                    [blank]="editorService.exhibition.unlimited_time"
                    (selectDate)="setExhibitionDate($event.date,'end')"
                    [disabledDate]="editorService.blockUserAction||editorService.exhibition.unlimited_time"
                    [month]="endDateMonth"
                    [year]="endDateYear"
                    [day]="endDateDay">
                </app-input-date>
                <small class="text-danger" *ngIf="!editorService.endDateValid&&!editorService.exhibition.unlimited_time">
                    End date cannot be less than the start date
                </small>
            </div>
            <div class="unlimited-time" [class.cursor-disable]="editorService.blockUserAction">
                <input [disabled]="editorService.blockUserAction" type="checkbox" [checked]="editorService.exhibition.unlimited_time">
                <label (click)="setUnlimitedTime();">Unlimited Time</label>
            </div>
        </div>
        
        <div class="active-share">
            <label class="mr15">Countdown Timer</label>
            <p-inputSwitch
                [styleClass]="editorService.blockUserAction? 'input-switch-disable switch-lg':'switch-lg'" 
                [disabled]="editorService.blockUserAction"
                (onChange)="setCountdownTimer();editorService.updateUndoRedoState()" 
                [(ngModel)]="editorService.exhibition.countdown_timer">
            </p-inputSwitch>
        </div>
        ============================= END EXHIBITION TIME =========================== -->

        <ng-container *ngIf="editorService.exhibition.published">
            <div class="wrap-share">
                <div class="form-group mb15">
                    <label>Shareable link:</label>
                    <div class="d-flex align-items-center mb15">
                        <input type="text" id="sharelink" readonly [value]="shareableLink" class="form-control bg-dark md mb0">
                        <button  (click)="copyText('sharelink')" class="btn btn-light2 btn-xs ml20">Copy</button>
                    </div>
                </div>
                <div class="form-group">
                    <label>Iframe:</label>
                    <div class="d-flex align-items-center">
                        <input type="text" id="embedLink" readonly [value]="iframe" class="form-control bg-dark md mb0">
                        <button class="btn btn-light2 btn-xs ml20"  (click)="copyText('embedLink')">Copy</button>
                    </div>
                </div>
            </div>
        </ng-container>


        <div class="wrap-export" *ngIf="canExportExhibition">
            <label>Export:</label>
            <button 
                (click)="exportScene('glb')" 
                [disabled]="editorService.blockUserAction" 
                [class.loading-button-primary]="onExportingGlb"
                class="btn btn-primary btn-xs mt25">
                Export To GLB File
            </button>
            <button 
                [disabled]="editorService.blockUserAction" 
                [class.loading-button-primary]="onExportingBabylon"
                (click)="exportScene('babylon')"  
                class="btn btn-primary btn-xs mt25">
                Export To Babylon File
            </button>
        </div>
    </div>
</div>

<app-splash-screen
    [visible]="showSplashScreen"
    (onHide)="showSplashScreen=false">
</app-splash-screen>

<input type="file" (change)="chooseImageByClick($event)" hidden #file>

<app-cropper-dialog
    #cropper
    type="cover"
    [disableDelete]="!editorService.exhibition.cover_image"
    [image]="coverImageTmp"
    (onHide)="closeCropedImage($event)"
    (onCropped)="setCoverImage($event)"
    (onDelete)="deleteCoverImage()"
    (onUpload)="file.click()"
    [show]="displayCropperImage">
</app-cropper-dialog>