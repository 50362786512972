import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments';
import { TextWall } from '@interfaces';
import { UtilsService } from '@services';
import { Observable } from 'rxjs';
declare const BABYLON: any;

@Injectable({
  providedIn: 'root'
})
export class TextLoaderService {
  public texts: any = [];
	public activeText: any;
	public activeTextNode: any;
	public textDataValid: boolean = true;
	public defaultFontFamilies: string [] = ['Times New Roman','Arial'];
  public textsNode:any = [];
  public highlightText: any;
  public textWallQuality: number = 1;
  public textFontFamilies = [
    { name:'Times New Roman', value: 'times_new_roman_cyrregular' },
    { name:'Arial', value: 'arial_narrowregular'  },
    { name:'Sora', value: 'soraregular' },
    { name:'Hahmlet', value: 'hahmletregular' },
    { name:'JetBrains Mono', value: 'jetbrains_monoregular' },
    { name:'Andada Pro', value: 'andada_proregular' },
    { name:'Epilogue', value: 'epilogueregular' },
    { name:'Inter', value: 'interregular' },
    { name:'Encode Sans', value: 'encode_sanscondensed_thin' },
    { name:'Manrope', value: 'manropeextralight' },
    { name:'Lora', value: 'loraregular' },
    { name:'BioRhyme', value: 'biorhymeextrabold' },
    { name:'Playfair Display', value: 'playfair_displayregular' },
    { name:'Archivo', value: 'archivosemibold' },
    { name:'Roboto', value: 'robotoregular' },
    { name:'Cormorant', value: 'cormorantlight' },
    { name:'Spectral', value: 'spectralregular' },
    { name:'Raleway', value: 'ralewaythin' },
    { name:'Work Sans', value: 'work_sansregular' },
    { name:'Lato', value: 'latoregular' },
    { name:'Anton', value: 'antonregular' },
    { name:'Old Standard TT', value: 'old_standard_ttregular' },
    { name:'Oswald', value: 'oswaldregular' },
    { name:'Montserrat', value: 'montserratthin' },
    { name:'Poppins', value: 'poppinsregular' },
    { name:'Nunito', value: 'nunitoextralight' },
    { name:'Source Sans Pro', value: 'source_sans_probold' },
    { name:'Oxygen', value: 'oxygenregular' },
    { name:'Open Sans', value: 'open_sansregular' },
    { name:'Space Mono', value: 'space_monoregular' },
    { name:'IBM Plex Sans', value: 'ibm_plex_sansregular' },
    { name:'Inconsolata', value: 'inconsolataregular' },
    { name:'Merriweather', value: 'merriweatherregular' },
    { name:'Quattrocento', value: 'quattrocentoregular' },
]

  constructor(
    private _utilsService: UtilsService,
		private _http: HttpClient
  ) { }

  /**
	 * ANCHOR: Load Text Wall
	 * @description Load text wall
	 * @param text : TextWall
	 * @param addToList : boolean
	 * @returns: Promise<BABYLON.TransformNode>
	 */
	public async loadText(text: TextWall, scene: any, addToList: boolean = true): Promise<any> {
		const textContenMesh = this._createTextContentMesh(text, scene);
		const wrapText = this._createTextContentMeshWapper(text, textContenMesh, scene);
		await this._createTextGUI(text, textContenMesh, wrapText);
		if (addToList) this.textsNode.push(wrapText);
		return wrapText;
	}

  /**
	 * ANCHOR: Create Text Content Mesh
	 * @description Create text content mesh for inserting text gui content
	 * @param text : TextWall
	 */
	private _createTextContentMesh(text: TextWall, scene: any): void {
		const contentMesh = BABYLON.MeshBuilder.CreatePlane('textWall', {}, scene);
		contentMesh.id = text.id;
		contentMesh.rotation.y = Math.PI;
		contentMesh.scaling = new BABYLON.Vector3(text.scaling.scaling_x, text.scaling.scaling_y, 1);
		contentMesh.position.z = 0.001;
		return contentMesh;
	}

  /**
	 * ANCHOR: Create Text Content Mesh Wrapper
	 * @description Create object wrapper for text content mesh
	 * @param text: TextWall
	 * @param textContentMesh: BABYLON.Mesh 
	 * @returns : BABYLON.TransformNode
	 */
	private _createTextContentMeshWapper(text: TextWall, textContentMesh: any, scene:any): any {
		const wrapText:any = new BABYLON.TransformNode('textWall', scene);
		wrapText.id = "textWall-"+text.id;
		wrapText.scaling = new BABYLON.Vector3(text.scaling.scaling_x, text.scaling.scaling_y, 1);
		textContentMesh.setParent(wrapText);
		wrapText.position = new BABYLON.Vector3(text.position.position_x, text.position.position_y, text.position.position_z);
		wrapText.rotation = new BABYLON.Vector3(text.rotation.rotation_x, text.rotation.rotation_y, text.rotation.rotation_z);
		wrapText.metadata = { textData: text };
		this._utilsService.enableHighlight({
			exhibitAsset: wrapText,
			enable: true,
			highlightLayer: this.highlightText,
		});
		this._utilsService.enableHighlight({
			exhibitAsset: wrapText,
			enable: false,
			highlightLayer: this.highlightText,
		});
		return wrapText;
	}

  /**
	 * ANCHOR: Create Text GUI
	 * @description to create text gui to attach to text content mesh
	 * @param text: TextWall
	 * @param textContentMesh: BABYLON.Mesh
	 * @param wrapText: BABYLON.TransformNode
	 */
	private async _createTextGUI( text: TextWall, textContentMesh: any, wrapText: any): Promise<void> {
		// Create advanced texture
		const size = 1024 * this.textWallQuality;
		const advancedTexture = BABYLON.GUI.AdvancedDynamicTexture.CreateForMesh(textContentMesh, size, size);    

		// Create GUI text
		const guiText = new BABYLON.GUI.TextBlock("text");
		this.configurationTextGUI(text, guiText);
		advancedTexture.addControl(guiText);

		wrapText['textGUI'] = guiText;
		wrapText['advancedTexture'] = advancedTexture;
	}

  /**
	 * ANCHOR: Configuration Text GUI
	 * @description to configuration text gui
	 * @param text: TextWall
	 * @param guiText: BABYLON.GUI.TextBlock
	 */
	public configurationTextGUI(text: TextWall, guiText: any, quality = null): void {
		quality = quality || this.textWallQuality;
		guiText.textWrapping= true;
		guiText.width = "97%";
		guiText.height = "97%";
		guiText.shadowColor = text.color + '20';
		guiText.shadowBlur = 45;
		guiText.text = text.content;
		guiText.color = text.color;
		const fontFamily = this.textFontFamilies.find((x) => x.name ==  text.font_family || x.value == text.font_family);
		guiText.fontFamily = fontFamily.value;
		guiText.fontSize = text.font_size/text.scaling.scaling_x * quality;
		guiText.textVerticalAlignment = text.text_vertical_alignment;
		guiText.fontStyle = (!text.font_style || text.font_style == "Regular") ? "" : text.font_style
		guiText.textHorizontalAlignment = text.text_horizontal_alignment;
		guiText.onLinesReadyObservable.addOnce(() => {
            guiText.fontOffset.height = text.line_height/text.scaling.scaling_x * quality;
        })
	}

	/**
	 * * REGENERATE TEXT WALLS *
	 * Todo: to generate text walls
	 * @returns Promise<void>
	 */
	public updateAllTexts = false;
	public regenerateTextWalls(exhibitionId): Promise<void> {
		return new Promise(async (resolve, reject) => {
			const sendRequest = (file) => {
				return new Promise((res, rej) => {
					this.uploadTextWalls([file], exhibitionId).subscribe((response) => {
						res(response);
						this.textsNode.forEach((node) => node.changed = false);
					}, err => rej(err));
				})
			}

			const requests = [];
			for(let i = 0; i < this.textsNode.length; i++) {
				const node = this.textsNode[i];
				if (node.changed || this.updateAllTexts) {	
						const file = await this.convertTextToFile(node);					
						requests.push(sendRequest(file));
				}
			}

			Promise.all(requests).then((responses) => {
				resolve();
				this.textsNode.forEach((node) => node.changed = false);
			}).catch(err => reject(err));
		});
	}

	  /**
	 * * UPLOAD TEXT WALLS *
	 * Todo: to upload text walls
	 * @param files : File[]
	 * @returns : 
	 */
		public uploadTextWalls(files: File[], exhibitonId:string): Observable<any> {
			const formData = new FormData();
			formData.append('exhibition_id', exhibitonId);
			files.forEach((file: File) => {
				formData.append('picture[]', file);
			})
	
			return this._http.post(`${environment.baseURL}/exhibition/save-changes-text-wall`, formData)
		}

	/**
	 * * CONVERT TEXT TO FILE *
	 * @param textNode : BABYLON.TransformNode
	 * @returns : Promise<File>
	 */
	public convertTextToFile(textNode: any): Promise<File> {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				try {
					const canvas = textNode['advancedTexture']._canvas;
					const dataURL = canvas.toDataURL();
					const textId = textNode.id.replace('textWall-','');
					const file = this.dataURLtoFile(dataURL, textId + '.png');
					resolve(file);
				} catch (error) {
					reject(error)
				}
			}, 100);
		})
	}

		/**
	 * * BASE64 TO FILE *
	 * Todo: for converting base64 to file
	 * @param url : String
	 */
		dataURLtoFile(dataurl, filename) {
			var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
				bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
			while(n--){
				u8arr[n] = bstr.charCodeAt(n);
			}
			return new File([u8arr], filename, {type:mime});
		}
}
