// Angular Packages
import { Component, OnInit, ElementRef, ViewChild, OnDestroy, AfterViewInit, Input } from '@angular/core';
import { Title, Meta } from "@angular/platform-browser";

// User defined services
import { SsrCookieService as CookieService } from 'ngx-cookie-service-ssr';
import { MainService } from "src/app/shared/services";
import { LayoutsService } from 'src/app/layouts/layouts.service';
import { ProfileService } from './profile.service';

// Third party packages
import * as _ from "lodash";

// Environment
import { environment } from '@environments';
import { MainComponent } from './main/main.component';
import { LayoutsComponent } from '../../layouts/layouts.component';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    standalone: true,
    imports: [LayoutsComponent, MainComponent],
})

export class ProfileComponent {
  public infoUser:any;
  public loading:boolean = true;
  public username: string;
  public isInside: boolean = false;

  @ViewChild('mainLayout') mainLayout: ElementRef;

  constructor(
    public mainService:MainService,
    public layoutService: LayoutsService,
    private cookieService: CookieService,
    public profileService: ProfileService,
    private _title:Title,
    private _meta:Meta,
    private _router: ActivatedRoute
  ) {
    this.mainService.userInfoPublicSubject.asObservable().subscribe((userData) => {
      this._updateMetaTag();
      this.infoUser = userData;
      this.loading = false;
    });

    if (this.mainService.isBrowser) {
      this._router.paramMap.subscribe(params => {
        this.username = params.get('username');
        this.isInside = this.username == this.mainService.userInfo?.username;
      });
    }
  }

  ngOnInit(): void {
    if (this.mainService.isBrowser) {
      const validateCreate = this.cookieService.get('validateCreate') === 'true';
      if (validateCreate) this.profileService.validateCreateAfterLogin();
    }
    this.mainService.isProfilePage = true;
    if (this.mainService.userInfo?.user_type=='support') document.cookie = "support_token=" +this.cookieService.get("villume_token");
  }

  ngOnDestroy(): void {
    this.mainService.isProfilePage = false;
  }

  ngAfterViewInit(){
		if(this.mainService.isBrowser){
      this._setWidthLayout();
			window.addEventListener('resize', ()=> this._setWidthLayout());
		}
	}

  /**
   * ANCHOR Update Meta Tag
   * @description update meta tag for SEO
   */
  private _updateMetaTag(): void {
    const title = 'Villume | ' + this.username;
    const description = this.infoUser?.about ? this.infoUser?.about.substring(0,180) : '';
    const image = this.infoUser?.avatar ? this.infoUser?.avatar : environment.staticAssets+'images/other/default-avatar.png';
    const url = 'https://villume.com/' + this.username;

    // Primary Meta Tags
    this._title.setTitle(title);
    this._meta.updateTag({name: 'title', content: title});
    this._meta.updateTag({name: 'description', content: description});
    
    // Open Graph / Facebook
    this._meta.updateTag({property: 'og:type', content: 'website'});
    this._meta.updateTag({property: 'og:url', content: url});
    this._meta.updateTag({property: 'og:title', content: title});
    this._meta.updateTag({property: 'og:description', content: description});
    this._meta.updateTag({property: 'og:image', content: image});
    
    // Twitter 
    this._meta.updateTag({property: 'twitter:card', content: 'summary_large_image'});
    this._meta.updateTag({property: 'twitter:url', content: url});
    this._meta.updateTag({property: 'twitter:title', content: title});
    this._meta.updateTag({property: 'twitter:description', content: description});
    this._meta.updateTag({property: 'twitter:image', content: image});
  }

  /**
   * ANCHOR Set Width Layout
   * @description set width layout
   */
  private _setWidthLayout(): void{
		if(this.mainLayout){
			document.documentElement.style.setProperty('--mainLayout', `${this.mainLayout.nativeElement.offsetWidth}px`);
		}
	}
}

