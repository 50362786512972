import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CreateExhibitionService } from '../services/create-exhibition.service';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-name-exhibition',
    templateUrl: './name-exhibition.component.html',
    styleUrls: ['./name-exhibition.component.scss'],
    standalone: true,
    imports: [FormsModule,NgIf]
})
export class NameExhibitionComponent {
  @Input() name: string = "";
  @Input() isProfile: boolean = false;
  @Output() exhibitionName: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    public createService: CreateExhibitionService
  ) { }

  ngOnInit(): void {
    if (this.name == "Exhibition Name") {
      this.name = "";
      this.exhibitionName.emit(this.name);
    }
  }

  ngAfterViewInit() {
    if (this.isProfile) window.scrollTo(0, 0);
  }

  /**
   * ANCHOR BACK ON PROFILE
   */
  public backOnProfile() {
    this.exhibitionName.emit('');
    this.createService.stepWizard--
  }

  /**
   * ANCHOR CHANGE NAME
   */
  changeName() {
    this.exhibitionName.emit(this.name);
  }
}
