import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments';
import { LayoutsService } from 'src/app/layouts/layouts.service';

@Injectable({
  	providedIn: 'root'
})
export class ContactService {
	public formType: 'help' | 'custom' | 'general' | '' = 'general';
	public fromPricing: boolean = false;

	constructor(
		private router: Router,
		private layoutService: LayoutsService,
		private http: HttpClient
	) {}

	/**
	 * * GO TO CUSTOM EXHIBITION REQUEST FORM *
	 * Todo: to direct user into custom exhibution request form
	 */
	goToCustomExhibitionRequest() {
		this.formType = 'custom';
		this.fromPricing = true;
		this.router.navigateByUrl('/contact');
	}

	/**
	 * SAVE TOKEN RECAPTCHA
	 */
	validateTokenRecaptcha(token: string) {
		return this.http.post(`${environment.baseURL}/validation-rcp`, { token })
	}
}
