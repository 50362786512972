import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments';

@Injectable({
	providedIn: 'root'
})
export class ResetPasswordService {

	public token: any;
	public code: any;

	constructor(
		private http: HttpClient
	) { }

	// ======================== //
	// RESET PASSOWRD
	// ======================= //
	resetPassword(newPassword, confirmPassword) {
		const options = {
			headers: new HttpHeaders({
				authorization: this.token
			})
		};
		
		return this.http.post(`${environment.baseURL}/users/reset-password`, { 
			code: this.code, 
			new_password: newPassword, 
			confirm_password: confirmPassword 
		}, options);
	}
}
