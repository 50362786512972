import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

 import { MessageConfig } from './alert-message.interfaces';

@Injectable({
	providedIn: 'root'
})
export class AlertMessageService {
	private componentMethodCallSource = new Subject<any>();
	componentMethodCalled$ = this.componentMethodCallSource.asObservable();

	constructor() { }

	/**
	 * * SHOW MESSAGE *
	 * Todo: to show message 
	 * @param config : object -> { summary, detail, severity }
	 */
	add(config: MessageConfig) {
		// console.log('AlertMessageService -> add -> config', config);
		this.componentMethodCallSource.next(config);
	}
}
