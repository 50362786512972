import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { MainService } from '../services/main.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserUpdateGuardService {
  constructor(
    private mainService: MainService,
    private router: Router
  ) {}
  
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.mainService.getUserProfile(true).subscribe({
      next: (res: any) => {
        const infoUser = res?.data?.result[0];
        if (infoUser.is_email_verified) {
          if (!infoUser.is_welcome) this.router.navigate(["/settings/profile"]);
          return false;
        }
      },
      error: (err) => {
        console.error(err);
      }
    });
    return true;
  };
}