// Angular modules/components
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// User-defined services
import { MainService } from 'src/app/shared/services';
import { SupportService } from './support.service';
import { LayoutsService } from 'src/app/layouts/layouts.service';
import { AlertMessageService } from 'src/app/components/alert-message/alert-message.service';

// Environment
import { environment } from '@environments';

// Third-party plugins NPM
import { debounce } from 'lodash';
import moment from 'moment';
import 'moment-timezone';

// Interfaces
import { FieldType, GetUsersParams, User } from "./support.interfaces";
import { PaginationComponent } from '../../components/pagination/pagination.component';
import { NgTemplateOutlet, NgIf, NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LayoutsComponent } from '../../layouts/layouts.component';

@Component({
    selector: 'app-support',
    templateUrl: './support.component.html',
    styleUrls: ['./support.component.scss'],
    standalone: true,
    imports: [LayoutsComponent, FormsModule, NgTemplateOutlet, NgIf, PaginationComponent, NgFor]
})
export class SupportComponent implements OnInit {
	public total_clients: number = 0;
	public listUsers: User[] = [];
	public hasMore: boolean = true;
	public currentPageItemLength: number = 0;

	// Params
	public page: number = 1;
	public limit: number = 5;
	public searchValue: string = "";
	public filter: string[] = [];
	private sortBy: string = "";
	public sortLabel: string = "Sort By";

	// Filter
	public filterValue: any[] = [
		{ label: 'All Clients', value: 'all', selected: true },
		{ label: 'Experimental Plan', value: 'experimental', selected: false },
		{ label: 'Basic Plan', value: 'basic', selected: false },
		{ label: 'Professional Plan', value: 'professional', selected: false },
		{ label: 'Premium Plan', value: 'premium', selected: false },
		{ label: 'Ultimate Plan', value: 'ultimate', selected: false }
	];

	constructor(
		public mainService: MainService,
		private router: Router,
		private route: ActivatedRoute,
		private layoutService: LayoutsService,
		private supportService: SupportService,
		private alertMessageService: AlertMessageService,
	) {}

	ngOnInit(): void {
		if(this.mainService.isBrowser){
			this.getQueryParams();
			this.getUsers();
		}
	}

	ngAfterViewInit(): void {
		if(this.mainService.isBrowser) {
			document.addEventListener('click', this.closeFilterPanel);
			document.addEventListener('click', this.closeSortPanel);
			this.handleBlinkScrollbar();
		}
	}

	ngOnDestroy(): void {
		if(this.mainService.isBrowser){
			document.removeEventListener('click', this.closeFilterPanel);
			document.removeEventListener('click', this.closeSortPanel);
			this.handleBlinkScrollbar(true);
		}
	}

	/**
	 * * GET QUERY PARAMS *
	 * Todo: to getting query params
	 */
	getQueryParams(): void {
		const queryParams = this.route.snapshot.queryParams;
		this.page = Number(queryParams.page || 1)
		this.searchValue = queryParams.search || '';
		this.filter = queryParams.filter ? queryParams.filter.split('|') : ['all'];
		this.sortBy = queryParams.sort_by ||'';
		this._getFilterActive();
	}

	/**
	 * * SET USER AVATAR *
	 * Todo: to set user avatar
	 * @param avatar : String | NULL -> user avatar path
	 * @return String
	 */
	setAvatar(avatar: string | null): string {
		if (avatar) {
			if (!avatar.includes("https://")) avatar = this.mainService.convertPathImage(avatar);
		} else {
			avatar = environment.staticAssets+'images/other/default-avatar.png?t='+this.mainService.appVersion;
		}
		return avatar;
	}

	/**
	 * * SET USER HISTORY *
	 * Todo: to set user history
	 * @param user : User
	 * @return User
	 */
	setUserHistory(user: User): User {
		const clientTimezone = moment.tz.guess();
		const reformat = (date: string, newFormat: string): string => {
			return moment(date).tz(clientTimezone).format(newFormat)
		}

		user.last_login = user.last_login ? reformat(user.last_login, 'YYYY-MM-DD / HH:mm') : '-';
		user.created_at = user.created_at ? reformat(user.created_at, 'YYYY-MM-DD') : '-';
		return user;
	}

	/**
	 * * GET USERS *
	 * Todo: to getting users
	 */
	public onFetchingUsers: boolean = false;
	getUsers(isAppend:boolean=false): void {
		// Reset data
		if (!isAppend) {
			this.listUsers = [];
			this.total_clients = 0;
		}
		
		// Create params
		const params = this.createGetUsersParams();
	
		this.onFetchingUsers = true; // Turn on loading
		this.supportService.getUsers(params).subscribe((res: any) => {
			this.onFetchingUsers = false; // Turn off loading
		
			const data = res['data'];
			if (data.listUsers.length > 0) {
				this.total_clients = data.totalUsers;

				const listUsers = data.listUsers.map((user: User) => {
					user = this.setUserHistory(user);
					user.avatar = this.setAvatar(user.avatar);
					user.on_login = false;
					return user;
				});
				if (isAppend) {
					this.listUsers = [...this.listUsers, ...listUsers];
				} else {
					this.listUsers = data.listUsers.map((user: User) => {
						user = this.setUserHistory(user);
						user.avatar = this.setAvatar(user.avatar);
						user.on_login = false;
						return user;
					});
				}
				this.currentPageItemLength = this.listUsers.length;
			}else{
				this.alertMessageService.add({severity:"warn",summary:"Not Found",detail: "Data not found"})
			}
		}, err => {
			this.onFetchingUsers = false; // Turn off loading

			this.total_clients = 0;
			this.listUsers = [];
			
			const statusCode = err.error.statusCode;
			if(statusCode == 401 || statusCode == 400){
				this.mainService.expiredSesionPopup = true;
			}else{
				this.alertMessageService.add({severity:"error", summary:"Error", detail:"Something went wrong. Please try again."})
			}
		})
	}

	/**
	 * * CREATE GET USERS PARAMS *
	 * Todo: to create get users params
	 * @return GetUsersParams
	 */
	createGetUsersParams(): GetUsersParams {
		const params: GetUsersParams = {
			limit: this.limit,
			page: this.page,
		}

		if (this.filter.length > 0 && !this.filter.includes('all')) {
			params['filter'] = this.filter.join('|');
		} else {
			params['filter'] = 'all';
		}
		if (this.sortBy) params['sort_by'] = this.sortBy || "last_registered";
		if (this.searchValue) params['search'] = this.searchValue;

		return params;
	}

	/**
	* * UPDATE QUERY PARAMS *
	* Todo: to update query params
	*/
	updateQueryParams(): void {
		this.router.navigate(
			['/support'],
			{ 
				queryParams: {
					filter: this.filter.includes('all') ? null: this.filter.join('|'),
					sort_by: this.sortBy || null,
					page: this.page,
					search: this.searchValue || null
				} 
			},
		);
	}


	/**
	 * * CHANGE PAGE *
	 * Todo: to change page
	 */
	changePage(page) : void {
		this.page = page;
		this.hasMore = true;
		this.updateQueryParams();
		this.getUsers();
	}

	/**
	 * * LOGIN AS USER *
	 * Todo: to load as user
	 */
	public onLogin: boolean = false;
	loginAsUser(user) : void {
		const params = {
			"email_profile" : user.email_profile,
			"username" : user.username
		}
		
		this.onLogin = true;
		user.onLogin = true;
		this.supportService.loginAsUser(params).subscribe((response: any) => {
			if (response['data'].is_email_verified){
        this.layoutService.updateToken(response['data'].token).subscribe(async (resToken:any) =>{
					window.location.href = "/" + response['data'].username;
        })
      }else{
				this.onLogin = false;
				user.onLogin = false;
				this.alertMessageService.add({severity:"warn",summary:"Warning",detail: "Email not verified"})
			}
		}, err => {
			this.onLogin = false;
			user.onLogin = false;
    
			const statusCode: number = err.error.statusCode;
			const errorMessage: string = err.error.data.errors.messages;

			switch(statusCode) {
				// Unauthorization
				case 401: this.mainService.expiredSesionPopup = true; break;
				
				// User in the process of deleting the account,
				case 409: 
					this.alertMessageService.add({
						severity: 'warn', 
						summary: 'Warning', 
						detail: errorMessage
					})
				break;

				// Other errors
				default: 
					this.alertMessageService.add({
						severity: 'error', 
						summary: 'Error', 
						detail: errorMessage
					})
				break;
			}
		})
	}

	/**
	 * ANCHOR Get Filter active
	 */
	private _getFilterActive(): void {
		this.filterValue.map(item => {
			if (!this.filter.includes('all')) {
				if (item.value == 'all') item.selected = false;
				if (this.filter.includes(item.value)) {
					item.selected = true;
				}
			}
		});
	}

	/**
   * ANCHOR Load More
   * @description: to load more promo codes
   */
  public loadMore(): void {
    this.page++;
    this.getUsers(true);
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { page: this.page },
      queryParamsHandling: 'merge'
    });
  }

	/**
	 * * SEARCH USER *
	 * Todo: to search user
	 * @param event : Event
	 */
	searchUser: any = debounce((event: any) => {
		this.page = 1;
		this.hasMore = true;
		this.updateQueryParams();
		this.getUsers();
	}, 1000);


	/**
	 * ANCHOR OPEN FILTER
	 */
	public openfilterPanel() {
		const dropdownPanel = document.querySelectorAll('.dropdown-panel.filter');
		dropdownPanel.forEach((el: HTMLElement) => {
			const expanded = el.getAttribute('aria-expanded') === 'true';
			el.setAttribute('aria-expanded', !expanded ? 'true' : 'false');
		});
	}

	/**
	 * ANCHOR OPEN SORT
	 */
	public openSortPanel() {
		const dropdownPanel = document.querySelectorAll('.dropdown-panel.sort');
		dropdownPanel.forEach((el: HTMLElement) => {
			const expanded = el.getAttribute('aria-expanded') === 'true';
			el.setAttribute('aria-expanded', !expanded ? 'true' : 'false');
		});
	}

	/*
	 * ANCHOR CLOSE FILTER PANEL
	 */
	closeFilterPanel(event: any) {
		const dropdown = document.querySelectorAll('.dropdown-filter');
		const dropdownPanel = document.querySelectorAll('.dropdown-panel.filter');
		const outsideClick = [];

		dropdown.forEach((dropdown: HTMLElement) => {
			outsideClick.push(!dropdown.contains(event.target));
		});

		if (outsideClick.every(item => item)) {
			dropdownPanel.forEach((el: HTMLElement) => {
				el.setAttribute('aria-expanded', 'false');
			});
		}
	}

	/*
	 * ANCHOR CLOSE SORT PANEL
	 */
	closeSortPanel(event: any) {
		const dropdown = document.querySelectorAll('.dropdown-sort');
		const dropdownPanel = document.querySelectorAll('.dropdown-panel.sort');
		const outsideClick = [];

		dropdown.forEach((dropdown: HTMLElement) => {
			outsideClick.push(!dropdown.contains(event.target));
		});

		if (outsideClick.every(item => item)) {
			dropdownPanel.forEach((el: HTMLElement) => {
				el.setAttribute('aria-expanded', 'false');
			});
		}
	}

	/**
	 * ANCHOR FILTER CHANGES *
	 */
	public onFilterChange(event: any) {
		this.filter = [];
		if (event.value != 'all') event.selected = !event.selected;
		else {
			this.filterValue.forEach(item => item.selected = false);
			event.selected = true;
		}
		
		if (event.value != 'all') {
			this.filterValue.find(item => item.value == 'all').selected = false;
			const allSelected = this.filterValue.filter(item => item.value != 'all')
			.every(item => item.selected);
			if (allSelected) {
				this.filterValue.map(item => item.selected = !item.selected);
			}
		}

		this.filterValue.map(item => {
			if (item.selected && item.value != 'all') {
				this.filter.push(item.value);
			}
		});

		if (this.filter.length == 0) {
			this.filter = ['all'];
			this.filterValue.find(item => item.value == 'all').selected = true;
		}

		this.page = 1;
		this.hasMore = true;
		this.updateQueryParams();
		this.getUsers();
	}

	/**
	 * ANCHOR SORT BY FIELD
	 */
	public sortByField(field: string): void {
		switch (field) {
			case 'last_registered':
				this.sortLabel = 'Last Registered';
				this.sortBy = field;
				break;
		
			case 'first_registered':
				this.sortLabel = 'First Registered';
				this.sortBy = field;
				break;

			case 'last_active':
				this.sortLabel = 'Last Active';
				this.sortBy = field;
				break;
		}

		this.page = 1;
		this.hasMore = true;
		this.updateQueryParams();
		this.openSortPanel();
		this.getUsers();
	}

	/*
	 * ANCHOR HANDLE BLINK SCROLLBAR
	 */
	handleBlinkScrollbar(destroy=false) {
		if (this.mainService.isBrowser) {
			setTimeout(() => {
				const scrollPanel = document.querySelector('.p-scrollpanel-content') as HTMLElement;
				console.log('scrollPanel', scrollPanel);
				const overflow = destroy ? 'auto' : 'scroll';
				scrollPanel.style.overflowY = overflow;
			}, 100);
		}
	}
}

