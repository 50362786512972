<div class="wrap-set-profile" [formGroup]="form">
    <div class="wrap-image-set">
        <img [src]="form.value.avatar">
        <button 
            (click)="file.click()" 
            [disabled]="onUploading"
            class="btn btn-light btn-sm"
            [class.loading-button-primary]="onUploading">
            Choose Your Profile Picture
        </button>
        <input type="file" hidden accept="image/*" #file (change)="uploadPicture($event)">
    </div>

    <div class="d-flex mb25">
        <div class="form-group w-50 mr20">
            <label for="fn">First name</label>
            <input 
                type="text" 
                (input)="handleSpaceAtBegin('first_name');setUpperToFirst('first_name')"
                [class.is-invalid]="form.get('first_name').invalid && form.get('first_name').touched" 
                class="form-control w-100" 
                formControlName="first_name">
            <div class="invalid-feedback" *ngIf="form.get('first_name').invalid && form.get('first_name').touched">
                <ng-container *ngIf="form.get('first_name')?.errors?.required">
                    First name required
                </ng-container>
                <ng-container *ngIf="form.get('first_name')?.errors?.forbiddenName">
                    First name invalid
                </ng-container>
            </div>
        </div>

        <div class="form-group w-50">
            <label for="fn">Last name</label>
            <input 
                type="text" 
                [class.is-invalid]="form.get('last_name').invalid && form.get('last_name').touched" 
                class="form-control w-100" 
                (input)="handleSpaceAtBegin('last_name');setUpperToFirst('last_name')"
                formControlName="last_name">
            <div class="invalid-feedback" *ngIf="form.get('last_name').invalid && form.get('last_name').touched">
                <ng-container *ngIf="form.get('last_name')?.errors?.required">
                    Last name required
                </ng-container>
                <ng-container *ngIf="form.get('last_name')?.errors?.forbiddenName">
                    Last name invalid
                </ng-container>
            </div>
        </div>
    </div>

    <div class="form-group mb25">
        <label>Username</label>
        <input 
            type="text" 
            class="form-control" 
            maxlength="40"
            (input)="handleSpaceAtBegin('username')"
            formControlName="username"
            [class.is-invalid]="(form.get('username').invalid || usernameNotAvailable || availableRoutes.includes(form.get('username').value.toLowerCase())) && form.get('username').touched"/>
        <div class="info-url">
            villume.com/<span class="text-white">{{form.get('username').value.substring(0, 50)}}</span>
        </div>
        <div class="invalid-feedback" *ngIf="form.get('username').invalid && form.get('username').touched">
            <ng-container *ngIf="form.get('username')?.errors?.required">
                Username required
            </ng-container>
            <ng-container *ngIf="form.get('username')?.errors?.pattern">
                Username invalid
            </ng-container>
            <ng-container *ngIf="form.get('username')?.errors?.maxlength">
                Username is too long
            </ng-container>
        </div>
        <div class="invalid-feedback" *ngIf="usernameNotAvailable">
            Username already exists
        </div>
        <div class="invalid-feedback" *ngIf="!form.get('username')?.errors?.required&&availableRoutes.includes(form.get('username').value.toLowerCase())">
            Username cannot be used
        </div>
        
    </div>
    <div class="form-group mb25">
        <label>Artistic medium</label>
        <input 
            maxlength="150"
            (input)="handleSpaceAtBegin('medium')"
            type="text" 
            class="form-control" 
            formControlName="medium">
    </div>
    <div class="form-group mb25">
        <label>About</label>
        <textarea 
            (input)="handleSpaceAtBegin('about')"
            class="form-control textarea w-100" 
            formControlName="about"></textarea>
    </div>
    <div class="form-group mb25">
        <label>Location</label>
        <input 
            type="text" 
            maxlength="150"
            (input)="handleSpaceAtBegin('location')"
            class="form-control" 
            formControlName="location">
    </div>
    <div class="form-group mb25">
        <label>Email</label>
        <input 
            type="text" 
            maxlength="150"
            [class.is-invalid]="form.get('email_profile').invalid" 
            (input)="handleSpaceAtBegin('email_profile')"
            class="form-control" 
            formControlName="email_profile">
        <div class="invalid-feedback" *ngIf="form.get('email_profile').invalid">
            <ng-container *ngIf="form.get('email_profile').errors.email">
                Email is not valid
            </ng-container>
        </div>
    </div>
    <div class="form-group mb25">
        <label>Website</label>
        <input 
            type="text" 
            maxlength="500"
            [class.is-invalid]="form.get('website_url').invalid" 
            class="form-control" 
            (input)="handleSpaceAtBegin('website_url')"
            formControlName="website_url" 
            style="padding-left: 13px;"/>
        <div class="invalid-feedback">
            Website url is invalid.
        </div>
    </div>
    <div class="form-group mb25">
        <label>Facebook</label>
        <input 
            maxlength="500" 
            type="text" 
            (input)="handleSpaceAtBegin('facebook_url')"
            class="form-control" 
            formControlName="facebook_url"/>
    </div>
    <div class="form-group mb25">
        <label>Instagram</label>
        <input 
            maxlength="500"
            type="text" 
            (input)="handleSpaceAtBegin('instagram_url')"
            class="form-control" 
            formControlName="instagram_url"/>
    </div>
    <div class="form-group mb25">
        <small class="info">* Information you add here is visible to anyone who can view your profile</small>
    </div>
    <div class="text-right mt35">
        <button 
            class="btn btn-primary btn-sm submit" 
            (click)="updateProfile()" 
            [disabled]="isSaveChangesDisabled()" 
            [style]="{'min-width': '135px'}" 
            [class.loading-button-primary]="loading">
            Save Changes
        </button>
    </div>
</div>