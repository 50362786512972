import { Component } from '@angular/core';
import { EditorService } from '../../../editor.service';
import { TextService } from '../../service/text-service.service';
import { TooltipModule } from 'primeng/tooltip';
import { TextLoaderService } from '../../service/text-loader.service';

@Component({
  selector: 'app-text-menu-editor',
  standalone: true,
  templateUrl: './text-menu-editor.component.html',
  styleUrl: './text-menu-editor.component.scss',
  imports: [TooltipModule],
})
export class TextMenuEditorComponent {

  constructor(
    public editorService: EditorService,
    public textService: TextService,
    private _textLoader: TextLoaderService
  ) { }

  /**
   * ANCHOR Align Text
   * @description to align text
   */
  public alignText(direction: "vertical" | "horizontal", value): void {
    if (!this.editorService.blockUserAction) {
      if (direction == "vertical") {
        if (this.editorService.selectedExhibitAssets.length > 1) {
          this.editorService.selectedExhibitAssets.forEach((node: any) => {
            const textWallData = this.textService.getTextWallData(node);
            textWallData.text_vertical_alignment = value;
            node.textGUI.textVerticalAlignment = value;
            node.changed = true;
          })
        } else {
          this._textLoader.activeTextNode.textGUI.textVerticalAlignment = value;
          this._textLoader.activeText.text_vertical_alignment = value;
          this._textLoader.activeTextNode.changed = true;
        }
      };
      if (direction == "horizontal") {
        if (this.editorService.selectedExhibitAssets.length > 1) {
          this.editorService.selectedExhibitAssets.forEach((node: any) => {
            const textWallData = this.textService.getTextWallData(node);
            textWallData.text_horizontal_alignment = value;
            node.textGUI.textHorizontalAlignment = value;
            node.changed = true;
          })
        } else {
          this._textLoader.activeTextNode.textGUI.textHorizontalAlignment = value;
          this._textLoader.activeText.text_horizontal_alignment = value;
          this._textLoader.activeTextNode.changed = true;
        }
      }
      this.editorService.updateLogActivity("Update text aligment");
      this.editorService.updateUndoRedoState()
    }
  }

  /**
 * ANCHOR Move Text by Axis
 * @description to show/hide rotation axis text
 */
  public moveTextByAxis(): void {
    if (!this.editorService.gizmos.position.attachedMesh) {
      this.editorService.hideAxis();
      this.editorService.showAxis("position", this._textLoader.activeTextNode)
      this.editorService.updateLogActivity("Show position axis")
    } else {
      this.editorService.hideAxis();
      this.editorService.updateLogActivity("Hide position axis")
    }
  }
}
