import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { environment } from '@environments';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import localbase from 'localbase';
import { map } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { ScriptService } from 'ngx-script-loader';
import { ForgotPasswordParams, initGrecaptchaParams, RegisterUserParams, ResendOTPParams, User } from 'src/app/shared/interfaces';
import { Base64 } from 'js-base64';
import { Subject } from 'rxjs';
import { SsrCookieService as CookieService } from 'ngx-cookie-service-ssr';


// Third party plugins (CDN)
declare const grecaptcha: any;
declare const fetch: any;

@Injectable({
    providedIn: 'root'
})
export class MainService {
	public isProfilePage: boolean = false;
	public layoutType: any = 'admin';
	public loginForExpiredToken: any = false;
	public displayLoginDialog = false;
	public token;
	public expiredSesionPopup = false;
	public db = new localbase('db');
	public userRegisterName: string = null;
	public lang: string;
	public subscribeExperiment: boolean = false;
	public loadingContent: boolean = false;
	public arrLanguages = [
		{name:'English', value: 'en'},
		{name: 'Deutsch', value: 'de'},
		{name: 'Russian', value: 'ru'},
		{name: 'Français', value: 'fr'},
		{name: 'Español', value: 'es'}
	];
	public arrLanguages2 = [
		{name: 'Eng', value: 'en'},
		{name: 'Deu', value: 'de'},
		{name: 'Rus', value: 'ru'},
		{name: 'Fra', value: 'fr'},
		{name: 'Esp', value: 'es'}
	];
	public currency : any = [
        { name: 'USD', value: 'usd' },
        { name: 'EUR', value: 'eur' },
        { name: 'RUB', value: 'rub' }
    ];

	public userInfoPublic: any;
	public userInfoPublicSubject = new Subject<any>();
	public userInfo: any;
	public isBrowser: boolean = inject(PLATFORM_ID) === 'browser';
	public isServer: boolean = inject(PLATFORM_ID) === 'server';
	public currentOs: string = "window";
	public appVersion: string = "-";

	public exhibitIdTester = "1430d03d-1bfe-48be-a79e-a301517a8883";
	public googleRecaptchaSiteKey: string = '6LdiyLIjAAAAAHmoAdlxAHpWM7WkZkYJhbJvs4Nk';
	public grecaptchaToken: string = '';
	public captchaVerifed: boolean = false;
	
	public urlInvoice: string = '';
  public showPendingPayment: boolean = false;
  public productDetail: string = '';

	public showMSG: boolean = false;
	public createExhibitionWidget: boolean = false;

    constructor(
			private _http: HttpClient,
			private sanitizer:DomSanitizer,
			private cookieService: CookieService,
			private scriptService: ScriptService
    ) {}

	decodeEntity(inputStr) {
		var textarea = document.createElement("textarea");
		textarea.innerHTML = inputStr;
		return textarea.value;
	}

    // ======================== //
	// Query
	// ======================= //
	queryGraphql(query, variables = null){
		const options = {
			headers: new HttpHeaders({
				"accept": 'application/json',
				"authorization": "Bearer "+ this.cookieService.get("villume_token") || "-"
			})
		};
		const body = { query }
		if(variables) body['variables'] = variables;
		return this._http.post(`${environment.baseGraphqlURL}`, body, options);
	}

	// ======================== //
	// Query Manual Token
	// ======================= //
	queryGraphqlManualToken(query,token){
		const options = {
			headers: new HttpHeaders({
				"accept": 'application/json',
				"authorization": "Bearer "+ token
			})
		};
		
		return this._http.post(`${environment.baseGraphqlURL}`, {query:query}, options);
	}

	getCookies() {
		return this._http.get(`${environment.base_host}/api/cookies`);
	}

	// ==================== //
	// Logout
	// ==================== //
	logout(){
		const query =`
            mutation {
                delete_authentication(
                    where: {}
                ) {
                    affected_rows
                }
            }
		`;
		
		return this.queryGraphql(query)
	}

	// ==================== //
	// Login with Email //
	// ==================== //
	auth(params: any) {
        return this._http.post(`${environment.baseURL}/auth`, params);
    }

	// ======================== //
	// Get User Profile
	// ======================= //
	getUserProfile(refetch: boolean = false){
		return this._http.get(this.fetchDataFromApi({
			host: 'users/detail',
			refetch: refetch
		}))
	}

	// =================================== //
    // Update user is Back
    // =================================== //
    updateUserIsBack(){
        return this._http.get(`${environment.baseURL}/users/is-back`);
    }

	// ======================== //
	// Get User Profile Server Side On Init
	// ======================= //
	getUserProfileServerSide(){
		return this._http.get(`${environment.baseURL_GET}/users/detail`);
	}

	// ======================== //
	// Register
	// ======================== //
    register(params: RegisterUserParams) {
        return this._http.post(`${environment.baseURL}/users/register`, params);
    }

	// ======================== //
	// Resend OTP
	// ======================== //
    resendOTP(body: ResendOTPParams) {
        return this._http.post(`${environment.baseURL}/users/resend-otp`, body);
    }


	// ======================== //
	// Change Password
	// ======================== //
    changePassword(params) {
        return this._http.post(`${environment.baseURL}/users/update-password`, params);
    }



	// ======================== //
	// Upload type (image & object)
	// ====================== //
	uploadAvatar(file) { 
		return this._http.post(`${environment.baseURL}/image/upload-avatar`, file);
	}

	
	// ======================== //
	// Check Password
	// ====================== //
	getIsPassword() {
        return this._http.get(`${environment.baseURL}/users/check-password`);
	}


	// ======================== //
	// search exhibition
	// ====================== //
	search(offset, keyword, type='') {
		let params = {
			keyword: keyword,
			offset: offset,
			type: type
		}
		return this._http.get(`${environment.base_host}/api/search`, { params: params });
	}

	// ======================== //
	// get user
	// ====================== //
	public getUserStamp: number = 0;
	public onFetchUser: boolean = false;
	public getPublicUserInfo(username) {
		return this._http.get(`${environment.baseURL}/user/${username}?stamp=${this.getUserStamp}`);
	}
	public getPublicUserInfoSSR(username) {
		return this._http.get(`${environment.baseURL_GET}/user/${username}`);
	}

	// ======================== //
	// get exhibition
	// ====================== //
	getExhibitionsByUser(username, offset, status) {
		let params = {
			username: username,
			offset: offset,
			status: status
		}
		return this._http.get(`${environment.base_host}/api/exhibitions`, { params: params });
	}

	// ======================== //
	// get exhibition
	// ====================== //
	getArtworksByUser(username) {
		let params = {
			username: username
		}
		return this._http.get(`${environment.base_host}/api/artworks`, { params: params });
	}

	// ======================== //
	// Add Customer and Subcribe
	// ====================== //
	subscribeProduct(type: string, body) {
		return this._http.post(`${environment.baseURL}/billing/add-customer?type=${type.toLowerCase()}`, body);
	}


	// ======================== //
	// List Card
	// ====================== //
	getListCard() {
		let base_Url = this.isBrowser ? environment.baseURL : environment.baseURL_GET;
		return this._http.get(`${base_Url}/billing/list-card`);
	}

	// ======================== //
	// Get Detail Pricing
	// ====================== //
	getBillingDetail() {
		return this._http.get(`${environment.baseURL}/billing/detail-pricing`);
	}
	

	// ======================== //
	// Update language
	// ====================== //
	updateLanguage(lang) {
		return this._http.post(`${environment.base_host}/api/save-lang`,{
			lang: lang
		});
	}

	/**
	 * * SANITIZE URL *
	 * Todo: for sanitize url
	 * @param url : String
	 */
	sanitize(url:string){
		return this.sanitizer.bypassSecurityTrustUrl(url);
	}

	/**
	 * * BASE64 TO FILE *
	 * Todo: for converting base64 to file
	 * @param url : String
	 */
	dataURLtoFile(dataurl, filename) {
		var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
		while(n--){
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new File([u8arr], filename, {type:mime});
	}

	/**
	 * * TO UPPER FIST LETTER *
	 * Todo: Transform first letter from each word to upper case
	 * @param text 
	 * @returns String
	 */
	toUpperFirstLetter(text) {
		text = text.toLowerCase().split(' ');
		text = text.map((s) => s.charAt(0).toUpperCase() + s.substring(1));
		return text.join(' ');
	}

	/**
     * * CONVERT BASE64 TO FILE OBJECT *
     * Todo: to covert base64 to file object
     * @param base64 
     */
	 async convertBase64ToFile(base64: string){
		const response = await fetch(base64);
		const data = await response.blob();
		
		// Craete file info
		const extention = base64.split(';')[0].split('/')[1];
		const fileName = Math.random().toString(20).substr(2, 10) + "." + extention;
		const metadata = { type: extention };
		
		return new File([data], fileName, metadata);
	}


	/**
	 * * CREATE IMAGE PLACEHOLDER *
	 * Todo: Transform first letter from each word to upper case
	 * @param text 
	 * @returns String
	 */
	createImagePlaceholder(width, height, text = "") {
        const element = document.createElement("canvas");
        const context = element.getContext("2d");

        let realHeight = 0;
        let realWidth = 0;
        if (height <= 4000 && width <= 4000) {
            realHeight = height
            realWidth = width
        } else {
            if (width >= height) {
                const ratioYX = height / width;
                realWidth  = 4000;
                realHeight  = Math.round(realWidth * ratioYX);
            } else {
                const ratioXY    = width / height;
                realHeight  = 4000;
                realWidth   = Math.round(realHeight * ratioXY);
            }
        }

        element.width = realWidth;
        element.height = realHeight;

        // Fill in the background
        context.fillStyle = "#aaaaaa";
        context.fillRect(0, 0, element.width, element.height);

        // Place the text
        context.font = `bold ${realWidth*0.07}px sans-serif`;
        context.fillStyle = "#333333";
        context.textAlign = "center";
        context.textBaseline = "middle";
		if(!text) text = `${width}x${height}`
        context.fillText(text, element.width / 2, element.height / 2);

        return element.toDataURL('image/jpeg', 0.5);
    }

	/**
     * * SET USER PROFILE *
     * Todo: to set user profile
     */
	setUserAvatar(avatar: any){
        if(!avatar) avatar = environment.staticAssets+"images/other/default-avatar.png?t="+this.appVersion;
        if(avatar.includes("?resize") && !avatar.includes(environment.image_path)) avatar = this.convertPathImage(avatar);
		return avatar;
    }

	/**
	 * * VALIDATION PROCESS PUBLISH/CREATE EXHIBITION *
	 * Todo: to validating process pulish and create exhibition 
	 */
	validationProcess(type:string){
		return this._http.post(`${environment.baseURL}/process-exhibition?param=${type}`,{})
	}

	/**
     * * CHECK USERNAME *
     * Todo: query for checking available username
     * @param username: String
     */
	 checkUsername(username){
        return this._http.get(`${environment.baseURL}/check-username/${username} `);
    }

	// ======================== //
	// FORGOT PASSWORD
	// ======================= //
    forgotPassword(body: ForgotPasswordParams){
        return this._http.post(`${environment.baseURL}/users/forgot-password`, body);
    }

	// ======================== //
	// CONTACT US
	// ======================= //
	postContactUs(subject,body){
		if(subject === 'general') subject = 'contact';
		return this._http.post(`${environment.baseURL}/contact-us?subject=${subject}`, body, {
			reportProgress: true,
			observe: 'events'
		}).pipe(
			map(event => {
				switch (event.type) {
					case HttpEventType.UploadProgress:
						const percentDone = Math.round(100 * event.loaded / event.total);
						return { status: 'progress', loaded: percentDone };
					case HttpEventType.Response:
						return { ...event.body, status: 'response' };
					default:
						return `Unhandled event: ${event.type}`;
				}
			})
		);
	}

	/**
	 * * GET BUILD VERSION *
	 * Todo: to getting build version
	 */
	getappVersion(){
		return this._http.get(`${environment.base_host}/assets/version.txt`, {
			responseType: "text"
		});	
	}

	/**
     * * INIT GRECAPTCHA *
     * Todo: to initialize grecaptcha
     */
    initGrecaptcha(params: initGrecaptchaParams) {
		const { elementId, callback, expiredCallback } = params;
		grecaptcha.ready(() => {
			grecaptcha.render(elementId, {
				'sitekey' : this.googleRecaptchaSiteKey,
				'callback' : callback,
				'expired-callback': expiredCallback
			});
		})
    }

	/**
	 * * RESET GRECAPTCHA *
	 * Todo: to reset grecaptcha
	 */
	resetGrecaptcha() {
		grecaptcha.reset();
	}


	/**
	 * * ========================================================================= *
	 * * UTILITIES FUNCTIONS 																											 *
	 * * ========================================================================= *
	 * - LOAD MUTLI SCRIPT
	 */

	/**
	 * * LOAD MUTLI SCRIPT *
	 * Todo: to load 3rd party scripts programmatically
	 * @param scriptPaths: String[] => link/path of scripts
	 * @return Promise 
	 */
	loadScripts(scriptPaths: string[]) {
		return new Promise((resolve, reject)=>{
			let itemIndex: number = 0;
			const loadScript = () => {
				if (scriptPaths[itemIndex]) {
					this.scriptService.loadScript(scriptPaths[itemIndex]).subscribe(()=>{
						itemIndex++;
						loadScript();
					}, (err) => {
						reject(err);
					});
				} else {
					resolve('Scripts has loaded!');
				}
			};
			loadScript();
		});
	}

	/**
	 * * CHECK IF IMAGE IS NOT BROKEN *
	 * Todo: to check if image is not broken
	 * @param image: File ->  seleted image file from input
	 * @return Promise
	 */
	isImageNotBroken(image: File): Promise<boolean> {
		return new Promise((resolve, reject) => {
			const url = URL.createObjectURL(image);
			const img = new Image();
			img.src = url;
			img.onerror = (err) => {
				resolve(false)
			};
			img.onload = () => {
				resolve(true)
			};
		})
	}

	// ======================== //
	// DISABLE KEY INPUT BACKSLASH
	// ======================= //
	blockChars(e) {
		if (e.key != "\\") return true;
		else e.preventDefault();
	}

	public blockSpaceAtStart(event: KeyboardEvent, element: HTMLInputElement): void {
		if (event.key === ' ' && element.selectionStart === 0) {
			event.preventDefault();
		}
	}


	/**
	 * * CONVERT IMAGE URL *
	 * Todo: to convert img url
	 * @param string : String -> image url
	 * @return String -> converted image url
	 */
	public convertPathImage(path: string): string {
		let imageUrl = '';
		if (!path.includes(environment.image_path)) imageUrl = environment.image_path + path;
		else imageUrl = path;

		if (imageUrl.includes('resize')) {	
			const resize = path.split('?')[1].match(/resize=\d+x\d+/)[0].slice(7).split('x');
			const pathImg = Base64.encode('local:///'+ path.split('?')[0]);
			imageUrl = environment.image_path + 'rs:fit:' + resize[1] + ':' + resize[0] + ':no:0/' + pathImg;	
		}

		return imageUrl;
	}


	/**
	 * ================================================================================================================
	 * ================================================================================================================
	 * ================================================================================================================
	 */

	/**
	 * ANCHOR Get File Name From File Object
	 * @description : to get file name from file object
	 * @param file : File
	 * @returns : String
	 */
	public getFileName(file: File): string {
		const fileName = file.name.split(".").slice(0,-1).join(".");
		return fileName;
	}
	
	/**
	 * ANCHOR Get File Extension From File Object
	 * @description : to get file extension from file object
	 * @param file : File
	 * @returns : String
	 */
	public getFileExtension(file: File): string {
		const fileExtension = file.name.split(".").slice(-1)[0].toLowerCase();
		return fileExtension;
	}

	/**
	 * ANCHOR Set Model Path 
	 * @description : to set model path
	 * @param modelPath : String
	 * @returns : String
	 */
	public setModelPath(modelPath: string): string {
		if (!modelPath.includes(environment.assets_path)) {
			modelPath = environment.assets_path + modelPath;
		}

		return modelPath;
	}

	/**
	 * ANCHOR INTERVAL PENDING PAYMENT
	 * @description : to show modal pending payment
	 */
	public intervalPendingPayment: any = null;
	public startInterval() {
		this.intervalPendingPayment = setInterval(() => {
			const pendingPayment = this.userInfo.billing_addresses?.status == 'pending' ? true : false;
			if (pendingPayment) {
				this.productDetail = this.userInfo.billing_addresses.product_detail;
				this.urlInvoice = this.userInfo.billing_addresses.pdf;
				this.showPendingPayment = true;
			}
			else {
				this.stopInterval();
			}
		}, 1200000); // 1200000 milliseconds = 20 minutes
	}

	/**
	 * ANCHOR CLEAR INTERVAL PENDING PAYMENT
	 * @description : to clear interval pending payment
	 */
	public stopInterval() {
		if (this.intervalPendingPayment) {
			clearInterval(this.intervalPendingPayment);
			this.intervalPendingPayment = null;
		}
	}

	/**
	 * ANCHOR GET USER INFO WITH TOKEN
	 * @description : to get user info with token
	 * @param token : any
	 * @returns : Promise
	 */
	public getUserInfoWithToken(body: any) {
		return this._http.post(`${environment.baseURL}/direct`, body);
	}

	/**
	 * ANCHOR Close Header Message
	 * @description : to close header message
	 */
	public closeHeaderMessage() {
		if (this.showMSG) {
			if (this.userInfo) this.userInfo.is_back = false;
			document.cookie = "show_header_msg = false";
			this.showMSG = false;
			this.refreshHeader();
		}
	}

	/**
	 * ANCHOR Refresh Header Height
	 * @description : to refresh header height when message banner is closed
	 */
	public refreshHeader(){
		if (this.isBrowser) {
			const mainHeader:any = document.querySelector(".wrap-header");
			if(window.innerWidth <= 600){
					mainHeader.style.height = '57px';
					document.documentElement.style.setProperty('--mainHeightHeader', `57px`);
			} else {
					mainHeader.style.height = '84px';
					document.documentElement.style.setProperty('--mainHeightHeader', `84px`);
			}
		}
	}

	/**
	 * ANCHOR Refetch Data From Server
	 * @description : refetch data from server
	 * @param token : any
	 */
	private _timestamp: any[] = []
	public fetchDataFromApi({host, params, refetch = false}:{host: string, params?: string, refetch?: boolean }): string {
		let request = this._timestamp.find((item) => { return item.host == host });

		if (request != undefined) {
			if (refetch) {
				request.stamp += 1;
				request.params = params || '';
			}
		} else {
			request = {};
			request.host = host;
			request.params = params || '';
			request.stamp = 0;
			this._timestamp.push(request)
		}

		const base_host = this.isBrowser ? environment.baseURL : environment.baseURL_GET;
		let endpoint: string;

		if (request.params) endpoint = `${base_host}/${request.host}?${request.params}&stamp=${request.stamp}`;
		else endpoint = `${base_host}/${request.host}?stamp=${request.stamp}`;	

		return endpoint;
	}

	/**
   * ANCHOR Get Latest Version
   * @description : to get latest version
   * @returns : Observable<any>
   */
  public getLatestVersion(): Promise<void> {
    return new Promise((resolve, reject) => {
      this._http.get(`${environment.baseURL}/additional/latest-version`).subscribe((response: any) => {
        this.appVersion = response.data.version.version_name;
        resolve();
      }, reject);
    })
  }
}
