<div class="wrap-set-display">
    <h2 class="title">Field of View</h2>
    <div class="content-set-display">
        <div class="side-menu">
            <!-- info field of view -->
            <div class="wrap-info">
                <i class="vi vi-start" style="font-size: 8px;"></i>
                <p class="desc-info ml5">The field of view value affects the camera's moving speed. please adjust it to your needs.</p>
            </div>

            <ul>
                <li class="item-menu" [class.active]="actionMenu==='desktop'"><a href="javascript:;" class="action-menu" (click)="tabMenu('desktop')">Desktop</a></li>
                <li class="item-menu" [class.active]="actionMenu==='tablet'"><a href="javascript:;" class="action-menu" (click)="tabMenu('tablet')">Tablet</a></li>
                <li class="item-menu" [class.active]="actionMenu==='mobile'"><a href="javascript:;" class="action-menu" (click)="tabMenu('mobile')">Mobile</a></li>
            </ul>
        </div>
        <div class="main">
            <div class="wrap-canvas">
                <canvas class="canvas" id="previewCanvas"  touch-action="none"></canvas>
                <div class="wrap-overlay" *ngIf="actionMenu==='mobile'||actionMenu==='tablet'">
                    <div class="overlay"></div>
                    <img *ngIf="actionMenu==='tablet'" src="{{env.staticAssets}}images/other/frame-tablet.png?t={{mainService.appVersion}}" alt="">
                    <img *ngIf="actionMenu==='mobile'" src="{{env.staticAssets}}images/other/frame-mobile.png?t={{mainService.appVersion}}" alt="">
                    <div class="overlay"></div>
                </div>
            </div>

            <div class="content-settings p-0">
                <div class="percentage">
                    {{sizePercent}}%
                </div>
                <div class="form-group side-by-side">
                    <button 
                        (mousedown)="updateButtonSlider('down',true)"
                        (mouseup)="updateButtonSlider('down',false)"
                        (mouseleave)="updateButtonSlider('down',false)"
                        class="btn btn-circle-icon mr15">
                        <i class="vi vi-dash"></i>
                    </button>
                    <p-slider
                        [(ngModel)]="displaySize"
                        styleClass="active-slider"
                        (onChange)="updateSliderValue(displaySize)"
                        [step]="1"
                        [min]="-130"
                        [max]="-65"
                        [ngStyle]="{'width': '100%'}">
                    </p-slider>
                    <button   
                        (mousedown)="updateButtonSlider('up',true)"
                        (mouseup)="updateButtonSlider('up',false)"
                        (mouseleave)="updateButtonSlider('up',false)"
                        class="btn btn-circle-icon ml15">
                        <i class="vi vi-plus"></i>
                    </button>
                </div>
            </div>

            <div class="wrap-button">
                <button class="btn btn-light btn-xs" (click)="editorService.showFieldOfView=false">Cancel</button>
                <button class="btn btn-primary btn-xs" (click)="saveChange()">Save <i class="vi vi-arrow-right"></i></button>
            </div>
        </div>
    </div>
</div>