<div class="wrap-menu-icon">
  <div class="left-menu">
    <ul>
      <li class="item-menu-icon" pTooltip="Horizontal Align Center" tooltipPosition="top" placeholder="top">
        <button
          [disabled]="editorService.blockUserAction"
          [class.disable-icon]="editorService.blockUserAction"
          (click)="alignText('horizontal',2)"
          class="item-btn icon-disable">
          <i class="vi vi-align-center"></i>
        </button>
      </li>
      <li class="item-menu-icon" pTooltip="Horizontal Align Left" tooltipPosition="top" placeholder="top">
        <button
          [disabled]="editorService.blockUserAction"
          [class.disable-icon]="editorService.blockUserAction"
          (click)="alignText('horizontal',3)"
          class="item-btn">
          <i class="vi vi-align-left"></i>
        </button>
      </li>
      <li class="item-menu-icon" pTooltip="Horizontal Align Right" tooltipPosition="top" placeholder="top">
        <button
          [disabled]="editorService.blockUserAction"
          [class.disable-icon]="editorService.blockUserAction"
          (click)="alignText('horizontal',1)"
          class="item-btn">
          <i class="vi vi-align-right"></i>
        </button>
      </li>
      <li class="item-menu-icon" pTooltip="Vertical Align Top" tooltipPosition="top" placeholder="top">
        <button
          [disabled]="editorService.blockUserAction"
          [class.disable-icon]="editorService.blockUserAction"
          (click)="alignText('vertical',0)"
          class="item-btn">
          <i class="vi vi-arrow-to-top"></i>
        </button>
      </li>
      <li class="item-menu-icon" pTooltip="Vertical Align Center" tooltipPosition="top" placeholder="top">
        <button
          [disabled]="editorService.blockUserAction"
          [class.disable-icon]="editorService.blockUserAction"
          (click)="alignText('vertical',2)"
          class="item-btn">
          <i class="vi vi-arrow-break"></i>
        </button>
      </li>
      <li class="item-menu-icon" pTooltip="Vertical Align bottom" tooltipPosition="top" placeholder="top">
        <button
          [disabled]="editorService.blockUserAction"
          [class.disable-icon]="editorService.blockUserAction"
          (click)="alignText('vertical',1)"
          class="item-btn">
          <i class="vi vi-arrow-to-down"></i>
        </button>
      </li>
      <li class="item-menu-icon" pTooltip="Set Position" tooltipPosition="top" placeholder="top">
        <button
          [disabled]="editorService.blockUserAction"
          [class.disable-icon]="editorService.blockUserAction"
          (click)="moveTextByAxis()" class="item-btn">
          <i class="vi vi-axis"></i>
        </button>
      </li>
    </ul>
  </div>
  <div class="right-menu">
    <ul>
      <li class="item-menu-icon" pTooltip="Delate Text" tooltipPosition="top" placeholder="top">
        <button
          [disabled]="editorService.blockUserAction"
          [class.disable-icon]="editorService.blockUserAction"
          (click)="textService.displayConfirmDelete = true"
          class="item-btn">
          <i class="vi vi-delete"></i>
        </button>
      </li>
    </ul>
  </div>
</div>