<div class="wrapper" [class.profile]="isProfile">
	<div class="wrap-viewer" [class.profile]="isProfile">
		<button class="btn-close" (click)="closePreview()"><i class="vi vi-x"></i></button>
		<h2 class="title">Hooray! You created your first gallery.</h2>

		<div class="viewer">
			<iframe width="560" height="315" [src]="shareUrl" [title]="exhibitionName"
				frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowfullscreen>
			</iframe>
		</div>

		<div class="d-flex align-item-center action">
			<!-- MOBILE BUTTON -->
			<button class="btn btn-light mobile" (click)="isShare = true">Share</button>
			<button class="btn btn-primary mobile" (click)="closePreview()">Continue to Villume</button>

			<!-- DESKTOP BUTTON -->
			<button class="btn btn-light share" (click)="isShare = true">Share the Gallery</button>
			<button class="btn btn-primary editing" (click)="openNewTab('/editor/'+exhibitionID);closePreview()">Continue Editing</button>

			<div class="share-tips tips">
				<ul>
					<li>show it off on sosial media</li>
					<li>embed it seamlessly in your website</li>
					<li>send it through messengers to spread the art</li>
					<li>email it to friends and fans</li>
				</ul>
			</div>

			<div class="edit-tips tips">
				<ul>
					<li>splash some new colors on your gallery</li>
					<li>perfect the size and arrangement of your artworks</li>
					<li>craft custom frames</li>
					<li>attach prices and direct links to your masterpleces</li>
				</ul>
			</div>
		</div>
	</div>
</div>


<ng-container *ngIf="layoutsService.isExperimental">
	<!-- ON SHARE NOT SUBS-->
	<app-not-subs-dialog [showNotSubs]="isShare" (onHide)="isShare = false"></app-not-subs-dialog>
</ng-container>

<ng-container *ngIf="!layoutsService.isExperimental">
	<!-- ON SHARE -->
	<p-dialog 
			[(visible)]="isShare" 
			[modal]="true" 
			[style]="{'width': '725px'}" 
			[baseZIndex]="10000"
			[draggable]="false" 
			[resizable]="false"
			styleClass="share-profile">
			<div class="wrap-share">
					<label for="" class="title">Share your gallery</label>
					<div class="form-group">
							<label>Shareable Link</label>
							<input type="text" id="sharelink" readonly [value]="shareLink" class="form-control bg-dark md mb0">
							<div class="d-block text-center mt10">
									<button (click)="copyText('sharelink')" class="btn btn-primary" style="padding-top: 10px">Copy</button>
							</div>
					</div>
					<div class="form-group">
							<label>Iframe</label>
							<input type="text" id="embedLink" readonly [value]="iframe" class="form-control bg-dark md mb0">
							<div class="d-block text-center mt10">
									<button class="btn btn-primary" (click)="copyText('embedLink')" style="padding-top: 10px">Copy</button>
							</div>
					</div>
			</div>
	</p-dialog>
</ng-container>