// Angular core modules
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

// User-defined modules
import { AllowedFile } from '../../images.interfaces';

// User-defined services
import { ImagesService } from '../../images.service';
import { AddImageService } from '../add-image.service';
import { MainService } from 'src/app/shared/services';
import { AlertMessageService } from 'src/app/components/alert-message/alert-message.service';
import { EditorService } from '../../../editor.service';
declare const fetch: any;

// User-defined data
import { messages } from '@data';
import { environment } from '@environments';

@Component({
    selector: 'files-handler',
    templateUrl: './files-handler.component.html',
    styleUrls: ['./files-handler.component.scss'],
    standalone: true
})
export class FilesHandlerComponent implements OnChanges {
  @Input() disabled: boolean = false;
  @Input() isOpened: boolean = false;
  @Output() onSelectFiles = new EventEmitter();

  constructor(
    private _artworkService: ImagesService,
    private _addArtworkService: AddImageService,
    private _mainService: MainService,
    private _messageService: AlertMessageService,
    private _editorService: EditorService
  ) {}


  ngOnChanges(): void {
    if(this.isOpened) setTimeout(() => this._initDragAndDrop(), 500);
  }

  /**
   * ANCHOR Init Drag And Drop
   * @description to init drag and drop
   */
  private _initDragAndDrop(): void {
    const dragArea = document.getElementById('dragArea');
    const handles = {
      ondrop: (e) => this.handleFiles(e?.dataTransfer?.files)
    }
    this._editorService.initDragAndDropFuction(dragArea, handles);
  }

  /**
   * ANCHOR Handle Input File Change
   * @description to handle input file change
   * @param event : any -> event
   */
  public handleInputFileChange(event: any): void {
    const files = event.target.files;
    if (files.length > 0) this.handleFiles(files);
    event.target.value = '';
  }

  /**
   * ANCHOR Handle Files
   * @description to handle files
   * @param files : File[] -> files
   */
  public async handleFiles(files: File[]): Promise<void> {
    if(this.disabled || files.length === 0) return;
    const arrFiles = [];
    for (let i = 0; i < files.length; i++) arrFiles.push(files[i]);
    const artworkFiles = await this._fillterAllowedFiles(arrFiles);
    this.onSelectFiles.emit(artworkFiles);
  }

  /**
   * ANCHOR Filter Allowed Files
   * @description to filter allowed files
   * @param files : File[] -> files
   * @returns : File[] -> allowed files
   */
  private async _fillterAllowedFiles(files: File[]): Promise<AllowedFile[]> {
    const allowedFiles: AllowedFile[] = [];
    let isFileNotValid = false;
    for (let i = 0; i < files.length; i++) {
      if(!this._artworkService.validateFile(files[i])) {
        isFileNotValid = true;
        break;
      }

      const type = this._addArtworkService.getArtworkType(files[i]);
    
      if(type == 'other') break;

      if(type == 'image') {
        const isImageNotBroken = await this._mainService.isImageNotBroken(files[i]);
        if(!isImageNotBroken) { isFileNotValid = true; break };

        allowedFiles.push({
          id: this._addArtworkService.generateRandomString(10),
          file: files[i],
          thumbnailUrl: this._mainService.sanitize(URL.createObjectURL(files[i])) as string
        });
        continue;
      } 
      
      if(type == 'object') {
        const thumbnailFile = await this._getThumbnailFromArtworkObject();
        allowedFiles.push({
          id: this._addArtworkService.generateRandomString(10),
          file: files[i],
          thumbnail: thumbnailFile,
          thumbnailUrl: this._mainService.sanitize(URL.createObjectURL(thumbnailFile)) as string
        });
        continue;
      }
    }

    if (isFileNotValid) {
      this._messageService.add({
        severity:'warn', 
        summary: 'Warning',
        detail: messages.editor.artwork.upload.notAlowedFormat
      });

      return null;
    }

    return allowedFiles;
  }

   /**
   * ANCHOR Get Thumbnail From Artwork Object
   * @returns : Promise<Blob>
   */
   private _getThumbnailFromArtworkObject(): Promise<File> {
    return new Promise((resolve, reject) => {
      fetch(environment.staticAssets+'images/other/ordinary-object.png?t='+this._mainService.appVersion)
        .then(response => response.blob())
        .then(blob => {
          const file = new File([blob], 'thumbnail.png', { type: 'image/png' });
          resolve(file)
        })
        .catch(reject);
      reject
    })
  }

}
