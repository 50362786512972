@import '../../../../../../assets/scss/common/variables';
@import '../../../../../../assets/scss/common/mixins';

.wrap-edit-frame{
    padding: 0 5px;

    .title-edit-frame{
        font-size: 40px;
        line-height: 120%;
        font-family: $font-whyte;
        color: $color-gray1;
        margin-left: -4px;
        margin-bottom: 10px;
    }

    .modal-frame{
        flex: 0 0 51%;
        max-width: 51%;

        .count{
            display: flex;
            align-items: center;
        }

        .btn-circle-icon{
            border-radius: 50%;
            background-color: #3D3D3D;
            width: 28px;
            height: 23px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding:0px;

            &:focus{
                outline: none;
                box-shadow: none;
            }

            .vi{
                color: $color-gray3;
                font-size: 18px;
            }
        }
    }

    .wrap-content-settings{
        flex: 0 0 49%;
        max-width: 49%;

        .wrap-edit{
            margin-right: -20px;
            padding: 0 0px 0 35px;
        
            .menu{
                margin-right: 36px;

                .nav-tabs{
                    border-bottom: none;
                    
                    .nav-item{
                        border: solid 1px $color-gray1;
                        border-right: 0;

                        &:last-child{
                            border-right: solid 1px $color-gray1;
                        }

                        .nav-link{
                            padding: 7px 5px 3px;
                            font-size: 16px;
                            color: $color-gray1;
                            border-radius: 0;
                            text-align: center;
                            height: 36px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
        
                            &.active{
                                background-color: $color-dark4;
                                border: solid 1px $color-gray3;
                                color: $color-gray3;
                                border-radius: 0;
                            }

                            &:hover{
                                text-decoration: none;
                            }
        
                            .form-group{
                                label{
                                    font-weight: normal !important;
                                    margin-left: 9px;
                                }
                            }
                        }
                    }
                }
            }
        
            .content-settings{
                background: $color-dark3;
                min-height: 402px;
                padding-top: 12px;
                border-bottom: solid 1px $color-dark4;
                padding-right: 23px;
                padding-bottom: 10px;
                padding-left: 0;
    
                .content-slide{
                    margin-bottom: 27px;
    
                    .title-content{
                        margin-bottom: 15px;
    
                        .badge{
                            margin-top: 0;
                        }
                    }
                }
        
                .btn-upload-image{
                    background-color: $color-dark4; 
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 20px 10px;
                    border: 0 none;
                    position: static;
                    height: 80px;
                    left: 0px;
                    top: 41px;
        
                    >p {
                        color: $color-gray1;
                        font-size: 16px;
                        line-height: 120%;
                        flex: none;
                        order: 0;
                        flex-grow: 0;
                        margin: 0px 10px;
                        width: 50%;
                    }

                    &.on-dragover{
                        border: solid 1px $color-gray3;
                    }
                }

                .wrap-img-upload{
                    position: relative;
                    margin-bottom: 10px;
                    
                    > a{

                        &:hover{
                            text-decoration: none;

                            .on-edit{
                                visibility: unset;
                            }
                        }

                        .img-upload{
                            width: 80px;
                            height: 80px;
                            object-fit: cover;
                        }
    
                        .on-edit{
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 80px;
                            height: 80px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: rgba(55, 55, 55, 0.78);
                            color: $color-gray3;
                            visibility: hidden;
                        }
                    }
                }
        
                .massage{
                    font-size: 12px;
                    line-height: 112.8%;
                    letter-spacing: -0.01em;
                    padding: 10px 0 11px;
                    display: block;
                    text-align: right;
                    width: 100%;
                    margin-bottom: 7px;
                    color: $color-gray1;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                }

                .measure {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &__input {
                        height: 25px;
                        width: 45px;
                        font-size: 14px;
                        padding: 0 5px;
                        color: $color-gray1;
                        background-color: $color-dark1;
                    }

                    &__vertical {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }

                    &__block {
                        width: 80px;
                        height: 80px;
                        background: $color-gray2;
                        margin: 10px;
                        position: relative;
                    }

                    &__resize {
                        color: red;
                        display: block;
                        position: absolute;
                        line-height: 14px;

                        i {
                            visibility: hidden;
                            font-size: 14px;
                        }

                        &:hover,
                        &:focus {
                            i {
                                visibility: visible !important;
                            }
                        }

                        &--top {
                            top: 0;
                            left: 10px;
                            width: 60px;
                            transform: translate(0, -50%);
                            text-align: center;

                            &:hover {
                                cursor: ns-resize;
                            }
                        }

                        &--right {
                            transform: translate(50%, 0);
                            height: 60px;
                            right: 0;
                            top: 10px;
                            display: flex;
                            align-items: center;

                            &:hover {
                                cursor: ew-resize;
                            }
                        }

                        &--bottom {
                            bottom: 0;
                            left: 10px;
                            width: 60px;
                            transform: translate(0, 50%);
                            text-align: center;

                            &:hover {
                                cursor: ns-resize;
                            }
                        }

                        &--left {
                            transform: translate(-50%, 0);
                            height: 60px;
                            left: 0;
                            top: 10px;
                            display: flex;
                            align-items: center;

                            &:hover {
                                cursor: ew-resize;
                            }
                        }

                        &.disabled {
                            cursor: default;

                            &:hover,
                            &:focus {
                                i {
                                    visibility: hidden !important;
                                }
                            }
                        }
                    }
                }

                .divider {
                    border-bottom: solid 1px $color-black;
                    margin-bottom: 15px;
                }
            }

            .wrap-list-frame{
                display: flex;
                flex-wrap: wrap;
                box-sizing: border-box;
                margin: 0 -5px;
                margin-top: 10px;

                .item-frame{
                    width: 25%;
                    max-width: 25%;
                    padding: 5px;
                    position: relative;

                    &.active{
                        .img-frame{
                            border: solid 2px $color-primary;
                        }

                        .title-frame{
                            color: $color-primary !important;
                        }
                    }

                    &.on-hover{
                        .wrap-img-frame{
                            &::before{
                                opacity: 0.5;
                            }

                            .action-menu{
                                display: flex;
                            }
                        }

                        .title-frame{
                            color: $color-primary;
                        }
                    }

                    .wrap-img-frame{
                        position: relative;
                        height: 92px;
                        cursor: pointer;

                        &::before{
                            content: "";
                            display: block;
                            width: 100%;
                            height: 100%;
                            background-color: $color-dark3;
                            position: absolute;
                            left: 0;
                            top: 0;
                            opacity: 0;
                            pointer-events: none;
                        }

                        .img-frame{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
    
                        .action-menu{
                            display: none;
                            position: absolute;
                            top: 9px;
                            width: 26px;
                            height: 26px;
                            border-radius: 30px;
                            background-color: $color-gray9;
                            color: $color-dark1;
                            align-items: center;
                            justify-content: center;

                            &.left{
                                left: 9px;
                            }

                            &.right{
                                right: 9px;
                            }

                            &:hover{
                                background-color: $color-gray3;
                            }

                            &:hover,
                            &:focus{
                                text-decoration: none;
                            }

                            .vi{
                                font-size: 18px;
                            }
                        }
                    }

                    .title-frame{
                        color: $color-gray1;
                        font-size: 14px;
                        line-height: 19.04px;
                        padding-top: 9px;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        cursor: pointer;
                        overflow-wrap: break-word;
                    }

                    .show-action{
                        display: block;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        
                        &.show-bg{
                            background: #292929;
                            opacity: 0.5;
                        }
                    }
                }
            }

            .wrap-loading-frame-list{
                padding-top: 10px;

                p{
                    font-size: 16px;
                    text-align: center;
                }
            }
        }

        .wrap-save-frame{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 20px;
            padding: 0 20px;
        }
    
        .btn{
            margin-right: 20px;

            > i {
                font-size: 12px;
            }
        }
    }
}