<div class="form-group content-slide mb30">
  <div class="title-content">
    <label>Scaling</label>
    <input-number
      #inputNumberScaling
      [value]="textService.scalingValue"
      (onChange)="updateInputValue($event)"
      (onFocus)="editorService.onInput=$event;"
      (onBlur)="editorService.onInput=$event;"
      [disabled]="editorService.blockUserAction"
      [min]="20"
      [max]="500">
    </input-number>
  </div>
  <p-slider 
    [(ngModel)]="textLoader.activeTextNode.scaling.x"
    (onChange)="scalingWrapText();textService.initInputsValue()"
    [min]="0.20" 
    [max]="5" 
    [step]="0.01" 
    [disabled]="editorService.blockUserAction" 
    [styleClass]="editorService.blockUserAction ? 'disable-slider ml15' : 'ml15'">
  </p-slider>
</div>