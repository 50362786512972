<!-- Alert Confirmation -->
<p-dialog
    [(visible)]="showAuthDialog"
    [modal]="true" 
    [baseZIndex]="5000"
    [draggable]="false" 
    [resizable]="false" 
    (onShow)="checkInvoice()"
    (onHide)="onHide.emit(true); onCancel(cancelAuthDialog)"
    closeIcon="vi vi-x"
    styleClass="p-dialog alert-dialog">
    <div class="wrap-content">
        <div class="alerts">
            <h1 class="alerts__title">Your latest payment needs 3D secure authentication</h1>
            <p class="alerts__desc">Please complete authentication on Stripe. If you cannot proceed the authentication, please update the billing information instead.</p>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="alerts__button">
            <button 
                (click)="showAuthDialog=false; cancelAuthDialog=true"
                class="btn btn-light btn-sm ml0 mr-auto">
                Cancel
            </button>
            <button 
                [disabled]="noInvoice"
                (click)="showAuthDialog=false; cancelAuthDialog=false"
                class="btn btn-light btn-sm ml0">
                Check Invoice
            </button>
            <button 
                (click)="AuthentificationSubscribe();cancelAuthDialog=true"
                class="btn btn-primary btn-sm ml15">
                Complete Authentification
                <i class="vi vi-outside-link"></i>
            </button>
        </div>
    </ng-template>
</p-dialog>

<!--  Preview Invoice -->
<p-dialog
    [(visible)]="showInvoiceDialog"
    [modal]="true" 
    [baseZIndex]="5000"
    [draggable]="false" 
    [resizable]="false" 
    (onShow)="showPDF()"
    closeIcon="vi vi-x"
    styleClass="p-dialog preview-pdf">
    <div class="wrap-content">
        <canvas id="pdf-canvas" width="620"></canvas>
    </div>
</p-dialog>