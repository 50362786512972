<div class="d-flex justify-content-between mb15">
  <div class="form-group side-by-side mb0">
    <label>Font Size</label>
    <input-number
      #inputNumberFontSize
      [value]="textService.fontSize"
      (onFocus)="editorService.onInput=true"
      (onChange)="updateInputValue($event,'fontSize')"
      (onBlur)="editorService.onInput=false"
      [button]="true"
      [min]="1"
      [max]="9999"
      [disabled]="editorService.blockUserAction">
    </input-number>
  </div>
  <div class="form-group side-by-side mb0">
    <label>Line Height</label>
    <input-number
      #inputNumberLineHeight
      [value]="textService.lineHeight"
      (onFocus)="editorService.onInput=true"
      (onChange)="updateInputValue($event,'lineHeight')"
      (onBlur)="editorService.onInput=false"
      [button]="true"
      [min]="1"
      [max]="9999"
      [disabled]="editorService.blockUserAction">
    </input-number>
  </div>
</div>
