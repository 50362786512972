<div class="form-group content-description mb20">
  <label>Content:</label>
  <textarea
      [readonly]="editorService.blockUserAction" 
      [(ngModel)]="textLoader.activeText.content" 
      (input)="updateTextContent()"
      (focus)="editorService.onInput=true"
      (blur)="editorService.onInput=false" 
      class="form-control bg-dark" 
      [ngStyle]="{'height.px': 124, 'resize': 'none'}"
      (keydown)="mainService.blockChars($event)">
  </textarea>
</div>