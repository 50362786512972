<app-layouts>
	<div class="wrapper offset-iped-1" content>
		<div class="info">
			<div class="wrap-details">
				<div class="about">
					<label class="label d-block">About</label>
					<p class="desc">
						{{userInfo?.about}}
						<i *ngIf="!userInfo?.about" class="vi vi-dash-2"></i>
					</p>
				</div>

				<div class="sosial-mobile">
					<ng-container *ngTemplateOutlet="sosialMedia"></ng-container>
				</div>

				<div class="contact-info">
					<label class="label">Contact Info</label>
					<div class="website" *ngIf="userInfo?.website_url">
						<label class="label-desc">Website</label>
						<span class="desc"><a [href]="userInfo?.website_url">{{userInfo?.website_url}}</a></span>
					</div>

					<div class="email"  *ngIf="userInfo?.email_profile">
						<label class="label-desc">Email</label>
						<span class="desc"><a [href]="'mailto:'+userInfo?.email_profile">{{userInfo?.email_profile}}</a></span>
					</div>

					<div *ngIf="!userInfo?.website_url && !userInfo?.email_profile">
						<i class="vi vi-dash-2"></i>
					</div>
				</div>

				<div class="general" *ngIf="userInfo?.location">
					<label class="label">General</label>
					<div class="location">
						<label class="label-desc">Location</label>
						<span class="desc">{{userInfo?.location}}</span>
					</div>
				</div>
			</div>

			<div class="sosial-desktop">
				<ng-container *ngTemplateOutlet="sosialMedia"></ng-container>
			</div>
		</div>
	</div>
</app-layouts>

<ng-template #sosialMedia>
	<div class="sosial-media">
		<label class="label">Social Media</label>
		<div class="media">
			<button class="facebook" *ngIf="userInfo?.facebook_url"
				(click)="navigateToSosmed(userInfo.facebook_url, 'facebook')">
				<i class="vi vi-facebook-solid"></i>
			</button>
			<button *ngIf="userInfo?.instagram_url" (click)="navigateToSosmed(userInfo.instagram_url, 'instagram')"
				class="instagram">
				<i class="vi vi-Instagram-solid"></i>
			</button>
			<div *ngIf="!userInfo?.facebook_url && !userInfo?.instagram_url">
				<i class="vi vi-dash-2"></i>
			</div>
		</div>
	</div>
</ng-template>