import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/shared/services';
import { LayoutsComponent } from '../../../layouts/layouts.component';

@Component({
    selector: 'app-delete-account',
    templateUrl: './delete-account.component.html',
    styleUrls: ['./delete-account.component.scss'],
    standalone: true,
    imports: [LayoutsComponent]
})
export class DeleteAccountComponent implements OnInit {

	constructor(public mainService:MainService) { }

	ngOnInit(): void {
	}

	openLogin(){
		this.mainService.displayLoginDialog = true; 
	}
}
