import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
import { MainService } from 'src/app/shared/services';
import { EditorService } from '../virtual-gallery/editor/editor.service';
import { FormGroup, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContactService } from './contact.service';
import { AlertMessageService } from 'src/app/components/alert-message/alert-message.service';
import { debounce } from 'lodash';
import { environment } from '@environments';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgIf, NgClass, NgStyle, NgFor } from '@angular/common';
import { LayoutsComponent } from '../../layouts/layouts.component';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('slideUp', [
            state('void', style({
                height: '*'
            })),
            transition('void => *', [
                animate(500, keyframes([
                    style({ opacity: 0, offset: 0, height: 0 }),
                    style({ opacity: 0.2, offset: 0.2, height: '*' }),
                    style({ opacity: 1, offset: 1.0, height: 'auto' })
                ]))
            ]),
            transition('* => void', [
                animate(200, keyframes([
                    style({ opacity: 1, offset: 0, height: 'auto' }),
                    style({ opacity: 0.2, offset: 0.2, height: '*' }),
                    style({ opacity: 0, offset: 1.0, height: 0 })
                ]))
            ])
        ])
    ],
    standalone: true,
    imports: [
        LayoutsComponent,
        NgIf,
        FormsModule,
        ReactiveFormsModule,
        NgClass,
        NgStyle,
        NgFor,
        LazyLoadImageModule,
    ],
})
export class ContactComponent implements OnInit {
	
	public conditionDragImage: boolean = false;
	public loading: boolean = false;
	public image: File;
	public showImage: boolean = false;
	public imageUrl: string = "";
	public imageFile: string = "";
	public imageFileHelp:any;
	public imageUrlHelp:any;

	public imageFileCustom:any;
	public imageUrlCustom:any;

	public helpAttacementFiles: any[] = [];
	public customAttacementFiles: any[] = [];
	public allowedExtensionsCustom: string = ".jpg,.png,.docx,.doc,.pdf,.jpeg";
	public allowedExtensionsHelp: string = ".jpg,.png,.jpeg";

	// Forms
	public formGeneral: FormGroup = null;
	public formHelp: FormGroup = null;
	public formRequest: FormGroup;

	// Loading marker
	public onLoadingGeneral: boolean = false;
	public onLoadingHelp: boolean = false;
	public onLoadingCustom: boolean = false;
	public loadingProgress: number = 0;
	public env = environment;

	constructor(
		public mainService: MainService,
		public editorService: EditorService,
		public contactService: ContactService,
		private alertMessageService:AlertMessageService,
	) {}

	ngOnInit(): void {
		this.initAllFormGroups();
	}

	ngAfterViewInit() {
		const recaptchaScript = `https://www.google.com/recaptcha/api.js?render=explicit`
		this.mainService.loadScripts([recaptchaScript]).then(() => {
			if (this.mainService.isBrowser) {
				// To reset form type to "general" if user not access this page from pricing pages 
				this.contactService.formType = this.contactService.fromPricing ? this.contactService.formType : 'general';
				
				const formType: any = this.contactService.formType;
				this.initGrecaptcha(formType);
				
				// Direct to "Custom Exhibition Request" if user acces this page from pricing pages
				if(this.contactService.fromPricing) {
					this.goToFormSection(formType);
					this.contactService.fromPricing = false;
				}
			}
		})
	}


	/**
	 * * ========================================== *
	 * * LIST OF FUNCTIONS							*
	 * * ========================================== *
	 * - TOOGLE FORM CONTENT
	 * - INIT GRECAPTHCA
	 * - SUBMIT CONTACT
	 * - UPLOAD ATTACHEMENT FILES
	 * - CREATE FILE DATA
	 * - CREATE IMAGE PREVIEW
	 * - INIT FORM GROUP
	 * - INIT ALL FORM GROUP
	 * - DELETE ATTACEMENT FILE
	 * - INIT DRAG DROP
	 */

	/**
	 * * TOOGLE FORM CONTENT *
	 * Todo: to toggle form contec
	 */
	toggleFormContent(type: 'help' | 'custom' | 'general'){
		if(this.contactService.formType == type) {
			this.contactService.formType = "";
			return;
		}
		else this.contactService.formType = type;

		this.initGrecaptcha(type);

		switch (type) {
			case 'help': this.initDragDrop("drop-area-help","help"); break;
			case 'custom': this.initDragDrop("drop-area-custom","custom"); break
		}
	}

	/**
	 * * INIT GRECAPTHCA *
	 * Todo: to init grecapthca
	 */
	initGrecaptcha(type: 'help' | 'custom' | 'general') {
		let formGroup: FormGroup;
		let elementId: string;
		
		switch(type) {
			case 'help': 
				elementId = 'captchaHelp';
				formGroup = this.formHelp;
			break;
			case 'custom': 
				elementId = 'captchaCustom';
				formGroup = this.formRequest;
			break;
			case 'general': 
				elementId = 'captchaGeneral';
				formGroup = this.formGeneral;
			break;
		}

		formGroup.get('captchaVerified').setValue(false);
		this.mainService.initGrecaptcha({
			elementId,
			callback: (e) => {
				formGroup.get('captchaVerified').setValue(true);
				formGroup.get('captchaToken').setValue(e);
				window.dispatchEvent(new Event('resize'));
			},
			expiredCallback: () => {
				window.dispatchEvent(new Event('resize'));
				if (!this.onLoadingGeneral && !this.onLoadingCustom && !this.onLoadingHelp) {
					formGroup.get('captchaVerified').setValue(false);
				}
			}
		});	
	}

	/**
	 * VALIDATE TOKEN RECAPTCHA
	 */
	validateTokenRecaptcha(): Promise<string> {
		return new Promise((resolve, reject) => {
		let formGroup: FormGroup;
		switch(this.contactService.formType) {
			case 'help': formGroup = this.formHelp; break;
			case 'custom': formGroup = this.formRequest; break;
			case 'general': formGroup = this.formGeneral; break;
		}

		this.contactService.validateTokenRecaptcha(formGroup.value.captchaToken).subscribe({
			next: (res: any) => {
				const token = res['data']['token'];
				formGroup.get('captchaVerified').setValue(true);
				formGroup.get('captchaToken').setValue(token);
				resolve(token);
			},
			error: (error) => {
				formGroup.get('captchaVerified').setValue(false);
				reject(error);
			}
		});
	});
	}

	/**
	 * * SUBMIT CONTACT *
	 * Todo: to submit contact
	 */
	async submitContact(type: 'general' | 'help' | 'custom'){
		const formData = new FormData();
		switch (type) {
			case "general": 
				{
					await this.validateTokenRecaptcha();
					const values = this.formGeneral.value;
					formData.append("from", values.email);
					formData.append("first_name", values.first_name);
					formData.append("last_name", values.last_name);
					formData.append("subject", values.subject);
					formData.append("description", values.description);
					formData.append("token", values.captchaToken);
					this.onLoadingGeneral = true;
					this.formGeneral.disable();
				}
			break;
			case "help":
				{
					await this.validateTokenRecaptcha();
					const values = this.formHelp.value;
					formData.append("from", values.email);
					formData.append("first_name", values.first_name);
					formData.append("last_name", values.last_name);
					formData.append("subject", values.subject);
					formData.append("description", values.description);
					this.helpAttacementFiles.map((fileData: any) => {
						formData.append("image_attachment[]", fileData.file)
					});
					formData.append("token", values.captchaToken);
					this.onLoadingHelp = true;
					this.loadingProgress = 0;
					this.formHelp.disable();
				}
			break;
			case "custom":
				{
					await this.validateTokenRecaptcha();
					const values = this.formRequest.value;
					formData.append("from", values.email);
					formData.append("description", values.description);
					formData.append("first_name", values.first_name);
					formData.append("last_name", values.last_name);
					formData.append("token", values.captchaToken);
					if (values.company) formData.append("company", values.company);
					if (values.web_url) formData.append("web_url", values.web_url);
					this.customAttacementFiles.map((fileData: any) => {
						if (fileData.file.type.includes('image')) {
							formData.append("image_attachment[]", fileData.file)
						} else {
							formData.append("file[]", fileData.file)
						}
					});
					this.onLoadingCustom = true;
					this.loadingProgress = 0;
					this.formRequest.disable();
				}
			break;
		}

		this.mainService.postContactUs(type,formData).subscribe((res:any) => {
			if(res['status'] == "progress") {
				this.loadingProgress = res['loaded'];
			}

			if(res['status'] == 'response') {
				this.onLoadingGeneral = false;
				this.onLoadingCustom = false;
				this.onLoadingHelp = false;
				this.mainService.resetGrecaptcha()
				if(type=="general") {
					this.formGeneral.reset();
					this.formGeneral.enable();
				};
				if(type=="help") {
					this.formHelp.reset();
					this.imageFileHelp = null;
					this.imageUrlHelp = null;
					this.helpAttacementFiles = [];
					this.formHelp.enable();
					this.initDragDrop("drop-area-help","help");
				}
				if(type=="custom") {
					this.formRequest.reset();
					this.imageFileCustom = null;
					this.imageUrlCustom = null;
					this.customAttacementFiles = [];
					this.formRequest.enable();
					this.initDragDrop("drop-area-custom","custom");
				};
				this.alertMessageService.add({ severity:"success", summary:"Success", detail: "Thank you, your request has been received!"})
			}
		}, (error)=>{
			this.onLoadingGeneral = false;
			this.onLoadingCustom = false;
			this.onLoadingHelp = false;
			if(error.name == "TimeoutError"){
				this.submitContact(type)
			} else {
				this.mainService.resetGrecaptcha();
				this.alertMessageService.add({ severity:"error", summary:"Error", detail: "Param Not Valid"})
			}
		})
	}

	/**
	 * * VALIDATE MAX SIZE ATTACHEMENT FILES *
	 * Todo: to validate size of attacement files
	 */
	MaxSizeAttacementFiles(files, type): boolean {
		const maxFileSizeMB = 100;
		const totalSizeBytes = files.reduce((totalSize, file) => totalSize + file.size, 0);

		let attachedSize = 0;
		switch (type) {
			case 'help':
				attachedSize = this.helpAttacementFiles.reduce((totalSize, fileData) => totalSize + fileData.file.size, 0);
				break;
			case 'custom':
				attachedSize = this.customAttacementFiles.reduce((totalSize, fileData) => totalSize + fileData.file.size, 0);
				break;
		}

		const totalSizeMB = (attachedSize + totalSizeBytes) / (1024 * 1024);

		if (totalSizeMB > maxFileSizeMB) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * * UPLOAD ATTACHEMENT FILES *
	 * Todo: to upload attacement files
	 */
	uploadAttachementFiles = debounce((event: any, type: "help" | "custom", fromDrag:boolean = false) => {
		// Convert FileList to Array;
		let newFiles:any[] = [];
		if (fromDrag) {
			newFiles = [...event];
		} else {
			newFiles = [...event.target.files];
			event.target.value = '';
		}

		// Create files data
		const createdFiles = []
		newFiles.map((file: any) => {
			const fileExtention = file.name.split(".").slice(-1)[0]?.toLowerCase();
			switch (type) {
				case 'custom':
					if (this.allowedExtensionsCustom.includes(fileExtention)) {
						createdFiles.push(this.createFileData(file, type, fileExtention));
					}
				break;
				case 'help':
					if (this.allowedExtensionsHelp.includes(fileExtention)) {
						createdFiles.push(this.createFileData(file, type, fileExtention));
					}
				break;
			}
		});

		if(createdFiles.length === 0) {
			this.alertMessageService.add({ 
				severity:"warn",
				summary:"Warning",
				detail: "Files format is not supported."
			});

			return;
		}

		Promise.all(createdFiles).then(() => {
			if (this.brokenImageCounter > 0) {
				this.alertMessageService.add({ 
					severity:"warn", 
					summary:"Warning", 
					detail: `There are ${this.brokenImageCounter} corrupt images! corrupt images will not be added to the list.`
				})
				this.brokenImageCounter = 0;
				return;
			}

			if (this.maxSizeFileCounter > 0) {
				this.alertMessageService.add({ 
					severity:"warn", 
					summary:"Warning", 
					detail: `Total file size exceeds maximum size.`
				})
				this.maxSizeFileCounter = 0;
				return;
			}
			
			switch(type) {
				case 'help':
					if(this.helpAttacementFiles.length > 0) this.initDragDrop("drop-area-help-add","help");
				break;
				case 'custom':
					if(this.customAttacementFiles.length > 0) this.initDragDrop("drop-area-custom-add","custom");
				break;
			}

		})
	}, 10)
	

	/**
	 * * CREATE FILE DATA *
	 * Todo: to create file data
	 */
	private brokenImageCounter: number = 0;
	private maxSizeFileCounter: number = 0;
	async createFileData(file, type: "help" | "custom", extension){
		// Validate broken image
		if('.jpg,.png,.jpeg'.includes(extension)){
			if(!await this.mainService.isImageNotBroken(file)) {
				this.brokenImageCounter++;
				return;
			}
		}

		// Validate Max of attached files is 100MB
		if (this.MaxSizeAttacementFiles([file], type)) {
			this.maxSizeFileCounter++;
			return;
		}

		// Create object file data
		const fileData: any = {
			id: Math.floor(Math.random() * 500),
			url: this.createImagePreview(file),
			file: file
		}
		
		// Add to list attacement file based on type
		switch (type) {
			case "help": this.helpAttacementFiles.push(fileData); break;
			case "custom": this.customAttacementFiles.push(fileData); break;
		}
	}

	/**
	 * * CREATE IMAGE PREVIEW *
	 * Todo: to create image preview
	 */
	createImagePreview(file: any){
		const fileExtention = file.name.split(".").slice(-1)[0];
		let url = "";
		if(fileExtention=="pdf") url = this.env.staticAssets+"images/other/pdf.png?t="+this.mainService.appVersion
		else if(fileExtention=="docx"||fileExtention=="doc") url = this.env.staticAssets+"images/other/doc.png?t="+this.mainService.appVersion
		else url = URL.createObjectURL(file);

		return url;
	}

	/**
	 * * INIT FORM GROUP *
	 * Todo: to initialize form group
	 */
	initFormGroup(formGroup: FormGroup, forFormCustom: boolean = false){
		formGroup = new FormGroup({
			email : new FormControl('', [Validators.required, Validators.email]),
			first_name : new FormControl('', [Validators.required]),
			subject : new FormControl('', [Validators.required]),
			last_name : new FormControl('', [Validators.required]),
			description : new FormControl('', [Validators.required]),
			company : new FormControl(),
			web_url : new FormControl(),
			captchaVerified: new FormControl(false, [Validators.requiredTrue]),
			captchaToken:  new FormControl('', [Validators.required])
		});

		if(forFormCustom) formGroup.removeControl("subject")
		return formGroup
	}

	/**
	 * * INIT ALL FORM GROUP *
	 * Todo: to initial all form group
	 */
	initAllFormGroups(){
		this.formGeneral = this.initFormGroup(this.formGeneral);
		this.formHelp = this.initFormGroup(this.formHelp);
		this.formRequest = this.initFormGroup(this.formRequest,true);
	}

	/**
	 * * DELETE ATTACEMENT FILE *
	 * Todo: to delete attacement file
	 */
	deleteAttacementFile(fileDataId: any, type: "help" | "custom"){
		if (this.onLoadingCustom || this.onLoadingHelp) return;
		switch (type) {
			case "help":
				this.helpAttacementFiles = this.helpAttacementFiles.filter((file: any) => file.id != fileDataId);
				if(!this.helpAttacementFiles.length) this.initDragDrop("drop-area-help","help");
			break;
			case "custom":
				this.customAttacementFiles = this.customAttacementFiles.filter((file: any) => file.id != fileDataId);
				if(!this.customAttacementFiles.length) this.initDragDrop("drop-area-custom","custom");
			break;
		}
	}

	/**
	 * * INIT DRAG DROP *
	 * Todo: to initialize drag and drop
	 */
	initDragDrop(id,type){
		setTimeout(()=>{
			const dropArea = document.getElementById(id);
			if(dropArea){
				const handles = {
					ondrop: (e) => {
						let dt = e.dataTransfer;
						this.uploadAttachementFiles(dt.files,type, true);
						dropArea.classList.remove('highlight');
					},
					ondragenter: (e) => {
						dropArea.classList.add('highlight');
					},
					ondragleave: (e) => {
						dropArea.classList.remove('highlight');
					} 
				}
				this.editorService.initDragAndDropFuction(dropArea, handles)	
			}
		},1000)
	}

	/**
	 * * GO TO FORM SECTION *
	 * Todo: to go to form section
	 */
	goToFormSection(type: 'general'| 'help' | 'custom') {
		setTimeout(() => {
			if(type !== 'general') {
				const wrapNavigation = document.getElementsByClassName('wrap-navigation')[0];
				const formPosition = document.getElementById(type).getBoundingClientRect().top;
				document.body.scrollTop = formPosition - wrapNavigation.clientHeight;
			}
		}, 100)
	}

	/**
   * * HANDLE SPACE AT BEGIN *
   * Todo: to handle spaces at the start of a word
	 * @param formControlName: String -> form control name
   */
  handleSpaceAtBegin(formControlName: string) {
		let formGroup;
		switch (this.contactService.formType) {
			case 'general': formGroup = this.formGeneral ;break;
			case 'help': formGroup = this.formHelp ;break;
			case 'custom': formGroup = this.formRequest ;break;
		}
    const formControl = formGroup.get(formControlName);
    formControl.setValue(formControl.value.trimStart());
  }
}
