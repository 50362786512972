import { Injectable, TransferState } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SsrCookieService as CookieService } from 'ngx-cookie-service-ssr';
import { MainService } from '../services/main.service';
import { userData } from 'src/app/app.component';

@Injectable({
  providedIn: 'root'
})
export class AuthSupportGuard  {

	constructor(
		public mainService: MainService,
		private cookieService: CookieService,
		private _transferState: TransferState
	) {}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		
		if(this.mainService.isBrowser){
			if(this.cookieService.get("villume_token")){
				const userInfo = this._transferState.get<any>(userData, null);
				if(userInfo?.user_type=='support'){
					return true;
				}else{
					window.location.href = "/404"
					return false;
				}
			}else{
				window.location.href = "/404"
				return false;
			}
		}
	}
}
