<app-layouts #layouts>
    <div class="wrap-main-catalogue" content>

        <!-- START CATALOGUE MACT HERDER
        ============================================= -->
        <section class="section catalogue">
                <div class="row">
                    <div class="col col16-16 col-mobile-4">
                        <h1 class="title">Choose the perfect space <br>for your artworks.</h1>
                    </div>
                    <div class="col col16-8 offset16-8 col-iped-8 col-mobile-4 description">
                        <p>
                            Use our vast collection of templates. Decide which one fits your art best and create 
                            a unique exhibition in just a few clicks. You can choose from templates with modifiable or fixed interiors.<br>
                            Can’t find a template that suits your exhibition, or have your own idea in mind? Contact us, 
                            and get a custom-made gallery according to your artistic vision. Have your own gallery? We can create 
                            an exact copy of the layout so you can exhibit and sell your artwork digitally anywhere in the world.
                        </p>
                    </div>
                </div>

                <!-- <div class="row">
                    <div class="col16-8">
                        <h1 class="title font-whyte">Choose the perfect space for your artworks.</h1>
                    </div>
                    <div class="col16-8"> -->
                        <!-- CONTENT
                        ============================== -->
                        <!-- <div class="content">
                            <p class="mb-0">
                                Use our vast collection of templates. Decide which one fits your art best and create 
                                a unique exhibition in a few clicks. You can choose from templates with modifiable 
                                and fixed interiors.<br>

                                Didn’t find a template that suits you or have your own idea of the art space design? 
                                Contact us, and you will get a custom-made gallery according to your wishes.<br>

                                Have your own gallery? We can make its exact copy so you could exhibit and sell 
                                your artwork online regardless of any offline circumstances. 

                            </p> -->
                            
                            <!-- DESCRIPTION ACTION BUTTON -->
                            <!-- <p class="desc-action-btn">We have templates which interior you can modify and the ones which you can’t bacause it’s a copy of a famous gallery or a museum.</p> -->
                            
                            <!-- START BUTTON ACTION HEADER -->
                            <!-- <div class="action-btn">
                                <ng-container *ngIf="!openFilter">
                                    <div class="form-group">
                                        <input type="radio" value="modifiable" [(ngModel)]="selectedModifiable" id="modifiable">
                                        <label for="modifiable">modifiable </label>
                                        <app-icon name="modify" class="ml10" size="18" color="#979797"></app-icon>
                                    </div>
                                    <div class="form-group">
                                        <input type="radio" value="unmodifiable" [(ngModel)]="selectedModifiable" id="unmodifiable">
                                        <label for="unmodifiable">unmodifiable </label>
                                        <app-icon name="unmodify" class="ml10" size="18" color="#979797"></app-icon>
                                    </div>
                                    <div class="form-group">
                                        <input type="radio" value="showAll" [(ngModel)]="selectedModifiable" id="showAll">
                                        <label for="showAll">Show All</label>
                                    </div>
                                </ng-container>
                                <div class="form-group ml-auto">
                                    <a href="javascript:;" (click)="openFilter=!openFilter">
                                        <ng-container *ngIf="!openFilter">
                                            <i class="pi pi-arrow-right mr-2" style="font-size: 18px;"></i>
                                        </ng-container>
                                        <ng-container *ngIf="openFilter">
                                            <i class="pi pi-arrow-down mr-2" style="font-size: 18px;"></i>
                                        </ng-container>
                                        Filter
                                    </a>
                                </div>
                            </div> -->
                        <!-- </div>
                    </div>
                </div> -->
        </section>
        <!-- END CATALOGUE MACT HERDER -->
    
        <!-- START EXHIBITION
        ========================================= -->
        <section class="wrap-item-exhibition">
            <!-- SIMULATION WIDTH LAYOUT -->
            <div class="row">
                <div class="col-xl-3 col-md-6" *ngFor="let item of catalogues">
                    <!-- START ITEM EXHIBITON
                    =================================== -->
                    <div class="item-exhibition">
                        <div class="wrap-image" [class.active]="item.onCreating">
                            <img src="{{env.staticAssets}}images/other/skeleton-landscape.svg?t={{mainService.appVersion}}" [lazyLoad]="item.thumbnail + '?t='+ mainService.appVersion" class="img-exhibition">

                            <div class="action">
                                <button (click)="openPreview(item.demo_link)" [tabIndex]="-1" class="btn-action border-left-0">Preview</button>
                                <button 
                                    [disabled]="catalogueService.onCreating " 
                                    (click)="createExhibition(item)" 
                                    [tabIndex]="-1" 
                                    class="btn-action" [class.loading-button-primary]="item.onCreating">
                                    Start Creating
                                </button>
                            </div>
                        </div>
                        <div class="content-exhibition">
                            <div class="title">{{item.name}}</div>
                            <div class="wrap-desc">
                                <p>{{item.description}}</p>
                            </div>
                        </div>
                    </div>
                    <!-- END ITEM EXHIBITON -->
                </div>

                <!-- MASSAGE DATA NOT FOUND -->
                <div class="col-12 text-center" *ngIf="!catalogues?.length && !loading">
                    <div>Data Not Found</div>
                </div>

                <!-- LOADING CONTENT -->
                <div class="col-12 wrap-loading" *ngIf="loading">
                    <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="5" animationDuration=".5s"></p-progressSpinner>
                </div>
            </div>
        </section>
        <!-- END EXHIBITION -->
    
        <!-- load more -->
        <!-- <button class="load-more">Load More</button> -->
    </div>

</app-layouts>

<p-dialog 
    [(visible)]="catalogueService.displayBlockMsg" 
    [modal]="true" 
    [draggable]="false" 
    [style]="{maxWidth: '725px'}"
    [baseZIndex]="10000"
    styleClass="confirm-delete">
    <div class="wrap-content-confirm">
        <div class="desc">
            <p class="pl0">
                Your Popup is blocked, we will direct you to the editor.
            </p>
        </div>
        <div class="wrap-btn">
            <a class="btn btn-light btn-sm" [href]="'/editor/'+catalogueService.createdId">
                Yes
            </a>
            <button (click)="closeBlockMessage()" class="btn btn-primary btn-sm mr0">No</button>
        </div>
    </div>
</p-dialog>
