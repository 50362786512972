// Angular modules/components
import { Component, TransferState, afterNextRender, makeStateKey } from '@angular/core';
import { Router, NavigationEnd, RouterOutlet } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

// User-defined services
import { MainService } from "src/app/shared/services";
import { environment } from '@environments';
import { WhiteLabelService } from './components/white-label/white-label.service';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { TranslateService } from '@ngx-translate/core';

export const userData = makeStateKey<any>('');

@Component({
    selector: 'app-root',
    template: '<router-outlet />',
    standalone: true,
    imports: [RouterOutlet],
    providers: [SsrCookieService]
  })
export class AppComponent {
  public lang: string = null;

  constructor(
    private cookieService: SsrCookieService,
    public mainService: MainService,
    private _whiteLabelService: WhiteLabelService,
    private _router: Router,
    private _meta: Meta,
    private _title:Title,
    private _translate: TranslateService,
    private _transferState: TransferState
  ) {
    this._translate.setDefaultLang('en');
    this._translate.use('en');
    
    this.mainService.getLatestVersion();
    this.getToken();
    this.getUserData();

    afterNextRender(()=>{
      this._detectDomainWhiteLabel();
      this._removeFavicon();
    })
  }
  
  ngOnInit() {
    this._handleOnChangeRoute();
    
    if(this.mainService.isBrowser) {
      // this.runChatWoot(); // this code for live only
      setTimeout(() => { this.detectOs() }, 1000);
      this.detectPendingPayment();
    }
  }

  ngAfterViewInit(){
		if(this.mainService.isBrowser){
      this.setScreenHeightProp();
      setTimeout(()=>{
        document.body.classList.add("loaded");
      }, 2000);
    }
  }

  /**
   * * HANDLE ON CHANGE ROUTE *
   * Todo: to handle on change route
   */
  private _handleOnChangeRoute(): void {
    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if(!this.mainService.isProfilePage) this._setDefaultMetaTags();
      }
    });
  }

  /**
   * * SET DEFAULT META TAGS *
   * Todo: to set default meta tags
   */
  private _setDefaultMetaTags(): void {
    const title = 'Villume | Create Your Own Virtual Exhibitions';
    const description = 'Create your own virtual exhibitions and showcase your work to a global audience. With our innovative VR technology, your virtual art gallery will come to life like never before.';
    const image = environment.staticAssets+'images/other/share-thumb.png?t='+this.mainService.appVersion;

    // Primary Meta Tags
    this._title.setTitle(title);
    this._meta.updateTag({name: 'title', content: title});
    this._meta.updateTag({name: 'description', content: description});
    
    // Open Graph / Facebook
    this._meta.updateTag({property: 'og:type', content: 'website'});
    this._meta.updateTag({property: 'og:url', content: 'https://villume.com/'});
    this._meta.updateTag({property: 'og:title', content: title});
    this._meta.updateTag({property: 'og:description', content: description});
    this._meta.updateTag({property: 'og:image', content: image});
    
    // Twitter 
    this._meta.updateTag({property: 'twitter:card', content: 'summary_large_image'});
    this._meta.updateTag({property: 'twitter:url', content: 'https://villume.com/'});
    this._meta.updateTag({property: 'twitter:title', content: title});
    this._meta.updateTag({property: 'twitter:description', content: description});
    this._meta.updateTag({property: 'twitter:image', content: image});
  }

  /**
   * * GET TOKEN *
   * Todo: to get user token
   */
  getToken() {
    this.mainService.token = this.cookieService.get('villume_token');
  }

  /**
   * * SET SCREEN HEIGHT PROPERY *
   * Todo: to set sceen height propery
   */
  setScreenHeightProp() {
    // Init screen height on load
    document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`);

    // Update screen height on resize
    window.addEventListener('resize', () => {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`);
    });
  }

  /**
   * * DETECT DEVICE OS *
   * Todo: to detect OS of user device
   */
  detectOs(){
    const userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod']

    // platform for mac
    if (macosPlatforms.indexOf(platform) !== -1)this.mainService.currentOs = "mac";  
    // platform for ios
    else if (iosPlatforms.indexOf(platform) !== -1)this.mainService.currentOs = "ios";
    // platform for window
    else if (windowsPlatforms.indexOf(platform) !== -1)this.mainService.currentOs = "window";
    // platform for andriod
    else if (/Android/.test(userAgent))this.mainService.currentOs = "android"; 
    // platform for linuk
    else if (/Linux/.test(platform))this.mainService.currentOs = "linux";  
  }

  /**
   * * GET USER DATA *
   * Todo: to get user data
   */
  getUserData() {
    if(this.mainService.token) {
      if(this.mainService.isServer) {
        this.mainService.getUserProfileServerSide().subscribe({
          next: (res: any) => {
            let resultInfo = res?.data?.result[0];
            if (resultInfo?.is_email_verified) {
              this.mainService.userInfo = resultInfo;
              this._transferState.set(userData, resultInfo);
            }
          }
        });
      }
      else if(this.mainService.isBrowser){
        this.mainService.userInfo = this._transferState.get(userData, null);
      }
    }
  }

  runChatWoot() {
    const baseURL = 'https://chat.gothru.co';
    this.mainService.loadScripts([ baseURL +'/packs/js/sdk.js' ]).then(() => {
      window['chatwootSDK'].run({
        websiteToken: '73SMpC6AuFrWsoBCPdYHdqPs',
        baseUrl: baseURL
      })
    })
  }

  /**
   * * DETECT PENDING PAYMENT *
   * Todo: to get user data
   */
  detectPendingPayment() {
    if(this.mainService.token){
      const pendingPayment = this.mainService.userInfo?.billing_addresses?.status == 'pending' ? true : false;
      if(pendingPayment) {
        if (this.mainService.intervalPendingPayment != null) return;
        
        setTimeout(() => {
          if (this.detectFirstLogin()) {
            this.mainService.productDetail = this.mainService.userInfo.billing_addresses.product_detail;
            this.mainService.urlInvoice = this.mainService.userInfo.billing_addresses.pdf;
            
            if (!this.mainService.urlInvoice.includes('https://app-qa.villume.com/invoice/')) {
              this.mainService.urlInvoice = 'https://app-qa.villume.com/invoice/' + this.mainService.urlInvoice;
            }
            this.mainService.showPendingPayment = true;
          }
          document.cookie = "first_pending_payment=pass;";
        }, 5000);
      }

      this.mainService.startInterval();
    }
  }

  /**
   * * DETECT FIRST MODAL PENDING PAYMENT *
   * Todo: to detect first login
   * @returns : boolean
   */
  detectFirstLogin(): boolean {
    const signIn = this.cookieService.get('first_pending_payment') != 'pass' ? true : false;
    if (this.mainService.token && signIn) return true;
    else return false;
  }

  /**
   * * DETECT DOMAIN WHITE LABEL *
   * Todo: to detect domain white label
   */
  private _detectDomainWhiteLabel() {
    if (this.mainService.isBrowser) {
      this._whiteLabelService.whiteLabelDomain = window.location.origin.replace(/^https?:\/\//, '');
      if (
        this._whiteLabelService.whiteLabelDomain === 'villume.com' ||
        this._whiteLabelService.whiteLabelDomain === 'dev.villume.com' ||
        this._whiteLabelService.whiteLabelDomain === 'qa.villume.com' ||
        this._whiteLabelService.whiteLabelDomain === 'localhost:4200'
      ) {
        this._whiteLabelService.whiteLabel = false;
      } else {
        this._whiteLabelService.whiteLabel = true;
      }
    }
  }

  //  change favicon to blank
  private _removeFavicon() {
    if (this.mainService.isBrowser) {
      const link = document.querySelector('link[rel*=\'icon\'][type=\'image/x-icon\']') || document.createElement('link');
      link.setAttribute('type', 'image/x-icon');
      link.setAttribute('rel', 'icon');
      link.setAttribute('href', this._whiteLabelService.whiteLabel ? `${environment.staticAssets}images/other/favicon-blank.png?t=${this.mainService.appVersion}` : `${environment.staticAssets}images/other/favicon.ico?t=${this.mainService.appVersion}`);
      document.head.appendChild(link);
    }
  }
}