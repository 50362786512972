// Common Angular modules/components
import { Component, ViewEncapsulation, HostListener, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

// User-defined services
import { EditorService } from './editor.service';
import { MainService, UtilsService } from "src/app/shared/services";
import { AlertMessageService } from 'src/app/components/alert-message/alert-message.service';
import { ImagesService } from './images/images.service';
import { ObjectsService } from './objects/objects.service';

// Third party plugins (NPM)
import * as _ from 'lodash';
import moment from 'moment';
import 'moment-timezone';

import { environment } from '@environments';

// Third party plugins (CDN)
declare const BABYLON: any;
declare const bowser: any;
declare const introJs: any;

// Interfaces
import { Artwork, OrdinaryObject, TextWall, Tips, TipsProgress } from 'src/app/shared/interfaces';

// External data
import { messages, tips } from '@data';
import { LoadingGalleryService } from 'src/app/components/loading-gallery/loading-gallery.service';
import { UndoRedoService } from 'src/app/shared/services/undo-redo.service';
import { SplashScreenService } from './publish/splash-screen/splash-screen.service';
import { SplashScreen } from '@interfaces';
import { HelpComponent } from '../../../components/help/help.component';
import { NotSubsDialogComponent } from '../../../components/not-subs-dialog/not-subs-dialog.component';
import { DialogModule } from 'primeng/dialog';
import { LoadingGalleryComponent } from '../../../components/loading-gallery/loading-gallery.component';
import { PublishComponent } from './publish/publish.component';
import { TextComponent } from './text/text.component';
import { ImagesComponent } from './images/images.component';
import { ObjectsComponent } from './objects/objects.component';
import { GeneralComponent } from './general/general.component';
import { FormsModule } from '@angular/forms';
import { NgIf, NgStyle } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';
import { LayoutsComponent } from '../../../layouts/layouts.component';
import { TextLoaderService } from './text/service/text-loader.service';

@Component({
    selector: 'app-editor',
    templateUrl: './editor.component.html',
    styleUrls: ['./editor.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
      LayoutsComponent,
      TooltipModule,
      NgIf,
      FormsModule,
      GeneralComponent,
      ObjectsComponent,
      ImagesComponent,
      TextComponent,
      PublishComponent,
      NgStyle,
      LoadingGalleryComponent,
      DialogModule,
      NotSubsDialogComponent,
      HelpComponent
    ]
})
export class EditorComponent implements AfterViewInit {
  public roomHasLoaded: boolean = false;
  public published:boolean = false;
  public onPublish:boolean = false;
  public onUpdate:boolean = false;
  public onLoadingInterval:any;
  public showHelp: boolean = false;
  private _scriptPaths: string[] = [
    environment.staticAssets+'plugins/bowser/bowser.js?t='+this.mainService.appVersion,
    environment.staticAssets+'plugins/babylonjs/babylonjs-editor.js?t='+this.mainService.appVersion,
    environment.staticAssets+'plugins/introjs/intro.min.js?t='+this.mainService.appVersion,
  ]

  // Handle close tab
  @HostListener('window:beforeunload', ['$event'])
  handleClose($event) {
    if(this.editorService.dataHasChanges){
      $event.returnValue = true;
    }
  }

  constructor(
    public editorService: EditorService,
    public mainService: MainService,
    public route: ActivatedRoute,
    public undoRedoService: UndoRedoService,
    public loadingGalleryService: LoadingGalleryService,
    private _messageService: AlertMessageService,
    private _objectsService: ObjectsService,
    private _artworkService: ImagesService,
    private _utilsService: UtilsService,
    private _cd: ChangeDetectorRef,
    private _titleService: Title,
    private _router: Router,
    private _splashScreenService: SplashScreenService,
    private _textLoader: TextLoaderService
  ) {
    this.mainService.layoutType = 'editor';
    this._titleService.setTitle('Villume | Editor');
  }

  ngAfterViewInit(){
    this.mainService.loadScripts(this._scriptPaths).then(() => {
      this._enableBabylonCaching();
      BABYLON.DracoCompression.Configuration = {
        decoder: {
          wasmUrl: environment.staticAssets+"plugins/draco/draco_wasm_wrapper_gltf.js",
          wasmBinaryUrl: environment.staticAssets+"plugins/draco/draco_decoder_gltf.wasm",
          fallbackUrl: environment.staticAssets+"plugins/draco/draco_decoder_gltf.js",
        },
      };
      
      this.editorService.browserUsed = bowser.getParser(window.navigator.userAgent);
      this._initializeBabylonCoreObjects();
      this._fetchExhibitions();
    });

    if (this.mainService.isBrowser) document.getElementsByTagName("body")[0].style.overflowY = 'hidden';
  }

  /**
   * * ============================================================================ *
   *   SECTION Get Artwork Data Functions
   * * ============================================================================ *
   */

  //#region 

  /**
   * * GET ARTWORK DATA FROM EXHIBITION *
   * ANCHOR Get Artwork Data From Exhibition
   * @description to getting artwork data from exhibition
   * @param exhibition: Exhibition
   */
  private async _getArtworksData(exhibition): Promise<void> {
    this.editorService.artworks = await Promise.all(exhibition.figures.map(async (artwork: Artwork) =>{
      artwork.image = this._artworkService.getArtworkImage(artwork);
      artwork.frame.frame.frame_texture = artwork.frame.frame.frame_texture || null;
      artwork.frame.passepartout.passepartout_texture = artwork.frame.passepartout.passepartout_texture || null;
      artwork.audio = this._getArtworkAudio(artwork);
      artwork.video_stream = await this._getVideoStream(artwork);
      artwork.model_path = this._artworkService.getModelPath(artwork);
      if (artwork.frame.frame.frame_texture) {
        artwork.frame.frame.frame_texture_url = this.mainService.convertPathImage(artwork.frame.frame.frame_texture) || null;
      }
      if (artwork.frame.passepartout.passepartout_texture) {
        artwork.frame.passepartout.passepartout_texture_url = this.mainService.convertPathImage(artwork.frame.passepartout.passepartout_texture) || null;
      }

      if (artwork.frame.frame.frame_width) {
        artwork.frame.frame.frame_width_bottom = artwork.frame.frame.frame_width;
        artwork.frame.frame.frame_width_top = artwork.frame.frame.frame_width;
        artwork.frame.frame.frame_width_left = artwork.frame.frame.frame_width;
        artwork.frame.frame.frame_width_right = artwork.frame.frame.frame_width;

        delete artwork.frame.frame.frame_width;
      }

      if (artwork.frame.passepartout.passepartout_width) {
        artwork.frame.passepartout.passepartout_width_top = artwork.frame.passepartout.passepartout_width;
        artwork.frame.passepartout.passepartout_width_bottom = artwork.frame.passepartout.passepartout_width;
        artwork.frame.passepartout.passepartout_width_left = artwork.frame.passepartout.passepartout_width;
        artwork.frame.passepartout.passepartout_width_right = artwork.frame.passepartout.passepartout_width;

        delete artwork.frame.passepartout.passepartout_width;
      }

      return artwork;
    }));

    delete exhibition.figures;
  }

  /**
   * * GET ARTWORK AUDIO *
   * ANCHOR Get Artwork Audio
   * @description to getting artwork audio
   * @param artwork : Artwork
   * @returns : string
   */
  private _getArtworkAudio(artwork: Artwork): string {
    let audio = artwork.audio;
    if(audio && !audio.includes(environment.assets_path)){
      audio = environment.assets_path + audio;
    }
    return audio;
  }

  /**
   * * GET VIDEO STREAM *
   * ANCHOR Get Video Stream
   * @description to getting video stream
   * @param artwork : Artwork
   * @returns : Promise<string>
   */
  private _getVideoStream(artwork: Artwork): Promise<string> {
    return new Promise((resolve, reject) => {
      const url = artwork.video;
      if (!url) resolve(null);
      const type = this._artworkService.getVideoType(url);
      this._artworkService.getVideoData(url, type).subscribe((res: any) => {
        const videoStream = res.data.videos.find((video: any) => video.fps <= 30).stream;
        resolve(videoStream);
      }, err => {
        reject(err);
      })
    })
  }

  //#endregion
  //!SECTION

   /**
   * * ============================================================================ *
   *   SECTION Load Exhibition Assets Functions
   * * ============================================================================ *
   */

  //#region 

  /**
   * * LOAD EXHIBITION ASSETS *
   * ANCHOR Load Exhibition Assets
   * @description to load exhibition assets such as artworks, ordinary objects and text-walls
   */
  private async _loadAllAssets(): Promise<void> {
    await this._loadAllArtwork();
    await this._loadAllText();

    const ordinaryObject = this.editorService.ordinaryObjects.filter((x: OrdinaryObject) => !x.deleted);
    if(ordinaryObject.length > 0) {
      this._loadAllOrdinaryObject();
    }else{
      this.editorService.allAssetsHasLoaded = true;
      this.loadingGalleryService.backgroundType = 'none';
      this.loadingGalleryService.label = ``;
      this.loadingGalleryService.show = false;
      this._utilsService.removeUsedMaterials(this.editorService.scene);
      this.editorService.updateLogActivity(`All assets has been loaded`);
    }
  }

  /**
	 * * LOAD ALL ORDINARY OBJECT *
   * ANCHOR Load All Ordinary Object
   * @description to load all ordinary object
	 */
	private _loadAllOrdinaryObject(): void {
    let index = 0;
    const objects = this.editorService.ordinaryObjects.filter((x: OrdinaryObject) => !x.deleted);
    this.loadingGalleryService.label = `Loading "3D-Objects" ... (${index}/${objects.length})`;
    const loadOrdinaryObject = () => {
      this._objectsService.loadOrdinaryObject(objects[index], { 
        onLoaded: () => {
          index++;
          if(objects[index]){
            loadOrdinaryObject();
            this.loadingGalleryService.label = `Loading "3D-Objects"... (${index}/${objects.length})`;
          }else{
            this.editorService.allAssetsHasLoaded = true;
            this.loadingGalleryService.backgroundType = 'none';
            this.loadingGalleryService.label = ``;
            this.loadingGalleryService.show = false;
            this._utilsService.removeUsedMaterials(this.editorService.scene);
          }
        },
        onError: (err) => {
          index++;
          if(objects[index]){
            loadOrdinaryObject();
            this.loadingGalleryService.label = `Loading "3D-Objects"... (${index}/${objects.length})`;
          }else{
            this.editorService.allAssetsHasLoaded = true;
            this.loadingGalleryService.backgroundType = 'none';
            this.loadingGalleryService.label = ``;
            this.loadingGalleryService.show = false;
            this._utilsService.removeUsedMaterials(this.editorService.scene);
          }
        }
      });
    }
    
    loadOrdinaryObject();
	}

  /**
	 * * LOAD ALL TEXT *
   * ANCHOR Load All Text
   * @description to load all text mesh to scene
	 */
	private async _loadAllText(): Promise<void> {
    const isFirefox = this.editorService.browserUsed.getBrowserName() === 'Firefox';
    if(isFirefox) this._textLoader.textWallQuality = 1;
    const texts = this._textLoader.texts.filter((text: TextWall) => !text.deleted);
    if(texts.length > 0) {
      let loadedText = 0;
      this.loadingGalleryService.label = `Loading "Text Wall" ... (${loadedText}/${texts.length})`;
      texts.map((text: TextWall)=>{
        this._textLoader.loadText(text, this.editorService.scene);
        loadedText++;
        this.loadingGalleryService.label = `Loading "Text Wall" ... (${loadedText}/${texts.length})`;
      });
    }
	}

  // SECTION Load All Artwork Functions
  //#region 

  /**
	 * * LOAD ALL ARTWORKS *
	 * Todo: for loading all artwork
	 */
	private async _loadAllArtwork(): Promise<void> {
		const artworksImageVideo = this._getArtworkImageAndVideos();
    let loaded = 0;
    if (artworksImageVideo.length > 0) {
      this.loadingGalleryService.label = `Loading "Artwork Image/Video" ... (${loaded}/${artworksImageVideo.length})`;
      for(let i = 0; i < artworksImageVideo.length; i++){
        const artwork = artworksImageVideo[i];
        const artworkNode = await this.editorService.createArtworkImageVideo(artwork);
        this._createShadowAndDonut(artworkNode);
        this._repositionArtwork(artworkNode, artwork);
        this.editorService.markPositionPlaceholderIsUsedOrNot(artworkNode);
        loaded++;
        this.loadingGalleryService.label = `Loading "Artwork Image/Video" ... (${loaded}/${artworksImageVideo.length})`;
      }
    }

    this.editorService.markAsUnusedIfRelatedArtworkNotExists();

    const artworksObject = this._getArtworkObjects();;
    if(artworksObject.length > 0) {
      loaded = 0;
      this.loadingGalleryService.label = `Loading "Artwork 3D-Object" ... (${loaded}/${artworksObject.length})`;
      await Promise.all(artworksObject.map(async (artwork: Artwork) => {
        const artworkNode = await this.editorService.loadArtworkObject(artwork);
        artworkNode['shadowHasInitialized'] = true;
        artworkNode['donutHasInitialized'] = true;
        loaded++;
        this.loadingGalleryService.label = `Loading "Artwork 3D-Object" ... (${loaded}/${artworksObject.length})`;
      }))
    }
	}

  /**
   * * GET ARTWORK IMAGE AND ARTWORK VIDEO *
   * ANCHOR Get Artwork Image And Artwork Video
   * @description to getting artwork image and artwork video data
   * @returns 
   */
  private _getArtworkImageAndVideos(): Artwork[] {
    const artworks = this.editorService.artworks.filter((artwork: Artwork) => !artwork.deleted)
    return artworks.filter((artwork: Artwork) => {
      return ['figure-image','video'].includes(artwork.file_type);
    });
  }

  /**
   * * GET ARTWORK OBJECTS *
   * ANCHOR Get Artwork Objects
   * @description to getting artwork objects data
   * @returns : Artwork[]
   */
  private _getArtworkObjects(): Artwork[] {
    const artworks = this.editorService.artworks.filter((artwork: Artwork) => !artwork.deleted)
    return artworks.filter((artwork: Artwork) => {
      return artwork.file_type == 'figure-object';
    });
  }

  /**
   * * CREATE SHADOW AND DONUT *
   * ANCHOR Create Shadow And Donut
   * @description to create shadow and donut for artwork
   * @param artworkNode : BABYLON.TransformNode
   */
  private _createShadowAndDonut(artworkNode: any): void {
    this.editorService.createShadowCast(artworkNode);
    this.editorService.createShadowComponents(artworkNode);
    this.editorService.createArtworkDonut(artworkNode);
  }

  /**
   * * REPOSITION ARTWORK *
   * ANCHOR Reposition Artwork
   * @description to reposition artwork
   * @param artworkNode: BABYLON.TransformNode
   * @param artwork : Artwork
   */
  private _repositionArtwork(artworkNode: any, artwork: Artwork): void {
    if(!artwork.old_frame.is_null) {
      this.editorService.correctingArtworkPosition(artworkNode, artwork.old_frame);
      this.editorService.updateArtworkData(artworkNode, artwork, false)
    };
  }

  //#endregion
  //!SECTION

  //#endregion
  //!SECTION

  /**
   * * ============================================================================ *
   *   SECTION Intoduction Tooltip Functions
   * * ============================================================================ *
   */

  //#region 
  private tipsProgress: TipsProgress[] = [];

  /**
   * ANCHOR Get Tips Progress
   * @description to getting tips progress
   */
  private _getTipsProgress(): void {
    try {
      this.tipsProgress = JSON.parse(localStorage['tipsProgress']);
    } catch (error) {
      this.tipsProgress = [
        { feature: 'Feature 1', viewed: false },
        { feature: 'Feature 2', viewed: false },
        { feature: 'Feature 3', viewed: false },
        { feature: 'Feature 4', viewed: false },
        { feature: 'Feature 5', viewed: false },
        { feature: 'Feature 6', viewed: false },
      ];
      localStorage['tipsProgress'] = JSON.stringify(this.tipsProgress);
    }
  }

  /**
   * ANCHOR Get Unviewed Tips
   * @description to getting unviewed tips
   * @returns : Tips[]
   */
  private _getUnviewdTips(): Tips[] {
    this._getTipsProgress();
    const filteredTips = tips.filter((tip: Tips) => {
      const unviewedStep = this.tipsProgress.find((x: TipsProgress) => x.feature === tip.featureName && x.viewed === false);
      if (unviewedStep) return true;
      return false;
    });

    return filteredTips;
  }

  /**
   * ANCHOR Init IntroJS
   * @description to init introJS
   * @param tips : Tips[] -> Array of tips
   * @returns : any -> introJS instance
   */
  private _initIntroJS(tips: Tips[]): any {
    const introJS = introJs();
    introJS.setOptions({
      exitOnOverlayClick: false,
      keyboardNavigation: false,
      disableInteraction: true,
      showBullets: false,
      nextLabel: 'Next<i class="vi vi-arrow-right"></i>',
      prevLabel: 'Skip All',
      doneLabel: 'Okay!',
      steps: tips.map((x: Tips) => {
        x.element = document.querySelector(x.selector);
        return x;
      })
    });

    return introJS;
  }

  /**
   * ANCHOR Handle On Change IntroJS
   * @description to handle on change introJS
   * @param introJS : any -> introJS instance
   * @param tips : Tips[] -> Array of tips
   */
  private _handleOnChangeIntroJS(introJS: any, tips: Tips[]): void {
    introJS.onchange((el: HTMLElement) => {
      const feature = el.dataset['tipsFeature'];
      const idx = tips.findIndex((x: Tips) => x.featureName === feature);
      const prevTips = tips[idx-1];
      if (prevTips) this._maskAsViewedTips(prevTips.featureName);

      // hide button skip all on last tips
      if (idx === tips.length - 1) {
        setTimeout(() => {
          const skipButton = document.querySelector('.introjs-prevbutton') as HTMLElement;
          const skipButtonLayer = document.querySelector('.introjs-skipbutton') as HTMLElement;
          skipButton.classList.add('d-none');
          skipButtonLayer.classList.add('d-none');
        }, 1);
      }
    });
  }

  /**
   * ANCHOR Handle On Complete IntroJS
   * @description to handle on complete introJS
   * @param introJS : any -> introJS instance
   * @param tips : Tips[] -> Array of tips
   */
  private _handleOnConpleteIntroJS(introJS: any, tips: Tips[]): void {
    introJS.oncomplete(() => {
      const lastTips = tips[tips.length-1];
      this._maskAsViewedTips(lastTips.featureName);
    });
  }

  /**
   * ANCHOR Handle On Exit IntroJS
   * @description to handle on exit introJS
   * @param introJS : any -> introJS instance
   * @param tips : Tips[] -> Array of tips
   */
  private _handleOnExitIntroJS(introJS: any, tips: Tips[]): void {
    introJS.onexit(() => {
      tips.forEach((tips: Tips) => {
        this._maskAsViewedTips(tips.featureName);
      });
    });
  }

  /**
   * ANCHOR Init Tooltips Intoduction
   * @description to init tooltips intoduction
   */
  private _initTooltipsIntoduction(): void {    
    const filteredTips = this._getUnviewdTips();
    const introJS = this._initIntroJS(filteredTips);

    this._handleOnChangeIntroJS(introJS, filteredTips);
    this._handleOnConpleteIntroJS(introJS, filteredTips);
    this._handleOnExitIntroJS(introJS, filteredTips);
  
    introJS.start();
  }

  /**
   * ANCHOR Mask As Viewed Tips
   * @description to mask as viewed tips
   * @param featureName : string -> feature name
   */
  private _maskAsViewedTips(featureName: string): void {
    const tipsProgress = this.tipsProgress.find((tip: TipsProgress) => tip.feature == featureName);
    if (tipsProgress) tipsProgress.viewed = true;
    localStorage['tipsProgress'] = JSON.stringify(this.tipsProgress);
  }

  //#endregion
  //!SECTION

  /**
   * * ============================================================================ *
   *   SECTION Uncategorized Functions
   * * ============================================================================ *
   */

  //#region

  /**
   * * ENABLE BABYLON CACHING *
   * ANCHOR Enable Babylon Caching
   * @description to enable babylon caching
   */
  private _enableBabylonCaching(): void {
    BABYLON.Database.IDBStorageEnabled = true;
    BABYLON.SceneLoader.ShowLoadingScreen = false;
  }

  /**
   * ANCHOR Set Default Timezone
   * @description to set default timezone
   */
  private _setDefaultTimezone(): void {
    const timezone = this.editorService.exhibition.timezone || this.mainService.userInfo.user_details[0].timezone || "Europe/London";
    moment.tz.setDefault(timezone);
  }

  /**
   * ANCHOR Recommended Browser Alert
   * @description to show recommended browser alert
   */
  private _recommendedBrowserAlert(): void {
    if (this.editorService.browserUsed.getBrowserName() != 'Chrome') {
      this._messageService.add({
        severity: 'suggest', 
        summary: '',
        detail: messages.editor.global.recommendBrowser, 
      })
    }
  }

  /**
   * ANCHOR Initialize Babylon Core Objects
   * @description to initialize babylon core objects
   */
  private _initializeBabylonCoreObjects(): void {
    this.editorService.canvas = document.querySelector("#editorCanvas");
    this.editorService.canvas.focus();
    this.editorService.engine = this.editorService.initEngine(this.editorService.canvas);
    this.editorService.scene = this.editorService.createScene(this.editorService.engine);
  }

  /**
   * ANCHOR Rename Exhibition
   * @description to rename exhibition
   */
  public renameExhibition(): void {
    this.editorService.dataHasChanges = true;
    this.editorService.updateLogActivity('Rename exhibition');
    this.editorService.updateUndoRedoStateWithDelay();
    this._cd.detectChanges();
  }

  /**
   * ANCHOR Open Editor Section
   * @description to open editor section
   * @param name 
   */
  public openEditorSection(name): void {
    if(!this.loadingGalleryService.show){
      if(
        this.editorService.artworkDataValid&&
        this._textLoader.textDataValid&&
        !this.editorService.blockUserAction&&
        this.editorService.publishDataValid&&
        !this.editorService.onPlacingArtwork&&
        this.editorService.ordinaryObjectDataValid&&
        !this.editorService.previewMode
      ){
        if(!this.editorService.editFrameMode){
          // unselect object
          if(
            this.editorService.activeArtworkNode ||
            this._textLoader.activeTextNode ||
            this.editorService.activeOrdinaryObjectNode ||
            this.editorService.overlapingTextWalls.length > 0
          ) {
            if(this.editorService.selectedExhibitAssets.length > 1) {
              this.editorService.unselectMultiExhibitAssets();
            }else{
              this.editorService.unselectExhibitAsset();
            }
          }
          this.editorService.showAlignLimit = false;
          this.editorService.bottomLimit.visibility = this.editorService.topLimit.visibility = 0;
          this.editorService.activeTab = name;
        }

        setTimeout(() => {
          this.undoRedoService.removeUndoRedoInputEvent();
        }, 500)
      }else{
        if(!this.editorService.artworkDataValid){
          this._messageService.add({severity:"warn",summary:"Warning", detail:"The Artworks tab contains invalid data. Please update the data."})
        }
        if(!this._textLoader.textDataValid){
          this._messageService.add({severity:"warn",summary:"Warning", detail:"The Text tab contains invalid data. Please update the data."})
        }
        if(!this.editorService.publishDataValid){
          this._messageService.add({severity:"warn", summary: "Warning", detail: "The Publish tab contains invalid data. Please update the data."})
        }
        if(!this.editorService.ordinaryObjectDataValid){
          this._messageService.add({severity:"warn", summary: "Warning", detail: "The Objects tab contains invalid data. Please update the data."})
        }
        if(this.editorService.onPlacingArtwork){
          this._messageService.add({severity:"warn", summary: "Warning", detail: "Please place the artwork onto the wall before switching to other tabs"})
        }
      }
    }
  }

  /**
   * ANCHOR Get Width Display
   * @description to get width display
   * @param device : string
   * @param width : number
   */
  public getWidthDisplay(device: string, width: number = 809): void {
    this.editorService.widthDisplay = width;
    this.editorService.showOverlayDisplay = true;
    this.editorService.deviceScreen = device;
    if (device == 'desktop') this.editorService.showOverlayDisplay = false;

    switch (this.editorService.deviceScreen) {
      case 'desktop':
        this.editorService.mainCameraNode.fov = this.editorService.exhibition.desktop_fov;
        break;
      case 'tablet':
        this.editorService.mainCameraNode.fov = this.editorService.exhibition.tablet_fov;
        break;
      case 'mobile':
        this.editorService.mainCameraNode.fov = this.editorService.exhibition.mobile_fov;
        break;
    }
  }

  //#endregion
  //!SECTION

  /**
   * * ============================================================================ *
   * * RENDER SCENE FUNCTIONS
   * * ============================================================================ *
   */

  //#region 

  /**
   * * RENDER EXHIBITIONS SCENE *
   * Todo : to render an active exhibition scene
   */
  private _renderExhibitonScene(): void {
    this.editorService.setupCamera();
    this.editorService.setupBasicLigting();

    this._handleTabShift();
    this.editorService.loadExhibition().then(async ()=>{
      this.editorService.canvas.focus();
      this.roomHasLoaded = true;
      this.loadingGalleryService.backgroundType = 'overlay';
      this.loadingGalleryService.percent = 0;
      this.editorService.blockUserAction = false;
      
      setTimeout(() => { this._initTooltipsIntoduction() }, 100);

      this.editorService.createPointerMesh();
      this.editorService.createAlignLimit();
      this.editorService.applyCameraGravityAndControl();
      this.editorService.registerMainKeyboardEvent();
      this.editorService.initMainPointerObs()
      this.editorService.initGizmos();
      
      this.editorService.mainCameraNode.onViewMatrixChangedObservable.add(()=>{
        this.editorService.cameraOutOfRoomHandler();
        this.editorService.detectCameraAboveStairs();
      })

      await this._loadAllAssets();

      this.editorService.updateUndoRedoState(true);
      this.editorService.registerUndoRedoKeyboardEvent();
      this.undoRedoService.removeUndoRedoInputEvent();

      // setTimeout(() => {
      //   console.log('Start: Collecting FPS Data...')
      //   this._getFPSData();
      // }, 20000)
    })

    this.editorService.renderScene();
    this.editorService.handleWebGLLostContext();
    this.editorService.handleFPSDown();
    // this.editorService.scene.debugLayer.show(
      // {showExplorer: false,}
    // );

    setTimeout(() => window.dispatchEvent(new Event('resize')), 1000)
  }

  private _getFPSData(): void {
    const fpsRecords = [];
    let currentInterval = 0;
    const oneMinutes = 60000;
    const readFps = setInterval(() => {
      if(currentInterval >= oneMinutes){
        clearInterval(readFps);
        const frequency: number[] = fpsRecords.reduce((acc, val) => {
          acc[val] = (acc[val] || 0) + 1;
          return acc;
        }, {});
        const maxFrequency = Math.max(...Object.values(frequency));
        const mode = Object.keys(frequency).filter(key => frequency[key] === maxFrequency).map(Number);
        const avg = fpsRecords.reduce((a, b) => a + b) / fpsRecords.length;
        const min = Math.min(...fpsRecords);
        const max = Math.max(...fpsRecords);

        console.log('Avg FPS: ', Math.round(avg));
        console.log('Min FPS: ', Math.round(min));
        console.log('Max FPS: ', Math.round(max));
        console.log('Mode FPS: ', Math.round(mode[0]));
        return;
      }
      const fps = this.editorService.engine.getFps();
      fpsRecords.push(fps);
      currentInterval += 500;
    },500)
  }

  /**
   * * HANDLE TAB SHIFT ACTION *
   * Todo: to handle tab shift action
   */
  private _handleTabShift(){
    document.addEventListener("visibilitychange", () => {
      // If the tab is active
      if (!document.hidden){
        if(!this.loadingGalleryService.show){
          if(
            !this.editorService.allDonutArtworkHasInitialized || 
            !this.editorService.allShadowArtworkHasInitialized
          ){
            this.editorService.artworkNodes.map((artworkNode: any)=>{
              if(!artworkNode['donutHasInitialized']) this.editorService.createArtworkDonut(artworkNode);
            })
          }
        }
      }
    });
  }

  //#endregion

  /**
   * * ============================================================================ *
   * * "BLOKING USER ACTION" CHECKER FUNCTIONS
   * * ============================================================================ *
   */

  //#region

  /**
   * * IS SAVE CHANGES DISABLE *
   * Todo: to check if save changes button is disabled or not
   * @returns : boolean
   */
  public isSaveChangesDisable(): boolean {
    return (
      this.editorService.onSavingData ||
      !this.editorService.dataHasChanges ||
      this.editorService.blockUserAction || 
      !this.editorService.exhibitionDataValid ||
      this.editorService.previewMode
    )
  }

  /**
   * * IS PUBLISH DISABLE *
   * Todo: to check if publish button is disabled or not
   * @returns : boolean
   */
  public isPublishDisable(): boolean {
    return (
      this.loadingGalleryService.show ||
      this.onPublish ||
      this.editorService.blockUserAction ||
      !this.editorService.exhibitionDataValid ||
      this.editorService.previewMode ||
      this.editorService.previewAutoPlacedArtworks ||
      this.undoRedoService.onUndo ||
      this.undoRedoService.onRedo
    )
  }

  /**
   * * IS PREVIEW DISABLE *
   * Todo: to check if preview button is disabled or not
   * @returns : boolean
   */
  public isPreviewDisable(): boolean {
    return (
      this.editorService.focusAnimationIsRun || 
      this.loadingGalleryService.show || 
      this.editorService.onPlacingArtwork || 
      this.onPublish ||
      this.editorService.previewAutoPlacedArtworks ||
      this.undoRedoService.onUndo ||
      this.undoRedoService.onRedo
    )
  }

  /**
   * * IS UNDO DISABLE *
   * Todo: to check if undo button is disabled or not
   * @returns : boolean
   */
  public isUndoDisable(): boolean {
    return (
      !this.undoRedoService.isEnabled('undo') || 
      this.editorService.blockUserAction ||
      this.editorService.previewMode ||
      this.loadingGalleryService.show ||
      this.editorService.onPlacingArtwork
    )
  }

  /**
   * * IS REDO DISABLE *
   * Todo: to check if redo button is disabled or not
   * @returns : boolean 
   */
  public isRedoDisable(): boolean {
    return (
      !this.undoRedoService.isEnabled('redo') || 
      this.editorService.blockUserAction ||
      this.editorService.previewMode ||
      this.loadingGalleryService.show ||
      this.editorService.onPlacingArtwork
    )
  }

  /**
   * * IS DELETE DISABLE *
   * Todo: to check if delete button is disabled or not
   * @returns : boolean
   */
  public isDeleteDisable(): boolean {
    return (
      this.editorService.focusAnimationIsRun ||
      this.loadingGalleryService.show ||
      this.editorService.blockUserAction ||
      this.editorService.previewMode
    )
  }

  /**
   * * IS RENAME EXHIBITION DISABLE *
   * Todo: to check if rename exhibition button is disabled or not
   * @returns : boolean
   */
  public isRenameExhibitionDisable(): boolean {
    return (
      this.loadingGalleryService.show ||
      this.editorService.onSavingData ||
      this.editorService.previewMode
    )
  }

  /**
   * ANCHOR Is Help Disable
   * Todo: to check if help button is disabled or not
   * @returns : boolean
   */
  public isHelpDisable(): boolean {
    return (
      this.loadingGalleryService.show ||
      this.editorService.blockUserAction
    )
  }

  //#endregion

  /**
   * * ============================================================================ *
   * * PUBLISH & SAVE DATA FUNCTIONS
   * * ============================================================================ *
  */

  //#region 

  /**
   * * SAVE ALL CHANGES *
   * Todo: to save all changes to exhibition-related data such as cameras,artworks,text wall and object also exhibition itself
   */
  public async saveAllChanges(): Promise<void> {
    if(this.editorService.exhibition.name.trim()){
      if(this.editorService.validateExhibitionData(true)) {
        this.editorService.onSavingData = true;
        this.editorService.blockUserAction = true

        this._addPrefixToWebsiteUrl(this.editorService.exhibition.exhibition_link?.website_url);
        await this.editorService.setupTextToSpeech();
        this._splashScreenService.saveSplashScreen(this.editorService.exhibition);

        this._textLoader.regenerateTextWalls(this.editorService.exhibition.id).then(() => {
          this.editorService.saveChanges().subscribe(res=>{
            this.editorService.updateExhibitionViewer().subscribe();
            this.editorService.dataHasChanges = false;
            this.editorService.onSavingData = false;
            this.editorService.blockUserAction = false;
            this.undoRedoService.clear();
  
            this.editorService.updateLogActivity("Save exhibition data")
            this._messageService.add({severity:"success",summary:"Success", detail: "All changes have been saved"})

            this.editorService.exhibition.edited_description = false;
						this.editorService.artworks.map((artwork:any)=>{
							artwork.edited_description = false;
						});
          },err=>{
            this.editorService.onSavingData = false;
            this.editorService.blockUserAction = false;
  
            if(err.error.statusCode==401){
              this.mainService.expiredSesionPopup = true;
            }else{
              this._messageService.add({severity:'error', summary:'Failed', detail:'Something went wrong. Failed to save data changes.'});
            }
          })
        }).catch((err) => {
          this.editorService.onSavingData = false;
            this.editorService.blockUserAction = false;
  
            if(err?.error?.statusCode==401){
              this.mainService.expiredSesionPopup = true;
            }else{
              this._messageService.add({severity:'error', summary:'Failed', detail:'Something went wrong. Failed to save data changes.'});
            }
        });
      }
    } else {
      this._messageService.add({severity:"warn", summary: "Warning", detail: "The exhibition name cannot be empty"})
    }
  }

  /**
   * * PUBLISH EXHIBITION *
   * Todo: publish the exhibit data and all data associated with it
   * @param isUpdate: boolean - to check if the exhibition is updated or not
   */
  public publishExhibition(isUpdate: boolean = false): void {
    if(this.editorService.exhibition.name.trim()){
      if(this.editorService.validatePublishData()) {
        this._addPrefixToWebsiteUrl(this.editorService.exhibition.exhibition_link?.website_url);

        this.editorService.exhibition.unlimited_time = true;
        this.editorService.setNewExhibitionDate(null,"start")
        this.editorService.setNewExhibitionDate(null,"end")

        this.editorService.validateExhibitionDate(); 

        if(this.editorService.publishDataValid){
          if(isUpdate) this.onUpdate = true;
          else this.onPublish = true;
        }
        
        this.editorService.publishExhibition(!this.editorService.exhibition.published,isUpdate).then(()=>{
          this.onUpdate = false;
          this.onPublish = false;
        }).catch(()=>{
          this.onUpdate = false;
          this.onPublish = false;
        })
      }
    } else {
      this._messageService.add({severity:"warn", summary: "Warning", detail: "The exhibition name cannot be empty"})
    }
  }

  //#endregion

  /**
   * * ============================================================================ *
   * * PREVIEW MODE FUNCTIONS
   * * ============================================================================ *
   */

  //#region

  /**
   * * PREVIEW MODE *
   * Todo: to enable/disable preview mode
   */
  public previewMode(): void {
    if(this.editorService.allAssetsHasLoaded){
      if(this.editorService.validateExhibitionData(true)){
        this.openEditorSection('images');
        this.editorService.previewMode = !this.editorService.previewMode;

        if(this.editorService.previewMode){
          this.editorService.blockUserAction = true;
          this._hideAlignLimitMeshes();
          this._unselectActiveNode();
          this._showDonutArwork(true);
          this.editorService.updateLogActivity("Turn on preview mode");
        }
          
        else{
          // Allow user action & close focus artwork
          this.editorService.blockUserAction = false;
          if(this.editorService.activeArtworkId!=null) this.editorService.unfocusFromArtworkAnimation();

          // Hide show artwork, update artwork list for editor mode & update log activity
          this._showDonutArwork(false);
          this.editorService.updateLogActivity("Turn off preview mode");
        }
      }
    }else{
      this._messageService.add({severity: "warn", summary: "Warning", detail: "Please wait until all assets have been loaded"})
    }
  }

  /**
   * * HIDE ALIGN LIMIT MESHES *
   * Todo: to hide align limit meshes
   */
  private _hideAlignLimitMeshes(): void {
    this.editorService.topLimit.visibility = 0;
    this.editorService.bottomLimit.visibility = 0;
  }

   /**
   * * UNSELECT ACTIVE NODE *
   * Todo: to unselect active node
   */
  private _unselectActiveNode(): void {
    if (
      this.editorService.activeArtworkNode ||
      this._textLoader.activeTextNode ||
      this.editorService.activeOrdinaryObjectNode
    ) {
      this.editorService.unselectExhibitAsset();
    }
  }

  /**
   * * SHOW DONUT ARTWORK *
   * Todo: show donut artwork when preview mode
   */
  private _showDonutArwork(show: boolean): void {
    if(this.editorService.exhibition.show_donut){
      this.editorService.artworkDonuts.map((donut:any)=>{
        if(donut['onTheFloor']) donut.setEnabled(show);
      })
    }
  }

  //#endregion

  /**
   * * ============================================================================ *
   * * DELETE EXHIBITION FUNCTIONS
   * * ============================================================================ *
	 */

  //#region

  /**
	 * * DELETE EXIBITION *
	 * Todo: delete the exhibit data and all data associated with it
	 */
  public onDeleting = false;
  public displayDeleteExhibition: boolean = false;
  public deleteExhibition(): void {
    const confirmMessage = messages.editor.global.deleteExhibition;
    this.onDeleting = true;
    this.editorService.deleteExhibition(this.editorService.exhibition.id).subscribe((res)=>{
      this._messageService.add({
        severity: "success", 
        summary: "Success", 
        detail: confirmMessage.success
      });

      setTimeout(()=> {
        this._redirectProfilePage()
      }, 3000);
    },err=>{
      this.onDeleting = false;
      if(err.error.statusCode==401){
        this.mainService.expiredSesionPopup = true;
      }else{
        this._messageService.add({
          severity: "error", 
          summary: "Error", 
          detail: confirmMessage.failed
        });
      }
    })
  }

  /**
   * * REDIRECT PROFILE PAGE *
   * Todo: redirect to profile page
   */
  private _redirectProfilePage(): void {
    this.mainService.layoutType="admin";
    document.getElementsByTagName("body")[0].style.overflowY = "scroll";
    this._router.navigate(['/' + this.mainService.userInfo.username]);
  }

  //#endregion

  /**
   * * ============================================================================ *
   * * FETCH EXHIBITION DATA FUNCTIONS
   * * ============================================================================ *
   */

  //#region

  /**
   * * REDIRECT TO 404 PAGE *
   * Todo: redirect to 404 page
   */
  private _redirectTo404Page(): void {
    this.mainService.layoutType = "admin";
    document.getElementsByTagName("body")[0].style.overflowY = "scroll";
    this._router.navigate(["/404"]);
  }

  /**
   * * GET CAMERA DATA FROM EXHIBITION *
   * Todo: to getting camera data from exhibition
   */
  private _getCameraData(exhibition): void {
    this.editorService.camera = exhibition.camera;
    delete exhibition.camera;
  };

  /**
   * * GET TEXTS DATA FROM EXHIBITION *
   * Todo: to getting texts data from exhibition
   */
  private _getTextsData(exhibition): void {
    this._textLoader.texts = exhibition.text_walls;
    delete exhibition.text_walls;
  }

  /**
   * * GET EXHIBITION DATA FROM RESPONSE *
   * Todo: to getting exhibition data from response
   */
  private _getExhibitionData(response: any): void {
    const exhibition = response.data.exhibition_detail[0];

    // Add prefix path assets 
    exhibition.model_path = environment.exhibition_path + exhibition.model_path.replace(environment.exhibition_path,"");
    exhibition.light_map = environment.exhibition_path + exhibition.light_map.replace(environment.exhibition_path,"");
      
    // Add prifix assets path for exhibition cover image
    if(exhibition.cover_image && !exhibition.cover_image.includes(environment.image_path)){
      exhibition.cover_image = this.mainService.convertPathImage(exhibition.cover_image);
    }

    exhibition.model_type = exhibition.model_type ? exhibition.model_type : "default";

    // Set publish status
    this.published = exhibition.published;
    this.editorService.exhibition = exhibition;

    return exhibition;
  }






  /**
   * * GET ORDINARY OBJECT DATA FROM EXHIBITION *
   * Todo: to getting ordinary object data from exhibition
   */
  private _getOrdinaryObjectData(exhibition): void {
    this.editorService.ordinaryObjects = exhibition.objects.map((object: OrdinaryObject)=>{
      object.model_path = this.mainService.setModelPath(object.model_path);
      object.light_intensity = object.light_intensity ? object.light_intensity : 2; // temporary code
      return object;
    })

    delete exhibition.objects;
  }

  /**
   * * FETCH EXHIBITIONS DATA *
   * Todo : to retrieve data exhibits from the database
   */
  private _fetchExhibitions(): void {
    this.loadingGalleryService.show = true;
    this.loadingGalleryService.label = "Fetching exhibition data...";

    this.editorService.blockUserAction = true;

    const exhibtionId = this.route.snapshot.params.id;
    this._textLoader.updateAllTexts = this.route.snapshot.queryParams.updateAllTexts == 'true'
    this.editorService.getExhibition(exhibtionId).subscribe(async (res: any) => {
      const exhibition = this._getExhibitionData(res);
      await this._getArtworksData(exhibition);
      this._getOrdinaryObjectData(exhibition);
      this._getTextsData(exhibition);
      this._getCameraData(exhibition);

      // get splash screen
      this._splashScreenService.getSplashScreen(exhibtionId)
        .then((res:SplashScreen) => {
          this.editorService.exhibition['enableSplashScreen'] = res.active;
        })
        .catch((err)=>{
          if (err == 401) {
            this.mainService.expiredSesionPopup = true;
          }
          this._messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Failed to get splash screen'
          });
        });

      // create highlight layer
      this.editorService.highlightLayer = this._textLoader.highlightText =
        this._utilsService.createHighlightLayer(this.editorService.scene);

      // Set default timezone
      this._setDefaultTimezone()

      // Check json version
      const shareString = this.editorService.exhibition.share_string;
      this.editorService.checkJsonVersion(shareString).subscribe();

      // Render editor scene
      this._renderExhibitonScene();
    },err =>{
      if(err.name == "TimeoutError"){
        this._fetchExhibitions();
      } else {
        this._messageService.add({
          severity:"error", 
          summary:"System Error", 
          detail: messages.editor.global.loadExhibitionFailed
        })
        setTimeout(()=> this._redirectTo404Page(), 1000);
      }
    });
  }

  //#endregion










  /**
   * * ================================================== *
   * * UNCATEGORIZED FUNCTIONS
   * * ================================================== *
   * * - SET DEFAULT TIMEZONE
   * * - SHOW RECOMMENDED BROWSER ALERT
   * * - INITIALIZE BABYLON CORE OBJECTS
   * * - RENAME EXHIBITION
   * * - OPEN EDITOR SECTION
   * * - GET WITDH DISPLAY
   * * - SHOW RECOMMENDED BROWSER ALERT
   * * ================================================== *
   */

  //#region

  /**
   * * ADD PREFIX TO WEBSITE URL *
   * Todo: to add prefix to website url
   * @example : google.com -> https://google.com
   */
  private _addPrefixToWebsiteUrl(link:string): void {
    let website_url:string;

    if(link) {
      const protocol = link.includes('https://') || link.includes('http://') ? '' : 'https://';
      website_url = protocol + link;
      this.editorService.exhibition.exhibition_link.website_url = website_url;
    }
  }
  
  /**
   * * AUTO FOCUS TO CANVAS *
   * Todo: to auto focus to canvas
   */
  private _autoFocusToCanvas(): void {
    window.addEventListener('keydown', (e) => {
      const keys = [65, 83, 68, 87, 37, 39, 38, 40];
      if (keys.includes(e.keyCode) && !this.editorService.onInput) {
        this.editorService.canvas.focus();
      }
    })
  }

  //#endregion
}
