import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor]
})
export class PaginationComponent implements OnChanges {
  @Input() totalData: number = 0;
  @Input() limit: number = 20;
  @Input() page: number = 1;
	@Input() filter?: string = '';

  @Output() public onPageChange: any = new EventEmitter();
	@Output() public onEndPage: any = new EventEmitter();

  public totalPage: number = 0;
  public pages: number[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    this.totalPage = Math.ceil(this.totalData / this.limit);
    this._generatePages();
		if(
			changes['filter'] == undefined &&
			this.page == this.totalPage
		) this.onEndPage.emit(this.page);
  }

	/**
	 * ANCHOR Change Page
	 * @description: change the page and emit the event
	 * @param page: number
	 */
	public changePage(page: number): void {
		this.onPageChange.emit(page);
		if(page == this.totalPage) this.onEndPage.emit(page);
	}

  /**
   * ANCHOR Generate Pages Data
   * @description Generate the pages data based on the current page and total page
   */
  private _generatePages(): void {
    if(this.totalPage > 4){
			if(this.page == 1 || this.page == 2) {
				this.pages = [1, 2, 3];
			}
			else if(this.page == 3 && this.totalPage == 5){
				for(let i = 0; i < this.totalPage; i++) this.pages[i] = i + 1;
			}
			else if(this.page == 3) {
				this.pages = [1,2,3,4];
			}
			else if(this.page > 3 && this.page < this.totalPage - 2) {
				this.pages = [this.page-1, this.page, this.page+1];
			}
			else if(this.page == this.totalPage - 2) {
				this.pages = [this.totalPage - 3, this.totalPage - 2, this.totalPage - 1, this.totalPage];
			}
			else if(this.page == this.totalPage - 1 || this.page == this.totalPage) {
				this.pages = [this.totalPage - 2, this.totalPage - 1, this.totalPage];
			}
		}else{
			this.pages = [];
			for(let i = 0; i < this.totalPage; i++) this.pages[i] = i + 1;
		}
  }
}
