<ng-container *ngIf="!inline">
    <div class="wrap-input-color">
        <button 
            class="triger-overlay" 
            (click)="toggleColorOverlayPanel($event)"
            [style.background-color]="disabled ? '#979797' : color"
            [disabled]="disabled">
        </button>
        <p-overlayPanel
            (onHide)="onHideColorOverlay()"
            (onShow)="onShowColorOverlay()"
            autoZIndex="true"
            appendTo="body"
            #colorOverlay> 
            <ng-container *ngIf="displayColorOverlay">
                <ng-container 
                    *ngTemplateOutlet="colorpicker">
                </ng-container>
            </ng-container>
        </p-overlayPanel>
    </div>
</ng-container>

<ng-container *ngIf="inline">
    <ng-container *ngTemplateOutlet="colorpicker"></ng-container>
</ng-container>

<!-- Color Picker
=========================== -->
<ng-template #colorpicker>        
    <div class="wrap-color-picker">
        <div class="overlay-disable" *ngIf="disabled"></div>
        <p-colorPicker
            inline="true"
            (onChange)="onChange.emit(color)"
            [(ngModel)]="color"
            [baseZIndex]="200"
            appendTo="body"
            [disabled]="disabled">
        </p-colorPicker>
        <div class="input-panel">    
            <div class="set-color-name">
                <div class="active-color" [style.background-color]="color"></div>
                <span>#</span>
                <input 
                    type="text" 
                    (focus)="this.onFocus.emit()"
                    (blur)="this.onBlur.emit()"
                    [(ngModel)]="colorForInput" 
                    [disabled]="disabled"
                    (change)="changeColorViaInput()" 
                    class="form-control bg-dark">
            </div>
            <div class="line"></div>
            <div class="wrap-recent-color">
                <button
                    [disabled]="disabled||loading"
                    [class.loading-button]="loading"
                    (click)="addToRecentColors()" 
                    class="btn-add-color">
                    <i [class.hide]="loading" class="vi vi-plus-box"></i>
                    <i [class.hide]="!loading" class="vi vi-border-box"></i>
                </button>
                <button 
                    [disabled]="disabled"
                    *ngFor="let color of recentColors"
                    (click)="selectRecentColors(color)"
                    [style.background-color]="color" 
                    class="recent-item">
                </button>
            </div>
        </div>
    </div>
</ng-template>

