import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImagesService } from '../../images.service';
import { AlertMessageService } from 'src/app/components/alert-message/alert-message.service';
import { messages } from '@data';
import { VideoData } from '../../images.interfaces';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NgIf } from '@angular/common';

@Component({
    selector: 'add-video',
    templateUrl: './add-video.component.html',
    styleUrls: ['./add-video.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, ProgressSpinnerModule]
})
export class AddVideoComponent {
  public videoUrl: FormControl = new FormControl('', [Validators.required]);
  public onFetcing: boolean = false;
  private _videoData: VideoData;
  
  @Output() onSubmited = new EventEmitter();
  @Output() onFetch = new EventEmitter();

  constructor(
    private _artworkService: ImagesService,
    private _messageService: AlertMessageService,
  ) { }

   /**
   * ANCHOR Process Video Url
   * @description to process video url
   */
  public processVideoUrl(): void {
    if(this.videoUrl.valid) {
      const url = this._getVideoUrl();
      const type = this._artworkService.getVideoType(url);

      if(type == 'vimeo') return this._invalidMessage();

      this.onFetcing = true;
      this.onFetch.emit(true);
      this._artworkService.getVideoData(url, type).subscribe((res: any) => {
        const thumbnail = this.getVideoThumbnail(res, type);
        const video = res.data.videos.find((video: any) => video.fps <= 30);
        this._videoData = { thumbnail, video };
        this._videoData.rawUrl = url;
        this.onSubmited.emit(this._videoData);
        this.onFetcing = false;
        this.onFetch.emit(false);
      }, err => {
        this.onSubmited.emit(null);
        this.onFetcing = false;
        this.onFetch.emit(false);
        this._invalidMessage();
      })    
    }
  }

   /**
    * ANCHOR Get Video Thumbnail
    * @description to get video thumbnail
    * @param res : any -> response
    * @param type : string -> type
    * @returns : string -> thumbnail
    */
  private getVideoThumbnail(res: any, type: string): any {
    if (type == 'youtube') return res.data.thumbnails.high;
    else return res.data.thumbnails.slice(-1)[0];
  }

  /**
   * ANCHOR Invalid Message
   * @description to show invalid message
   */
  private _invalidMessage(): void {
    this._messageService.add({
      severity:'warn', 
      summary: 'Invalid URL',
      detail: messages.editor.artwork.video.invalidUrl
    });
  }

  /**
   * ANCHOR Get Video Url
   * @description to get video url
   * @returns : string -> url
   */
  private _getVideoUrl(): string {
    const url = this.videoUrl.value;
    return url.split('?')[0] + '?' + url.split('?')[1]?.split('&')[0];
  }
}
