import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/shared/services';
import { SsrCookieService as CookieService } from 'ngx-cookie-service-ssr';
import { LayoutsService } from 'src/app/layouts/layouts.service';
import { ResetPasswordService } from '../reset-password.service';

@Component({
    selector: 'app-reset-token',
    templateUrl: './reset-token.component.html',
    styleUrls: ['./reset-token.component.scss'],
    standalone: true
})
export class ResetTokenComponent implements OnInit {

	private urlResetToken;
	private token;

	constructor(
		private resetPasswordService:ResetPasswordService,
		private activatedRoute:ActivatedRoute,
		private router:Router,
		public mainService:MainService,
		private layoutsService:LayoutsService,
		private cookieService: CookieService
	) {}

	ngOnInit(): void {
		// Get token from url by email
		this.activatedRoute.queryParams.subscribe(params => {
			this.resetPasswordService.code = params['code'];
			this.resetPasswordService.token = params['token'];
			this.urlResetToken = "/resettoken?code="+ params['code'] +"&token="+ params['token'];
		});

		if (this.mainService.isBrowser){
			// Get villume_token from localStorage
			this.token = this.cookieService.get('villume_token')
			if (this.token){
				// Delete villume_token from localStorage
				this.layoutsService.updateToken("").subscribe(resToken =>{
					this.mainService.token = null;
					window.location.href = this.urlResetToken;
				});
			} else {
				this.router.navigate(['reset-password'])
			}
		}
	}
}
