import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InputNumberComponent } from 'src/app/components/input-number/input-number.component';

@Component({
  selector: 'app-font-size',
  standalone: true,
  templateUrl: './font-size.component.html',
  styleUrl: './font-size.component.scss',
  imports: [
    InputNumberComponent
  ],
})
export class FontSizeComponent {

  @Input() fontSize: number;
  @Input() lineHeight: number;

  @Output() fontSizeChange: EventEmitter<any> = new EventEmitter();

  public updateInputValue(value: number, type: string): void {
    switch (type) {
      case 'fontSize':
        this.fontSize = value;
        break;
      case 'lineHeight':
        this.lineHeight = value;
        break;
    }
    this.fontSizeChange.emit({fontSize:this.fontSize, lineHeight:this.lineHeight});
  }

}
