<app-layouts #layouts>
    <div content>
        <div class="wrap-reset-password col16-3 col-iped-4 offset-iped-2 offset16-6 offset-mobile-0">
            <form class="form-group" [formGroup]="formPassword" *ngIf="!passwordChanged">
                <!-- NEW PASSWORD
                ======================= -->
                <label for="npassword">New password</label>
                <div 
                    [ngClass]="{'is-invalid':formPassword.controls.newPassword.invalid&&formPassword.controls.newPassword.touched}"
                    class="d-flex align-items-center" >
                    <input 
                        [type]="showNewPassword? 'text' : 'password'"
                        id="npassword"
                        formControlName="newPassword"
                        class="form-control">
                        
                    <!-- IMAGE SHOW PASSWORD -->
                    <div class="field-icon" (click)="showNewPassword = !showNewPassword">
                        <i class="vi vi-eye" *ngIf="showNewPassword"></i>
                        <i class="vi vi-eye-closed" *ngIf="!showNewPassword"></i>
                    </div>
                </div>
                
                <!-- INVALID FEEDBACK -->
                <div class="invalid-feedback" *ngIf="formPassword.controls.newPassword.invalid&&formPassword.controls.newPassword.touched">
                    <span *ngIf="formPassword.controls.newPassword.value.length > 0">Password must be at least 6 characters</span>
                    <span *ngIf="formPassword.controls.newPassword.value.length == 0">Password required</span>
                </div>

                <!-- CONFIRM NEW PASSWORD
                ======================= -->
                <label for="cpassword" class="mt20">Confirm new password</label>
                <div 
                    [ngClass]="{ 'is-invalid' : formPassword.controls.confirmPassword.invalid&&formPassword.controls.confirmPassword.touched}"
                    class="d-flex align-items-center">
                    <input 
                        [type]="showConfirmPassword?'text' :'password'"
                        id="cpassword"
                        formControlName="confirmPassword"
                        class="form-control">

                    <!-- IMAGE SHOW PASSWORD -->
                    <div class="field-icon" (click)="showConfirmPassword = !showConfirmPassword">
                        <i class="vi vi-eye" *ngIf="showConfirmPassword"></i>
                        <i class="vi vi-eye-closed" *ngIf="!showConfirmPassword"></i>
                    </div>  
                </div>
                
                <!-- INVALID FEEDBACK -->
                <div class="invalid-feedback" *ngIf="formPassword.invalid&&formPassword.controls.confirmPassword.touched">
                    <span *ngIf="formPassword.controls.confirmPassword.value.length > 0">Password must be match</span>
                    <span *ngIf="formPassword.controls.confirmPassword.value.length == 0">Password required</span>

                </div>

                <div class="btn-reset-password">
                    <button     
                        type="submit"
                        class="btn btn-primary btn-sm mt30"
                        [disabled]="formPassword.invalid||loading" 
                        [class.loading-button-primary]="loading"
                        (click)="resetPassword()">
                        Reset Password
                    </button>
                </div>
            </form>
            
            <!-- PASSWORD SUCCESSFULLY CHANGED
            ======================= -->
            <div class="msg-password-changed" *ngIf="passwordChanged">
                <h2 class="title-pages"><i class="vi vi-check-circle"></i>Password reset</h2>
                <div>
                    <p>Your password has been successfully changed</p>
                    <button class="btn btn-sm btn-primary" (click)="mainService.displayLoginDialog=true">Login now <i class="vi vi-arrow-right"></i></button>
                </div>
            </div>
        </div>
    </div>
</app-layouts>
