// Angular packages
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments';

// User-defined services
import { MainService } from 'src/app/shared/services';
import { Observable } from 'rxjs';

// User-defined interfaces
import { AddEditBillingInfoParams, AddPaymentMethodParams, DeletePaymentMethodParams, EditPaymentMethodParams, GetInvoicesParams } from './billing.interfaces';

@Injectable({
  providedIn: 'root'
})
export class BillingService {

  constructor(
    private _http: HttpClient,
    private _mainService: MainService,
  ) { }

  /**
   * * ============================================ *
   * * SUBSCRIPTION FUNCTIONS
   * * ============================================ *
   * * - GET SUBSCRIPTION OVERVIEW
   * * - UNSUBSCRIBE PRODUCT
   * * ============================================ *
   */

  //#region

  /**
   * * GET SUBSCRIPTION OVERVIEW *
   * Todo: to get subscription overview
   * @returns : Observable<any> -> http observable
   */
  public getSubscriptionOverview(refetch:boolean = false): Observable<any> {
    return this._http.get(this._mainService.fetchDataFromApi({
      host: 'billing/detail-pricing',
      refetch: refetch
    }));
  }

  /**
   * * UNSUBSCRIBE PRODUCT *
   * Todo: to unsubribe product
   */
  public unsubscribe(): Observable<any> {
		return this._http.post(`${environment.baseURL}/billing/unsubs`,{});
  }

  //#endregion





  /**
   * * ============================================ *
   * * INVOICES FUNCTIONS
   * * ============================================ *
   * * - GET INVOICES
   * * - CREATE GET INVOICES REQUEST PARAMS
   * * ============================================ *
   */

  //#region 

  /**
   * * GET INVOICES *
   * Todo: to get invoices
   * @param params : GetInvoicesParams -> get invoices params
   * @returns : Observable<any> -> http observable
   */
  public getInvoices(params: GetInvoicesParams): Observable<any> {
    return this._http.get(`${environment.baseURL}/billing/history-invoice`, {
      params: this._createGetInvoicesRequestParams(params)
    });
  }

  /**
   * * CREATE GET INVOICES REQUEST PARAMS *
   * Todo: to create get invoices request params
   * @param params : GetInvoicesParams -> get invoices params
   * @returns : any -> request params
   */
  private _createGetInvoicesRequestParams(params: GetInvoicesParams): any {
    const requestParams = {
      page: params.page.toString(),
      limit: params.limit.toString(),
    };
    if (params.from_date)  requestParams['from_date'] = params.from_date;
    if (params.to_date)  requestParams['to_date'] = params.to_date;

    return requestParams;
  }

  /**
   * * GENERATE ZIP FILE *
   * Todo: to generate invoice as zip file from urls
   * @param urls 
   * @returns 
   */
  public generateZipFile(urls: string[]): Observable<any> {
    return this._http.post(`${environment.base_host}/api/invoice-zip-file`, { urls });
  }

  //#endregion






  /**
   * * ============================================ *
   * * PAYMENT METHODS FUNCTIONS
   * * ============================================ *
   * * - GET CURRENT PAYMENT METHOD
   * * - EDIT PAYMENT METHOD
   * * - DELETE PAYMENT METHOD
   * * - ADD PAYMENT METHOD
   * * ============================================ *
   */

  //#region

  /**
   * * ADD PAYMENT METHOD *
   * Todo: to add payment method
   * @param data : AddPaymentMethodParams
   * @returns : Observable<any> -> http observable
   */
  public addPaymentMethod(data: AddPaymentMethodParams): Observable<any> {
    return this._http.post(`${environment.baseURL}/billing/add-card`, data);
  }

  /**
   * * GET CURRENT PAYMENT METHOD *
   * Todo: to get current payment method
   * @returns : Observable<any> -> http observable
   */
  public getCurrentPaymentMethod(): Observable<any> {
    return this._http.get(`${environment.baseURL}/billing/detail-card-default`);
  }

  /**
   * * EDIT PAYMENT METHOD *
   * Todo: to edit payment method
   * @param data: EditPaymentMethodParams
   * @returns : Observable<any> -> http observable
   */
  public editPaymentMethod(data: EditPaymentMethodParams): Observable<any> {
    return this._http.post(`${environment.baseURL}/billing/edit-card`, data);
  }

  /**
   * * DELETE PAYMENT METHOD *
   * Todo: to delete payment method
   * @param data : DeletePaymentMethodParams
   * @returns : Observable<any> -> http observable
   */
  public deletePaymentMethod(data: DeletePaymentMethodParams): Observable<any> {
    return this._http.post(`${environment.baseURL}/billing/delete-card`, data);
  }

  //#endregion






  /**
   * * ============================================ *
   * * BILING INFO FUNCTIONS
   * * ============================================ *
   * * - GET BILLING INFO
   * * - ADD/EDIT BILLING INFO
   * * ============================================ *
   */

  //#region

  /**
   * * GET BILLING INFO *
   * Todo: to get billing info
   * @returns : Observable<any> -> http observable
   */
  public getBillingInfo(refetch: boolean = false): Observable<any> {
    return this._http.get(this._mainService.fetchDataFromApi({
      host: 'billing/info-user',
      refetch: refetch
    }));
  }

  /**
   * * ADD/EDIT BILLING INFO *
   * Todo: to add/edit billing info
   * @param data : AddEditBillingInfoParams
   * @returns Observable<any> -> http observable
   */
  public addEditBillingInfo(action: 'add' | 'edit', data: AddEditBillingInfoParams): Observable<any> {
    let route = '';
    if (action === 'edit') route = 'edit-customer';
    else if (action === 'add') route = 'create-customer';

    return this._http.post(`${environment.baseURL}/billing/${route}`, data);
  }

  //#endregion
}
