<app-layouts #layouts>
    <div class="about" content>
        <!-- about header section -->
        <section class="header">
            <h1 class="header__title">
                We’re on a mission to democratize the art world and make it accessible to everyone.
            </h1>
        </section>

        <!-- about main section -->
        <section class="main">
            <div class="main__story">
                <div class="main__title">
                    <h2 class="main__title--text">
                        We are villume.
                    </h2>
                </div>
                <div class="main__desc">
                    <p class="main__desc--text">
                        Villume was founded in 2020 by a team of artists and tech enthusiasts who wanted to combine their passion for art and technology to create something new  and exciting.
                    </p>
                </div>
                <div class="main__desc">
                    <p class="main__desc--text">
                        We believe that the traditional art world can be challenging for both artists and galleries, that’s why we created a new innovative platform that makes it easier to showcase and sell artworks in a virtual environment.
                    </p>
                </div>
                <div class="main__desc">
                    <p class="main__desc--text">
                        Our team has been working tirelessly to develop the best quality platform. We are committed to making sure that Villume is accessible and easy to use.
                    </p>
                </div>
            </div>

            <div class="main__teams">
                <div class="main__teams__row">
                    <div class="main__item main__item--left">
                        <div class="main__cards cards">
                            <img class="cards__image" src="{{env.staticAssets}}images/about/alin.png?t={{mainService.appVersion}}" alt="">
                            <div class="cards__label">
                                <p class="cards__name">Alin</p>
                                <p class="cards__name cards__name--divisi">Co-Founder & Head of Development</p>
                            </div>
                        </div>
                    </div>
                    <div class="main__item main__item--left mr-auto">
                        <div class="main__cards cards">
                            <img class="cards__image" src="{{env.staticAssets}}images/about/george.png?t={{mainService.appVersion}}" alt="">
                            <div class="cards__label">
                                <p class="cards__name">George</p>
                                <p class="cards__name cards__name--divisi">Co-Founder & Executive Art Director</p>
                            </div>
                        </div>
                    </div>
                    <div class="main__item main__item--left">
                        <div class="main__cards cards">
                            <img class="cards__image" src="{{env.staticAssets}}images/about/alina.png?t={{mainService.appVersion}}" alt="">
                            <div class="cards__label">
                                <p class="cards__name">Alina</p>
                                <p class="cards__name cards__name--divisi">Head of Finances</p>
                            </div>
                        </div>
                    </div>
                    <div class="main__item main__item--left mr-auto">
                        <div class="main__cards cards">
                            <img class="cards__image" src="{{env.staticAssets}}images/about/victoria.png?t={{mainService.appVersion}}" alt="">
                            <div class="cards__label">
                                <p class="cards__name">Victoria</p>
                                <p class="cards__name cards__name--divisi">Head of Product Design</p>
                            </div>
                        </div>
                    </div>                
                </div>  

                <div class="main__teams__row">
                    <div class="main__item main__item--right ml-auto">
                        <div class="main__cards cards">
                            <img class="cards__image" src="{{env.staticAssets}}images/about/polina.png?t={{mainService.appVersion}}" alt="">
                            <div class="cards__label">
                                <p class="cards__name">Polina</p>
                                <p class="cards__name cards__name--divisi">Associate Art Director</p>
                            </div>
                        </div>
                    </div>
                    <div class="main__item main__item--right">
                        <div class="main__cards cards">
                            <img class="cards__image" src="{{env.staticAssets}}images/about/max.png?t={{mainService.appVersion}}" alt="">
                            <div class="cards__label">
                                <p class="cards__name">Max</p>
                                <p class="cards__name cards__name--divisi">3D & Babylon Developer</p>
                            </div>
                        </div>
                    </div>
                    <div class="main__item main__item--right ml-auto">
                        <div class="main__cards cards">
                            <img class="cards__image" src="{{env.staticAssets}}images/about/adam.png?t={{mainService.appVersion}}" alt="">
                            <div class="cards__label">
                                <p class="cards__name">Adam</p>
                                <p class="cards__name cards__name--divisi">Development Team Lead</p>
                            </div>
                        </div>
                    </div>
                    <div class="main__item main__item--right">
                        <div class="main__cards cards">
                            <img class="cards__image" src="{{env.staticAssets}}images/about/rifky.png?t={{mainService.appVersion}}" alt="">
                            <div class="cards__label">
                                <p class="cards__name">Rifky</p>
                                <p class="cards__name cards__name--divisi">Developer</p>
                            </div>
                        </div>
                    </div>                
                </div>

                <div class="main__teams__row">
                    <div class="main__item main__item--left">
                        <div class="main__cards cards">
                            <img class="cards__image" src="{{env.staticAssets}}images/about/eko.png?t={{mainService.appVersion}}" alt="">
                            <div class="cards__label">
                                <p class="cards__name">Eko</p>
                                <p class="cards__name cards__name--divisi">Tester</p>
                            </div>
                        </div>
                    </div>
                    <div class="main__item main__item--left">
                        <div class="main__cards cards">
                            <img class="cards__image" src="{{env.staticAssets}}images/about/kristina.png?t={{mainService.appVersion}}" alt="">
                            <div class="cards__label">
                                <p class="cards__name">Kristina</p>
                                <p class="cards__name cards__name--divisi">3D Designer</p>
                            </div>
                        </div>
                    </div>
                    <div class="main__item main__item--left mr-auto">
                        <div class="main__cards cards">
                            <img class="cards__image" src="{{env.staticAssets}}images/about/guilia.png?t={{mainService.appVersion}}" alt="">
                            <div class="cards__label">
                                <p class="cards__name">Guilia</p>
                                <p class="cards__name cards__name--divisi">Art Blogger & Writer</p>
                            </div>
                        </div>
                    </div>
                    <div class="main__item main__item--left">
                        <div class="main__cards cards">
                            <img class="cards__image" src="{{env.staticAssets}}images/about/pebi.png?t={{mainService.appVersion}}" alt="">
                            <div class="cards__label">
                                <p class="cards__name">Pebi</p>
                                <p class="cards__name cards__name--divisi">Developer</p>
                            </div>
                        </div>
                    </div>
                    <div class="main__item main__item--left">
                        <div class="main__cards cards">
                            <img class="cards__image" src="{{env.staticAssets}}images/about/rispan.png?t={{mainService.appVersion}}" alt="">
                            <div class="cards__label">
                                <p class="cards__name">Rispan</p>
                                <p class="cards__name cards__name--divisi">Developer</p>
                            </div>
                        </div>
                    </div>                
                </div> 
            </div>

            <!-- carousel only for mobile devices -->
            <div class="wrap-carousel">
            <div class="owl-carousel">
                <div class="main__item">
                    <div class="main__cards cards">
                        <img class="cards__image" src="{{env.staticAssets}}images/about/alin.png?t={{mainService.appVersion}}" alt="">
                        <div class="cards__label">
                            <p class="cards__name">Alin</p>
                            <p class="cards__name cards__name--divisi">Co-Founder & Head of Development</p>
                        </div>
                    </div>
                </div>
                <div class="main__item">
                    <div class="main__cards cards">
                        <img class="cards__image" src="{{env.staticAssets}}images/about/george.png?t={{mainService.appVersion}}" alt="">
                        <div class="cards__label">
                            <p class="cards__name">George</p>
                            <p class="cards__name cards__name--divisi">Co-Founder & Executive Art Director</p>
                        </div>
                    </div>
                </div>
                <div class="main__item">
                    <div class="main__cards cards">
                        <img class="cards__image" src="{{env.staticAssets}}images/about/alina.png?t={{mainService.appVersion}}" alt="">
                        <div class="cards__label">
                            <p class="cards__name">Alina</p>
                            <p class="cards__name cards__name--divisi">Head of Finances</p>
                        </div>
                    </div>
                </div>
                <div class="main__item">
                    <div class="main__cards cards">
                        <img class="cards__image" src="{{env.staticAssets}}images/about/victoria.png?t={{mainService.appVersion}}" alt="">
                        <div class="cards__label">
                            <p class="cards__name">Victoria</p>
                            <p class="cards__name cards__name--divisi">Head of Product Design</p>
                        </div>
                    </div>
                </div> 
                <div class="main__item">
                    <div class="main__cards cards">
                        <img class="cards__image" src="{{env.staticAssets}}images/about/polina.png?t={{mainService.appVersion}}" alt="">
                        <div class="cards__label">
                            <p class="cards__name">Polina</p>
                            <p class="cards__name cards__name--divisi">Associate Art Director</p>
                        </div>
                    </div>
                </div>
                <div class="main__item">
                    <div class="main__cards cards">
                        <img class="cards__image" src="{{env.staticAssets}}images/about/max.png?t={{mainService.appVersion}}" alt="">
                        <div class="cards__label">
                            <p class="cards__name">Max</p>
                            <p class="cards__name cards__name--divisi">3D & Babylon Developer</p>
                        </div>
                    </div>
                </div>
                <div class="main__item">
                    <div class="main__cards cards">
                        <img class="cards__image" src="{{env.staticAssets}}images/about/adam.png?t={{mainService.appVersion}}" alt="">
                        <div class="cards__label">
                            <p class="cards__name">Adam</p>
                            <p class="cards__name cards__name--divisi">Development Team Lead</p>
                        </div>
                    </div>
                </div>
                <div class="main__item">
                    <div class="main__cards cards">
                        <img class="cards__image" src="{{env.staticAssets}}images/about/rifky.png?t={{mainService.appVersion}}" alt="">
                        <div class="cards__label">
                            <p class="cards__name">Rifky</p>
                            <p class="cards__name cards__name--divisi">Developer</p>
                        </div>
                    </div>
                </div>
                <div class="main__item">
                    <div class="main__cards cards">
                        <img class="cards__image" src="{{env.staticAssets}}images/about/eko.png?t={{mainService.appVersion}}" alt="">
                        <div class="cards__label">
                            <p class="cards__name">Eko</p>
                            <p class="cards__name cards__name--divisi">Tester</p>
                        </div>
                    </div>
                </div>
                <div class="main__item">
                    <div class="main__cards cards">
                        <img class="cards__image" src="{{env.staticAssets}}images/about/kristina.png?t={{mainService.appVersion}}" alt="">
                        <div class="cards__label">
                            <p class="cards__name">Kristina</p>
                            <p class="cards__name cards__name--divisi">3D Designer</p>
                        </div>
                    </div>
                </div>
                <div class="main__item">
                    <div class="main__cards cards">
                        <img class="cards__image" src="{{env.staticAssets}}images/about/guilia.png?t={{mainService.appVersion}}" alt="">
                        <div class="cards__label">
                            <p class="cards__name">Guilia</p>
                            <p class="cards__name cards__name--divisi">Art Blogger & Writer</p>
                        </div>
                    </div>
                </div>
                <div class="main__item">
                    <div class="main__cards cards">
                        <img class="cards__image" src="{{env.staticAssets}}images/about/pebi.png?t={{mainService.appVersion}}" alt="">
                        <div class="cards__label">
                            <p class="cards__name">Pebi</p>
                            <p class="cards__name cards__name--divisi">Developer</p>
                        </div>
                    </div>
                </div>
                <div class="main__item">
                    <div class="main__cards cards">
                        <img class="cards__image" src="{{env.staticAssets}}images/about/rispan.png?t={{mainService.appVersion}}" alt="">
                        <div class="cards__label">
                            <p class="cards__name">Rispan</p>
                            <p class="cards__name cards__name--divisi">Developer</p>
                        </div>
                    </div>
                </div> 
                <div></div>
              </div>
              </div>
        </section>

        <!-- about footer section -->
        <section class="about-footer">
            <h3 class="about-footer__title">
                We look forward to helping you experience art in a new and exciting way.
            </h3>
        </section>
    </div>
</app-layouts>