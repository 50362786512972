<app-layouts>
    <div content>
        <div class="wrap-maintenance">
            <div class="row w-100">
                <div class="col col16-8 offset16-4 wrap-title">
                    <h1 class="title pl-0">
                        We will return soon!
                    </h1>
                </div>
            </div>
            <div class="row w-100">
                <div class="col col16-5 offset16-7 wrap-desc">
                    <div class="desc pl-0">
                        <p>
                            Unfortunately the gallery is down for maintenance now. Please come back later!
                        </p>
                        <a href="javascript:;" class="btn btn-primary" routerLink="/">
                            <i class="vi vi-arrow-left"></i> Back
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-layouts>