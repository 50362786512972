import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { NgSwitch, NgSwitchCase, NgClass } from '@angular/common';
declare const require;

@Component({
    selector: 'app-icon',
    templateUrl: './icon.component.html',
    styles: [':host { display: content; }'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgSwitch, NgSwitchCase, NgClass]
})
export class IconComponent implements OnInit {

	@Input() name: string = "";
	@Input() size: number = 24;
	@Input() color: string = "";
	@Input() costum_color: string = "";
	@Input() stroke: string = "";
	@Input() fillOpacity: number = 1;
	@Input() className: string = "";

	/**
	 * * AVAILABLE ICON *
	 * * ============== *
	 * - text-align-right
	 * - text-align-left
	 * - text-align-center
	 * - set-figure-rotation
	 * - plus
	 * - lock
	 * - config-frame
	 * - close
	 * - axis-position
	 * - align-vertical-top
	 * - align-vertical-center
	 * - align-vertical-bottom
	 * - align-top
	 * - align-center
	 * - align-bottom
	 * - delete
	 */

	constructor() { }

	ngOnInit(): void {
	
	}
}
