<div class="wrap-main-profile">
    <ng-container *ngIf="!whiteLabelService.infoPage">
        <div *ngIf="loading" class="overlay-loading">
            Loading....
        </div>

        <!-- PROFILE PAGE -->
        <div *ngIf="!loading" class="wrap-content">
            <div class="profile-content">
                <div class="row-item">

                    <!-- EXHIBITIONS -->
                    <div class="col-item" *ngFor="let item of exhibitions">
                        <div class="post-item">
                            <div class="wrap-image mb15" #wrapImage [style.height.px]="wrapImage.offsetWidth*0.88">
                                <img src="{{env.staticAssets}}images/other/skeleton.svg?t={{mainService.appVersion}}"
                                    [lazyLoad]="item.cover_image ? item.cover_image : item.thumbnail" alt="" />

                                <div class="action">
                                    <button (click)="openNewTab('/v/' + item.share_string)" [tabIndex]="-1"
                                        class="btn-action border-left-0">
                                        <i class="vi vi-eye"></i>
                                        <span>View</span>
                                    </button>
                                    <button class="btn-action" [tabIndex]="-1" (click)="openShareLink(item);">
                                        <i class="vi vi-share"></i>
                                        <span>Share</span>
                                    </button>
                                </div>

                                <div class="countdown d-flex justify-content-center align-items-center"
                                    *ngIf="item.show_countdown">
                                    <div class="content">
                                        <label class="title">Don't miss:</label>
                                        <div class="d-flex">
                                            <div class="time">
                                                <div>{{item.time?.days}}</div>
                                                days
                                            </div>
                                            <div class="time">
                                                <div>{{item.time?.hours}}</div>
                                                hours
                                            </div>
                                            <div class="time">
                                                <div>{{item.time?.minutes}}</div>
                                                minutes
                                            </div>
                                            <div class="time">
                                                <div>{{item.time?.seconds}}</div>
                                                seconds
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="d-flex w-100">
                                <a href="javascript:;" class="exhibition-name"
                                    (click)="openNewTab(viewerPath + '/' + item.share_string)">
                                    {{item.name}}
                                </a>
                            </div>
                        </div>
                    </div>

                    <!-- LOADING -->
                    <div *ngIf="loading" class="wrap-loading">
                        <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="5"
                            animationDuration=".5s"></p-progressSpinner>
                    </div>
                </div>

                <!-- load more -->
                <div class="loadmore text-center" *ngIf="!loading">
                    <button *ngIf="exhibitions.length < totalCount" (click)="getExhibitions(exhibitions.length)">Load
                        More</button>
                </div>
            </div>
        </div>
    </ng-container>

    <!-- INFORMATION PAGE -->
    <ng-container *ngIf="whiteLabelService.infoPage">
        <app-info-white-label [infoUser]="whiteLabelService.infoUser"></app-info-white-label>
    </ng-container>
</div>


<!-- ON SHARE -->
<p-dialog [(visible)]="isShare" [modal]="true" [style]="{'width': '725px'}" [baseZIndex]="10000" [draggable]="false"
    [resizable]="false" styleClass="share-profile">
    <div class="wrap-share">
        <label for="" class="title">Share your gallery</label>
        <div class="form-group">
            <label>Shareable Link</label>
            <input type="text" id="sharelink" readonly [value]="shareableLink" class="form-control bg-dark md mb0">
            <div class="d-block text-center mt10">
                <button (click)="copyText('sharelink')" class="btn btn-primary" style="padding-top: 10px">Copy</button>
            </div>
        </div>
        <div class="form-group">
            <label>Iframe</label>
            <input type="text" id="embedLink" readonly [value]="iframe" class="form-control bg-dark md mb0">
            <div class="d-block text-center mt10">
                <button class="btn btn-primary" (click)="copyText('embedLink')" style="padding-top: 10px">Copy</button>
            </div>
        </div>
    </div>
</p-dialog>