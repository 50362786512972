export const userReducer = (state = {
    info: null,
    userStory: {},
}, action) =>{
	switch (action.type) {
        case "UPDATE_INFO_USER":
            state.info = action.info;
            break;
        case "UPDATE_USER_STORY":
            state.userStory = action.userStory;
            break;
    }
    return state;
}