// Angular packages
import { Component, Input, OnInit } from '@angular/core';

// User-defined interfaces
import { ExhibitionColor } from 'src/app/shared/interfaces';

// User-defined services
import { EditorService } from '../../editor.service';
import { PrimeTemplate } from 'primeng/api';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-exhibition-selection',
    templateUrl: './exhibition-selection.component.html',
    styleUrls: ['./exhibition-selection.component.scss'],
    standalone: true,
    imports: [NgIf, DropdownModule, FormsModule, PrimeTemplate]
})
export class ExhibitionSelectionComponent implements OnInit {
  public exhibitionTypes = []
  public selectedExhibitionType: { label: string, value: string } = null;
  public hasModelTypes: boolean = false;

  @Input() modelType: string = '';

  constructor(
    public editorService: EditorService,
  ) { }

  ngOnInit(): void {
    this._getExhibitionTypes();
  }

  ngOnChanges(): void {
    this._getExhibitionTypes();
  }

  /**
   * * =================================================== *
   * * LIST OF FUNCTIONS
   * * =================================================== *
   * * HANDLE ON CHANGE
   * * GET EXHIBITION TYPES
   * * SET SELECTED EXHIBITION TYPE
   * * =================================================== *
   */

  //#region 

  /**
   * * HANDLE ON CHANGE *
   * Todo: to handle on change event
   */
  public handleOnChange(): void {
    const type = this.selectedExhibitionType.value;
    this.editorService.exhibition.model_type = type;
    this.editorService.switchExhibition(type).then(()=> {
      this.editorService.updateUndoRedoState();
			this.editorService.updateLogActivity('Select exhibition : ' + type + '');
    });
  }

  /**
   * * GET EXHIBITION TYPES *
   * Todo: to get exhibition types from exhibition config
   */
  private _getExhibitionTypes(): void {
    try {
      const { modelTypes } = this.editorService.exhibition.config;
      this.exhibitionTypes = modelTypes.map(({ name, type }, index) => ({
        label: `${index + 1}. ${name}`,
        value: type,
      }));
      this._setSelectedExhibitionType();
      this.hasModelTypes = true;
    } catch (error) {
      this.hasModelTypes = false;
    }
  }
  

  /**
   * * SET SELECTED EXHIBITION TYPE *
   * Todo: to set selected exhibition type
   */
  private _setSelectedExhibitionType(): void {
    this.selectedExhibitionType = this.exhibitionTypes.find((type) => {
      return type.value === this.editorService.exhibition.model_type;
    });
  }

  //#endregion
}
