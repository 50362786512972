import { Component } from '@angular/core';
import { EditorService } from '../../../editor.service';
import { NgStyle } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TextService } from '../../service/text-service.service';
import { MainService } from '@services';
import { TextLoaderService } from '../../service/text-loader.service';

@Component({
  selector: 'app-text-content',
  standalone: true,
  templateUrl: './text-content.component.html',
  styleUrl: './text-content.component.scss',
  imports: [
    NgStyle,
    FormsModule
  ],
})
export class TextContentComponent {

  constructor(
    public editorService: EditorService,
    public textService: TextService,
    public mainService: MainService,
    public textLoader: TextLoaderService
  ) { }

  /**
   * * UPDATE TEXT CONTENT *
   * ANCHOR: Update Text Content
   * @description: to update text content
   */
  public updateTextContent(): void {
    this.editorService.dataHasChanges = true;
    if (this.editorService.selectedExhibitAssets.length > 1) {
      this.editorService.selectedExhibitAssets.forEach((node: any) => {
        const textWallData = this.textService.getTextWallData(node);
        textWallData.content = this.textLoader.activeText.content;
        node.textGUI.text = this.textLoader.activeText.content;
        node.changed = true;
      })
    } else {
      this.textLoader.activeTextNode.textGUI.text = this.textLoader.activeText.content;
      this.textLoader.activeTextNode.changed = true;
    }
    this.editorService.updateLogActivityWithDelay("Update text content")
    this.editorService.updateUndoRedoStateWithDelay()
  }

}
