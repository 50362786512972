// Angular built-in components/methods
import { Component, OnInit, AfterViewInit, Input, ViewEncapsulation, Output, EventEmitter  } from '@angular/core';
import { NgIf, NgTemplateOutlet } from '@angular/common';

// Third party plugins
import * as _ from "lodash";
declare var $: any;
import { SsrCookieService as CookieService } from 'ngx-cookie-service-ssr';

// User-defined services
import { MainService } from "src/app/shared/services";
import { LayoutsService } from "../layouts.service";
import { environment } from '@environments';
import { WhiteLabelService } from 'src/app/components/white-label/white-label.service';
import { PrimeTemplate } from 'primeng/api';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
      NgIf,
      RouterLink,
      NgTemplateOutlet,
      RouterLinkActive,
      DropdownModule,
      FormsModule,
      PrimeTemplate
    ]
})
export class HeaderComponent implements OnInit, AfterViewInit{
  public headerMsg: string = 'Play with the villume beta version for free until April 1st, 2023';
  public supportToken: string = '';
  public onLogout: boolean = false;
  public selectedLanguage: {
    value: string
  };
  public env = environment;

  @Input() contentWidth: number = 0;
  @Output() hideMSG: EventEmitter<boolean> = new EventEmitter();
    
  constructor(
    public cookieService: CookieService,
    public mainService:MainService,
    public layoutsService:LayoutsService,
    public whiteLabelService: WhiteLabelService,
  ) {}

  ngOnInit(): void {
    this.setHeaderMsgVisibility();
    this.getSupportToken();
  }

  ngAfterViewInit() {
    if(this.mainService.isBrowser){
      this.setOverlayReponsiveMargin();
    } 
  }

  /**
   * * ================================ * 
   * * LIST OF FUNCTIONS                *
   * * ================================ *
   * - SET OVERLAY RESPONSIVE MARGIN
   * - SET HEADER MESSAGE VISIBILITY
   * - CLOSE HEADER MESSAGE
   * - UPDATE USER IS BACK
   * - GET SUPPORT TOKEN
   * - STICKY HEADER
   * - BACK TO SUPPORT
   * - LOGOUT
   * - CHANGE LANGUAGE
   * - GO TO NEWS 
   * - LOGIN
   */

  /**
   * * SET OVERLAY RESPONSIVE MARGIN *
   * Todo: to set margin of "Overlay Responsive" element
   */
  setOverlayReponsiveMargin() {
    const overlay = document.querySelector<HTMLElement>('.overlay-responsive');
    overlay.style.marginLeft= '-105%';
    window.addEventListener('resize', () =>{
      if(window.innerWidth < 1025){
        overlay.style.marginLeft= '-105%';
        this.layoutsService.showMenu = false;
      }else{
        overlay.style.marginLeft= '-105%';
      }
    });
  }

  /**
   * * SET HEADER MESSAGE VISIBILITY *
   * Todo: to set header message banner visbility
   * Detail: if user is back, then show header message banner for 30 seconds
   */
  setHeaderMsgVisibility() {
    if(this.mainService.userInfo?.is_back){
      this.mainService.showMSG = true;
      this.headerMsg = `Welcome back, ${this.mainService.userInfo.first_name}! Your profile has been successfully restored.`;
      this.updateUserIsBack();
      setTimeout(()=>{
        this.mainService.closeHeaderMessage();
      }, 30000);
    }
  }

  /**
   * * UPDATE USER IS BACK *
   * Todo: to update user 'is_back' status
   */
  updateUserIsBack() {
    this.mainService.updateUserIsBack().subscribe(res => {}, err => {
      if (err.name === 'TimeoutError') {
        this.updateUserIsBack();
      }
    });
  }

  /**
   * * GET SUPPORT TOKEN *
   * Todo: to get support token
   */
  getSupportToken() {
    if(this.mainService.isBrowser) this.supportToken = this.cookieService.get('support_token');
    else this.supportToken = this.cookieService.get('support_token');
  }

  /**
   * * STICKY HEADER *
   * Todo: to adding function sticky header
   */
  stickyHeader() {  
    $('body').on("scroll",() => {
      const wrapNavigation = $('.wrap-navigation');
      const headerHolder = $('.header-holder');
      const bannerMessage = $('.beta-message');
      const wrapHeader = $('.wrap-header')
      const scrollTop = $('body').scrollTop();

      if (scrollTop > (bannerMessage.innerHeight() || 0)){
        headerHolder.css("height", wrapNavigation.innerHeight() + "px")
        wrapNavigation.addClass('fixed');
      }else{
        headerHolder.css("height", "0px")
        wrapNavigation.removeClass('fixed');
      }

      if ($('.wrap-navigation.fixed')[0]){
        document.documentElement.style.setProperty('--mainHeightHeader', wrapNavigation.innerHeight()+'px');
      } else {
        document.documentElement.style.setProperty('--mainHeightHeader', wrapHeader.innerHeight()+'px');
        if (!bannerMessage[0]){
          document.documentElement.style.setProperty('--mainHeightHeader', wrapNavigation.innerHeight()+'px');
        }
      }
    });
  }

  /**
   * * BACK TO SUPPORT *
   * Todo: to back to support account after login into other user 
   */
  backToSupport(url: string) {
    if (this.mainService.isBrowser){
      if (this.supportToken!=null){
        this.layoutsService.updateToken(this.supportToken).subscribe(async (res)=>{
          localStorage.removeItem('deleted_user');
          window.location.href = `/${url}`
        })
      }
    }
  }

  /**
   * * LOGOUT *
   * Todo: -
   */
  logout() {
    this.onLogout = true;
    this.layoutsService.updateToken("").subscribe(resToken =>{
      this.mainService.token = null;
      document.cookie = "support_token=;";
      document.cookie = "first_pending_payment=;";
      localStorage.removeItem('loginFrom');
      localStorage.removeItem('productName');
      window.location.href = "/";
    })
	}

  /**
   * * GO TO NEWS *
   * Todo: to directs user to villume news
   */
  goToVillumeNews() {
    this.layoutsService.showMenu=false;
    window.open("https://news.villume.com/")
  }

  /**
   * * LOGIN *
   * Todo: -
   */
  login() {
    this.mainService.displayLoginDialog = true;
    this.layoutsService.formType='login';
  }
}
