<div class="wrap-menu-icon">
    <div class="left-menu">
        <ul>
            <!-- EDIT FRAME -->
            <li 
                *ngIf="!isMultiSelectMode() && ['figure-image','video'].includes(editorService.activeArtwork.file_type)" 
                class="item-menu-icon" 
                pTooltip="Frame Editor" 
                tooltipPosition="top" 
                placeholder="top">
                <button
                    [disabled]="editorService.blockUserAction"
                    [class.disable-icon]="editorService.blockUserAction"
                    class="item-btn" 
                    (click)="openEditFrame()">
                    <i class="vi vi-frame"></i>
                </button>
            </li>

            <!-- ROTATE VIA AXIS -->
            <li 
                *ngIf="!isMultiSelectMode()"
                class="item-menu-icon" 
                pTooltip="Rotate Axis" 
                tooltipPosition="top" 
                placeholder="top">
                <button 
                    [disabled]="editorService.blockUserAction" 
                    (click)="adjustPositionByAxis('rotation')" 
                    [class.disable-icon]="editorService.blockUserAction"
                    class="item-btn">
                    <i class="vi vi-global"></i>
                </button>
            </li>

            <!-- MOVE VIA AXIS -->
            <li 
                class="item-menu-icon" 
                pTooltip="Moving Axis" 
                tooltipPosition="top" 
                placeholder="top">
                <button
                    [disabled]="editorService.blockUserAction" 
                    [class.disable-icon]="editorService.blockUserAction"
                    (click)="adjustPositionByAxis('position')" 
                    class="item-btn">
                    <i class="vi vi-axis"></i>
                </button>
            </li>

            <!-- LOCK CAMERA -->
            <li 
                *ngIf="!isMultiSelectMode()"
                class="item-menu-icon" 
                pTooltip="{{!editorService.lockCameraWhenDragArtwork ? 'Lock Camera': 'Unlock Camera'}}" 
                tooltipPosition="top" 
                placeholder="top">
                <button 
                    (click)="toggleLockCamera()" 
                    [disabled]="editorService.blockUserAction" 
                    [class.disable-icon]="editorService.blockUserAction"
                    class="item-btn">
                    <i class="vi vi-locked" *ngIf="editorService.lockCameraWhenDragArtwork"></i>
                    <i class="vi vi-unlocked" *ngIf="!editorService.lockCameraWhenDragArtwork"></i>
                </button>
            </li>

            <!-- FOCUS TO ARTWORK -->
            <li 
                *ngIf="!isMultiSelectMode()"
                class="item-menu-icon" 
                tooltipPosition="top" 
                pTooltip="Focus to Artwork"
                placeholder="top">
                <button 
                    (click)="focusOnArtwork()"
                    [disabled]="isFocusOnArtworkDisabled()" 
                    [class.disable-icon]="isFocusOnArtworkDisabled()"
                    class="item-btn">
                    <i class="vi vi-eye"></i>
                </button>
            </li>
        </ul>
    </div>

    <div class="right-menu">
        <ul>
            <!-- DELETE ARTWORK -->
            <li class="item-menu-icon" pTooltip="Delete Artwork" tooltipPosition="top" placeholder="top">
                <button 
                    class="item-btn" 
                    (click)="showDeleteArtworkPopup()"
                    [class.disable-icon]="editorService.blockUserAction"
                    [disabled]="editorService.blockUserAction">
                    <i class="vi vi-delete"></i>
                </button> 
            </li>
        </ul>
    </div>
</div>


<!-- COMFIRM DELETE 
============================== -->
<p-dialog 
    [(visible)]="displayConfirmDelete" 
    [modal]="true" 
    [style]="{ maxWidth: '725px' }"
    [baseZIndex]="10000"
    [closable]="!onDeleting"
    [draggable]="false" 
    [resizable]="false"
    styleClass="confirm-delete-dark">
    <div class="wrap-content-confirm">
        <div class="desc">
            <h3>Are you sure you want to delete this artwork?</h3>
            <p>If you press “Yes”, your artwork will be deleted immediately. You can’t undo this action.</p>
        </div>
        <div class="wrap-btn">
            <button
                [disabled]="onDeleting" 
                (click)="deleteArtwork()" 
                class="btn btn-light btn-sm" 
                [class.loading-button-primary]="onDeleting">
                Yes
            </button>
            <button [disabled]="onDeleting" class="btn btn-primary btn-sm mr0" (click)="displayConfirmDelete=false">No</button>
        </div>
    </div>
</p-dialog>