<app-layouts >
    <div content>
        <div class="info-delete-accont">
            <div class="row">
                <div class="col col16-8 col-iped-5 col-mobile-3 offset16-4 offset-iped-2">
                    <h1 class="title">
                        We’re sorry <br> to see you go :(
                    </h1>
                </div>
            </div>
            <div class="row">
                <div class="col col16-8 col-iped-3 col-mobile-3 offset16-5 offset-iped-2">
                    <div class="desc">
                        <button class="btn btn-primary" (click)="openLogin()">
                            <i class="vi vi-arrow-left"></i>  Restore Your Profile
                        </button>
                        <p>
                            Your profile will remain active for 14 days in case you change your mind. 
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-layouts>