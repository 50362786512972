import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/shared/services';
import { AlertMessageService } from 'src/app/components/alert-message/alert-message.service';
import { ResetPasswordService } from './reset-password.service';
import { NgIf, NgClass } from '@angular/common';
import { LayoutsComponent } from '../../layouts/layouts.component';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    standalone: true,
    imports: [LayoutsComponent, NgIf, FormsModule, ReactiveFormsModule, NgClass]
})
export class ResetPasswordComponent implements OnInit {

	public loading: boolean = false;
	public showConfirmPassword: boolean = false;
	public showNewPassword: boolean = false;
	public passwordChanged: boolean = false;

	public formPassword: any = new FormGroup({
		newPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
		confirmPassword: new FormControl('', [Validators.required])
	},{ validators: this.checkNewPasswords });

	constructor(
		private resetPasswordService: ResetPasswordService,
		private alertMessageService: AlertMessageService,
		public mainService: MainService,
		public router: Router
	) { }

	ngOnInit(): void {
		if(this.resetPasswordService.token == null){
			this.router.navigateByUrl('/404')
		}
	}

	resetPassword(){
		this.loading = true
		this.resetPasswordService.resetPassword(this.formPassword.value.newPassword, this.formPassword.value.confirmPassword).subscribe((res:any)=>{
			this.loading = false;
			this.passwordChanged = true;
			this.resetPasswordService.token = null;
		},err=>{
			if(err.name == "TimeoutError"){
				this.resetPassword();
			}else{
				this.loading = false;
				this.alertMessageService.add({ severity: "error", summary: "Error", detail: "Something went wrong. Failed to update password"})
			}
		});
	}

	checkNewPasswords(group: FormGroup) {
		let npass = group.controls.newPassword.value;
		let cPass = group.controls.confirmPassword.value;
		
		return npass === cPass ? null : { notSame: true }
	}
}
