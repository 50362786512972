
<!-- ADMIN LAYOUT 
===================================-->
<p-scrollPanel *ngIf="mainService.layoutType!='editor'" [style]="{'height':'var(--vh)'}">
    <div class="wrapper" *ngIf="mainService.layoutType=='admin' && mainService.isBrowser">
        <!-- HEADER
        ============================= -->
        <div class="wrap-header" #mainHeightHeader [class.fixed-header]="layoutsService.showMenu">
            <app-header [contentWidth]="container.offsetWidth"></app-header>
        </div>

        <!-- MAIN CONTENT
        ============================= -->
        <div class="main" #container>
            <div class="wrap-content" [class.d-flex]="layoutsService.sideMenu">
                <!-- Side Menu -->
                <ng-container *ngIf="layoutsService.sideMenu">
                    <app-sidemenu></app-sidemenu>
                </ng-container>

                <div class="content" *ngIf="!whiteLabelService.whiteLabel">
                    <ng-container *ngIf="!mainService.createExhibitionWidget">
                        <ng-content  select="[content]"></ng-content>
                    </ng-container>

                    <ng-container *ngIf="mainService.createExhibitionWidget">
                        <app-create-exhibition></app-create-exhibition>
                    </ng-container>
                </div>

                <div [hidden]="!mainService.loadingContent" class="loading-content">
                    <p>Loading ...</p>
                </div>
            </div>

            <ng-container *ngIf="whiteLabelService.whiteLabel">
                <div class="wrap-content d-flex">
                    <app-sidemenu [userInfo]="whiteLabelService.infoUser"></app-sidemenu>

                    <div class="content">
                        <app-white-label></app-white-label>
                    </div>
                </div>
            </ng-container>
        </div>

        <!-- FOOTER
        ============================= -->
        <div class="wrap-footer" #mainHeightFooter>
            <app-footer></app-footer>
        </div>
    </div>
</p-scrollPanel>


<!-- EDITOR LAYOUT 
===================================-->
<ng-content 
    *ngIf="mainService.layoutType=='editor'"
    select="[content-editor]">
</ng-content>

<!-- POPPUP LOGIN AND SIGN UP
==================================== -->
<ng-container *ngIf="mainService.isBrowser">
    <p-dialog 
        [(visible)]="mainService.displayLoginDialog" 
        appendTo="body" 
        [modal]="true" 
        [closable]="!layoutsService.loadingProcess"
        [breakpoints]="{'1024px': '685px', '920px': '718px', '420px': '100%'}" 
        [style]="{width: '685px'}" 
        [baseZIndex]="10000" 
        [draggable]="false" 
        [resizable]="false" 
        (onHide)="onHideDialog()"
        (onShow)="onShowDialog()"
        closeIcon="vi vi-x"
        styleClass="show-login">

        <!-- DIALOG HEADER
        =========================== -->
        <ng-template pTemplate="header">
            <h5 class="p-dialog-title">{{(layoutsService.formType=='login' ? 'Log In' : 'Get Started')}}</h5>
        </ng-template> 
        <!-- DIALOG CONTENT 
        =========================== -->

        <!-- LOGIN -->
        <ng-container *ngIf="mainService.displayLoginDialog&&layoutsService.formType=='login'">
            <app-login></app-login>
        </ng-container>

        <!-- SIGN UP -->
        <ng-container *ngIf="mainService.displayLoginDialog&&layoutsService.formType=='sign up'">
            <app-sign-up></app-sign-up>
        </ng-container>

        <!-- DIALOG FOOTER
        ========================= -->
        <ng-template pTemplate="footer">

            <h5 class="title-footer">or Sign In with:</h5>
            <div class="d-flex align-items-center h-40"> 
                <div id="google-btn"></div>
            </div>
            <div class="go-sign-up" *ngIf="layoutsService.formType=='sign up' && !layoutsService.loadingProcess">
                Already have an account? <a href="javascript:;" (click)="layoutsService.formType='login'">Log in <i class="vi vi-arrow-right"></i></a>
            </div>
            <div class="go-sign-up" *ngIf="layoutsService.formType=='login' && !layoutsService.loadingProcess&&!mainService.loginForExpiredToken">
                Don’t have an account? <a href="javascript:;" (click)="layoutsService.formType='sign up'">Sign up <i class="vi vi-arrow-right"></i></a>
            </div>
        </ng-template>

    </p-dialog>
</ng-container>

<!-- toast message -->
<app-alert-message></app-alert-message>

<!-- Token expired -->
<p-dialog 
    [(visible)]="isDialogVisible" 
    [modal]="true" 
    [draggable]="false" 
    [style]="{maxWidth: '725px'}"
    [baseZIndex]="10000"
    styleClass="confirm-delete">
    <div class="wrap-content-confirm">
        <div class="desc">
            <p class="pl0">
                Your token has expired. Do you want to log in again?
            </p>
        </div>
        <div class="wrap-btn">
            <button 
                (click)="openLoginSessionExpired()"
                class="btn btn-primary btn-sm">
                Yes
            </button>
            <button 
                (click)="refreshPage()"
                class="btn btn-light btn-sm mr0">
                No
            </button> 
        </div>
    </div>
</p-dialog>

<app-pending-payment
    (onHide)="mainService.showPendingPayment=false"
    [urlInvoice]="mainService.urlInvoice"
    [showAuthDialog]="mainService.showPendingPayment"
    [productDetail]="mainService.productDetail">
</app-pending-payment>

<!-- LIMIT MESSAGE -->
<p-dialog 
    [(visible)]="layoutsService.displayLimitMessage" 
    [modal]="true"
    [draggable]="false" 
    [style]="{maxWidth: '725px'}"
    [baseZIndex]="10000"
    styleClass="confirm-delete">
    <div class="wrap-content-confirm">
        <div class="desc" [style]="{'margin-bottom': '28px'}">
            <h3>You have reached your maximum gallery limit.</h3>
            <p>
                {{layoutsService.billingProduct}} accounts are limited to {{layoutsService.onlineExhibtionsLimit}} Active Gallery and {{layoutsService.exhibtionDraftsLimit}} Inactive Gallery. To add more galleries please upgrade your Pricing Plan or <a routerLink="/contact" class="text-primary">contact us.</a>
            </p>
        </div>
    </div>
</p-dialog>