import { Injectable } from '@angular/core';
import { EditorService } from '../../editor.service';
import { AlertMessageService } from 'src/app/components/alert-message/alert-message.service';
import { FormControl, Validators } from '@angular/forms';
import { InputNumberComponent } from 'src/app/components/input-number/input-number.component';
import { TextLoaderService } from './text-loader.service';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { environment } from '@environments';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TextService {
  public displayConfirmDelete: boolean = false;
  public onDelete: boolean = false;
  public onCreating: boolean = false;
  public selectedFontFamily;
  public selectedFontStyle: any = { name: "Regular" }
  public fontSize = 0;
  public lineHeight: number = 0;
  public scalingValue: any = 0;
  public textDefinition: any = new FormControl("", [
    Validators.required,
    Validators.maxLength(50),
    Validators.pattern("^[a-zA-Z0-9 ()]*$")
  ]);

  constructor(
    private _editorService: EditorService,
    private _alertMessageService: AlertMessageService,
    private _textLoader: TextLoaderService,
    private _http: HttpClient
  ) { }

  /** 
   * * DELETE TEXT *
   * ANCHOR Delete Text
   * @description: to delete text
   */
  public deleteText(): void {
    const ids = this._getDeleteTextWallIds();
    if (ids.length == 1) this._editorService.deleteTextFromScene(ids[0])
    else this._editorService.deleteMultiTextFromScene(ids)
    this.displayConfirmDelete = false;
    this._alertMessageService.add({ severity: "success", summary: "Success", detail: "Text has been successfully deleted" })
  }


  /**
   * * GET DELETE TEXT WALL IDS *
   * ANCHOR Get Delete Text Wall Ids
   * @description: to get ids of text walls to be deleted
   * @returns : string[] -> array of text wall ids
   */
  private _getDeleteTextWallIds(): string[] {
    if (this._editorService.selectedExhibitAssets.length > 1) {
      return this._editorService.selectedExhibitAssets.map(node => node.id.replace("textWall-", ""))
    } else {
      return [this._textLoader.activeText.id]
    }
  }

  /**
   * ANCHOR: Get Text Wall Data
   * @description: to get text wall data from text wall node
   * @param textWallNode : BABYLON.TransformNode
   * @returns : TextWall
   */
  public getTextWallData(textWallNode) {
    const id = textWallNode.id.replace('textWall-', '');
    const textWallData = this._textLoader.texts.find(textWall => textWall.id === id);
    return textWallData;
  }

  /**
   * ANCHOR Init Inputs Value
   * @description to initialize all input when select text wall
   */
  public initInputsValue(): void {
    const fontFamily = this._textLoader.textFontFamilies.find((x) =>
      x.name == this._textLoader.activeText.font_family || x.value == this._textLoader.activeText.font_family
    );
    this.selectedFontFamily = fontFamily;
    this.selectedFontStyle = { name: this._textLoader.activeText.font_style }
    this.fontSize = this._textLoader.activeText.font_size / 2;
    this.lineHeight = Math.round((this._textLoader.activeText.line_height / 2) * 1.12);
    this.scalingValue = Math.round(this._textLoader.activeTextNode.scaling.x * 100);
    this.textDefinition.setValue(this._textLoader.activeText.name);
    this._textLoader.textDataValid = this.textDefinition.valid;
  }

  /**
   * * TRIGGER ON CHANGE INPUT *
   * ANCHOR Trigger On Change Input
   * @description to trigger on change input
   */
  public triggerOnChangeInput(element: InputNumberComponent): void {
    element.onChangeHandler();
  }

  /**
  * * UPDATE LINE HEIGHT *
  * ANCHOR Update line height
  * @description for updated line height
  */
  public updateLineheight(textWallNode): void {
    const textWallData = this.getTextWallData(textWallNode);
    textWallNode.textGUI.onLinesReadyObservable.addOnce(() => {
      textWallNode.textGUI.fontOffset.height = textWallData.line_height / textWallData.scaling.scaling_x * this._textLoader.textWallQuality;
      textWallNode.changed = true;
    });
  }

  /**
	 * * CREATE TEXT [QUERY] *
	 * Todo: Query for craeting new text
	 */
	public createText(initialPosition: { rotation: any, position: any }, textName: string, textWallImage: File, exhibitionId: string) {
		// Create header options
		const options: any = {
			reportProgress: true,
			observe: 'events',
		};

		const { rotation, position } = initialPosition;
		const formData = new FormData();
		formData.append('picture', textWallImage);
		formData.append('position', JSON.stringify({
			position_x: position.x,
			position_y: position.y,
			position_z: position.z
		}));
		formData.append('rotation', JSON.stringify({
			rotation_x: rotation.x,
			rotation_y: rotation.y,
			rotation_z: rotation.z
		}));
		formData.append('exhibition_id', exhibitionId);
		formData.append('name', textName);
		


		// Send request
		return this._http.post(`${environment.baseURL}/image/add-text-wall`, formData, options).pipe(map((event: any) => {
			switch (event.type) {
				case HttpEventType.UploadProgress:
					const progress = Math.round(100 * event.loaded / event.total);
					return {status: 'progress', data: progress};
	
				case HttpEventType.Response:
					return {status: 'response', data: event.body};
	
				default:
					return `Unhandled event: ${event.type}`;
			}
		}));
	}
}
