import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingGalleryService {
  public label: string = '';
  public show: boolean = true;
  public percent: number = 0;
  public backgroundType: 'block' | 'overlay' | 'none' = 'block';
}
