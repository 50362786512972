<ng-container *ngIf="hasModelTypes">
    <p-dropdown 
      [options]="exhibitionTypes" 
      [(ngModel)]="selectedExhibitionType" 
      (onChange)="handleOnChange()"
      optionLabel="label" 
      dropdownIcon="pi pi-arrow-down" 
      styleClass="bg-dark md mb30"
      [disabled]="editorService.blockUserAction">
      <ng-template let-item pTemplate="item">
          <div class="item-dropdown">
              <div>{{item.label}}</div>
              <i class="pi pi-arrow-left"></i>
          </div>
      </ng-template>
    </p-dropdown>
</ng-container>