<div class="wrapper offset-iped-1" content>
  <div class="info">
    <div class="wrap-details">
      <div class="about" *ngIf="infoUser?.about">
        <label class="label d-block">About</label>
        <p class="desc">{{infoUser.about}}</p>
      </div>

      <div class="sosial-mobile">
        <ng-container *ngTemplateOutlet="sosialMedia"></ng-container>
      </div>

      <div class="contact-info" *ngIf="infoUser?.website_url || infoUser?.email_profile">
        <label class="label">Contact Info</label>
        <div class="website" *ngIf="infoUser?.website_url">
          <label class="label-desc">Website</label>
          <span class="desc"><a [href]="infoUser?.website_url">{{infoUser?.website_url}}</a></span>
        </div>

        <div class="email" *ngIf="infoUser?.email_profile">
          <label class="label-desc">Email</label>
          <span class="desc"><a [href]="'mailto:'+infoUser?.email_profile">{{infoUser?.email_profile}}</a></span>
        </div>
      </div>

      <div class="general" *ngIf="infoUser?.location">
        <label class="label">General</label>
        <div class="location">
          <label class="label-desc">Location</label>
          <span class="desc">{{infoUser?.location}}</span>
        </div>
      </div>
    </div>

    <div class="sosial-desktop">
      <ng-container *ngTemplateOutlet="sosialMedia"></ng-container>
    </div>
  </div>
</div>


<ng-template #sosialMedia>
	<div class="sosial-media" *ngIf="infoUser?.facebook_url||infoUser?.instagram_url">
		<label class="label">Social Media</label>
		<div class="media">
			<button class="facebook" *ngIf="infoUser?.facebook_url"
				(click)="navigateToSosmed(infoUser.facebook_url, 'facebook')">
				<i class="vi vi-facebook-solid"></i>
			</button>
			<button *ngIf="infoUser?.instagram_url" (click)="navigateToSosmed(infoUser.instagram_url, 'instagram')"
				class="instagram">
				<i class="vi vi-Instagram-solid"></i>
			</button>
		</div>
	</div>
</ng-template>