import { NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TextWall } from '@interfaces';
import { EditorService } from '../../editor.service';
import { TextLoaderService } from '../service/text-loader.service';

@Component({
  selector: 'app-text-list',
  standalone: true,
  templateUrl: './text-list.component.html',
  styleUrl: './text-list.component.scss',
  imports: [
    NgIf,
    NgFor
  ],
})
export class TextListComponent {
  @Input() loading: boolean;

  constructor(
    public editorService: EditorService,
    private _textLoader: TextLoaderService
  ) { }


  /**
  * * SELECT TEXT *
  * ANCHOR Select Text
  * @description select text
  */
  public selectText(id: number): void {
    if (!this.editorService.blockUserAction) {
      const textMesh = this.editorService.scene.getTransformNodeByID("textWall-" + id);
      this.editorService.selectExhibitAsset(textMesh)
      this.editorService.updateLogActivity("Select text")
    }
  }
  /**
   * ANCHOR Is Text Walls Not Empty
   * @description to check is text walls not empty
   * @returns : boolean
   */
  public isTextWallsNotEmpty(): boolean {
    return this.textWallsData().length > 0;
  }

  /**
   * ANCHOR Text Walls Data
   * @description to get text walls data that not deleted
   * @returns : TextWall[]
   */
  public textWallsData(): TextWall[] {
    return this._textLoader.texts.filter((text: TextWall) => !text.deleted);
  }
}
