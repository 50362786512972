<div>
    <ng-container *ngIf="!textLoader.activeTextNode">
        <app-text-create></app-text-create>
        <app-text-list [loading]="loadingGalleryService.show"></app-text-list>
    </ng-container>

    <ng-container *ngIf="textLoader.activeTextNode">
        <app-text-editor></app-text-editor>
    </ng-container>
</div>

<!-- COMFIRM DELETE  -->
<p-dialog 
    [(visible)]="textService.displayConfirmDelete" 
    [modal]="true" 
    [style]="{maxWidth: '725px'}"
    [baseZIndex]="10000"
    [draggable]="false" 
    [resizable]="false"
    [closable]="!textService.onDelete"
    styleClass="confirm-delete-dark">
    <div class="wrap-content-confirm">
        <div class="desc">
            <h3>Are you sure you want to delete this text?</h3>
            <p>If you press “Yes”, your text will be deleted immediately. You can’t undo this action.</p>
        </div>
        <div class="wrap-btn">
            <button  
                (click)="textService.deleteText()"
                [disabled]="textService.onDelete"
                class="btn btn-light btn-sm"
                [class.loading-button-primary]="textService.onDelete">
                Yes
            </button>
            <button 
                [disabled]="textService.onDelete" class="btn btn-primary btn-sm mr0" 
                (click)="textService.displayConfirmDelete=false">
                No
            </button>
        </div>
    </div>
</p-dialog>

<!-- COMFIRM DELETE  -->
<p-dialog 
    [(visible)]="editorService.displaySelectOverlappingTexts"
    [modal]="true" 
    [style]="{maxWidth: '725px'}"
    [baseZIndex]="10000"
    (onHide)="onHideDisplaySelectOverlappingTexts()"
    (onShow)="onShowDisplaySelectOverlappingTexts()"
    [draggable]="false" 
    [resizable]="false"
    styleClass="select-overlaping-text">
    <div class="desc">
        <h3>The text is overlapping with another text!</h3>
        <p>Please select which text to edit</p>
    </div>
    <div class="wrap-item-text">
        <ul>
            <ng-container *ngFor="let text of editorService.overlapingTextWalls">
                <li class="item-text" (click)="selectOverlapingTexts(text.id)">
                    <a 
                        href="javascript:;" 
                        class="link-text"
                        [class.active]="isSelectedOverlapingText(text.id)">
                        <div class="text">{{text.name}}</div>
                        <button class="btn-modify">
                            <i class="vi vi-modify"></i>
                        </button>
                    </a>
                </li>
            </ng-container>
        </ul>
    </div>
    <div class="wrap-btn-select">
        <button  
            [disabled]="selectedOvarlapingTextIds.length === 0"
            (click)="setActiveSelectedOverlapingTexts()"
            class="btn btn-light btn-sm">
            Select
        </button>
    </div>
</p-dialog>