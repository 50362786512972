import { Component } from '@angular/core';
import { EditorService } from '../../editor.service';
import { DialogModule } from 'primeng/dialog';
import { TooltipModule } from 'primeng/tooltip';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-menu-icons',
    templateUrl: './menu-icons.component.html',
    styleUrls: ['./menu-icons.component.scss'],
    standalone: true,
    imports: [NgIf, TooltipModule, DialogModule]
})
export class MenuIconsComponent {
  public displayConfirmDelete: boolean = false;
  public onDeleting: boolean = false;

  constructor(
    public editorService: EditorService,
  ) { }



  /**
   * * ================================================== *
   *   SECTION Delete Artwork Functions
   * * ================================================== *
   */

  //#region 

  /**
   * ANCHOR Show Delete Popup
   * @description: to show delete artwork popup
   */
	public showDeleteArtworkPopup(): void {
		this.displayConfirmDelete = true;
		if (this.editorService.activeArtworkId != null) {
			this.editorService.unfocusFromArtworkAnimation();
		}
	}

  /**
   * ANCHOR Delete Artwork
   * @description: to delete artwork
   */
  public deleteArtwork(): void {
		if (this.isMultiSelectMode()) {
      this.editorService.deleteMultiArtworkFromScene();
    } else {
      this.editorService.deleteArtworkFromScene();
    }
  }

  //#endregion
  //!SECTION




  /**
   * * ================================================== *
   *   SECTION Uncategorized Functions
   * * ================================================== *

  //#region 

  /**
   * ANCHOR Is Multi Select Mode
   * @description: to check if multi select mode is active or not
   * @return: boolean
   */
  public isMultiSelectMode(): boolean {
    return this.editorService.selectedExhibitAssets.length > 1;
  }

  /**
   * ANCHOR Is Focus On Artwork Disabled
   * @description: to check if focus on artwork is disabled or not
   * @return boolean
   */
  public isFocusOnArtworkDisabled(): boolean {
    return (
      this.editorService.blockUserAction || 
      this.editorService.activeArtworkId != null || 
      this.editorService.focusAnimationIsRun
    );
  }

  /**
   * ANCHOR Open Edit Frame
   * @description: to open edit frame popup
   */
	public openEditFrame(): void {
		this.editorService.applyToAllFrameArtwork = false;
		this.editorService.editFrameMode = true;
		this.editorService.blockUserAction = true;
	}

  /**
   * ANCHOR Adjust Position by Axis
   * @description: to adjust position by axis
   * @param action : 'rotation' | 'position'
   */
	public adjustPositionByAxis(action: 'rotation' | 'position'): void {
		if(!this.editorService.gizmos[action].attachedMesh){
			this.editorService.hideAxis();
			this.editorService.showAxis(action, this.editorService.activeArtworkNode);			
			this.editorService.updateLogActivity(`Show ${action} axis`)
		}else{
			this.editorService.updateLogActivity('Hide axis')
			this.editorService.hideAxis();
		}
  }

  /**
   * ANCHOR Toggle Lock Camera
   * @description: to toggle lock camera
   */
	public toggleLockCamera(): void {
		this.editorService.lockCameraWhenDragArtwork = !this.editorService.lockCameraWhenDragArtwork;
		if(this.editorService.lockCameraWhenDragArtwork){
			this.editorService.updateLogActivity('Lock Camera movement when drag figure')
		}else{
			this.editorService.updateLogActivity('Unlock Camera movement when drag figure')
		}
	}

  /**
   * ANCHOR Focus On Artwork
   * @description: to focus on artwork
   */
	public focusOnArtwork(): void {
		this.editorService.focusOnArtworkAnimation(this.editorService.activeArtworkNode);
	}

  //#endregion
}
