import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments';
import { CreateTextToSpeech, StreamUrlArtwork } from '../interfaces/create-text-to-speech';
import { Artwork } from '../interfaces/artwork';

@Injectable({
  providedIn: 'root'
})
export class TextToSpeechService {
  public streamUrlExhibitioin: string = '';
  public streamUrlArtwork: StreamUrlArtwork[] = [];

  constructor(
    private _http: HttpClient
  ) { }

  public createTextToSpeech(text: string, sid: string) {
    const payload: CreateTextToSpeech = {
			"text": text,
			"voice": "en-US-JennyNeural",
			"platform" : "villume",
			"sid" : sid
		}
    return this._http.post(environment.tts_url, payload);
  }

  public createTextToSpeechArtwork(param: {sid: string, artworks: Artwork[]}): Promise<StreamUrlArtwork[]> {
    const { sid, artworks } = param;
    const promises: Promise<any>[] = [];
    this.streamUrlArtwork = [];
  
    artworks.forEach((artwork) => {
      if (artwork.edited_description && artwork.description.length > 0) {
        const requestPromise = this.createTextToSpeech(artwork.description, sid).toPromise();
        promises.push(requestPromise);
        requestPromise.then((res: any) => {
          this.streamUrlArtwork.push({
            id: artwork.id,
            url: res['data'].stream
          });
        }).catch((err: any) => {
          console.error(err);
        });
      } else if (artwork.edited_description && artwork.description.length === 0) {
        this.streamUrlArtwork.push({
          id: artwork.id,
          url: null
        })
        promises.push(Promise.resolve(null));
      } else {
        this.streamUrlArtwork.push({
          id: artwork.id,
          url: artwork.tts_path
        })
        promises.push(Promise.resolve(null));
      }
    });
  
    return Promise.all(promises).then(() => {
      return this.streamUrlArtwork;
    });
  }

  public async createTextToSpeechExhibition(text: string, sid: string, edited:boolean=false): Promise<any> {
    return new Promise((resolve, reject) => {
      if (edited && text.length === 0) {
        this.streamUrlExhibitioin = null;
        resolve(null);
      } else if (edited && text.length > 0){
        this.createTextToSpeech(text, sid).subscribe({
          next: (res: any) => {
            this.streamUrlExhibitioin = res['data'].stream;
            resolve(res);
          },
          error: (err: any) => {
            console.error(err);
            reject(err);
          }
        });
      } else {
        resolve(null);
      }
    });
  }
}
