// Angular packages
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';

// User-defined services
import { EditorService } from '../../editor.service';
import { ArtworkShadowsService, store } from 'src/app/shared/services';

// Third-party packages
import watch from 'redux-watch';
import { FormsModule } from '@angular/forms';
import { SliderModule } from 'primeng/slider';
import { InputNumberComponent } from '../../../../../components/input-number/input-number.component';

@Component({
    selector: 'app-rotation-image',
    templateUrl: './rotation-image.component.html',
    styleUrls: ['./rotation-image.component.scss'],
    standalone: true,
    imports: [InputNumberComponent, SliderModule, FormsModule]
})
export class RotationImageComponent implements OnInit, OnDestroy, OnChanges {
  @Input() rotate: any;

  public rotateX: number;
	public rotateY: number;
	public rotateZ: number;

  private _selectObjectWatchSubscription: any;

  constructor(
    public editorService: EditorService,
    private _artworkShadowsService: ArtworkShadowsService
  ) { 
    this._watchSelectedObjectState();
  }

  ngOnInit(): void {
    this.initRotationValues();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.rotate) {
      this.initRotationValues();
    }
  }

  ngOnDestroy(): void {
    this._selectObjectWatchSubscription();
  }

  /**
   * * ======================================================= *
   * * LIST OF FUNCTIONS
   * * ======================================================= *
   * - INIT ROTATION VALUES
   * - WATCH SELECTED OBJECT STATE
   * - ROTATE VIA INPUT
   * - ADJUST ROTATION
   */

  //#region

  /**
   * * INIT ROTATION VALUES *
   * Todo: for init rotation values
   */
  public initRotationValues(): void {
    this.rotateX = Math.round(this.rotate.x * (180/Math.PI))
		this.rotateY = Math.round(this.rotate.y * (180/Math.PI))
		this.rotateZ = Math.round(this.rotate.z * (180/Math.PI))
  }

  
  /**
   * * WATCH SELECTED OBJECT STATE *
   * Todo: to watch selected object state from redux store
   */
  private _watchSelectedObjectState(): void {
    const seleceObjectWatch = watch(store.getState, 'editor.objectHasSelected')
		this._selectObjectWatchSubscription = store.subscribe(seleceObjectWatch((e: boolean) => {
      if (e && this.editorService.activeArtworkNode) {
        this.initRotationValues();
      }
		}));
  }

  /**
   * * ROTATE VIA INPUT *
   * Todo: for rotate via input
   * @param value : number
   * @param axis : 'x' | 'y' | 'z'
   */
  public rotateViaInput(value: number, axis: 'x' | 'y' | 'z'): void {
		value = this.editorService.validateSliderManualInput(value, -180, 180);
    this.rotate[axis] = value  / (180/Math.PI);
    this.initRotationValues();
    this.updateRotationData();
  }

  /**
	 * * UPDATE ROTATION DATA *
	 * Todo: to update rotation data
	 */
	public updateRotationData(): void {
    const { file_type } = this.editorService.activeArtworkNode.metadata.artworkData;
		if(file_type !== 'figure-object') {
			this._artworkShadowsService.setEnableShadowCast(this.editorService.activeArtworkNode, false);
			this._artworkShadowsService.setEnableShadowComponents(this.editorService.activeArtworkNode, false);
		}

    this.editorService.markForUpdate(this.editorService.activeArtwork, 'transform');
		this.editorService.updateArtworkData(
			this.editorService.activeArtworkNode,
			this.editorService.activeArtwork,
			true,
			true
		)
		this.editorService.dataHasChanges = true;
		this.editorService.updateLogActivity("Update artwork rotation");
	}

  //#endregion
}
