import { Component, OnInit } from '@angular/core';
import { InvoicesComponent } from './invoices/invoices.component';
import { PaymentMethodComponent } from './payment-method/payment-method.component';
import { BillingInfoComponent } from './billing-info/billing-info.component';
import { PricingPlanComponent } from './pricing-plan/pricing-plan.component';

@Component({
    selector: 'app-billing',
    templateUrl: './billing.component.html',
    styleUrls: ['./billing.component.scss'],
    standalone: true,
    imports: [
        PricingPlanComponent,
        BillingInfoComponent,
        PaymentMethodComponent,
        InvoicesComponent,
    ],
})
export class BillingComponent implements OnInit {
	constructor() { }

	ngOnInit(): void {}
}
