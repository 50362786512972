// Angular modules/components
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// User-defined services
import { MainService } from "src/app/shared/services";
import { LayoutsService } from 'src/app/layouts/layouts.service';
import { ContactService } from '../contact/contact.service';
import { PricingService } from './pricing.service';

// Third-party plugins (CDN)
declare let $: any;

// User-defined interfaces/classes
import { PricingProduct } from '@interfaces';
import { SubscriptionOverview } from '../settings/billing/billing.interfaces';
import { environment } from '@environments';
import { AlertMessageService } from 'src/app/components/alert-message/alert-message.service';
import { DialogModule } from 'primeng/dialog';
import { FaqComponent } from '../../components/faq/faq.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NgIf, NgFor } from '@angular/common';
import { LayoutsComponent } from '../../layouts/layouts.component';

@Component({
    selector: 'app-pricing',
    templateUrl: './pricing.component.html',
    styleUrls: ['./pricing.component.scss'],
    standalone: true,
    imports: [LayoutsComponent, NgIf, ProgressSpinnerModule, NgFor, FaqComponent, DialogModule]
})
export class PricingComponent implements OnInit {
	public displayAnnouncement: boolean = false;
  public pricingProducts: PricingProduct[] = [];
  public currentSubscription: SubscriptionOverview;
	public displayDowngrade: boolean = false;
	public displayDowngradeInterval: boolean = false;
	public loading: boolean = false;

	constructor(
		private router: Router,
		public mainService: MainService,
		public pricingService: PricingService,
		public contactService: ContactService,
		private messageService: AlertMessageService,
	) {}

	ngOnInit() {
		this.mainService.loadScripts([
			environment.staticAssets+'plugins/jquery/jquery-3.4.1.min.js?t='+this.mainService.appVersion,
			environment.staticAssets+'plugins/match-height/jquery.matchHeight-min.js?t='+this.mainService.appVersion,
			environment.staticAssets+'plugins/owl-carousel/owl.carousel.min.js?t='+this.mainService.appVersion,
    	]).then(() => {
			this._getProducts();
		})
	}

	/**
	 * * GET PRODUCTS *
	 * Todo: to get products
	 */
	private _getProducts(): void {
		this.loading = true;
		this.pricingService.getProducts().subscribe(async (res) => {
			if(this.mainService.isBrowser) {
				if (this.mainService.userInfo) {
					try {
						await this._getCurrentSubscription();
					} catch (error) {
						this.currentSubscription = null;
					}
				}

				this.pricingProducts = this._processProductData(res);
				setTimeout(()=> {
					this.runMatchHeight();
					this._dragItemPrice();
					this.loading = false;
				}, 500)
			}
		})
	}

	public isActiveProduct(product: PricingProduct): boolean {
		if (this.currentSubscription && this.mainService.userInfo) {
			if(product.name !== 'Experimental') {
				const activeInterval = this.pricingService.isYearly ? 'yearly' : 'monthly';
				return product.isActive && product.activeInterval === activeInterval;
			} else {
				return this.currentSubscription.product === 'Experimental';
			}
		} else {
			return product.name === 'Experimental';
		}
	}

	/**
	 * * GET CURRENT PRODUCT *
	 * Todo: to get current product of subscription
	 */
	private _getCurrentSubscription(): Promise<any> {
		return new Promise((resolve, reject) => {
			this.pricingService.getSubscriptionOverview().subscribe((res) => {
				this.currentSubscription = res.data.product_detail;
				resolve(null);
			}, err => {
				reject(null);
			})
		})
	}

	/**
   * * CREATE PRICE DATA FOR DISPLAY *
   * Todo: to create price data for display
   */
	private _displayPrice(price: string) {
		const newPriceStr = price.replace('$','').replace(',','.');
		const priceNum = Number(newPriceStr);
		return `$${Math.round(priceNum)}`;
	}
	

	/**
	 * * PROCESS PRODUCT DATA *
	 * Todo: to process product data
	 * @param res : Http response
	 * @returns : PricingProduct[]
	 */
	private _processProductData(res: any): PricingProduct[] {
		return res.data.product.map((product: PricingProduct) => {
			product.buttonColor = 'btn-light';
			product.buttonLink = `/pricing/${product.name.toLowerCase()}`
			product.buttonDisplay = true;
			product.buttonLoading = false;
		
			// Update price value
			product.price_list.monthly.amount = this._displayPrice(product.price_list.monthly.amount);
			if (product.price_list.yearly) {
				product.price_list.yearly.amount = this._displayPrice(product.price_list.yearly.amount);
				product.price_list.yearly.per_monthly = this._displayPrice(product.price_list.yearly.per_monthly);
			}
		
			// only for development
			product.buttonText = 'Subscribe';
			product.buttonDisabled = false;

			// update action ultimate product
			if (product.name === 'Ultimate') {
				product.buttonText = 'Contact Us';
				product.buttonLink = '/contact';
			}

			if(this.currentSubscription) {
				if(this.currentSubscription.product == product.name) {
					product.isActive = true;
					product.activeInterval = this.currentSubscription.interval;
				}
			} else {
				if (product.name === 'Experimental') {
					product.isActive = true;
				}
			}
			
	
			// Uncoment this if in 'production '
			// ---------------------------------------
			// product.buttonText = 'Coming Soon';
			// product.buttonDisabled = true;
	

			return product;
		});
	}

	/**
	 * * RUM MATCH HEIGHT *
	 * Todo: to run "matchHeight" plugins
	 */
	runMatchHeight(): void {
		const el = $('.item-pricing');
		if (el.matchHeight) {
			el.matchHeight({
				byRow: false,
				property: 'height',
				target: null,
				remove: false
			});
		}
	}

	/**
	 * * SUBSCRIBE *
	 * Todo: to direct user to supcription page
	 */
	subscribe(product: PricingProduct) {
		if(this.mainService.userInfo){
			if(this.isActiveProduct(product)) {
				this.mainService.subscribeExperiment = false;
				this.router.navigate(['/settings/billing']);
			} else {
				if(product.name === 'Experimental') {
					this.mainService.subscribeExperiment = true;
					this.router.navigate(['/settings/billing']);
				} else {
					if(this._isSameProduct(product) && this._isFromYearlyToMonthly(product)) {
						this.displayDowngradeInterval = true;
					} else if(this._isDowngrade(product)) {
						this.displayDowngrade = true;
					} else {
						product.buttonLoading = true;
						const productPayment = `${product.name.toLowerCase()}_${this.pricingService.isYearly ? 'yearly' : 'monthly'}`;
						const productData = {
							payment: productPayment,
							success_url: `${environment.base_host}/save-invoices`,
							cancel_url: `${environment.base_host}/pricing`,
						}

						// create stripe checkout session
						this.pricingService.subscribeProduct(productData).subscribe((res)=>{
							product.buttonLoading = false;
							const url = res['data'].link;
							window.open(url, '_self');
						}, (err) => {
							product.buttonLoading = false;
							this.messageService.add({
								severity: 'error',
								summary: 'Error',
								detail: 'Something went wrong. Please try again.'
							});
						})
					}
				}
			}
		}else{
			this.mainService.displayLoginDialog = true
		}
	}

	/**
	 * * IS DOWNGRADE *
	 * Todo: to check if user is downgrade
	 * @param product : PricingProduct
	 * @returns : boolean
	return this._isFromYearlyToMonthly(product);
	 */
	private _isDowngrade(product: PricingProduct): boolean {
		const currentProductIdx = this._getProductIndex(this.currentSubscription.product)
		const newProductIdx = this._getProductIndex(product.name);
		return currentProductIdx > newProductIdx;
	}


	/**
	 * * GET PRODUCT INDEX *
	 * Todo: to get product index
	 * @param productName : string
	 * @returns : number
	 */
	private _getProductIndex(productName: string): number {
		return this.pricingProducts.findIndex((item) => {
			return item.name === productName;
		});
	}

	/**
	 * * IS SAME PRODUCT *
	 * Todo: to check if product is same
	 * @param product : PricingProduct
	 * @returns : boolean
	 */
	private _isSameProduct(product: PricingProduct): boolean {
		return this.currentSubscription.product === product.name;
	}

	/**
	 * * IS FROM YEARLY TO MONTHLY *
	 * Todo: is form yearly to monthly
	 * @param product 
	 * @returns 
	 */
	private _isFromYearlyToMonthly(product: PricingProduct): boolean {
		return this.currentSubscription.interval === 'yearly' && !this.pricingService.isYearly;
	}

	/**
	 * * IS ULTIMATE PRODUCT *
	 * Todo: to get product
	 */
	public isUltimateProduct(product: PricingProduct): boolean {
		return product.name === 'Ultimate';
	}

	/**
	 * * PRODUCT ULTIMATE *
	 * Todo: to direct user to contact page
	 */
	public getUltimateProduct(product: PricingProduct): void {
		if (product.name === 'Ultimate') {
			this.contactService.formType = 'custom';
			this.contactService.fromPricing = true;
			this.router.navigateByUrl('/contact');
		}
	}

	private _dragItemPrice() {
		const wrapItem = document.getElementById('wrap-item');
		wrapItem.style.cursor = 'grab';

		let position = { top: 0, left: 0, x: 0, y: 0 };

		const mouseDownHandler = (e: { clientX: any; clientY: any; }) => {
				wrapItem.style.cursor = 'grabbing';
				wrapItem.style.userSelect = 'none';

				position = {
						left: wrapItem.scrollLeft,
						top: wrapItem.scrollTop,
						x: e.clientX,
						y: e.clientY,
				};

				document.addEventListener('mousemove', mouseMoveHandler);
				document.addEventListener('mouseup', mouseUpHandler);
		};

		const mouseMoveHandler = (e: { clientX: number; clientY: number; }) => {
				const dx = e.clientX - position.x;
				const dy = e.clientY - position.y;

				wrapItem.scrollTop = position.top - dy;
				wrapItem.scrollLeft = position.left - dx;
		};

		const mouseUpHandler = function () {
				wrapItem.style.cursor = 'grab';
				wrapItem.style.removeProperty('user-select');

				document.removeEventListener('mousemove', mouseMoveHandler);
				document.removeEventListener('mouseup', mouseUpHandler);
		};

		wrapItem.addEventListener('mousedown', mouseDownHandler);
	}
}
