// Angular core
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";

// User-defined service
import { MainService } from 'src/app/shared/services';
import { SettingsService } from '../settings.service';
import { LayoutsService } from 'src/app/layouts/layouts.service';
import { AlertMessageService } from 'src/app/components/alert-message/alert-message.service';

// Third-party plugins (npm)
import md5 from 'md5';
import moment from 'moment';

// Data
import { messages } from '@data';
import { DialogModule } from 'primeng/dialog';
import { RouterLink } from '@angular/router';
import { DropdownModule } from 'primeng/dropdown';
import { NgIf, NgStyle } from '@angular/common';
import { InputDateComponent } from '../../../components/input-date/input-date.component';
import { Timezone } from '@interfaces';

@Component({
    selector: 'app-account-setting',
    templateUrl: './account-setting.component.html',
    styleUrls: ['./account-setting.component.scss'],
    standalone: true,
    imports: [
        InputDateComponent,
        NgIf,
        DropdownModule,
        FormsModule,
        ReactiveFormsModule,
        RouterLink,
        NgStyle,
        DialogModule
    ],
})
export class AccountSettingComponent implements OnInit {
  public loading:boolean = false;
  public formPassword: FormGroup;
  public selectedGender: 'male' | 'female' | 'non-binary' = 'non-binary';
  public showChangePasswordForm: boolean = false;
  public havePassword: boolean = false;
  public displayConfirmDelete: boolean = false;
  
  public birth_date: string;
  public birth_month: string;
  public birth_year: string;
  public birth: any;
  public birthDateValid: boolean;

  public timeZones: Timezone[] = [];
  public selectedTimeZone: Timezone;

  @ViewChild('oldPassword') oldPassword: ElementRef;


  constructor(
    public mainService:MainService,
    private settingsService:SettingsService,
    private alertMessageService:AlertMessageService,
    private layoutService: LayoutsService,
  ) {}

  ngOnInit(): void {
    this.checkUserHavePassword();
    this.setSelectedGender();
    this._initChangePasswordForm();
    if (this.mainService.isBrowser) {
      this._setBirthDateData();
      this._getListTimezone();
    }
  }

  /**
   * * ============================================================================= *
   *   SECTION Change Password Functions
   * * ============================================================================= *
   */
  //#region
  /**
   * ANCHOR Check User Have Password
   * @description Check whether the user have password or not
   * @param refetch 
   */
  public checkUserHavePassword(refetch:boolean=false): void {
    this.settingsService.checkUserHavePassword(refetch).subscribe({
      next: (res: any) => {
          this.havePassword = res.data.is_password;
      }, 
      error: (err: any) => {}
    })
  }

  /**
   * ANCHOR Open Change Password Form
   * @description Open the change password form
   */
  public openChangePasswordForm(): void {
    this.formPassword.reset();
    this.showChangePasswordForm = true;
    this.setOldPasswordValidations();

    // set width form password
    setTimeout(() => {
      const npasswd = document.querySelector('#npassword') as HTMLElement;
      const cpassed = document.querySelector('#cpassword') as HTMLElement;
      npasswd.style.width = `${this.oldPassword.nativeElement.offsetWidth}px`;
      cpassed.style.width = `${this.oldPassword.nativeElement.offsetWidth}px`;
    }, 10);
  }

  /**
   * ANCHOR Set Old Password Validations
   * @description Set the old password validations
   */
  public setOldPasswordValidations(): void {
    if(this.havePassword){
      this.formPassword.get("oldPassword").setValidators([
        Validators.minLength(6), 
        Validators.required
      ])
    }else{
      this.formPassword.get("oldPassword").clearValidators()
    }
    this.formPassword.get("oldPassword").updateValueAndValidity()
  }

  /**
   * ANCHOR Check Confirm Password
   * @param group : FormGroup
   * @returns : null | { notSame: true }
   */
  public checkConfirmPassword(group: FormGroup): null | { notSame: true } {
		let newPassword = group.controls.newPassword.value;
		let confirmPassword = group.controls.confirmPassword.value;
		return confirmPassword === newPassword ? null : { notSame: true }
	}

  /**
   * ANCHOR Init Change Password Form
   * @description Initialize the change password form
   */
  private _initChangePasswordForm(): void {
    this.formPassword = new FormGroup({
      oldPassword: new FormControl(""), 
      newPassword: new FormControl("",[
        Validators.minLength(6), 
        Validators.required
      ]), 
      confirmPassword: new FormControl("",[
        Validators.required
      ])
    },{ validators: this.checkConfirmPassword });
  }

  /**
   * ANCHOR Change Password
   * @description Change the password
   * @returns : void
   */
  public changePassword(): void {
    const oldPassword = this.formPassword.value.oldPassword;
    const newPassword = this.formPassword.value.newPassword;
    const confirmPassword = this.formPassword.value.confirmPassword;

    // Validate old password
    if (this.havePassword && this.mainService.userInfo.password != md5(oldPassword)) {
      this.alertMessageService.add({ 
        severity: 'error', 
        summary: 'Failed', 
        detail: messages.updatePassword.wrongOldPassword 
      });
      return;
    }

    // Validate new password and confirm password
    if(newPassword != confirmPassword) {
      this.alertMessageService.add({ 
        severity: 'error', 
        summary: 'Failed', 
        detail: messages.updatePassword.notSamePassword 
      });
      return;
    }

    // Update password
    this.loading = true;
    const encriptPassword = md5(newPassword);
    this.settingsService.updateUserPassword(encriptPassword).subscribe((res: any) => {
      this.loading = false;

      // Success
      if (res['data']) {
        this.alertMessageService.add({ 
          severity: 'success', 
          summary: 'Success', 
          detail: messages.updatePassword.success
        });
        this.mainService.userInfo.password = encriptPassword;
        this.havePassword = true;
        this.showChangePasswordForm = false;
        this.checkUserHavePassword(true);
      } 
      
      // Failed
      else {
        if (res.errors['0'].message == "Malformed Authorization header") {
          this.mainService.expiredSesionPopup = true;
        } else {
          this.alertMessageService.add({ 
            severity: 'error', 
            summary: 'Failed', 
            detail: messages.updatePassword.failed
          });
        }
      }
    }, err => {
      this.loading = false;
      this.alertMessageService.add({ 
        severity: 'error', 
        summary: 'Failed', 
        detail: messages.updatePassword.failed
      });
    })
  }
  //#endregion
  //!SECTION

  /**
   * * ============================================================================= *
   *    SECTION Birth Date & Timezone Functions
   * * ============================================================================= * 
   */
  //#region
  /**
   * ANCHOR Set Birth Date Data
   * @description Set the birth date data
   */
  private _setBirthDateData(){
    this.mainService.userInfo.birth_date = this.mainService.userInfo.birth_date || moment().format("YYYY-MM-DD");
    const birthDate = moment(this.mainService.userInfo.birth_date,"YYYY-MM-DD");
    this.birth_date = birthDate.format("DD");
    this.birth_month = birthDate.format("MM");
    this.birth_year = birthDate.format("YYYY");
    this.birth = this.mainService.userInfo.birth_date;
  }

  /**
   * ANCHOR Change Date
   * @description Change the date
   * @param e : any
   */
  public changeDate(e){
    if(e.isvalid){
      this.birth = e.date;
      this._validateBirthDate();
      let birth_day = moment(this.birth,"YYYY-MM-DD");
      this.birth_date = birth_day.format("DD");
      this.birth_month = birth_day.format("MM");
      this.birth_year = birth_day.format("YYYY");
    }
  }	

  /**
   * ANCHOR Validate Birth Date
   * @description Validate the birth date
   */
  private _validateBirthDate(){
    // Create birth date and current date
    const currentDate = moment(moment().format("YYYY-MM-DD"));
    const birthDate = moment(this.birth, "YYYY-MM-DD");

    // Check whether the birth date is valid or not
    this.birthDateValid = birthDate.isSameOrBefore(currentDate);
    if(this.birthDateValid) this.birthDateValid = true;
    else this.birthDateValid = false;
  }

  /**
   * ANCHOR Get List Timezone
   * @description Get the list of timezones
   */
  private _getListTimezone(): void {
    if (this.mainService.isBrowser) {
      this.settingsService.getTimezoneList().subscribe((res:any) => {
        for (let i = 0; i < res.length; i++) {
          this.timeZones.push({label: res[i], value: res[i]});
        }
        const timezone:string = this.mainService.userInfo.user_details[0].timezone || "Europe/London";
        this.selectedTimeZone = {label: timezone, value: timezone};
      });
    }
  }
  //#endregion
  //!SECTION

  /**
   * * ============================================================================= *
   *   SECTION Uncategorized Functions
   * * ============================================================================= *
   */
  //#region
  /**
   * ANCHOR Set Selected Gender
   * @description Set the selected
   */
  public setSelectedGender(): void {
    const userGender = this.mainService.userInfo?.gender;
    if (['male','female'].includes(userGender)) {
      this.selectedGender = userGender;
    } else {
      this.selectedGender = 'non-binary';
    }
  }

  /**
   * ANCHOR Save Changes
   * @description Save the changes
   */
  public saveChanges(): void {
    const body = {
      birth_date: this.birth,
      gender: this.selectedGender,
      timezone: this.selectedTimeZone.value
    }
    this.loading = true;
    this.settingsService.updateAccountSetting(body).subscribe((res: any) => {
      this.mainService.userInfo.gender = this.selectedGender;
      this.mainService.userInfo.birth_date = this.birth;
      this.alertMessageService.add({ 
        severity: 'success', 
        summary: 'Success', 
        detail: messages.updateAccount.success
      });
      this.loading = false;
    }, err => {
      this.loading = false;
      if(err.error.statusCode==401) this.mainService.expiredSesionPopup = true;
      else{
        this.alertMessageService.add({ severity: 'error', summary: 'Failed', detail:  'Update account' });
      }
    })
  }

  /**
   * ANCHOR Delete Account
   * @description Delete the account
   */
  public deleteAccount(): void {
    this.loading = true;
    this.settingsService.deleteAccount().subscribe(res =>{
      this.layoutService.updateToken("").subscribe(()=>{
        this.alertMessageService.add({ 
          severity: 'success', 
          summary: 'Success', 
          detail: messages.deleteAccount.success
        });
        setTimeout(() => window.location.href = "/delete-account", 2000);
      })
    }, err =>{
      this.loading = false;

      if(err.name == "TimeoutError") this.deleteAccount();
      else if(err.error.statusCode==401) this.mainService.expiredSesionPopup = true;
      else{
        this.alertMessageService.add({ severity: 'error', summary: 'Failed', detail:  'Delete account' });
      }
    });
  }

  /**
   * ANCHOR Check Support Account
   */
  public isSupportAccount(): boolean {
    if (this.mainService.userInfo?.user_type === "support") return true;
    else return false;
  }

  //#endregion
  //!SECTION
} 
