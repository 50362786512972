<app-layouts>
  <div class="wrapper success" content>
      <div class="success__content">
          <h1 class="success__title">
              Hooray! Your payment has been processed successfully!
          </h1>
          <p class="success__subtitle">
              We’ve sent you a confirmation email
          </p>
          <button routerLink="/{{mainService.userInfo?.username}}" class="btn btn-primary btn-sm">Got it</button>
      </div>
  </div>
</app-layouts>