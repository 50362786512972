import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PromocodesService } from '../promocodes.service';
import { AlertMessageService } from 'src/app/components/alert-message/alert-message.service';
import { dateLessThanToday, dateGreaterThan, notEmptyObject, validDate } from '@custom-validators';
import { AddPromoCodeParams, EditPromoCodeParams, PromoCode, ListProducts, ProductId, originalListUsers } from '../promocodes.interfaces';
import moment from 'moment';
import { messages } from '@data';
import { DropdownModule } from 'primeng/dropdown';
import { NgIf } from '@angular/common';
import { LayoutsComponent } from '../../../layouts/layouts.component';

@Component({
    selector: 'app-promocode-form',
    templateUrl: './promocode-form.component.html',
    styleUrls: ['./promocode-form.component.scss'],
    standalone: true,
    imports: [LayoutsComponent, FormsModule, ReactiveFormsModule, NgIf, DropdownModule]
})
export class PromocodeFormComponent implements OnInit {
  public discountTypes: any = [
    { label: 'Percentage', value: 'percentage' },
    { label: 'Amount', value: 'amount' },
  ];
  public userAction: 'edit' | 'add';
  public code: string;
  public promo_id: string;
  public promoCodeForm: FormGroup;
  public promoCodeDetails: PromoCode;
  public discountPlaceholder: string = 'Percent Off';
  public loading: boolean = false;
  public productInterval: any = [
    { label: 'Monthly', value: 'monthly' },
    { label: 'Yearly', value: 'yearly' },
    { label: 'Monthly and Yearly', value: 'both'}
  ];
  public productTypes: ListProducts[];
  public originalListUsers: originalListUsers[];
  public limitedPeriod: string = 'month(s)'
  public showUserList: boolean = false;
  public selectedUser: any = [];
  public listUserForSelect: any = [];
  public showSelected: boolean = false;
  public pointerOnList: boolean = false;
  public selectedUserEmail: string = '';


  constructor(
    private _promocodeService: PromocodesService,
    private _messageService: AlertMessageService,
    private _route: ActivatedRoute,
    private _router: Router
  ) {
    this.userAction = this._route.snapshot.data.action; 
    if (this.userAction == 'edit') {
      this.code = this._getCodeFromRoute('code');
      this.promo_id = this._getCodeFromRoute('promo_id');
    }
  }

  async ngOnInit(): Promise<void> {
    this._initializeForm();
    this.updateValidators();
    const promises = [this._getListProductForSupport()];
    if(this.userAction == 'add') promises.push(this._getListUserForSupport());
    await Promise.all(promises);
    if(this.userAction == 'edit') this._getPromoCodeDetails(true);
  }


  /**
	 * * ================================================================================================ *
	 *   SECTION Edit Promo Code Functions
	 * * ================================================================================================ *
	 */

  //#region

  /**
   * * CREATE EDIT PROMO CODE PAYLOAD *
   * ANCHOR Create edit promo code payload
   * @description to create edit promo code payload
   * @returns : EditPromoCodeParams
   */
  private _createEditPromoCodePayload(): EditPromoCodeParams {
    const payload: EditPromoCodeParams = {
      name: this.promoCodeForm.controls.name.value.trim(),
      code: this.code,
      promo_id: this.promo_id
    }
    return payload;
  }

  /**
   * * EDIT PROMO CODE *
   * ANCHOR Edit Promo Code
   * @description to edit promo code
   */
  private _editPromoCode(): void {
    if (this.promoCodeForm.valid) {
      const payload = this._createEditPromoCodePayload();
      this._enableLoading(true);
      this._promocodeService.editPromoCode(payload).subscribe((res: any) => {
        this._messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: messages.promoCodes.edit.success
        })
        setTimeout(() => {
          this.redirectToPromoCodeList();
          this._enableLoading(false);
        }, 2000)
      }, error => {
        this._enableLoading(false);
        this._messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.data.errors.messages
        })
      })
    }
  }

  //#endregion
  // !SECTION

  








  /**
	 * * ================================================================================================ *
	 *   SECTION Add Promo Code Functions
	 * * ================================================================================================ *
	 */

  //#region 

  /**
   * * ADD PROMO CODE *
   * ANCHOR Add Promo Code
   * @description: to add promo code
   */
  private _addPromoCode(): void {
    if(this.promoCodeForm.valid) {
      const payload = this._createAddPromoCodePayload();
      this._enableLoading(true);
      this._promocodeService.addPromoCode(payload).subscribe((res: any) => {
        this._messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: messages.promoCodes.add.success
        })
        setTimeout(() => {
          this.redirectToPromoCodeList();
          this._enableLoading(false);
        }, 1000)
      }, error => {
        this._enableLoading(false);
        this._messageService.add({
          severity: 'error',
          summary: 'Failed',
          detail: error.error.data.errors.messages
        })
      });
    }
  }

  /**
   * * CREATE ADD PROMO CODE PAYLOAD *
   * ANCHOR Create Add Promo Code Payload
   * @description: to create add promo code payload
   */
  private _createAddPromoCodePayload(): any {
    const discountType = this.promoCodeForm.get('discountType').value.value;
    const discount = this._getDiscountValue()
    const payload: AddPromoCodeParams = {
      name: this.promoCodeForm.get('name').value,
      amount_off: discountType === 'amount' ? discount : null,
      percent_off: discountType === 'percentage' ? String(discount) : null,
      duration: this._getDuration(),
      duration_in_months: this._getDurationInMonths(),
      max_redemptions: this.promoCodeForm.get('maxRedemptions').value || null,
      expires_on: this._getExpires(),
      metadata: this._getMetadata(),
      allowed_user: this.selectedUserEmail || null,
    }

    return payload;
  }

  private _getDiscountValue() {
    const discountType = this.promoCodeForm.get('discountType').value.value;
    let value = this.promoCodeForm.get('discount').value;
    if(discountType === 'amount') {
      const hasDecimal = value.split('.')[1];
      if(!hasDecimal) value += '.00';
      if(hasDecimal && hasDecimal.length === 1) value += '0';
      return Number(value.replace(/[,\.]/g, ""));
    } else {
      return Number(value);
    }
  }

  /**
   * * GET EXPIRES *
   * ANCHOR Get Expires
   * @description: to get expires
   * @returns : string | null
   */
  private _getExpires(): string {
    if(this.promoCodeForm.get('expires').value) {
      if(moment(this.promoCodeForm.get('expires').value, 'DD-MM-YYYY').format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
        return moment(this.promoCodeForm.get('expires').value, 'DD-MM-YYYY').format('YYYY-MM-DD 23:59:59');
      }
      return moment(this.promoCodeForm.get('expires').value, 'DD-MM-YYYY').format('YYYY-MM-DD 00:00:00');
    }
    return null;
  }

  /**
   * *  GET DURATION *
   * ANCHOR Get Duration
   * @description: to get duration
   * @returns: 'once' | 'forever' | null
   */
  private _getDuration(): 'once' | 'forever' | 'repeating' {
    if(this.promoCodeForm.get('durationOnce').value) {
      return 'once';
    } 
    if(this.promoCodeForm.get('durationForever').value) {
      return 'forever';
    }
    if(this.promoCodeForm.get('durationRepeating').value) {
      return 'repeating';
    }
  }

  /**
   * * GET DURATION IN MONHTS *
   * ANCHOR Get Duration In Months
   * @description: to get duration in months
   * @returns 
   */
  private _getDurationInMonths(): number {
    if(this.promoCodeForm.get('durationRepeating').value) {
      if (this.promoCodeForm.get('billingPeriod').value.value === 'yearly') {
        return (this.promoCodeForm.get('durationRepeatingValue').value * 12)
      } else {
        return this.promoCodeForm.get('durationRepeatingValue').value;
      }
    }
    return null;
  }

  /**
   * * GET METADATA  *
   * ANCHOR Get Metadata Aplicable Product
   * @description: to get metadata applicable product
   * @returns 
   */
  private _getMetadata (): any {
    const product = this.promoCodeForm.get('productType').value;
    const interval = this.promoCodeForm.get('billingPeriod').value.value;

    const productId: ProductId = product.product_id;
    const name: string = product.name.toLowerCase();

    if (product && name != 'all') {
      if (interval != 'both') {
        // for 1 product type and 1 interval
        return {applicable_products: `${name}_${interval}:${productId[interval]}`}
      } else {
        // for 1 product type and both interval
        return {applicable_products: `${name}_monthly:${productId['monthly']},${name}_yearly:${productId['yearly']}`}
      }

    } else if (product && name == 'all') {
      // for all product type
      let metadata:any[]=[];
      this.productTypes.map((product) => {
        if (product.name != 'All') {
          if (interval != 'both') {
            // for all product type and 1 interval
            metadata.push(`${product.name.toLowerCase()}_${interval}:${this._getProductId(product.name,interval)}`);
          } else {
            // for all product type and both interval
            metadata.push(`${product.name.toLowerCase()}_yearly:${this._getProductId(product.name,'yearly')}`);
            metadata.push(`${product.name.toLowerCase()}_monthly:${this._getProductId(product.name,'monthly')}`);
          }
        }
      });
      return { applicable_products: metadata.join(',') }
    }
    return null;
  }

  /**
   * * GET PRODUCT ID FOR METADATA  *
   * ANCHOR Get Product ID for metadata
   * @description: to get product id for metadata
   * @returns 
   */
  private _getProductId(name:string,period:'monthly'|'yearly'): string {
    return this.productTypes.find((product) => product.name === name).product_id[period];
  }

  /**
   * * GET ALLOWED USER  *
   * ANCHOR Get Allowed User
   * @description: to set promo code only for allowed user
   * @returns 
   */
  private _getAllowedUser (): any {
    const allowedUser = this.selectedUser;
    
    if (allowedUser && allowedUser.length > 0) {
      return allowedUser.map((user: any) => user.email);
    };

    return null;
  }

  //#endregion
  // !SECTION
  









  /**
	 * * ================================================================================================ *
	 *   SECTION Set/Update Validators Functions
	 * * ================================================================================================ *
	 */

  //#region

  /**
   * * UPDATE VALIDATORS *
   * ANCHOR Update Validators
   * @description: to update validators
   */
  public updateValidators(): void {  
    this._setNameValidators();
    this._setDiscountTypeValidators();
    this._setDiscountValidators();
    this._setDurationRepeatingValueValidators()
    this._setExpiresValidators();
    this._setMaxRedemptionsValidators();
    this._setProductTypeValidators();
    this._setBillingPeriodValidators();
  }


 /**
  * * SET NAME VALIDATORS *
  * ANCHOR Set Name Validators
  * @description: to set name validators
  */
  private _setNameValidators(): void {
    const nameControl = this.promoCodeForm.get('name');
    nameControl.clearValidators();
    nameControl.setValidators([
      Validators.required,
      Validators.maxLength(20),
      Validators.pattern(/^[a-zA-Z0-9 ]*$/)
    ]); 
    nameControl.updateValueAndValidity();
  }

  /**
  * * SET DICOUNT TYPE VALIDATORS *
  * ANCHOR Set Discount Type Validators
  * @description: to set name validators
  */
  private _setDiscountTypeValidators(): void {
    const discountTypeControl = this.promoCodeForm.get('discountType');
    discountTypeControl.clearValidators();
    discountTypeControl.setValidators([
      notEmptyObject()
    ]); 
    discountTypeControl.updateValueAndValidity();
  }

  /**
   * * SET DISCOUNT VALIDATORS *
   * ANCHOR Set Discount Validators
   * @description: to set discount validators
   */
  private _setDiscountValidators(): void {
    const discountControl = this.promoCodeForm.controls.discount;
    const discountTypeControl = this.promoCodeForm.controls.discountType;
    discountControl.clearValidators();
    if (discountTypeControl.valid) {
      discountControl.enable();
      const isPercentage = discountTypeControl.value.value === 'percentage';
      discountControl.setValidators([
        Validators.required,
        Validators.min(isPercentage ? 1 : 0),
        Validators.max(isPercentage ? 100 : 1000000),
      ]);
    } else {
      discountControl.disable();
    }
    discountControl.updateValueAndValidity();
  }

  /**
   * * SET DURATION REPEATING VALUE VALIDATORS *
   * ANCHOR Set Duration Repeating Value Validators
   * @description: to set duration repeating value validators
   */
  private _setDurationRepeatingValueValidators(): void {
    const durationRepeatingValueControl = this.promoCodeForm.controls.durationRepeatingValue;
    const durationRepeatingControl = this.promoCodeForm.controls.durationRepeating;
    durationRepeatingValueControl.clearValidators();
    if (durationRepeatingControl.value) {
      durationRepeatingValueControl.setValidators([
        Validators.required,
        Validators.min(1),
        Validators.max(999)
      ]);
    }
    durationRepeatingValueControl.updateValueAndValidity();
  }

  /**
   * * SET EXPIRES VALIDATORS *
   * ANCHOR Set Expires Validators
   * @description: to set expires validators
   */
  private _setExpiresValidators(): void {
    const maxDate = moment().add(5, 'year').endOf('year');
    const expiresControl = this.promoCodeForm.controls.expires;
    expiresControl.clearValidators();
    expiresControl.setValidators([
      validDate('DD-MM-YYYY'),
      dateLessThanToday('DD-MM-YYYY'),
      dateGreaterThan(maxDate, 'DD-MM-YYYY')
    ]);
    expiresControl.updateValueAndValidity();
  }

  /**
   * * SET MAX REDEMPTIONS VALIDATORS *
   * ANCHOR Set Max Redemptions Validators
   * @description: to set max redemptions validators
   */
  private _setMaxRedemptionsValidators(): void {
    const maxRedemptionsControl = this.promoCodeForm.controls.maxRedemptions;
    maxRedemptionsControl.setValidators([
      Validators.min(1),
      Validators.max(100000000)
    ]);
    maxRedemptionsControl.updateValueAndValidity();
  }

 /**
   * * SET PRODUCT TYPE VALIDATORS *
   * ANCHOR Set Product Type Validators
   * @description: to set product type validators
   */
  private _setProductTypeValidators(): void {
    const productTypeControl = this.promoCodeForm.get('productType');
    productTypeControl.clearValidators();
    productTypeControl.setValidators([
      Validators.max(100000000),
      notEmptyObject()
    ]); 
    productTypeControl.updateValueAndValidity();
  }

  /**
   * * SET PRODUCT TYPE VALIDATORS *
   * ANCHOR Set Product Type Validators
   * @description: to set product type validators
   */
  private _setBillingPeriodValidators(): void {
    const productTypeControl = this.promoCodeForm.get('billingPeriod');
    productTypeControl.clearValidators();
    productTypeControl.setValidators([
      notEmptyObject()
    ]); 
    productTypeControl.updateValueAndValidity();
  }

  //#endregion
  //!SECTION










  /** ================================================================================================ *
	 *   SECTION Set Promo Code Form Values Functions
	 * * ================================================================================================ *
	 */

  //#region

  /**
   * * SET PROMO CODE FORM VALUES *
   * ANCHOR Set Promo Code Form Values
   * @description: to set promo code form values
   */
  private _setPromoCodeFormValues(): void {
    this.promoCodeForm.get('name').setValue(this.promoCodeDetails.name);
    this._setProductTypeValue();
    this._setBillingPeriodValue();
    const discountType = this.discountTypes.find(x => x.value === this.promoCodeDetails.type_discount);
    this.promoCodeForm.get('discountType').setValue(discountType);
    this.promoCodeForm.get('discount').setValue(this.promoCodeDetails.discount);
    this.promoCodeForm.get('maxRedemptions').setValue(this.promoCodeDetails.max_redemptions);

    if (this.promoCodeForm.get('billingPeriod').value.value != 'yearly') {
      this.limitedPeriod = 'month(s)';
      this.promoCodeForm.get('durationRepeatingValue').setValue(this.promoCodeDetails.duration_in_months);
    } else {
      this.limitedPeriod = 'year(s)';
      this.promoCodeForm.get('durationRepeatingValue').setValue(parseInt(this.promoCodeDetails.duration_in_months) / 12);
    }

    const allowedUser = this.originalListUsers = [{
      username: this.promoCodeDetails.allowed_user,
      email: ''
    }]
    this.promoCodeForm.get('allowedUsers').setValue(allowedUser[0]);

    this.changeDuration(this.promoCodeDetails.duration, false);
    this._setExpiresValue();
    this.updateValidators();
    this._disableAllInput(['name']);
  }

  /**
   * * * SET PRODUCT TYPE AND BILLING PERIOD *
   * ANCHOR Set Product type and Billing period
   * @description: to set product type and billing period
   */
  private _setProductTypeValue(): void {
    const metadata = this.promoCodeDetails.metadata.applicable_products;
    
    // set product type
    if ( 
      metadata.includes(this.productTypes[1].name.toLocaleLowerCase()) &&
      metadata.includes(this.productTypes[2].name.toLocaleLowerCase()) &&
      metadata.includes(this.productTypes[3].name.toLocaleLowerCase())) {
      this.promoCodeForm.get('productType').setValue(this.productTypes.find(x => x.name === 'All'));
    } else if ( metadata.includes(this.productTypes[3].name.toLocaleLowerCase())) {
      this.promoCodeForm.get('productType').setValue(this.productTypes.find(x => x.name === this.productTypes[3].name));
    } else if ( metadata.includes(this.productTypes[2].name.toLocaleLowerCase())) {
      this.promoCodeForm.get('productType').setValue(this.productTypes.find(x => x.name === this.productTypes[2].name));
    } else if ( metadata.includes(this.productTypes[1].name.toLocaleLowerCase())) {
      this.promoCodeForm.get('productType').setValue(this.productTypes.find(x => x.name === this.productTypes[1].name));
    }
  }
  

  /**
   * * * SET BILLING PERIOD *
   * ANCHOR Set Billing period
   * @description: to set and billing period
   */
  private _setBillingPeriodValue(): void {
    const metadata = this.promoCodeDetails.metadata.applicable_products;

    // set billing period
    if ( metadata.includes('yearly') && metadata.includes('monthly')) {
      this.promoCodeForm.get('billingPeriod').setValue(this.productInterval.find(x => x.value === 'both'));
    } else if ( metadata.includes('yearly')) {
      this.promoCodeForm.get('billingPeriod').setValue(this.productInterval.find(x => x.value === 'yearly'));
    } else if ( metadata.includes('monthly')) {
      this.promoCodeForm.get('billingPeriod').setValue(this.productInterval.find(x => x.value === 'monthly'));
    }
  }
    
    /**
   * * SET EXPIRES VALUE *
   * ANCHOR Set Expires Value
   * @description: to set expires value
   */
  private _setExpiresValue(): void {
    if(!this.promoCodeDetails.expires) return;
    const expires = moment(this.promoCodeDetails.expires, 'YYYY-MM-DD').format('DD-MM-YYYY');
    this.promoCodeForm.get('expires').setValue(expires);
  }

  /**
   * * DISABLE ALL INPUT *
   * ANCHOR Disable All Input
   * @description: disable all input
   */
  private _disableAllInput(except: string[] = []): void {
    this.promoCodeForm.disable();
    except.forEach(control => {
      this.promoCodeForm.get(control)?.enable();
    })
  }
  
  //#endregion
  //!SECTION










  /**
	 * * ================================================================================================ *
	 *   SECTION Uncategorized Functions
	 * * ================================================================================================ *
	 */

  //#region

  /**
   * ANCHOR Redirect to Promo Code List
   * @description: to redirect to promo code list
   */

  public redirectToPromoCodeList(): void {
    localStorage.setItem('refetch-promocode', 'true');
    this._router.navigate(['/promocodes']);
  }

  /**
   * * INITIALIZE FORM *
   * ANCHOR Initialize Form
   * @description: to initialize form 
   */
  private _initializeForm(): void {
    this.promoCodeForm = new FormGroup({
      name: new FormControl(''),
      discountType: new FormControl({}),
      discount: new FormControl(''),
      durationOnce: new FormControl(true),
      durationForever: new FormControl(false),
      durationRepeating: new FormControl(false),
      durationRepeatingValue: new FormControl(''),
      expires: new FormControl(''),
      maxRedemptions: new FormControl(''),
      productType: new FormControl({}),
      billingPeriod: new FormControl({}),
      allowedUsers: new FormControl([]),
      searchUser: new FormControl('')
    });
  }

  /**
   * * GET CODE FROM ROUTE *
   * ANCHOR Get Code From Route
   * @description: to get code from route
   * @returns : string -> code
   */
  private _getCodeFromRoute(param:string): string {
    return this._route.snapshot.paramMap.get(param);
  }

  /**
   * * HANDLE SPACE AT START *
   * ANCHOR Handle Space At Start
   * @description: to handle space at start
   * @param type : string
   */
  public handleSpaceAtStart(type: string): void {
    if (type === 'name') {
      this.promoCodeForm.get('name').setValue(this.promoCodeForm.get('name').value.trimStart());
    }
  }

  /**
   * * HANDLE PRODUCT PERIOD CHANGE *
   * ANCHOR Handle Product Period Change
   * @description: to handle product period change
   */
  public handleBillingPeriodChange(e): void {
    this.updateValidators();
    const productPeriodValue = this.promoCodeForm.get('billingPeriod').value.value;
    if (productPeriodValue === 'yearly') this.limitedPeriod = 'year(s)'
    else this.limitedPeriod = 'month(s)';
  }

  /**
   * * HANDLE DISCOUNT TYPE CHANGE *
   * ANCHOR Handle Discount Type Change
   * @description: to handle discount type change
   */
  public handleDiscountTypeChange(e): void {
    this.updateValidators();
    const discountTypeValue = this.promoCodeForm.get('discountType').value;    
    this.discountPlaceholder = discountTypeValue.value === 'percentage' ? 'Percent Off' : 'Amount Off';
    this.promoCodeForm.get('discount').reset();
  }

  /**
   * * CHANGE DURATION *
   * ANCHOR Change Duration
   * @description to change duration
   * @param type : 'once' | 'forever' | 'repeating';
   */
  public changeDuration(
    type: 'once' | 'forever' | 'repeating', updateValidators: boolean = true
  ): void {
    if (this.promoCodeForm.get('duration' + this._toUpperFirstLetter(type)).disabled) return;

    const types = ['once', 'forever', 'repeating'];
    types.forEach((x: string) => {
      const duration = this.promoCodeForm.get('duration' + this._toUpperFirstLetter(x))
      if(x === type) duration.setValue(true);
      else duration.setValue(false);
    });

    if(updateValidators) this.updateValidators();
  }

  /**
   * * TO UPPER FIRST LETTER *
   * ANCHOR To Upper First Letter
   * @description: to upper first letter
   * @param str : string
   * @returns : string
   */
  private _toUpperFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  /**
   * * SAVE PROMO CODE *
   * ANCHOR Save Promo Code
   * @description: to save promo code
   */
  public savePromoCode(): void {
    if(this.userAction == 'add') this._addPromoCode();
    else this._editPromoCode();
  }

  /**
   * * ENABLE LOADING *
   * ANCHOR Enable Loading
   * @description: to enable loading
   * @param enable : boolean
   */
  private _enableLoading(enable: boolean): void {
    this.loading = enable;
    if(enable) this.promoCodeForm.disable();
    else this.promoCodeForm.enable();
  }

  /**
   * * REMOVE LEADING ZERO *
   * ANCHOR Remove Leading Zero
   * @description: to remove leading zero
   */
  public removeLeadingZero(control): void {
    if(!control.value) return;
    const value = String(control.value);
    const trimmedString = value.replace(/^0+/, '');
    if(trimmedString === 'null') return;
    const newValue = Number(trimmedString.replace(/[A-Za-z]|[^\w\s]/g, ''));
    if (newValue === 0) {
      control.setValue('')
      return; 
    }
    control.setValue(newValue);
    return
  }

  /**
   * ANCHOR Process Discount Value
   * @description: to process discount value
   * @param isBlur 
   */
  public processDiscountValue(isBlur: boolean = false): void {
    const inputElement = document.getElementById('discountInput');
    if(this.promoCodeForm.get('discountType').value.value === 'amount') {
      this._formatCurrency(inputElement, isBlur ? 'blur' : null);
    } else {
      this.removeLeadingZero(this.promoCodeForm.get('discount'));
    }
  }

  /**
   * * GET PROMO CODE DETAILS *
   * ANCHOR Get Promo Code Details
   * @description: to get promo code details
   */
  private _getPromoCodeDetails(refetch:boolean=false): void {
    this._promocodeService.getPromoCode(this.promo_id, refetch).subscribe((res: any) => {
      this.promoCodeDetails = res.data.coupon_datas;
      this.promoCodeDetails.discount = String(this.promoCodeDetails.discount).replace('$', '');
      this._setPromoCodeFormValues();
    }, error => {
      this._router.navigate(['/404']);
    })
  }


  /**
   * * HANDLE PLUS MINUS VALUE *
   * ANCHOR Handle Plus Minus Value
   * @description: to handle plus minus value
   */
  public handleNotAllowedValue(e) {
    const keyCodes = [
      69, // e
      189, // -
      187, // =
      107, // +
      109, // -
    ]
    if (keyCodes.includes(e.keyCode)) e.preventDefault();
  }

  /**
   * * GET LIST PRODUCT *
   * ANCHOR Get List Product
   * @description: to get list product
   */
  private _getListProductForSupport(): Promise<void> {
    return new Promise((resolve, reject) => {
      this._promocodeService.getProductSupport().subscribe((res: any) => {
        const productData = res.data.list_product;
        let listProduct: any[] = [];
  
        listProduct.push({ name: 'All', product_id: null, price_id:  null, description: null });
        productData.map((x: any) => {
          listProduct.push(x);
        });
        
        this.productTypes = listProduct;
        resolve()
      }, error => {
        reject();
        this._messageService.add({
          severity: 'error',
          summary: 'Failed',
          detail: error.error.data.errors.messages
        });
      });
    })
  }

  /**
   * * GET LIST USERS *
   * ANCHOR Get List Users
   * @description: Get list users for support
   */
  private _getListUserForSupport(): Promise<void> {
    return new Promise((resolve, reject) => {
      this._promocodeService.getListUsers().subscribe((res: any) => {
        const userData = res.data.list_email;
        let listUserData = [];
        userData.map((x: any) => {
          x.selected = false;
          listUserData.push(x);
        });
  
        this.listUserForSelect = listUserData;
        this.originalListUsers = userData;
        resolve();
      }, error => {
        reject();
        this._messageService.add({
          severity: 'error',
          summary: 'Failed',
          detail: error.error.data.errors.messages
        });
      });
    })
  }

  /**
   * * SELECT ALLOED USER *
   * ANCHOR Select Allowed User 
   * @description: Select allowed user for use promo code
   */
  public selectAllowedUser(user:any): void {
    user.selected = true;
    let listUserSelected = this.promoCodeForm.get('allowedUsers')?.value;
    listUserSelected.push(user.email);
    
    this.promoCodeForm.get('allowedUsers')?.setValue(listUserSelected);
    this.selectedUser.push(user);
    this.listUserForSelect = this.listUserForSelect.filter((user: { selected: boolean; }) => user.selected !== true);
    
    this.originalListUsers.find((x: any) => x.email === user.email).selected = true;
    this.toggleSelectedUser();
  }

  /**
   * * UNSELECT ALLOED USER *
   * ANCHOR Unselect Allowed User 
   * @description: Unselect allowed user for use promo code
   */
  public unselectAllowedUser(user: any): void {
    user.selected = false;
    let listUserSelected = this.promoCodeForm.get('allowedUsers')?.value;
    listUserSelected = listUserSelected.filter((x: any) => x !== user.email);
    
    this.promoCodeForm.get('allowedUsers')?.setValue(listUserSelected);
    this.selectedUser = this.selectedUser.filter((user: { selected: boolean; }) => user.selected !== false);
    this.listUserForSelect.push(user);
    
    this.originalListUsers.find((x: any) => x.email === user.email).selected = false;
    this.toggleSelectedUser();
  }

  /**
   * * SEARCH ALLOED USER *
   * ANCHOR Search Allowed User 
   * @description: Search allowed user for use promo code
   */
  public searchAllowedUser(): void {
    const username = this.promoCodeForm.get('searchUser')?.value;

    this.listUserForSelect = this.originalListUsers.filter((user: any) => {
      return user.username.includes(username.toLowerCase()) && user.selected === false;
    });
  }

  /**
   * * TOGGLE SELECTED ALLOWED USER *
   * ANCHOR Toggle Selected Allowed User 
   * @description: for show and hide selected allowed user
   */
  public toggleSelectedUser(action: boolean = false): void {
    if(action) this.showSelected = !this.showSelected;
    const toggle = document.querySelector('.select__button-expand');
    
    if(!this.showSelected) {
      toggle?.setAttribute('style', `top: 90px;`);
      return;
    }

    if (this.selectedUser.length < 2) this.showSelected = false;

    const positionToggle = (this.selectedUser.length * 50) - 10;
    toggle?.setAttribute('style', `top: ${positionToggle}px;`);
    
  }

  /**
   * * TOGGLE PANEL LIST USER *
   * ANCHOR Toggle panel list user 
   * @description: for show and hide panel list user
   */
  public hidePanelListUser(): void {
    if (!this.pointerOnList) {
      this.showUserList = false;
      this.showSelected = false;
    }
    
    return;
  }

  /**
   * * TOGGLE PANEL LIST USER ON MOBILE *
   * ANCHOR Toggle panel list user on mobile
   * @description: for show and hide panel list user on mobile
   */
  public handlePanelListUserMobile(): void {
    setTimeout(() => {
      this.pointerOnList = false;
      const searchPanel = document.querySelector('.searchUser') as HTMLElement;
      searchPanel?.focus();
    }, 100);
  }

  //#endregion
  //!SECTION


  private _formatNumber(n) {
    return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  private _formatCurrency(inputElement, blur = null) {
    var input_val = inputElement.value;
    if (input_val === "") return;
    var original_len = input_val.length;
    var caret_pos = inputElement.selectionStart;

    const removeLeadingZero = (value: string) => {
      let splitedValue = value.split(',');
      splitedValue[0] = String(Number(splitedValue[0]));
      return splitedValue.join(',')
    }

    if (input_val.indexOf(".") >= 0) {
      var decimal_pos = input_val.indexOf(".");
      var left_side = input_val.substring(0, decimal_pos);
      var right_side = input_val.substring(decimal_pos);
      left_side = this._formatNumber(left_side);
      right_side = this._formatNumber(right_side);
      if (blur === "blur") right_side += "00";
      right_side = right_side.substring(0, 2);
      left_side = removeLeadingZero(left_side)
      input_val = left_side + "." + right_side;
    } else {
      input_val = this._formatNumber(input_val);
      input_val = removeLeadingZero(input_val);
      input_val = input_val;

      if (blur === "blur") input_val += ".00";
    }

    inputElement.value = input_val;

    var updated_len = input_val.length;
    caret_pos = updated_len - original_len + caret_pos;
    inputElement.setSelectionRange(caret_pos, caret_pos);
  }

  public selectUserForPromoCode(event): void {
    this.selectedUserEmail = event.value.email;
  }

  public backToList(): void {
    this._router.navigate(['/promocodes']);
  }
}
