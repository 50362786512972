import { Component } from '@angular/core';
import { TextService } from '../service/text-service.service';
import { EditorService } from '../../editor.service';
import { LoadingGalleryService } from 'src/app/components/loading-gallery/loading-gallery.service';
import { AlertMessageService } from 'src/app/components/alert-message/alert-message.service';
import { messages } from '@data';
import { MainService } from '@services';
import * as _ from 'lodash';
import { TextLoaderService } from '../service/text-loader.service';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-text-create',
  standalone: true,
  templateUrl: './text-create.component.html',
  styleUrl: './text-create.component.scss',
  imports: [NgIf],
})
export class TextCreateComponent {

  constructor(
    public textService: TextService,
    public editorService: EditorService,
    private _loadingGalleryService: LoadingGalleryService,
    private _messageService: AlertMessageService,
    private _mainService: MainService,
    private _textLoader: TextLoaderService
  ) { }

  /**
   * * CREATE TEXT WALL *
   * ANCHOR Create Text Wall
   * @description to create text wall
   */
  public createText = _.debounce(async () => {
    const initialPosition = this.editorService.getInitialPositionAssets('text-wall');
    this.textService.onCreating = true;
    this._loadingGalleryService.show = true;
    this._loadingGalleryService.percent = 0;
    this.editorService.blockUserAction = true;

    let textName: string;
    for (let i = 0; i < 200; i++) {
      if (i == 0) textName = 'Your Text Definition';
      else textName = `Your Text Definition (${i})`

      const duplicateName = this._textLoader.texts.find((x: any) => x.name == textName);
      if (duplicateName) continue;
      else break;
    }

    const textConfig = this._getDefaultTextWallConfig(initialPosition);
    const textNode = await this._textLoader.loadText(textConfig, this.editorService.scene, false);
    const yPos = textNode.position.y;
    textNode.position.y = -100;
    const file = await this._textLoader.convertTextToFile(textNode);

    this.textService.createText(initialPosition, textName, file, this.editorService.exhibition.id).subscribe((response: any) => {
      // While still in the process of uploading
      if (response['status'] == "progress") {
        this._loadingGalleryService.percent = response['data'];
      }

      // When the upload process is complete
      if (response['status'] == "response") {
        const newTextWall = response.data.data.text_wall[0];
        textNode.metadata.textData = newTextWall;
        newTextWall.deleted = false;
        this._textLoader.texts.unshift(newTextWall);
        this.editorService.updateLogActivity("Add text")
        this.editorService.updateUndoRedoState();

        textNode.id = 'textWall-' + newTextWall.id;
        textNode.position.y = yPos;
        this._textLoader.textsNode.push(textNode);

        this.editorService.blockUserAction = false;
        this._loadingGalleryService.show = false;
        this.textService.onCreating = false;

        this._messageService.add({
          severity: "success",
          summary: "Success",
          detail: messages.editor.textWall.add.success
        })
      }
    }, (err: any) => {
      textNode?.dispose();
      this._loadingGalleryService.show = false;
      this._loadingGalleryService.percent = 0;
      this.editorService.blockUserAction = false;
      if (err.error.statusCode == 401) {
        this._mainService.expiredSesionPopup = true;
      } else {
        this._messageService.add({
          severity: "error",
          summary: "System Error",
          detail: messages.editor.textWall.add.failed
        })
      }
    });
  }, 500);

  /**
   * ANCHOR: Get Default Text Wall Config
   * @description: to get default text wall config
   * @param initialPosition : { rotation, position }
   * @returns : TextWall
   */
  private _getDefaultTextWallConfig(initialPosition): any {
    const { rotation, position } = initialPosition;
    return {
      name,
      content: "Your Text content",
      color: "#000000",
      font_size: 100,
      text_horizontal_alignment: 2,
      text_vertical_alignment: 2,
      font_family: "Times New Roman",
      position: {
        position_x: position.x,
        position_y: position.y,
        position_z: position.z
      },
      rotation: {
        rotation_x: rotation.x,
        rotation_y: rotation.y,
        rotation_z: rotation.z
      },
      line_height: 100,
      scaling: {
        scaling_x: 0.4,
        scaling_y: 0.4,
        scaling_z: 0.4
      },
      font_style: "Regular",
    }
  }
}
