// Angular packages
import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';

// Used-defined interfaces
import { PromoCode } from '../promocodes.interfaces';

// User-defined Services
import { PromocodesService } from '../promocodes.service';
import { MainService } from '@services';
import { AlertMessageService } from 'src/app/components/alert-message/alert-message.service';

// Third-party packagess
import moment from 'moment';

// Constants
import { messages } from '@data';
import { DialogModule } from 'primeng/dialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NgIf } from '@angular/common';
import { PrimeTemplate } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { PaginationComponent } from '../../../components/pagination/pagination.component';
import { LayoutsComponent } from '../../../layouts/layouts.component';

@Component({
    selector: 'app-promocode-list',
    templateUrl: './promocode-list.component.html',
    styleUrls: ['./promocode-list.component.scss'],
    standalone: true,
    imports: [LayoutsComponent, RouterLink, PaginationComponent, TableModule, PrimeTemplate, NgIf, ProgressSpinnerModule, DialogModule]
})
export class PromocodeListComponent {
  public promoCodes: PromoCode[];
  public gettingPromoCodes: boolean = false;
  public displayDeleteConfirm: boolean = false;
  public deletingPromoCode: boolean = false;
  public status: 'all' | 'active' | 'expired' = 'all';
  public page: number = 0;
  public limit: number = 10;
  public totalCoupon: number = 0;
  public hasMore: boolean = true;
  public currentPageItemLength: number = 0;

  private _deleteCode: string;

  constructor(
    public mainService: MainService,
    private _promocodeService: PromocodesService,
    private _messageService: AlertMessageService,
    private _router: Router,
    private _route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.status = this._route.snapshot.queryParams.status || 'all';
    this.page = Number(this._route.snapshot.queryParams.page || 1)
    if (!this.mainService.isBrowser) {
      this._getPromoCodes();
    } else {
      const refetch = localStorage.getItem('refetch-promocode') === 'true';
      localStorage.removeItem('refetch-promocode');
      this._getPromoCodes(refetch);
    }
  }

  /**
   * ANCHOR Get Promo Codes
   * @description: to get promo codes
   * @param refetch : boolean -> to refetch the data
   * @param isAppend : boolean -> to append the data
   * @returns void
   */
  private _getPromoCodes(refetch : boolean = false, isAppend: boolean = false): void {
    this.gettingPromoCodes = true;
    if(!isAppend) this.promoCodes = [];
    this._promocodeService.getPromoCodes(refetch, this.status, this.page, this.limit).subscribe((res: any) => {
      this.totalCoupon = res.data.total
      this.gettingPromoCodes = false;
      const promoCodes = this._processPromoCodesData(res.data.list_coupon);
      if(isAppend) this.promoCodes = [...this.promoCodes, ...promoCodes];
      else this.promoCodes = this._processPromoCodesData(res.data.list_coupon);
      this.currentPageItemLength = promoCodes.length;
    }, (err) => {
      this.gettingPromoCodes = false;
    })
  }

  /**
   * ANCHOR Load More
   * @description: to load more promo codes
   */
  public loadMore(): void {
    this.page++;
    this._getPromoCodes(true, true);
    this._router.navigate([], {
      relativeTo: this._route,
      queryParams: { page: this.page },
      queryParamsHandling: 'merge'
    });
  }

  /**
   * ANCHOR Process Promo Code Data
   * @description: to process promo code data
   * @param data : PromoCode -> promo code data from 
   * @returns PromoCode -> processed promo code data
   */
  private _processPromoCodesData(data: PromoCode[]): PromoCode[] {
    return data.map((promoCode: PromoCode) => {
      if (promoCode.expires && promoCode.expires !== 'Never') {
        promoCode.expires = moment(promoCode.expires).format("DD MMM YYYY");
      } else {
        promoCode.expires = 'Never';
      }

      if(promoCode.type_discount === 'percentage') {
        promoCode.display_discount = `${promoCode.discount}%`;
      } else {
        promoCode.display_discount = `${promoCode.discount}`;
      }
      return promoCode;
    })
  }

  /**
   * ANCHOR Display Delete Confirm Dialog
   * @description: to display delete confirm dialog
   * @param code : string -> promo code to be deleted
   */
  public displayDeleteConfirmDialog(code: string): void {
    this._deleteCode = code;
    this.displayDeleteConfirm = true;
  }

  /**
   * ANCHOR Delete Promo Code
   * @description: to delete promo code
   * @param code : string -> promo code to be deleted
   */
  public deletePromoCode(): void {
    this.deletingPromoCode = true;
    this._promocodeService.deletePromoCode(this._deleteCode).subscribe((res: any) => {
      this.deletingPromoCode = false;
      this.displayDeleteConfirm = false;
      this._getPromoCodes(true);
      this._messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: messages.promoCodes.delete.success
      })
    }, (err) => {
      this.deletingPromoCode = false;
      this._messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: messages.promoCodes.delete.failed
      })
    });
  }
  /**
   * ANCHOR Navigate To Edit
   * @description: to navigate to edit promo code page
   */
  public navigateToEdit(item: any) {
    this._router.navigate(['/promocodes/edit', item.code, item.promo_id], { state: { refetch: true } });
  }

  /**
   * ANCHOR Viewed Promo Codes
   * @description: to get the viewed promo codes
   */
  public viewedPromoCodes(): number {
    return (this.page - 1) * this.limit + this.currentPageItemLength;
  }

  /**
   * ANCHOR Filter By Status
   * @description: to filter promo codes by status
   * @param status 
   */
  public filterByStatus(status: 'all' | 'active' | 'expired') {
    this.status = status;
    this.page = 1;
    this.hasMore = true;
    this._router.navigate([], {
      relativeTo: this._route,
      queryParams: { status: status == 'all' ? null : status},
      queryParamsHandling: 'merge'
    });
    this._getPromoCodes(true);
  }

  /**
   * ANCHOR On Page Change
   * @description: to handle page change
   * @param page 
   */
  public onPageChange(page: number) {
    this.page = page;
    this.hasMore = true;
    this._router.navigate([], {
      relativeTo: this._route,
      queryParams: { page },
      queryParamsHandling: 'merge'
    });
    this._getPromoCodes(true);
  }
}
