<app-layouts #layouts>
    <div class="wrap-pricing" content>
        <div class="header-pricing">
            <h1>Choose your plan</h1>
        </div>
        <section class="wrap-item-pricing">
            <div class="wrap-switch-pay">
                <p>Pay</p>
                <div class="switch-button" [class.is-yearly]="pricingService.isYearly" [class.is-monthly]="!pricingService.isYearly" (click)="pricingService.isYearly=!pricingService.isYearly">
                    <input id="pay-item" class="switch-button-checkbox" [checked]="pricingService.isYearly" type="checkbox">
                    <label class="switch-button-label" for="pay-item">
                        <span class="switch-button-label-span">Monthly</span>
                    </label>
                </div>            
            </div>
            <div class="scroll-x" id="wrap-item">
                <div class="wrap-carousel">
                    <div *ngIf="loading" class="wrap-spinner text-center">
                        <p-progressSpinner strokeWidth="3" animationDuration="1s"></p-progressSpinner>
                    </div>

                    <ng-container *ngIf="!loading">
                        <div
                            class="item-pricing" 
                            [class.active]="isActiveProduct(item)"  
                            *ngFor="let item of pricingProducts">
                            <div class="content-pricing">
                                <h1 class="title text-primary">
                                    <!-- Procuct Name -->
                                    {{item.name}} <br/>

                                    <!-- Product Price -->
                                    <ng-container *ngIf="item.name != 'Experimental'">
                                        <!-- Yearly Price (per month) -->
                                        <ng-container *ngIf="!pricingService.isYearly">
                                            {{item.price_list?.monthly?.amount}}
                                        </ng-container>

                                        <!-- Monthly Price -->
                                        <ng-container *ngIf="pricingService.isYearly">
                                            {{item.price_list.yearly.per_monthly}}
                                        </ng-container>

                                        <span>/ month </span>

                                        <ng-container *ngIf="pricingService.isYearly">
                                            <span class="per-year"> ({{item.price_list.yearly.amount}} / year)</span>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="item.name == 'Experimental'">
                                        $0
                                        <span>/ month</span>
                                    </ng-container>
                                </h1>
                                <ul>
                                    <li *ngFor="let desc of item.description; let i = index">
                                        {{desc}}
                                    </li>
                                </ul>
                            </div>

                            <ng-container *ngIf="item.buttonDisplay">
                                <button 
                                    *ngIf="!isActiveProduct(item)&&!isUltimateProduct(item)"
                                    [disabled]="item.buttonDisabled"
                                    class="btn btn-light"
                                    [class.loading-button-primary]="item.buttonLoading" 
                                    (click)="subscribe(item)">
                                    {{item.buttonText}}
                                </button>

                                <button
                                    *ngIf="!isActiveProduct(item)&&isUltimateProduct(item)"
                                    [disabled]="item.buttonDisabled"
                                    class="btn btn-light"
                                    [class.loading-button-primary]="item.buttonLoading" 
                                    (click)="getUltimateProduct(item)">
                                    {{ item.buttonText }}
                                </button>

                                <button 
                                    *ngIf="isActiveProduct(item)"
                                    [disabled]="item.buttonDisabled"
                                    class="btn btn-primary" 
                                    (click)="subscribe(item)">
                                        {{ mainService.userInfo ? 'Active' : 'Get Started'}}
                                </button>
                            </ng-container>

                        </div>
                    </ng-container>
                </div>
            </div>
        </section>


        <section class="faq-section">
            <div class="row">
                <div class="col col16-5 col-iped-8 wrap-title">
                    <h1 class="title">F</h1>
                    <h1 class="title">A</h1>
                    <h1 class="title">Q</h1>
                </div>
            </div>
            <!-- START ITEM FAQ -->
            <app-faq groupName="t_faq"></app-faq>
            <!-- END ITEM FAQ -->
        </section>
    </div>
</app-layouts>

<p-dialog 
    [(visible)]="displayAnnouncement" 
    [modal]="true" 
    [baseZIndex]="10000"
    [draggable]="false" 
    [resizable]="false" 
    styleClass="confirm-delete"
    [style]="{width: '725px'}">
    <div class="wrap-content-confirm">
        <div class="desc">
            <p class="pl0">
                Subscription feature will be available soon.
            </p>
        </div>
    </div>
</p-dialog>

<p-dialog 
    [(visible)]="displayDowngrade" 
    [modal]="true" 
    [draggable]="false" 
    [resizable]="false"
    [style]="{maxWidth: '725px'}"
    [baseZIndex]="10000"
    styleClass="confirm-delete">
    <div class="wrap-content-confirm">
        <div class="desc">
            <p class="pl0">
                You are currently using a "{{currentSubscription?.product}}" ({{currentSubscription?.interval}}) subscription. To downgrade your current plan, please contact our team in Live Chat or at <a href="mailto:info@villume.com">info&#64;villume.com</a>
            </p>
        </div>
    </div>
</p-dialog>


<p-dialog 
    [(visible)]="displayDowngradeInterval" 
    [modal]="true" 
    [draggable]="false" 
    [resizable]="false"
    [style]="{maxWidth: '725px'}"
    [baseZIndex]="10000"
    styleClass="confirm-delete">
    <div class="wrap-content-confirm">
        <div class="desc">
            <p class="pl0">
                You can't change to "{{currentSubscription?.product}} (monthly)" because you are currently subscribed on "{{currentSubscription?.product}} (yearly)" Product.
            </p>
        </div>
    </div>
</p-dialog>
