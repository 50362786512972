import { Injectable } from '@angular/core';
import {
	ICSGActionsOptions,
	ICreateRayCastOptions,
	IEnableHighlightOptions,
	IExcludedMeshHighlightLayerOptions,
	IGetCameraYPositionOptions,
	IGetDistanceToFrontObject,
	INodeDimension,
	SceneMetadata
} from '@interfaces';
import { debounce } from 'lodash';

declare const BABYLON: any;

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

	//#region

	/**
	 * ANCHOR CSG Action 
	 * @description to intersect, subtract or union two mesh;
	 * @param Options: ICSGActionsOptions
	 * @return newMesh: BABYLON.AbstactMesh
	 */
	public CSGActions(options: ICSGActionsOptions): any {
		const { mesh1, mesh2, disposeSourceMeshes, action, scene } = options;
		const mesh1Clone = mesh1.clone();
		const mesh2Clone = mesh2.clone();
		const mesh1CSG = BABYLON.CSG.FromMesh(mesh1Clone);
		const mesh2CSG = BABYLON.CSG.FromMesh(mesh2Clone);

		// remove old material
		// const oldMaterial = scene.getMaterialByName('csgMat');
		// if(oldMaterial) oldMaterial.dispose();
		// create new material
		const material = new BABYLON.StandardMaterial('csgMat', scene);

		let newCSG;
		switch (action) {
			case 'intersect': newCSG = mesh1CSG.intersect(mesh2CSG); break;
			case 'subtract': newCSG = mesh1CSG.subtract(mesh2CSG); break;
			case 'union': newCSG = mesh1CSG.union(mesh2CSG); break;
		}
		const newMesh = newCSG.toMesh('csgMat', material, scene);
		mesh1Clone.dispose();
		mesh2Clone.dispose();
		
		if(disposeSourceMeshes) {
			mesh1.dispose();
			mesh2.dispose();
		}
		return newMesh
	}

	/**
	 * ANCHOR Get Drag Area Meshes
	 * @description Get drag area meshes
	 * @param scene : BABYLON.Scene
	 * @returns : BABYLON.Mesh[]
	 */
	public getDragAreaMeshes(scene: any): any {
		const metadata = scene.metadata as SceneMetadata;
		const activeExhibitionId = metadata.activeExhibtionUniquId;
		const { dragAreaMeshesUniqueIds } = metadata.exhibitions[activeExhibitionId];
		return dragAreaMeshesUniqueIds.map((meshId: any) => scene.getMeshByUniqueId(meshId));
	}

	/**
	 * ANCHOR Calculate Distance Between Camera And Mesh
	 * @param mesh: BABYLON.Mesh
	 * @description : to calculate the distance between camera and mesh
	 */
	public calculateDistanceCameraAndMesh(mesh: any, camera: any): number {
		const distance = BABYLON.Vector3.Distance(
			camera.position, 
			mesh.position
		);
		return distance;
	}

	/**
	 * ANCHOR Create Highlight Layer
	 * @description Create a highlight layer
	 */
	public createHighlightLayer(scene): any {
		const highlightLayer = new BABYLON.HighlightLayer("highlightLayer", scene, {
			blurTextureSizeRatio: 0.7
		});
		highlightLayer.innerGlow = false;
		return highlightLayer;
	} 

	/**
	 * ANCHOR Is Meshes Intesected
	 * @description Check if two meshes are intersected
	 * @param mesh1: BABYLON.Mesh 
	 * @param mesh2 : BABYLON.Mesh
	 * @returns : Promise<boolean>
	 */
	public isMeshesIntesected(mesh1: any, mesh2: any): Promise<boolean> {
		return new Promise((resolve, reject) => {
			try {
				setTimeout(() => {
					resolve(mesh1.intersectsMesh(mesh2, true))
				}, 10)
			} catch (error) {
				reject(error)
			}
		})
	}

	/**
	 * ANCHOR Enable/Disable Highlight
	 * @description Enable highlight on an exhibit asset
	 * @param exhibitAsset : any
	 * @param enable : boolean
	 */
	public enableHighlight(options: IEnableHighlightOptions): void {
    const { enable, exhibitAsset, highlightLayer } = options;
		const meshes = exhibitAsset.getChildren();
		meshes.map((mesh: any) => {
			if (enable) {
				this.excludedMeshHighlightLayer({ mesh, highlightLayer, isAdd: false })
				highlightLayer.addMesh(mesh, new BABYLON.Color4(1,0.85,0.08,1))
			}
			else {
				this.excludedMeshHighlightLayer({ mesh, highlightLayer, isAdd: true })
				highlightLayer.removeMesh(mesh)
			};
		})
	}

	/**
	 * ANCHOR Add/Remove Excluded A Mesh For Highlight Layer
	 * @description Excluded a mesh for highlight layer
	 * @param mesh : any
	 * @param add : boolean -> true to add, false to remove
	 */
	public excludedMeshHighlightLayer(options: IExcludedMeshHighlightLayerOptions): void {
    const { mesh, highlightLayer, isAdd } = options;
		if (isAdd) highlightLayer.addExcludedMesh(mesh);
		else highlightLayer.removeExcludedMesh(mesh);
	}

	/**
   * ANCHOR Clone Artwork Wrapper
   * @description to clone artwork wrapper based on artwork node transform values
   * @param artworkNode : BABYLON.TransformNode -> Container of artwork
   * @returns : BABYLON.Mesh
   */
  public cloneArtworkContainer(artworkNode: any, scene) {
    const meshParent = new BABYLON.Mesh("parent", scene);
    const currentTransform = {
      position: artworkNode.position.clone(),
      rotation: artworkNode.rotation.clone(),
    }

    artworkNode.position = BABYLON.Vector3.Zero();
    artworkNode.rotation = BABYLON.Vector3.Zero();

		artworkNode.getChildMeshes().map((mesh: any) => {
			const clonedMesh = mesh.clone();
      clonedMesh.setParent(meshParent)
    })

		const childMeshes = meshParent.getChildMeshes();
		let min = childMeshes[0].getBoundingInfo().boundingBox.minimumWorld;
		let max = childMeshes[0].getBoundingInfo().boundingBox.maximumWorld;

		for(let i=0; i<childMeshes.length; i++){
			const meshMin = childMeshes[i].getBoundingInfo().boundingBox.minimumWorld;
			const meshMax = childMeshes[i].getBoundingInfo().boundingBox.maximumWorld;

			min = BABYLON.Vector3.Minimize(min, meshMin);
			max = BABYLON.Vector3.Maximize(max, meshMax);
		}

		meshParent.setBoundingInfo(new BABYLON.BoundingInfo(min, max));
		meshParent.showBoundingBox = true;

		const parentDimensions = (meshParent.getBoundingInfo().boundingBox.extendSizeWorld).scale(2);
		const boxWrapper = BABYLON.MeshBuilder.CreateBox(
			"boxWrapper", 
      {}, 
			scene
		);

		boxWrapper.position.copyFrom(meshParent.getBoundingInfo().boundingBox.centerWorld);
		boxWrapper.visibility = 0;
    boxWrapper.scaling = new BABYLON.Vector3(
      parentDimensions.x, 
			parentDimensions.y, 
			parentDimensions.z
    )
    meshParent.dispose();
      
    const getRotationValue = (axis: 'x' | 'z') => {
      let rotateValue = artworkNode.rotation[axis];
      if(rotateValue >= -Math.PI/2 && rotateValue <= Math.PI/2) {
        return 0;
      } else {
        return -Math.PI
      }
    }

    boxWrapper.setParent(artworkNode);
    artworkNode.position = currentTransform.position;
    artworkNode.rotation = currentTransform.rotation;
    boxWrapper.setParent(null);

    boxWrapper.rotation.y = artworkNode.rotation.y;
    boxWrapper.rotation.x = getRotationValue('x');
    boxWrapper.rotation.z = getRotationValue('z');
    boxWrapper['artworkNode'] = artworkNode;


		return boxWrapper;
  }

	/**
   * ANCHOR Get Camera "Y"  Position Based on the Footing Position
   * @description : to get camera "Y" position based on the footing position
   * @param position : BABYLON.Vector3
   * @returns : Promise<number | null>
   */
  public getCameraYPosition(options: IGetCameraYPositionOptions): number | null {
    const { camera, position, scene } = options;
    const footingPosition = this.getFootingPosition(position, scene);
    if(!footingPosition) return null;
    const realHeight = camera.height_camera / (49.75124378109452/100);
    return footingPosition.y + realHeight;
  }

  /**
   * ANCHOR Get Footing Position
   * @description : to get footing position based on position given
   * @param position : BABYLON.Vector3
   * @param scene : BABYLON.Scene
   * @returns : BABYLON.Vector3
   */
	public getFootingPosition(position: any, scene: any): any {
		const predicate = (mesh: any) => {
      const isCollision = (
        mesh.name.toLowerCase().includes("collision")|| 
				mesh.name.toLowerCase().includes("floor") || 
				mesh.name.toLowerCase().includes("stairs") || 
				mesh.name.toLowerCase().includes("wall")
      )
			if(isCollision){
				return true;
			} else{
				return false;
			}
		};
		const ray = new BABYLON.Ray(position, new BABYLON.Vector3(0, -1, 0), 100);
		const hit = scene.pickWithRay(ray, predicate);
		return hit.pickedPoint;
	}

	/**
   * ANCHOR Get Distance To Front Object
   * @description to get distance to front object
   * @param artworkContainer: BABYLON.Mesh -> Clone of artwork container
   * @param watchPosition: BABYLON.Vector3 -> Watch position like a visitor in real life
   * @returns Number -> Distance
   */
  public getDistanceToFrontObject(options: IGetDistanceToFrontObject): Promise<number> {
    const { artworkContainer, watchPosition, scene } = options;
    return new Promise((resolve, reject) => {
      try { 
        const enablePickble = (enable: boolean) => {
          artworkContainer.isPickable = enable;
          artworkContainer['artworkNode'].getChildren().forEach((mesh: any) => mesh.isPickable = enable);
        };
    
        setTimeout(() => {
          const ray = this.createRaycast({ 
            node: artworkContainer, 
            direction: watchPosition, 
            scene
          });

          enablePickble(false);
          const distance = scene.pickWithRay(ray).distance;
          enablePickble(true);
      
          resolve(distance);
        }, 10)
      } catch (error) {
        reject(error);
      }
    })
  }

  /**
   * ANCHOR Create Raycast
   * @description : to create raycast
   * @param options : ICreateRayCastOptions
   * @returns : BABYLON.Ray
   */
  public createRaycast(options: ICreateRayCastOptions) {
		const { node, direction, showRay, length, scene } = options;
		const origin = node.position.clone();
		const orginMarker = BABYLON.MeshBuilder.CreateBox('originMarker', {size: 0.1}, scene);
		orginMarker.position = origin;
		orginMarker.visibility = 0;

		let directionVec;
		switch (direction) {
			case 'forward': directionVec = new BABYLON.Vector3(0, 0, 100); break;
			case 'backward': directionVec = new BABYLON.Vector3(0, 0, -100); break;
			case 'left': directionVec = new BABYLON.Vector3(-100, 0, 0); break;
			case 'right': directionVec = new BABYLON.Vector3(100, 0, 0); break;
			case 'top': directionVec = new BABYLON.Vector3(0, 100, 0); break;
			case 'bottom': directionVec = new BABYLON.Vector3(0, -100, 0); break;
			default: directionVec = direction; break;
		}

		directionVec = this.vecToLocal(directionVec, typeof direction != 'string' ? orginMarker : node);
		directionVec = directionVec.subtract(origin);
		directionVec = BABYLON.Vector3.Normalize(directionVec);

		orginMarker.dispose();

		const ray = new BABYLON.Ray(origin, directionVec, length ? length : 100 );
		if (showRay) {
			const rayHelper = new BABYLON.RayHelper(ray);
			rayHelper.show(scene);
		}
		return ray;
	}

  /**
   * ANCHOR Vec To Local
   * @description : to convert vector to local
	 * @param vector : BABYLON.Vector3
	 * @param mesh : BABYLON.Mesh
	 * @returns : BABYLON.Vector3
	 */
	public vecToLocal(vector: any, mesh: any) {
    const m = mesh.getWorldMatrix();
    const v = BABYLON.Vector3.TransformCoordinates(vector, m);
    return v;
  }

	/**
   * ANCHOR Set Ease Mode for Animation
   * @description : to set ease mode for animation
   * @returns : BABYLON.CubicEase
   */
  public setEaseMode(): any {
    const ease = new BABYLON.CubicEase();
    ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT);
    return ease;
  }

  /**
   * ANCHOR Get Node Dimension
   * @description to get node dimension
   * @param node : BABYLON.TransformNode
   * @returns : INodeDimension
   */
	public getNodeDimension(node: any) : INodeDimension { 
		const sizes = node.getHierarchyBoundingVectors()
      const size = {
        x: sizes.max.x - sizes.min.x,
        y: sizes.max.y - sizes.min.y,
        z: sizes.max.z - sizes.min.z
      }

		return { 
			height: size.y, 
			width: size.x, 
			depth: size.z 
		}
	}

	/**
	 * ANCHOR Recalulate Rotation Node
	 * @description recalculates the resulting rotation value of the "setDirection" function 
	 *              so that the value is always between -Math Phi and Math Phi
	 * @param value : number -> axis value (x,y,z)
	 * @returns : number
	 */
	public recalulateRotationNode(value:any){
		while(!(value <= Math.PI && value >= -Math.PI)){
			if(value > Math.PI) {
				value -= Math.PI*2
			}
			if(value < -Math.PI){
				value -= Math.PI*2 
			}
		}
		return value;
	}

	/**
	 * ANCHOR Merge Meshes
	 * @description merge meshes
	 * @param meshes : BABYLON.Mesh[]
	 * @param name : string
	 * @returns : BABYLON.Mesh
	 */
	public mergedMeshes(meshes: any, name: string = ""){
		const mergedMeshes: any = BABYLON.Mesh.MergeMeshes(meshes, true, true, undefined, false, true);
		mergedMeshes.name = name;
		return mergedMeshes;
	}

	/**
	 * ANCHOR Remove Used Materials
	 * @description remove used materials
	 * @param scene 
	 */
	public removeUsedMaterials = debounce((scene: any) => {
		const materials = scene.materials;
		const meshes = scene.meshes;
		const usedMaterials = [...new Set(meshes.map((mesh: any) => mesh.material?.uniqueId))].filter((id: any) => id);
		materials.forEach((material: any) => {
			if(!usedMaterials.includes(material.uniqueId)) {
				material.dispose(true);
			}
		})
	}, 1000);

	/**
	 * ANCHOR Load Texture
	 * @description load texture
	 * @param source : string
	 * @param scene : BABYLON.Scene
	 * @returns : Promise<BABYLON.Texture>
	 */
	public loadTexture(source: string, scene: any): Promise<any> {
		return new Promise((resolve, reject) => {
			try {
				const texture = new BABYLON.Texture(source, scene);
				texture.onLoadObservable.add(() => {
					resolve(texture);
				})
			} catch (error) {
				reject(error);
			}
		})
	}
	//#endregion
}