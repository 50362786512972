<p-dialog 
  *ngIf="mainService.isBrowser"
  header="Edit Splash Screen"
  [(visible)]="visible"
  [style]="{width: '960px'}"
  (onHide)="onHideSplashScreen();splashPreview.clearUndoRedo()"
  (onShow)="onShowSplashScreen();initDragDrop('drop-area-bg');splashPreview.handleBlinkingText()"
  [modal]="true"
  [closable]="false"
  [draggable]="false"
  [resizable]="false"
  styleClass="p-dialog splash-screen">
  <ng-template pTemplate="header">
      <span class="text-xl">Edit Splash Screen</span>

      <div class="wrap-btn-icons">
        <ul>
          <li class="item-btn">
            <button
              class="btn-icons mr15"
              [disabled]="splashScreenService.undoRedoIndex==0"
              [class.disable]="splashScreenService.undoRedoIndex==0"
              [class.disable-icon]="splashScreenService.undoRedoIndex==0"
              (click)="undoRedoChanges=undoRedoChanges+1;splashPreview.undoRedo('undo')">
              <i class="vi vi-undo"></i>
            </button>
          </li>
          <li class="item-btn">
            <button 
              class="btn-icons mr35"
              [disabled]="splashScreenService.undoRedoIndex==splashScreenService.undoRedoStates.length-1"
              [class.disable]="splashScreenService.undoRedoIndex==splashScreenService.undoRedoStates.length-1"
              [class.disable-icon]="splashScreenService.undoRedoIndex==splashScreenService.undoRedoStates.length-1"
              (click)="undoRedoChanges=undoRedoChanges-1;splashPreview.undoRedo('redo')">
              <i class="vi vi-redo"></i>
            </button>
          </li>
          <li class="item-btn">
            <button
              class="btn-icons"
              (click)="visible=false"
              [disabled]="loading">
              <i class="vi vi-x"></i>
            </button>
          </li>
        </ul>
      </div>

  </ng-template>

  <div class="row">
    <div class="col widget-left">
      <div class="hint" [class.hidden]="!mobileView">
        <p>
          Drag the mobile screen left and right
          to choose the right position of the background
        </p>
      </div>

      <div class="preview-splash-screen">
        <div class="preview-screen">
          <app-splash-screen-preview
            #splashPreview
            [headerText]="headerText"
            [bodyText]="bodyText"
            [colorTone]="colorTone"
            [alignHeader]="alignHeader"
            [alignBody]="alignBody"
            [positionText]="textPlacement"
            [buttonText]="buttonText"
            [backgroundImage]="backgroundImage"
            [zoomValue]="mobileView ? zoomValue[1]: zoomValue[0]"
            [mobileView]="mobileView"
            [splashScreen]="splashScreenService.splashScreenData"
            [mobileBackgroundPosition]="backgroundPosition"
            [undoRedoChanges]="undoRedoChanges"
            (zoomValueChanges)="setZoomValue($event)"
            (backgroundPositionChange)="backgroundPosition = $event"
            (onUndoRedo)="updateForm($event)">
          </app-splash-screen-preview>
        </div>
      </div>

      <div class="wrap-zoom">
        <div class="zoom-value mr10">{{mobileView ? zoomValue[1]:zoomValue[0]}}%</div>
        <div class="zoom-slider">
          <button 
              (click)="setButtonBackgroundSize('zoomout')"
              [disabled]="!backgroundImage"
              class="btn-slider mr15">
              <i class="vi vi-dash"></i>
          </button>
          <p-slider
              (onChange)="setSliderBackgroundSize($event)"
              (ngModel)="mobileView ? zoomValue[1]:zoomValue[0]"
              [ngModel]="mobileView ? zoomValue[1]:zoomValue[0]"
              [min]="0"
              [max]="100"
              [disabled]="!backgroundImage"
              styleClass="p-slider splash"
              [ngStyle]="{'width' : '100%'}">
          </p-slider>
          <button 
              (click)="setButtonBackgroundSize('zoomin')"
              [disabled]="!backgroundImage"
              class="btn-slider ml15">
              <i class="vi vi-plus"></i>
          </button>
        </div>
      </div>

      <div class="wrap-devices">
        <ul>
          <li class="item-device">
            <button 
              (click)="mobileView=true"
              [class.active]="mobileView"
              class="btn-device mr30">
              <i class="vi vi-phone"></i>
            </button>
          </li>
          <li class="item-device">
            <button 
              (click)="mobileView=false"
              [class.active]="!mobileView"
              class="btn-device">
              <i class="vi vi-monitor"></i>
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="col widget-right">
      <p-scrollPanel [style]="{height:'439px'}" styleClass="widget-scroll">
        <div class="widget-scroll">
          <!-- BUTTON UPLOAD -->
          <!-- INPUT FILE ATTACEMENT FILES -->
          <input
            hidden
            type="file"
            accept=".png, .jpg, .jpeg"
            #inputBackground
            (change)="uploadAttachementFile($event)"/>

          <div *ngIf="!backgroundImage" class="wrap-upload-image">
            <div id="drop-area-bg">
              <button class="btn-upload-image" (click)="onUploadBackground()" id="upload-bg">
                <ng-container *ngIf="!dropHere">
                  Drag & Drop or Click to upload <br> a background image
                </ng-container>
                <ng-container *ngIf="dropHere">
                  Drop your file here
                </ng-container>
              </button>
            </div>
          </div>

          <!-- PREVIEW FILES -->
          <div *ngIf="backgroundImage" class="uploaded-img">
            <img [defaultImage]="env+'images/other/skeleton.svg'" [lazyLoad]="backgroundImage" alt="" class="thumb">
            <div class="overlay"></div>
            <div class="action">
              <button class="btn-action mr15" (click)="onUploadBackground()">
                <i class="vi vi-modify"></i>
              </button>
              <button class="btn-action" (click)="backgroundImage=null;initDragDrop('drop-area-bg')">
                <i class="vi vi-delete"></i>
              </button>
            </div>
          </div>

          <div class="overlay-tone">
            <label class="label">Overlay Tone</label>
            <div class="switch-button" (click)="changeViewerTheme()" [class.light]="viewerLightMode" [class.dark]="!viewerLightMode">
              <input id="pay-item" class="switch-button-checkbox" [checked]="viewerLightMode" type="checkbox">
              <label class="switch-button-label" for="pay-item">
                <span class="switch-button-label-span">Dark</span>
              </label>
            </div>
          </div>

          <div class="wrap-header">
            <label class="label">Header</label>
            <input type="text" maxlength="120" class="form-control header-splash" (ngModelChange)="validationInput('header')" [(ngModel)]="headerText">
            <div class="wrap-align">
              <ul>
                <li class="item-align">
                  <button class="btn-align"  [class.active]="alignHeader=='left'" (click)="setAlignText('header', 'left')">
                    <i class="vi vi-align-left"></i>
                  </button>
                </li>
                <li class="item-align">
                  <button class="btn-align" [class.active]="alignHeader=='center'" (click)="setAlignText('header', 'center')">
                    <i class="vi vi-align-center"></i>
                  </button>
                </li>
                <li class="item-align">
                  <button class="btn-align" [class.active]="alignHeader=='right'" (click)="setAlignText('header', 'right')">
                    <i class="vi vi-align-right"></i>
                  </button>
                </li>
              </ul>
            </div>

            <div class="font-style">
              <app-font-style 
                [type]="'header'"
                [splashScreen]="splashScreenService.splashScreenData"
                (onChange)="updateFontStyle($event, 'header')"></app-font-style>
            </div>
          </div>

          <div class="wrap-body">
            <label class="label">Body</label>
            <textarea 
              class="text-content"
              rows="6"
              maxlength="500"
              (ngModelChange)="validationInput('body')"
              [(ngModel)]="bodyText"
              [ngStyle]="{'resize': 'none'}"></textarea>
            <div class="wrap-align">
              <ul>
                <li class="item-align">
                  <button class="btn-align" [class.active]="alignBody=='left'" (click)="setAlignText('body', 'left')">
                    <i class="vi vi-align-left"></i>
                  </button>
                </li>
                <li class="item-align">
                  <button class="btn-align"  [class.active]="alignBody=='center'" (click)="setAlignText('body', 'center')">
                    <i class="vi vi-align-center"></i>
                  </button>
                </li>
                <li class="item-align">
                  <button class="btn-align"  [class.active]="alignBody=='right'" (click)="setAlignText('body', 'right')">
                    <i class="vi vi-align-right"></i>
                  </button>
                </li>
              </ul>
            </div>

            <div class="font-style">
              <app-font-style
                [type]="'body'"
                [splashScreen]="splashScreenService.splashScreenData"
                (onChange)="updateFontStyle($event, 'body')"></app-font-style>
            </div>
          </div>

          <div *ngIf="!mobileView" class="text-placement">
            <label class="label">Text Placement</label>
            <div class="placement">
              <div class="placement-col">
                <button class="placement-item" (click)="setPositionText(1)">
                  <input type="radio" [checked]="textPlacement===1"/>
                </button>
                <button class="placement-item" (click)="setPositionText(2)">
                  <input type="radio" [checked]="textPlacement===2"/>
                </button>
              </div>
              <div class="placement-col">
                <button class="placement-item placement-item-full" (click)="setPositionText(3)">
                  <input type="radio" [checked]="textPlacement===3"/>
                </button>
              </div>
              <div class="placement-col">
                <button class="placement-item" (click)="setPositionText(4)">
                  <input type="radio" [checked]="textPlacement===4"/>
                </button>
                <button class="placement-item" (click)="setPositionText(5)">
                  <input type="radio" [checked]="textPlacement===5"/>
                </button>
              </div>
            </div>
          </div>

          <div class="button-text">
            <label class="label mr10">Button Text:</label>
            <button class="btn btn-outline-primary">
              {{buttonText}}
              <input type="text" class="form-control" maxlength="20" (input)="validationInput('button')" [(ngModel)]="buttonText">
            </button>
          </div>

          <div class="text-error">
            <small class="text" *ngIf="buttonEmpty">Button text required</small>
          </div>
        </div>
      </p-scrollPanel>

      <div class="wrap-btn-action">
        <hr class="divider">
        <button class="btn btn-light2 btn-sm mr20" (click)="visible = false" [disabled]="loading">Cancel</button>
        <button
          class="btn btn-primary btn-sm"
          [class.loading-button-primary]="loading"
          [disabled]="loading || buttonEmpty"
          (click)="saveSplashScreen()">
          Save
        </button>
      </div>
    </div>
  </div>
</p-dialog>

<app-cropper-dialog 
  [show]="cropping"
  [type]="'splash'"
  [disableDelete]="true"
  [closable]="false"
  [image]="backgroundFile"
  (onCropped)="onCroppedImage($event)"
  (onUpload)="onUploadBackground()"
  (onHide)="cropping=false">
</app-cropper-dialog>