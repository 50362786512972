<div class="form-group content-slide scaliing-artwork" [style]="{'margin-bottom': '33px'}">
  <div class="title-content">
      <label>Scaling</label>
      <input-number 
          [value]="scalingValue"
          (onChange)="updateScalingViaInput($event)"
          (onBlur)="editorService.onInput=$event"
          (onFocus)="editorService.onInput=$event"
          [disabled]="editorService.blockUserAction"
          [min]="minScaling"
          [max]="500">
      </input-number>
  </div>
  <p-slider 
      [(ngModel)]="scalingValue" 
      (onChange)="handleOnChangeSlider()"
      (onSlideEnd)="scalingArtwork('end-scaling')"
      [disabled]="editorService.blockUserAction||editorService.onInput"
      [step]="1" 
      [min]="minScaling" 
      [max]="500"
      [styleClass]="editorService.blockUserAction? 'disable-slider ml15' :'ml15'">
  </p-slider>
</div>