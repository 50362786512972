// Angular packages
import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router, RouterLinkActive, RouterLink } from '@angular/router';

// User-defined services
import { MainService } from '@services';
import { LayoutsService } from '../layouts.service';

// Environment
import { environment } from '@environments';
import { BillingService } from 'src/app/pages/settings/billing/billing.service';
import { User } from '@interfaces';
import { WhiteLabelService } from 'src/app/components/white-label/white-label.service';
import { ProfileService } from 'src/app/pages/profile/profile.service';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-sidemenu',
    templateUrl: './sidemenu.component.html',
    styleUrls: ['./sidemenu.component.scss'],
    standalone: true,
    imports: [NgIf, RouterLinkActive, RouterLink]
})
export class SidemenuComponent {
  public isInside: boolean = true;
  public defaultAvatar: string = environment.staticAssets+'images/other/default-avatar.png?t='+this.mainService.appVersion;
  public whiteLabel: boolean = this.whiteLabelService.whiteLabel;
  @Input() userInfo: User;

  constructor(
    public mainService: MainService,
    public layoutsService: LayoutsService,
    public whiteLabelService: WhiteLabelService,
    private _billingService: BillingService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _profileService: ProfileService,
  ) {}

  ngOnInit(): void {
    if (!this.whiteLabelService.whiteLabel) {
      this._route.paramMap.subscribe(params => {
        const username = params.get('username');
        if (username) this.isInside = username === this.mainService.userInfo?.username;
        if (this.isInside) this._getSubscriptionOverview();
        if (this._isInSettings()) this.layoutsService.settingsMenu = true;
        else if (!this._isInSettings() && !this.isInside) this.layoutsService.settingsMenu = false;
      })
    } else {
      this.isInside = false;
    }
  }

  /**
   * ANCHOR Get Subscription Overview
   * @description get subscription overview
   * @param refetch : boolean
   */
  private _getSubscriptionOverview(refetch:boolean = false): void {
    this._billingService.getSubscriptionOverview(refetch).subscribe((response: any) => {
      const product = response.data.product_detail.product.toLowerCase();
      this.layoutsService.isExperimental = product === 'experimental';
    })
  }

  /**
   * ANCHOR Navigate to
   * @description navigate to the given path
   * @param path : string
   */
  public navigateTo(path): void {
    this.mainService.closeHeaderMessage()
    this._router.navigate([path]);
    this.isActiveMenu(path);
  }

  /**
   * ANCHOR Get User data
   * @description return user data
   * @returns : any
   */
  public userData(): any {
    if(this.mainService.onFetchUser) return {};
    if (this.whiteLabelService.whiteLabel) return this.userInfo;
    return this.mainService.userInfoPublic || this.mainService.userInfo;
  }

  /**
   * ANCHOR To Pricing Page
   * @description navigate to pricing page
   */
  public toPricingPage(): void {
    this._router.navigate(['/pricing']);
  }

  /**
   * ANCHOR Convert path image to img2.villume.com
   * @description convert path image to img2.villume.com
   * @param path : string
   * @returns : string
   */
  public convertPathImage(path: string): string {
    if (!path) return `${environment.staticAssets}images/other/default-avatar.png?t=${this.mainService.appVersion}`;
    if (path.includes('https://')) return path;
    return this.mainService.convertPathImage(path);
  }

  /**
   * ANCHOR Check if active menu
   * @description check if the given path is active
   * @param path : string
   * @returns : boolean
   */
  public isActiveMenu(path: string): boolean {
    return this._router.url === path;
  }

  /**
   * ANCHOR Toggle Settings Menu
   * @description toggle settings menu
   * @param action 
   * @returns 
   */
  public toggleSettingsMenu(action: boolean): void {
    if (action) {
      this.layoutsService.settingsMenu = !this.layoutsService.settingsMenu;
      this._router.navigateByUrl('/settings/profile')
      return;
    }
  }

  /**
   * ANCHOR Detect if current page is in settings
   * @description detect if the current page is in settings
   * @returns : boolean
   */
  private _isInSettings(): boolean {
    const urls = [
      '/settings/profile',
      '/settings/account',
      '/settings/billing'
    ];

    return urls.includes(this._router.url);
  }

  createExhibition(create:boolean = false) {
    this.mainService.closeHeaderMessage();
    this._profileService.startCreating(() => {
      this._router.navigate([this.mainService.userInfo.username+'/create']);
    })
  }

  isCreateExhibition(): boolean {
    return this._router.url.includes('create');
  }

}
