<app-align-object 
    [alignLimit]="[editorService.topLimit.position.y,editorService.bottomLimit.position.y]"
    [exhibitionHeight]="editorService.activeExhibitionNode?.dimensions.height"
    [disabled]="editorService.blockUserAction"
    [inputFocus]="editorService.onInput"
    [showAlignLimit]="editorService.showAlignLimit"
    [activeNodes]="editorService.selectedExhibitAssets"
    (onChangeDisplay)="showAlignLimitMesh($event)"
    (onAdjustLimit)="adjustAlignLimit($event)"
    (onChangePosition)="alignArtworkNodes($event)"
    (onInput)="editorService.onInput=$event">
</app-align-object>
