import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Validators, FormControl, FormsModule, NgModel } from '@angular/forms';
import { EditorService } from '../../editor.service';
import { ArtworkLoaderService, ArtworkShadowsService, MainService } from "src/app/shared/services";
import { cloneDeep } from 'lodash';

import { AlertMessageService } from 'src/app/components/alert-message/alert-message.service';
import { Artwork, ICreateFrameComponentOptions, Position } from '@interfaces';
import { debounce } from 'lodash';
import { environment } from '@environments';
import { max } from 'moment';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { InputColor } from '../../../../../components/input-color/input-color.component';
import { InputNumberComponent } from '../../../../../components/input-number/input-number.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TooltipModule } from 'primeng/tooltip';
import { SliderModule } from 'primeng/slider';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { NgIf, NgStyle, NgSwitch, NgSwitchCase, NgFor, NgClass } from '@angular/common';

declare const BABYLON: any;

@Component({
    selector: 'app-edit-frame',
    templateUrl: './edit-frame.component.html',
    styleUrls: ['./edit-frame.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgIf,
        SliderModule,
        FormsModule,
        NgStyle,
        TooltipModule,
        InputSwitchModule,
        NgSwitch,
        NgSwitchCase,
        InputNumberComponent,
        InputColor,
        LazyLoadImageModule,
        ScrollPanelModule,
        NgFor,
        NgClass]
})

export class EditFrameComponent implements OnInit {
    private engine: any;
    private canvas: any;
    public scene: any;
    public camera: any;
    public cameraView: number;
    public figureMesh: any;
    public figureMeshRadiusChange: boolean = false;
    public figureDataCloned: Artwork;
    public showActionMenuFrame: any;
    public light: any = null;
    public env = environment;

    public generalThinkness: number = 0;
    public frameThinkness: number = 0;
    public frameWidth: number = 0;
    public passepartoutThinkness: number = 0;
    public passepartoutWidth: number = 0;
    public passepartoutRadius: number = 0;
    public frameRadius: number = 0;
    public passeMaxRadiusSlider: number = 0;
    public frameId:any;
    public onDragover: boolean = false;
    public frameName:any = new FormControl("", [Validators.required]);
    public dataEditFrame:any;
    public sidFrameTemplateNew:any;
    public isLoading:boolean = false;
    public useFrameTemplate:boolean = false;
    private _markerMaster: any;
    private _markerMaterial: any;
    private _markerRadiusMaster: any;
    private _previewMeshes: any = []
    private _radiusMarker: any;

    @Input() figureData: any;
    @Output() onDataChange: any = new EventEmitter;

    public tab: string = 'passepartout';
    public onUploding: boolean = false;

    public checked: boolean;
    public height: boolean;
    public setColor: boolean = false;
    public color: string;
    public dragAngDrop: boolean = false;

    public loadingListFrame:boolean = false;
    public listFrame:any = [];
    private zoomAction: any;
    private artworkImageOriginalTexture: any = ""; 
    private artworkImagePlaceholderTexture: any = ""; 
    public onLoadingArtwork: boolean = false;

    // Frame Template
    public selectedEditedFrameTemplate: any = {};
    public selectedDeletedFrameTemplate: any = null;
    public frameTemplates: any = [];
    public onFetchingFrameTemplate: boolean = false;
    public onLoadingButtonFrameTemplatePopup: boolean = false;
    public onLoadingSaveAsTemplate: boolean = false;
    public linkedWithTemplate: boolean = false;

    public passepartoutWidthAllSlider: number = 0;
    public passepartoutWidthLeft: number = 0;
    public passepartoutWidthRight: number = 0;
    public passepartoutWidthTop: number = 0;
    public passepartoutWidthBottom: number = 0;

    public frameWidthAllSlider: number = 0;
    public frameWidthLeft: number = 0;
    public frameWidthRight: number = 0;
    public frameWidthTop: number = 0;
    public frameWidthBottom: number = 0;


    //measurements
    public measurementsPass: boolean = false;
    public measurementsFrame: boolean = false;

    public passepartout_radius: number = 0;

    constructor(
        public editorService: EditorService,
        public mainService: MainService,
        private _alertMessageService: AlertMessageService,
        private _artworkLoaderService: ArtworkLoaderService,
        private _artworkShadowsService: ArtworkShadowsService,
    ) {
    }

    ngOnInit(): void {
        this.getFrameTemplates();
    }

    /**
	 * * ====================================================================================== *
	 * * SECTIONS LIST 																	        *
	 * * ====================================================================================== *
	 * - DELETE FRAME TEMPLATE FUNCTIONS SECTION
     * - EDIT FRAME TEMPLATE FUNCTIONS SECTION
     * - SAVE FRAME TEMPLATE FUNCTIONS SECTION
     * - FETCHING FRAME TEMPLATES DATA FUNCTIONS SECTION
     * - SETUP BABYLON CORE NODE FUNCTIONS SECTION
     * - RENDER EDIT FRAME SCENE FUNCTIONS SECTION
     * - LOAD ARTWORK FUNCTIONS SECTION
	 */





    /**
     * * =============================================== *
     * * DELETE FRAME TEMPLATE FUNCTIONS SECTION
     * * =============================================== *
     * - SHOW DELETE FRAME TEMPLATE POPUP
     * - DELETE FRAME
     * - SET TO DEFAULT FRAME SETTING
     * - CREATE REQUEST DATA FOR DELETE FRAME
     * - UPDATE SID DELETED FRAME TEMPLATES ARRAY 
     * - UPDATE EDITED FRAME TEMPLATES ARRAY
     */

    /**
     * * SHOW DELETE FRAME TEMPLATE POPUP *
     * Todo: to show delete frame template popup
     */
    showDeleteFramePopup(item){
        this.editorService.displayDeleteFrameTemplatePopop = true;
        this.selectedDeletedFrameTemplate = item;
    }

    /**
     * * UPDATE SID DELETED FRAME TEMPLATES ARRAY *
     * Todo: to update array data "sidDeletedFrameTemplates"
     */
    updateSidDeletedFrameTemplatesArray(){
        const editedFrameTempate = this.editorService.editedFrameTemplates.find((item: any) => {
            return item.newSidTemplate == this.selectedDeletedFrameTemplate.sid_template;
        })
        if(editedFrameTempate) this.editorService.sidDeletedFrameTemplates.push(editedFrameTempate.oldSidTemplate);
        else this.editorService.sidDeletedFrameTemplates.push(this.selectedDeletedFrameTemplate.sid_template)
    }

     /**
     * * UPDATE EDITED FRAME TEMPLATES ARRAY *
     * Todo: to update array data "editedFrameTemplates"
     */
    updateEditedFrameTemplatesArray(){   
        this.editorService.editedFrameTemplates = this.editorService.editedFrameTemplates.filter((x: any) =>{
            return x.newSidTemplate != this.selectedDeletedFrameTemplate.sid_template;
        })
    }

	/**
	 * * DELETE FRAME *
	 * Todo: to delete frame template
	 */
	deleteFrameTemplate() {
        this.onLoadingButtonFrameTemplatePopup = true;
        const requestData = this.createRequestDataDeleteFrameTemplate();
		this.editorService.deleteFrameTemplate(requestData).subscribe((response:any) => {
            this.getFrameTemplates(true);
            this.setToDefaultFrameSetting();
            this.updateEditedFrameTemplatesArray();
            this.editorService.updateJsonVersion().subscribe();
            this.updateSidDeletedFrameTemplatesArray();

            this.selectedDeletedFrameTemplate = null;
            this.onLoadingButtonFrameTemplatePopup = false;
			this.editorService.displayDeleteFrameTemplatePopop = false
			this._alertMessageService.add({severity:"success",summary:"Success", detail: response?.data?.messages})

		}, err => {
            this.selectedDeletedFrameTemplate = null;
            this.onLoadingButtonFrameTemplatePopup = false;
            this.editorService.displayDeleteFrameTemplatePopop=false;

            if(err.error.statusCode==401){
                this.mainService.expiredSesionPopup = true;
            }else{
                this._alertMessageService.add({severity:"error",summary:"Error", detail: "Something went wrong. Failed to delete Frame Template Failed"})
            }
		})
	}

    /**
     * * SET TO DEFAULT FRAME SETTING *
     * Todo: set to default frame setting
     */
    setToDefaultFrameSetting(){
        if(this.figureDataCloned.frame.sid_frame === this.selectedDeletedFrameTemplate.sid_template){
            const frameId = this.figureDataCloned.frame.id;
            this.figureDataCloned.frame = cloneDeep(this.editorService.defaultFrameTemplate);
            this.figureDataCloned.frame.id = frameId;
            this.onLoadingArtwork = true;
            this.loadArtworkWithDelay();
        }
    }

    /**
     * * CREATE REQUEST DATA FOR DELETE FRAME *
     * Todo: to create request data for delete frame
     */
    createRequestDataDeleteFrameTemplate(){
        return {
            exceptFrameId: this.getAllArtworkFrameIdInThisExhibition(),
            frameId: this.selectedDeletedFrameTemplate.id
        }
    }




    
    /**
     * * =============================================== *
     * * EDIT FRAME TEMPLATE FUNCTIONS SECTION
     * * =============================================== *
     * - SHOW EDIT FRAME TEMPLATE POPUP
     * - EDIT FRAME TEMPLATE
     * - UPDATED EDITED FRAME TEMPLATE LIST
     * - CREATE REQUEST DATA EDIT FRAME TEMPLATE
     */
    
    /**
	 * * SHOW EDIT FRAME TEMPLATE POPUP *
	 * Todo: to show edit frame template popup
	 */
    showEditFrameTemplateModePopup(item: any) {
        this.frameName.setValue(item.frame_name);
        this.selectedEditedFrameTemplate = item;
        this.editorService.editFrameTemplateMode = true;
        this.editorService.displaySaveEditFrameTemplatePopup = true;
    }

    /**
     * * EDIT FRAME TEMPLATE *
     * Todo: to edit frame template
     */
    async editFrameTemplate(){
        this.onLoadingButtonFrameTemplatePopup = true;
        const requestData = await this.createRequestDataEditFrameTemplate();

        this.editorService.editFrameTemplate(requestData).subscribe((res: any) =>{
            const newSidTemplate = res.data.sid_template;

            if(this.figureDataCloned.frame.sid_frame === this.selectedEditedFrameTemplate.sid_template){
                this.figureDataCloned.frame.sid_frame = newSidTemplate;
                this.linkedWithTemplate = true;
            }

            this.updateEditedFrameTemplateList(newSidTemplate);
            this.getFrameTemplates(true);
			this.editorService.updateJsonVersion().subscribe();

            
            this.selectedEditedFrameTemplate = null;
            this.onLoadingButtonFrameTemplatePopup = false;
            this.editorService.editFrameTemplateMode = false;
            this.editorService.displaySaveEditFrameTemplatePopup = false;

            this._alertMessageService.add({severity:"success", summary:"Success", detail: "Your Frame was successfully edited"})
        }, (err: any) => {
            this.selectedEditedFrameTemplate = null;
            this.onLoadingButtonFrameTemplatePopup = false;
            this.editorService.editFrameTemplateMode=false;
            this.editorService.displaySaveEditFrameTemplatePopup=false;

            if(err.error.statusCode==401){
                this.mainService.expiredSesionPopup = true;
            } else if (err.error.statusCode == 409){
                this._alertMessageService.add({
                    severity: "warn", 
                    summary:"Warning",
                    detail: err.error.data.errors.messages
                })
            }else {
                this._alertMessageService.add({severity: "error", summary:"Failed",detail:"Something went wrong. Failed to edit frames."})
            }
        })
    }

    /**
     * * UPDATED EDITED FRAME TEMPLATE LIST *
     * Todo: to update edited frame template list
     */
    updateEditedFrameTemplateList(newSidTemplate){
        const editedFrameTemplate = this.editorService.editedFrameTemplates.find((x: any) => {
            return x.newSidTemplate == this.selectedEditedFrameTemplate.sid_template
        });

        if(editedFrameTemplate){
            editedFrameTemplate.newSidTemplate = newSidTemplate;
            editedFrameTemplate.frameData = cloneDeep(this.figureDataCloned.frame);
        }else{
            this.editorService.editedFrameTemplates.push({
                oldSidTemplate: this.selectedEditedFrameTemplate.sid_template,
                newSidTemplate: newSidTemplate,
                frameData: cloneDeep(this.figureDataCloned.frame)
            })
        }
    }

    /**
     * * CREATE REQUEST DATA EDIT FRAME TEMPLATE *
     * Todo: to create request data edit frame template
     */
    async createRequestDataEditFrameTemplate(){
        const frame = { ...this.figureDataCloned.frame.frame};
				const passepartout = { ...this.figureDataCloned.frame.passepartout};

				delete frame.frame_texture_url;    
				delete passepartout.passepartout_texture_url;
      
        return {
          frameName: this.frameName.value.trim(),
          thumbnail: await this.getThumbnail(),
          frame,
          passepartout,
          backFrame: this.figureDataCloned.frame.back_frame,
          templateId: this.selectedEditedFrameTemplate.id,
          exceptFrameId: this.getAllArtworkFrameIdInThisExhibition(),
        };
    }





    /**
     * * =================================================== *
     * * SAVE FRAME TEMPLATE FUNCTIONS SECTION
     * * ================================================= *
     * - SHOW SAVE FRAME TEMPLATE POPUP
     * - SAVE FRAME TEMPLATE
     * - CREATE REQUEST DATA EDIT FRAME TEMPLATE
     */

     /**
	 * * SHOW SAVE FRAME TEMPLATE POPUP *
	 * Todo: to show edit frame template popup
	 */
    showSaveFrameTemplateModePopup() {
        this.frameName.reset();
        this.editorService.editFrameTemplateMode = false;
        this.editorService.displaySaveEditFrameTemplatePopup = true;
    }

    /**
     * * SAVE FRAME TEMPLATE *
     * Todo: to save frame template
     */
    async saveFrameTemplate(saveAs: boolean = false){
        if(saveAs){
            this.onLoadingSaveAsTemplate = true;
        }else{
            this.onLoadingButtonFrameTemplatePopup = true;
        }
        
        const reData = await this.createRequestDataSaveFrameTemplate();

        this.editorService.saveFrameTemplate(reData).subscribe((res: any) => {
            this.figureDataCloned.frame.sid_frame = res.data.sid_template;
            this.linkedWithTemplate = true;
            this.tab = "saved";
            this.getFrameTemplates(true);

            this.onLoadingSaveAsTemplate = false;
            this.onLoadingButtonFrameTemplatePopup = false;
            this.editorService.displaySaveEditFrameTemplatePopup = false;
            this._alertMessageService.add({severity:"success", summary:"Success", detail: "Your Frame was successfully saved"});
        }, err => {
            this.onLoadingSaveAsTemplate = false;
            this.onLoadingButtonFrameTemplatePopup = false;
            this.editorService.displaySaveEditFrameTemplatePopup = false;

            if(err.error.statusCode == 401){
                this.mainService.expiredSesionPopup = true;
            } else if (err.error.statusCode == 409){
                this._alertMessageService.add({
                    severity: "warn", 
                    summary:"Warning",
                    detail: err.error.data.errors.messages
                })
            }else {
                this._alertMessageService.add({
                    severity: "error", 
                    summary:"Failed",
                    detail:"Something went wrong. Failed to add frames."
                })
            }
        })
    }

     /**
     * * CREATE REQUEST DATA EDIT FRAME TEMPLATE *
     * Todo: to create request data edit frame template
     */
    async createRequestDataSaveFrameTemplate(){
			const frame = { ...this.figureDataCloned.frame.frame};
			const passepartout = { ...this.figureDataCloned.frame.passepartout};

			delete frame.frame_texture_url;
			delete passepartout.passepartout_texture_url;

			return {
				frameName: this.frameName.value.trim(),
				thumbnail: await this.getThumbnail(),
				frame,
				passepartout,
				backFrame: this.figureDataCloned.frame.back_frame,
			};
    }





    /**
     * * =================================================== *
     * * FETCHING FRAME TEMPLATES DATA FUNCTIONS SECTION
     * * ================================================= *
     * - GET FRAME TEMPLATES
     */

    /**
     * * GET FRAME TEMPLATES *
     * Todo: to get frame templates
     */
    getFrameTemplates(refetch: boolean = false){
      this.onFetchingFrameTemplate = true;
      this.editorService.getFrameTemplate(refetch).subscribe((
        {data}: any) => {
					this.frameTemplates = data.frame_templates.map((frame)=>{
						frame.thumbnail = this.mainService.convertPathImage(frame.thumbnail);

						if (frame.frame.frame_texture) {
							frame.frame.frame_texture_url = this.mainService.convertPathImage(frame.frame.frame_texture);
						}

						if (frame.passepartout.passepartout_texture) {
							frame.passepartout.passepartout_texture_url = this.mainService.convertPathImage(frame.passepartout.passepartout_texture);
						}
						return frame;
					})

					this.onFetchingFrameTemplate = false;
				},
        err => {
					if(err.error.statusCode==401 || err.errors.message == "Malformed Authorization header"){
						this.mainService.expiredSesionPopup = true;
					}else {
						this._alertMessageService.add({severity:"error", summary:'Failed', detail:"Something went wrong. Failed get list frame template."})
					}
					this.onFetchingFrameTemplate = false;
				}
			);
    }





    /**
     * * =================================================== *
     * * SETUP BABYLON CORE NODE FUNCTIONS SECTION
     * * =================================================== *
     * - SETUP LIGHTS
     * - CREATE LIGHT NODE
     * - SETUP SCENE
     * - SETUP CAMERA
     */

    /**
     * * INIT CANVAS AND ENGINE *
     * Todo: to initialize canvas and engine
     */
    initCanvasAndEngine(){
        this.canvas = document.getElementById("editFrameCanvas");
        this.engine = new BABYLON.Engine(this.canvas);
    }

    /**
     * * SETUP LIGHTS *
     * Todo: for setup lighting
     */
    setupLight() {
        const light = new BABYLON.HemisphericLight("mainLigth", new BABYLON.Vector3(0, 0, 0), this.scene);
        light.intensity = 1.85;
    //     const backLight = this.createLight(new BABYLON.Vector3(0, 0, -3), 0.5)
    //     const topLight = this.createLight(new BABYLON.Vector3(0, 5, 3), 0.5)
    //     const leftLight = this.createLight(new BABYLON.Vector3(5, 0, 3), 0.5)
    //     const rightLight = this.createLight(new BABYLON.Vector3(-5, 0, 3), 0.5)
    //     const bottomLight = this.createLight(new BABYLON.Vector3(0, -5, 3), 0.5)
    }
     

    /**
     * * CREATE LIGHT NODE *
     * Todo: to create light node
     */
    createLight(position, intensity){
        const light = new BABYLON.SpotLight("spotLight", position, BABYLON.Vector3.Zero(), Math.PI * 2, 1, this.scene);
        light.intensity = intensity;
        light.setDirectionToTarget(BABYLON.Vector3.Zero());
        return light;
    }

    /**
     * * SETUP SCENE *
     * Todo: for setup scene for preview frame figure
     */
    setupScene() {
        this.engine = new BABYLON.Engine(this.canvas, true);
        this.scene = new BABYLON.Scene(this.engine);
        this.scene.clearColor = BABYLON.Color3.FromHexString("#495057");
    }

    /**
     * * SETUP CAMERA *
     * Todo: for setup camera
     */
    setupCamera() {
        this.camera = new BABYLON.ArcRotateCamera("Camera Figure", Math.PI / 2, Math.PI * 0.5, 5, BABYLON.Vector3.Zero(), this.scene);
        this.camera.attachControl(this.canvas, true);
        this.camera.wheelPrecision = 50;
        this.camera.minZ = -10;
        this.scene.beforeRender = () => {
            this.cameraView = -this.camera.radius;
        }
        this.camera.inputs.attached.pointers.buttons = [0];
    }





    /**
     * * =================================================== *
     * * RENDER EDIT FRAME SCENE FUNCTIONS SECTION
     * * =================================================== *
     * - RENDER SCENE
     * - RENDER EDIT FRAME SCENE
     * - RESET CAMERA POSITION
     * - DETECT FRAME LINKED TO FRAME TEMPLATE
     */

    /**
     * * RENDER SCENE *
     * Todo: for rendering scene
     */
    renderScene(rendered: boolean) {
        if (!rendered) {
            this.engine.stopRenderLoop()
        } else {
            this.engine.runRenderLoop(() => this.scene.render());
            window.addEventListener("resize", () => this.engine.resize());
        };
    }

    /**
     * * RENDER EDIT FRAME SCENE *
     * Todo: for render edit frame scene
     */
    renderEditFrameScene() {
        this.figureDataCloned = JSON.parse(JSON.stringify(this.figureData));
        this.tab = "passepartout";
        setTimeout(() => {
            // Setup core object babylon js
            this.initCanvasAndEngine();
            this.setupScene();
            this.setupCamera();
            this.setupLight();
            this._createMarkerMaterial();
            this._createMarker();
            this._createRadiusMarker();
            
            // Initialize input action
            this.initDragDrop();
            this.setSliderManualInputsValue();

            // Reset camera position
            this.resetCameraPosition();

            // Render and load figure
            this.onLoadingArtwork = true;
            this.loadArtwork(this.detectFrameLinkedToFrameTemplate(), true);

            // this.scene.debugLayer.show();

            this.renderScene(true);

        }, 100);
    }

    /**
     * * RESET CAMERA POSITION *
     * Todo: to reset camera position
     */
    resetCameraPosition(){
        this.camera.alpha = Math.PI / 2;
        this.camera.beta = Math.PI * 0.5;
    }

    /**
     * * DETECT FRAME LINKED TO FRAME TEMPLATE *
     * Todo: to detect frame liked to frame template or not
     */
    detectFrameLinkedToFrameTemplate(){
        const sidFrameTemplates = this.frameTemplates.map((item: any) => item.sid_template);
        const frameLikedToFrameTemplate = sidFrameTemplates.includes(this.figureDataCloned.frame.sid_frame);
        return frameLikedToFrameTemplate;
    }





    /**
     * * =================================================== *
     * * LOAD ARTWORK FUNCTIONS SECTION
     * * =================================================== *
     * - LOAD ARTWORK TO SCENE
     * - SET POSITION ROTATION ARTWORK 
     * - RECALCULATION CAMERA RADIUS AFTER LOAD ARTWORK
     */



    // ================================================================== //
    // UNCATEGIZED FUNCTIONS
    // ================================================================== //

    getAllArtworkFrameIdInThisExhibition(){
        const arrayIds = this.editorService.artworks.map((artworkData: any) => artworkData.frame.id );
        return arrayIds.join(",")
    }
    

    /**
     * * ENABLE TEXTURE/COLOR *
     * Todo: for enable color texture of frame/passepartout
     */
    enableTexureColor(type: "frame" | "passepartout", field) {
        this.figureDataCloned.frame[type][`${type}_material_${field == 'color' ? 'texture' : 'color'}`] = !this.figureDataCloned.frame[type][`${type}_material_${field}`]
        this.onLoadingArtwork = true;
        this.loadArtworkWithDelay();
        this.initDragDrop();
    }

    /**
     * * CHANGE COLOR *
     * Todo: for change color of frame/passepartout/backfigure
     */
    changeColor(type: "frame" | "passepartout" | "backFrame") {
        switch (type) {

            // Apply color to frame
            case "frame":
                let frame = this.figureMesh.getChildren().find(child => child.name == "frame");
                frame.material.diffuseColor = BABYLON.Color3.FromHexString(this.figureDataCloned.frame.frame.frame_color);
                this.figureDataCloned.frame.back_frame.back_frame_color = this.figureDataCloned.frame.frame.frame_color;
                this.changeColor("backFrame");
            break;

            // Apply color to passepartout
            case "passepartout":
                let passepartout = this.figureMesh.getChildren().find(child => child.name == "passepartout");
                passepartout.material.diffuseColor = BABYLON.Color3.FromHexString(this.figureDataCloned.frame.passepartout.passepartout_color);
                if (!this.figureDataCloned.frame.frame) {
                    this.figureDataCloned.frame.back_frame.back_frame_color = this.figureDataCloned.frame.passepartout.passepartout_color;
                    this.changeColor("backFrame")
                }
            break;

            // Apply color to passepartout
            case "backFrame":
                let backFigure = this.figureMesh.getChildren().find(child => child.name == "backFrame");
                backFigure.material.diffuseColor = BABYLON.Color3.FromHexString(this.figureDataCloned.frame.back_frame.back_frame_color)
            break;
        }

        this.linkedWithTemplate = false;
    }

    

    /**
     * * SET SLIDER MANUAL INPUTS VALUE *
     * Todo: to set slider manual input value
     */
    setSliderManualInputsValue() {
        const { frame, passepartout, back_frame } = this.figureDataCloned.frame;
        this.generalThinkness = Math.round(back_frame.back_frame_depth * 100);
        this.frameThinkness = Math.round(frame.frame_depth * 100);
        this.passepartoutThinkness = Math.round(passepartout.passepartout_depth * 100);
        this.passepartoutRadius = Math.round(passepartout.passepartout_radius * 100);
        this.frameRadius = Math.round(frame.frame_radius * 100);

        this._initmeasurementsValues();
        const minValuePass = Math.min(
            this.passepartoutWidthTop,
            this.passepartoutWidthBottom,
            this.passepartoutWidthLeft,
            this.passepartoutWidthRight
        );

        const minValueFrame = Math.min(
            this.frameWidthTop,
            this.frameWidthBottom,
            this.frameWidthLeft,
            this.frameWidthRight
        );

        this.passepartoutWidth = minValuePass;
        this.passepartoutWidthAllSlider = minValuePass / 100;
        this.frameWidth = minValueFrame;
        this.frameWidthAllSlider = minValueFrame / 100;
    }

    private _initmeasurementsValues(): void {
        this.passepartoutWidthLeft = Math.round(this.figureDataCloned.frame.passepartout.passepartout_width_left * 100);
        this.passepartoutWidthRight = Math.round(this.figureDataCloned.frame.passepartout.passepartout_width_right * 100);
        this.passepartoutWidthTop = Math.round(this.figureDataCloned.frame.passepartout.passepartout_width_top * 100);
        this.passepartoutWidthBottom = Math.round(this.figureDataCloned.frame.passepartout.passepartout_width_bottom * 100);

        this.frameWidthLeft = Math.round(this.figureDataCloned.frame.frame.frame_width_left * 100);
        this.frameWidthRight = Math.round(this.figureDataCloned.frame.frame.frame_width_right * 100);
        this.frameWidthTop = Math.round(this.figureDataCloned.frame.frame.frame_width_top * 100);
        this.frameWidthBottom = Math.round(this.figureDataCloned.frame.frame.frame_width_bottom * 100);
    }

    public maxPasseRadius(type: 'slider' | 'inputNumber'): number {
        const { real_height, real_width } = this.figureDataCloned;
        const max = Math.min(real_height, real_width) / 2;
        if(type == 'inputNumber') return max * 100;
        return max;
    }
    

    public maxFrameRadius(type: 'slider' | 'inputNumber'): number {
        const { 
            passepartout, 
            passepartout_width_left,
            passepartout_width_right,
            passepartout_width_top,
            passepartout_width_bottom
        } = this.figureDataCloned.frame.passepartout;
        const { frame } = this.figureDataCloned.frame.frame
        const { real_height, real_width } = this.figureDataCloned;
        if(frame && !passepartout) {
            const max = Math.min(real_height, real_width) / 2;
            if(type == 'inputNumber') return max * 100;
            return max;
        }

        if(frame && passepartout) {
            const passeWidth = (passepartout_width_left + passepartout_width_right + real_width)/2;
            const passeHeight = (passepartout_width_top + passepartout_width_bottom + real_height)/2;

            const max = Math.min(passeWidth, passeHeight);
            if(type == 'inputNumber') return max * 100;
            return max;
        }
    }

    public isPasseInputDisabled(): boolean {
        return this.onUploding || !this.figureDataCloned.frame.passepartout.passepartout;
    }

    /**
     * * APPLY TEXTURE *
     * Todo: for apply texture to figure frame or passepartout
     */
    applyTexture(url, path, type: "frame" | "passepartout") {
        if (type == "frame") {
						this.figureDataCloned.frame.frame.frame_texture = path;
            this.figureDataCloned.frame.frame.frame_texture_url = url;
        }
        if (type == "passepartout") {
						this.figureDataCloned.frame.passepartout.passepartout_texture = path;
            this.figureDataCloned.frame.passepartout.passepartout_texture_url = url;
        }
        
        this.onLoadingArtwork = true;
        this.loadArtworkWithDelay();
    }

    /**
     * * UPLOAD TEXTURE *
     * Todo: for apply texture to figure frame or passepartout
     */
    async uploadTexture(file, type, fromDrag = false) {
        let imgFile;
        if (fromDrag) imgFile = file;
        else imgFile = file.target.files[0];
        
        if (imgFile) {
            this.onUploding = true;
            // get file extension and set allowed format file data
            const fileExtention = imgFile['name'].split(".").slice(-1)[0];
            const allowedFormatFile = ['png', "jpg", "jpeg"];

            // validate format file
            if (allowedFormatFile.includes(fileExtention.toLowerCase())) {
                // validate file size
                if (imgFile.size / 1024 / 1024 <= 2) {
                    try {
                        // validate broken image
                        await this.validateBrokenImg(imgFile);

                        // create form data
                        const formData = new FormData();
                        formData.append('file', imgFile);

                        // upload texture to server
                        this.editorService.uploadTexture(formData, type, this.figureDataCloned.frame.id).subscribe((res: any) => {
                            if (res.status == "response") {
                                const pathTexture = this.mainService.convertPathImage(res.data.data.path);
                                const pathTextureOriginal = res.data.data.path;
                                this.applyTexture(pathTexture, pathTextureOriginal, type)
                                this.onUploding = false;
                            }
                        }, err => {
                            this.onUploding = false;
                            this._alertMessageService.add({
                                severity: "error",
                                summary: "Error",
                                detail: "Something went wrong. Failed to add texture."
                            })
                        })
                    } catch (error) {
                        this.onUploding = false;
                        this._alertMessageService.add({
                            severity:"warn", 
                            summary:"Warning", 
                            detail:"Corrupted file!"
                        });
                    }
                } else {
                    this.onUploding = false;
                    this._alertMessageService.add({
                        severity: "warn",
                        summary: "Warning",
                        detail: "The file size is too large. Please only upload files less than 2 MB for stable performance."
                    })
                }
            } else {
                this.onUploding = false;
                this._alertMessageService.add({
                    severity: "warn",
                    summary: "Warning",
                    detail: "This file format is not supported. Please upload .png, .jpeg, or .jpg"
                })
            }
        }

        // clear input file
        if (!fromDrag) file.target.value = '';
    }

    /**
     * * VALIDATE BROKEN IMAGE *
     * Todo: to validate broken image
     */
    validateBrokenImg(file: File) {
        return new Promise((resolve, reject) => {
        const url = URL.createObjectURL(file);
        const img = new Image();
        img.onerror = reject;
        img.onload = resolve;
        img.src = url;
        })
    }

    /**
     * * CLEAR EDIT FRAME SCENE *
     * Todo: for clearing figure and the scene
     */
    clearEditFrameScene() {
        this.editorService.blockUserAction = false;
        this.figureMesh.dispose();
        this.figureDataCloned = null;
        this.figureMeshRadiusChange = false;
        this.renderScene(false)
    }

    /**
     * * INIT DRAG AND DROP *
     * Todo: initialize drag and drop function
     */
    initDragDrop() {
        setTimeout(()=>{
            let dropArea: any;
            if (this.tab == "frame") dropArea = document.getElementById("dropAreaFrame");
            if (this.tab == "passepartout") dropArea = document.getElementById("dropAreaPassepartout");
    
            // Process the result file from drag
            const proccesFile = (e) => {
                if (this.figureDataCloned.frame[this.tab][this.tab + '_material_texture']) {
                    const files = Array.from(e.dataTransfer.files);
                    if (files.length == 1) return files[0]
                    else {
                        return null;
                    }
                } else {
                    return null;
                }
            }

            if (dropArea) {
                // Init drag and drop function
                this.editorService.initDragAndDropFuction(dropArea, {
                    ondrop: (e) => {
                        this.onDragover = false
                        const file = proccesFile(e);
                        if (file) this.uploadTexture(file, this.tab, true);
                    },
                    ondragover: () => {
                        if (this.figureDataCloned.frame[this.tab][this.tab + '_material_texture']) {
                            this.onDragover = true
                        }
                    },
                    ondragleave: () => { this.onDragover = false }
                })
            }
        },500)
    }

    /**
     * * ADJUST CAMERA VIEW USING BUTTON *
     * Todo: for adjusting camera view via button
     */
    zoom(action, condition) {
        if (condition) {
            switch (action) {
                case "in":
                    this.figureMeshRadiusChange = true;
                    this.zoomAction = setInterval(() => {
                        this.camera.radius -= 0.01;
                    })
                    break
                case "out":
                    this.figureMeshRadiusChange = true;
                    this.zoomAction = setInterval(() => {
                        this.camera.radius += 0.01;
                    })
                    break
            }
        } else {
            if (this.zoomAction) {
                clearInterval(this.zoomAction)
                this.zoomAction = null;
            }
        }
    }

    /**
     * * SAVE DATA FRAME CHANGES *
     * Todo: for saving data frame changes
     */
    saveChangesFrame() {
        this.editorService.editFrameMode = false;
        if(!this.linkedWithTemplate) this.figureDataCloned.frame.sid_frame = null;
        this.onDataChange.emit(this.figureDataCloned)
    }

    /** SCREEN SHOT ARTWORK *
     * Todo: for adjust thinkness fo frame/passepartout/backfigure
     */
    screenShotArtwork() {
        let lower: any = Math.max(this.camera.lowerRadiusLimit, this.camera.radius);
        if (lower === this.camera.lowerRadiusLimit) {
            this._alertMessageService.add({
                severity: "warn",
                summary: "Warning",
                detail: "The frame can't save because the frame radius is too close."
            });
        } else {
            this.editorService.blockUserAction = true;
            if (this.figureMeshRadiusChange) {
                this.editorService.takeScreenshot(this.camera, this.engine, this.canvas)
                    .then((data) => {
                        this.editorService.downloadFileFromBase64(data, this.figureData.name + "_" + new Date().getTime());
                        this.editorService.blockUserAction = false;
                        this.editorService.updateLogActivity("Take a screenshoot of artwork");
                        this._alertMessageService.add({
                            severity: "success",
                            summary: "Success",
                            detail: "The screenshot was successfully captured."
                        });
                        this.renderEditFrameScene();
                    });
            } else {
                this.camera.radius *= 0.5;
                this.editorService.takeScreenshot(this.camera, this.engine, this.canvas)
                    .then((data) => {
                        this.editorService.downloadFileFromBase64(data, this.figureData.name + "_" + new Date().getTime());
                        this.editorService.blockUserAction = false;
                        this.editorService.updateLogActivity("Take a screenshoot of artwork");
                        this._alertMessageService.add({
                            severity: "success",
                            summary: "Success",
                            detail: "The screenshot was successfully captured."
                        });
                        this.renderEditFrameScene();
                    });
            }
        }
    }

    /**
     * * CALCULATE CAMERA RADIUS *
     * Todo: to calculates camera radius to make the artwork look ideal (neither too far nor too close)
     */
    calculateCameraRadius(){
        this.camera.alpha = Math.PI / 2;
        this.camera.beta = Math.PI * 0.5;
        const cameraViewRange = Math.max(this.figureMesh.scaling.x, this.figureMesh.scaling.y) * 2.5;
        this.camera.radius = 60 / 100 * cameraViewRange;
    }

    /** 
     * * CHANGE ARTWORK IMAGE TEXTURE *
     * Todo: to change artwork image to placeholder or vice versa
    */
    async changeArtworkImage(action: "toPlaceholder" | "toArtworkImage"){
        const artworkImageNode = this.figureMesh.getChildMeshes().find((x:any)=>x.name == "imageVideo");
        switch(action){
            case "toPlaceholder": await this.convertToImagePlaceholder(artworkImageNode); break;
            case "toArtworkImage": this.convertToImageOriginal(artworkImageNode); break;
        }
    }

    /**
     * * CONVERT IMAGE PLACEHOLDER TEXTURE TO ARTWOK IMAGE(ORIGINAL) TEXTURE *
     * Todo: to convert  image placeholder texture to artwork image(original) texture
     */
    convertToImageOriginal(artworkImageNode: any){
        if(this.artworkImageOriginalTexture){
            artworkImageNode.material.diffuseTexture = this.artworkImageOriginalTexture;
            this.artworkImageOriginalTexture = null;
        }
    }

    /**
     * * CONVERT ARTWOK IMAGE TEXTURE TO IMAGE PLACEHOLDER TEXTURE *
     * Todo: to convert artwork image texture to image placeholder texture
     */
    convertToImagePlaceholder(artworkImageNode: any){
        return new Promise((resolve: any,reject: any)=>{
            // Get original texture
            this.artworkImageOriginalTexture = artworkImageNode.material.diffuseTexture;
    
            // Clear previeous image placeholder texture
            if(this.artworkImagePlaceholderTexture) {
                this.artworkImagePlaceholderTexture.dispose();
                this.artworkImagePlaceholderTexture = false;
            }

            // Success handler on loaded texture
            const texureHasLoaded = () => {
                // Apply new texture to artwork
                artworkImageNode.material.diffuseTexture = this.artworkImagePlaceholderTexture;
                resolve(null)
            }

            // Error handler when load texture
            const failedToLoadTexture = () => reject(null);
    
            // Create new image placeholder texture
            const imagePlaceholder = this.generateImagePlaceholderForThumbnail();
            this.artworkImagePlaceholderTexture = new BABYLON.Texture(imagePlaceholder,this.scene,false,true,3,texureHasLoaded,failedToLoadTexture)           

        })
    }  

    /**
     * * GENERATE IMAGE PLACEHOLDER FOR THUMBNAIL *
     * Todo: to generate image placeholder for thumbnail
     */
    generateImagePlaceholderForThumbnail(){
        // Get dumpy width and height
        const width = this.figureDataCloned.real_width * 1000; 
        const heigth = this.figureDataCloned.real_height * 1000; 

        // Create new image placeholder texture
        const imagePlaceholder = this.mainService.createImagePlaceholder(width, heigth, "Artwork Image");

        return imagePlaceholder;

    }

    /**
     * * GET THUMBNAIL FOR FRAME TEMPLATE *
     * Todo: to getting thumbnail from frame template
     */
    async getThumbnail(){
        // Calculate camera radius
        this.calculateCameraRadius();

        // Get screenshot for sceen
        await this.changeArtworkImage("toPlaceholder");
        const base64: any = await this.editorService.takeScreenshot(this.camera,this.engine,this.canvas, 500);
        this.changeArtworkImage("toArtworkImage");
    
        // Convert base64 to file object
        const imageFile: any = await this.mainService.convertBase64ToFile(base64);

        return imageFile;
    }

    /**
     * * CHANGE TAB *
     * Todo: for switch tab
     */
    changeTab(name) {
        if (!this.onUploding) {
            this.tab = name;
            this.initDragDrop();
            
            if(this.tab == "saved" && !this.onFetchingFrameTemplate){
                // this.getFrameTemplates();
            }
        };
    }

    /**
     * * SELECT FRAME TEMPLATE *
     * Todo: to select frame template and update figure
     */
    selectFrameTemplate(seletedFrame) {
        this.figureDataCloned.frame.back_frame = seletedFrame['back_frame'];
        this.figureDataCloned.frame.frame = seletedFrame['frame'];
        if(!this.figureDataCloned.frame.frame.frame_width_bottom){
            const width = this.figureDataCloned.frame.frame.frame_width;
            this.figureDataCloned.frame.frame.frame_width_bottom = width;
            this.figureDataCloned.frame.frame.frame_width_top = width;
            this.figureDataCloned.frame.frame.frame_width_left = width;
            this.figureDataCloned.frame.frame.frame_width_right = width;
        } 
        this.figureDataCloned.frame.passepartout = seletedFrame['passepartout'];
        if(!this.figureDataCloned.frame.passepartout.passepartout_width_bottom){
            const width = this.figureDataCloned.frame.passepartout.passepartout_width;
            this.figureDataCloned.frame.passepartout.passepartout_width_bottom = width;
            this.figureDataCloned.frame.passepartout.passepartout_width_top = width;
            this.figureDataCloned.frame.passepartout.passepartout_width_left = width;
            this.figureDataCloned.frame.passepartout.passepartout_width_right = width;
        }
        this.figureDataCloned.frame.sid_frame = seletedFrame['sid_template'];
        this.onLoadingArtwork = true;
        this.loadArtworkWithDelay(true);
    }



	/**
	 * * TRUNCATE STRING *
	 * Todo: truncate string
	 */
    truncateString(data:any) {
        return data >= 30 ? data.substring(0,2) : data;
    }

	/**
	 * * CLOSE EDIT FRAME *
	 * Todo: -
	 */
    closeEditFrame() {
        this.editorService.editFrameMode = false;
        this.editorService.closeEditFrame = false;
    }

    /**
     * =================================================================================== *
     * NOTE: Below are the functions that have been refactored
     * =================================================================================== *
     */

     /**
     * =================================================================================== *
     * SECTION Preview Changes Functions
     * =================================================================================== *
     */
    //#region 

    /**
     * ANCHOR Update Preview Passepartout Side Changes
     * Todo: to update preview passepartout side changes
     * @param side : 'all' | 'top' | 'bottom' | 'right' | 'left'
     */
    public async updatePreviewPasseSide(side: 'all' | 'top' | 'bottom' | 'right' | 'left'|'passepartout_radius'|'frame_radius'): Promise<void> {
        const { 
            passepartout_width_top: topWidth,
            passepartout_width_bottom: bottomWidth,
            passepartout_width_left: leftWidth,
            passepartout_width_right: rightWidth,
            passepartout_depth: thinkness
        } = this.figureDataCloned.frame.passepartout;
        const { back_frame_depth } = this.figureDataCloned.frame.back_frame;
        const { real_height, real_width } = this.figureDataCloned;

        const zPosition = back_frame_depth + thinkness/2;
        const topPosition = new BABYLON.Vector3(0, real_height/2 + topWidth/2, zPosition);
        const bottomPosition = new BABYLON.Vector3(0, -real_height/2 - bottomWidth/2, zPosition);
        const leftPosition = new BABYLON.Vector3(real_width/2 + leftWidth/2, 0, zPosition);
        const rightPosition = new BABYLON.Vector3(-real_width/2 - rightWidth/2, 0, zPosition);
        const topScale = new BABYLON.Vector3(real_width, topWidth, thinkness);
        const bottomScale = new BABYLON.Vector3(real_width, bottomWidth, thinkness);
        const leftScale = new BABYLON.Vector3(leftWidth, real_height + bottomWidth + topWidth, thinkness);
        const rightScale = new BABYLON.Vector3(rightWidth, real_height + bottomWidth + topWidth, thinkness);

        // Recalculate top/bottom scale when previewing top/bottom side only
        if(['top','bottom'].includes(side)) {
            topScale.x += leftWidth + rightWidth;
            bottomScale.x = topScale.x;
        }

        // Recalculate top/bottom position when left or right width is not equal
        if(leftWidth !== rightWidth) {
            if(side !== 'all') {
                const halfTopScaleX = topScale.x/2;
                const rightSideWidth = real_width/2 + rightWidth;
                const leftSideWidth = real_width/2 + leftWidth;
                const lowestWidth = Math.min(rightSideWidth, leftSideWidth);

                if(lowestWidth == leftSideWidth) {
                    topPosition.x -= halfTopScaleX - lowestWidth;
                } else {
                    topPosition.x += halfTopScaleX - lowestWidth;
                }

                bottomPosition.x = topPosition.x;
            }
        }

        // Recalculate left/right position when top or bottom width is not equal
        if(topWidth !== bottomWidth) {
            const halfLeftScaleY = leftScale.y/2;
            const topSideWidth = real_height/2 + topWidth;
            const bottomSideWidth = real_height/2 + bottomWidth;
            const lowestWidth = Math.min(topSideWidth, bottomSideWidth);

            if(lowestWidth == topSideWidth) {
                leftPosition.y -= halfLeftScaleY - lowestWidth;
            } else {
                leftPosition.y += halfLeftScaleY - lowestWidth;
            }

            rightPosition.y = leftPosition.y;
        }

        let topLeft, topRight, bottomLeft, bottomRight, scalingRadius,
            scalingRadiusPosition, scalingRadiusX, scalingRadiusY;
        if (side == 'passepartout_radius') {
            scalingRadius = new BABYLON.Vector3(
                (this.figureDataCloned.frame.passepartout.passepartout_radius*20),
                (this.figureDataCloned.frame.passepartout.passepartout_radius*20),
                0.01,
            )

            topLeft = new BABYLON.Vector3((real_width/2)-(scalingRadius.x/20), (real_height/2)-(scalingRadius.y/20), back_frame_depth);
            topRight = new BABYLON.Vector3((-real_width/2)-(-scalingRadius.x/20), (real_height/2)-(scalingRadius.y/20), back_frame_depth);
            bottomLeft = new BABYLON.Vector3((real_width/2)-(scalingRadius.x/20), (-real_height/2)-(-scalingRadius.y/20), back_frame_depth);
            bottomRight = new BABYLON.Vector3((-real_width/2)-(-scalingRadius.x/20), (-real_height/2)-(-scalingRadius.y/20), back_frame_depth);

            scalingRadiusPosition = new BABYLON.Vector3(0, 0, back_frame_depth);
            scalingRadiusX = new BABYLON.Vector3(real_width + (-scalingRadius.x/10), real_height, 0.01);
            scalingRadiusY = new BABYLON.Vector3(real_width, real_height + (-scalingRadius.y/10), 0.01);
        }

        this._updatePreviewMeshes(side, { 
            topPosition, bottomPosition, leftPosition, rightPosition, topScale, bottomScale,
            leftScale, rightScale, topLeft, topRight, bottomLeft, bottomRight, scalingRadius,
            scalingRadiusX, scalingRadiusY, scalingRadiusPosition
        })
    }

    /**
     * ANCHOR Update Preview Frame Side Changes
     * Todo: to update preview Frame side changes
     * @param side : 'all' | 'top' | 'bottom' | 'right' | 'left'
     */
    public updatePreviewFrameSide(side: 'all' | 'top' | 'bottom' | 'right' | 'left'|'passepartout_radius' | 'frame_radius'): void {
        const { 
            frame_width_top: topFrameWidth,
            frame_width_bottom: bottomFrameWidth,
            frame_width_left: leftFrameWidth,
            frame_width_right: rightFrameWidth,
            frame_depth: frameThinkness
        } = this.figureDataCloned.frame.frame;
        const { 
            passepartout_width_top: topPasseWidth,
            passepartout_width_bottom: bottomPasseWidth,
            passepartout_width_left: leftPasseWidth,
            passepartout_width_right: rightPasseWidth,
            passepartout: usePasse
        } = this.figureDataCloned.frame.passepartout;
        const { back_frame_depth } = this.figureDataCloned.frame.back_frame;
        const { real_height, real_width } = this.figureDataCloned;

        const zPosition = back_frame_depth + frameThinkness/2;
        const topPosition = new BABYLON.Vector3(0, real_height/2 + topFrameWidth/2 + (usePasse ? topPasseWidth : 0), zPosition);
        const bottomPosition = new BABYLON.Vector3(0, -real_height/2 - bottomFrameWidth/2 - (usePasse ? bottomPasseWidth : 0), zPosition);
        const leftPosition = new BABYLON.Vector3(real_width/2 + leftFrameWidth/2 + (usePasse ? leftPasseWidth : 0), 0, zPosition);
        const rightPosition = new BABYLON.Vector3(-real_width/2 - rightFrameWidth/2 - (usePasse ? rightPasseWidth : 0), 0, zPosition);
        const topScale = new BABYLON.Vector3(
            real_width + (usePasse ? leftPasseWidth + rightPasseWidth : 0),
            topFrameWidth,
            frameThinkness
        );
        const bottomScale = new BABYLON.Vector3(
            real_width + (usePasse ? leftPasseWidth + rightPasseWidth : 0),
            bottomFrameWidth,
            frameThinkness
        );
        const leftScale = new BABYLON.Vector3(
            leftFrameWidth, 
            real_height + bottomFrameWidth + topFrameWidth + (usePasse ? topPasseWidth + bottomPasseWidth : 0), 
            frameThinkness
        );
        const rightScale = new BABYLON.Vector3(
            rightFrameWidth,
            real_height + bottomFrameWidth + topFrameWidth + (usePasse ? topPasseWidth + bottomPasseWidth : 0),
            frameThinkness
        );

        // Recalculate top/bottom scale when previewing top/bottom side only
        if(['top','bottom'].includes(side)) {
            topScale.x += leftFrameWidth + rightFrameWidth;
            bottomScale.x = topScale.x;
        }

        // Recalculate top/bottom position when left or right width is not equal
        if(
            leftFrameWidth !== rightFrameWidth ||
            leftPasseWidth != rightPasseWidth
        ) {
            const halfTopScaleX = topScale.x/2;
            const rightSideWidth = real_width/2 + rightFrameWidth + (usePasse ? rightPasseWidth : 0);
            const leftSideWidth = real_width/2 + leftFrameWidth + (usePasse ? leftPasseWidth : 0);
            const lowestWidth = Math.min(rightSideWidth, leftSideWidth);
            if(lowestWidth == leftSideWidth) {
                topPosition.x -= halfTopScaleX - lowestWidth;
            } else {
                topPosition.x += halfTopScaleX - lowestWidth;
            }
            bottomPosition.x = topPosition.x;
        }

        // Recalculate left/right position when top or bottom width is not equal
        if(
            topFrameWidth !== bottomFrameWidth ||
            topPasseWidth != bottomPasseWidth
        ) {
            const halfLeftScaleY = leftScale.y/2;
            const topSideWidth = real_height/2 + topFrameWidth + (usePasse ? topPasseWidth : 0);
            const bottomSideWidth = real_height/2 + bottomFrameWidth + (usePasse ? bottomPasseWidth : 0);
            const lowestWidth = Math.min(topSideWidth, bottomSideWidth);
            if(lowestWidth == topSideWidth) {
                leftPosition.y -= halfLeftScaleY - lowestWidth;
            } else {
                leftPosition.y += halfLeftScaleY - lowestWidth;
            }
            rightPosition.y = leftPosition.y;
        }

        let topLeft, topRight, bottomLeft, bottomRight, scalingRadius,
            scalingRadiusPosition, scalingRadiusX, scalingRadiusY;
        if (side == 'frame_radius') {
            scalingRadius = new BABYLON.Vector3(
                (this.figureDataCloned.frame.frame.frame_radius*20),
                (this.figureDataCloned.frame.frame.frame_radius*20),
                0.001,
            )

            topLeft = new BABYLON.Vector3(
                (real_width/2 + (usePasse? leftPasseWidth:0)) - (scalingRadius.x/20),
                (real_height/2 + (usePasse? topPasseWidth:0)) - (scalingRadius.y/20),
                back_frame_depth
            );
            topRight = new BABYLON.Vector3(
                (-real_width/2 + (usePasse? -rightPasseWidth:0)) - (-scalingRadius.x/20),
                (real_height/2 + (usePasse? topPasseWidth:0)) - (scalingRadius.y/20),
                back_frame_depth
            );
            bottomLeft = new BABYLON.Vector3(
                (real_width/2 + (usePasse? leftPasseWidth:0)) - (scalingRadius.x/20),
                (-real_height/2 + (usePasse? -bottomPasseWidth:0)) - (-scalingRadius.y/20),
                back_frame_depth
            );
            bottomRight = new BABYLON.Vector3(
                (-real_width/2 + (usePasse? -rightPasseWidth:0)) - (-scalingRadius.x/20),
                (-real_height/2 + (usePasse? -bottomPasseWidth:0)) - (-scalingRadius.y/20),
                back_frame_depth
            );

            scalingRadiusX = new BABYLON.Vector3(
                real_width + (-scalingRadius.x/10) + (usePasse ? leftPasseWidth + rightPasseWidth : 0),
                real_height + (usePasse ? topPasseWidth + bottomPasseWidth : 0),
                0.001);
            scalingRadiusY = new BABYLON.Vector3(
                real_width + (usePasse ? leftPasseWidth + rightPasseWidth : 0),
                real_height + (-scalingRadius.y/10) + (usePasse ? topPasseWidth + bottomPasseWidth : 0),
                0.001
            );

            if (usePasse) {
                scalingRadiusPosition = this._setupMarkerPosition({
                    top: topPasseWidth,
                    left: leftPasseWidth,
                    right: rightPasseWidth,
                    bottom: bottomPasseWidth,
                    real_width,
                    real_height,
                    depth: back_frame_depth
                })
                scalingRadiusPosition.z = back_frame_depth;
            } else {
                scalingRadiusPosition = new BABYLON.Vector3(0, 0, back_frame_depth);
            }
        }

        this._updatePreviewMeshes(side, { 
            topPosition, bottomPosition, leftPosition, rightPosition, topScale, bottomScale,
            leftScale, rightScale, topLeft, topRight, bottomLeft, bottomRight, scalingRadius,
            scalingRadiusX, scalingRadiusY, scalingRadiusPosition
        })
    }

    /**
     * ANCHOR Update Preview Back Frame
     * @description to update preview back frame
     */
    public updatePreviewBackFrame(): void {
        const { back_frame_depth } = this.figureDataCloned.frame.back_frame;
        const { real_height, real_width, } = this.figureDataCloned;
        const { 
            passepartout,
            passepartout_width_left,
            passepartout_width_right,
            passepartout_width_top,
            passepartout_width_bottom
        } = this.figureDataCloned.frame.passepartout;

        let heightWithPasse, widthWithPasse;
        if (passepartout) {
            heightWithPasse = passepartout_width_bottom + passepartout_width_top;
            widthWithPasse = passepartout_width_left + passepartout_width_right;
        } else {
            heightWithPasse = 0;
            widthWithPasse = 0;
        }

        const backFrameScale = new BABYLON.Vector3(real_width + widthWithPasse, real_height + heightWithPasse, back_frame_depth);
        let backFramePosition = new BABYLON.Vector3(0, 0, back_frame_depth/2);
        if (passepartout) {
            backFramePosition = this._setupMarkerPosition({
                top: passepartout_width_top,
                left: passepartout_width_left,
                right: passepartout_width_right,
                bottom: passepartout_width_bottom,
                real_width,
                real_height,
                depth: back_frame_depth
            })
            backFramePosition.z = back_frame_depth/2;
        }

        this.setOpacityArtwork();
        let previewMesh;
        if(this._previewMeshes.length == 0) {
            previewMesh = this._markerMaster.clone('backFrameMesh');
            this._previewMeshes = [previewMesh];
        } else previewMesh = this._previewMeshes[0];
        previewMesh.position = backFramePosition;
        previewMesh.scaling = backFrameScale;
        this._setupMarkerRadius(previewMesh);
    }

    /**
     * ANCHOR Set Opacity Artwork
     * @description to make artwork look transparent
     */
    public setOpacityArtwork(): void {
        if(this.figureMesh['transparent'] == true) return;
        this.figureMesh.getChildren().map((mesh: any) => { mesh.visibility = 0.8 });
        this.figureMesh['transparent'] = true;
        this._markerMaster.visibility = 0;
    }

    /**
     * ANCHOR Set Preview Radius Marker
     * @description to set preview radius marker
     */
    private _setupMarkerRadius(mesh:any): void {
        mesh.visibility = 0.01;
        this.outerLayer.outerGlow = false;
        this.outerLayer.addMesh(mesh, BABYLON.Color3.Yellow());
    }

    /**
     * ANCHOR Set Position Marker
     * @description to set position marker
     */
    private _setupMarkerPosition(
        positions :{
            top:number,
            left:number,
            right:number,
            bottom:number,
            real_width:number,
            real_height:number,
            depth:number
        }
    ): Position {
        const { top, left, right, bottom, real_height, real_width } = positions;
        let positionX = 0;
        let positionY = 0;
        if (left !== right) {
            const minPasseWidth = Math.min(left, right);
            const minWidth = real_width + minPasseWidth*2
            const maxWidth = real_width + left + right;
            positionX = maxWidth - minWidth;
        }

        if (top !== bottom) {
            const minPasseHeight = Math.min(top, bottom);
            const minHeight = real_height + minPasseHeight*2
            const maxHeight = real_height + top + bottom;
            positionY = maxHeight - minHeight;
        }   

        const meshPosition = new BABYLON.Vector3(
            right > left ? -positionX/2:positionX/2,
            bottom > top ? -positionY/2:positionY/2,
            0
        );
        return meshPosition;
    }

    /**
     * ANCHOR Update Preview Meshes
     * Todo: to update preview meshes that used for previewing frame/passepartout changes
     * @param side : 'all' | 'top' | 'bottom' | 'right' | 'left'
     * @param transformData : {
            topPosition, bottomPosition, leftPosition, rightPosition, topScale, bottomScale,
            leftScale, rightScale, topWidth, bottomWidth, leftWidth, rightWidth
        }
     */
    private _updatePreviewMeshes(
        side: 'all' | 'top' | 'bottom' | 'right' | 'left' |'passepartout_radius' | 'frame_radius', 
        transformData: {
            topPosition, bottomPosition, leftPosition, rightPosition, topScale, bottomScale,
            leftScale, rightScale, topLeft?, topRight?, bottomLeft?, bottomRight?, scalingRadius?
            scalingRadiusX?, scalingRadiusY?, scalingRadiusPosition?
        }
    ): void {
        const { 
            topPosition, bottomPosition, leftPosition, rightPosition, topScale, bottomScale,
            leftScale, rightScale, topLeft, topRight, bottomLeft, bottomRight, scalingRadius,
            scalingRadiusX, scalingRadiusY, scalingRadiusPosition
        } = transformData;

        this.setOpacityArtwork();

        switch (side) {
            case 'all': {
                let top, bottom, left, right;
                if(this._previewMeshes.length == 0) {
                    top = this._markerMaster.clone('topMesh');
                    bottom = this._markerMaster.clone('bottomMesh');
                    left = this._markerMaster.clone('leftMesh');
                    right = this._markerMaster.clone('rightMesh');
                    this._previewMeshes = [top, bottom, left, right];
                } else {
                    [top, bottom, left, right] = this._previewMeshes;
                }
                top.position = topPosition;
                top.scaling = topScale;
                bottom.position = bottomPosition;
                bottom.scaling = bottomScale;
                left.position = leftPosition;
                left.scaling = leftScale;
                right.position = rightPosition;
                right.scaling = rightScale;

                this._previewMeshes.map((mesh: any) => {
                    this._setupMarkerRadius(mesh);
                });
            };
            break;
            case 'top': {
                let top;
                if(this._previewMeshes.length == 0) {
                    top = this._markerMaster.clone('topMesh');
                    this._previewMeshes = [top];
                } else top = this._previewMeshes[0];
                top.position = topPosition;
                top.scaling = topScale;
                this._setupMarkerRadius(top);
            };
            break
            case 'bottom': {
                let bottom;
                if(this._previewMeshes.length == 0) {
                    bottom = this._markerMaster.clone('bottomMesh');
                    this._previewMeshes = [bottom];
                } else bottom = this._previewMeshes[0];
                bottom.position = bottomPosition;
                bottom.scaling = bottomScale;
                this._setupMarkerRadius(bottom);
            }
            break;
            case 'left': {
                let left;
                if(this._previewMeshes.length == 0) {
                    left = this._markerMaster.clone('leftMesh');
                    this._previewMeshes = [left];
                } else left = this._previewMeshes[0];
                left.position = leftPosition;
                left.scaling = leftScale;
                this._setupMarkerRadius(left);
            }
            break;
            case 'right': {
                let right;
                if(this._previewMeshes.length == 0) {
                    right = this._markerMaster.clone('rightMesh');
                    this._previewMeshes = [right];
                } else right = this._previewMeshes[0];
                right.position = rightPosition;
                right.scaling = rightScale;
                this._setupMarkerRadius(right);
            }
            break;
            case 'passepartout_radius': {
                let topLeftRadius, topRightRadius, bottomRightRadius, bottomLeftRadius, centerWidth, centerHeight;

                if(this._previewMeshes.length == 0) {
                    topLeftRadius = this._radiusMarker.clone('topLeftMesh');
                    topRightRadius = this._radiusMarker.clone('topRightMesh');
                    bottomRightRadius = this._radiusMarker.clone('bottomLeftMesh');
                    bottomLeftRadius = this._radiusMarker.clone('bottomRightMesh');
                    centerWidth = this._markerMaster.clone('centerWidth');
                    centerHeight = this._markerMaster.clone('centerHeight');
                    this._previewMeshes = [
                        topLeftRadius,
                        topRightRadius,
                        bottomRightRadius,
                        bottomLeftRadius,
                        centerWidth,
                        centerHeight
                    ];
                } else {
                    [
                        topLeftRadius,
                        topRightRadius,
                        bottomRightRadius,
                        bottomLeftRadius,
                        centerWidth,
                        centerHeight
                    ] = this._previewMeshes;
                }

                topLeftRadius.position = topLeft;
                topRightRadius.position = topRight;
                bottomRightRadius.position = bottomRight;
                bottomLeftRadius.position = bottomLeft;

                topLeftRadius.scaling = scalingRadius;
                topRightRadius.scaling = scalingRadius;
                bottomRightRadius.scaling = scalingRadius;
                bottomLeftRadius.scaling = scalingRadius;
                
                centerWidth.position = scalingRadiusPosition;
                centerWidth.scaling = scalingRadiusX;
                centerHeight.position = scalingRadiusPosition;
                centerHeight.scaling = scalingRadiusY;


                this._previewMeshes.map((mesh: any) => {
                    this._setupMarkerRadius(mesh);
                });
            }
            break;
            case 'frame_radius': {
                let topLeftRadius, topRightRadius, bottomRightRadius, bottomleftRadius, centerWidth, centerHeight;

                if(this._previewMeshes.length == 0) {
                    topLeftRadius = this._radiusMarker.clone('topLeftMesh');
                    topRightRadius = this._radiusMarker.clone('topRightMesh');
                    bottomRightRadius = this._radiusMarker.clone('bottomLeftMesh');
                    bottomleftRadius = this._radiusMarker.clone('bottomRightMesh');
                    centerWidth = this._markerMaster.clone('centerWidth');
                    centerHeight = this._markerMaster.clone('centerHeight');
                    this._previewMeshes = [
                        topLeftRadius,
                        topRightRadius,
                        bottomRightRadius,
                        bottomleftRadius,
                        centerWidth,
                        centerHeight
                    ];
                } else {
                    [
                        topLeftRadius,
                        topRightRadius,
                        bottomRightRadius,
                        bottomleftRadius,
                        centerWidth,
                        centerHeight
                    ] = this._previewMeshes;
                }

                topLeftRadius.position = topLeft;
                topRightRadius.position = topRight;
                bottomRightRadius.position = bottomRight;
                bottomleftRadius.position = bottomLeft;
                
                topLeftRadius.scaling = scalingRadius;
                topRightRadius.scaling = scalingRadius;
                bottomRightRadius.scaling = scalingRadius;
                bottomleftRadius.scaling = scalingRadius;
                
                centerWidth.position = scalingRadiusPosition;
                centerWidth.scaling = scalingRadiusX;
                centerHeight.position = scalingRadiusPosition;
                centerHeight.scaling = scalingRadiusY;

                this._previewMeshes.map((mesh: any) => {
                    this._setupMarkerRadius(mesh);
                });
            }
        }
    }

    //#endregion 
    //!SECTION

    /**
     * =================================================================================== *
     * SECTION Update Sides (Width/Thickness) of Passeparout/Frame Functions
     * =================================================================================== *
     */
    //#region 

    /**
     * ANCHOR Update Sides Width
     * @description to update sides width of passepartout/frame
     * @param type : 'passepartout' | 'frame'
     */
    public updateSidesWidth(type: 'passepartout' | 'frame'|'passepartout_radius'|'frame_radius', value: number): void {
        switch(type) {
            case 'passepartout':
                this.figureDataCloned.frame.passepartout.passepartout_width_top = value;
                this.figureDataCloned.frame.passepartout.passepartout_width_bottom = value;
                this.figureDataCloned.frame.passepartout.passepartout_width_left = value;
                this.figureDataCloned.frame.passepartout.passepartout_width_right = value;
                this.updatePreviewPasseSide('all');
                break;
            case 'frame':
                this.figureDataCloned.frame.frame.frame_width_top = value;
                this.figureDataCloned.frame.frame.frame_width_bottom = value;
                this.figureDataCloned.frame.frame.frame_width_left = value;
                this.figureDataCloned.frame.frame.frame_width_right = value;
                this.updatePreviewFrameSide('all');
                break;
            case 'passepartout_radius':
                this.figureDataCloned.frame.passepartout.passepartout_radius = value;
                this.updatePreviewPasseSide('passepartout_radius');
                break;
            case 'frame_radius':
                this.figureDataCloned.frame.frame.frame_radius = value;
                this.updatePreviewFrameSide('frame_radius');
                break;
        }

        this.onLoadingArtwork = true;
        this.loadArtworkWithDelay();
        this.setSliderManualInputsValue();
    }

    /**
     * ANCHOR Update Sides Thickness
     * @description to update sides thickness of passepartout/frame
     * @param type : 'passepartout' | 'frame'
     * @param value : number
     */
    public updateSidesThickness(type: 'passepartout' | 'frame' | 'backFrame', value: number): void {
        if (type === 'passepartout') {
            this.figureDataCloned.frame.passepartout.passepartout_depth = value;
            this.updatePreviewPasseSide('all');
        } else if (type === 'frame') {
            this.figureDataCloned.frame.frame.frame_depth = value;
            this.updatePreviewFrameSide('all');
        } else if (type === 'backFrame') {
            this.figureDataCloned.frame.back_frame.back_frame_depth = value;
            this.updatePreviewBackFrame();
        }

        this.onLoadingArtwork = true
        this.loadArtworkWithDelay();
        this.setSliderManualInputsValue();
    }

    /**
	 * ANCHOR on Mouse Down Measurements
     * @description to handle mouse down event on measurements of frame/passepartout)
	 */
    private _clientX: number;
    private _clientY: number;
    private _measure: any = { x: 0, y: 0, position: '', value: 0 };
    public onMouseDownMeasurements(event:any, position: 'top' | 'bottom' | 'left' | 'right') {
        if (this.figureDataCloned.frame.frame.frame && this.tab == 'frame' ||
            this.figureDataCloned.frame.passepartout.passepartout && this.tab == 'passepartout'
        ) {
            const overlay = document.createElement('div');
            overlay.classList.add('overlay-measure');
            overlay.style.position = 'absolute';
            overlay.style.zIndex = '9999';
            overlay.style.top = '0';
            overlay.style.left = '0';
            overlay.style.width = '100%';
            overlay.style.height = '100%';
            document.body.appendChild(overlay);

            this._clientX = event.clientX;
            this._clientY = event.clientY;

            document.body.addEventListener('mousemove', this.onMouseMoveMeasurements);
            document.body.addEventListener('mouseup', this.onMouseUpMeasurements);

            switch (position) {
                case 'top':
                    overlay.style.cursor = 'ns-resize';
                    const top = document.querySelector('.top') as HTMLElement;
                    top.style.visibility = 'visible';

                    this._measure.position = 'top';
                    this._getMeasurementFrameOrPass(
                        this.figureDataCloned.frame.frame.frame_width_top,
                        this.figureDataCloned.frame.passepartout.passepartout_width_top
                    )
                    break;
                case 'bottom':
                    overlay.style.cursor = 'ns-resize';
                    const bottom = document.querySelector('.bottom') as HTMLElement;
                    bottom.style.visibility = 'visible';

                    this._measure.position = 'bottom';
                    this._getMeasurementFrameOrPass(
                        this.figureDataCloned.frame.frame.frame_width_bottom,
                        this.figureDataCloned.frame.passepartout.passepartout_width_bottom
                    )
                    break;
                case 'left':
                    overlay.style.cursor = 'ew-resize';
                    const left = document.querySelector('.left') as HTMLElement;
                    left.style.visibility = 'visible';

                    this._measure.position = 'left';
                    this._getMeasurementFrameOrPass(
                        this.figureDataCloned.frame.frame.frame_width_left,
                        this.figureDataCloned.frame.passepartout.passepartout_width_left
                    )
                    break;
                case 'right':
                    overlay.style.cursor = 'ew-resize';
                    const right = document.querySelector('.right') as HTMLElement;
                    right.style.visibility = 'visible';

                    this._measure.position = 'right';
                    this._getMeasurementFrameOrPass(
                        this.figureDataCloned.frame.frame.frame_width_right,
                        this.figureDataCloned.frame.passepartout.passepartout_width_right
                    )
                    break;
            }
        }
    }

    /**
     * ANCHOR on Mouse Move Measurements
     * @description to handle mouse move event on measurements of frame/passepartout)
     */
    public onMouseMoveMeasurements = (event: any) => {
        if (event.buttons === 1) {
            let value = 0;
            switch (this._measure.position) {
                case 'top':
                    this._measure.y = (this._clientY - event.clientY) / 1000;
                    value = this._measure.value + this._measure.y;
                    if (value >= 0.01 && value <= 0.5) {
                        if (this.tab === 'frame') {
                            this.figureDataCloned.frame.frame.frame_width_top = value;
                            this.updatePreviewFrameSide('top');
                        }
                        if (this.tab === 'passepartout') { 
                            this.figureDataCloned.frame.passepartout.passepartout_width_top = value;
                            this.updatePreviewPasseSide('top');
                        }
                    }
                break;

                case 'bottom':
                    this._measure.y = -(this._clientY - event.clientY) / 1000;
                    value = this._measure.value + this._measure.y;
                    if (value >= 0.01 && value <= 0.5) {
                        if (this.tab === 'frame') {
                            this.figureDataCloned.frame.frame.frame_width_bottom = value;
                            this.updatePreviewFrameSide('bottom');
                        }
                        if (this.tab === 'passepartout') {
                            this.figureDataCloned.frame.passepartout.passepartout_width_bottom = value;
                            this.updatePreviewPasseSide('bottom');
                        }
                    }
                break;

                case 'left':
                    this._measure.x = (this._clientX - event.clientX) / 1000;
                    value = this._measure.value + this._measure.x;
                    if (value >= 0.01 && value <= 0.5) {
                        if (this.tab === 'frame') {
                            this.figureDataCloned.frame.frame.frame_width_left = value;
                            this.updatePreviewFrameSide('left');
                        }
                        if (this.tab === 'passepartout') {
                            this.figureDataCloned.frame.passepartout.passepartout_width_left = value;
                            this.updatePreviewPasseSide('left');
                        }
                    }
                break;

                case 'right':
                    this._measure.x = -(this._clientX - event.clientX) / 1000;
                    value = this._measure.value + this._measure.x;
                    if (value >= 0.01 && value <= 0.5) {
                        if (this.tab === 'frame') {
                            this.figureDataCloned.frame.frame.frame_width_right = value;
                            this.updatePreviewFrameSide('right');
                        }
                        if (this.tab === 'passepartout') { 
                            this.figureDataCloned.frame.passepartout.passepartout_width_right = value;
                            this.updatePreviewPasseSide('right');
                        }
                    }
                break;
            }

            this.onLoadingArtwork = true;
            this.loadArtworkWithDelay();
            this.setSliderManualInputsValue();
        }
    }

    /**
     * ANCHOR on Mouse Up Measurements
     * @description to handle mouse up event on measurements of frame/passepartout)
     */
    public onMouseUpMeasurements = () => {
        const overlay = document.querySelector('.overlay-measure');
        if (overlay) overlay.remove();

        const top = document.querySelector('.top') as HTMLElement;
        const bottom = document.querySelector('.bottom') as HTMLElement;
        const left = document.querySelector('.left') as HTMLElement;
        const right = document.querySelector('.right') as HTMLElement;
        top.style.visibility = 'hidden';
        bottom.style.visibility = 'hidden';
        left.style.visibility = 'hidden';
        right.style.visibility = 'hidden';

        document.body.removeEventListener('mousemove', this.onMouseMoveMeasurements);
        document.body.removeEventListener('mouseup', this.onMouseUpMeasurements);

        this._clientX = null;
        this._clientY = null;
        this._measure.value = 0;
    }

    /**
     * ANCHOR Get Measurement Frame Or Passepartout
     * @description to get measurement frame or passepartout
     */
    private _getMeasurementFrameOrPass(frame: number, passepartout: number): void {
        switch (this.tab) {
            case 'passepartout':  
                this._measure.value = passepartout;
            break;
            case 'frame':
                this._measure.value = frame;
            break;
        }
    }
    //#endregion
    //!SECTION

    /**
     * =================================================================================== *
     * SECTION Create Artwork Functions
     * =================================================================================== *
     */
    //#region

    /**
     * ANCHOR Load Artwork With Delay (Debounce)
     * @description to load artwork with 1 second delay
     * @param linkedWithTemplate : boolean
     * @param calcRadius : boolean
     */
    public loadArtworkWithDelay = debounce(async (linkedWithTemplate: boolean = false, calcRadius: boolean = false) => {
        this.loadArtwork(linkedWithTemplate, calcRadius); 
    }, 1000)

	/**
     * ANCHOR Load Artwork
     * @description to load artwork
     * @param linkedWithTemplate : boolean
     * @param calcRadius : boolean
     */
	public loadArtwork = async (linkedWithTemplate: boolean = false, calcRadius: boolean = false) => {
		let metadata = null;
        if (this.figureMesh) {
            metadata = this.figureMesh.metadata;
            this.figureMesh.dispose();
        }
        if(calcRadius) this._calcCameraRadius();
        this.figureMesh = await this.editorService.createArtworkImageVideo(this.figureDataCloned,this.scene,true);
        const csgMat = this.scene.getMeshByName('csgMat');
        if (csgMat) csgMat.visibility = 0;
        this._previewMeshes.map((mesh: any) => mesh.dispose());
        this._previewMeshes = [];
        this._markerMaster.visibility = 0;

        if(metadata) this.figureMesh.metadata = metadata;
        this._setPositionRotationArtwork();
        this.linkedWithTemplate = linkedWithTemplate;
        if (!this.figureMesh.metadata.shadowComponent) {
            this.editorService.createShadowComponents(this.figureMesh);
        } else {
            this._artworkShadowsService.setShadowComponentLightsPosition(this.figureMesh)
        }

        this.onLoadingArtwork = false;
	}
    
    /**
     * ANCHOR Set Position Rotation Artwork
     * @description to set position and rotation of artwork
     */
    private _setPositionRotationArtwork(){
        this.figureMesh.position = new BABYLON.Vector3(
            0, 0, this.figureMesh.scaling.z/2
        );
        this.figureMesh.rotation = BABYLON.Vector3.Zero();
        this.figureMesh.getChildren().map((mesh: any) => {
            if(mesh.material) mesh.material.maxSimultaneousLights = 5;
        })
    }

    /**
     * ANCHOR Calculate Camera Radius
     * @description to calculate camera radius
     */
    private _calcCameraRadius(){
        const { real_height, real_width } = this.figureDataCloned;
        const { frame, passepartout } = this.figureDataCloned.frame;
        const {
            frame_width_bottom: frameWidthBottom,
            frame_width_left: frameWidthLeft,
            frame_width_right: frameWidthRight,
            frame_width_top: frameWidthTop,
            frame: useFrame
        } = frame;
        const {
            passepartout_width_bottom: passeWidthBottom,
            passepartout_width_left: passeWidthLeft,
            passepartout_width_right: passeWidthRight,
            passepartout_width_top: passeWidthTop,
            passepartout: usePasse
        } = passepartout;

        const halfLeftWidth = real_width/2 + (usePasse ? passeWidthLeft : 0) + (useFrame ? frameWidthLeft : 0);
        const halfRightWidth = real_width/2 + (usePasse ? passeWidthRight : 0) + (useFrame ? frameWidthRight : 0);
        const halfTopWidth = real_height/2 + (usePasse ? passeWidthTop : 0) + (useFrame ? frameWidthTop : 0);
        const halfBottomWidth = real_height/2 + (usePasse ? passeWidthBottom : 0) + (useFrame ? frameWidthBottom : 0);
        const highestHorizonalWidth = Math.max(halfTopWidth, halfBottomWidth);
        const highestVerticalWidth = Math.max(halfLeftWidth, halfRightWidth);


        const cameraViewRange = Math.max(highestHorizonalWidth, highestVerticalWidth) * 5;
        this.camera.upperRadiusLimit = cameraViewRange;
        this.camera.lowerRadiusLimit = 20 / 100 * cameraViewRange;
        this.camera.radius = 60 / 100 * cameraViewRange;
    } 
    //#endregion
    //!SECTION

    /**
     * =================================================================================== *
     * SECTION Uncategorize Functions
     * =================================================================================== *
     */
    //#region 

    /**
     * ANCHOR Create Marker (Preview Mesh)
     * @description to create marker (preview mesh) that used for previewing frame/passepartout changes
     */
    private outerLayer;
    private _createMarker() :  void {
        this._markerMaster = BABYLON.MeshBuilder.CreateBox('scalingTmp',{}, this.scene);
        this._markerMaster.position.y = -100;
        this._markerMaster.visibility = 0;
        this._markerMaster.material = this._markerMaterial;
        
        this._radiusMarker = BABYLON.MeshBuilder.CreateSphere("radiusTmp", {diameter: 0.1}, this.scene);
        this._radiusMarker.material = this._markerMaterial;
        this._radiusMarker.position.y = -100;
        this._radiusMarker.visibility = 0;

        this.outerLayer = new BABYLON.HighlightLayer("highlightRadius", this.scene);
    }

    /**
     * ANCHOR Create Marker Material
     * @description to create marker material
     */
    private _createMarkerMaterial() : void {
        this._markerMaterial = new BABYLON.StandardMaterial('markerMat', this.scene);
        this._markerMaterial.emissiveColor = new BABYLON.Color3(1,0.85,0.08);
        this._markerMaterial.disableLighting = true;
    }

    /**
     * ANCHOR Create Radius Marker
     * @description to create radius marker
     */
    private _createRadiusMarker() : void {
        this._markerRadiusMaster = this._artworkLoaderService.createRadius({
            scene: this.scene,
            radius: 1
        });
        this._markerRadiusMaster.position.y = -100;
        this._markerRadiusMaster.visibility = 0.5;
        this._markerRadiusMaster.material = this._markerMaterial;
    }

    /**
     * ANCHOR Set Slider Manual Inputs Value
     * @description to set slider manual inputs value
     */
    public updateSliderManualInputValue(value: any, type: string, min: number, max: number) {
        value = this.editorService.validateSliderManualInput(value,min,max);
        value /= 100;
        const { frame, passepartout, back_frame } = this.figureDataCloned.frame;
        switch (type) {
            case "generalThinkess": back_frame.back_frame_depth = value; break;
            case "passepartoutThinkness": passepartout.passepartout_depth = value; break;
            case "passepartoutWidth":
                passepartout.passepartout_width_top = value;
                passepartout.passepartout_width_bottom = value;
                passepartout.passepartout_width_left = value;
                passepartout.passepartout_width_right = value;
            break;
            case 'passepartoutWidthTop': passepartout.passepartout_width_top = value; break;
            case 'passepartoutWidthBottom': passepartout.passepartout_width_bottom = value; break;
            case 'passepartoutWidthLeft': passepartout.passepartout_width_left = value; break;
            case 'passepartoutWidthRight': passepartout.passepartout_width_right = value; break;
            case "passepartoutRadius": passepartout.passepartout_radius = value; break;
            case "frameThinkness": frame.frame_depth = value; break;
            case "frameWidth":
                frame.frame_width_top = value;
                frame.frame_width_bottom = value;
                frame.frame_width_left = value;
                frame.frame_width_right = value;
            break;
            case 'frameWidthTop': frame.frame_width_top = value; break;
            case 'frameWidthBottom': frame.frame_width_bottom = value; break;
            case 'frameWidthLeft': frame.frame_width_left = value; this.updatePreviewFrameSide('left') ;break;
            case 'frameWidthRight': frame.frame_width_right = value; break;
            case "frameRadius": frame.frame_radius = value; break;
        }

        this.onLoadingArtwork = true;
        this.loadArtworkWithDelay();
        this.setSliderManualInputsValue();
    }
    //#endregion 
    //!SECTION
}