import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MainService } from "src/app/shared/services";
import { AlertMessageService } from 'src/app/components/alert-message/alert-message.service';
import { NgIf, NgClass } from '@angular/common';
import { LayoutsComponent } from '../../layouts/layouts.component';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
    standalone: true,
    imports: [LayoutsComponent, NgIf, FormsModule, ReactiveFormsModule, NgClass]
})
export class ForgotPasswordComponent implements OnInit {

	public showSentEmail: boolean= false;
	public loading: boolean = false;
	private grecaptchaToken: string = '';
	public captchaVerifed: boolean = false;

	public form = new FormGroup({
		email_forgot: new FormControl('', [
			Validators.required,
			Validators.email
		])
	});

	constructor(
		public mainService:MainService,
		private alertMessageService:AlertMessageService,
		private cd: ChangeDetectorRef
	) { }

	ngOnInit(): void {}

	ngAfterViewInit() {
		const recaptchaScript = `https://www.google.com/recaptcha/api.js?render=explicit`
		this.mainService.loadScripts([recaptchaScript]).then(() => {
			if (this.mainService.isBrowser) {
				this.mainService.initGrecaptcha({
					elementId: 'captchaForgot',
					callback: (e) => {
						this.captchaVerifed = true;
						this.grecaptchaToken = e;
						this.cd.detectChanges();
					},
					expiredCallback: () => {
						this.captchaVerifed = false;
						this.cd.detectChanges();
					}
			});
			}
		});
	}

	/**
	 * * FORGOT PASSWORD *
	 * Todo: to send forgot password request
	 */
	forgotPassword(){
		if (this.captchaVerifed) {
			this.loading = true
			this.mainService.forgotPassword({
				email: this.form.value.email_forgot,
				token: this.grecaptchaToken
			}).subscribe((res:any)=>{
				this.loading = false;
				this.showSentEmail = true;
			},err=>{
				this.captchaVerifed = false;
				this.mainService.resetGrecaptcha();
				this.loading = false;
				this.captchaVerifed = false;
				if(err.error.statusCode==403){
					this.alertMessageService.add({severity: "warn", summary: "Warning", detail: "Email address is not verified, please check your email"})
				}
				else if(err.error.statusCode==409){
					this.alertMessageService.add({ severity: "error", summary: "Not Found", detail: "User not found"})
				}else{
					this.alertMessageService.add({ severity: "error", summary: "Error", detail: "Something went wrong. Failed to send an email."})
				}
			});
		}
	}

	back(){
		window.history.back()
	}

}
