import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AllowedFile, VideoData } from '../../images.interfaces';
import { NgIf } from '@angular/common';

@Component({
    selector: 'single-preview',
    templateUrl: './single-preview.component.html',
    styleUrls: ['./single-preview.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf]
})
export class SinglePreviewComponent implements OnInit {
  @Input() disabled: boolean = false;
  @Input() file: AllowedFile;
  @Input() isVideo: boolean = false;
  @Input() videoData: VideoData;
  
  @Output() onBack: EventEmitter<any> = new EventEmitter();
  @Output() onRename: EventEmitter<any> = new EventEmitter();
  @Output() onValidate: EventEmitter<any> = new EventEmitter();

  public artworkName: FormControl = new FormControl('', [ Validators.required ]);

  ngOnInit(): void {
  }

  /**
   * ANCHOR Rename Artwork
   * @description to rename artwork
   * @param controlName : string -> control name
   */
  public renameArtwork(): void {
    const value = this.artworkName.value.trimStart();
    const newValue = value.replace(/\\/g, '').replace(/\"/g, '');
    this.artworkName.setValue(newValue.trimStart());
    if(this.isVideo) {
      this.videoData.name = this.artworkName.value;
      this.onRename.emit(this.videoData);
    } else {
      this.file.name = this.artworkName.value;
      this.onRename.emit(this.file);
    }
    this.onValidate.emit(this.artworkName.valid);
  }
}
