export const mainReducer = (state = {
    theme: "light",
    keywordSearch: "",
    isBrowser: undefined,
    pageWithoutLogin: ['/catalogue', '/profile/401' ,'/pricing','/','/search'],
    typePricing: [
        { name: 'profesional', monthly: 24.90, annually: 288.90, feature: ['1 online exhibition', 'Full editing capabilities', '2 past exhibitions (no sharing feature)']}
    ],
    detailPricing: {},
    userRegisterState: Date.now()
}, action) =>{
	switch (action.type) {
        case "UPDATE_THEME":
            state.theme = action.theme
            break;
        case "UPDATE_SEARCH":
            state.keywordSearch = action.keywordSearch
            break;
        case "UPDATE_IS_BROWSER":
            state.isBrowser = action.isBrowser
            break;
        case "UPDATE_REGISTER_STATE":
            state.userRegisterState = action.userRegisterState
            break;
    }
    return state;
}