<div 
    *ngIf="loadingGalleryService.show"
    class="wrap-loading" 
    [class.bg-block]="loadingGalleryService.backgroundType === 'block'"
    [class.bg-overlay]="loadingGalleryService.backgroundType === 'overlay'"
    [class.bg-none]="loadingGalleryService.backgroundType === 'none'">
    <div class="loading">
        <div 
            *ngIf="loadingGalleryService.percent > 0" 
            [style.background-color]="loadingGalleryService.backgroundType === 'none' ? '#fff' : 'transparent'"
            class="percentage">
            <p class="m-0">{{loadingGalleryService.percent}}%</p>
        </div>
        <div class="wrapper">
            <div class="cubic">
                <img [src]="env.staticAssets+'images/other/yellow-box.png?t='+mainService.appVersion" alt="">
            </div>
        </div>
    </div>
    <p *ngIf="loadingGalleryService.label">{{loadingGalleryService.label}}</p>
</div>