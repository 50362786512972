import { Injectable } from '@angular/core';
import { cloneDeep, debounce } from 'lodash'
import { State, UndoRedoCallback } from '@interfaces';

@Injectable({
  providedIn: 'root'
})

export class UndoRedoService {
  public activeId: number = 0;
  public activeIndex: number = 0;
  public states: any[] = [];
  public onUndo: boolean = false;
  public onRedo: boolean = false;

  constructor() { }

  /**
   * ANCHOR Init States
   * @description This function is used to initialize the states array with the first state of the application
   * @param state : State
   */
  public initStates(state: State): void {
    const id = Date.now();
    this.states.push({ id, state: this._cloneState(state) });
    this.activeIndex = 0;
    this.activeId = id;
  }

  /**
   * ANCHOR On Undo Redo
   * @description This function is used to mark if the app is doing an undo or redo action
   * @param enable : boolean
   * @param action : 'undo' | 'redo'
   */
  public onUndoRedo(enable, action: 'undo' | 'redo') {
    if(action === 'undo') this.onUndo = enable;
    else this.onRedo = enable;
  }

  /**
   * ANCHOR Add State
   * @description This function is used to add a new state to the states array
   * @param state : State
   */
  public addState(state): void {
    const id = Date.now();
    this.states = this.states.slice(0, this.activeIndex + 1);
    this.states.push({ id, state: this._cloneState(state) });
    this.activeIndex++;
    this.activeId = id;
  }

  /**
   * ANCHOR Clear Invalid States
   * @description This function is used to clear the invalid states from the states array
   * @returns : State -> The active state
   */
  public clearInvalidStates(): State {
    this.states = this.states.filter(state => state.state.stateValid);
    let activeState = this.states.find(state => state.id === this.activeId);
    if(!activeState) {
      this.activeIndex = this.states.length - 1;
      activeState = this.states[this.activeIndex];
      this.activeId = activeState.id;
    } else {
      this.activeIndex = this.states.indexOf(activeState);
    }
    return activeState.state;
  }

  /**
   * ANCHOR Is Enabled
   * @description This function is used to check if the undo or redo action is enable
   * @param action : 'undo' | 'redo'
   * @returns : boolean
   */
  public isEnabled(action: 'undo' | 'redo'): boolean {
    if(action === 'undo') return this.activeIndex > 0;
    else return this.activeIndex < this.states.length - 1;
  }

  /**
   * ANCHOR Register Undo Redo Keyboard Event
   * @description This function is used to register the undo and redo keyboard event
   * @param callback 
   */
  public registerUndoRedoKeyboardEvent(callback: UndoRedoCallback) {
    window.addEventListener('keydown', (e) => {
      if(e.ctrlKey && !e.shiftKey && e.key.toLowerCase() === 'z') {
        if(this.isEnabled('undo')) callback.onUndo();
      } else if(e.ctrlKey && e.shiftKey && e.key.toLowerCase() === 'z') {
        if(this.isEnabled('redo')) callback.onRedo();
      }
    })
  }

  /**
   * ANCHOR Remove Undo Redo Input Event
   * @description This function is used to remove default undo and redo event on input and textarea
   */
  public removeUndoRedoInputEvent() {
    const inputs = document.querySelectorAll('input');
    const textareas = document.querySelectorAll('textarea');

    const removeUndoRedo = (el) => {
      el.addEventListener('beforeinput', e => {
        if (e.inputType === 'historyUndo' || e.inputType === 'historyRedo') {
          e.preventDefault();
        }
      }, { capture: true });
    }
    
    inputs.forEach(input => removeUndoRedo(input));
    textareas.forEach(textarea => removeUndoRedo(textarea));
  }

  /**
   * ANCHOR Undo Redo
   * @description This function is used to undo or redo the states
   * @param action : 'undo' | 'redo'
   * @returns : State -> The active state
   */
  public undoRedo(action: 'undo' | 'redo') {
    if(action === 'undo') this.activeIndex--;
    else this.activeIndex++;
    this.activeId = this.states[this.activeIndex].id;
    return this._cloneState(this.states[this.activeIndex].state);
  }

  /**
   * ANCHOR Clear States
   * @description This function is used to clear the states array
   */
  public clear() {
    const activeState = this.states[this.activeIndex];
    this.activeId = activeState.id;
    this.states = [activeState];
    this.activeIndex = 0;
  }

  /**
   * ANCHOR Clone State
   * @description This function is used to clone the state object
   * @param state : State
   * @returns : State
   */
  private _cloneState(state: State): State {
    return {
      artworks: cloneDeep(state.artworks),
      ordinaryObjects: cloneDeep(state.ordinaryObjects),
      texts: cloneDeep(state.texts),
      exhibition: cloneDeep(state.exhibition),
      camera: cloneDeep(state.camera),
      stateValid: state.stateValid
    }
  }
}
