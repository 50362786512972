import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { store, MainService } from 'src/app/shared/services';
import { environment } from '@environments';
import { SsrCookieService as CookieService } from 'ngx-cookie-service-ssr';

@Injectable({
	providedIn: 'root'
})
export class LayoutsService {
	public loadingUser: boolean = false;
	public loadingProcess: boolean = false;
	public showMenu: boolean = false;
	public dataExhibition;
	public formType: string = "login";
	public loginFrom: 'pricing-page' | 'catalogue-page' | null;
	public productName: string | null;
	public paymentType: 'monthy' | 'yearly' | null;
	public resetScrollPosition: boolean = true;
	public sideMenu: boolean = false;
	public settingsMenu: boolean = false;

	public displayLimitMessage: boolean = false;
	public billingProduct: string = '';
	public onlineExhibtionsLimit: number = 0;
	public exhibtionDraftsLimit: number = 0;
	public isExperimental: boolean = false;

	constructor(
		public mainService: MainService,
		private router: Router,
		private cookieService: CookieService,
		private http: HttpClient
	) {}

	async checkIsSameUser() {
		if (this.mainService.userInfo) {
			try {
				// if the response is true it means this is the same user as the previous user, 
				// but if the response is false it means the user is different from the previous user
				const response = await this.checkIsSameUserQuery(this.mainService.userInfo.username);
				return response;
			} catch (err) {
				return false;
			}
		} else {
			return false;
		}
	}

	checkIsSameUserQuery(username) {
		return new Promise((resolve: any, reject: any) => {
			const query = `
                query MyQuery {
                    users_aggregate(where: {username: {_eq: "${username}"}}) {
                        aggregate {
                            count
                        }
                    }
                }
            `;

			this.mainService.queryGraphql(query).subscribe((res: any) => {
				if (res['data']) {
					const userCount = res['data'].users_aggregate.aggregate.count;
					if (userCount > 0) resolve(true);
					else resolve(false);
				} else {
					reject(false)
				}
			}, err => {
				reject(false)
			})
		})
	}

	// ======================== //
	// Check User has login and Access Page
	// ======================= //
	fetchUser() {
		const token = this.cookieService.get('villume_token');
		if (!token) return

		this.loadingUser = true;
		this.mainService.getUserProfile().subscribe(res => {
			let resultInfo = res['data'].result[0];
			if (resultInfo.is_email_verified) {
				this.mainService.userInfo = resultInfo;
				if (this.mainService.isBrowser) {
					document.body.classList.add(resultInfo.user_details[0].theme_style);
					// save to store
					store.dispatch({ type: "UPDATE_INFO_USER", info: resultInfo });
					store.dispatch({ type: "UPDATE_USER_STORY", userStory: resultInfo.user_details[0] });
				}
			}
			this.loadingUser = false;
		}, (err) => {
			if (this.mainService.isBrowser) document.cookie = "villume_token=;";
			this.router.navigate(["/"]);
		});
	}

	// ======================== //
	// UPDATE TOKEN
	// ======================= //
	updateToken(token, expires = null) {
		this.mainService.token = token;
		return this.http.post(`${environment.base_host}/api/save-token`, { token, expires });
	}
}
