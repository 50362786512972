import { NgIf, NgFor } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild, afterNextRender } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { MainService } from '@services';
import { LayoutsService } from 'src/app/layouts/layouts.service';
import { ProfileService } from 'src/app/pages/profile/profile.service';
import * as _ from 'lodash';
import { environment } from '@environments';
import { ExhibitionProfile, GetExhibitionResponse } from 'src/app/pages/profile/profile.interfaces';
import { AlertMessageService } from '../alert-message/alert-message.service';
import moment from 'moment';
import { WhiteLabelService } from './white-label.service';
import { Router } from '@angular/router';
import { DialogModule } from 'primeng/dialog';
import { InformationComponent } from './information/information.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@Component({
    selector: 'app-white-label',
    templateUrl: './white-label.component.html',
    styleUrls: ['./white-label.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, LazyLoadImageModule, ProgressSpinnerModule, InformationComponent, DialogModule]
})
export class WhiteLabelComponent implements OnInit {
  public isInside:boolean = false;
  public loading:boolean = false;
  public toggleTruncateAbout: boolean = true;
  public fullname: string = "";
  public loadExhibition: boolean = false;
  public env = environment;

  @ViewChild('mainLayout') mainLayout: ElementRef;

	public offset: number = 0;
	public isShare: boolean = false;
	public shareableLink: string;
	public iframe: string;
	public viewerPath: string = environment.viewer_path;
  public exhibitions: ExhibitionProfile[] = [];
  public totalCount: number;
  public username:string;

  constructor(
    public mainService:MainService,
    public layoutsService: LayoutsService,
    public profileService: ProfileService,
    public whiteLabelService: WhiteLabelService,
    private _title:Title,
    private _meta:Meta,
    private _router: Router,
    private alertMessageService: AlertMessageService
  ) { 
    afterNextRender(()=>{
      this.mainService.isBrowser = true;
    });
    if (this._router.url === '/404') {
      this._router.navigate(['/']);
    }
  }

  ngOnInit(): void {
    this.mainService.isProfilePage = true;
    this.loading = true;

    this.detectWhiteLabel().then((res:any)=>{
      this.getUserData();
      this.getExhibitions(0, res, true);
    }).catch(err=>{
      window.location.href = environment.base_host+'/404';
    });
  }

  ngOnDestroy(): void {
    this.mainService.isProfilePage = false;
  }

  ngAfterViewInit(){
		if(this.mainService.isBrowser){
      this.setWidthLayout();
			window.addEventListener('resize', ()=>{
				this.setWidthLayout();
			});
		}
	}

  updateMetaTag(){
    const title = this.username;
    const description = this.whiteLabelService.infoUser?.about ? this.whiteLabelService.infoUser?.about.substring(0,180) : '';
    const image = this.whiteLabelService.infoUser?.avatar ? this.whiteLabelService.infoUser?.avatar : environment.staticAssets+'images/other/default-avatar.png?t='+this.mainService.appVersion;
    const url = `https://${this.whiteLabelService.whiteLabelDomain}`;

    // Primary Meta Tags
    this._title.setTitle(title);
    this._meta.updateTag({name: 'title', content: title});
    this._meta.updateTag({name: 'description', content: description});
    
    // Open Graph / Facebook
    this._meta.updateTag({property: 'og:type', content: 'website'});
    this._meta.updateTag({property: 'og:url', content: url});
    this._meta.updateTag({property: 'og:title', content: title});
    this._meta.updateTag({property: 'og:description', content: description});
    this._meta.updateTag({property: 'og:image', content: image});
    
    // Twitter 
    this._meta.updateTag({property: 'twitter:card', content: 'summary_large_image'});
    this._meta.updateTag({property: 'twitter:url', content: url});
    this._meta.updateTag({property: 'twitter:title', content: title});
    this._meta.updateTag({property: 'twitter:description', content: description});
    this._meta.updateTag({property: 'twitter:image', content: image});
  }

  setWidthLayout(){
		if(this.mainLayout){
			document.documentElement.style.setProperty('--mainLayout', `${this.mainLayout.nativeElement.offsetWidth}px`);
		}
	}

  getUserData() {
    if(this.mainService.isBrowser){
      this.fetchUser();
    }else{
      this.fetchUserServerSide();
    }
  }

  fetchUser() {
    this.loading = true;
    this.mainService.getPublicUserInfo(this.username).subscribe((res) => {
      if(res['data']['userDatas']){
        this.whiteLabelService.infoUser = res['data']['userDatas'];
        this.whiteLabelService.infoUser.avatar = this.mainService.setUserAvatar(this.whiteLabelService.infoUser.avatar);
        if(this.whiteLabelService.infoUser.is_email_verified){
          this.updateMetaTag();
          this.fullname = this.whiteLabelService.infoUser?.first_name+' '+this.whiteLabelService.infoUser?.last_name;
        }else{
          window.location.href = environment.base_host+'/404';
        }
      }else{
        window.location.href = environment.base_host+'/404';
      }
      this.loading = false;
    }, err => {
      if(err.name == "TimeoutError"){
        this.fetchUser();
      }
      this.loading = false;
    });
  }
    
  fetchUserServerSide(){
    this.loading = true;
    this.mainService.getPublicUserInfoSSR(this.username).subscribe((res) => {
      if(res['data']['userDatas']){
        this.whiteLabelService.infoUser = res['data']['userDatas'];
        this.whiteLabelService.infoUser.avatar = this.mainService.setUserAvatar(this.whiteLabelService.infoUser.avatar);
        if(this.whiteLabelService.infoUser.is_email_verified){
          this.updateMetaTag();
          this.fullname = this.whiteLabelService.infoUser?.first_name+' '+this.whiteLabelService.infoUser?.last_name;
        }else{
          window.location.href = environment.base_host+'/404';
        }
      }else{
        window.location.href = environment.base_host+'/404';
      }
      this.loading = false;
    });
  }

  
  /**
   * * ======================================== *
   * * LIST OF FUNCTIONS                        *
   * * ======================================== *
   * - GET EXHIBITIONS
   * - LOAD EXHIBITION DATA
   * - SET DISPLAY EXHIBITON TIME
   * - SET EXHIBITION IMAGES PATH
   * - CREATE COUNTDOWN TIMER
   * - OPEN LINK NEW TAB
   * - COPY TEXT
   * - OPEN SHARE LINK
   */

  /**
   * * GET EXHIBITIONS *
   * Todo: to getting exhibition by username
   */
  getExhibitions(offset: number = 0, data?: any, firstLoad: boolean = false){
    // Send request api to getting exibition
    this.loadExhibition = true;
    if (this.mainService.isBrowser) {
      let domain = window.location.origin;
      domain = domain.replace(/^https?:\/\//, '');

      if (firstLoad) {
        this.loadData(data, offset);
        this.loadExhibition = false;
        this.loading = false;
      } else {
        this.whiteLabelService.getExhibitionWhiteLabel(domain, offset).subscribe({
          next: (response: GetExhibitionResponse) => {
            this.loadData(response, offset);
            this.loadExhibition = false;
            this.loading = false;
          },
          error: (err) => {
            this.alertMessageService.add({ 
              severity: "error", 
              summary: "Error", 
              detail: "Something went wrong. Failed to load exhibitions."
            });
          }
        })
      }
    }
  }

  /**
   * * LOAD EXHIBITION DATA *
   * Todo: to load exhibition data to collection
   */
  loadData(response: GetExhibitionResponse, offset: number){
    const exhibitions: ExhibitionProfile[] = response['data']['exhibitions'].map((exhibition: ExhibitionProfile) => {
      exhibition = this.setExhibitionImagesPath(exhibition);
      exhibition = this.setDisplayExhibitionTime(exhibition);

      return exhibition;
    })

    this.exhibitions = offset == 0 ? exhibitions : this.exhibitions.concat(exhibitions);
    this.totalCount = response['data']['totalCount'];
    this.profileService.totalCountExhibit = this.totalCount;
			
    if(this.mainService.isBrowser){
      setTimeout(() => window.dispatchEvent(new Event('resize')), 100);
    }

  }

  /**
   * * SET DISPLAY EXHIBITON TIME *
   * Todo: to set the exhibition time to display on the page
   */
  setDisplayExhibitionTime(exhibition: ExhibitionProfile) {
    if (exhibition.started) {
      exhibition.started_display = moment(exhibition.started, "YYYY-MM-DD").format("DD.MM.YYYY");
      exhibition.ended_display = moment(exhibition.ended, "YYYY-MM-DD").format("DD.MM.YYYY");
      exhibition.show_countdown = exhibition.countdown_timer && moment(moment().format("YYYY-MM-DD")).isBefore(exhibition.started);
    }

    if (exhibition.show_countdown) {
      exhibition = this.countdown(exhibition);
    }
    
    return exhibition;
  }

  /**
   * * SET EXHIBITION IMAGES PATH *
   * Todo: to update exibition images path (thumbail & cover image)
   */
  setExhibitionImagesPath(exhibition: ExhibitionProfile) {
    const thumbnailPath =  environment.exhibition_path;
    if(!exhibition.thumbnail.includes(thumbnailPath)){
      exhibition.thumbnail = thumbnailPath + exhibition.thumbnail;
    }

    if (exhibition.cover_image && !exhibition.cover_image.includes(environment.image_path)) {
      exhibition.cover_image = this.mainService.convertPathImage(exhibition.cover_image);
    }

    return exhibition;
  }

  /**
   * * CREATE COUNTDOWN TIMER *
   * Todo: to create countdown timer
   */
  countdown(exhibition: ExhibitionProfile) {
    if(this.mainService.isBrowser){
      const getTimeCountdown = new Date(exhibition.started).getTime();
      const countdown = setInterval(() => {
        const time = Math.floor((getTimeCountdown-new Date().getTime())/1000);
        exhibition.time = {
          days: Math.floor(time / (3600 * 24)),
          hours: Math.floor((time % (3600 * 24)) / 3600),
          minutes: Math.floor((time % 3600) / 60),
          seconds: Math.floor(time % 60)
        };
        
        if (time === 0) clearInterval(countdown);
      }, 1000);

      return exhibition
    }
  }

	/**
	 * * OPEN LINK NEW TAB *
	 * Todo: for open link in new tab
	 */
	openNewTab(url: string){
    const domain = window.location.origin;
    window.open(domain+url) 
  };

	/**
	 * * COPY TEXT *
	 * Todo: for copy text
	 */
	copyText(id){
		const copyText:any = document.getElementById(id);
		copyText.select();
		copyText.setSelectionRange(0, 99999);
		document.execCommand("copy");
		if (id === "sharelink") {
      this.alertMessageService.add({severity:"success", detail: "The link has been copied."})
    } else {
      this.alertMessageService.add({severity:"success", detail: "The code has been copied."})
    }
	}

  /**
   * * OPEN SHARE LINK *
   * Todo: to opne share link
   */
	openShareLink(e){
		this.isShare = true;
    const protocol = window.location.protocol;
		const viewerLink = `${protocol}//${this.whiteLabelService.whiteLabelDomain}/v/${e.share_string}`;
		this.iframe = `<iframe width="560" height="315" src="${viewerLink}" title="${e.name}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
		this.shareableLink = viewerLink;
	}



  
  // =============================== //
  // Detect White Label
  // =============================== //
  detectWhiteLabel(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (
        this.whiteLabelService.whiteLabelDomain === 'villume.com' ||
        this.whiteLabelService.whiteLabelDomain === 'dev.villume.com' ||
        this.whiteLabelService.whiteLabelDomain === 'qa.villume.com' ||
        this.whiteLabelService.whiteLabelDomain === 'localhost:4200'
      ) return;
      this.whiteLabelService.getExhibitionWhiteLabel(this.whiteLabelService.whiteLabelDomain, 0).subscribe({
      next: (res:any) => {
        const data = res.data;
        this.whiteLabelService.whiteLabel = true;
        this.whiteLabelService.whiteLabelUsername = this.username = data.user.username;
        resolve(res);
      },
      error: (err) => {
        this.whiteLabelService.whiteLabel = false;
        reject(err);
      }
      })
    })
  }
}
