// Angular packages
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';

// User-defined services
import { AlertMessageService } from 'src/app/components/alert-message/alert-message.service';
import { ImagesService } from '../images.service';
import { EditorService } from '../../editor.service';
import { ArtworkShadowsService, MainService, store } from 'src/app/shared/services';

// User-defined services
import { ArtworkDimension, ClonedArtwork, ClonedShadowAndDonut, EditArtworkParams } from '../images.interfaces';
import { messages } from '@data';
import { Artwork } from 'src/app/shared/interfaces';

// Third-party packages (npm)
import watch from 'redux-watch';
import { LoadingGalleryService } from 'src/app/components/loading-gallery/loading-gallery.service';
import { environment } from '@environments';
import { UndoRedoService } from 'src/app/shared/services/undo-redo.service';
import { NgIf } from '@angular/common';
import { LazyLoadImageModule } from 'ng-lazyload-image';


@Component({
    selector: 'app-edit-image',
    templateUrl: './edit-image.component.html',
    styleUrls: ['./edit-image.component.scss'],
    standalone: true,
    imports: [LazyLoadImageModule, NgIf, FormsModule, ReactiveFormsModule]
})
export class EditImageComponent implements OnInit, OnDestroy {
  public artworkName: FormControl = new FormControl('', [Validators.required]);
  public onUploading: boolean = false;
	private _selectObjectWatchSubscription: any;
	public env = environment;

  constructor(
    public editorService: EditorService,
		public mainService: MainService,
		private _messageService: AlertMessageService,
    private _artworkService: ImagesService,
		private _artworkShadowsService: ArtworkShadowsService,
		private _loadingGalleryService: LoadingGalleryService,
		private _undoRedoService: UndoRedoService
  ) { 
		this._watchSelectedObjectState();
	}

	ngOnInit(): void {
		this._setArtworkName();
	}

	ngOnDestroy(): void {
		this._selectObjectWatchSubscription();
	}


	/**
	 * * ===================================================== *
	 * * LIST OF GROUPED FUNCTIONS
	 * * ===================================================== *
	 * - EDIT ARTWORK FUNCTIONS
	 * - UNCATAGORIZED FUNCTIONS
	 */


	/**
	 * * ===================================================== *
	 * * EDIT ARTWORK FUNCTIONS
	 * * ===================================================== *
	 * - EDIT ARTWORK
	 * - SET SHADOW AND DONUT
	 * - CLONE SHADOW AND DONUT
	 * - CLONED ACTIVE ARTWORK
	 * - REMOVE OLD ARTWORK DATA
	 * - CREATE NEW ARTWORK NODE
	 * - CREATE FORM DATA
	 * - CREATE EDIT ARTWORK PARAMS
	 * - UPDATE ACTIVE ARTWORK DATA
	 * - UPDATE ARTWORK UNDO REDO STATE
	 */

	//#region 

  /**
	 * * EDIT ARTWORK *
	 * Todo: to replace artwork asset
   * @param e: event input file
	 */
	public editArtwork(e): void {
		const file = e.target.files[0];
		if(this._artworkService.validateFile(file, 'image')){
			const params = this._createEditArtworkParams(file);
			const uploadMessages = messages.editor.artwork.upload;

      const enableLoading = (enable: boolean) => {
        this.onUploading = enable;
        this.editorService.blockUserAction = enable;
        this._loadingGalleryService.show = enable;
        this._loadingGalleryService.percent = 0;
      }
			
      enableLoading(true);
			this._artworkService.editArtwork(params).subscribe(async (res:any)=>{
				if(res['status'] == "progress") {
					this._loadingGalleryService.percent = res['data'];
				}
				
				if(res['status'] == "response"){
					const image = this.mainService.convertPathImage(res['data'].data.link_figure[0].image);
					const imageResolution = await this.editorService.getImageResolutionFromUrl(image);
					const dimension = this._artworkService.resizeArtworkDimension(imageResolution);
					
					this.editorService.updateDimension(this.editorService.activeArtwork.id, dimension).subscribe((res: any) => {
						this.editorService.updateExhibitionViewer().subscribe(async (res: any) => {
							enableLoading(false);

							this._updateActiveArtworkData(image, dimension);
							this._updateArtworkUndoRedoState(image, dimension);

							const { data: artworkData, node: artworkNode } = this._cloneActiveArtwork();
							this.editorService.unselectExhibitAsset();
							const donut = artworkNode['donut'];
							const metadata = artworkNode['metadata'];
							artworkNode.dispose();
			
							const newArtworkNode = await this._createNewArtworkNode(artworkData);
							newArtworkNode['metadata'] = metadata;
							newArtworkNode['donut'] = donut;
							this._artworkShadowsService.addShadowCaster(newArtworkNode);
							this._artworkShadowsService.setShadowCastPosition(newArtworkNode);
							this._artworkShadowsService.setShadowComponentLightsPosition(newArtworkNode);
							
							
							this._artworkService.selectArtwork(artworkData);

							this._removeOldArtworkData();
						}, err => {
							enableLoading(false);
							this._messageService
						})						
					}, err => {
						enableLoading(false);
					});
					
				}
			},err=>{
				enableLoading(false)
				if(err.error.statusCode==401){
					this.mainService.expiredSesionPopup = true;
				} else {
					this._messageService.add({
						severity: 'error', 
						summary: 'Failed', 
						detail: uploadMessages.replace.failed
					})
				}
			})		
		}
		e.target.value = "";
	}


	/**
	 * * SET SHADOW AND DONUT *
	 * Todo: to set shadow and donut of new artwork node
	 * @param artworkNode : BABYLON.TransformNode
	 * @param shadowAndDonut : ClonedShadowAndDonut object
	 */
	private _setShadowAndDonut(
		artworkNode: any, 
		shadowAndDonut: ClonedShadowAndDonut
	): void {
		const { shadow, donut } = shadowAndDonut;
		artworkNode['shadow'] = shadow;
		artworkNode['donut'] = donut;
	}


	/**
	 * * CLONE SHADOW AND DONUT *
	 * Todo: to clone shadow and donut of active artwork
	 * @param artworkNode : BABYLON.TransformNode
	 * @returns : ClonedShadowAndDonut object
	 */
	private _cloneShadowAndDonut(artworkNode: any): ClonedShadowAndDonut {
		return {
			shadow: artworkNode['shadow'],
			donut: artworkNode['donut']
		}
	}


	/**
	 * * CLONED ACTIVE ARTWORK *
	 * Todo: to clone active artwork data and node
	 * @returns : clonedArtwork object
	 */
	private _cloneActiveArtwork(): ClonedArtwork {
		return {
			data: this.editorService.activeArtwork,
			node: this.editorService.activeArtworkNode,
		}
	}


	/**
	 * * REMOVE OLD ARTWORK DATA *
	 * Todo: to remove old artwork data
	 */
	private _removeOldArtworkData(): void {
		this.editorService.artworkNodes = this.editorService.artworkNodes.filter((artworkNode:any)=> !artworkNode.isDisposed());
	}


	/**
	 * * CREATE NEW ARTWORK NODE *
	 * Todo: to create new artwork node
	 * @param artworkData : Artwork object
	 * @returns : BABYLON.TransformNode
	 */
	private async _createNewArtworkNode(artworkData: Artwork): Promise<any> {
		const newArtworkNode = await this.editorService.createArtworkImageVideo(artworkData);
		newArtworkNode['donutHasInitialized'] = true;

		return newArtworkNode;
	}
	

  /**
   * * CREATE FORM DATA *
   * Todo: to create form data
   * @param file : file image
   * @returns : form data
   */
  private _createFormData(file: File): FormData {
    const formData = new FormData();
    formData.append("file_image", file);
    return formData;
  }


	/**
	 * * CREATE EDIT ARTWORK PARAMS *
	 * Todo: to create edit artwork params for API
	 * @param file : file image
	 * @returns : EditArtworkParams object
	 */
	private _createEditArtworkParams(file: File): EditArtworkParams {
		return {
			artworkId: this.editorService.activeArtwork.id,
			exhibitionId: this.editorService.exhibition.id,
			body: this._createFormData(file)
		}
	}


	/**
	 * * UPDATE ACTIVE ARTWORK DATA *
	 * Todo: to update active artwork data
	 * @param imgPath : image path
	 * @param dimension : ArtworkDimension object
	 */
	private _updateActiveArtworkData(
		imgPath: string, 
		dimension: ArtworkDimension
	): void {
		this.editorService.activeArtwork['width'] = dimension.width
		this.editorService.activeArtwork['height'] = dimension.height
		this.editorService.activeArtwork['real_height'] = dimension.realHeight
		this.editorService.activeArtwork['real_width'] = dimension.realWidth
		this.editorService.activeArtwork['dimension'] = `${dimension.width} Cm x ${dimension.height} Cm`
		this.editorService.activeArtwork['image'] = imgPath;
	}


	/**
	 * * UPDATE ARTWORK UNDO REDO STATE *
	 * Todo: to update artwork undo redo state
	 * @param imgPath : image path
	 * @param dimension : ArtworkDimension object
	 */
	private _updateArtworkUndoRedoState(
		imgPath: string, 
		dimension: ArtworkDimension
	): void {
		this._undoRedoService.states.map(x=>{
			x.state.artworks.map(x=>{
				if(x.id==this.editorService.activeArtwork.id){
					x['width'] = dimension.width;
					x['height'] = dimension.height;
					x['real_height'] = dimension.realHeight;
					x['real_width'] = dimension.realWidth;
					x['dimension'] = `${dimension.width} Cm x ${dimension.height} Cm`
					x['image'] = imgPath
				}
			})
		})
	}

	//#endregion





	/**
	 * * ===================================================== *
	 * * UNCATAGORIZED FUNCTIONS
	 * * ===================================================== *
	 * - RENAME ARTWORK
	 * - WATCH SELECTED OBJECT STATE
	 * - SET ARTWORK NAME
	 * - VALIDATE ARTWORK NAME
	 */

	//#region 

	/**
	 * * SET ARTWORK NAME *
	 * Todo: to set artwork name value
	 */
	private _setArtworkName(): void {
		this.artworkName.setValue(this.editorService.activeArtwork.name);
	}
	
	/**
	 * * RENAME ARTWORK *
	 * Todo: to rename artwork
	*/
	public renameArtwork(): void {
		this.editorService.activeArtwork.name = this.artworkName.value;
		this._validateName();
		this.editorService.updateUndoRedoStateWithDelay();
		this.editorService.updateLogActivityWithDelay('Rename artwork');
	}

	/**
	 * * VALIDATE ARTWORK NAME *
	 * Todo: to validate artwork name
	 */
	private _validateName(): void {
		this._artworkService.artworkNameValid = this.artworkName.valid;
		this._artworkService.validateAllArtworkData();
		this.editorService.validateExhibitionData();
	}

	/**
   * * WATCH SELECTED OBJECT STATE *
   * Todo: to watch selected object state from redux store
   */
  private _watchSelectedObjectState(): void {
    const selectObjectWatch = watch(store.getState, 'editor.objectHasSelected')
		this._selectObjectWatchSubscription = store.subscribe(selectObjectWatch((selected: boolean) => {
      if (selected && this.editorService.activeArtworkNode) {
				this._setArtworkName();
      }
		}));
  }

	//#endregion
}
