import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from "src/app/shared/services";
import { ProfileService } from '../profile.service';

@Component({
    selector: 'app-verification',
    templateUrl: './verification.component.html',
    styleUrls: ['./verification.component.scss'],
    standalone: true
})
export class VerificationComponent implements OnInit {
	public infoUser: any;

	constructor(
		private router:Router,
		public mainService: MainService,
    private route:ActivatedRoute,
		private profileService:ProfileService
	) { }

	ngOnInit(): void {
		this.route.queryParams.subscribe(params => {
			let code = params['code'];
			let token = params['token'];
			
			if(this.mainService.isBrowser){
				localStorage.removeItem("temporary_token");
				this.updateVerification(code,token);
			}
		});
	}

	// ==================================== //
	// Update Verification Code
	// ==================================== //
	updateVerification(code,token){
		this.profileService.updateVerificationEmail(code,token).subscribe(res =>{
			document.cookie = "villume_token=" + res['data'].token;
			this.profileService.updateToken(res['data'].token).subscribe(resToken =>{
				window.location.href = "/user-update";
			});
		}, err =>{
			if(err.name == "TimeoutError"){
        this.updateVerification(code,token);
      }else{
				this.router.navigate(["/404"]);
			}
		});
	}
}
