// Angular packages
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';

// Data
import { messages } from '@data';

// User-defined interfaces
import { billingInfo } from 'src/app/shared/interfaces';
import { AddEditBillingInfoParams, AddPaymentMethodParams } from '../billing.interfaces';

// User-defined services
import { AlertMessageService } from 'src/app/components/alert-message/alert-message.service';
import { SubscribePricingService } from 'src/app/pages/pricing/subscribe-pricing/subscribe-pricing.service';
import { BillingService } from '../billing.service';

// User-defined form validators
import { notEmptyObject, onlyLattersAndSpaces, zipCode } from '@custom-validators';
import { MainService } from '@services';
import { PrimeTemplate } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'app-billing-info',
    templateUrl: './billing-info.component.html',
    styleUrls: ['./billing-info.component.scss'],
    standalone: true,
    imports: [NgIf, DialogModule, FormsModule, ReactiveFormsModule, NgClass, DropdownModule, PrimeTemplate]
})
export class BillingInfoComponent implements OnInit {
  public billingInfo: billingInfo;
  public editBillingInfo: boolean = false;
  public states: any[] = [];
  public counties: any[] = [];
  public billingForm: FormGroup;
  public onSaving: boolean = false;
  public onFetching: boolean = false;
  public countryValid: boolean = false;
  public stateValid: boolean = false;

  @Input() public addCardParams: AddPaymentMethodParams;

  @Output() public refetchPaymentMethod = new EventEmitter();

  constructor(
    private _billingService: BillingService,
    private _subscriptionService: SubscribePricingService,
    private _messageService: AlertMessageService,
    public mainService: MainService
  ) { }

  ngOnInit(): void {
    this._initForm();
    if(this.mainService.isBrowser) this._getBillingInfo(true);
  }

  /**
   * * ============================================== *
   * * GETTING DATA FUNCTIONS
   * * ============================================== *
   * * GET COUNTRIES
   * * GET STATES
   * * GET BILLING INFO
   * * ============================================== *
   */

  //#region

  /**
   * * GET COUNTRIES *
   * Todo: to get countries
   */
  private _getCountries(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._subscriptionService.getCountries().subscribe((counties: any) => {
        this.counties = counties;
        resolve(counties);
      }, reject );
    })
  }

  /**
   * * GET STATES *
   * Todo: to get states based on country code
   * @param countryCode: string -> country code
   */
  private _getStates(countryCode:string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._subscriptionService.getStates(countryCode).then((states: any) => {
        this.states = [];
        this.states = states;
        this.billingForm.get('state').setValue(this.states[0]);
        this.stateValid = !this._isObjectEmpty(this.billingForm.get('state'));
        resolve(states);
      }, reject );
    })
  }

  /**
   * * GET BILLING INFO *
   * Todo: to get billing info
   */
  private _getBillingInfo(isFirstLoad: boolean = false, refetch: boolean = false): void {
    this.onFetching = true;
    this._billingService.getBillingInfo(refetch).subscribe(async (res: any) => {
      this.onFetching = false
      if(!Array.isArray(res.data.billing_info)) {
        const billingInfo = res.data.billing_info;
        if (isFirstLoad)  await this._getCountries();
        await this._getStates(billingInfo.country);
        this._assignAdditionalBillingInfo(billingInfo);
        this.setFormValues();
      } else {
        this._getCountries()
      }
    }, err => {
      this.onFetching = false
      this._getCountries()
    })
  }

  /**
   * * ASSIGN ADDITIONAL BILLING INFO *
   * Todo: to assign additional billing info
   * @param data : any -> billing info data
   */
  private _assignAdditionalBillingInfo(data): void {
    this.billingInfo = data;
    this.billingInfo.zip_code = data.postal_code;
    this.billingInfo.is_address_empty = this._isBillingAddressIsEmpty();

    const countryName = this._getDisplayCountry(this.billingInfo.country);
    const stateName = this._getDisplayState(this.billingInfo.state);
    const otherInfo = [stateName, countryName, this.billingInfo.zip_code]
    this.billingInfo.display_other_info = otherInfo.filter(i => i).join(', ');
  }

  /**
   * * GET DISPLAY COUNTRY *
   * Todo: to get display country
   * @param countryCode : string -> country code
   * @returns : string -> country name
   */
  private _getDisplayCountry(countryCode: string): string {
    let country = this.counties.find(c => c.code === countryCode);
    return country ? country.name : null;
  }

  /**
   * * GET DISPLAY STATE *
   * Todo: to get display state
   * @param stateCode : string -> state code
   * @returns : string -> state name
   */
  private _getDisplayState(stateCode: string): string {
    let state = this.states.find(s => s.code === stateCode);
    return state ? state.name : null;
  }

  /**
   * * IS BILLING ADDRESS EMPTY *
   * Todo: to check if billing address, state, country, zip code) is empty 
   * @returns : boolean -> true if empty, false if not empty
   */
  private _isBillingAddressIsEmpty(): boolean {
    return (
      !this.billingInfo.address &&
      !this.billingInfo.state &&
      !this.billingInfo.country &&
      !this.billingInfo.zip_code
    );
  }

  //#endregion




  /**
   * * ============================================== *
   * * ADD/EDIT BILLING INFO FUNCTIONS
   * * ============================================== *
   * * ADD/EDIT BILLING INFO
   * * CREATE ADD/EDIT BILLING INFO PARAMS
   * * ADD PAYMENT METHOD
   * * SAVE BILLING INFO
   * * ============================================== *
   */

  //#region 

  /**
   * * ADD/EDIT BILLING INFO *
   * Todo: to add/edit billing info
   */
  private _addEditBillingInfo(action: 'add' | 'edit') {

    const params = this._createAddEditBillingInfoParams();
    this.onSaving = true;
    this._billingService.addEditBillingInfo(action, params).subscribe(async (res)=> {
      try {
        if(this.addCardParams) await this._addPaymentMethod();  
  
        this.onSaving = false;
        this.editBillingInfo = false;
        this._messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: messages.billing.billingInfo.add.success
        });
  
        this._getBillingInfo(false, true);
      } catch (error) {
        this.onSaving = false;
        this._messageService.add({
          severity: 'warn',
          summary: 'Warning',
          detail: error.error.data.errors.messages
        })
      }
    }, err => {
      this.onSaving = false;
      this._messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: err.error.data.errors.messages
      })
    })
  } 

  /**
   * * ADD PAYMENT METHOD *
   * Todo: to add payment method
   * @returns : Promise<void>
   */
  private _addPaymentMethod(): Promise<void> {
    return new Promise((reslove, reject) => {
      this._billingService.addPaymentMethod(this.addCardParams).subscribe((res: any) => {
        reslove();
        this.refetchPaymentMethod.emit();
      }, (err)=> {
        reject(err)
      })
    })
  }

  /**
   * * CREATE ADD/EDIT BILLING INFO PARAMS
   * Todo: to create add/edit biling info params
   */
  private _createAddEditBillingInfoParams(): AddEditBillingInfoParams {
    return {
      first_name: this.billingForm.get('firstName').value,
      last_name: this.billingForm.get('lastName').value,
      company: this.billingForm.get('companyName').value,
      address: {
        city: this.billingForm.get('city').value,
        country: this.billingForm.get('country').value.code,
        state: this.billingForm.get('state').value.code,
        line1: this.billingForm.get('address').value,
        line2: ' ',
        postal_code: this.billingForm.get('zip').value
      }
    }
  }

  /**
   * * SAVE BILLING INFO *
   * Todo: to saving billing info
   */
  public saveBillingInfo(): void {
    this.billingForm.markAllAsTouched();
    if (this.billingForm.valid) {
      if (this.billingInfo) {
        this._addEditBillingInfo('edit')
      } else {
        this._addEditBillingInfo('add')
      }
    }
  }

  //#endregion



  /**
   * * ============================================== *
   * * UNCATEGORIZED FUNCTIONS
   * * ============================================== *
   * * INIT FORM
   * * ON CHANGE HANDLER
   * * SAVE BILLING INFO
   * * SET FORM VALUES
   * * ============================================== *
   */

  //#region

  /**
   * * INIT FORM *
   * Todo: to init billing form
   */
  private _initForm(): void {
    this.billingForm = new FormGroup({
      firstName: new FormControl('', [Validators.required, onlyLattersAndSpaces()]),
      lastName: new FormControl('', [Validators.required, onlyLattersAndSpaces()]),
      companyName: new FormControl(''),
      address: new FormControl('', [Validators.required]),
      country: new FormControl({}, [Validators.required, notEmptyObject, ]),
      state: new FormControl({}, [Validators.required, notEmptyObject]),
      city: new FormControl('', [Validators.required, onlyLattersAndSpaces()]),
      zip: new FormControl('', [Validators.required, zipCode()]),
      taxNumber: new FormControl(''),
    })
  }

  /**
   * * ON CHANGE HANDLER *
   * Todo: to handle change value event
   * @param isCountryInput : boolean -> to check if the change event is from country input
   */
  public onChangeHandler(isCountryInput: boolean = false): void {
    this.countryValid = !this._isObjectEmpty(this.billingForm.get('country'));
    if (isCountryInput) this._getStates(this.billingForm.get('country').value?.code);
  }

  /**
   * * SET FORM VALUES *
   * Todo: to set form values
   */
  public setFormValues(): void {
    const selectedCountry = this.counties.find(c => c.code === this.billingInfo.country);
    const selectedState = this.states.find(s => s.code === this.billingInfo.state);
    this.billingForm.patchValue({
      firstName: this.billingInfo.first_name,
      lastName: this.billingInfo.last_name,
      companyName: this.billingInfo.company_name,
      address: this.billingInfo.address,
      country: selectedCountry,
      state: selectedState,
      city: this.billingInfo.city,
      zip: this.billingInfo.zip_code,
      taxNumber: this.billingInfo.tax
    })
  }

  // Check empty object
  private _isObjectEmpty(object:any): boolean {
    return Object.keys(object).length === 0;
  }

  //#endregion
}
