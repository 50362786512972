// Common Angular modules/components
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

// User-defined services
import { EditorService } from 'src/app/pages/virtual-gallery/editor/editor.service';
import { AlertMessageService } from '../alert-message/alert-message.service';

// Third party plugins NPM
import { debounce } from 'lodash';
import { FormsModule } from '@angular/forms';
import { SliderModule } from 'primeng/slider';
import { InputNumberComponent } from '../input-number/input-number.component';
import { TooltipModule } from 'primeng/tooltip';
import { NgStyle, NgIf } from '@angular/common';

// Third party plugins CDN
declare const BABYLON: any;

@Component({
    selector: 'app-align-object',
    templateUrl: './align-object.component.html',
    styleUrls: ['./align-object.component.scss'],
    standalone: true,
    imports: [NgStyle, NgIf, TooltipModule, InputNumberComponent, SliderModule, FormsModule]
})
export class AlignObjectComponent implements OnInit {

	@Input() alignLimit: any;
	@Input() activeNodes: any = [];
	@Input() exhibitionHeight: any;
	@Input() disabled: boolean;
	@Input() showAlignLimit:boolean;
	@Input() inputFocus: boolean;

	@Output() onChangePosition = new EventEmitter();
	@Output() onChangeDisplay = new EventEmitter();
	@Output() onAdjustLimit = new EventEmitter();
	@Output() onInput = new EventEmitter();

	public inputTopLimit: number = 0;
	public inputBottomLimit: number = 0;
	public limitValues:number[] = [];
	public maxLimit:number = 0;

	constructor(
		public editorService: EditorService,
		private alertMessageService: AlertMessageService
	) { }

	ngOnInit(): void {
		this.setSliderManualInputValue();
		this.initKeyboardEvent();
	}

	ngOnDestroy() {
		removeEventListener('keydown', this.keyPressed);
	}

	/**
	 * * ALIGN FIGURE *
	 * Todo: to align figure
	 */
	alignObject(position: "top" | "center" | "bottom"){
		if(this.showAlignLimit){
			// calculate alignment position
			const limitTop = this.inputTopLimit/100;
			const limitBottom = this.inputBottomLimit/100;

			const alignValues: any = [];
			this.activeNodes.map((node: any) => {
				node['shadow']?.setEnabled(false);
				
				const wrapper = this.createNodeWrapper(node);
				switch (position) {
					case "top":
						wrapper.position.y = limitTop - (wrapper.height/2);
					break;
					case "center":
						wrapper.position.y = (limitTop-limitBottom)/2+limitBottom;
					break;
					case "bottom":
						wrapper.position.y = limitBottom+(wrapper.height/2)
					break;
				}

				alignValues.push({ nodeId: node.id })
				
				// if (node.name === 'artwork') node.position.y = wrapper.position.y;
				// else 
				node.setParent(null);
				wrapper.dispose();
			})

			this.onChangePosition.emit({alignValues, position});
		}else{
			this.alertMessageService.add({severity:'warn', summary: 'Warning', detail: `Please activate the function \"Grid\" before you start aligning the ${this.activeNodes[0].name}.`});
		}
	}
	
	/** 
	 * * SHOW/HIDE ALIGN LIMIT *
	 * Todo: for show and hide align limit object
	 */
	 showAlignLimitMesh(){
		// change value 'showAlignLimit' variable 
		this.showAlignLimit = !this.showAlignLimit;
		
		if(this.showAlignLimit){
			this.onChangeDisplay.emit({
				visibility: this.showAlignLimit ? 1 : 0,
				update:"Enabled align limit"
			})
		}else{
			this.onChangeDisplay.emit({
				visibility: this.showAlignLimit ? 1 : 0,
				update: "Disabled align limit"
			})
		}

	}

	/**
	 * * SET SLIDER MANUAL INPUTS VALUE *
	 * Todo: to set slider manual input value, the original values have been recalculated for use in input element 
	 */
	setSliderManualInputValue(){
		this.inputTopLimit = Math.round(this.alignLimit[0] * 100);
		this.inputBottomLimit = Math.round(this.alignLimit[1] * 100);
		this.maxLimit = Math.round(this.exhibitionHeight * 100)
		this.limitValues = [this.inputBottomLimit,this.inputTopLimit];
	}

	/**
	 * * UPDATE SLIDER MANUAL INPUT VALUE *
	 * Todo: to to update the slider value via manual input, 
	 */
	 updateSliderManualInput(value:number, type: string, min:number, max:number){
		// validate input value
		value = this.validateSliderManualInput(value,min,max);
		
		// Choose action based on 'type'
		switch(type){
			// Update top limit position value
			case "topLimit": 
				this.limitValues = [this.inputBottomLimit, value]
				this.onAdjustLimit.emit({
					topLimit: value/100
				})
			break;
			
			// Update bottom limit position value
			case "bottomLimit": 
				this.limitValues = [value, this.inputTopLimit]
				this.onAdjustLimit.emit({
					bottomLimit: value/100
				})
			break;
		}
	}

	/**
	 * * VALIDATE SLIDER MANUAL INPUT *
	 * Todo: to validate slider manual input
	 */
	validateSliderManualInput(value,min,max){
		// if the input value is not empty and is of type number
		if(value!=null&&typeof value == "number"){
			// Validate the input value based on the maximum and minimum values.
			// if the input value is more than the maximum value that has been determined, 
			// then the input value will be filled with the maximum value, and vice versa
			if(value > max ) value = max;
			if(value < min ) value = min;
		} else {
			value = min;
		}

		return value;
	}

	/**
	 * * ADJUST SLIDER ALIGN LIMIT POSITION *
	 * Todo: to adjust align limit position
	 */
	adjustSliderAlignLimit(value:number[]){ 
		// for adjust align limit
		this.inputTopLimit = this.validateSliderManualInput(value[1],this.inputBottomLimit+100,this.maxLimit);
		this.inputBottomLimit = this.validateSliderManualInput(value[0],0,this.inputTopLimit-100);
		this.limitValues = [this.inputBottomLimit,this.inputTopLimit];
		this.onAdjustLimit.emit({bottomLimit: this.inputBottomLimit/100,topLimit: this.inputTopLimit/100});
	}

	/**
	 * * ADJUST ALIGN LIMIT POSITION *
	 * Todo: to adjust align limit position
	 */
	adjustAlignLimit(value:number,type:string){
		switch(type){
			case "top":
				this.updateSliderManualInput(
					value,
					'topLimit',
					this.inputBottomLimit+100,
					this.maxLimit
				);
			break;
			case "bottom":
				this.updateSliderManualInput(
					value,
					'bottomLimit',
					0,
					this.inputTopLimit-100
				);
			break;
		}

		this.setSliderManualInputValue();
	}

	
	/**
	 * * ADJUST ALIGN LIMIT POSITION WITH DELAY*
	 * Todo: to adjust align limit position with delay
	 */
	adjustAlignLimitWithDelay = debounce((value:number, type:string) => {
		this.adjustAlignLimit(value, type)
	}, 500)

	/**
	 * * INIT KEYBOARD EVENT *
	 * Todo: initialize keyboard event
	 */
	initKeyboardEvent(){
		addEventListener('keydown', this.keyPressed);
	}

	// initialize keyboard event
	keyPressed = (e)=>{
		if (!this.inputFocus){
			if(e.shiftKey && e.keyCode==84 ){
				this.alignObject('top');
			}
			
			if(e.shiftKey && e.keyCode==67 ){
				this.alignObject('center');
			}
			
			if(e.shiftKey && e.keyCode==66 ){
				this.alignObject('bottom');
			}
		}
	}

	/**
	 * * CRATE NODE WRAPPER *
	 * Todo: to creating node wrapper(Box) based on bouding box 
	 * @param node : BABYLON.TransformNode -> Container of Artwork, TextWall and OrdinaryObject
	 * @return BABYLON.Mesh -> Container of "node" in param
	 */
	createNodeWrapper(node: any) {
		const meshParent = new BABYLON.Mesh("parent", this.editorService.scene);
		node.getChildMeshes().map((mesh) => {
			const clonedMesh = mesh.clone();
      clonedMesh.setParent(meshParent)
    })

		const childMeshes = meshParent.getChildMeshes();
		let min = childMeshes[0].getBoundingInfo().boundingBox.minimumWorld;
		let max = childMeshes[0].getBoundingInfo().boundingBox.maximumWorld;

		for(let i=0; i<childMeshes.length; i++){
			const meshMin = childMeshes[i].getBoundingInfo().boundingBox.minimumWorld;
			const meshMax = childMeshes[i].getBoundingInfo().boundingBox.maximumWorld;

			min = BABYLON.Vector3.Minimize(min, meshMin);
			max = BABYLON.Vector3.Maximize(max, meshMax);
		}

		meshParent.setBoundingInfo(new BABYLON.BoundingInfo(min, max));
		meshParent.showBoundingBox = true;

		const parentDimensions = (meshParent.getBoundingInfo().boundingBox.extendSizeWorld).scale(2);
		const boxWrapper = BABYLON.MeshBuilder.CreateBox(
			"boxWrapper", { 
				width: parentDimensions.x, 
				height: parentDimensions.y, 
				depth: parentDimensions.z
			}, 
			this.editorService.scene
		);
		boxWrapper.position.copyFrom(meshParent.getBoundingInfo().boundingBox.centerWorld);
		boxWrapper.visibility = 0.4;
		boxWrapper.height = parentDimensions.y;
		meshParent.dispose();

		node.setParent(boxWrapper);

		return boxWrapper;
	}

}
