<!-- ROTATE X VIA SLIDER
============================== -->
<div class="form-group content-slide mb30">
    <div class="title-content">
        <label>Rotation X</label>
        <input-number 
            [value]="rotateX"
            (onChange)="rotateViaInput($event, 'x')"
            (onBlur)="editorService.onInput = false"
            (onFocus)="editorService.onInput = true"
            [disabled]="editorService.blockUserAction"
            [min]="-180"
            [max]="180">
        </input-number>
    </div>
    <p-slider 
        [(ngModel)]="editorService.activeArtworkNode.rotation.x" 
        (onSlideEnd)="updateRotationData()"
        (onChange)="initRotationValues()" 
        [disabled]="editorService.blockUserAction" 
        [step]="0.000001" 
        [styleClass]="editorService.blockUserAction? 'disable-slider ml15' : 'ml15'" 
        [min]="-3.14159" 
        [max]="3.14159">
    </p-slider>
</div>

<!-- ROTATION Y VIA SLIDER
============================== -->
<div class="form-group content-slide mb30">
    <div class="title-content">
        <label>Rotation Y</label>
        <input-number 
            [value]="rotateY"
            (onChange)="rotateViaInput($event, 'y')"
            (onBlur)="editorService.onInput=$event"
            (onFocus)="editorService.onInput=$event"
            [disabled]="editorService.blockUserAction"
            [min]="-180"
            [max]="180">
        </input-number>
    </div>
    <p-slider 
        [(ngModel)]="editorService.activeArtworkNode.rotation.y" 
        (onSlideEnd)="updateRotationData()"
        (onChange)="initRotationValues()" 
        [step]="0.000001" 
        [disabled]="editorService.blockUserAction" 
        [min]="-3.14159"
        [styleClass]="editorService.blockUserAction? 'disable-slider ml15' : 'ml15'" 
        [max]="3.14159">
    </p-slider>
</div>

<!-- ROTATION Z VIA SLIDER
============================== -->
<div class="form-group content-slide" [style]="{'margin-bottom': '18px'}">
    <div class="title-content">
        <label>Rotation Z</label>
        <input-number 
            [value]="rotateZ"
            (onChange)="rotateViaInput($event, 'z')"
            (onBlur)="editorService.onInput=$event"
            (onFocus)="editorService.onInput=$event"
            [disabled]="editorService.blockUserAction"
            [min]="-180"
            [max]="180">
        </input-number>
    </div>
    <p-slider 
        [(ngModel)]="editorService.activeArtworkNode.rotation.z" 
        (onSlideEnd)="updateRotationData()"
        (onChange)="initRotationValues()" 
        [step]="0.000001" 
        [disabled]="editorService.blockUserAction" 
        [styleClass]="editorService.blockUserAction? 'disable-slider ml15' : 'ml15'" 
        [min]="-3.14159" 
        [max]="3.14159">
    </p-slider>
</div>
