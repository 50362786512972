import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments';
import { MainService } from 'src/app/shared/services';
import { Observable } from 'rxjs';
import { AddPromoCodeParams, EditPromoCodeParams } from './promocodes.interfaces';

@Injectable({
  providedIn: 'root'
})
export class PromocodesService {

  constructor(
    private _http: HttpClient,
    private _mainService: MainService
  ) { }

  /**
   * * GET PROMO CODES *
   * ANCHOR Get Promo Codes
   * @description: to get promo codes
   * @returns : Observable<any> -> http observable
   */
  public getPromoCodes(
      refetch: boolean = false, 
      status: 'all' | 'active' | 'expired',
      page: number = 1,
      limit: number = 10
    ): Observable<any> 
  {
    const params = {
      is_active: status === 'all' ? null : status == 'active',
      page,
      limit
    };
    const searchParams = new URLSearchParams();
    for (const key in params) {
      if (params[key] !== null) {
        searchParams.append(key, params[key]);
      }
    }
    const queryString = searchParams.toString();

    return this._http.get(this._mainService.fetchDataFromApi({
      host: 'billing/list-coupon',
      params: queryString,
      refetch: refetch
    }));
  }

  /**
   * * DELETE PROMO CODE *
   * ANCHOR Delete Promo Code
   * @description: to delete promo code
   * @param code : string -> promo code to be deleted
   * @returns : Observable<any> -> http observable
   */
  public deletePromoCode(code: string): Observable<any> {
    return this._http.delete(`${environment.baseURL}/billing/delete-coupon?code=${code}`);
  }
  
  /**
   * * ADD PROMO CODE *
   * ANCHOR Add Promo Code
   * @description: to add promo code
   * @param data : AddPromoCodeParams -> data to be added
   * @returns : Observable<any> -> http observable
   */
  public addPromoCode(data: AddPromoCodeParams): Observable<any> {
    return this._http.post(`${environment.baseURL}/billing/add-coupon`, data);
  }

  /**
   * * GET PROMO CODE *
   * ANCHOR Get Promo Code
   * @description: to get promo code
   * @param code : string -> promo code to be fetched
   * @returns : Observable<any> -> http observable
   */
  public getPromoCode(promoId: string, refetch: boolean = false): Observable<any> {
    return this._http.get(this._mainService.fetchDataFromApi({
      host: 'billing/detail-coupon',
      params: `promo_id=${promoId}`,
      refetch: refetch
    }));
  }

  /**
   * * EDIT PROMO CODE *
   * ANCHOR Edit Promo Code
   * @description: to edit promo code
   * @param payload : EditPromoCodeParams -> data to be edited
   * @returns 
   */
  public editPromoCode(payload: EditPromoCodeParams): Observable<any> {
    return this._http.post(`${environment.baseURL}/billing/edit-coupon`, payload);
  }

  /**
   * * GET PRODUCT FOR SUPPORT *
   * ANCHOR Get Product For Support
   * @description: get product for support
   * @returns 
   */
  public getProductSupport(): Observable<any> {
    return this._http.get(`${environment.baseURL}/billing/product-for-support`);
  }

   /**
   * * GET LIST USER FOR VOUCHER *
   * ANCHOR Get List User For Voucher
   * @description: get list user for voucher
   * @returns 
   */
   public getListUsers(): Observable<any> {
    return this._http.get(`${environment.baseURL}/support/email-users`);
  }
}
