<app-layouts #layouts>
    <div class="wrap-contact" content>
        <h1 class="title">Contact Us</h1>
        <div class="row">
            <div class="col col16-8 offset16-6 col-iped-7 offset-iped-1 offset-mobile-0 pr-0 pl-0 wrap-accordion">
                <div class="accordion text-left" id="accordionExample">

                    <!-- CONTACT US & GENERAL INQUIRES -->
                    <div class="card" id="general">

                        <!-- TITLE CONTENT -->
                        <div class="card-header">
                            <button class="btn-accordion" (click)="toggleFormContent('general')" [class.active-contact]="contactService.formType=='general'">
                                Contact Us & General Inquiries
                                <div class="wrap-icon">
                                    <i class="vi vi-plus" *ngIf="contactService.formType!='general'"></i>
                                    <i class="vi vi-dash" *ngIf="contactService.formType=='general'"></i>
                                </div>
                            </button>
                        </div>

                        <!-- CONTENT -->
                        <div [@slideUp] *ngIf="contactService.formType=='general'" class="card-body">

                            <!-- FORM INPUT -->
                            <form action="" class="form-group" [formGroup]="formGeneral">

                                <!-- INPUT GROUP EMAIL -->
                                <div class="form-group">

                                    <!-- INPUT EMAIL -->
                                    <label>Your email address</label>
                                    <input 
                                        type="email" 
                                        class="form-control" 
                                        formControlName="email" 
                                        (input)="handleSpaceAtBegin('email')"
                                        [ngClass]="{'is-invalid':formGeneral.controls?.email?.invalid && formGeneral.controls?.email.touched}"
                                    />

                                    <!-- VALIDATION MESSAGE -->
                                    <div class="invalid-feedback" *ngIf="formGeneral.controls?.email.errors">

                                        <!-- REQUIRED ERROR -->
                                        <ng-container *ngIf="formGeneral.controls?.email.errors.required">
                                            Email is required
                                        </ng-container>

                                        <!-- INVALID EMAIL ERROR -->
                                        <ng-container *ngIf="formGeneral.controls?.email.errors.email">
                                            Email is not valid
                                        </ng-container>

                                    </div>

                                </div>

                                <!-- INPUT GROUP SUBJECT -->
                                <div class="form-group">

                                    <!-- INPUT SUBJECT -->
                                    <label>Subject</label>
                                    <input 
                                        type="text" 
                                        (input)="handleSpaceAtBegin('subject')"
                                        class="form-control" 
                                        formControlName="subject" 
                                        [ngClass]="{'is-invalid':formGeneral.controls?.subject?.invalid&&formGeneral.controls?.subject.touched}"
                                    />
                                    
                                    <!-- VALIDATION MESSAGE -->
                                    <div class="invalid-feedback" *ngIf="formGeneral.controls?.subject.errors">

                                        <!-- REQUIRED ERROR -->
                                        <ng-container *ngIf="formGeneral.controls?.subject.errors.required">
                                            Subject is required
                                        </ng-container>

                                    </div>

                                </div>

                                <!-- INPUT GROUP DESCRIPTION -->
                                <div class="form-group">

                                    <!-- INPUT DESCRIPTION -->
                                    <label>Description</label>
                                    <textarea 
                                        class="form-control description" 
                                        (input)="handleSpaceAtBegin('description')"
                                        [ngStyle]="{'height.px': 210, 'resize': 'none'}" 
                                        formControlName="description" 
                                        [ngClass]="{'is-invalid':formGeneral.controls?.description?.invalid&&formGeneral.controls?.description.touched}">
                                    </textarea>

                                    <!-- VALIDATION MESSAGE -->
                                    <div class="invalid-feedback" *ngIf="formGeneral.controls?.description.errors">

                                        <!-- REQUIRED ERROR -->
                                        <ng-container *ngIf="formGeneral.controls?.description.errors.required">
                                            Description is required
                                        </ng-container>

                                    </div>

                                </div>

                                <!-- INPUT GROUPS NAME -->
                                <div class="wrap-set-name mb0">

                                    <!-- INPUT GRUP FIRST NAME -->
                                    <div class="form-group mr20 mb0">
                                        
                                        <!-- INPUT FIRST NAME -->
                                        <label>First name</label>
                                        <input 
                                            type="text" 
                                            class="form-control" 
                                            (input)="handleSpaceAtBegin('first_name')"
                                            formControlName="first_name" 
                                            [ngClass]="{'is-invalid':formGeneral.controls?.first_name?.invalid&&formGeneral.controls?.first_name.touched}" 
                                        />

                                        <!-- VALIDATION MESSAGE -->
                                        <div class="invalid-feedback" *ngIf="formGeneral.controls?.first_name.errors">

                                            <!-- REQUIRED ERROR -->
                                            <ng-container *ngIf="formGeneral.controls?.first_name.errors.required">
                                                First name is required
                                            </ng-container>

                                        </div>

                                    </div>

                                    <!-- INPUT GRUP LAST NAME -->
                                    <div class="form-group mb0">

                                        <!-- INPUT LAST NAME -->
                                        <label>Last name</label>
                                        <input 
                                            type="text" 
                                            class="form-control"
                                            (input)="handleSpaceAtBegin('last_name')"
                                            formControlName="last_name" 
                                            [ngClass]="{'is-invalid':formGeneral.controls?.last_name?.invalid&&formGeneral.controls?.last_name.touched}" 
                                        />
                                        
                                        <!-- VALIDATION MESSAGE -->
                                        <div class="invalid-feedback" *ngIf="formGeneral.controls?.last_name.errors">
                                            <ng-container *ngIf="formGeneral.controls?.last_name.errors.required">
                                                Last name is required
                                            </ng-container>
                                        </div>

                                    </div>

                                </div>

                                <div class="recaptcha mt-4">
                                    <div [class.hidden]="formGeneral.controls?.captchaVerified?.value" class="g-recaptcha" id="captchaGeneral"></div>
                                    <div [class.hidden]="!formGeneral.controls?.captchaVerified?.value" class="recaptcha-valid">
                                        <i class="vi vi-check"></i>
                                        Recaptcha verified
                                    </div>
                                </div>

                                <!-- SUBMIT BUTTON -->
                                <div class="d-flex justify-content-end mt40">
                                    <button 
                                        class="btn btn-primary button-submit" 
                                        (click)="submitContact('general')" 
                                        [class.loading-button-primary]="onLoadingGeneral" 
                                        [disabled]="onLoadingGeneral || formGeneral?.invalid || formGeneral?.invalid && !formGeneral.get('captchaVerified').value">
                                        Submit
                                    </button>
                                </div>

                            </form>

                        </div>

                    </div>
                    
                    <!-- HELP CENTER -->
                    <div class="card" id="help">

                        <!-- TITLE CONTENT -->
                        <div class="card-header">
                            <button class="btn-accordion" (click)="toggleFormContent('help')" [class.active-contact]="contactService.formType=='help'">
                                Help Center
                                <div class="wrap-icon">
                                    <i class="vi vi-plus" *ngIf="contactService.formType!='help'"></i>
                                    <i class="vi vi-dash" *ngIf="contactService.formType=='help'"></i>
                                </div>
                            </button>
                        </div>

                        <!-- CONTENT -->
                        <div [@slideUp] *ngIf="contactService.formType=='help'" class="card-body">

                            <!-- FORM INPUTS -->
                            <form action="" class="form-group" [formGroup]="formHelp">

                                <!-- INPUT GROUP EMAIL -->
                                <div class="form-group">
                                    
                                    <!-- INPUT EMAIL -->
                                    <label>Your email address</label>
                                    <input 
                                        type="email"
                                        (input)="handleSpaceAtBegin('email')" 
                                        class="form-control" 
                                        formControlName="email" 
                                        [ngClass]="{'is-invalid':formHelp.controls?.email?.invalid&&formHelp.controls?.email.touched}"
                                    />

                                    <!-- VALIDATION MESSAGE -->
                                    <div class="invalid-feedback" *ngIf="formHelp.controls?.email.errors">

                                        <!-- REQUIRED ERROR -->
                                        <ng-container *ngIf="formHelp.controls?.email.errors.required">
                                            Email is required
                                        </ng-container>

                                        <!-- INVALID EMAIL ERROR -->
                                        <ng-container *ngIf="formHelp.controls?.email.errors.email">
                                            Email is not valid
                                        </ng-container>

                                    </div>

                                </div>

                                <!-- INPUT GROUP SUBJECT -->
                                <div class="form-group">
                                    
                                    <!-- INPUT SUBJECT -->
                                    <label>Subject</label>
                                    <input 
                                        type="text" 
                                        class="form-control" 
                                        (input)="handleSpaceAtBegin('subject')" 
                                        formControlName="subject" 
                                        [ngClass]="{'is-invalid':formHelp.controls?.subject?.invalid&&formHelp.controls?.subject.touched}" 
                                    />

                                    <!-- VALIDATION MESSAGE -->
                                    <div class="invalid-feedback" *ngIf="formHelp.controls?.subject.errors">
                                        
                                        <!-- REQUIRED ERROR -->
                                        <ng-container *ngIf="formHelp.controls?.subject.errors.required">
                                            Subject is required
                                        </ng-container>

                                    </div>

                                </div>

                                <!-- INPUT GROUP DESCRIPTION -->
                                <div class="form-group">

                                    <!-- INPUT DESCRIPTION -->
                                    <label>Description</label>
                                    <textarea 
                                        class="form-control description" 
                                        [ngStyle]="{'height.px': 210, 'resize': 'none'}" 
                                        (input)="handleSpaceAtBegin('description')" 
                                        formControlName="description" 
                                        [ngClass]="{'is-invalid':formHelp.controls?.description?.invalid&&formHelp.controls?.description.touched}">
                                    </textarea>

                                    <!-- VALIDATION MESSAGE -->
                                    <div class="invalid-feedback" *ngIf="formHelp.controls?.description.errors">

                                        <!-- REQUIRED ERROR -->
                                        <ng-container *ngIf="formHelp.controls?.description.errors.required">
                                            Description is required
                                        </ng-container>

                                    </div>

                                </div>

                                <!-- INPUT GROUPS NAME -->
                                <div class="wrap-set-name">

                                    <!-- INPUT GROUP FIRST NAME -->
                                    <div class="form-group mr20 mb0">

                                        <!-- INPUT FIRST NAME -->
                                        <label>First name</label>
                                        <input 
                                            type="text" 
                                            class="form-control" 
                                            (input)="handleSpaceAtBegin('first_name')" 
                                            formControlName="first_name" 
                                            [ngClass]="{'is-invalid':formHelp.controls?.first_name?.invalid&&formHelp.controls?.first_name.touched}" 
                                        />

                                        <!-- VALIDATION MESSAGE -->
                                        <div class="invalid-feedback" *ngIf="formHelp.controls?.first_name.errors">

                                            <!-- REQUIRED ERROR -->
                                            <ng-container *ngIf="formHelp.controls?.first_name.errors.required">
                                                First name is required
                                            </ng-container>

                                        </div>

                                    </div>

                                    <!-- INPUT GROUP LAST NAME -->
                                    <div class="form-group mb0">

                                        <!-- INPUT LAST NAME -->
                                        <label>Last name</label>
                                        <input 
                                            type="text" 
                                            class="form-control" 
                                            (input)="handleSpaceAtBegin('last_name')" 
                                            formControlName="last_name" 
                                            [ngClass]="{'is-invalid':formHelp.controls?.last_name?.invalid&&formHelp.controls?.last_name.touched}" 
                                        />

                                        <!-- VALIDATION MESSAGE -->
                                        <div class="invalid-feedback" *ngIf="formHelp.controls?.last_name.errors">

                                            <!-- REQUIRED ERROR -->
                                            <ng-container *ngIf="formHelp.controls?.last_name.errors.required">
                                                Last name is required
                                            </ng-container>
                                        </div>
                                    </div>

                                </div>

                                <!-- INPUT ATTACEMENT FILES -->
                                <div class="form-group mb0">

                                    <!-- INPUT UPLOAD ATTACEMENT FILE -->
                                    <input  
                                        hidden 
                                        multiple 
                                        [accept]="allowedExtensionsHelp" 
                                        type="file" 
                                        [disabled]="onLoadingHelp"
                                        #inputAttacementHelp  
                                        (change)="uploadAttachementFiles($event, 'help')"/>

                                    <!-- LABEL -->
                                    <label>Add a screenshot if needed. We support: JPG, JPEG, PNG.</label>

                                    <!-- BUTTON UPLOAD -->
                                    <div *ngIf="helpAttacementFiles.length == 0" id="drop-area-help">
                                        <button class="btn-upload-image" [disabled]="onLoadingHelp" (click)="inputAttacementHelp.click()" id="uploadHelp">
                                            Drop your image here
                                        </button>
                                    </div>

                                    <!-- PREVIEW LIST -->
                                    <ng-container *ngIf="helpAttacementFiles.length > 0">
                                        <div class="wrap-upload-image">

                                            <!-- PREVIEW FILES -->
                                            <div class="item-image-upload" *ngFor="let file of helpAttacementFiles">
                                                <img [defaultImage]="env.staticAssets+'images/other/skeleton.svg?t'+mainService.appVersion" [lazyLoad]="file.url" alt="" class="img-upload">
                                                <a href="javascript:;" (click)="deleteAttacementFile(file.id,'help')" class="hide-image">
                                                    <i class="vi vi-x"></i>
                                                </a>
                                            </div>

                                            <!-- UPLOAD NEW ATTACEMENT FILE -->
                                            <div class="add-image-upload" id="drop-area-help-add">
                                                <a href="javascript:;" [disabled]="onLoadingHelp" [class.disabled]="onLoadingHelp" (click)="inputAttacementHelp.click()" class="add-image">
                                                    <i class="vi vi-plus"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>

                                <div class="recaptcha mt-4">
                                    <div [class.hidden]="formHelp.controls?.captchaVerified?.value" class="g-recaptcha" id="captchaHelp"></div>
                                    <div [class.hidden]="!formHelp.controls?.captchaVerified?.value" class="recaptcha-valid">
                                        <i class="vi vi-check"></i>
                                        Recaptcha verified
                                    </div>
                                </div>

                                <!-- SUBMIT BUTTON -->
                                <div class="d-flex justify-content-end mt40">
                                    <button 
                                        class="btn btn-primary button-submit" 
                                        (click)="submitContact('help')" 
                                        [class.loading-button-primary]="onLoadingHelp" 
                                        [disabled]="onLoadingHelp||formHelp?.invalid || formHelp?.invalid && !formHelp.get('captchaVerified').value">
                                        {{onLoadingHelp ? loadingProgress+'%' : 'Submit'}}
                                    </button>
                                </div>

                            </form>

                        </div>

                    </div>

                    <!-- CUSTOM EXHIBITION REQUEST -->
                    <div class="card">
                        <!-- TITLE CONTENT -->
                        <div class="card-header" id="custom">
                            <button class="btn-accordion" (click)="toggleFormContent('custom')" [class.active-contact]="contactService.formType=='custom'">
                                Custom Exhibition request
                                <div class="wrap-icon">
                                    <i class="vi vi-plus" *ngIf="contactService.formType!='custom'"></i>
                                    <i class="vi vi-dash" *ngIf="contactService.formType=='custom'"></i>
                                </div>
                            </button>
                        </div>

                        <!-- CONTENT -->
                        <div [@slideUp] *ngIf="contactService.formType=='custom'" class="card-body">

                            <!-- FORM INPUTS -->
                            <form action="" class="form-group" [formGroup]="formRequest">

                                <!-- INPUT GROUP EMAIL -->
                                <div class="form-group">
                                    
                                    <!-- INPUT EMAIL -->
                                    <label>Your email address</label>
                                    <input 
                                        type="email" 
                                        class="form-control" 
                                        formControlName="email" 
                                        (input)="handleSpaceAtBegin('email')" 
                                        [ngClass]="{'is-invalid':formRequest.controls?.email?.invalid&&formRequest.controls?.email.touched}"
                                    />

                                    <!-- VALIDATION MESSAGE -->
                                    <div class="invalid-feedback" *ngIf="formRequest.controls?.email.errors">

                                        <!-- REQUIRED ERROR -->
                                        <ng-container *ngIf="formRequest.controls?.email.errors.required">
                                            Email is required
                                        </ng-container>

                                        <!-- INVALID EMAIL ERROR -->
                                        <ng-container *ngIf="formRequest.controls?.email.errors.email">Email is not valid</ng-container>
                                    </div>

                                </div>

                                <!-- INPUT GROUP DESCRIPTION -->
                                <div class="form-group">

                                    <!-- INPUT DESCRIPTION -->
                                    <label>Describe your request</label>
                                    <textarea 
                                        class="form-control description" 
                                        [ngStyle]="{'height.px': 210, 'resize': 'none'}" 
                                        (input)="handleSpaceAtBegin('description')" 
                                        formControlName="description" 
                                        [ngClass]="{'is-invalid':formRequest.controls?.description?.invalid&&formRequest.controls?.description.touched}">
                                    </textarea>

                                    <!-- VALIDATION MESSAGE -->
                                    <div class="invalid-feedback" *ngIf="formRequest.controls?.description.errors">
                                        
                                        <!-- REQUIRED ERROR -->
                                        <ng-container *ngIf="formRequest.controls?.description.errors.required">
                                            Description is required
                                        </ng-container>

                                    </div>

                                </div>

                                <!-- INPUT GROUPS NAME -->
                                <div class="wrap-set-name">

                                    <!-- INPUT GROUP FIRST NAME -->
                                    <div class="form-group mr20 mb0">

                                        <!-- INPUT FIRST NAME -->
                                        <label>First name</label>
                                        <input 
                                            type="text" 
                                            class="form-control" 
                                            formControlName="first_name" 
                                            (input)="handleSpaceAtBegin('first_name')" 
                                            [ngClass]="{'is-invalid':formRequest.controls?.first_name?.invalid&&formRequest.controls?.first_name.touched}"
                                        />

                                        <!-- VALIDATION MESSAGE -->
                                        <div class="invalid-feedback" *ngIf="formRequest.controls?.first_name.errors">

                                            <!-- REQUIRED ERROR -->
                                            <ng-container *ngIf="formRequest.controls?.first_name.errors.required">
                                                First name is required
                                            </ng-container>

                                        </div>

                                    </div>

                                    <!-- INPUT GROUP LAST NAME -->
                                    <div class="form-group mb0">
                                        
                                        <!-- INPUT LAST NAME -->
                                        <label>Last name</label>
                                        <input 
                                            type="text" 
                                            class="form-control" 
                                            formControlName="last_name"
                                            (input)="handleSpaceAtBegin('last_name')" 
                                            [ngClass]="{'is-invalid':formRequest.controls?.last_name?.invalid&&formRequest.controls?.last_name.touched}" 
                                        />

                                        <!-- VALIDATION MESSAGE -->
                                        <div class="invalid-feedback" *ngIf="formRequest.controls?.last_name.errors">

                                            <!-- REQUIRED ERROR -->
                                            <ng-container *ngIf="formRequest.controls?.last_name.errors.required">
                                                Last name is required
                                            </ng-container>

                                        </div>

                                    </div>

                                </div>
                                
                                <!-- INPUT GROUP COMPANY -->
                                <div class="form-group">
                                    <label>Name of the company / art institution (optional)</label>
                                    <input type="text" class="form-control" (input)="handleSpaceAtBegin('company')" formControlName="company" />
                                </div>

                                <!-- INPUT GROUP WEBSITE -->
                                <div class="form-group">
                                    <label>Website URL (optional)</label>
                                    <input type="text" class="form-control" (input)="handleSpaceAtBegin('web_url')" formControlName="web_url"/>
                                </div>
                                
                                <!-- INPUT ATTACEMENT FILES -->
                                <div class="form-group mb0">

                                    <!-- INPUT FILE ATTACEMENT FILES -->
                                    <input  
                                        hidden 
                                        type="file" 
                                        [accept]="allowedExtensionsCustom" 
                                        #inputAttacementCustom 
                                        [disabled]="onLoadingCustom"
                                        multiple
                                        (change)="uploadAttachementFiles($event, 'custom')"
                                    />

                                    <!-- LABEL -->
                                    <label>You can upload here a floor plan, pictures or other documents connected to the project.
                                        <br>We support: JPG, JPEG, PNG, DOCX, DOC, PDF.</label>

                                    <!-- BUTTON UPLOAD -->
                                    <div *ngIf="customAttacementFiles.length == 0" id="drop-area-custom">
                                        <button class="btn-upload-image" [disabled]="onLoadingCustom" (click)="inputAttacementCustom.click()" id="uploadCustom">
                                            Press to upload or <br> drag and drop your files here
                                        </button> 
                                    </div>

                                    <!-- PREVIEW LIST -->
                                    <ng-container *ngIf="customAttacementFiles.length > 0">
                                        <div class="wrap-upload-image">

                                            <!-- PREVIEW FILES -->
                                            <div class="item-image-upload" *ngFor="let file of customAttacementFiles">
                                                <img [defaultImage]="env.staticAssets+'images/other/skeleton.svg?t='+mainService.appVersion" [lazyLoad]="file.url" alt="" class="img-upload">
                                                <a href="javascript:;" (click)="deleteAttacementFile(file.id,'custom')" class="hide-image">
                                                    <i class="vi vi-x"></i>
                                                </a>
                                            </div>

                                            <!-- UPLOAD NEW ATTACEMENT FILES -->
                                            <div class="add-image-upload" id="drop-area-custom-add">
                                                <a href="javascript:;" (click)="inputAttacementCustom.click()" class="add-image" [class.disabled]="onLoadingCustom">
                                                    <i class="vi vi-plus"></i>
                                                 </a>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>

                                <div class="recaptcha mt-4">
                                    <div [class.hidden]="formRequest.controls?.captchaVerified?.value" class="g-recaptcha" id="captchaCustom"></div>
                                    <div [class.hidden]="!formRequest.controls?.captchaVerified?.value" class="recaptcha-valid">
                                        <i class="vi vi-check"></i>
                                        Recaptcha verified
                                    </div>
                                </div>

                                <!-- SUBMIT BUTTON -->
                                <div class="d-flex justify-content-end mt40">
                                    <button 
                                        class="btn btn-primary button-submit loading-button-primary" 
                                        (click)="submitContact('custom')" 
                                        [class.loading-button-primary]="onLoadingCustom" 
                                        [disabled]="onLoadingCustom||formRequest?.invalid || formRequest?.invalid && !formRequest.get('captchaVerified').value">
                                        {{onLoadingCustom ? loadingProgress+'%' : 'Submit'}}
                                    </button>
                                </div>

                            </form>

                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</app-layouts>
