import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments';
import { MainService } from 'src/app/shared/services';
import { PricingProduct, UserBillingDetail } from 'src/app/shared/interfaces';
import moment from 'moment';
import { Observable } from 'rxjs';

declare const Stripe;

@Injectable({
  providedIn: 'root'
})
export class PricingService {
  public pricingProducts: PricingProduct[] = [];
  public userBillingDetail: UserBillingDetail  = null;
  public isYearly: boolean = false;
  public activeProduct: string = '';

  constructor(
    private http: HttpClient,
    private mainService: MainService,
  ) {}

  /**
   * * GET PRICING PRODUCTS *
   * Todo: to getting pricing products
   */
  public getProducts(): Observable<any> {
    const baseUrl = this.mainService.isBrowser ? environment.baseURL : environment.baseURL_GET;
    return this.http.get(`${baseUrl}/billing-product`);
  }

  
  /**
   * * GET DETAIL PRODUCT *
   * Todo: to get detail product
   */
  getProductDetail(type: string) {    
    const baseUrl = this.mainService.isBrowser ? environment.baseURL : environment.baseURL_GET;
    return this.http.get(`${baseUrl}/billing/product-detail?type=${type}`)
  }

  /**
   * * GET USER BILLING DETAIL * 
   * Todo: to get user billing detail
   */
  getUserBillingDetail(refetch: boolean = false) {
    if (refetch) this.userBillingDetail = null;
    return new Promise((resolve, reject) => {
      if (!this.userBillingDetail) {

        // Send http request
        const baseUrl = this.mainService.isBrowser ? environment.baseURL : environment.baseURL_GET;
        this.http.get(`${baseUrl}/billing/detail-pricing`).subscribe((res) => {
          this.userBillingDetail = res['data'].product_detail;
          if(this.userBillingDetail.period_start) {
            this.userBillingDetail.period_end_display = moment(
              this.userBillingDetail.period_start, 'YYYY-MM-DD HH:mm:ss'
            ).format('MMM D, YYYY')

            this.userBillingDetail.period_end_display = moment(
              this.userBillingDetail.period_end, 'YYYY-MM-DD HH:mm:ss'
            ).format('MMM D, YYYY')
          }
          resolve(this.userBillingDetail);
        }, err => {
          reject(err);
        });
      } else {
        resolve(this.userBillingDetail);
      }
    })
  }

  /**
   * * UNSUBSCRIBE PRODUCT *
   * Todo: to unsubribe product
   */
  unsubscribe() {
		return this.http.post(`${environment.baseURL}/billing/unsubs`, {});
  }

  /**
   * * CREATE PRICE DATA FOR DISPLAY *
   * Todo: to create price data for display
   */
  displayPrice(price: string) {
    const newPriceStr = price.replace('$','').replace(',','.');
    const priceNum = Number(newPriceStr);
    return `$${Math.round(priceNum)}`;
  }

  /**
   * * GET SUBSCRIPTION OVERVIEW *
   * Todo: to get subscription overview
   * @returns : Observable<any> -> http observable
   */
  public getSubscriptionOverview(): Observable<any> {
    return this.http.get(`${environment.baseURL}/billing/detail-pricing`);
  }

  /**
   * * SUBSCRIBE PRODUCT *
   * Todo: to create checkout product
   */
  public subscribeProduct(product: any) {
    const headers = {
      'Content-Type': 'application/json'
    };
    const body = JSON.stringify(product);
    return this.http.post(`${environment.baseURL}/billing/create-checkout`, body, {headers});
  }
}
