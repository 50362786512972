@import '../../../assets/scss/common/variables';

.beta-message{
    background: $color-primary;
    color: $color-dark1;
    text-align: center;
    padding: 7px 0px 9px;
    font-size: 20px;
    line-height: 120%;
    width: 100%;
    display: block;
    position: fixed;
    font-family: $font-whyte;
    z-index: 9999;

    @media (max-width: $small-screen) {
        font-size: 14px;
    }
    
    @media (max-width: 491px) {
        padding: 6px 48px 7px;
    }

    .close-massage{
        position: absolute;
        right: 11px;
        top: 7px;
        display: inline-block;

        &:hover{
            text-decoration: none;
        }

        @media (max-width: 491px) {
            right: 12px;
            top: 15px;
        }

        .vi{
            font-size: 16px;  
        }
    }
}

.benner-first-time{
    padding: 40px 0;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9999;
    background-color: $color-gray1;
    width: 100%;
    
    .desc{
        color: $color-dark1;
        max-width: 85%;

        a{
            text-decoration: underline;
        }
    }
}

.header-holder {
    height: var(--mainHeightHeader);
    
    @media (max-width: $small-screen) {
        height: 57px;
    }
}

.wrap-navigation{
    padding: 0 30px;
    height: 84px;
    background: linear-gradient(180deg, #292929 0%, rgba(41, 41, 41, 0.93) 100%);
    border-bottom: solid 1px $color-gray3;
    font-size: 20px;
    line-height: 101.8%;
    z-index: 1100;
    position: relative;
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    width: calc(100vw - 14px);

    @media(max-width: $large-screen){
        padding: 0 25px;
        width: 100vw;
    }

    @media(max-width: $small-screen){
        padding: 0 15px;
    }

    @media(max-width: $small-screen){
        height: 57px;
    }

    &.fixed {
        position: fixed;
        left: 0;
        top: 0;
    }

    &.widget {
        width: 100vw;
    }
 
    .brand{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        z-index: 999;

        @media(max-width: 1024px){
            left: 80px;
            transform: translateX(0);
        }

        @media(max-width: $small-screen){
            left: 44px
        }

        a{
            display: inline-flex;
            position: relative;
            top: 28.58px;

            &:hover{
                text-decoration: none;
            }

            @media(max-width: $small-screen){
                top: 17px;
            }

            img{
                width: 126px;
    
                @media(max-width: $small-screen){
                    width: 82.43px;
                }
            }

            .logo-beta-vesion-responsive{
                font-size: 10px;
                line-height: 120%;
                color: $color-gray1;
                margin-left: 5px;
                display: none;

                @media(max-width: 1024px){
                    display: block;
                }
            }
        }
    }

    .main-box{
        display: inline-flex;
        position: relative;
        top: 30px;
        align-items: flex-start;

        &:hover{
            text-decoration: none;
        }

        img{
            width: 28px;
            object-fit: contain;
        }

        .logo-beta-vesion{
            font-size: 10px;
            line-height: 120%;
            color: $color-gray1;
            margin-left: 5px;
            display: block;

            @media(max-width: 1024px){
                display: none;
            }
        }

        @media(max-width: $small-screen){
            top: 21px;

            img{
                width: 18px;
            }
        }
    }

    ul.main-menu{
        display: inline-block;
        margin-top: 35px;
        padding-left: 0;

        @media(max-width: 1024px){
            display: none;
        }

        li{
            display: block;
            float: left;

            &.support-menu{
                padding-bottom: 15px;

                &:hover ul {
                    display:inherit;
                }
            }

            .login-on-responsive{
                display: none;

                @media(max-width: 1024px){
                    display: block;
                }
            }

            a{
                display: block;
                font-size: 18px;
                line-height: 101.8%;
                letter-spacing: -0.01em;
                color: $color-gray3;
                font-family: $font-suisse;
                padding: 0 16.5px;

                @media (max-width: $large-screen){
                    padding: 0 12.5px;
                }

                &:hover{
                    text-decoration: none;
                    color: $color-primary;
                }

                &.active{
                    color: $color-primary;
                }
            }

            &:first-child{
                a{
                    padding-left: 0;
                }
            }

            .support {
                &-menu {
                    display: none;
                    position: absolute; 
                    top: 60px;
                    margin-left: 16px;
                    padding: 0;
                    box-shadow: $color-black 0px 1px 5px 0px;
                }

                &-item {
                    list-style: none;
                    width: 215px;
                    background-color: $color-gray4;
                    padding: 0;
                    font-size: 18px;
                    line-height: 22px;
                    color: $color-gray3;
                    display: list-item;
                    position: relative;
                    clear: both;

                    &.active,
                    &:focus,
                    &:hover{
                        background-color: $color-dark6 !important;
                    }

                    a {
                        padding: 0;
                        padding: 14px 20px 12px;

                        &:hover {
                            color: $color-gray3;
                        }
                    }
                }
            }

            .menu-logout{
                display: none;

                @media(max-width: 1024px){
                    display: inline-block;
                    position: absolute;
                    bottom: 30px;
                    margin-left: -31px;
                }
            }
        }
    }

    .wrap-dropdown{
        text-align: right;
        position: relative;
        top: 28px;

        @media(max-width: $small-screen){
            top: 16px;
        }
    }

    .wrap-attr{
        display: flex;
        position: relative;
        top: 28px;

        @media(max-width:1024px){
            text-align: right;
            
            .wrap-search{
                display: none;
            }

            .header-language{
                display: none;
            }
        }

        @media(max-width:$small-screen){
            top: 17px
        }
    }

    input.search{
        display: block;
        width: calc(100% - 30px);
        max-width: 215px;
        transition: all 0.2s ease-in-out;
        background-color: $color-dark3;
        border: 1px solid $color-gray3;
        height: 34px;
        border-radius: 70px;
        outline: 0;
        padding: 9px 20px 5px;
        color: $color-gray3;

        &:focus{
            max-width: 333px;
        }
    }

    .header-language{
        display: block;
        padding-left: 43px;

        p-dropdown{
            width: 75px;
        }
    }

    .single-link{
        display: block;
        position: relative;
        top: 9px;

        @media(max-width:1024px){
            font-size: 18px;
            width: 100%;
            top: 0;
        }

        @media(max-width: $small-screen){
            top: -2px;
        }

        @media(max-width: 1024px){
            .login-menu{
                display: none;
            }
        }

        a{
            display: inline-block;
            color: $color-primary;
    
            &:hover{
                text-decoration: none;
            }

            &.toggle-bar{
                margin-left: 85px;
                margin-right: 5px;
                display: none;

                @media(max-width:1024px){
                    display: inline-block;
                    margin-left: 0;
                    margin-right: 0;
                }

                img{
                    width: 24px;
                }
            }
        }
    }

    
    .dropdown{
        display: inline-block;
        
        .dropdown-toggle{
            color: $color-gray3;
            margin-right: 0;
            padding: 7px 0;
            position: relative;
            top: 10px;

            @media(max-width: $large-screen){
                top: 6px;
            }

            @media(max-width:1024px){
                display: none;
            }

            @media(max-width:$small-screen){
                top: -8px;
            }

            &:hover{
                text-decoration: none;

                ~ .user-menu{
                    display: block;
                }
            }

            &::after{
                display: none;
            }
        }

        .toggle-bar{
            margin-left: 85px;
            margin-right: 5px;
            display: none;

            @media(max-width:1024px){
                display: inline-block;
                margin-left: 0;
                margin-right: 0;
            }

            img{
                width: 24px;
            }
        }

        
        .user-menu {
            display: none;
            padding: 0;
            margin: 0;
            position: absolute;
            right: 0;
            top: 34px;
            box-shadow: $color-black 0px 1px 5px 0px;

            &:hover {
                display: block;
            }

            .user-item {
                list-style: none;
                text-align: start;
                
                &--link {
                    width: 215px;
                    background-color: $color-gray4;
                    padding: 14px 20px 12px;
                    font-size: 20px;
                    line-height: 22px;
                    color: $color-gray3;
                    display: block;

                    &:hover {
                        background-color: $color-dark6;
                        color: $color-white;
                        text-decoration: none;
                    }
                }
            }
        }
    }

    // Responsive
    // ============================ //
    .overlay-responsive{
        position: fixed;
        z-index: 1100;
        width: 100vw;
        height: calc(var(--vh) - var(--mainHeightHeader));
        top: 84px;
        left: 0;
        background-color: $color-dark3;

        @supports (-webkit-touch-callout: none) {
            /* CSS specific to iOS devices */ 
            top: var(--mainHeightHeader);
        }

        @media(max-width:$small-screen){
            top: 57px;

            @supports (-webkit-touch-callout: none) {
                /* CSS specific to iOS devices */ 
                top: var(--mainHeightHeader);
            }
        }

        .main-content{
            height: calc(var(--vh) - var(--mainHeightHeader) - 84px);
            background-color: $color-dark1;
            padding: 50px 25px;
            position: relative;

            @media (max-width:$small-screen) {
                padding: 27px 16px;
                height: calc(var(--vh) - var(--mainHeightHeader) - 57px);
            }

            @media only screen 
            and (max-width: 920px) 
            and (orientation: landscape){
                overflow-y: scroll;
                padding: 27px 16px;
            }

            .overlay-language{
                width: 275px;
                padding-left: 92px;

                @media(max-width:$small-screen){
                    display: none;
                }

                &-small{
                    display: none;
                    width: 92px;
                    margin: 13.5px auto;
            
                    @media(max-width:$small-screen){
                        display: block;
                    }
                }
            }

            input.search{
                width: 100%;
                max-width: 100%;
                height: 54px;
                padding-left: 25px;
                padding-right: 25px;
                background-color: $color-dark1;

                @media(max-width:$small-screen){
                    height: 30px;
                    padding-left: 10px;
                    padding-right: 10px;
                    font-size: 16px;
                }
            }

            .overlay-menu ul{
                display: block;
                text-align: center;
                font-size: 18px;
                list-style: none;
                padding: 0;
                margin: 50px 0 0 0;

                @media(max-width:$small-screen){
                    margin-top: 13.5px;
                }

                @media only screen 
                and (max-width: 920px) 
                and (orientation: landscape){
                    margin-top: 0;
                }

                li{
                    display: block;
                    padding: 20px 0;

                    @media(max-width:$small-screen){
                        padding: 13.5px 0;
                    }

                    .login-on-responsive{
                        color: $color-primary;
                        display: none;
        
                        @media(max-width: 1024px){
                            display: inline-block;
                        }
                    }

                    a{
                        display: inline-block;
                        color: $color-gray3;

                        @media(max-width:$small-screen){
                            font-size: 16px;
                        }

                        &:hover{
                            text-decoration: none;
                            color: $color-primary;
                        }
                    }

                    .menu-logout{
                        @media(max-width: 1024px){
                            position: absolute;
                            bottom: 30px;
                            margin-left: -31px;
                        }

                        @media only screen 
                        and (max-width: 920px) 
                        and (orientation: landscape){
                            position: relative;
                            bottom: 0;
                            margin-left: 0;
                        }
                    }
                }
            }

            .support-menu {
                @media (max-width: $large-screen) {
                    padding: 0 !important;
                }

                &.sub {
                    @media (max-width: $large-screen) {
                        margin: 0;
                    }
                }
                
                .parent-menu{
                    @media (max-width: $large-screen) {
                        display: none;
                    }
                }
            }
        }
        
        .footer-content{
            display: block;
            height: 84px;
            background-color: $color-dark3;
            text-align: center;

            @media(max-width:$small-screen){
                height: 57px;
            }

            ul{
                display: inline-block;
                padding: 0;
                margin: 0;

                li{
                    display: block;
                    float: left;
                    padding: 27px 36px 0;

                    @media(max-width:$small-screen){
                        padding: 14px 23.5px 0;
                    }

                    .link-social{
                        margin-bottom: 34px;
                        border-radius: 50%;
                        background-color: $color-gray1;
                        width: 24px;
                        height: 24px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-left: auto;
                        padding: 5px;

                        .vi{
                            font-size: 16px;
                            color: $color-dark1;
                        }
                    }
                }
            }
        }
    }

    &.fixed{
        .overlay-responsive{
            @supports (-webkit-touch-callout: none) {
                /* CSS specific to iOS devices */ 
                top: var(--mainHeightHeader);
            }
    
            @media(max-width:$small-screen){
                top: 57px;
    
                @supports (-webkit-touch-callout: none) {
                    /* CSS specific to iOS devices */ 
                    top: calc(var(--mainHeightHeader) + 1px);
                }
            }
        }
    }
}