@import '../../../../../assets/scss/common/variables';

.wrap-image{
    .wrap-btn-option{
        display: flex;
        flex-wrap: nowrap;
        box-sizing: border-box;

        .item-btn{
            width: 50%;
            border: solid 1px $color-dark1;
            position: relative;
            height: 108px;

            .btn-option-figure{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                font-size: 16px;
                line-height: 120%;
                color: $color-gray3;
                padding: 0 15px;

                &:hover{
                    text-decoration: none;
                }
            }
        }
    }

    .massage{
        font-size: 12px;
        line-height: 112.8%;
        letter-spacing: -0.01em;
        padding: 12px 0 11px;
        display: block;
        text-align: right;
        width: 100%;

        span{
            font-size: 20px;
        }
    }

    .btn-back-to{
        i{
            font-size: 14px;
            font-weight: 600;
            margin-top: 0px;
        }
    }

    .wrap-set-animation{
        padding: 20px 0 6px 10px;
        display: flex;
        align-items: flex-start;

        h5{
            color: $color-gray1;
            font-size: 16px;
        }

        .desc{
            font-size: 10px;
        }
    }

    .button-name-figure{
        margin-bottom: 16px;

        .contant-name-figure{
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: solid 1px $color-dark1;
            padding: 15px 10px;
            margin-top: 1px;

            .wrap-title-figure{
                display: flex;
                align-items: center;
                width: 100%;

                .wrap-image-figure{
                    position: relative;
                    
                    .img-figure{
                        width: 44px;
                        height: 44px;
                        object-fit: cover;
                    }

                    .badge {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 14px;
                        height: 14px;
                        object-fit: cover;
                        display: block;
                        padding: 0;
                        border-radius: 0;
                    }

                    .edit-image{
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        opacity: 0;
                        background: rgba(55, 55, 55, 0.78);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 16px;
                        line-height: 94.3%;
                        color: $color-gray3;
        
                        &:hover{
                            opacity: 1;
                            text-decoration: none;
                        }

                        &.procces{
                            opacity: 1;
                            text-decoration: none;
                        }
                    }
                }

                .name-figure{
                    color: $color-gray3;
                    font-size: 17px;
                    line-height: 130.3%;
                    margin-bottom: 0;
                    margin-left: 10px;
                    background-color: transparent;
                    border: 0 none;
                    width: 100%;
                    font-family: $font-whyte;

                    &:focus{
                        border: 0 none;
                        outline: none;
                        box-shadow: none;
                    }
                }
            }

            .btn-close{
                background-color: transparent;
                margin: 0 11px;
                border: 0 none;
                padding: 0;

                i{
                    font-size: 24px;
                    color: $color-gray1;
                }
            }
        }
    }

    .wrap-btn-fiture{
        display: flex;
        align-items: center;
        justify-content: space-between;

        .wrap-item-btn{
            .btn-fiture{
                background-color: transparent;
                border: 0 none;
                display: contents;
                margin-right: 22px;
                display: inline-flex;
                
                > i{
                    font-size: 18px;
                    color: $color-gray1;
                }

                > img{
                    width: 18px;
                    height: 18px;
                    object-fit: contain;
                }
            }
        }

        .btn-close{
            background-color: transparent;
            border: 0 none;
            display: contents;
            margin-right: 22px;
            display: inline-flex;
            
            > i{
                font-size: 18px;
                color: $color-gray1;
            }

            > img{
                width: 18px;
                height: 18px;
                object-fit: contain;
            }
        }
    }

    .wrap-info-artwork{
        margin-bottom: 18px;
        
        .btn-outline-primary{
            color: $color-gray3;

            &.btn-apply{
                background-color: #353535;
                border-color: #434343;
            }
        }

        .content-info-artwork{
            margin: 15px 0;
            position: relative;

            label{
                margin-right: 0;
            }

            .label-content-info{
                width: 105px;

                label{
                    margin-right: 0;
                    margin-top: -3px;
                    position: relative;
                }

                input[type=checkbox] + label:before {
                    height: 14px;
                    width: 14px;
                    margin-right: 5px;
                    content: "";
                    display: inline-block;
                    vertical-align: baseline;
                    background: transparent;
                    border: solid 1px #777;
                    border-radius: 50%;
                    position: relative;
                    top: 1px;
                }
    
                input[type=checkbox]:checked + label:before{
                    border: solid 1px $color-primary;
                    padding: 2px;
                }
    
                input[type=checkbox]:checked + label::after{
                    top: 4px;
                    content: "";
                    background: #FFDA16;
                    width: 8px;
                    height: 8px;
                    position: absolute;
                    left: 3px;
                    border-radius: 50%;
                }
            }

            input[type=text]{
                padding: 6px 5px 2px;
            }

            .all-artwork{
                width: 172px;
                margin: auto;
                
                label{
                    color: $color-gray1;
                }

                input[type=checkbox] + label:before{
                    margin-right: 9px;
                }
            }
        }
    }

    .wrap-advanced-settings{
        
        h5{
            margin-bottom: 0;
        }
    }

    .form-group{
        .year-input{
            background-color: $color-dark1;
            width: 60px;
            height: 30px;
            padding: 4px 4px 0;
            text-align: center;
            color: $color-gray3;
            border-radius: 0;
            font-size: 16px;
            outline: none;
            border: 0;
            padding: 3px 0px 0px 0px;
            font-size: 14px;
            line-height: 94.3%;
        }
    }
}

.wrap-save-frame{
    .title{
        color: $color-dark1;
        margin-bottom: 40px;
        font-size: 40px;
        line-height: 120%;
        font-family: 'whyte_inktrapregular';
    }

    .form-group{
        margin: 0 20px;

        label{
            color: $color-dark1; 
            font-size: 16px;
            line-height: 125%;
            margin-bottom: 10px;
        }

        .form-control{
            color: $color-dark1; 
            width: 509px;
        }

        .note{
            color: $color-gray3;
            margin-top: 15px;
            font-size: 16px;
        }
    }

    .wrap-btn-save-frame{
        margin-top: 40px;
        margin-right: 20px !important;
        margin-right: 0;
        display: inline-flex;
        float: right;
    }
}

.header-frame-edit {
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;

    button {
        background: transparent;
        border: none;
        padding: 0;

        i {
            color: #c4c4c4;
        }
    }
}