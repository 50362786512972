// Angular packages
import { HttpEventType, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Environment
import { environment } from '@environments';

// User-defined services
import { MainService, store } from 'src/app/shared/services';
import { EditorService } from '../editor.service';
import { LoadingGalleryService } from 'src/app/components/loading-gallery/loading-gallery.service';

// Third-party packages
import { map, timeout } from 'rxjs/operators';
import { Observable } from 'rxjs';

// User-defined interfaces
import { ArtworkDimension, EditArtworkParams, UploadArtworkPayload } from './images.interfaces';
import { Artwork } from 'src/app/shared/interfaces';

@Injectable({
  providedIn: 'root'
})
export class ImagesService {
	private _httpOptions;
	public artworkNameValid: boolean = true;
	public mediaSupportNameValid: boolean = true;
	public requestArtworkValid: boolean = true;
	public artworkInfoValid: boolean = true;

  constructor(
    private _mainService: MainService,
    private _editorService: EditorService,
		private _http: HttpClient,
		private _loadingGalleryService: LoadingGalleryService,
  ) { 
		this._setHttpOptions();
	}


	/**
	 * * VALIDATE ALL ARTWORK DATA *
	 * Todo: to validate all artwork data
	 */
	public validateAllArtworkData(): void {
		this._editorService.artworkDataValid = (
			this.artworkNameValid &&
			this.mediaSupportNameValid &&
			this.requestArtworkValid &&
			this.artworkInfoValid
		)
	}

	/**
	 * * SET HTTP OPTIONS *
	 * Todo: to set http options
	 */
	private _setHttpOptions(): void {
		this._httpOptions = {
			reportProgress: true,
			observe: 'events',
		}
	}

  /**
	 * * VALIDATE ARTWORK FILE *
	 * Todo: to validate artwork file
   * @param file : File -> file
   * @returns : boolean -> true if valid, false if not
	 */
	public validateFile(file: File, specificType?: 'image' | 'object'): boolean {
		const extention = this._mainService.getFileExtension(file).toLowerCase()
		const allowedFormatFile = this._getAllowedFormatFile(specificType);
		if (allowedFormatFile.includes(extention)) return true;
		else return false;
	}

	/**
	 * ANCHOR Get Allowed Format File
	 * @description to get allowed format file
	 * @param specificType : 'image' | 'object' -> specific type -> nullable
	 * @returns : string[] -> allowed format file
	 */
	private _getAllowedFormatFile(specificType?: 'image' | 'object'): string[] {
		let allowedFormatFile;
		if(specificType === 'image') allowedFormatFile = ['png', 'jpeg', 'jpg'];
		else if(specificType === 'object') allowedFormatFile = ['glb'];
		else allowedFormatFile = ['png', 'jpeg', 'jpg', 'glb'];

		return allowedFormatFile;
	}

  /**
	 * * GET ARTWORK DIMENSION *
	 * Todo: to getting artwork dimension
   * @param file : File -> file
   * @returns : Promise<ArtworkDimension> -> artwork dimension
	 */
	public async getArtworkDimension(file: File): Promise<ArtworkDimension> {
		const image = URL.createObjectURL(file);
		const imageResolution = await this._editorService.getImageResolutionFromUrl(image);
		const resizedResolution = this.resizeArtworkDimension(imageResolution);
		const resolution = {
			realWidth: imageResolution.width,
			realHeight: imageResolution.height,
			width: resizedResolution.width,
			height: resizedResolution.height
		}
		return resolution;
	}

	/**
	 * * RESIZE ARTWORK DIMENSION *
	 * ANCHOR: to resize artwork dimension
	 * @param artworkDimension : ArtworkDimension -> artwork dimension
	 * @returns : ArtworkDimension -> resized artwork dimension
	 */
	public resizeArtworkDimension(artworkDimension: ArtworkDimension): ArtworkDimension {
		const { width, height } = artworkDimension;
		const ratioXY = height / width;
		const newWidth = 5000;
		const newHeight = newWidth * ratioXY;
		return {
			width: Math.round(this._editorService.covertPxToCm(newWidth)),
			height: Math.round(this._editorService.covertPxToCm(newHeight)),
			realHeight: this._editorService.convertPxToM(newHeight),
			realWidth: this._editorService.convertPxToM(newWidth)
		}
	}

	


	/**
	 * * EDIT ARTWORK *
	 * Todo: to upload new image for artwork to server
	 * @param params : EditArtworkParams -> params
	 * @returns : Observable<any> -> response
	 */
	public editArtwork(params: EditArtworkParams): Observable<any> {
		const { body, exhibitionId, artworkId } = params;
		const apiRoute = `${environment.baseURL}/image/replace-file/${exhibitionId}/${artworkId}?type=image`;

		return this._http.post(apiRoute, body, this._httpOptions).pipe(map((event: any ) => {
			switch (event.type) {
				case HttpEventType.UploadProgress:
					const progress = Math.round(100 * event.loaded / event.total);
					return {status: 'progress', data: progress};

				case HttpEventType.Response:
					return {status: 'response', data: event.body};

				default:
					return `Unhandled event: ${event.type}`;
			}
		}));
	}

	/**
	 * * SELECT ARTWORK *
	 * Todo : for selecting artwork
	 * @param artwork : Artwork -> artwork data
	 */
	public selectArtwork(artwork: Artwork) : void {
		if(!this._loadingGalleryService.show){
			const artworkNode = this._editorService.scene.getTransformNodeByID(`artwork-${artwork.id}`);
	
			if(!this._editorService.previewMode){
				this._editorService.updateLogActivity("Select object");
				this._editorService.selectExhibitAsset(artworkNode, null, true);
				
				store.dispatch({type: "UPDATE_STAT_SELECT_OBJECT", objectHasSelected: true});
			}
			else{
				if(!this._editorService.focusAnimationIsRun){
					this._editorService.scene.onPointerObservable.clear();
					this._editorService.focusOnArtworkAnimation(artworkNode).then(()=>{
						this._editorService.initMainPointerObs();
						this._editorService.setHorizontalCameraMovementObs(this._editorService.exhibition.horizontal_view_movement)
					})
				}
			}
		}
	}


	/**
	 * * UPLOAD  ARTWORK IMAGE *
	 * ANCHOR : to upload artwork image
	 * @description : to upload artwork image
	 * @param payload : UploadArtworkPayload -> payload
	 * @returns : Observable<any> -> response
	 */
	public uploadArtworkImage(payload: UploadArtworkPayload): Observable<any> {
		const formData = this._convertObjectToFormData(payload);
		const apiEndpoint = `${environment.baseURL}/image/upload-file/${payload.exhibition_id}`;
		return this._http.post(apiEndpoint, formData, this._httpOptions).pipe(map((event: any) => {
			switch (event.type) {
				case HttpEventType.UploadProgress:
					const progress = Math.round(100 * event.loaded / event.total);
					return {status: 'progress', data: progress};

				case HttpEventType.Response:
					return {status: 'response', data: event.body};

				default:
					return `Unhandled event: ${event.type}`;
			}
		}));
	} 

	/**
	 * * CONVERT OBJECT TO FORM DATA *
	 * ANCHOR : to convert object to form data
	 * @description : to convert object to form data
	 * @param object : any -> object
	 * @returns : FormData -> form data
	 */
	private _convertObjectToFormData(object: any): FormData {
		const formData = new FormData();
		for (const key in object) {
			if (object.hasOwnProperty(key)) {
				const element = object[key];
				if (typeof element === "object" && !(element instanceof File)) {
					formData.append(key, JSON.stringify(element));
				}
				else {
					formData.append(key, element);
				}
			}
		}
		return formData;
	}

	/**
	 * * GET VIDEO DATA *
	 * ANCHOR : to get video data
	 * @description : to get video data
	 * @param url : string -> url
	 * @param type : type -> 'youtube' | 'vimeo'
	 * @returns : Observable<any> -> response
	 */
	public getVideoData(url: string, type: 'youtube' | 'vimeo') : Observable<any> {
		return this._http.get(`${environment.video_path}/${type}?url=${url}&audio=false`);
	}

	/**
	 * * UPLOAD ARTWORK VIDEO *
	 * ANCHOR : to upload artwork video
	 * @param payload : UploadArtworkPayload -> payload
	 * @returns : Observable<any> -> response
	 */
	public uploadArtworkVideo(payload: UploadArtworkPayload): Observable<any> {
		const formData = this._convertObjectToFormData(payload);
		const apiEndpoint = `${environment.baseURL}/video/upload`;
		return this._http.post(apiEndpoint, formData, this._httpOptions).pipe(map((event: any) => {
			switch (event.type) {
				case HttpEventType.UploadProgress:
					const progress = Math.round(100 * event.loaded / event.total);
					return {status: 'progress', data: progress};

				case HttpEventType.Response:
					return {status: 'response', data: event.body};

				default:
					return `Unhandled event: ${event.type}`;
			}
		}));
	}

	/**
	 * * UPLOAD ARTWORK OBJECT *
	 * ANCHOR : to upload artwork object
	 * @param payload : UploadArtworkPayload -> payload
	 * @returns : Observable<any> -> response
	 */
	public uploadArtworkObject(payload: UploadArtworkPayload): Observable<any> {
		const formData = this._convertObjectToFormData(payload);
		const apiEndpoint = `${environment.baseURL}/image/upload-object-artwork`;
		return this._http.post(apiEndpoint, formData, this._httpOptions).pipe(map((event: any) => {
			switch (event.type) {
				case HttpEventType.UploadProgress:
					const progress = Math.round(100 * event.loaded / event.total);
					return {status: 'progress', data: progress};

				case HttpEventType.Response:
					return {status: 'response', data: event.body};

				default:
					return `Unhandled event: ${event.type}`;
			}
		}));
	}

	/**
   * * GET VIDEO TYPE *
   * ANCHOR Get Video Type
   * @description to get video type
   * @param url : string -> video url
   * @returns : 'youtube' | 'vimeo'
   */
  public getVideoType(url: string): 'youtube' | 'vimeo' {
    if(url.includes('youtube')) return 'youtube';
    if(url.includes('vimeo')) return 'vimeo';
  }

	/**
   * * GET ARTWORK MODEL PATH *
   * ANCHOR Get Artwork Model Path
   * @param artwork : Artwork
   * @returns : string
   */
  public getModelPath(artwork: Artwork): string {
    const path = artwork.model_path;
    if(path !== null && path !== undefined && path !== ''){
			try {
				const url = new URL(path);
				return environment.assets_path + url.pathname.replace('/asset/', '');
			} catch (e) {
				return environment.assets_path + path;
			}
    }
		else return path
  }

  /**
   * * GET ARTWORK IMAGE *
   * ANCHOR Get Artwork Image
   * @param artwork : Artwork
   * @returns : string
   */
  public getArtworkImage(artwork: Artwork): string {
    let image = artwork.image;
    if(['figure-image','figure-object'].includes(artwork.file_type)){
      if(!image.includes(environment.image_path)){
        image = this._mainService.convertPathImage(image);
      }
    }
    return image;
  }


	 /**
	 * * ================================================================================================ *
	 *   SECTION Adjust Artwork Object Lighting Functions
	 * * ================================================================================================ *
	 */

  //#region 

  
  /**
   * ANCHOR Adjust Artwork Object Lighting
   * @description : to adjust artwork object lighting
   * @param artwork : Artwork
   */
  public adjustLighting(artwork: Artwork): void {
    const artworkNode = this._editorService.artworkNodes.find((artworkNode: any) => {
      return artworkNode.id === `artwork-${artwork.id}`;
    });

    if (artworkNode) {
      artworkNode.getChildren().forEach((mesh: any) => {
          mesh.material.environmentIntensity = artwork.light_intensity / 2;
      })
    }
  }

  /**
   * ANCHOR Adjust Lighting For All Artwork Objects
   * @description : to adjust lighting for all artwork objects
   * @param intensity : number
   */
  public adjustLightingForAll(intensity: number): void {
    this._editorService.artworks.forEach((artwork: Artwork) => {
      artwork.light_intensity = intensity;
      this.adjustLighting(artwork);
    })
  }

  //#endregion
  // !SECTION
}
