<div class="wrapper">
    <div class="billing">
        <p class="billing__label">Billing Information</p>
        <ul class="billing__detail">
            <ng-container *ngIf="!onFetching">
                <ng-container *ngIf="billingInfo">
                    <ng-container *ngIf="!billingInfo.is_address_empty">
                        <li *ngIf="billingInfo.address" class="billing__detail__item">
                            {{billingInfo.address}}
                        </li>
                        <li *ngIf="billingInfo.display_other_info" class="billing__detail__item">
                            {{billingInfo.display_other_info}}
                        </li>
                    </ng-container>
                    <ng-container *ngIf="billingInfo.is_address_empty">
                        <li class="billing__detail__item no-billing">No Billing Address Information</li>
                    </ng-container>
                </ng-container>
                <li class="billing__detail__item no-billing" *ngIf="!billingInfo">No Billing Information</li>
            </ng-container>
            <ng-container *ngIf="onFetching">
                ...
            </ng-container>
        </ul>
        <div class="billing__button">
            <button (click)="editBillingInfo=true" class="btn btn-light btn-sm">Edit Billing Info</button>
        </div>
    </div>
</div>

<!-- START EDIT BILLING -->
<p-dialog
    #editBilling
    [(visible)]="editBillingInfo" 
    [modal]="true" 
    [draggable]="false" 
    [baseZIndex]="5000"
    [closable]="!onSaving"
    (onHide)="billingForm.reset()"
    (onShow)="setFormValues()"
    (wheel)="editBilling['el'].nativeElement.click()"
    [resizable]="false"
    closeIcon="vi vi-x"
    styleClass="edit-billing-info">
    <div class="scroll-overlay top"></div>
    <div class="wrap-content">
        <div class="edit-billing">
            <h1 class="edit-billing__title">Edit Billing Information</h1>
            <div class="edit-billing__wrapper" [formGroup]="billingForm">
                <div class="edit-billing__row">
                    <div class="edit-billing__form w50" *ngIf="billingForm.controls.firstName as firstNameInput">
                        <label class="edit-billing__label">First Name</label>
                        <input 
                            (input)="onChangeHandler()"
                            formControlName="firstName"
                            type="text" 
                            [ngClass]="{'is-invalid': firstNameInput.invalid && firstNameInput.touched }"
                            placeholder="First Name" 
                            class="edit-billing__input form-control">

                        <ng-container *ngIf="firstNameInput.errors as errors">
                            <div class="invalid-feedback" *ngIf="errors?.required && firstNameInput.touched">
                                First name required!
                            </div>
                            <div class="invalid-feedback" *ngIf="!errors?.required && errors?.containsSpecialChars && firstNameInput.touched">
                                First name cannot contain special characters!
                            </div>
                        </ng-container>
                    </div>
                    <div class="edit-billing__form" *ngIf="billingForm.controls.lastName as lastNameInput">
                        <label class="edit-billing__label">Last Name</label>
                        <input 
                            (input)="onChangeHandler()"
                            type="text"
                            [ngClass]="{'is-invalid': lastNameInput.invalid && lastNameInput.touched }"
                            formControlName="lastName"
                            placeholder="Last Name" 
                            class="edit-billing__input form-control">
                        <ng-container *ngIf="lastNameInput.errors as errors">
                            <div class="invalid-feedback" *ngIf="errors?.required && lastNameInput.touched">
                                Last name required!
                            </div>
                            <div class="invalid-feedback" *ngIf="!errors?.required && errors?.containsSpecialChars && lastNameInput.touched">
                                Last name cannot contain special characters!
                            </div>
                        </ng-container>
                    </div>
                    <div class="edit-billing__form company" *ngIf="billingForm.controls.companyName as companyNameInput">
                        <label class="edit-billing__label">Company Name</label>
                        <input 
                            (input)="onChangeHandler()"
                            type="text"
                            [ngClass]="{'is-invalid': companyNameInput.invalid && companyNameInput.touched }"
                            formControlName="companyName"
                            placeholder="Company Name" 
                            class="edit-billing__input form-control">
                        <div class="invalid-feedback" *ngIf="companyNameInput.invalid && companyNameInput.touched">
                            Company name required! 
                        </div>
                    </div>
                </div>
                <div class="edit-billing__row">
                    <div class="edit-billing__form full" *ngIf="billingForm.controls.address as addressInput">
                        <label class="edit-billing__label">Address</label>
                        <input 
                            (input)="onChangeHandler()"
                            type="text" 
                            [ngClass]="{'is-invalid': addressInput.invalid && addressInput.touched }"
                            placeholder="Address"
                            formControlName="address"
                            class="edit-billing__input form-control">
                        <div class="invalid-feedback" *ngIf="addressInput.invalid && addressInput.touched">
                            Address required!
                        </div>
                    </div>
                </div>
                <div class="edit-billing__row">
                    <div  class="edit-billing__form w50" *ngIf="billingForm.controls.country as countryInput">
                        <label class="edit-billing__label">Country</label>
                        <p-dropdown 
                            #id1
                            appendTo="body"
                            [options]="counties" 
                            formControlName="country"
                            (onChange)="onChangeHandler(true)"
                            name="counties"
                            [filter]="true"
                            placeholder="Select a Country"
                            styleClass="billing-info"
                            panelStyleClass="panel-billing-info"
                            [overlayOptions]="{ baseZIndex: 5100 }"
                            optionLabel="name">
                        </p-dropdown>
                        <small class="text-danger" *ngIf="countryInput.invalid && countryInput.touched">
                            Country required!
                        </small>
                    </div>
                    <div class="edit-billing__form" *ngIf="billingForm.controls.state as stateInput">
                        <label class="edit-billing__label">State / Province</label>
                        <p-dropdown 
                            appendTo="body"
                            [options]="states" 
                            formControlName="state"
                            (onChange)="onChangeHandler()"
                            name="states"
                            [filter]="true"
                            placeholder="Select a State"
                            styleClass="billing-info"
                            panelStyleClass="panel-billing-info"
                            [overlayOptions]="{ baseZIndex: 5100 }"
                            optionLabel="name">
                        </p-dropdown>
                        <small class="text-danger" *ngIf="stateInput.invalid && stateInput.touched">
                            State required!
                        </small>
                    </div>
                    <div class="edit-billing__form w50" *ngIf="billingForm.controls.city as cityInput">
                        <label class="edit-billing__label">City</label>
                        <input 
                            (input)="onChangeHandler()"
                            formControlName="city"
                            type="text"
                            [ngClass]="{'is-invalid': cityInput.invalid && cityInput.touched }"
                            placeholder="City"
                            class="edit-billing__input form-control">
                        <div class="invalid-feedback" *ngIf="cityInput.invalid && cityInput.touched">
                            City required!
                        </div>
                    </div>
                    <div class="edit-billing__form zip" *ngIf="billingForm.controls.zip as zipInput">
                        <label class="edit-billing__label">ZIP / Postal Code</label>
                        <input 
                            (input)="onChangeHandler()"
                            formControlName="zip"
                            type="text" 
                            [ngClass]="{'is-invalid': zipInput.invalid && zipInput.touched }"
                            placeholder="ZIP / Postal Code"
                            class="edit-billing__input form-control">
                        <ng-container *ngIf="zipInput.errors as errors">     
                            <div class="invalid-feedback" *ngIf="errors?.required && zipInput.touched">
                                Zip code required!
                            </div>
                            <div class="invalid-feedback" *ngIf="!errors?.required && errors?.invalidZipCode && zipInput.touched">
                                Zip code is invalid!
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="edit-billing__row">
                    <div class="edit-billing__form full">
                        <label class="edit-billing__label">Tax Number</label>
                        <input 
                            (input)="onChangeHandler()"
                            type="text"
                            formControlName="taxNumber"
                            placeholder="Tax Number"
                            class="edit-billing__input form-control">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="scroll-overlay bottom"></div>
    <ng-template pTemplate="footer">
        <div class="edit-billing__button">
            <button
                [disabled]="onSaving"
                (click)="editBillingInfo = false" 
                class="btn btn-light">
                Cancel
            </button>
            <button
                [disabled]="onSaving || billingForm.invalid || !countryValid || !stateValid"
                (click)="saveBillingInfo()" 
                class="btn btn-primary">
                {{ onSaving ? 'Saving...' : 'Save' }}
            </button>
        </div>
    </ng-template>
</p-dialog>
<!-- END EDIT BILLING -->