// Angular build-it components
import { Component, OnInit, Output, EventEmitter, makeStateKey, TransferState} from '@angular/core';
import { EditorService } from '../pages/virtual-gallery/editor/editor.service';
import { BillingService } from '../pages/settings/billing/billing.service';
import { WhiteLabelService } from '../components/white-label/white-label.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileService } from '../pages/profile/profile.service';
import { PendingPaymentComponent } from '../components/pending-payment/pending-payment.component';
import { AlertMessageComponent } from '../components/alert-message/alert-message.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { LoginComponent } from './login/login.component';
import { FooterComponent } from './footer/footer.component';
import { WhiteLabelComponent } from '../components/white-label/white-label.component';
import { CreateExhibitionComponent } from '../components/create-exhibition/create-exhibition.component';
import { SidemenuComponent } from './sidemenu/sidemenu.component';
import { HeaderComponent } from './header/header.component';
import { NgIf } from '@angular/common';
import { SsrCookieService as CookieService } from 'ngx-cookie-service-ssr';

// User-defined services
import { MainService } from "src/app/shared/services";
import { LayoutsService } from './layouts.service';
import { AlertMessageService } from '../components/alert-message/alert-message.service';
import { CatalogueService } from '../pages/catalogue/catalogue.service';
import { environment } from "@environments";

// Third party plugins
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { PrimeTemplate } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
declare let google:any;
declare var $: any;

const infoUser = makeStateKey<object>('infoUser');


@Component({
    selector: 'app-layouts',
    templateUrl: './layouts.component.html',
    styleUrls: ['./layouts.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        HeaderComponent,
        SidemenuComponent,
        CreateExhibitionComponent,
        WhiteLabelComponent,
        FooterComponent,
        DialogModule,
        PrimeTemplate,
        LoginComponent,
        SignUpComponent,
        AlertMessageComponent,
        PendingPaymentComponent,
        ScrollPanelModule
    ],
})
export class LayoutsComponent implements OnInit {
    public loading: boolean = false;
    get isDialogVisible(): boolean {
        return this.mainService.isBrowser && this.mainService.expiredSesionPopup;
    }

    set isDialogVisible(value: boolean) {
        if (this.mainService.isBrowser) {
            this.mainService.expiredSesionPopup = value;
        }
    }

    @Output() onHideLoginDialog = new EventEmitter();

    constructor(
        public mainService:MainService,
        public layoutsService: LayoutsService,
        public alertMessageService:AlertMessageService,
        private editorService:EditorService,
        private catalogueService:CatalogueService,
        public billingService: BillingService,
        public whiteLabelService: WhiteLabelService,
        private _cookieService: CookieService,
        private _profileService: ProfileService,
        private _route: ActivatedRoute,
        private _transferState: TransferState,
        private _router: Router
    ) {
        this.layoutsService.sideMenu = this._route.snapshot.data.sideMenu;
    }
    
    ngOnInit(): void {
        this._route.paramMap.subscribe(params => {
            this._getPublicUserInfo()
            if (this.mainService.isBrowser)  document.body.scrollTop = 2;
        })
    }

    ngAfterViewInit(){
        if(this.mainService.isBrowser){
            this.setHeightHeader();
            this.setHeightFooter();

            window.addEventListener('resize', () => {
                this.setHeightFooter();
                this.setHeightHeader();
            })

            if(!this.mainService.userInfo && !this.whiteLabelService.whiteLabel){
                google.accounts.id.initialize({
                    client_id: environment.googleClientId,
                    callback: (response)=>{
                        this.submitLogin(response['credential']);
                    }
                });

                google.accounts.id.prompt();
            }

            this.mainService.loadScripts([environment.staticAssets+'plugins/jquery/jquery-3.4.1.min.js?t='+this.mainService.appVersion,]).then(() => {
                $('body').on("scroll",function(){
                    if(document.body.scrollTop < 40) $(".wrapper").css("overflow", "hidden"); 
                    else $(".wrapper").css("overflow", "visible");
                });
            });
        }
    }
    
    /**
     * ANCHOR Get Public User Info
     * @description to get public user info, this function will called if url give username data
     */
    private _getPublicUserInfo() {
        const username = this._route.snapshot.paramMap.get('username');
        if(username) {
            this.mainService.onFetchUser = true;
            this.mainService.loadingContent = true;
            const handleSuccess = (response) => {
                this.mainService.onFetchUser = false;
                this.mainService.loadingContent = false;
                if(response.data.userDatas) {
                    this._transferState.set(infoUser, {...response, valid: true});
                    this.mainService.userInfoPublic = response.data.userDatas;
                    this.mainService.userInfoPublicSubject.next(response.data.userDatas);
                } else {
                    this._router.navigate(['/404']);
                }
            }
            const handleError = (error) => {
                this.mainService.onFetchUser = false;
                this.mainService.loadingContent = false;
            }

            if(this.mainService.isBrowser) {
                const response = this._transferState.get(infoUser, null);
                if(response && response['valid'] && response['data']['userDatas']['username'] === username) {
                    handleSuccess(response);
                } else {
                    this.mainService.getPublicUserInfo(username).subscribe(handleSuccess, handleError);
                }
            }
            else {
                this.mainService.getPublicUserInfoSSR(username).subscribe({
                    next: (res) => {
                        if (!res['userDatas']) {
                            this._transferState.set(infoUser, {...res, valid: false});
                        } else {
                            this._transferState.set(infoUser, {...res, valid: true});
                        }
                    },
                    error: (err) => {
                        this._transferState.set(infoUser, {...err, valid: false});
                    }
                });
            }
        }
    }


    /**
     * * ======================================== *
     * * LIST OF FUNCTIONS
     * * ======================================== *
     * - OPEN LOGIN SESSION EXPIRED
     * - SUBMINT LOGIN GOOGLE
     * - HANDLE ON SHOW DIALOG EVENT
     * - SET HEIGHT HEADER STYLE PROPERY
     * - SET HEIGHT FOOTER STYLE PROPERY
     * - HANDLE ON HIDE DIALOG EVENT
     * - REFRESH PAGE
     * - REFRESH HEADER HEIGHT
     */

    /**
     * * OPEN LOGIN SESSION EXPIRED *
     * Todo: to open popup login session expired
     */
    openLoginSessionExpired(){
        google.accounts.id.initialize({
            client_id: environment.googleClientId,
            callback: (response)=>{
                this.submitLogin(response['credential']);
            }
        });
        
        this.editorService.onInput = true;
        this.mainService.expiredSesionPopup = false;
        this.layoutsService.formType = 'login';
        this.mainService.loginForExpiredToken = true;
        this.mainService.displayLoginDialog = true;
    }

    /**
     * * HANDLE VERIFED ACCOUNT *
     * Todo: to handle verifed account
     */
    handleVerifedAccount(responseData: any) {
        this.layoutsService.updateToken(responseData.token, responseData.exp).subscribe(async (resToken:any) =>{
            if(responseData.is_welcome){
                localStorage.setItem('loginFrom', this.layoutsService.loginFrom);
                localStorage.setItem('productName', this.layoutsService.productName);
                localStorage.setItem('paymentType', this.layoutsService.paymentType);
                window.location.href = "/user-update";
            }else{
                const isSameUser = await this.layoutsService.checkIsSameUser()
                this.editorService.onInput = false;
                if(isSameUser){
                    this.mainService.displayLoginDialog = false;
                    this.enableLoginLoading(false);
                }else{
                    if(this.layoutsService.loginFrom == 'pricing-page') {
                        this.handleLoginViaPricingPage();
                    } else if (this.layoutsService.loginFrom == 'catalogue-page') {
                        this.handleLoginViaCatalogue(responseData.username);
                    } else {
                        window.location.href = "/" + responseData.username;
                    }
                }
            }
        })
    }

    /**
     * * HANDLE LOGIN VIA PRICING PAGE *
     * Todo: to handle login via pricing page
     */
    handleLoginViaPricingPage() {
        if (this.layoutsService.productName !== 'experimental') {
            window.location.href = `/pricing/${this.layoutsService.productName.replace('_test','')}?pay=${this.layoutsService.paymentType}`;
        }else {
            window.location.href = "/settings/billing";
        }
    }

    /**
     * * HANDLE LOGIN VIA CATALOGUE PAGE *
     * Todo: to handle login via catalogue page
     */
    handleLoginViaCatalogue(username): void {
        const dataExhibition = this.layoutsService.dataExhibition;
        this._profileService.validateCreateAfterLogin().then(() => {
        if (this.mainService.isBrowser) {
            this._cookieService.set('createExhibition', dataExhibition.folder_name);
            window.location.href = "/"+username+"/create";
        }
        }).catch((err) => {
        if (err.error.statusCode === 403) {
            if (this.mainService.isBrowser) {
            this._cookieService.set('validateCreate', 'true')
            window.location.href = '/'+username;
            }
        }
        });
    }

    /**
     * * SUBMINT LOGIN GOOGLE *
     * Todo: to submit login google 
     */
    submitLogin(token:string){
        this.enableLoginLoading(true);
        this.mainService.auth({token:token}).subscribe( response => {
            if(response['data'].is_email_verified){
                this.handleVerifedAccount(response['data']);
            }else{
                this.enableLoginLoading(false);
                this.alertMessageService.add({severity: "warn", summary: "Warning", detail: "Email address is not verified, please check your email"})
            } 
            
        }, (error) => {
            if(error.name == "TimeoutError"){
                this.submitLogin(token);
            }else{
                this.enableLoginLoading(false);
                this.alertMessageService.add({severity:'error', summary:'Login failed', detail: `The user is not found`});
            }
        });
    }
    
    /**
     * * ENABLE LOGIN LOADING *
     * Todo: to enable loading
     */
    enableLoginLoading(enable: boolean) {
        this.loading = enable;
        this.layoutsService.loadingProcess = enable;
    }

    /**
     * * HANDLE ON SHOW DIALOG EVENT *
     * Todo: to handle on show diaog evetn
     */
    onShowDialog(){
        google.accounts.id.renderButton(document.getElementById("google-btn"),{
            type: "standard",
            size: "large",
        });
    }

    /**
     * * SET HEIGHT HEADER STYLE PROPERY *
     * Todo: to set height header to style propery
     */
    setHeightHeader(){
        const mainHeader: any = document.getElementsByClassName('wrap-navigation');
        if(mainHeader[0]){
            document.documentElement.style.setProperty('--mainHeightHeader', `${mainHeader[0].offsetHeight}px`);
        }
    }

    /**
     * * SET HEIGHT FOOTER STYLE PROPERY *
     * Todo: to set height footer to style propery
     */
    setHeightFooter(){
        const mainFooter: any = document.getElementsByClassName('wrap-footer');
        if(mainFooter[0]){
            document.documentElement.style.setProperty('--mainHeightFooter', `${mainFooter[0].offsetHeight-4}px`);
        }
    }

    /**
     * * HANDLE ON HIDE DIALOG EVENT *
     * Todo: to handle on hide diaog evetn
     */
    onHideDialog(){
        this.onHideLoginDialog.emit();
        this.layoutsService.loginFrom = null;
        this.layoutsService.productName = '';
    }

    /**
     * * REFRESH PAGE *
     * Todo: to refresh page when click "No" button on popup session expired
     */
    refreshPage(){
        if (this.mainService.isBrowser) {
            this.mainService.expiredSesionPopup = false;
            window.location.reload();
        }
    }
}