<!-- Invoices header -->
<div class="invoices">
    <p class="invoices__label">Invoices</p>
    <p class="invoices__detail">Billing Period:</p>
    <div class="d-flex justify-content-between align-items-center flex-wrap">
        <div class="invoices__date">
            <div class="invoices__date invoices__date__from">
                <span class="invoices__date__label"> from: </span>
                <app-input-date 
                    [year]="fromDateYear" 
                    [month]="fromDateMonth"
                    (selectDate)="onSelectDateHandler($event, 'from')" 
                    [hideDays]="true" 
                    [yearType]="'invoice-date'">
                </app-input-date>
            </div>
            <div class="invoices__date invoices__date__to">
                <span class="invoices__date__label"> to: </span>
                <app-input-date 
                    [year]="toDateYear" 
                    [month]="toDateMonth" 
                    [hideDays]="true"
                    (selectDate)="onSelectDateHandler($event, 'to')" 
                    [yearType]="'invoice-date'">
                </app-input-date>
            </div>
        </div>
        <div class="invoices__download-all">
            <button 
                *ngIf="selectedInvoices.length === 0"
                (click)="downloadAllOrSelectedInvoices('all')"
                class="btn btn-light btn-sm"
                [disabled]="onDownloadingInvoices || invoices.length === 0">
                <i class="vi vi-download-circle"></i>
                {{onDownloadingInvoices ? 'Downloading...' : 'Download All'}}
            </button>
            <button 
                *ngIf="selectedInvoices.length > 0"
                (click)="downloadAllOrSelectedInvoices('selected')"
                [disabled]="onDownloadingInvoices || invoices.length === 0"
                class="btn btn-primary btn-sm">
                <i class="vi vi-download-circle"></i>
                {{onDownloadingInvoices ? 'Downloading...' : 'Download Selected Invoices'}}
            </button>
        </div>
    </div>
    <!-- alert incorrect date -->
    <small class="text-danger" *ngIf="filterDateInvalid">
        Period date is incorrect
    </small>
</div>

<!-- Invoices table -->
<div class="invoices-table">
    <p-table
        [value]="invoices" 
        styleClass="invoices-table-list">
        <ng-template pTemplate="header">
            <tr>
                <th class="product">Product</th>
                <th class="payment-status">Payment Status</th>
                <th class="amount">Amount</th>
                <th class="date">Date</th>
                <th class="action">Download</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
            <tr *ngIf="!onFetchingInvoices">
                <td class="product" (click)="selectInvoice(item)">
                    <input [checked]="item.select" type="checkbox">
                    <label>{{item.product}}</label>
                </td>
                <td class="payment-status">
                    <ng-container *ngIf="item.status!='pending'">
                        Succeeded
                    </ng-container>

                    <!-- button pending -->
                    <ng-container *ngIf="item.status=='pending'">
                        <button class="btn btn-primary btn-sm" (click)="showPendingAction(item.download_pdf)">
                            <i class="vi vi-pending"></i>
                            Pending
                        </button>
                    </ng-container>

                    <!-- text failed -->
                    <ng-container *ngIf="item.status == 'failed'">
                        Failed
                    </ng-container>

                    <!-- button failed -->
                    <ng-container *ngIf="false">
                        <button class="btn btn-danger btn-sm">
                            <i class="vi vi-x"></i>
                            Failed
                        </button>
                    </ng-container>
                </td>
                <td class="amount"(click)="selectInvoice(item)">{{item.amount}}</td>
                <td class="date" (click)="selectInvoice(item)">{{item.period_start}}</td>
                <td class="action">
                    <ng-container>
                        <button [disabled]="item.download_pdf == '-'" class="btn btn-dark btn-sm" (click)="downloadInvoices([item])">
                            <i class="vi vi-download-circle"></i>
                        </button>
                    </ng-container>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <!-- Table loading -->
    <div class="loading" *ngIf="onFetchingInvoices">
        <p-progressSpinner 
            [style]="{width: '50px', height: '50px'}" 
            styleClass="custom-spinner" 
            strokeWidth="5" 
            fill="transparent" 
            animationDuration=".5s">
        </p-progressSpinner>
    </div>
    <!-- Table no data -->
    <div class="no-data" *ngIf="!onFetchingInvoices && invoices.length == 0">
        <p>No data found</p>
    </div>
</div>