<div [style]="{'padding': '10px'}">
	<button (click)="unselectText()" class="btn btn-outline-default2 btn-sm">
		<i class="pi pi-arrow-left" style="font-size: 14px;"></i> Back to Text
	</button>
</div>

<app-text-title></app-text-title>
<app-text-menu-editor></app-text-menu-editor>

<div class="content-settings mb20">
	<app-text-content></app-text-content>
	<app-text-color></app-text-color>
	<app-text-scaling></app-text-scaling>
	<app-font-size></app-font-size>
	<app-font-family></app-font-family>
</div>