// Angular built-in components/methods
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgFor, NgIf } from "@angular/common";

// User-defined services
import { MainService } from "src/app/shared/services";
import { CatalogueService } from "./catalogue.service"
import { ProfileService } from '../profile/profile.service';
import { LayoutsService } from 'src/app/layouts/layouts.service';

// Primeng services
import { environment } from '@environments';
import { CreateExhibitionService } from 'src/app/components/create-exhibition/services/create-exhibition.service';
import { DialogModule } from 'primeng/dialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { LayoutsComponent } from '../../layouts/layouts.component';

@Component({
    selector: 'app-catalogue',
    templateUrl: './catalogue.component.html',
    styleUrls: ['./catalogue.component.scss'],
    standalone: true,
    imports: [
        LayoutsComponent,
        NgFor,
        LazyLoadImageModule,
        NgIf,
        ProgressSpinnerModule,
        DialogModule,
    ],
})
export class CatalogueComponent implements OnInit {
  public loading: boolean = false;
  public openFilter: boolean = false;
  public items: any = [];
  public loadingCreateExhibition: boolean = false;
  public selectedModifiable: any = 'showAll';
  public selectedModify: any = [
    { name: 'modifiable', checked: false },
    { name: 'unmodifiable', checked: false },
  ];
  public selectedType: any = [
    { name: 'loft', checked: false },
    { name: 'minimalist', checked: false },
    { name: 'classical', checked: false },
  ];
  public selectedTheme: any = [
    { name: 'dark', checked: false },
    { name: 'light', checked: false },
  ];
  public name: string;
  public infoUser: any;
  public catalogues: any = [];
  public env = environment;

  constructor(
    private router: Router,
    private profileService: ProfileService,
    public mainService: MainService,
    public catalogueService: CatalogueService,
    private layoutService: LayoutsService,
    private _createService: CreateExhibitionService
  ) {}

  ngOnInit(): void {
    this.getCatalogue();
  }

  ngAfterViewInit() {
    this.infoUser = this.mainService.userInfo;
  }

  /**
   * * GET CATALOGUE *
   * Todo: for get list of catalogue
   */
  getCatalogue() {
    this.loading = true;
    const renderer = this.mainService.isBrowser ? 'browser' : 'server';
    this.catalogueService.getCatalogue(renderer).subscribe((res: any) => {
      this.loading = false;
      this.catalogues = res.data.room;
      this.catalogues = this.catalogues.map((room) => {
        room['thumbnail'] = environment.exhibition_path + room.folder_name + "/thumbnail.png";
        room['onCreating'] = false
        return room;
      })
    }, err => {
      if (err.name == "TimeoutError") {
        this.getCatalogue()
      }
    });
  }

  /**
   * * Create Exhibition * 
   * Todo: creating new exhibition with default configuration
   * @param dataExhibition: any
   */
  createExhibition(dataExhibition: any) {
    if(this.infoUser){
      this.profileService.startCreating(() => {
        this._createService.fromCatalogue = true;
        this._createService.exhibitionData = dataExhibition;
        this.router.navigate([this.mainService.userInfo.username + '/create']);
      }).catch(err => {
        if (err.error.statusCode == 403) {
          this.router.navigate(['/'+this.mainService.userInfo.username]);
          const limit = err.error.data.limit;
          this.layoutService.onlineExhibtionsLimit = limit.online_exhibitions;
          this.layoutService.exhibtionDraftsLimit = limit.exhibition_drafts;
          this.layoutService.billingProduct = err.error.data.product;
          this.layoutService.displayLimitMessage = true;
        };
      });
    }else{
      this.layoutService.dataExhibition = dataExhibition;
      this.layoutService.loginFrom = 'catalogue-page';
      this.mainService.displayLoginDialog = true;
    }
  }

  /**
   * * OPEN ROOM PREVIEWE *
   * Todo: for previewing room model
   * @param demolink : String  (Name of Room)
   */
  openPreview(demoLink) {
    window.open(environment.viewer_path + '/' + demoLink)
  }

  /**
   * * CLOSE BLOCK MESSAGE *
   * Todo: alert if popup is blocked
   */
  closeBlockMessage(): void {
    this.catalogueService.displayBlockMsg = false;
    if (!this.infoUser) {
      window.location.href = '/catalogue';
    }
  }
}
