import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { environment } from '@environments';
import { PrimeTemplate } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { NgIf } from '@angular/common';
@Component({
    selector: 'app-not-subs-dialog',
    templateUrl: './not-subs-dialog.component.html',
    styleUrls: ['./not-subs-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgIf, DialogModule, PrimeTemplate]
})
export class NotSubsDialogComponent implements OnInit {
  @Input() showNotSubs: boolean = false;
  @Output() onHide = new EventEmitter();
  @Input() subs=0;

  public textContent = [{
    title: "Sorry, you cannot access this feature",
    message: "Please note that the Experimental pricing plan allows you to view but not share your gallery. To unlock the full potential of Villume, consider subscribing to one of our other pricing plans. Activate your subscription to gain access to exclusive features and use the website without restrictions!"
  },
  {
    title: "Subscribe to get full experience",
    message: "Please note that the Experimental pricing plan allows you to view but not share your gallery. To unlock the full potential of Villume, consider subscribing to one of our other pricing plans. Activate your subscription to gain access to exclusive features and use the website without restrictions!"
  }
  ];

  constructor() { }

  ngOnInit(): void {
  }

  closeDialog() {
    this.showNotSubs = false;
    this.onHide.emit(false);
  }

  subscribe() {
    window.open(`${environment.base_host}/pricing`, '_blank');
  }

}
