<app-layouts>
	<div class="wrap-content-promocode" content>
		<div class="wrap-header">
			<div class="header">
				<h3 class="header__title">
					Villume Promo Codes
				</h3>
				<button [routerLink]="['/promocodes/add']" class="btn btn-primary header__button">
					<i class="vi vi-plus header__button__icon"></i>
					Add New Promo Code
				</button>
			</div>
			<div class="navigation">
				<ul>
					<li (click)="filterByStatus('all')">
						<input type="radio" [checked]="status=='all'">
						<label>show all</label>
					</li>
					<li (click)="filterByStatus('active')">
						<input type="radio" [checked]="status=='active'">
						<label>active</label>
					</li>
					<li (click)="filterByStatus('expired')">
						<input type="radio" [checked]="status=='expired'">
						<label>expired</label>
					</li>
				</ul>

				<pagination
						(onPageChange)="onPageChange($event)"
						(onEndPage)="hasMore = false"
						[page]="page"
						[filter]="status"
						[totalData]="totalCoupon"
						[limit]="limit">
				</pagination>
			</div>
			<!-- END PAGE PAGINATION -->
		</div>

		<div class="table-list">
			<p-table [value]="promoCodes" styleClass="promocodes-table-list">
				<ng-template pTemplate="header">
					<tr>
						<th class="name">Name</th>
						<th class="code">Code</th>
						<th class="discount">Discount</th>
						<th class="expires">Expires</th>
						<th class="used"># Used</th>
						<th class="action">Actions</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-item>
					<tr>
						<td class="name">{{item.name}}</td>
						<td class="code">{{item.code}}</td>
						<td class="discount">{{item.display_discount}}</td>
						<td class="expires">{{item.expires}}</td>
						<td class="used">{{item.used}}</td>
						<td class="action">
							<div class="button">
								<button (click)="navigateToEdit(item)" class="btn btn-outline-light button__edit">
									Edit
								</button>
								<button class="btn btn-outline-light button__delete"
									(click)="displayDeleteConfirmDialog(item.code)">
									<i class="vi vi-delete"></i>
								</button>
							</div>
						</td>
					</tr>
				</ng-template>
			</p-table>

			<!-- Table loading -->
			<div class="loading" *ngIf="gettingPromoCodes">
				<p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="5"
					fill="transparent" animationDuration=".5s">
				</p-progressSpinner>
			</div>

			<!-- Table no data -->
			<div class="no-data" *ngIf="!gettingPromoCodes && promoCodes.length == 0">
				<p>No data found</p>
			</div>

			<!-- LOAD MORE -->
			<div class="wrap-loadmore" *ngIf="!gettingPromoCodes && promoCodes.length > 0">
				<p class="text">You've viewed {{viewedPromoCodes()}} of {{totalCoupon}} promo codes</p>
				<button *ngIf="hasMore" class="btn-loadmore" (click)="loadMore()">Load More</button>
			</div>
		</div>

		<!-- DELETE DIALOG -->
		<p-dialog [(visible)]="displayDeleteConfirm" [modal]="true" [baseZIndex]="5000" [draggable]="false"
			[resizable]="false" closeIcon="vi vi-x" styleClass="p-dialog alert-dialog">
			<div class="wrap-content">
				<div class="alerts">
					<h1 class="alerts__title">Are you sure you want to delete this promo code?</h1>
					<p class="alerts__desc">If you press “Yes”, your promo code will be deleted immediately.
						<br>You can’t undo this action.
					</p>
				</div>
			</div>
			<ng-template pTemplate="footer">
				<div class="alerts__button">
					<button (click)="deletePromoCode()" [disabled]="this.deletingPromoCode"
						class="btn btn-light btn-sm">
						<p-progressSpinner *ngIf="this.deletingPromoCode" [style]="{width: '20px', height: '20px'}"
							styleClass="custom-spinner" strokeWidth="5" fill="transparent" animationDuration=".5s">
						</p-progressSpinner>
						<ng-conteiner *ngIf="!this.deletingPromoCode">
							Yes
						</ng-conteiner>
					</button>
					<button (click)="displayDeleteConfirm=false" [disabled]="this.deletingPromoCode"
						class="btn btn-light btn-sm">
						No
					</button>
				</div>
			</ng-template>
		</p-dialog>
	</div>
</app-layouts>