@import '../../../assets/scss/common/variables';

.order-1{
    @media (max-width: $medium-screen) {
        order: 1 !important;
    }
    
    @media (max-width: $small-screen) {
        order: 2 !important;
    }
}

.order-2{
    @media (max-width: $medium-screen) {
        order: 2 !important;
    }

    @media (max-width: $small-screen) {
        order: 1 !important;
    }
}

.order-4{
    @media (max-width: $large-screen) {
        order: 3 !important;
    }
}

.order-4{
    @media (max-width: $large-screen) {
        order: 3 !important;
    }

    @media (max-width: $small-screen) {
        order: 4 !important;
    }
}

.order-5{
    @media (max-width: $large-screen) {
        order: 4 !important;
    }

    @media (max-width: $small-screen) {
        order: 3 !important;
    }
}

.footer{
    position: relative;
    background-color: $color-gray4;
    min-height: 100%;

    .content-footer{
        padding: 115px 30px 38px;
        position: relative;
        display: inline-table;
        width: 100%;

        @media (max-width: $large-screen) {
            padding: 100px 25px 25px;
        }

        @media (max-width: $medium-screen) {
            padding: 100px 25px 38px;
        }

        @media (max-width: $small-screen) {
            padding: 50px 0 30px;
            text-align: left;
        }
    
        .set-width-responsive-dropdown{
            @media (max-width: $desktop-screen) {
                
            }

            @media (max-width: $large-screen) {
                max-width: 50%;
                flex: 0 0 50%;
            }

            @media (max-width: $medium-screen) {
                max-width: 100%;
                flex: 0 0 100%;
            }

            @media (max-width: $medium-screen) {
                max-width: 100%;
                flex: 0 0 100%;
            }
        }

        .set-width-responsive-menu-1{
            @media (max-width: $desktop-screen) {
                margin-left: 14% !important;
                -ms-flex: 0 0 25%;
                flex: 0 0 25%;
                max-width: 25%;
            }

            @media (max-width: $large-screen){
                margin-left: 25% !important;
            }

            @media (max-width: $medium-screen) {
                margin-left: 0 !important;
            }
        }

        .set-width-responsive-menu-2 {
            flex: 25%;
            max-width: 25%;
        }

        .set-width-responsive-menu-1,
        .set-width-responsive-menu-2{
            @media (max-width: $large-screen) {
                max-width: 25%;
                flex: 0 0 25%;
                margin-left: 0;
            }

            @media (max-width: $large-screen) {
                max-width: 25%;
                flex: 0 0 25%;
            }

            @media (max-width: $medium-screen) {
                max-width: 37.5%;
                flex: 0 0 37.5%;
            }

            @media (max-width: $small-screen) {
                max-width: 50%;
                flex: 0 0 50%;
            }
        }

        .set-width-responsive-desc{
            @media (max-width: $desktop-screen) {
                -ms-flex: 0 0 31.25%;
                flex: 0 0 31.25%;
                max-width: 31.25%;
            }

            @media (max-width: $large-screen) {
                max-width: 50%;
                flex: 0 0 50%;
                margin-left: 0;
            }

            @media (max-width: $medium-screen) {
                max-width: 62.5%;
                flex: 0 0 62.5%;
            }

            @media (max-width: $small-screen) {
                max-width: 100%;
                flex: 0 0 100%;
            }
        }

        .set-width-responsive-social{
            @media (max-width: $large-screen) {
                max-width: 6.25%;
                flex: 0 0 6.25%;
            }

            @media (max-width: $medium-screen) {
                max-width: 37.5%;
                flex: 0 0 37.5%;
            }

            @media (max-width: $small-screen) {
                max-width: 100%;
                flex: 0 0 100%;
                margin-left: 0;
            }
        }

        .widget-on-responsive{
            display: none;

            @media (max-width: $large-screen) {
                display: block;
            }

            @media (max-width: $medium-screen) {
                display: none;
            }
        }

        .country{
            width: 215px;
            margin-right: 20px;

            @media (max-width: $large-screen) {
                margin-left: -15px;
            }

            @media (max-width: $medium-screen) {
                margin-right: 65px;
                margin-bottom: 80px;
                width: 83px;
                margin-left: 0;
            }

            @media (max-width: $medium-screen) {
                max-width: 33%;
                flex: 0 0 37.5%;
                margin-left: -17px;
                margin-right: 60px;
            }

            @media (max-width: $small-screen) {
                margin-right: 66px;
                margin-left: 0;
                max-width: 30%;
                flex: 0 0 50%;
                margin-bottom: 43px;
            }
        }

        .currency{
            width: 127px;
            
            @media (max-width: $medium-screen) {
                margin-bottom: 80px;
                margin-left: -5px;
                max-width: 255%;
                flex: 0 0 25%;
            }

            @media (max-width: $small-screen) {
                margin-bottom: 43px;
                width: 66px;
            }
        }

        .widget{
            margin-bottom: 44px;

            @media (max-width: $large-screen) {
                margin-bottom: 93px;
            }

            @media (max-width: $medium-screen) {
                margin-bottom: 40px;
                margin-left: -5px;
            }

            @media (max-width: $small-screen) {
                margin-bottom: 38px;
                margin-left: 0;
            }


            &.menu-footer{
                @media (max-width: $medium-screen) {
                    margin-left: -15px;
                }

                @media (max-width: 420px) {
                    margin-left: 5px;
                }
            }

            &.widget-off-responsive{
                display: block;

                @media (max-width: $large-screen) {
                    display: none;
                }

                @media (max-width: $medium-screen) {
                    display: block;
                }

                >ul{
                    display: flex;
                    flex-direction: column;

                    >li{
                        &.item-menu-info{
                            &:first-child{
                                @media (max-width: $small-screen) {
                                    order: 2;
                                }
                            }
        
                            &:nth-child(2){
                                @media (max-width: $small-screen) {
                                    order: 1;
                                }
                            }
                        }
                    }
                }
                
            }

            ul{
                padding-left: 0;
                margin-bottom: 0;

                li{
                    list-style: none;
                    margin-bottom: 8px;

                    @media (max-width: $large-screen) {
                        margin-bottom: 15px;
                    }

                    @media (max-width: $medium-screen) {
                        margin-bottom: 20px;
                    }

                    @media (max-width: $small-screen) {
                        margin-bottom: 25px;
                    }

                    a{
                        color: $color-dark1;
                        font-size: 20px;

                        @media (max-width: $small-screen) {
                            font-size: 14px;
                            line-height: 22px;
                        }

                        &.active{
                            color: $color-white;
                        }


                        &:hover{
                            color: $color-white;
                            text-decoration: none;
                        }
                    }
                }
            }
        }

        .desc{
            margin-left: 15px;

            @media (max-width: $desktop-screen) {
            
            }

            @media (max-width: $large-screen) {
                margin-left: -20px;
                padding-right: 50px;
                margin-top: 0;
            }

            @media (max-width: $medium-screen) {
                margin-left: -20px;
                margin-top: 30px;
            }

            @media (max-width: $small-screen) {
                margin-left: 0;
                margin-top: -5px;
                padding-right: 0px;
            }

            span {
                display: block;
                color: $color-dark1;
                font-size: 20px;
                line-height: 146%;
                text-wrap: nowrap;
        
                @media (max-width: $small-screen) {
                    font-size: 14px;
                    line-height: 22px;
                }
        
                a{
                    color: $color-white;
        
                    @media (max-width: 420px) {
                        font-size: 14px;
                        line-height: 22px;
                    }
                }
            }
        }

        .wrap-icon-social{
            text-align: right;
            margin-top: 4px;

            @media (max-width: $large-screen) {
                margin-top: 0;
            }
            
            @media (max-width: $medium-screen) {
                margin-top: 30px;
            }

            @media (max-width: $small-screen) {
                margin-top: 0;
                margin-right: 40px;
                margin-top: 6px;
                display: inline-flex;
                float: right;
            }
        
            .btn-social{
                margin-bottom: 34px;
                border-radius: 50%;
                background-color: $color-dark1;
                width: 24px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: auto;

                @media (max-width: $large-screen) {
                    margin-left: inherit;
                }

                @media (max-width: $medium-screen) {
                    margin-bottom: 32px;
                    margin-left: auto;
                }

                @media (max-width: $small-screen) {
                    margin-bottom: 0;
                    margin-left: 50px;
                }

                .vi{
                    font-size: 16px;
                }

                &:hover{
                    background-color: $color-white;
                    text-decoration: none;

                    .icon-social{
                        path{
                            stroke: $color-gray4;
                        }
        
                        ellipse{
                            stroke: $color-gray4;
                        }
        
                        rect{
                            stroke: $color-gray4;
                        }
                    }
                }
            }
        }
    }

    .sub-footer{
        text-align: right;
        padding: 30px;
    
        @media (max-width: $medium-screen) {
            padding: 30px 25px;
        }
    
        @media (max-width: $small-screen) {
            padding: 15px;
        }

        &.white {
            padding: 35px 30px 30px;

            @media (max-width: $medium-screen) {
                padding: 30px 25px 25px;
            }
        
            @media (max-width: $small-screen) {
                padding: 18px 15px 15px;
            }
        }
    
        p{
            color: $color-dark1;
            font-size: 16px;
            line-height: 109.8%;
    
            @media (max-width: $small-screen) {
                font-size: 14px;
                line-height: 22px;
            }
        }
    }
}