import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MainService } from '@services';
import { EditorService } from '../../editor.service';
import { SplashScreenService } from './splash-screen.service';
import { AlertMessageService } from 'src/app/components/alert-message/alert-message.service';
import { environment } from '@environments';
import { CropperDialogComponent } from '../../../../../components/cropper-dialog/cropper-dialog.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { FormsModule } from '@angular/forms';
import { SliderModule } from 'primeng/slider';
import { SplashScreenPreviewComponent } from './splash-screen-preview/splash-screen-preview.component';
import { PrimeTemplate } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { NgIf, NgStyle } from '@angular/common';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { FontStyleComponent } from "./font-style/font-style.component";

@Component({
    selector: 'app-splash-screen',
    templateUrl: './splash-screen.component.html',
    styleUrls: ['./splash-screen.component.scss'],
    standalone: true,
    imports: [
    NgIf,
    DialogModule,
    PrimeTemplate,
    SplashScreenPreviewComponent,
    SliderModule,
    FormsModule,
    NgStyle,
    LazyLoadImageModule,
    CropperDialogComponent,
    ScrollPanelModule,
    FontStyleComponent
]
})
export class SplashScreenComponent {
  public backgroundImage;
  public backgroundFile;
  public cropping = false;
  public loading: boolean = false;
  public buttonEmpty: boolean = false;
  public env = environment.staticAssets;
  
  public zoomValue = [0, 0];
  public headerText: string = '';
  public bodyText: string = '';
  public colorTone: string = 'dark';
  public alignHeader: string = '';
  public alignBody: string =  '';
  public textPlacement: number = 3;
  public buttonText: string = 'Enter Gallery';
  public mobileView: boolean = false;
  public undoRedoChanges: number = 0;
  public backgroundPosition: string = '';

  @ViewChild('inputBackground') inputBackground: ElementRef;
  @Input() visible: boolean = true;
  @Output() onHide = new EventEmitter();

  constructor(
    public mainService: MainService,
    private editorService: EditorService,
    public splashScreenService: SplashScreenService,
    private _messageService: AlertMessageService
  ) {
    this.getSplashScreen();
  }

  ngOnInit():void {
  }

  /**
	 * * ANCHOR CHANGE VIEWER THEME *
	 * Todo: to change viewer theme 'dark' or 'light'
	 */
	changeViewerTheme(){
    this.viewerLightMode = !this.viewerLightMode;
    this.colorTone = this.viewerLightMode ? 'light' : 'dark';
	}

  /**
	 * * INIT DRAG DROP *
	 * Todo: to initialize drag and drop
	 */
  public dropHere: boolean = false;
	initDragDrop(id){
		setTimeout(()=>{
			const dropArea = document.getElementById(id);
			if(dropArea){
				const handles = {
					ondrop: (e) => {
						let dt = e.dataTransfer;
						this.uploadAttachementFile(dt.files, true);
						dropArea.classList.remove('drop');
            this.dropHere = false;
					},
					ondragenter: (e) => {
            this.dropHere = true;
						dropArea.classList.add('drop');
					},
					ondragleave: (e) => {
            this.dropHere = false;
						dropArea.classList.remove('drop');
					} 
				}
				this.editorService.initDragAndDropFuction(dropArea, handles)	
			}
		},1000)
	}

  /**
	 * * ANCHOR UPLOAD ATTACHEMENT FILES *
	 * Todo: to upload attacement files
	 */
	async uploadAttachementFile(file, fromDragDrop = false) {
    if (fromDragDrop) {
      if (await this.validateImage(file[0])) {
        this.backgroundFile = URL.createObjectURL(file[0]);
        this.cropping = true;
      }
    } else {
      if (await this.validateImage(file.target.files[0])) {
        this.backgroundFile = URL.createObjectURL(file.target.files[0]);
        this.cropping = true;
      }
    }

      this.inputBackground.nativeElement.value = '';
    }

  /**
   * * VALIDATE IMAGE *
   * Todo: for validating uploaded file 
   */
  async validateImage(file: File){
    if(file){
      // Get file extension & set allowed file extension
      const extension = file.name.split(".").slice(-1)[0].toLowerCase();
      const allowedExtention = ['png','jpeg','jpg'];
      // Validate file extension 
      if(allowedExtention.includes(extension)){
        try {
          await this.validateBrokenImg(file);

          return true;
        } catch (error) {
          this._messageService.add({
            severity:"warn", 
            summary:"Warning", 
            detail:"Corrupted file!"
          });
          
          return false;
        }
      }else{
        this._messageService.add({severity:"warn", summary:"Warning", detail:"This file format is not supported. Please upload .png, or .jpeg"});
        return false;
      }	
    }else{
      return false;
    }
  }

  /**
   * * VALIDATE BROKEN IMAGE *
   * Todo: to validate broken image
   */
  validateBrokenImg(file: File) {
    return new Promise((resolve, reject) => {
      const url = URL.createObjectURL(file);
      const img = new Image();
      img.onerror = reject;
      img.onload = resolve;
      img.src = url;
    })
  }

  /**
   * ANCHOR: SET POSITION TEXT
   * Todo: to set position text
   */
  public setPositionText(position: number) {
    this.textPlacement = position;
  }

  /**
   * ANCHOR: SET ALIGN TEXT
   * Todo: to set align text
   */
  public setAlignText(type: 'header'|'body', align: string) {
    if (type === 'header') this.alignHeader = align;
    else this.alignBody = align;
  }

  /**
   * ANCHOR VALIDATION FORM INPUT
   * Todo: validate input text
   */
  public validationInput(form) {
    switch (form) {
      case 'header':
        setTimeout(() => {
          if (this.headerText.length > 120) this.headerText = this.headerText.substring(0, 120);
        }, 50);
        break;
    
      case 'body':
        setTimeout(() => {
          if (this.bodyText.length > 500) this.bodyText = this.bodyText.substring(0, 500);
        }, 50);
        break;

      case 'button':
          if (this.buttonText.length === 1) {
            this.buttonText = this.buttonText.trim();
          }

          if (this.buttonText.length === 0) this.buttonEmpty = true;
          else this.buttonEmpty = false;
          if (this.buttonText.length > 20) this.buttonText = this.buttonText.substring(0, 20);
        break;
    }
  }

  /**
   * ANCHOR: SET SLIDER BACKGROUND ZOOM
   */
  public setSliderBackgroundSize(event) {
    if (this.mobileView) this.zoomValue[1] = event.value;
    else this.zoomValue[0] = event.value;
  }

  /**
   * ANCHOR: SET ZOOM VALUE BUTTON
   */
  public setButtonBackgroundSize(action: 'zoomout' | 'zoomin') {
    const value = this.mobileView ? this.zoomValue[1] : this.zoomValue[0];
    if (action == 'zoomout' && value > 0) {
      this.mobileView ? this.zoomValue[1] = this.zoomValue[1]-1: this.zoomValue[0] = this.zoomValue[0]-1;
    } else if (action == 'zoomin' && value < 100) {
      this.mobileView ? this.zoomValue[1] = this.zoomValue[1]+1: this.zoomValue[0] = this.zoomValue[0]+1;
    }
  }

  /**
   * ANCHOR: SET ZOOM VALUE
   */
  setZoomValue(event){
    if (this.mobileView) this.zoomValue[1] = event;
    else this.zoomValue[0] = event;
  }

  /**
   * ANCHOR: Init form values
   * Todo: to initialize form values
   */
  private _initFormValues(data: any) {
    const clonedData = {...data};
    let image;
    if (clonedData.image && !clonedData.image.includes(environment.image_path)) {
      if (clonedData.image.includes('data:image'))
        image = clonedData.image;
      else
        image = this.splashScreenService.convertPathImage(clonedData.image);
    } else if (clonedData.image) {
      image = clonedData.image;
    }

    this.headerText = clonedData?.header || '';
    this.bodyText = clonedData?.body || '';
    this.colorTone = clonedData?.overlay_tone || 'dark';
    this.alignHeader = clonedData?.header_align || 'center';
    this.alignBody = clonedData?.body_align || 'center';
    this.textPlacement = clonedData?.text_placement || 3;
    this.buttonText = clonedData?.button_text || 'Enter Gallery';
    this.backgroundImage = image
    this.zoomValue[0] = Number(clonedData?.zoom) || 0;
    this.zoomValue[1] = Number(clonedData?.zoom_mobile) || 0;
    this.backgroundPosition = clonedData?.background_position || 'auto 100%';
    this.viewerLightMode = clonedData?.overlay_tone === 'light' ? true : false;
  }

  /**
   * ANCHOR: update form
   */
  public updateForm(event: any) {
    for (const key in event) {
      if (Object.prototype.hasOwnProperty.call(event, key)) {
        const element = event[key];
        if (key === 'zoomValue') this.mobileView ? this.zoomValue[1] = Number(element) : this.zoomValue[0] = Number(element);
        else if (key === 'colorTone') {
          this.viewerLightMode = element === 'light' ? true : false;
          this.colorTone = element;
        }
        else {
          this[key] = element;
        }
      }
    }
  }

  /**
   * ANCHOR: create form data
   */
  private _createFormData() {
    const data = this.splashScreenService.splashScreenData;
    const formData = new FormData();
    formData.append('v', '1');
    formData.append('sid', this.editorService.exhibition.id);
    formData.append('overlay_tone', this.colorTone);
    formData.append('header_align', this.alignHeader);
    formData.append('body_align', this.alignBody);
    formData.append('text_placement', this.textPlacement.toString());
    formData.append('button_text', this.buttonText);
    formData.append('zoom', this.zoomValue[0].toString());
    formData.append('background_position', this.backgroundPosition);
    formData.append('zoom_mobile', this.zoomValue[1].toString());

    formData.append('header_font_size', data.header_font_size);
    formData.append('header_line_height', data.header_line_height);
    formData.append('header_font_family', data.header_font_family?.value||data.header_font_family);
    formData.append('header_font_weight', data.header_font_weight?.name||data.header_font_weight);

    formData.append('body_font_size', data.body_font_size);
    formData.append('body_line_height', data.body_line_height);
    formData.append('body_font_family', data.body_font_family?.value||data.body_font_family);
    formData.append('body_font_weight', data.body_font_weight?.name||data.body_font_weight);

    if (this.headerText) formData.append('header', this.headerText);
    if (this.bodyText) formData.append('body', this.bodyText);
    
    if (this.backgroundImage && this.backgroundImage.includes('data:image')) {
      const image = this.splashScreenService.base64ToBlob(this.backgroundImage);
      formData.append('image', image, 'splash_screen.png');
    }

    if (this.backgroundImage && this.backgroundImage.includes(environment.image_path)) {
      formData.append('old_image', true.toString());
    }
    
    return formData;
  }

  /**
   * ANCHOR: Update Splash Screen
   * Todo: to update splash screen
   */
  public saveSplashScreen() {
    this.loading = true;
    const formData = this._createFormData();
    this.splashScreenService.updateSplashScreen(formData).subscribe({
      next: (res: any) => {
        this.editorService.updateJsonVersion().subscribe({
          next: (res) => {
            this.editorService.updateExhibitionViewer().subscribe({
              next: (res) => {
                this._messageService.add({ severity: 'success', summary: 'Success', detail: 'Splash screen updated successfully' });
                this.splashScreenService.isSaved = true;
                this.loading = false;
                this.visible = false;
              },
              error: (err) => {
                this.loading = false;
                this._messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to get splash screen' });
              }
            });
          },
          error :(err) => {
            this.loading = false;
            this._messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to get splash screen' });
          }
        });
      },
      error: (err) => {
        if (err.status === 401) this.mainService.expiredSesionPopup = true;
        else this._messageService.add({ severity: 'error', summary: 'Failed', detail: 'Failed to save splash screen'})
        this.loading = false;
      }
    });
  }


  /**
   * ANCHOR: Get splash screen
   */
  public viewerLightMode: boolean;  
  public getSplashScreen() {
    if (this.splashScreenService.splashScreenData) {
      this._initFormValues(this.splashScreenService.splashScreenData);
      this.viewerLightMode = this.splashScreenService.splashScreenData.overlay_tone === 'light' ? true : false;
    } else {
      this.splashScreenService.fetchSplashScreen(this.editorService.exhibition?.id).subscribe({
        next: (res: any) => {
          if (Array.isArray(res.data.data)) {
            this.splashScreenService.splashScreenData = res['data']['data'][0];
          } else {
            this.splashScreenService.splashScreenData = res['data']['data'];
          }

          this._initFormValues(this.splashScreenService.splashScreenData);
          this.viewerLightMode = this.splashScreenService.splashScreenData?.overlay_tone === 'light' ? true : false;
        },
        error: (err) => { 
          if (err.status === 401) {
            this.mainService.expiredSesionPopup = true;
            this.visible = false;
          } else {
            this._messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to get splash screen' });
          }
        }
      });
    }
  }

  /**
   * ANCHOR: After Cropped Image
   */
  public onCroppedImage(event: any) {
    this.backgroundImage = event;
    this.cropping = false;
  }

  public onShowSplashScreen() {
    this._initFormValues(this.splashScreenService.splashScreenData);
    this.splashScreenService.isSaved = false;
  }

  public onHideSplashScreen() {
    this.undoRedoChanges=0;
    this.onHide.emit();
    this.mobileView = false;
    this.buttonEmpty = false;

    if (this.splashScreenService.isSaved) {
      const data = this.splashScreenService.splashScreenData;
      this.splashScreenService.splashScreenData = {
        ...data,
        background_position: this.backgroundPosition,
        body: this.bodyText,
        body_align: this.alignBody,
        button_text: this.buttonText,
        header: this.headerText,
        header_align: this.alignHeader,
        image: this.backgroundImage,
        overlay_tone: this.colorTone,
        text_placement: this.textPlacement,
        zoom: this.zoomValue[0],
        zoom_mobile: this.zoomValue[1],
        header_font_size: data.header_font_size,
        header_line_height: data.header_line_height,
        header_font_family: data.header_font_family,
        header_font_weight: data.header_font_weight,
        body_font_size: data.body_font_size,
        body_line_height: data.body_line_height,
        body_font_family: data.body_font_family,
        body_font_weight: data.body_font_weight,
      }
    } else {
      this._initFormValues(this.splashScreenService.splashScreenData);
    }
  }

  public updateFontStyle(event:any) {
    const data = this.splashScreenService.splashScreenData;
    this.splashScreenService.splashScreenData = {
      ...data,
      header_font_family: event.header_font_family||data.header_font_family,
      header_font_size: event.header_font_size||data.header_font_size,
      header_line_height: event.header_line_height||data.header_line_height,
      header_font_weight: event.header_font_weight||data.header_font_weight,
      body_font_family: event.body_font_family||data.body_font_family,
      body_font_size: event.body_font_size||data.body_font_size,
      body_line_height: event.body_line_height||data.body_line_height,
      body_font_weight: event.body_font_weight||data.body_font_weight,
    }
  }

  public onUploadBackground() {
    this.inputBackground.nativeElement.click();
  }
}
