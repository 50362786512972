<div>
    <form name="form" [formGroup]="form">
        <div class="d-flex form-group">

            <!-- FIRST NAME -->
            <div class="form-group w-50 mr-1 mb-0">
                <input type="text" class="form-control lg bg-gray" 
                    placeholder="First Name"
                    formControlName="first_name"
                    (input)="removeSpaceAtBegin('first_name')"
                    [ngClass]="{'is-invalid': getErrors('first_name') && formControl().first_name.touched}"
                    [disabled]="loading">
                <div class="invalid-feedback mb-0" *ngIf="getErrors('first_name')">
                    <ng-container *ngIf="getErrors('first_name').required">
                        First name is required
                    </ng-container>
                    <ng-container *ngIf="getErrors('first_name').pattern">
                        Can't contain a special character
                    </ng-container>
                </div>
            </div>

            <!-- LAST NAME -->
            <div class="form-group w-50 ml-1 mb-0">
                <input type="text" class="form-control lg bg-gray" 
                    placeholder="Last Name" 
                    formControlName="last_name"
                    (input)="removeSpaceAtBegin('last_name')"
                    [ngClass]="{'is-invalid' : getErrors('last_name') && formControl().last_name.touched}"
                    [disabled]="loading">
                <div class="invalid-feedback mb-0" *ngIf="getErrors('last_name')">
                    <ng-container *ngIf="getErrors('last_name').required">
                        Last name is required
                    </ng-container>
                    <ng-container *ngIf="getErrors('last_name').pattern">
                        Can't contain a special character
                    </ng-container>
                </div>
            </div>

        </div>

        <!-- EMAIL -->
        <div class="form-group">
            <input type="email" 
                class="form-control lg bg-gray" 
                placeholder="Email" 
                formControlName="email"
                (input)="removeSpaceAtBegin('email')"
                [ngClass]="{'is-invalid':getErrors('email') && formControl().email.touched}"
                [disabled]="loading">
            <div class="invalid-feedback" *ngIf="getErrors('email')">
                <ng-container *ngIf="getErrors('email').required">Email is required</ng-container>
                <ng-container *ngIf="getErrors('email').email">Email is not valid</ng-container>
            </div>
        </div>

        <!-- PASSWORD -->
        <div class="form-group">
            <div class="d-flex align-items-center position-relative" [ngClass]="{'is-invalid':form.controls.password.invalid&&form.controls.password.touched}">
                <input 
                    [type]="showPassword ?'text' : 'password'" 
                    class="form-control lg bg-gray"
                    [style]="{'padding-right': '52px'}"
                    placeholder="Password"
                    [ngClass]="{'is-invalid': form.controls.password.invalid && form.controls.password.touched}"
                    formControlName="password"
                    [disabled]="loading" />

                <!-- IMAGE SHOW PASSWORD -->
                <div class="field-icon" (click)="showPassword = !showPassword">
                    <i class="vi vi-eye" *ngIf="showPassword"></i>
                    <i class="vi vi-eye-closed" *ngIf="!showPassword"></i>
                </div>
            </div>
            <div class="invalid-feedback" *ngIf="form.controls.password.invalid&&form.controls.password.touched">
                <ng-container *ngIf="form.controls.password.value.length == 0">Password is required</ng-container>
                <ng-container *ngIf="form.controls.password.value.length > 0">Password must be at least 6 characters</ng-container>
            </div>
        </div>

        <!-- ANTION BUTTON CONTINUE -->
        <p class="confirm-terms">
            By clicking Continue, I agree that I have read and accepted the 
            <a href="javascript:;" routerLink="/term-of-service" (click)="mainService.displayLoginDialog=false">Terms of Use</a> 
            and 
            <a href="javascript:;" routerLink="/privacy-cookie-policy" (click)="mainService.displayLoginDialog=false">Privacy Policy</a>.
        </p>

        <div class="g-recaptcha" id="captchaSignUp"></div>

        <button 
            class="btn btn-primary" 
            (click)="register()" 
            [disabled]="form.invalid || !captchaVerifed || loading " 
            [class.loading-button-primary]="loading">
            Continue <i class="pi pi-arrow-right" style="font-size: 16px;"></i>
        </button>
    </form>
</div>
