import { Component, Input, OnInit } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import moment from 'moment';
import { PrimeTemplate } from 'primeng/api';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'app-input-date',
    templateUrl: './input-date.component.html',
    styleUrls: ['./input-date.component.scss'],
    standalone: true,
    imports: [NgIf, DropdownModule, FormsModule, PrimeTemplate, NgClass]
})
export class InputDateComponent implements OnInit {
	@Input() label:any;
	@Input() disabledDate:boolean = false;
	@Input() blank:boolean = false;
	@Input() months:any = [];
	@Input() days:any = [];
	@Input() years:any = [];
	@Input() month:any;
	@Input() year:any;
	@Input() day:any;
	@Input() hideDays:boolean = false;
	@Input() yearType: 'birth-date' | 'editor-date' | 'invoice-date' = 'birth-date'; 
	@Output() selectDate = new EventEmitter();

	constructor() { }

	ngOnInit(): void {}
	
	ngAfterViewInit(): void{
		if(this.yearType == 'birth-date') this.onSelectDate();
	}
	
	ngOnChanges(): void { 
		if(this.yearType == 'birth-date') this.initBirthDateOptions();
		else if(this.yearType == 'editor-date') this.initEditorDateOptions();
		else if(this.yearType == 'invoice-date') this.initInvoiceDate();
	}
	
	/**
	 * * PAD *
	 * Todo: -
	 * @param d : Number
	 * @returns String
	 */
	pad(d) { return (d < 10) ? '0' + d.toString() : d.toString(); }

	/**
	 * * ON SELECT DATE *
	 * Todo: to emit event select date
	 */
	onSelectDate(){
		let date;
		let obj

		if (this.yearType != 'invoice-date') {
			const countDays = moment(`${this.month} ${this.year}`,"MM YYYY").daysInMonth();
			if(Number(this.day) > countDays) this.day = countDays;
			date = `${this.year}-${this.month}-${this.day}`;
			obj = {
				date: date,
				isvalid: moment(date, 'YYYY-MM-DD', true).isValid()
			}
		} else {
			date = `${this.year}-${this.month}`;
			obj = {
				date: {
					year: this.year,
					month: this.month,
				},
				isvalid: moment(date, 'YYYY-MM', true).isValid()
			}
		}
		this.selectDate.emit(obj);
	}

	/**
	 * * INIT BIRTH DATE OPTIONS *
	 * Todo: to initialize birth date options
	 */
	initBirthDateOptions(){
		this.months = [];
		this.days = [];
		this.years = [];

		// Init years options 
		for(let i = -100;i<1;i++){
			this.years.push({
				label: moment().add(i, 'years').format("YYYY"),
				value: moment().add(i, 'years').format("YYYY")
			});
		}
		// Set selected year
		if(!this.year) this.year = this.years.find((e) => e.value == moment().format("YYYY")).value;
		

		// Init months options
		for(let i = 0;i < moment.months().length;i++){
			const month = moment.months()[i];
			this.months.push({
				label: month,
				value: this.pad(i + 1)
			})
		}
		// Set selected month
		if(!this.month) {
			this.month = this.months.find((e) => e.value == moment().format("MM")).value;
		}else{
			const month = this.months.find((x:any)=>x.value==this.month)
			if(!month) this.month = this.months.slice(-1)[0].value;
		}

		// Init days options
		const countDays = moment(`${this.month} ${this.year}`,"MM YYYY").daysInMonth();
		for(let i = 1; i <= countDays;i++){
			this.days.push({
				label:i,
				value: this.pad(i)
			})
		}

		// Set selected month
		if(!this.day) {
			this.day = this.days.find((e)=> e.value === moment().format("DD")).value
		}else{
			const day = this.days.find((x:any)=>x.value==this.day);
			if(!day) this.day = countDays;
		}
	}

	/**
	 * * INIT EDITOR DATE OPTIONS *
	 * Todo: to initialize editor date options
	 */
	initEditorDateOptions(){
		this.months = [];
		this.days = [];
		this.years = [];

		// Init years options
		for(let i = 0; i < 11;i++){
			this.years.push({
				label: moment().add(i, 'years').format("YYYY"),
				value: moment().add(i, 'years').format("YYYY")
			});
		} 


		// Set selected options
		if(!this.year){
			this.year = this.years.find((e) => e.value == moment().format("YYYY")).value;
		}else{
			if(Number(this.year) < Number(this.years[0].value)){
				this.years.unshift({
					label: this.year,
					value: this.year
				});
			} 
		}

		// Init months options
		for(let i = 0;i < moment.months().length;i++){
			const month = moment.months()[i];
			this.months.push({
				label: month,
				value: this.pad(i + 1)
			})
		}

		// Set selected month
		if(!this.month) {
			this.month = this.months.find((e) => e.value == moment().format("MM")).value;
		}else{
			const month = this.months.find((x:any)=>x.value==this.month)
			if(!month) this.month = this.months.slice(-1)[0].value;
		}

		// Init days options
		const countDays = moment(`${this.month} ${this.year}`,"MM YYYY").daysInMonth()
		for(let i = 1; i <= countDays;i++){
			this.days.push({
				label: i.toString(),
				value: this.pad(i)
			})
		}

		// Set selected month
		if(!this.day) {
			this.day = this.days.find((e)=> e.value === moment().format("DD")).value
		}else{
			const day = this.days.find((x:any)=>x.value==this.day)
			if(!day) this.day = this.days.slice(-1)[0].value;
		}
	}

	/**
	 * * INIT INVOICES DATE *
	 * Todo: to initialize invoice date options
	 */
	initInvoiceDate(){	
		this.months = [];
		this.years = [];

		// Init years options 
		for(let i = -100;i<1;i++){
			this.years.push({
				label: moment().add(i, 'years').format("YYYY"),
				value: moment().add(i, 'years').format("YYYY")
			});
		}

		// Set selected options
		if(!this.year) this.year = this.years.find((e) => e.value == moment().format("YYYY")).value;

		// Init months options
		for(let i = 0;i < moment.months().length;i++){
			const month = moment.months()[i];
			this.months.push({
				label: month,
				value: this.pad(i + 1)
			})
		}

		// Set selected month
		if(!this.month) this.month = this.months.find((e) => e.value == moment().format("MM")).value;
	}
}
