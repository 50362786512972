import { Component } from '@angular/core';
import { MainService } from '@services';
import { WhiteLabelService } from 'src/app/components/white-label/white-label.service';
import { NgIf, NgTemplateOutlet } from '@angular/common';
import { LayoutsComponent } from '../../../layouts/layouts.component';

@Component({
    selector: 'app-information',
    templateUrl: './information.component.html',
    styleUrls: ['./information.component.scss'],
    standalone: true,
    imports: [LayoutsComponent, NgIf, NgTemplateOutlet]
})
export class InformationComponent {
  public userInfo: any;

  constructor(
    private _mainService: MainService,
    private _whiteLabelService: WhiteLabelService
  ) {
    if (!this._whiteLabelService.whiteLabel) {
      this._mainService.userInfoPublicSubject.asObservable().subscribe((userData) => {
        this.userInfo = userData;
      })
    } else {
      this._whiteLabelService.infoPage = true;
    }
  }

  public navigateToSosmed(username: string, sosmed: 'instagram' | 'facebook'): void {
    const path = 'https://www.' + sosmed + '.com/' + username;
    window.open(path, '_blank');
  }
}
