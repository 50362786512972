// Angular packages
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';

// User-defined services
import { ArtworkShadowsService, UtilsService, store } from 'src/app/shared/services';
import { EditorService } from '../../editor.service';
import { ImagesService } from '../images.service';

// Third-party packages
import watch from 'redux-watch';

// User-defined interfaces
import { Artwork } from 'src/app/shared/interfaces';
import { 
  ArtworkDimension, 
  ArtworkRatio, 
} from '../images.interfaces';
import { FormsModule } from '@angular/forms';
import { SliderModule } from 'primeng/slider';
import { InputNumberComponent } from '../../../../../components/input-number/input-number.component';

declare const BABYLON: any;


@Component({
    selector: 'app-scaling-image',
    templateUrl: './scaling-image.component.html',
    styleUrls: ['./scaling-image.component.scss'],
    standalone: true,
    imports: [InputNumberComponent, SliderModule, FormsModule]
})

export class ScalingImageComponent implements OnInit, AfterViewInit, OnDestroy {
  public minScaling: number;
  public scalingValue: number;
  private _ratio: ArtworkRatio[] = [];
  private _doFirstStep: boolean = false;
  private _selectObjectWatchSubscription: any;

  constructor(
    public editorService: EditorService,
    private _artworkShadowsService: ArtworkShadowsService,
    private _artworkService: ImagesService,
    private _utilsService: UtilsService
  ) { 
    this._watchSelectedObjectState();
  }

  ngOnInit(): void {
    this._initialScalingValues();
  }

  ngAfterViewInit(): void {
    this._registerStartScalingEvent();
  }

  ngOnDestroy(): void {
    this._selectObjectWatchSubscription();
  }


   /**
	 * * ================================================================================================ *
	 *   SECTION Handle Start Scaling Event Functions
	 * * ================================================================================================ *
	 */

  //#region
  
  /**
   * * REGISTER START SCALING EVENT *
   * ANCHOR Register Start Scaling Event
   * @description to register start scaling event
   */
  private _registerStartScalingEvent(): void {
    const sliderHandle = document.querySelector('.scaliing-artwork .p-slider-handle');
    sliderHandle.addEventListener('pointerdown', () => {
      if (
        !this.editorService.onInput &&
        !this.editorService.blockUserAction
        ) this._handleStartScaling();
    })
  }

  /**
   * * HANDLE START SCALING EVENT *
   * ANCHOR Handle Start Scaling Event
   * @description to handle start scaling event
   */
  private _handleStartScaling(): void {
    this._doFirstStep = true;
    this._utilsService.enableHighlight({
      exhibitAsset: this.editorService.activeArtworkNode,
      enable: false,
      highlightLayer: this.editorService.highlightLayer
    });
    this._createScalingMarker(this.editorService.activeArtworkNode);
    this._setArtworkNodeOpacity(this.editorService.activeArtworkNode, 0.5);

    this.editorService.selectedExhibitAssets.map((node: any) => {
      if(node.id != this.editorService.activeArtworkNode.id){
        this._utilsService.enableHighlight({
          exhibitAsset: node,
          enable: false,
          highlightLayer: this.editorService.highlightLayer
        });
        this._createScalingMarker(node);
        this._setArtworkNodeOpacity(node, 0.5);
      }
    });

  }

  /**
   * * SET ARTWORK OPACITY *
   * ANCHOR Set Artwork Opacity
   * @description to set artwork opacity
   * @param artworkNode : BABYLON.TransformNode -> artwork node
   */
  private _setArtworkNodeOpacity(artworkNode: any, opacity: number): void {
    artworkNode.getChildren().forEach((child) => {
      child.visibility = opacity;
    });
  }


  /**
   * * CREATE SCALING MARKER *
   * ANCHOR Create Scaling Marker
   * @description to create scaling marker
   * @param artworkNode : BABYLON.TransformNode -> artwork node
   */  
	private _createScalingMarker(artworkNode: any): void {
		const marker = this._getMarker(artworkNode);
    const ratio = this._getRatio(artworkNode, marker);
		this._ratio.push(ratio);
	}


  /**
   * * GET ARWORK RATIO *
   * ANCHOR Get Artwork Ratio
   * @description to get artwork ratio
   * @param artworkNode : BABYLON.TransformNode -> artwork node
   * @param marker : BABYLON.Mesh -> mesh marker
   * @returns : ArtworkRatioYX -> ratio
   */
  private _getRatio(artworkNode: any, marker: any): ArtworkRatio {
    if (this._isArtworkObject(artworkNode.id)) {
      return {
        id: artworkNode.id,
        ratioYX: artworkNode.scaling.y / artworkNode.scaling.x,
        ratioZX: artworkNode.scaling.z / artworkNode.scaling.x,
      }
    } else {
      const { realHeight, realWidth } = this._getArtworkDimension(artworkNode);
      marker.scaling.z += 0.01;
      return {
        id: artworkNode.id,
        ratioYX: realHeight / realWidth
      }
    }
  }
  
  
  /**
   * * GET MESH MARKER *
   * ANCHOR Get Mesh Marker
   * @description to get mesh marker
   * @param artworkNode : BABYLON.TransformNode -> artwork node
   * @returns : BABYLON.Mesh -> mesh marker
   */
  private _getMarker(artworkNode: any): any {
    const markerId = `scalingMarker-${artworkNode.id}`;
    const oldMarker = this.editorService.scene.getTransformNodeByName(markerId);
    if (oldMarker) oldMarker.dispose();
    const marker = new BABYLON.TransformNode(markerId, this.editorService.scene);
		marker.id = artworkNode.id.replace('artwork-','scaling-');
		marker.position = artworkNode.position.clone();
		marker.rotation = artworkNode.rotation.clone();
		marker.scaling = artworkNode.scaling.clone();
    marker['startPosition'] = marker.position.clone();
    marker['startScaling'] = marker.scaling.clone();
    const markerMesh = this._createMeshMarker(artworkNode);
    markerMesh.setParent(marker);
    return marker;
  }

  /**
   * * CREATE MESH MARKER *
   * ANCHOR Create Mesh Marker
   * @description to create mesh marker
   * @param artworkNode 
   * @returns 
   */
  private _createMeshMarker(artworkNode: any): any {
    const meshParent = new BABYLON.Mesh("parent", this.editorService.scene);
		artworkNode.getChildMeshes().map((mesh) => {
			const clonedMesh = mesh.clone();
      clonedMesh.setParent(meshParent)
    })

		const childMeshes = meshParent.getChildMeshes();
		let min = childMeshes[0].getBoundingInfo().boundingBox.minimumWorld;
		let max = childMeshes[0].getBoundingInfo().boundingBox.maximumWorld;

		for(let i=0; i<childMeshes.length; i++){
			const meshMin = childMeshes[i].getBoundingInfo().boundingBox.minimumWorld;
			const meshMax = childMeshes[i].getBoundingInfo().boundingBox.maximumWorld;

			min = BABYLON.Vector3.Minimize(min, meshMin);
			max = BABYLON.Vector3.Maximize(max, meshMax);
		}

		meshParent.setBoundingInfo(new BABYLON.BoundingInfo(min, max));
		meshParent.showBoundingBox = true;

    const scalingMarker = this.editorService.scene.getMeshByName('scalingTmp').clone();
    scalingMarker.name = 'scalingMarker';
    scalingMarker.position.copyFrom(meshParent.getBoundingInfo().boundingBox.centerWorld);
    scalingMarker.rotation = artworkNode.rotation.clone();
    scalingMarker.scaling = artworkNode.scaling.clone();
    scalingMarker.enableEdgesRendering();
    scalingMarker.isPickable = false;

    meshParent.dispose();

    return scalingMarker;
  }
  

  /**
   * * GET ARTWORK DIMENSION *
   * ANCHOR Get Artwork Dimension
   * @description to get artwork dimension
   * @param artworkNode : BABYLON.TransformNode -> artwork node
   * @returns : ArtworkDimension -> artwork dimension
   */
  private _getArtworkDimension(artworkNode: any): ArtworkDimension {
    const artworkData = this._getArtworkData(artworkNode.id);
		const realHeight = artworkData.real_height;
		const realWidth = artworkData.real_width;

    return { realHeight, realWidth }
  }

  //#endregion
  // !SECTION


  /**
	 * * ================================================================================================ *
	 *   SECTION Handle On Scaling Event Functions
	 * * ================================================================================================ *
	 */

  //#region

  /**
   * * HANDLE ON SCALING EVENT *
   * ANCHOR Handle On Scaling Event
   * @description : to handle on scaling event
   */
  private _handleOnScaling(): void {
    this._resizeMarker(this._ratio[0]);
    this.editorService.selectedExhibitAssets.map((node: any) => {
      if(node.id != this.editorService.activeArtworkNode.id){
        const ratio = this._ratio.find((x: ArtworkRatio) => x.id == node.id);
        this._resizeMarker(ratio);
      }
    })
  }

  /**
   * * RESIZE MARKER *
   * ANCHOR: Resize Marker
   * @description : to resize marker
   * @param params : ArtworkRatio -> artwork ratio
   */
  private _resizeMarker(artworkRatio: ArtworkRatio): void {
    const realScalingValue = this.scalingValue / 100;
    const markerId = this._getMarkerId(artworkRatio.id);
    const marker = this.editorService.scene.getTransformNodeByName(markerId);
    const { frameWidth, passeWidth } = this._getFrameWidth(artworkRatio.id);
    const height = (realScalingValue - (frameWidth + passeWidth)) * artworkRatio.ratioYX;

    marker.scaling.x = realScalingValue;
    marker.scaling.y = height + (frameWidth + passeWidth);

    if(this._isArtworkObject(artworkRatio.id)) {
      marker.scaling.y = marker.scaling.x * artworkRatio.ratioYX;
      marker.scaling.z = marker.scaling.x * artworkRatio.ratioZX;
      this._repositionMarker(marker);
    }
  }

  /**
   * * REPOSITION MARKER *
   * ANCHOR: Reposition Marker
   * @description : to reposition marker
   * @param artworkRatio : ArtworkRatio -> artwork ratio
   * @param marker : BABYLON.Mesh -> mesh marker
   */
  private _repositionMarker(marker: any): void {
    marker.position = marker['startPosition'].clone();
    const additionalZPos = (marker.scaling.z - marker['startScaling'].z)/2 ;
    marker.translate(
			new BABYLON.Vector3(0, 0, additionalZPos), 
			1/marker.scaling.z, 
			BABYLON.Space.LOCAL
		);
  }

  //#endregion
  // !SECTION


  /**
	 * * ================================================================================================ *
	 *   SECTION Handle End Scaling Event Functions
	 * * ================================================================================================ *
	 */

  //#region 

  /**
   * * GET REAL WIDTH OF ARWORK IMAGE/VIDEO *
   * ANCHOR: Get Real Width of Artwork Image/Video
   * @description: to get real width based on marker dimension
   * @param artworkNodeId : string -> artwork node id
   * @returns : number -> real width
   */
  private _getRealWidth(artworkNodeId: string): number {
    const artworkData = this._getArtworkData(artworkNodeId);

    const { frame, passepartout } = artworkData.frame;
    const frameWidth = frame.frame ? frame.frame_width_right + frame.frame_width_left : 0; 
    const passeWidth = passepartout.passepartout ? passepartout.passepartout_width_left + passepartout.passepartout_width_right : 0;

    const markerId = this._getMarkerId(artworkNodeId);
    const marker = this.editorService.scene.getTransformNodeByName(markerId);
    const realWidth = marker.scaling.x - (frameWidth + passeWidth);
    marker.dispose();
    return realWidth
  }

  
  /**
   * * GET FRAME WIDTH OF ARWORK IMAGE/VIDEO *
   * ANCHOR: Get Frame Width of Artwork Image/Video
   * @description: to get frame width based on marker dimension
   * @param artworkNodeId : string -> artwork node id
   * @returns : number -> frame width, passepartout width
   */
  private _getFrameWidth(artworkNodeId: string): any {
    const artworkData = this._getArtworkData(artworkNodeId);
    const { frame, passepartout } = artworkData.frame;
    const frameWidth = frame.frame ? frame.frame_width_left + frame.frame_width_right : 0; 
    const passeWidth = passepartout.passepartout ? passepartout.passepartout_width_left + passepartout.passepartout_width_right : 0;

    return { frameWidth, passeWidth }
  }

  /**
   * * GET ARTWORK DATA BY ARTWORK NODE ID *
   * ANCHOR: Get Artwork Data By Artwork Node Id
   * @description: to get artwork data by artwork node id
   * @param artworkNodeId : string -> artwork node id
   * @returns : Artwork -> artwork data
   */
  private _getArtworkData(artworkNodeId: string): Artwork {
    return this.editorService.artworks.find((artwork: Artwork) => {
      return artwork.id === artworkNodeId.replace('artwork-','')
    });
  }

  /**
   * * RESIZE "REAL WIDTH" & "REAL HEIGH" ARTWORK *
   * ANCHOR: Resize "Real Width" & "Real Height" Artwork
   * @description: to resize "real width" & "real height" artwork
   * @param artworkNodeId : string -> artwork node id
   */
  private _resizeArtworkImageVideo(artworkNodeId: string ): void {
    const realWidth = this._getRealWidth(artworkNodeId);
    const artworkData = this._getArtworkData(artworkNodeId);
    const { ratioYX } = this._ratio.find((x: ArtworkRatio) => x.id == artworkNodeId);
    artworkData.real_width = realWidth;
		artworkData.real_height = realWidth * ratioYX;
  }

  /**
   * * RESIZE ARTWORK OBJECT *
   * ANCHOR: Resize Artwork Object
   * @description: to resize artwork object
   * @param artworkNodeId : string -> artwork node id
   */
  private _resizeArtworkObject(artworkNodeId: string): void {
    const artworkNode = this.editorService.scene.getTransformNodeByID(artworkNodeId);
    const markerId = this._getMarkerId(artworkNodeId);
    const marker = this.editorService.scene.getTransformNodeByName(markerId);
    artworkNode.scaling = marker.scaling.clone();
    artworkNode.position = marker.position.clone();
    marker.dispose();
  }

  /**
   * * RECREATE ARTWORK NODE *
   * ANCHOR: Recreate Artwork Node
   * @description: to recreate artwork node
   * @param artworkNodeId : string -> artwork node id
   * @returns : BABYLON.TransformNode -> new artwork node
   */
  private async _recreateArtworkNode(artworkNodeId: string): Promise<any> {
    const { donut, metadata } = this._getMetadataAndDonut(artworkNodeId);
    const artworkData = this._getArtworkData(artworkNodeId);
    const newArtworkNode = await this.editorService.createArtworkImageVideo(artworkData);
    newArtworkNode['donut'] = donut;
    newArtworkNode['donutHasInitialized'] = true;
    newArtworkNode['metadata'] = metadata;
    this._artworkShadowsService.addShadowCaster(newArtworkNode);
    this._artworkShadowsService.setShadowCastPosition(newArtworkNode);
    this._artworkShadowsService.setShadowComponentLightsPosition(newArtworkNode);
    return newArtworkNode;
  }


  /**
   * ANCHOR: Get Metadata and Donut
   * @description: to get metadata and donut
   * @param artworkNodeId : number -> artwork node id
   * @returns : any -> metadata and donut
   */
  private _getMetadataAndDonut(artworkNodeId: string): any {
    const artworkNode = this.editorService.scene.getTransformNodeByID(artworkNodeId);
    const metadata = artworkNode['metadata'];
    const donut = artworkNode['donut'];
    this._artworkShadowsService.setEnableShadowCast(artworkNode, false);
    this._artworkShadowsService.setEnableShadowComponents(artworkNode, false);
    artworkNode.dispose();
    return { metadata, donut };
  }

  /**
   * * HANDLE END SCALING EVENT *
   * ANCHOR: Handle End Scaling Event
   * @description: to handle end scaling event
   */
  private async _handleEndScaling(): Promise<void> {
    const newNodes: any[] = [];
    if(this._isArtworkObject(this.editorService.activeArtworkNode.id)) {
      const artworkNode = this._updateArtworkObject(this.editorService.activeArtworkNode);
      this.editorService.activeArtworkNode = artworkNode;
      newNodes.push(artworkNode)
    } else {
      const artworkNode = await this._updateArtworkImageVideo(this.editorService.activeArtworkNode);
      this.editorService.activeArtworkNode = artworkNode;
      newNodes.push(artworkNode);
    }

    if (this.editorService.selectedExhibitAssets.length === 1) {
      this._artworkService.selectArtwork(this.editorService.activeArtwork)
    }

    this.editorService.markForUpdate(this.editorService.activeArtwork, 'shape');

    await Promise.all(this.editorService.selectedExhibitAssets.map(async (node: any) => {
      if(node.id != this.editorService.activeArtworkNode.id){
        if(this._isArtworkObject(node.id)) {
          const artworkNode = this._updateArtworkObject(node);
          newNodes.push(artworkNode);
          this.editorService.markForUpdate(artworkNode.metadata.artworkData, 'shape');
        } else {
          const artworkNode = await this._updateArtworkImageVideo(node);
          newNodes.push(artworkNode);
          this.editorService.markForUpdate(artworkNode.metadata.artworkData, 'shape');
        }
      } 
    }));


    this.editorService.selectedExhibitAssets = newNodes;
    this.editorService.selectedExhibitAssets.map((node)=>{
      this._utilsService.enableHighlight({
        exhibitAsset: node,
        enable: true,
        highlightLayer: this.editorService.highlightLayer
      });
    })

    this._ratio = [];
    this._updateActivityLog()
    this.editorService.artworkNodes = this.editorService.artworkNodes.filter((artworkNode:any)=> !artworkNode.isDisposed());
    this.editorService.dataHasChanges = true;
    this.editorService.updateUndoRedoState();

    this._doFirstStep = false;
  }

  /**
   * * UPDATE ARTWORK OBJECT DATA *
   * ANCHOR: Update Artwork Object Data
   * @description: to update artwork object data
   * @param node : BABYLON.TransformNode -> artwork node
   */
  private _updateArtworkObject(node: any): any {
    this._resizeArtworkObject(node.id);
    this._saveArtworkObjectScaling(node);
    this._setArtworkNodeOpacity(node, 1);
    return node;
  }

  /**
   * * UPDATE ARTWORK IMAGE VIDEO DATA *
   * ANCHOR: Update Artwork Image Video Data
   * @param node: BABYLON.TransformNode -> artwork node
   * @returns : BABYLON.TransformNode -> artwork node
   */
  private async _updateArtworkImageVideo(node: any): Promise<any> {
    this._resizeArtworkImageVideo(node.id);
    return await this._recreateArtworkNode(node.id);
  }

  /**
   * * SAVE ARTWORK OBJECT SCALING *
   * ANCHOR: Save Artwork Object Scaling
   * @description: to save artwork object scaling
   * @param artworkNode : BABYLON.TransformNode -> artwork node
   */
  private _saveArtworkObjectScaling(artworkNode: any): void {
    const artworkData = this._getArtworkData(artworkNode.id);
    artworkData.scaling = {
      scaling_x: artworkNode.scaling.x,
      scaling_y: artworkNode.scaling.y,
      scaling_z: artworkNode.scaling.z
    }
  }

  /**
   * * UPDATE ACTIVITY LOG *
   * ANCHOR: Update Activity Log
   * @description: to update activity log
   */
  private _updateActivityLog(): void {
    if(this.editorService.selectedExhibitAssets.length > 1){
      this.editorService.updateLogActivity('Update multi artwork scaling')
    }else{
      this.editorService.updateLogActivity('Update artwork scaling')
    }
  }

  //#endregion
  // !SECTION


  /**
	 * * ================================================================================================ *
	 *   SECTION Uncategorized Functions
	 * * ================================================================================================ *
	 */

  //#region

  /**
   * * IS ARTWORK OBJECT *
   * ANCHOR Is Artwork Object
   * @description to check if artwork is object
   * @param artworkNodeId : BABYLON.TransformNode -> artwork node
   * @returns: boolean 
   */
  private _isArtworkObject(artworkNodeId: string): boolean {
    const artworkNode = this.editorService.scene.getTransformNodeByID(artworkNodeId.toString());
    return artworkNode.artworkType === 'figure-object';
  }

  /**
   * * INITIAL SCALING VALUES *
   * ANCHOR Initial Scaling Values
   * @description to set initial scaling values such as min scaling and scaling value
   */
  private _initialScalingValues(): void {
    this.minScaling = this._calculateMinScaling();
    this.scalingValue = Math.round(this.editorService.activeArtworkNode.scaling.x * 100);
  }
  

  /**
   * * WATCH SELECTED OBJECT STATE *
   * ANCHOR Watch Selected Object State
   * @description to watch selected object state from redux store
   */
  private _watchSelectedObjectState(): void {
    const seleceObjectWatch = watch(store.getState, 'editor.objectHasSelected')
		this._selectObjectWatchSubscription = store.subscribe(seleceObjectWatch((e: boolean) => {
      if (e && this.editorService.activeArtworkNode) {
        this._initialScalingValues();
      }
		}));
  }

  /**
   * * CACULATE MIN SCALING *
   * ANCHOR Calculate Min Scaling
   * @description to calculate min scaling
   * @returns : number -> min scaling
   */
  private _calculateMinScaling(): number {
		let	 minScaling = 0.05;
    const type = this.editorService.activeArtwork.file_type;
    if(type !== 'figure-object') {
      const { frame, passepartout } = this.editorService.activeArtwork.frame;
      const frameWidth = frame.frame_width_left + frame.frame_width_right;
      const passepartoutWidth = passepartout.passepartout_width_right + passepartout.passepartout_width_left;
      if (frame.frame) minScaling += frameWidth;
      if (passepartout.passepartout) minScaling += passepartoutWidth;
    };

		return minScaling * 100;
  }

  /**
   * * SCALING ARTWORK VIA INPUT *
   * ANCHOR Scaling Artwork Via Input
   * @description to scale artwork via input
   * @param value : number -> scaling value
   */
  public updateScalingViaInput(value: number): void {
    this.scalingValue = this.editorService.validateSliderManualInput(value, this.minScaling, 500);
    this.scalingArtwork('start-scaling');
    this.scalingArtwork('on-scaling');
    this.scalingArtwork('end-scaling');
  }
  
  /**
   * * GET SCALING MARKER ID *
   * ANCHOR Get Scaling Marker Id
   * @description to get scaling marker id
   * @param artworkNodeId : string -> artwork node id
   * @returns : string -> scaling marker id
   */
  private _getMarkerId(artworkNodeId: string): string {
    return `scalingMarker-${artworkNodeId}`;
  }

  /**
	 * * SCALING ARTWRORK *
   * ANCHOR Scaling Artwork
   * @description to scale artwork
	 */
	public scalingArtwork(event: 'start-scaling' | 'on-scaling' | 'end-scaling'): void {
		switch(event){
			case 'start-scaling': this._handleStartScaling(); break;
			case 'on-scaling': this._handleOnScaling(); break;
			case 'end-scaling': this._handleEndScaling(); break;
		}
	}

  /**
   * * HANDLE ON CHANGE SLIDER *
   * ANCHOR Handle On Change Slider
   */
  public handleOnChangeSlider(): void {
    if (this.editorService.onInput) return;

    if (this._doFirstStep) {
      this.scalingArtwork('on-scaling');      
    } else {
      this.scalingArtwork('start-scaling');
      this.scalingArtwork('on-scaling');
      this.scalingArtwork('end-scaling');
    }
  }

  //#endregion
  //!SECTION
}
