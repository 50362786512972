<div class="form-group side-by-side mb25" [ngStyle]="{'margin-top.px': -1}">
  <label>Color</label>
  <input-color
    [inline]="false"
    (onFocus)="editorService.onInput=true"
    (onBlur)="editorService.onInput=false"
    (onHide)="hideColorOverlay = true;mainService.userInfo.user_details[0].fav_colors=$event"
    (onShow)="hideColorOverlay = false"
    [hideColorOverlay]="hideColorOverlay"
    [disabled]="editorService.blockUserAction"
    (onChange)="textLoader.activeText.color=$event;editColorText()"
    [favoriteColor]="mainService.userInfo.user_details[0].fav_colors"
    [color]="textLoader.activeText.color">
  </input-color>
</div>