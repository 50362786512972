import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Router } from '@angular/router';
import { FirstUpdateUserParams } from 'src/app/shared/interfaces';
import { MainService } from "src/app/shared/services";

import moment from 'moment';
import { AlertMessageService } from 'src/app/components/alert-message/alert-message.service';
import { ProfileService } from '../profile.service';
import { environment } from '@environments';
import { SafeUrl } from '@angular/platform-browser';
import { InputDateComponent } from '../../../components/input-date/input-date.component';
import { NgIf } from '@angular/common';
import { LayoutsComponent } from '../../../layouts/layouts.component';

@Component({
    selector: 'app-user-update',
    templateUrl: './user-update.component.html',
    styleUrls: ['./user-update.component.scss'],
    standalone: true,
    imports: [
        LayoutsComponent,
        NgIf,
        FormsModule,
        ReactiveFormsModule,
        InputDateComponent,
    ],
})
export class UserUpdateComponent implements OnInit {
	public loading: boolean = false;
	public infoUser:any = null;
  public file: any = null;
  public birthDate: string;
  public birth_date = moment().format('DD');
  public birth_month = moment().format('MM');
  public birth_year = moment().format('YYYY');
  public usernameNotAvailable: boolean = false;
  public birthDateInvalid: boolean = false;
  public availableRoutes: any;
	public form: FormGroup;
  public setDefaultImg: SafeUrl = ""
  public dataInvalid: boolean = false; 
  public subscribeEmail: boolean = false;
  public useMetric: boolean = true;

	constructor(
    public mainService:MainService,
    private profileService: ProfileService,
    private router:Router,
    private alertMessageService:AlertMessageService,
  ){
    this.setDefaultImg = this.mainService.sanitize(environment.staticAssets+'images/other/default-avatar.png?t='+this.mainService.appVersion);
  }

	ngOnInit(): void {
    if(this.mainService.isBrowser) {
      this.infoUser = this.mainService.userInfo;
      if (this.infoUser) {
        this.setFormValues();
        this._setBirthDateData();
      }
      this.getAvailableRoutes();
    }
  }
  

  /**
   * * SET FORM VALUES *
   * Todo: to set form values
   */
  setFormValues(): void {
    this.form = new FormGroup({
      avatar: new FormControl(),
      username: new FormControl(
        this.infoUser.username, 
        [ 
          Validators.required,
          Validators.maxLength(40),
          Validators.pattern('[a-z0-9_.]*')
        ]
      ),
      language: new FormControl('en'),
      male: new FormControl(true),
      female: new FormControl(false),
      nonBinary: new FormControl(false)
    });
  }

  getAvailableRoutes() {
    this.availableRoutes = this.router.config.map((config: any) => config.path);
  }

  /**
   * * GET SELECTED GENDER *
   * Todo: to getting selected gender 
   */
  getSelectedGender(): string {
    let gender;
    if (this.form.value.male) gender = 'male';
    else if (this.form.value.female) gender = 'female';
    else if (this.form.value.nonBinary) gender = 'non-binary';
    else gender = '';

    return gender;
  }

  /**
   * * CREATE PARAMS 'UPDATE USER' *
   * Todo: to create params for API 'Update Param'
   */
  createParams(): FirstUpdateUserParams {
    const gender = this.getSelectedGender();
    return {
      gender: gender,
      username: this.form.value.username,
      birth_date: this.birthDate,
      latest_news: this.subscribeEmail,
      measurement: this.useMetric ? 'metric':'imperial'
    }
  }

  /**
   * * CHECK USERNAME *
   * Todo: to check if user name already exists
   */
  checkUsername(username: string): any {
    return new Promise((resolve, reject) => {
      this.mainService.checkUsername(username).subscribe((response) => {
        this.usernameNotAvailable = false;
        resolve(response);
      },err => {
        const code = err.error.statusCode;
        switch (code) {
          // Username already exists
          case 409:
            this.usernameNotAvailable = true;
            this.alertMessageService.add({
              severity:"warn",
              summary:"Warning",
              detail:"Username already exists"
            })
          break;

          // Token expired
          case 401:
            this.mainService.expiredSesionPopup = true;
          break;

          // Other errors
          default:
            this.alertMessageService.add({
              severity:"error", 
              summary:"Error", 
              detail:"Something went wrong. Please try again."
            })
          break;
        }

        reject(err);
      });
    })
  }

	/**
   * * UPDATE USER PROFILE *
   * Todo: to updating user profile
   */
  async updateProfile(): Promise<void> {
    if (!this.dataInvalid) {
      this.loading = true;
      const params = this.createParams();
      try { await this.checkUsername(params.username) } 
      catch (error) { this.loading = false }
      if (this.usernameNotAvailable) return;
      this.profileService.updateProfileUser(params).subscribe((response: any) => {
        if (this.file) this.uploadAvatar();
        else this.handleUpdateSuccess();
      }, (err: any) => {
        this.loading = false;
        const code = err.error.statusCode;
        switch (code) {
          case 401:
            this.mainService.expiredSesionPopup = true;
          break;
          default:
            this.alertMessageService.add({
              severity:"error", 
              summary:"Error", 
              detail:"Something went wrong. Please try again."
            })
          break;
        }
      })
    }
  }

  /**
   * * UPLOAD AVATAR *
   * Todo: to upload avatar
   */
  uploadAvatar(): void {
    const formData: FormData = new FormData();
    formData.append('file', this.file);
    this.mainService.uploadAvatar(formData).subscribe((res: any) => {
      this.handleUpdateSuccess();
    }, err =>{
      this.loading = false;
      this.alertMessageService.add({ severity: 'error', summary: 'Failed', detail:  'Failed to update avatar!' });
    });
  }

  /**
   * * VALIDATE DATA *
   * Todo: to validate data
   */
  validateData(): void {
    this.dataInvalid = this.form.invalid || this.usernameNotAvailable || this.birthDateInvalid
  }

  /**
   * * VALIDATE BIRTH DATE *
   * Todo: to validate birth date
   */
  validateBirthDate(birth): void {
    // Create birth date and current date moment object
    const currentDate = moment(moment().format("YYYY-MM-DD"));
    const birthDate = moment(birth.date, "YYYY-MM-DD");

    // Check whether the birth date is valid or not
    this.birthDateInvalid = birthDate.isAfter(currentDate);
	}

  /**
   * * SET AVATAR USER *
   * Todo: to openig cropper dialog
   */
  setAvatar(event): void{
    this.file = event.target.files[0];
    if (this.file) {
      if(this.validateImage(this.file)) {
        this.setDefaultImg = this.mainService.sanitize(URL.createObjectURL(this.file));
      } else {
        this.file = null;
      }
    }
    event.target.value = ""
  }

  /**
   * * SET GENDER *
   * Todo: to set gender image
   */
  setGender(value): void {
    switch (value) {
      case 'male':
        this.form.controls['male'].setValue(true);
        this.form.controls['female'].setValue(false);
        this.form.controls['nonBinary'].setValue(false);
      break;
      case 'female':
        this.form.controls['male'].setValue(false);
        this.form.controls['female'].setValue(true);
        this.form.controls['nonBinary'].setValue(false);
      break;
      case 'nonBinary':
        this.form.controls['male'].setValue(false);
        this.form.controls['female'].setValue(false);
        this.form.controls['nonBinary'].setValue(true);
      break;
    }
  }

  /**
   * ANCHOR Set Birth Date Data
   * @description Set the birth date data
   */
  private _setBirthDateData(){
    this.infoUser.birth_date = this.infoUser.birth_date || moment().format("YYYY-MM-DD");
    const birthDate = moment(this.infoUser.birth_date,"YYYY-MM-DD");
    this.birth_date = birthDate.format("DD");
    this.birth_month = birthDate.format("MM");
    this.birth_year = birthDate.format("YYYY");
    this.birthDate = this.infoUser.birth_date;
  }

  /**
   * * VALIDATE IMAGE *
   * Todo: to validating image
   */
  validateImage(file): boolean {
    if (file) {
      // Get file extension & set allowed file extension
      const extension = file.name.split(".").slice(-1)[0].toLowerCase();
      const allowedExtention = ['png','jpeg','jpg'];
      // Validate file extension 
      if(allowedExtention.includes(extension)){
        // Validate file size
        if(file.size  / 1024 / 1024 <= 2) return true;
        else{
          this.alertMessageService.add({severity:"warn", summary:"Warning", detail:"The file size is too large. Please only upload files less than 2 MB for stable performance."});
          return false;
        }
      }else{
        this.alertMessageService.add({severity:"warn", summary:"Warning", detail:"This file format is not supported. Please upload .png, or .jpeg"});
        return false;
      }	
    }else{
      return false;
    }
  }

  /**
   * * HANDLE UPDATE SUCCESS *
   * Todo: to handle update sucess
   */
  handleUpdateSuccess() {
    localStorage.removeItem('loginFrom');
    localStorage.removeItem('productName');
    localStorage.removeItem('paymentType');
    this.router.navigate(["/"]);
    this.mainService.createExhibitionWidget = true;
    this.mainService.userInfo.username = this.form.value.username;
  }

  /**
   * * CHANGE DATE *
   * Todo: to change date
   */
  changeDate(e): void{
    this.validateBirthDate(e);
    this.validateData();
    if(e.isvalid) {
      this.birthDate = e.date
      let birth_day = moment(this.birthDate,"YYYY-MM-DD");
      this.birth_date = birth_day.format("DD");
      this.birth_month = birth_day.format("MM");
      this.birth_year = birth_day.format("YYYY");
    };
  }
}
