import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, switchMap} from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class DataInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      switchMap(async (event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          let modifiedBody = { ...event.body };
          
          const isArray = Array.isArray(event.body);
          if (!isArray) {
            if (modifiedBody.data?.cry) {
              const decryptedData = await this._decryptResponse(modifiedBody.data);
              modifiedBody.data = decryptedData;
            }
            
            const modifiedEvent = event.clone({ body: modifiedBody });
            return modifiedEvent;
          }
        }
        return event;
      }),
      catchError(async(error: HttpErrorResponse) => {
        let modifiedError = { ...error };
        const isArray = Array.isArray(error.error);
        if (!isArray) {
          if (modifiedError.error.data?.cry) {
            const decryptedData = await this._decryptResponse(modifiedError.error.data);
            modifiedError.error.data = decryptedData;
          }
        }
        throw (modifiedError);
      })
    );    
  }

  private _getPassphrase(data:any): Promise<any> {
    return new Promise((resolve, reject) => {
      const pattern = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/;
      let lastLogin = data.ls;
      const matches = lastLogin.match(pattern);
      const yearnmonth = matches[1] + matches[2];
      const day = matches[3];
      const hours = matches[4];
      const minutes = matches[5];
      const seconds = matches[6];
      const formula = (hours * day ^ 3 / 3) + (minutes * day ^ 2 / 2) + (seconds * day) + parseInt(yearnmonth);
      const encrypt = data.e4;
      const passphrase = encrypt.slice(0, 5) + formula + encrypt.slice(5);
      resolve(passphrase);
    })
  }

  private _decryptResponse(data:any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const passphrase = await this._getPassphrase(data);
      let objres = data.cry;
      const obj_json = {
          "ciphertext": objres.c1p,
          "iv": objres.f0ur,
          "salt": objres.su1fur
      }

      const encrypted = obj_json.ciphertext;
      const salt = CryptoJS.enc.Hex.parse(obj_json.salt);
      const iv = CryptoJS.enc.Hex.parse(obj_json.iv);

      const key = CryptoJS.PBKDF2(passphrase, salt, { hasher: CryptoJS.algo.SHA512, keySize: 64 / 8, iterations: 999 });

      const decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv: iv });
      let result = decrypted.toString(CryptoJS.enc.Utf8);
      resolve(JSON.parse(result));
    })
  }
}
