// Common Angular modules/components
import { Injectable } from '@angular/core';
import { OrdinaryObject } from '@interfaces';
import { EditorService } from '../editor.service';

// Third party plugins CDN
declare const BABYLON: any;

// User-defined interfaces
import { LoadOrdinaryObjectCallbacks } from './objects.interfaces';

@Injectable({
  providedIn: 'root'
})
export class ObjectsService {
	public loadedOrdinaryObjects: string[] = [];

  constructor(
    private _editorService: EditorService,
  ) { }

  /**
	 * * ================================================================================================ *
	 *   SECTION Load Ordinary Object Functions
	 * * ================================================================================================ *
	 */

	//#region 
	/**
	 * ANCHOR Load Ordinary Object
	 * @description : to load ordinary object
   * @param objectData : OrdinaryObject
   * @param callbacks : LoadOrdinaryObjectCallbacks
	 */
	public loadOrdinaryObject(objectData: OrdinaryObject, callbacks: LoadOrdinaryObjectCallbacks): void {
    const { onLoaded, onLoad, onError } = callbacks;
		BABYLON.SceneLoader.ImportMeshAsync('', '', objectData.model_path, this._editorService.scene, onLoad).then((res: any) => {
			const ordinaryObject = this._createOrdinaryObjectWrapper(objectData)

			let rootMesh: any = null;
			res.meshes.map((mesh: any) => {
				if (mesh.name != "__root__") {
					mesh.setParent(ordinaryObject);
					this._setLighting(objectData, mesh);
          if (this._editorService.glowEffect) this._editorService.glowEffect.addIncludedOnlyMesh(mesh);
				} else {
					rootMesh = mesh;
				}
			})
			rootMesh?.dispose();

      this._setOrdinaryObjectTransform(objectData, ordinaryObject);
			this._editorService.ordinaryObjectsNodes.push(ordinaryObject);
			this.loadedOrdinaryObjects.push(objectData.id)
			onLoaded(ordinaryObject)
		}).catch((err)=>{
			onError(err)
		})
	}

  /**
   * ANCHOR Set Lighting
   * @description : to set lighting for ordinary object
   * @param ordinaryObject : OrdinaryObject
   * @param mesh : BABYLON.Mesh
   */
  private _setLighting(ordinaryObject: OrdinaryObject, mesh: any): void {
		this._editorService.artworkLighting.excludedMeshes.push(mesh);
		mesh.material.environmentIntensity = ordinaryObject.light_intensity / 2;
  }

  /**
   * ANCHOR Create Ordinary Object Wrapper
   * @description : to create ordinary object wrapper
   * @param ordinaryObject : OrdinaryObject
   * @returns : BABYLON.TransformNode
   */
  private _createOrdinaryObjectWrapper(ordinaryObject: OrdinaryObject): any {
    const wrapper = new BABYLON.TransformNode("ordinaryObject", this._editorService.scene);
		wrapper.id = `ordinaryObject-${ordinaryObject.id}`;
		wrapper['isMove'] = false;
    return wrapper;
  }

  /**
   * ANCHOR Set Ordinary Object Transform
   * @description : to set ordinary object transform
   * @param ordinaryObject : OrdinaryObject
   * @param node : BABYLON.TransformNode
   */
  private _setOrdinaryObjectTransform(ordinaryObject: OrdinaryObject, node: any): void {
    node.position = new BABYLON.Vector3(
      ordinaryObject.position.position_x,
      ordinaryObject.position.position_y,
      ordinaryObject.position.position_z
    );
    node.rotation = new BABYLON.Vector3(
      ordinaryObject.rotation.rotation_x,
      ordinaryObject.rotation.rotation_y,
      ordinaryObject.rotation.rotation_z
    );
    node.scaling = new BABYLON.Vector3(
      ordinaryObject.scaling.scaling_x,
      ordinaryObject.scaling.scaling_y,
      ordinaryObject.scaling.scaling_z
    );
  }

	//#endregion
	// !SECTION


  /**
	 * * ================================================================================================ *
	 *   SECTION Adjust Ordinary Object Lighting Functions
	 * * ================================================================================================ *
	 */

  //#region 

  
  /**
   * ANCHOR Adjust Ordinary Object Lighting
   * @description : to adjust ordinary object lighting
   * @param ordinaryObject : OrdinaryObject
   */
  public adjustLighting(ordinaryObject: OrdinaryObject): void {
    const ordinaryObjectNode = this._editorService.ordinaryObjectsNodes.find((ordinaryObjectNode: any) => {
      return ordinaryObjectNode.id === `ordinaryObject-${ordinaryObject.id}`;
    });

    if (ordinaryObjectNode) {
      ordinaryObjectNode.getChildren().forEach((mesh: any) => {
          mesh.material.environmentIntensity = ordinaryObject.light_intensity / 2;
      })
    }
  }

  /**
   * ANCHOR Adjust Lighting For All Ordinary Objects
   * @description : to adjust lighting for all ordinary objects
   * @param intensity : number
   */
  public adjustLightingForAll(intensity): void {
    this._editorService.ordinaryObjects.forEach((ordinaryObject: OrdinaryObject) => {
      ordinaryObject.light_intensity = intensity;
      this.adjustLighting(ordinaryObject);
    })
  }

  //#endregion
  // !SECTION


}
