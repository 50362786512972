import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { EditorService } from '../editor.service';
import { MainService, UtilsService } from 'src/app/shared/services';
import { AlertMessageService } from 'src/app/components/alert-message/alert-message.service';
import { TextWall } from 'src/app/shared/interfaces';
import { messages } from '@data';
import { LoadingGalleryService } from 'src/app/components/loading-gallery/loading-gallery.service';
import { UndoRedoService } from 'src/app/shared/services/undo-redo.service';
import { DialogModule } from 'primeng/dialog';
import { PrimeTemplate } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { SliderModule } from 'primeng/slider';
import { InputNumberComponent as InputNumberComponent_1 } from '../../../../components/input-number/input-number.component';
import { InputColor } from '../../../../components/input-color/input-color.component';
import { NgIf, NgFor, NgStyle } from '@angular/common';
import { TextListComponent } from './text-list/text-list.component';
import { TextEditorComponent } from './text-editor/text-editor.component';
import { TextService } from './service/text-service.service';
import { TextCreateComponent } from "./text-create/text-create.component";
import { TextLoaderService } from './service/text-loader.service';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    FormsModule,
    ReactiveFormsModule,
    NgStyle,
    InputColor,
    InputNumberComponent_1,
    SliderModule,
    DropdownModule,
    PrimeTemplate,
    DialogModule,
    TextEditorComponent,
    TextListComponent,
    TextCreateComponent
]
})
export class TextComponent {

  constructor(
    public editorService: EditorService,
    public alertMessageService: AlertMessageService,
    public mainService: MainService,
    private _utilsService: UtilsService,
    public loadingGalleryService: LoadingGalleryService,
    public _undoRedoService: UndoRedoService,
    public textService: TextService,
    public textLoader: TextLoaderService
  ) {}


  public selectedOvarlapingTextIds: string[] = [];
  public notSelectedOvarlapingTextIds: string[] = [];

  /**
   * * SELECT OVERLAPING TEXTS *
   * ANCHOR Select Overlaping Texts
   * @description to select overlaping texts
   * @param text : TextWall
   */
  public selectOverlapingTexts(id: string): void {
    if (!this.editorService.selectMultiOverlappingTexts) {
      this.selectedOvarlapingTextIds = [];
    }

    if (!this.isSelectedOverlapingText(id)) {
      this.selectedOvarlapingTextIds.push(id);
      this.notSelectedOvarlapingTextIds = this.notSelectedOvarlapingTextIds.filter(
        textId => textId !== id
      );
    } else {
      this.selectedOvarlapingTextIds = this.selectedOvarlapingTextIds.filter(
        textId => textId !== id
      );
      this.notSelectedOvarlapingTextIds.push(id);
    }
  }

  /**
   * * IS SELECTED OVERLAPING TEXT *
   * ANCHOR Is Selected Overlaping Text
   * @description to check is selected overlaping text
   * @param id : string
   * @returns : boolean
   */
  public isSelectedOverlapingText(id: string): boolean {
    return this.selectedOvarlapingTextIds.includes(id);
  }

  /**
   * * SET ACTIVE SELECTED OVERLAPING TEXTS *
   * ANCHOR Set Active Selected Overlaping Texts
   * @description to set active selected overlaping texts
   */
  public setActiveSelectedOverlapingTexts(): void {
    if (this.editorService.selectMultiOverlappingTexts) {
      const selectedNodeIds = this.editorService.selectedExhibitAssets.map((node) => node.id.replace('textWall-', ''));
      this.selectedOvarlapingTextIds.forEach(textId => {
        if (!selectedNodeIds.includes(textId)) {
          const textWallNode = this._getTextWallNode(textId);
          this.editorService.selectMultiExhibitAssets(textWallNode);
        }
      });
      this.notSelectedOvarlapingTextIds.forEach(textId => {
        if (selectedNodeIds.includes(textId)) {
          const textWallNode = this._getTextWallNode(textId);
          this.editorService.selectMultiExhibitAssets(textWallNode);
        } else {
          const textWallNode = this._getTextWallNode(textId);
          this._utilsService.enableHighlight({
            exhibitAsset: textWallNode,
            enable: false,
            highlightLayer: this.editorService.highlightLayer
          });
        }
      });
    } else {
      const textWallNode = this._getTextWallNode(this.selectedOvarlapingTextIds[0]);
      this.editorService.selectExhibitAsset(textWallNode);
    }

    this.editorService.displaySelectOverlappingTexts = false;
  }

  /**
   * * GET TEXT WALL NODE *
   * ANCHOR Get Text Wall Node
   * @description to get text wall node
   * @param id : string
   * @returns : TransformNode
   */
  private _getTextWallNode(id: string) {
    return this.editorService.scene.getTransformNodeByID('textWall-' + id);
  }

  /**
   * * ON HIDE DISPLAY SELECT OVERLAPING TEXTS *
   * ANCHOR On Hide Display Select Overlaping Texts
   * @description to handle on hide display select overlaping texts event
   */
  public onHideDisplaySelectOverlappingTexts(): void {
    if (!this.editorService.selectMultiOverlappingTexts) {
      const wraper = [...this.editorService.selectedExhibitAssets];
      this.editorService.selectedExhibitAssets = [];
      wraper.forEach((node) => {
        this.editorService.selectMultiExhibitAssets(node);
      })
    }

    if (
      this.editorService.selectedExhibitAssets.length == 1 &&
      !this.editorService.observables['moveExhibitAssetByDragObs']
    ) {
      this.editorService.moveExhibitAssetByDragObs();
    }

    this.editorService.selectMultiOverlappingTexts = false;
    this.selectedOvarlapingTextIds = [];
    this.editorService.overlapingTextWalls = [];
  }

  /**
   * * ON SHOW DISPLAY SELECT OVERLAPING TEXTS *
   * ANCHOR On Show Display Select Overlaping Texts
   * @description to handle on show display select overlaping texts event
   */
  public onShowDisplaySelectOverlappingTexts(): void {
    if (this.editorService.selectMultiOverlappingTexts) {
      const selectedNodeIds = this.editorService.selectedExhibitAssets.map((node) => node.id.replace('textWall-', ''));
      const ovarlapingTextIds = this.editorService.overlapingTextWalls.map((text: TextWall) => text.id);
      ovarlapingTextIds.forEach((textId) => {
        if (selectedNodeIds.includes(textId)) {
          this.selectedOvarlapingTextIds.push(textId);
        } else {
          this.notSelectedOvarlapingTextIds.push(textId);
        }
      })
    }
  }
}
