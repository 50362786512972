@import '../../../../../assets/scss/common/variables';

.general {
    $self: &;

    &__feature {
        display: flex;
        justify-content: flex-end;
    }

    &__list {
        padding-left: 0;
        margin-bottom: 0;
    }

    &__item {
        list-style: none;
        float: left;
        margin: 10px 7px 9px 0;

        &:last-child {
            margin-right: 0;
        }
    }
    
    &__btn {
        background-color: transparent;
        padding: 10px 0 0;
        border: 0;
        width: 80px;
        height: 80px;
        font-size: 11px;
        line-height: 120%;
        color: $color-gray2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        &:hover {
            color: $color-gray3;
            background: #202020;

            #{ $self }__icon {
                color: $color-gray3;
            }
        }

        &:active {
            background: $color-dark1;
            color: $color-gray3;
        }
    }

    &__axis {
        display: block;
        margin: 0 auto;
        height: 30px;

        &--xy {
            path {
                fill: $color-primary;
            }
        }
    }

    &__icon {
        display: block;
        color: $color-gray1;
        font-size: 18px;
        margin-bottom: 7px;
        margin-top: 5px;
    }

    .adjusment {
        margin: 0px -20px 15px;
        padding: 10px 20px 1px;
        background-color: $color-dark4;

        &__title {
            text-align: center;
            font-size: 16px;
            color: $color-gray3;
            margin: 0;
            padding: 0 0 7px;
        }

        &__label {
            margin: 5px 0 0 10px;
        }
    }
}

.close-popup {
    position: absolute;
    right: 17px;
    top: 16px;

    button {
        background: transparent;
        border: none;
        padding: 0;

        i {
            color: #c4c4c4;
            font-size: 16px;
        }
    }
}