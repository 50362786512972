import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments';
import { MainService } from 'src/app/shared/services';
import { GetUsersParams } from './support.interfaces';

@Injectable({
	providedIn: 'root'
})
export class SupportService {

	public item: number = 8;

	constructor(
		private http: HttpClient,
		public mainService: MainService
	) { }

	// ======================== //
	// Get User List
	// ======================= //
	getUserProfile(token, page,) {
		const options = {
			headers: new HttpHeaders({
				'accept': 'application/json',
				'authorization': token
			}),
		};

		return this.http.get(`${environment.baseURL}/support/list-users?limit=${this.item}&page=${page}`, options);
	}

	// ======================== //
	// Search User List
	// ======================= //
	searchByFilter(page, filter, search) {
		return this.http.get(`${environment.baseURL}/support/list-users?limit=${this.item}&page=${page}&offset=0&${filter}=${search}`);
	}

	getUsers(params: GetUsersParams){
		const options: any = {params};

		return this.http.get(`${environment.baseURL}/support/list-users`, options);
	}

	loginAsUser(params) {
        return this.http.post(`${environment.baseURL}/support/auth-user`, params);
    }
}
