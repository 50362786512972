import { Component, OnInit, Input } from '@angular/core';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss'],
    animations: [
        trigger('slideUp', [
            state('void', style({
                height: '*'
            })),
            transition('void => *', [
                animate(500, keyframes([
                    style({ opacity: 0, offset: 0, height: 0 }),
                    style({ opacity: 0.2, offset: 0.2, height: '*' }),
                    style({ opacity: 1, offset: 1.0, height: 'auto' })
                ]))
            ]),
            transition('* => void', [
                animate(200, keyframes([
                    style({ opacity: 1, offset: 0, height: 'auto' }),
                    style({ opacity: 0.2, offset: 0.2, height: '*' }),
                    style({ opacity: 0, offset: 1.0, height: 0 })
                ]))
            ])
        ])
    ],
    standalone: true,
    imports: [
        NgFor,
        NgIf,
    ],
})
export class FaqComponent implements OnInit {
	public arrFAQ: any = [
		{
			t_title: "Why choose Villume?",
			t_desc: "Villume is the only online exhibition platform on the market that offers high-quality design for a reasonable subscription price."
		},
		{
			t_title: "Can Villume galleries be viewed on all mobile devices?",
			t_desc: "Yes, they absolutely can! Our galleries are optimized to view on all devices: from desktops to tablets and smartphones."
		},
		{
			t_title: "What do I need to create a virtual gallery with Villume?",
			t_desc: "Register on our platform, choose a virtual gallery from our vast collection of unique templates, and upload your artwork. Our intuitive software makes the process hassle-free , but also you can watch the complete guide on using Villume here. Villume virtual galleries constructor is supported by all common browsers."
		},
		{
			t_title: "I need a custom-made gallery, is that possible?",
			t_desc: "Absolutely! Please send us a message with what you have in mind, and we will get back to you with a quote.<br><a href='mailto:info@villume.com' class='link-admin-faq'>info@villume.com</a>"
		}
	];

	@Input() groupName: string = "";

	constructor() { }

	ngOnInit(): void {
		this.arrFAQ.map((x)=> x.open = false);
	}

}
