import { Component, OnDestroy, OnInit } from '@angular/core';

// User-defined interfaces
import { Artwork, ArtworkRequest } from 'src/app/shared/interfaces';

// User-defined services
import { EditorService } from '../../editor.service';
import { MainService, store } from 'src/app/shared/services';
import { AlertMessageService } from 'src/app/components/alert-message/alert-message.service';

// Third-party packages
import watch from 'redux-watch';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { messages } from '@data';
import { ImagesService } from '../images.service';
import { debounce } from 'lodash';
import { InputSwitchModule } from 'primeng/inputswitch';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'app-request-image',
    templateUrl: './request-image.component.html',
    styleUrls: ['./request-image.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass, InputSwitchModule, FormsModule, ReactiveFormsModule]
})
export class RequestImageComponent implements OnInit, OnDestroy {
	public requestArtworkData: ArtworkRequest;

	public requestLink: FormControl = new FormControl("");
	public requestEmail: FormControl = new FormControl("", Validators.email);
	public requestArtworkText: FormControl = new FormControl("", Validators.required);

	public requestArtworkValid: boolean = false;
	public requestArtworkUrl: string;

	private _stateWatcher: any;


	constructor(
		public editorService: EditorService,
		private _messageService: AlertMessageService,
		public mainService: MainService,
		private _artworkService: ImagesService,
	) {
		this._initReduxStateWatcher();
	}

	ngOnInit(): void {
		this._setFormValues();
	}

	ngOnDestroy(): void {
		this._stateWatcher();
	}

	/**
	 * * ============================================================ *
	 * * LIST OF FUNCTIONS
	 * * ============================================================ *
	 * - WATCH SELECTED OBJECT STATE
	 * - GET TEMPORARY REQUEST DATA
	 * - TOGGLE ARTWORK BUTTON
	 * - SET VALIDATORS REQUEST ARTWORK
	 * - CLEAR VALIDATORS
	 * - UPDATE VALIDATORS
	 * - VALIDATE INPUT
	 * - IS VALID VALUES
	 * - IS REQUEST ARTWORK DISABLED
	 * - REQUEST ARTWORK
	 * - SET TYPE REQUEST ARTWORK
	 * - UPDATE REQUEST ARTWORK DATA
	 * - APPLY CHANGES
	 * - UPDATE STATE REQUEST ARTWORK
	 * - APPLY TO REQUEST ARTWORK DATA
	 * - IS APPLY CHANGES DISABLED
	 */

	//#region 

	/**
	 * ANCHOR Apply Changes To All
	 * @description: to apply changes to all artwork
	 */
	public applyChangesToAll(): void {
		const applyMessage = messages.editor.artwork.requestArtwork
		this.editorService.artworks.map((artwork: Artwork) => { this._updateOtherRequestData(artwork) })
		this.editorService.updateLogActivity("Apply request config to all artwork");
		this._messageService.add({ severity: 'success', summary: 'Success', detail: applyMessage.applyAll });
		
		this._setFormValues()
		this.editorService.dataHasChanges = true;
		this.editorService.updateUndoRedoState();
	}

	//#endregion


	/**
  * * =============================================================================== *
  *   SECTION Form Validators Functions
  * * =============================================================================== *
  */

	//#region 

	/**
	 * ANCHOR Is Valid Values
	 * @description: to validate if the form values are valid
	 * @returns : boolean
	 */
	private _isValidValues(): boolean {
		return (
			this.requestArtworkText.valid &&
			this.requestEmail.valid &&
			this.requestLink.valid
		);
	}

	/**
	 * ANCHOR Set Validators
	 * @description: to set validators to the form
	 */
	private _setValidators(): void {
		this._clearValidators();
		const requestData = this.editorService.activeArtwork.request_artwork;
		if (requestData.request_button) {
			this.requestArtworkText.setValidators([
				Validators.maxLength(20),
				Validators.pattern("^[a-zA-Z0-9 ]*$"),
				Validators.required
			])

			if (requestData.request_via_link) {
				this.requestLink.setValidators([
					Validators.required,
					Validators.pattern('^(http(s)?://)?[^ "]+$')
				]);
			}

			if (requestData.request_via_email) {
				this.requestEmail.setValidators([
					Validators.required,
					Validators.email
				]);
			}
		}

		this._updateValidators();
	}

	/**
	 * ANCHOR Clear Validators
	 * @description: to clear validators to the form
	 */
	private _clearValidators(): void {
		this.requestEmail.clearValidators();
		this.requestArtworkText.clearValidators();
		this.requestLink.clearValidators();
	}

	/**
	 * ANCHOR Update Validators
	 * @description: to update validators to the form
	 */
	private _updateValidators(): void {
		this.requestArtworkText.updateValueAndValidity();
		this.requestEmail.updateValueAndValidity();
		this.requestLink.updateValueAndValidity();
	}

	/**
	 * ANCHOR Validate All Artwork Data
	 * @description to valudate all artwork data
	 */
	private _validateValues(): void {
		this._artworkService.requestArtworkValid = this._isValidValues();
		this._artworkService.validateAllArtworkData();
		this.editorService.validateExhibitionData();
	}

	//#endregion
	//!SECTION


	/**
  * * =============================================================================== *
  *   SECTION Handle Value Changes In Input Text Functions
  * * =============================================================================== *
  */

	//#region 

	/**
	 * ANCHOR Handle Value Changes Input Text
	 * @description to handle value changes input text
	 * @param type : 'buttonText' | 'email' | 'website'
	 */
	public handleValueChangesInputText(type: 'buttonText' | 'email' | 'website'): void {
		const control = this._getFormControl(type);
		const prop = this._getRequestProperty(type);

		this.editorService.activeArtwork.request_artwork[prop] = control.value;
		control.setValue(control.value);

		this._setRequestURL();
		this._validateValues();

		this.editorService.dataHasChanges = true;
		this.editorService.updateUndoRedoStateWithDelay();
		this.editorService.updateLogActivityWithDelay("Update " + prop.replace(/_/g, " "));
	}
	


	/**
	 * ANCHOR Get Form Control 
	 * @description to get form control based on the type
	 * @param type : 'buttonText' | 'email' | 'website'
	 * @returns : FormControl
	 */
	private _getFormControl(type: 'buttonText' | 'email' | 'website'): FormControl {
		switch (type) {
			case 'buttonText': return this.requestArtworkText;
			case 'email': return this.requestEmail;
			case 'website': return this.requestLink;
		}
	}

	/**
	 * ANCHOR Get Request Property
	 * @description to get request property based on the type
	 * @param type : 'buttonText' | 'email' | 'website'
	 * @returns : string
	 */
	private _getRequestProperty(
		type: 'buttonText' | 'email' | 'website'
	): 'request_text_button' | 'request_email_value' | 'request_link_value' {
		switch (type) {
			case 'buttonText': return 'request_text_button';
			case 'email': return 'request_email_value';
			case 'website': return 'request_link_value';
		}
	}

	//#endregion
	//!SECTION


	/**
  * * =============================================================================== *
  *   SECTION Disbled Action Checker Functions
  * * =============================================================================== *
  */

	//#region 

	/**
	 * ANCHOR Is Apply All Changes Disabled
	 * @description to check if apply all changes action is disabled or not
	 * @return: boolean
	 */
	public isApplyAllChangesDisabled(): boolean {
		return (
			!this.editorService.artworkDataValid ||
			this.editorService.blockUserAction
		)
	}

	/**
	 * ANCHOR Is Request Disabled
	 * @description to check if request action is disabled or not
	 * @return: boolean
	 */
	public isRequestDisabled(): boolean {
		return (
			!this.editorService.artworkDataValid ||
			this.editorService.blockUserAction ||
			!this.requestArtworkUrl
		)
	}

	//#endregion
	//!SECTION


	/**
  * * =============================================================================== *
  *   SECTION Uncategorized Functions
  * * =============================================================================== *
  */

	//#region 

	/**
	 * ANCHOR Initialize Redux State Watcher
	 * @description to initialize redux state watcher
	 */
	private _initReduxStateWatcher(): void {
		const seleceObjectWatch = watch(store.getState, 'editor.objectHasSelected')
		this._stateWatcher = store.subscribe(seleceObjectWatch((e: boolean) => {
			if (e && this.editorService.activeArtworkNode) {
				this._setFormValues();
			}
		}));
	}

	/**
	 * ANCHOR Request Artwork
	 * @description to request artwork
	 */
	public requestArtwork(): void {
		if (this.requestArtworkUrl) window.open(this.requestArtworkUrl);
	}

	/**
	 * ANCHOR Update Request Visibility
	 * @description to change the visibility of the request button on the artwork (gallery)
	 */
	public updateRequestVisibility(): void {
		this._setValidators();
		this._validateValues();

		const isEnabled = this.editorService.activeArtwork.request_artwork.request_button;
		this.editorService.updateLogActivity(`${isEnabled ? 'Enabled' : 'Disabled'} request button`);
		this.editorService.updateUndoRedoState();
		this.editorService.dataHasChanges = true;
	}

	/**
	 * ANCHOR Set Form Values
	 * @description  to set form values based request data
	 */
	private _setFormValues(): void {
		const requestData = this.editorService.activeArtwork.request_artwork;
		this.requestArtworkText.setValue(requestData.request_text_button)
		this.requestEmail.setValue(requestData.request_email_value)
		this.requestLink.setValue(requestData.request_link_value)
		this._setRequestURL();
		this._setValidators();
	}

	/**
	 * ANCHOR Set Request URL
	 * @description to set request url
	 */
	private _setRequestURL(): void {
		this.requestArtworkUrl = this.editorService.setRequestUrl(
			this.editorService.activeArtwork.request_artwork
		);
	}

	/**
	 * ANCHOR Change Artwork Type
	 * @description to change artwork type
	 * @param type : 'email' | 'link'
	 */
	public changeRequestType(type: 'email' | 'link'): void {
		if (!this.editorService.activeArtwork.request_artwork[`request_via_${type}`]) {
			this.editorService.activeArtwork.request_artwork[`request_via_${type}`] = true;
			this.editorService.activeArtwork.request_artwork[`request_via_${(type === 'email') ? 'link' : 'email'}`] = false;
			this._setRequestURL();
		}
		this._setValidators();
		this._validateValues();

		this.editorService.updateLogActivity(`Change request type to ${type}`);
		this.editorService.updateUndoRedoState();
		this.editorService.dataHasChanges = true;
	}

	/**
	 * ANCHOR Update Other Request Data
	 * @description to update current artwork request data to other artwork
	 * @param artwork : Artwork
	 */
	private _updateOtherRequestData(artwork: Artwork): void {
		const requestData = this.editorService.activeArtwork.request_artwork;
		artwork.request_artwork.request_text_button = requestData.request_text_button
		artwork.request_artwork.request_via_email = requestData.request_via_email
		artwork.request_artwork.request_email_value = requestData.request_email_value || '';
		artwork.request_artwork.request_via_link = requestData.request_via_link
		artwork.request_artwork.request_link_value = requestData.request_link_value || '';
		artwork.request_artwork.request_button = requestData.request_button
	}

	//#endregion
	//!SECTION
}
