// Angular Packages
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Validators, FormGroup, FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Router } from '@angular/router';

// User-defined services
import { MainService } from "src/app/shared/services";
import { SettingsService } from '../settings.service';
import { AlertMessageService } from 'src/app/components/alert-message/alert-message.service';
import { forbiddenName } from '@custom-validators';

// User-defined interfaces
import { FileInputEvent, UpdateUserParams, User } from 'src/app/shared/interfaces';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-profile-setting',
    templateUrl: './profile-setting.component.html',
    styleUrls: ['./profile-setting.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf]
})
export class ProfileSettingComponent implements OnInit {
  public loading:boolean = false;
  public usernameNotAvailable: boolean = false;
  public infoUser: User = this.mainService.userInfo;
  public onUploading: boolean = false;

  public form: FormGroup = new FormGroup({
    avatar: new FormControl(''),
    first_name: new FormControl('', [ Validators.required,forbiddenName() ]),
    last_name: new FormControl('', [ Validators.required, forbiddenName() ]),
    username: new FormControl('', [ 
      Validators.required, 
      Validators.maxLength(40),
      Validators.pattern('[a-z0-9_.]*')
    ]),
    medium: new FormControl(''),
    about: new FormControl(''),
    location: new FormControl(''),
    website_url: new FormControl('',[
      Validators.pattern("(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?")
    ]),
    email_profile: new FormControl('', [ Validators.email ]),
    facebook_url: new FormControl(''),
    instagram_url: new FormControl(''),
  });

  public availableRoutes: string[];

  constructor(
    public mainService:MainService,
    private alertMessageService:AlertMessageService,
    private settingsService:SettingsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if(this.mainService.isBrowser) {
      this._setFormValues();
      this._getAvailableRoutes();
    }
  }

  /**
   * ANCHOR Get Available Routes
   * @description to get available routes, this used for handle if user input username 
   *              that same with routes in the app
   */
  public _getAvailableRoutes(): void {
    this.availableRoutes = this.router.config.map((config: any) => config.path);
  }


  /**
   * ANCHOR Handle Space At Begin
   * @description to handle space at begin of input value
   * @param formControlName 
   */
  public handleSpaceAtBegin(formControlName: string): void {
    const formControl = this.form.get(formControlName);
    formControl.setValue(formControl.value.trimStart());
    if (formControlName == 'username') this.usernameNotAvailable = false;
  }

  /**
   * ANCHOR Set Form Values
   * @description to set form values
   */
  private _setFormValues(): void {
    this.infoUser.avatar = this.mainService.setUserAvatar(this.infoUser?.avatar);
    this.form.setValue({
      avatar: this.infoUser?.avatar,
      first_name: this.infoUser?.first_name,
      last_name: this.infoUser?.last_name,
      username: this.infoUser?.username,
      medium: this.infoUser?.medium,
      about: this.infoUser?.about,
      location: this.infoUser?.location,
      website_url: this.infoUser?.website_url,
      email_profile: this.infoUser?.email_profile,
      facebook_url: this.infoUser?.facebook_url,
      instagram_url: this.infoUser?.instagram_url,
    });
  }

  /**
   * ANCHOR Set Upper To First
   * @description to set uppercase to first word
   * @param formControlName 
   */
  public setUpperToFirst(formControlName: string) {
    const formControl = this.form.get(formControlName);
    const value = formControl.value.split('');
    value[0] = value[0].toUpperCase();
    formControl.setValue(value.join(''));
  }

  /**
   * ANCHOR Set Url Protocol
   * @description to set url protocol to https:// or http://
   * @param url : string | null
   * @returns : string
   */
  private _setUrlProtocol(url: string | null): string {
    if (url) {
      const protocol = url.includes("https://") || url.includes("http://") ? '' : 'https://';
      return protocol + url.trim();
    }
    return '';
  }

  /**
   * ANCHOR Check Username
   * @description to check username if already exists
   * @param username : string
   * @returns : Promise
   */
  private _checkUsername(username: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.mainService.checkUsername(username).subscribe((response) => {
        this.usernameNotAvailable = false;
        resolve(response);
      },err => {
        const code = err.error.statusCode;
        switch (code) {
          case 409:
            this.usernameNotAvailable = true;
            this.alertMessageService.add({
              severity:"warn",
              summary:"Warning",
              detail:"Username already exists"
            })
          break;
          case 401:
            this.mainService.expiredSesionPopup = true;
          break;
          default:
            this.alertMessageService.add({
              severity:"error", 
              summary:"Error", 
              detail:"Something went wrong. Please try again."
            })
          break;
        }
        reject(err);
      });
    })
  }

  /**
   * ANCHOR Create Request Body
   * @description to create request body
   * @returns : UpdateUserParams
   */
  private _createRequestBody(): UpdateUserParams {
    const values = this.form.getRawValue();
    return {
      first_name: values.first_name?.trim() ||'',
      last_name: values.last_name?.trim() ||'',
      username: values.username?.trim().toLowerCase() ||'',
      medium: values.medium?.trim() ||'',
      about: values.about?.trim() ||'',
      location: values.location?.trim() ||'',
      website_url: this._setUrlProtocol(values.website_url) ||'',
      email_profile: values.email_profile?.trim() ||'',
      facebook_url: values.facebook_url?.trim() ||'',
      instagram_url: values.instagram_url?.trim() ||'',
      v: 1
    }
  }


  /**
   * ANCHOR Update Profile
   * @description to update profile
   * @returns : void
   */
  public async updateProfile() {
    if (this.isSaveChangesDisabled()) {
      this.alertMessageService.add({ severity: 'warn', summary: 'Warning', detail: 'The data is invalid!'});
      return;
    }

    const requestBody = this._createRequestBody()
    this.loading = true;
    if(this.infoUser.username != requestBody.username){
      try { await this._checkUsername(requestBody.username)} 
      catch (error) { this.loading = false }
    }

    if (!this.usernameNotAvailable) {
      this.settingsService.updateUserProfile(requestBody).subscribe(async (res: any) => {
        await this.settingsService.fetchUser(true)
        this.mainService.userInfoPublic = { ...this.mainService.userInfo };
        this.mainService.getUserStamp = Date.now();
        this.alertMessageService.add({ 
          severity: 'success', 
          summary: 'Success', 
          detail: 'Changes were successfully saved' 
        });
        this.loading = false;
      }, (err: any) => {
        this.loading = false;
        switch (err.error.statusCode) {
          case 401:
            this.mainService.expiredSesionPopup = true;
          break;
          default:
            this.alertMessageService.add({ severity:"error", summary:"Error", detail:"Something went wrong. Please try again."})
          break;
        }
      })
    }
  }

  /**
   * ANCHOR Upload Picture
   * @description to upload picture
   * @param event : FileInputEvent
   */
  public async uploadPicture(event: FileInputEvent) {
    const file = event.target.files[0];
    if (file) {
      if(await this.validateImage(file)) {
        const formData: FormData = new FormData();
        formData.append('file', file);
        this.onUploading = true;
        this.mainService.uploadAvatar(formData).subscribe(async (response: any) => {
          this.onUploading = false;
          await this.settingsService.fetchUser(true);
          this.infoUser.avatar = this.mainService.setUserAvatar(response.data.urlAvatar);
          this.form.get('avatar').setValue(this.infoUser.avatar);
          this.mainService.userInfoPublic = { ...this.mainService.userInfo };
          this.mainService.getUserStamp = Date.now();
    
          this.alertMessageService.add({
            severity:"success", 
            summary:"Success", 
            detail:"Avatar successfully updated"
          });
        }, err => {
          this.onUploading = false;
          // Handle other errors
          this.alertMessageService.add({ 
            severity: 'error', 
            summary: 'Failed', 
            detail: 'Data failed to update' 
          });
        });
      }
    }
    event.target.value = ""
  }

  /**
   * ANCHOR Validate Image
   * @description to validate image
   * @param file : File
   * @returns : Promise
   */
  public async validateImage(file: File){
    if(file){
      const extension = file.name.split(".").slice(-1)[0].toLowerCase();
      const allowedExtention = ['png','jpeg','jpg'];
      if(allowedExtention.includes(extension)){
        if(file.size  / 1024 / 1024 <= 2){
          try {
            await this._validateBrokenImg(file);
            return true;
          } catch (error) {
            this.alertMessageService.add({ severity:"warn", summary:"Warning", detail:"Corrupted file!"});
            return false;
          }
        } else{
          this.alertMessageService.add({severity:"warn", summary:"Warning", detail:"The file size is too large. Please only upload files less than 2 MB for stable performance."});
          return false;
        }
      }else{
        this.alertMessageService.add({severity:"warn", summary:"Warning", detail:"This file format is not supported. Please upload .png, or .jpeg"});
        return false;
      }	
    }else{
      return false;
    }
  }

  /**
   * ANCHOR Validate Broken Img
   * @description to validate broken image
   * @param file : File
   * @returns : Promise
   */
  private _validateBrokenImg(file: File) {
    return new Promise((resolve, reject) => {
      const url = URL.createObjectURL(file);
      const img = new Image();
      img.onerror = reject;
      img.onload = resolve;
      img.src = url;
    })
  }

  /**
   * ANCHOR Is Save Changes Disabled
   * @description to check if save changes is disabled
   * @returns : boolean
   */
  public isSaveChangesDisabled(): boolean {
    return (
      this.form.invalid || 
      this.loading ||
      this.availableRoutes.includes(this.form.get('username').value.toLowerCase())
    )
  }
}