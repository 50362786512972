import { Component, OnInit } from '@angular/core';
import { LayoutsComponent } from '../../layouts/layouts.component';

@Component({
    selector: 'app-careers',
    templateUrl: './careers.component.html',
    styleUrls: ['./careers.component.scss'],
    standalone: true,
    imports: [LayoutsComponent]
})
export class CareersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  back(): void {
    window.history.back();
  }

}
