@import '../../../assets/scss/common/variables';

.wrap-contact {
    padding: 64px 30px 330px 30px;
    background-color: $color-dark3;

    @media (max-width: $desktop-screen) {
        padding: 64px 30px 330px;
    }

    @media (max-width: $large-screen) {
        padding: 54px 25px 330px;
    }

    @media (max-width: $small-screen) {
        padding: 30px 15px 150px;
    }

    .title {
        font-size: 96px;
        font-family: $font-whyte;
        line-height: 111%;
        margin-bottom: 50px;

        @media (max-width: $large-screen) {
            font-size: 80px;    
            line-height: 111%;
        }

        @media (max-width: $medium-screen) {
            font-size: 58px;
            line-height: 111%;
        }

        @media (max-width: $small-screen) {
            font-size: 30px;
        }
    }

    .wrap-accordion{
        @media (max-width: $desktop-screen) {
            -ms-flex: 0 0 62.5%;
            flex: 0 0 62.5%;
            max-width: 62.5%;
        }

        @media (max-width: $large-screen) {
            -ms-flex: 0 0 87.5%;
            flex: 0 0 87.5%;
            max-width: 87.5%;
        }

        @media (max-width: $small-screen) {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
        
        .accordion{
            border-top: 1px solid #9f9f9f;
            margin-left: 5px;

            @media (max-width: $small-screen) {
                margin-left: 0;
            }
    
            .card{
                background-color: $color-dark3;
                border-bottom: solid 1px #9f9f9f;
                border-right: 0;
                border-left: 0;
                border-radius: 0;
    
                .card-header{
                    color: $color-white;
                    border-bottom: none;
                    padding: 0;
    
                    .btn-accordion{
                        display: block;
                        text-align: left;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        font-size: 28px;
                        line-height: 54px;
                        color: $color-gray3;
                        padding: 28px 23px 19px 0;
                        background-color: transparent;
                        border: 0;
                        font-family: "suisse_intlregular";

                        @media (max-width: $desktop-screen) {
                            font-size: 24px;
                            line-height: 54px;
                        }

                        @media (max-width: $medium-screen) {
                            font-size: 20px;
                            line-height: 130.5%;
                        }

                        @media (max-width: $small-screen) {
                            font-size: 16px;
                            line-height: 125%;
                        }
    
                        &.active-contact{
                            color: $color-gray1;
    
                            .wrap-icon{
                                .vi{
                                    color: $color-gray1;
                                }
                            }
                        }
    
                        .wrap-icon{
                            flex: 1 1 auto;
                            text-align: right;
    
                            .vi{
                                font-size: 18px;
                                color: $color-gray3;

                                @media (max-width: $desktop-screen) {
                                    font-size: 24px;
                                }
        
                                @media (max-width: $medium-screen) {
                                    font-size: 20px;
                                }

                                @media (max-width: $small-screen) {
                                    font-size: 16px;
                                }
                            }
                        }
    
                        &:focus{
                            box-shadow: none;
                            border: 0 none;
                            outline: none;
                        }
                    }
                }
    
                .card-body{
                    padding: 0 67px 60px 0;

                    @media (max-width: $small-screen) {
                        padding: 0 0 50px 0;
                    } 
    
                    .form-group{
                        margin-bottom: 30px;

                        @media (max-width: $small-screen) {
                            margin-bottom: 20px;
                        }

                        label{
                            @media (max-width: $small-screen) {
                                font-size: 14px;
                                line-height: 22px;
                            } 
                        }
    
                        .form-control{
                            width: 250px;

                            &:focus {
                                background: $color-gray3;
                            }

                            &:disabled {
                                background-color: $color-gray4;
                            }

                            @media (max-width: $small-screen) {
                                width: 100%;
                            } 
    
                            &.description{
                                resize: none;
                                width: 100%;
                            }
                        }
    
                        .btn-upload-image{
                            padding: 30px 0;
                            width: 450px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: $color-dark5;
                            color: $color-gray1;
                            border: 0;
                            font-size: 16px;
                            line-height: 120%;
                            height: 80px;

                            @media (max-width: $small-screen) {
                                width: 514px;
                            }

                            @media (max-width: $small-screen) {
                                width: 100%;
                            }
                        }

                        #drop-area-help.highlight .btn-upload-image,
                        #drop-area-custom.highlight .btn-upload-image{
                            border: dashed 4px $color-dark6;
                        }
    
                        .wrap-upload-image{
                            margin: -10px -10px -10px;
                            display: flex;
                            flex-wrap: wrap;
    
                            .item-image-upload{
                                position: relative;
                                width: 80px;
                                display: inline-block;
                                margin: 10px 10px;
    
                                &:hover{
                                    .hide-image{
                                        display: flex;
                                    }
                                }
                                
                                .show-overlay {
                                    display: flex;
                                }
    
                                .img-upload{
                                    width: 80px;
                                    height: 80px;
                                    object-fit: cover;
                                    display: block;
                                }
        
                                .hide-image{
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    width: 100%;
                                    height: 100%;
                                    background-color: rgba(0, 0, 0, 0.68);
                                    display: none;
                                    align-items: center;
                                    justify-content: center;
    
                                    &:hover{
                                        text-decoration: none;
                                    }
    
                                    .vi{
                                        font-size: 16px;
                                        color: $color-gray3;
                                    }
                                }
                            }
    
                            .add-image-upload{
                                margin: 10px 10px;
    
                                .add-image{
                                    width: 78px;
                                    height: 78px;
                                    background-color: $color-dark4;
                                    border: solid 1px $color-gray1;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
    
                                    &:hover{
                                        text-decoration: none;
                                        background-color: $color-dark3;
                                        border: solid 1px $color-gray3;
    
                                        .vi{
                                            color: $color-gray3;
                                        }
                                    }

                                    &.disabled {
                                        border: solid 1px $color-gray1 !important;
                                        background-color: $color-dark4 !important;

                                        i {
                                            color: $color-gray1 !important;
                                        }
                                    }
    
                                    .vi{
                                        font-size: 24px;
                                        color: $color-gray1;
                                    }
                                }
                            }
                        }
                    }

                    .wrap-set-name{
                        display: flex;
                        margin-bottom: 30px;

                        @media (max-width: $small-screen) {
                            display: block;
                            margin-bottom: 20px;
                        }

                        .form-group{
                            @media (max-width: $small-screen) {
                                margin-right: 0 !important;
                                margin-bottom: 20px !important;

                                &:last-child{
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }

                    .recaptcha {
                        position: relative;
                        height: 66px;

                        &-valid {
                            position: absolute;
                            top: 0 ;
                            left: 0;
                            height: 66px;
                            display: flex;
                            align-items: center;

                            i {
                                font-size: 22px;
                                color: $color-primary;
                                border: 1px solid $color-gray3;
                                margin-right: 10px;
                                margin-bottom: 7px;
                            }
                        }

                        .hidden {
                            visibility: hidden;
                        }
                    }

                    .button-submit{
                        @media (max-width: $medium-screen){
                            padding: 11px 20px;
                            font-size: 16px;
                            height: 45px;
                        }

                        @media (max-width: $small-screen){
                            font-size: 14px;
                            padding: 7px 20px;
                            height: 35px;
                            margin: auto;
                        }

                        &.loading-button-primary {
                            width: 129px;
                            font-size: 18px;
                            padding: 12px 0px 8px 42px;
                            color: $color-dark1 !important;

                            &::after {
                                left: calc(25% - 0.65em);
                            }
                        }
                    }
                }
            }
        }
    }
}
