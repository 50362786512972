import { Component, OnInit } from '@angular/core';
import { SubscribePricingService } from '../subscribe-pricing/subscribe-pricing.service';
import { Router } from '@angular/router';
import { MainService } from '@services';

@Component({
    selector: 'app-save-invoices',
    templateUrl: './save-invoices.component.html',
    styleUrls: ['./save-invoices.component.scss'],
    standalone: true
})
export class SaveInvoicesComponent implements OnInit {

  constructor(
    private _subscriptionService: SubscribePricingService,
    private _router: Router,
    private _mainService: MainService
  ) { }

  ngOnInit(): void {
    if (this._mainService.isBrowser) {
      this.SaveInvoices();
    }
  }

  SaveInvoices() {
    this._subscriptionService.saveInvoiceToServer().subscribe((res) => {
      this._router.navigate(['/pricing/success']);
    },
    (err) => {
        this._router.navigate(['/pricing/success']);
    });
  }
}
