import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InputNumberComponent } from 'src/app/components/input-number/input-number.component';
import { TextService } from '../../service/text-service.service';
import { EditorService } from '../../../editor.service';
import { SliderModule } from 'primeng/slider';
import { TextLoaderService } from '../../service/text-loader.service';

@Component({
  selector: 'app-text-scaling',
  standalone: true,
  templateUrl: './text-scaling.component.html',
  styleUrl: './text-scaling.component.scss',
  imports: [
    FormsModule,
    InputNumberComponent,
    SliderModule
  ],
})
export class TextScalingComponent {

  constructor(
    public textService: TextService,
    public editorService: EditorService,
    public textLoader: TextLoaderService
  ) { }

  /**
   * ANCHOR: Update Input Value
   * @description: to update input value
 */
  public updateInputValue(value): void {
    this.textLoader.activeTextNode.scaling.x = value / 100;
    this.scalingWrapText();
    this.textService.initInputsValue()
  }

  /**
  * * SCALING WRAP TEXT *
  * ANCHOR Scaling Wrap Text
  * @description for scaling wrap text
  */
  public scalingWrapText(): void {
    this.editorService.dataHasChanges = true;
    this.textLoader.activeTextNode.changed = true;

    if (this.editorService.selectedExhibitAssets.length > 1) {
      this._scalingMultipleWrapText();
    } else {
      this._scalingSingleWrapText();
    }

    this.editorService.updateLogActivityWithDelay("Update wrap text scale")
    this.editorService.updateUndoRedoStateWithDelay()
  }

  /**
   * * SCALING MULTIPLE WRAP TEXT *
   * ANCHOR Scaling Multiple Wrap Text
   * @description for scaling multiple wrap text
   */
  private _scalingMultipleWrapText(): void {
    this.editorService.selectedExhibitAssets.map((node) => {
      const textWallData = this.textService.getTextWallData(node);

      // Scaling Wrap Text
      node.scaling.x = this.textLoader.activeTextNode.scaling.x;
      node.scaling.y = node.scaling.x;
      const fontSize = textWallData.font_size / node.scaling.x * this.textLoader.textWallQuality;
      node.textGUI.fontSize = fontSize;

      // Update text data
      textWallData.scaling.scaling_x = node.scaling.x;
      textWallData.scaling.scaling_y = node.scaling.y;

      // update line height
      node.textGUI.onLinesReadyObservable.addOnce(() => {
        const lineHeight = textWallData.line_height / textWallData.scaling.scaling_x * this.textLoader.textWallQuality;
        node.textGUI.fontOffset.height = lineHeight;
      });
    })
  }

  /**
  * * SCALING SINGLE WRAP TEXT *
  * ANCHOR Scaling Single Wrap Text
  * @description for scaling single wrap text
  */
  private _scalingSingleWrapText(): void {
    // Scaling Wrap Text
    this.textLoader.activeTextNode.scaling.y = this.textLoader.activeTextNode.scaling.x;
    const fontSize = this.textLoader.activeText.font_size / this.textLoader.activeTextNode.scaling.x * this.textLoader.textWallQuality;
    this.textLoader.activeTextNode.textGUI.fontSize = fontSize;

    // Update text data
    this.textLoader.activeText.scaling.scaling_x = this.textLoader.activeTextNode.scaling.x;
    this.textLoader.activeText.scaling.scaling_y = this.textLoader.activeTextNode.scaling.y;

    // update line height
    this.textLoader.activeTextNode.textGUI.onLinesReadyObservable.addOnce(() => {
      const lineHeight = this.textLoader.activeText.line_height / this.textLoader.activeText.scaling.scaling_x * this.textLoader.textWallQuality;
      this.textLoader.activeTextNode.textGUI.fontOffset.height = lineHeight;
    });
  }
}
