import { NgStyle } from '@angular/common';
import { Component } from '@angular/core';
import { InputColor } from 'src/app/components/input-color/input-color.component';
import { TextService } from '../../service/text-service.service';
import { EditorService } from '../../../editor.service';
import { MainService } from '@services';
import { FormsModule } from '@angular/forms';
import { TextLoaderService } from '../../service/text-loader.service';
declare var $: any;

@Component({
  selector: 'app-text-color',
  standalone: true,
  templateUrl: './text-color.component.html',
  styleUrl: './text-color.component.scss',
  imports: [
    FormsModule,
    NgStyle,
    InputColor
  ],
})
export class TextColorComponent {
  public hideColorOverlay: boolean = true;

  constructor(
    public textService: TextService,
    public editorService: EditorService,
    public mainService: MainService,
    public textLoader: TextLoaderService
  ) { }

  ngAfterViewInit() {
    $(".sidebar").on("scroll", () => {
      if (!this.hideColorOverlay) this.hideColorOverlay = true;
    });
  }

  /**
  * ANCHOR Edit Color Text
  * @description to edit color text
  */
  public editColorText(): void {
    if (this.editorService.selectedExhibitAssets.length > 1) {
      this.editorService.selectedExhibitAssets.forEach((node: any) => {
        const textWallData = this.textService.getTextWallData(node);
        textWallData.color = this.textLoader.activeText.color;
        node.textGUI.color = textWallData.color;
        node.textGUI.shadowColor = textWallData.color + '20';
        node.changed = true;
      })
    } else {
      this.textLoader.activeTextNode.textGUI.color = this.textLoader.activeText.color;
      this.textLoader.activeTextNode.textGUI.shadowColor = this.textLoader.activeText.color + '20';
      this.textLoader.activeTextNode.changed = true;
    }
    this.editorService.dataHasChanges = true;
    this.editorService.updateLogActivityWithDelay("Update seleted text color")
    this.editorService.updateUndoRedoStateWithDelay()
  }
}
