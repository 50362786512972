<div class="wrap-image">
    <!-- Unselect artwork content -->
    <ng-container *ngIf="!editorService.activeArtwork">
        <!-- Add image (artwork) -->
        <app-add-image></app-add-image>
        
        <!-- Animation sequance setting -->
        <app-animation-sequance></app-animation-sequance>

        <!-- List image (artwork) -->
        <app-list-images></app-list-images>
    </ng-container>

    <!-- Select artwork content -->
    <ng-container *ngIf="editorService.activeArtwork">
        <!-- Back to list artwork (unselect artwork) -->
        <div class="btn-back-to">
            <button
                [disabled]="editorService.blockUserAction"
                (click)="unselectArtwork()" 
                class="btn btn-outline-default2 btn-sm">
                <i class="vi vi-arrow-left"></i> Back to Artworks
            </button>
        </div>

        <!-- Edit image (artwork) -->
        <app-edit-image></app-edit-image>

        <!-- Media Support (Audio or Video) -->
        <ng-container *ngIf="editorService.activeArtwork.file_type!='video' && editorService.selectedExhibitAssets.length <= 1">
            <app-media-support></app-media-support>
        </ng-container>

        <!-- Menu icons setting -->
        <app-menu-icons #menuIcons></app-menu-icons>
    
        <div class="content-settings">
            <!-- scaling image (artwork) -->
            <app-scaling-image></app-scaling-image>

            <!-- Lighting setting -->
            <app-lighting-setting 
                *ngIf="editorService.activeArtwork.file_type == 'figure-object'">
            </app-lighting-setting>

            <!-- align image (artwork) -->
            <div class="outline-bottom"></div>
            <app-align-image></app-align-image>

            <ng-container *ngIf="editorService.selectedExhibitAssets.length <= 1">
                <!-- Advance setting toggle -->
                <div class="outline-bottom"></div>
                <div class="d-flex align-center switch-artwork-settings" [ngClass]="editorService.advanceSettingArtworks ? 'mb30' : 'mb15'">
                    <p-inputSwitch 
                        [disabled]="editorService.blockUserAction" 
                        [(ngModel)]="editorService.advanceSettingArtworks" 
                        [styleClass]="(editorService.blockUserAction)? 'input-switch-disable switch-lg mt0' : 'switch-lg mt0'">
                    </p-inputSwitch>
                    <label class="ml15">Advanced Settings</label>
                </div>

                <!-- Rotate image (artwork) -->
                <app-rotation-image *ngIf="editorService.advanceSettingArtworks" [rotate]="editorService.activeArtworkNode.rotation"></app-rotation-image>
                
                <!-- Request image (artwork) setting -->
                <ng-container *ngIf="editorService.activeArtwork.file_type!='video'">
                <div class="outline-bottom"></div>
                    <app-request-image></app-request-image>
                </ng-container>
                
                <!-- image (artwork) info setting -->
                <ng-container *ngIf="editorService.activeArtwork.file_type!='video'">
                <div class="outline-bottom"></div>
                    <app-image-info></app-image-info>
                </ng-container>

                <!-- Audio Support -->
                <!-- <div class="outline-bottom"></div>
                <images-audio-support></images-audio-support> -->
                <div class="outline-bottom mb0"></div>
            </ng-container>
        </div>
    </ng-container>
</div>

<!-- EDIT PRAME FIGURE
============================== -->
<p-dialog 
    [(visible)]="editorService.editFrameMode" 
    [style]="{width: '960px'}" 
    [modal]="true" 
    [closable]="false"
    (onShow)="editFrame.renderEditFrameScene();editFrame.getFrameTemplates();editorService.onInput=true"
    (onHide)="editFrame.clearEditFrameScene();editorService.onInput=false"
    appendTo="body"
    [showHeader]="false"
    [draggable]="false" 
    styleClass="edit-frame">
    <div class="header-frame-edit">
        <button *ngIf="!editFrame.onUploading" (click)="editorService.closeEditFrame=true">
            <i class="vi vi-x"></i>
        </button>
    </div>
    <app-edit-frame 
        #editFrame
        (onDataChange)="updateArtworkFrame($event)"
        [figureData]="editorService.activeArtwork">
    </app-edit-frame>
</p-dialog>

<p-dialog 
    [(visible)]="editorService.displaySaveEditFrameTemplatePopup" 
    [style]="{maxWidth: '725px'}"
    styleClass="confirm-delete"
    [baseZIndex]="10000"
    [draggable]="false" 
    [resizable]="false"
    [modal]="true">

    <div class="wrap-save-frame">
        <h2 class="title">Save Frame</h2>
        <div class="form-group">
            <label for="">Frame Name</label>
            <input 
                type="text"
                (focus)="editorService.onInput=true"
                (blur)="editorService.onInput=false"
                class="form-control bg-gray"
                (input)="editFrame.frameName.setValue(editFrame.frameName.value.trimStart())"
                [formControl]="editFrame.frameName"
                (keydown)="mainService.blockChars($event)">

            <div *ngIf="editFrame.frameName.invalid && (editFrame.frameName.dirty || editFrame.frameName.touched)" class="validation-error">
                <p *ngIf="editFrame.frameName.errors?.required">Name is required.</p>
            </div>

            <p class="note" *ngIf="editorService.editFrameTemplateMode">
                If you click “Update” all artworks connected to this frame will also be updated
            </p>
        </div>
        <div class="wrap-btn-save-frame">
            <button 
                *ngIf="editorService.editFrameTemplateMode"
                [disabled]="editFrame.onLoadingButtonFrameTemplatePopup || editFrame.frameName.errors || editFrame.onLoadingSaveAsTemplate" 
                [class.loading-button-primary]="editFrame.onLoadingSaveAsTemplate"
                (click)="editFrame.saveFrameTemplate(true)" 
                class="btn btn-light btn-sm">
                Save as a New Frame
            </button>
            <button 
                (click)="editorService.editFrameTemplateMode ? editFrame.editFrameTemplate() : editFrame.saveFrameTemplate()" 
                [disabled]="editFrame.onLoadingButtonFrameTemplatePopup || editFrame.frameName.errors || editFrame.onLoadingSaveAsTemplate" 
                [class.loading-button-primary]="editFrame.onLoadingButtonFrameTemplatePopup"
                class="btn btn-primary btn-sm ml30">
                <span>
                    {{editorService.editFrameTemplateMode ? 'Update' : 'Save'}} 
                    <i class="pi pi-arrow-right"></i>
                </span>
            </button>
        </div>
    </div>
</p-dialog>

<!-- Delete frame dialog -->
<p-dialog 
    [(visible)]="editorService.displayDeleteFrameTemplatePopop" 
    [style]="{maxWidth: '725px'}"
    styleClass="confirm-delete"
    [baseZIndex]="10000"
    [draggable]="false" 
    [resizable]="false"
    [modal]="true">

    <div class="wrap-content-confirm">
        <div class="desc">
            <h3>Are you sure you want to delete this frame?</h3>
            <p>
                If you select “Yes”, artworks connected to this frame will go back to default frame settings in all your galleries.
            </p>
        </div>
        <div class="wrap-btn">
            <button 
                [disabled]="editFrame.onLoadingButtonFrameTemplatePopup"
                [class.loading-button-primary]="editFrame.onLoadingButtonFrameTemplatePopup"
                class="btn btn-light btn-sm" 
                (click)="editFrame.deleteFrameTemplate()">
                
                Yes
            </button>
            <button (click)="editorService.displayDeleteFrameTemplatePopop = false" class="btn btn-primary btn-sm mr0" >No</button>
        </div>
    </div>
</p-dialog>

<!-- popup confirm delete -->
<p-dialog 
    [(visible)]="editorService.closeEditFrame" 
    [modal]="true" 
    [style]="{maxWidth: '725px'}"
    [baseZIndex]="10000"
    [draggable]="false" 
    [resizable]="false"
    styleClass="confirm-delete">
    <div class="wrap-content-confirm">
        <div class="desc">
            <h3>Are yo sure you want to close frame editor ?</h3>
            <p>
                If you select “Yes”, 
                your changes will not be saved.
            </p>
        </div>
        <div class="wrap-btn">
            <button 
                [disabled]="editFrame.isLoading"
                class="btn btn-light btn-sm" 
                (click)="editFrame.closeEditFrame()">
                <p-progressSpinner 
                    [hidden]="!editFrame.isLoading"
                    [style]="{width: '30px', height: '30px'}"
                    strokeWidth="3"
                    animationDuration=".5s">
                </p-progressSpinner>
                <span [hidden]="editFrame.isLoading">
                    Yes
                </span>
            </button>
            <button (click)="editorService.closeEditFrame=false;" class="btn btn-primary btn-sm mr0" >No</button>
        </div>
    </div>
</p-dialog>
