import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd, RouterOutlet } from '@angular/router';
import { MainService } from 'src/app/shared/services';
import { LayoutsComponent } from '../../layouts/layouts.component';


@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
    standalone: true,
    imports: [LayoutsComponent, RouterOutlet]
})
export class SettingsComponent implements OnInit {
    public page:string;
    
    constructor(
        private router:Router,
        public mainService: MainService,
    ) { 
        this.router.events.subscribe(event => {
            if(event instanceof NavigationEnd) {
                this.changeTitlePage();
            }
        });
    }

    ngOnInit(): void {
        if(this.mainService.isBrowser){
            this.changeTitlePage();
        }
    }
    
	// ======================== //
	// Change title page
	// ====================== //
    changeTitlePage() {
        const urlSplit = this.router.url.split('/');
        let page = urlSplit[urlSplit.length-1];
        switch (page) {
            case "profile": this.page = "Profile Settings"; break;
            case "account": this.page = "Account Settings"; break;
            case "notifications": this.page = "Notifications"; break;
            case "pricing-plan": this.page = "Pricing Plan"; break;
            case "billing": this.page = "Billing"; break;
            case "invoice": this.page = "Invoice"; break;
        }
    }

}
