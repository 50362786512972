<app-layouts>
    <!-- START WRAP WELCOME -->
    <div content class="wrap-welcome">
        <div *ngIf="loading || !mainService.isBrowser" class="overlay-loading">
            Loading....
        </div>
        <div *ngIf="!loading && mainService.isBrowser" class="row">
            <div class="col col16-12 offset16-4 col-iped-7 offset-iped-1 col-mobile-4 offset-mobile-0 set-width-responsive-title">
                <h1 class="title">
                    <img src="{{env.staticAssets}}images/icon/stars.png?t={{mainService.appVersion}}" alt="" />
                    Welcome to Villume,
                </h1>
                <h2 class="username">
                    {{name}}!
                </h2>
            </div>
            <div class="col col16-11 offset16-5 offset-iped-2 col-iped-6 col-mobile-4 offset-mobile-0 set-width-responsive-desc">
                <h4 class="sub-title">
                    Thanks for signing up.<br/>
                    Please check your email to complete the process.
                </h4>
                <p class="desc">If you don’t receive an email after 5 minutes please click the button below.</p>

                <div class="g-recaptcha mb-3" id="captchaWelcome"></div>

                <div class="wrap-button">
                    <button class="btn btn-primary btn-sm" (click)="resendOtpUser()" [disabled]="loadingSendOTP || !captchaVerifed" [class.loading-button-primary]="loadingSendOTP">
                        Resend Email <i class="vi vi-arrow-right"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- END WRAP WELCOME -->
</app-layouts>