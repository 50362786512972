import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { InputColorService } from './input-color.service';

import * as _ from 'lodash';
import { FormsModule } from '@angular/forms';
import { ColorPickerModule } from 'primeng/colorpicker';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { NgIf, NgTemplateOutlet, NgFor } from '@angular/common';

@Component({
    selector: 'input-color',
    templateUrl: './input-color.component.html',
    styleUrls: ['./input-color.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgIf, OverlayPanelModule, NgTemplateOutlet, ColorPickerModule, FormsModule, NgFor]
})
export class InputColor implements OnInit {

	@Input() inline: boolean = true;
	@Input() color: string = "#ffffff";
	@Input() disabled: boolean = false;
	@Input() hideColorOverlay: boolean = false;
	@Input() favoriteColor: string;

	@Output() onChange = new EventEmitter(); 
	@Output() onFocus = new EventEmitter(); 
	@Output() onBlur = new EventEmitter(); 
	@Output() onHide = new EventEmitter(); 
	@Output() onShow = new EventEmitter(); 

	public colorForInput: string = "";
	public displayColorOverlay:boolean = false;
	public recentColors: string[] = [];
	public loading: boolean = false;

	@ViewChild('colorOverlay') public colorOverlay;

	constructor(private inputColorService:InputColorService) { }

	ngOnInit(): void {
		this.initRecentColor();
	}
	
	ngOnChanges(): void {
		if(this.hideColorOverlay) this.colorOverlay?.hide()
		this.colorForInput = this.color.replace("#","");
	}

	ngOnDestroy(){
		this.onHide.emit(this.favoriteColor);
	}

	/**
	 * * CHANGE COLOR VIA INPUT *
	 * Todo: to change color via input
	 */
	changeColorViaInput(){
		this.colorForInput = this.hexToSixDigit(this.colorForInput)
		this.onChange.emit("#"+this.colorForInput);
	}
	

	/**
	 * * HEX TO SIX DIGIT*
	 * Todo: to converting hex with any digit to hex six digit	
	 * @param oldHex : String -> 'ffffff'
	 */
	hexToSixDigit(oldHex) {
		oldHex = oldHex.replace("#","").toLowerCase();
		// Validate And Convert To Valid Hex
		const hexChar = '0123456789abcdef';
		let hexTmp = '';
		for (let i = 0; i < oldHex.length; i++) {
		  if (hexChar.includes(oldHex.charAt(i))) hexTmp += oldHex.charAt(i)
		  else break;
		}	  
		oldHex = hexTmp ? hexTmp : '000000';
	  
		// Convert To 6 Digit
		const len = oldHex.length;
		let newHex;
		if (len == 1) newHex = oldHex.repeat(6);
		else if (len == 2) newHex = oldHex.repeat(3);
		else if (len == 3 || len == 4 || len == 5) {
		  newHex = '';
		  for (let i = 0; i < 3; i++) {
			newHex += oldHex.charAt(i).repeat(2);
		  }
		} else newHex = oldHex.slice(0, 6);
	  
		return newHex;
	}

	/**
	 * * ADD TO RECENT COLOR *
	 * Todo: to adding selected color to recent colors
	 */
	addToRecentColors(){
		this.loading = true;
		this.recentColors.unshift(this.color);
		this.recentColors = this.recentColors.slice(0,6);
		this.recentColors = this.recentColors.filter((x) => x !=='');
		this.favoriteColor = this.recentColors.join();
		this.inputColorService.saveFavoriteColor({fav_colors:this.favoriteColor}).subscribe((res)=>{
			this.loading = false;	
		});
	}

	/**
	 * * SELECT RECENT COLOR *
	 * Todo: to select recent colors
	 */
	selectRecentColors(color){
		this.onChange.emit(color);
	}

	/**
	 * * INIT RECENT COLOR *
	 * Todo: to init recent colors
	 */
	initRecentColor(){
		this.recentColors = this.favoriteColor.split(',')||[];
		this.recentColors = this.recentColors.filter((x)=> x !== '');
	}

	/**
	 * * TOGGLE COLOR OVERLAY PANEL *
	 * Todo: to select recent colors
	 * @param e : event
	 */
	toggleColorOverlayPanel(e:any){
		this.displayColorOverlay = !this.displayColorOverlay;
		if(this.displayColorOverlay) this.colorOverlay.show(e);
		else this.colorOverlay.hide();
	}
	
	/**
	 * * EVENT ON HIDE COLOR OVERLAY *
	 * Todo: event on hide color overlay
	 */
	onHideColorOverlay(){
		this.displayColorOverlay = false;
		this.onHide.emit(this.favoriteColor);
	}

	/**
	 * * EVENT ON SHOW COLOR OVERLAY *
	 * Todo: event on show color overlay
	 */
	onShowColorOverlay(){
		this.initRecentColor();
		this.displayColorOverlay = true;
		this.onShow.emit();
	}
}
