import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SsrCookieService as CookieService } from 'ngx-cookie-service-ssr';
import { MainService } from '../services/main.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

	constructor(
		public mainService: MainService,
		private cookieService: CookieService,
	) {}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		
		if(this.mainService.isBrowser){
			if(this.cookieService.get("villume_token")){
				return true;
			}else{
				window.location.href = "/404"
				return false;
			}
		}
	}
  
}
