<div class="wrap-set-noification offset16-3">
    <h6 class="title">Email Notifications</h6>
    <div class="form-group">
        <label class="mr-2">Turn off all</label>
        <p-inputSwitch [(ngModel)]="turnOff" (onChange)="actionTurnOff()"></p-inputSwitch>
    </div>
    <div class="form-group d-flex">
        <label class="costum-checkbox">
            <input type="checkbox" [(ngModel)]="privacyCookie">
            <span class="checkmark"></span>
        </label>
        <p class="ml-3">Privacy and cookie policy changes</p>
    </div>
    <div class="form-group d-flex">
        <label class="costum-checkbox">
            <input type="checkbox" [(ngModel)]="pricingChanges">
            <span class="checkmark"></span>
        </label>
        <p class="ml-3">Pricing changes</p>
    </div>
    <div class="form-group d-flex">
        <label class="costum-checkbox">
            <input type="checkbox" [(ngModel)]="exhibitionStart">
            <span class="checkmark"></span>
        </label>
        <p class="ml-3">One day before my exhibitions starts</p>
    </div>
    <div class="form-group d-flex">
        <label class="costum-checkbox">
            <input type="checkbox" [(ngModel)]="exhibitionEnded">
            <span class="checkmark"></span>
        </label>
        <p class="ml-3">My exhibitions ended</p>
    </div>
    <button class="btn btn-load-primary ml-auto d-flex" (click)="saveNotif()" [class.loading-button-primary]="loading">
        Save Changes
    </button>
</div>