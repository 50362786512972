import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "@environments"
import { MainService } from 'src/app/shared/services';

@Injectable({
  	providedIn: 'root'
})
export class ModelTesterService {

	constructor(
		private http: HttpClient,
		public mainService: MainService
	) { }

	/**
	 * * UPLOAD MODEL / LIGTMAP *
	 * Todo: for uploading asset exhibiton (only for testing)
	 * @param type : String => "light-map", "model-path"
	 * @param file : formData Object
	 */
	uploadModel(type,file){
		return this.http.post(`${environment.baseURL}/upload-for-tester/${this.mainService.exhibitIdTester}?type=${type}`, file );
	}

	/**
	 * * RESET CAMERA POSITION * 
	 * Todo: for reset camera position to (x = 0,y = 0, z = 0)
	 */
	resetCameraPosition(){
		const query = `
			mutation {
				update_camera(
					where: {
						exhibitions: {
							id: {_eq: "${this.mainService.exhibitIdTester}"}
						}
					}, 
					_set: {
						position: {
							position_x: 0,
							position_y: 0, 
							position_z: 0
						},
						target: {
							target_x: 0, 
							target_y: 0, 
							target_z: 0
						}
					}
				) {
					affected_rows
				}
			}
		`;
		return this.mainService.queryGraphql(query);
	}
}
