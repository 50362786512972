@import '../../../assets/scss/common/variables';

.dialog-mask {
  background-color: rgba($color: #131313, $alpha: 0.35);
  height: 100%;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition-property: background-color;
  width: 100%;
}

.notSubsDialog {
  &.p-dialog {
    background-color: #9F9F9F;
    @media (max-width: $small-screen) {
      width: calc(100% - 30px) !important;
    }

    .p-dialog-header {
      color: #171717;
      font-family: $font-whyte;
      font-size: 22px;
      padding: 67px 0 5px 88px;

      @media (max-width: $small-screen) {
        padding: 57px 20px 5px 20px;
      }

      span {
        @media (max-width: $small-screen) {
          font-size: 25px;
          line-height: 103%;
        }
      }

      .btn-close {
        position: absolute;
        top: 20px;
        right: 20px;
        background: transparent;
        border: 0;
        outline: none;
        font-size: 16px;
        line-height: 14px;
        padding: 4px;
      }
    }

    .p-dialog-content {
      padding: 10px 85px 25px 111px;

      @media (max-width: $small-screen) {
        padding: 10px 20px 25px 20px;
      }

      p {
        color: #313131;
        font-size: 16px;

        @media (max-width: $small-screen) {
          font-size: 14px;
        }
      }
    }

    .p-dialog-footer {
      padding: 0px 85px 50px 111px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @media (max-width: $small-screen) {
        padding: 0px 15px 50px 15px;
      }

      .button {
        margin-left: 52px;
        outline: none;
        border: 0;
        padding: 13px 25px 8px;
        border-radius: 40px;
        font-size: 16px;
        line-height: 19.2px;
        color: #292929;
        background-color: #D3D3D3;
        
        &-cancel,
        &-subs {
          &:hover {
            background-color: #E7E7E7;
          }

          &:focus {
            background-color: #D3D3D3;
          }
        }

        &-cancel {
          @media (max-width: $small-screen) {
            margin-left: 0;
          }
        }

        &-subs {
          background-color: #FFDA16;

          @media (max-width: $small-screen) {
            margin-left: auto;
          }
        }
      }
    }
  }
}