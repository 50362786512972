// Common Angular modules/components
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';

// User-defined services
import { MainService, store } from 'src/app/shared/services';
import { EditorService } from '../editor.service';
import { AlertMessageService } from 'src/app/components/alert-message/alert-message.service';
import { ObjectsService } from './objects.service';

// Third party plugins (NPM)
import * as _ from 'lodash'
import watch from "redux-watch";

// User-defined interfaces
import { FileInputEvent, OrdinaryObject } from '@interfaces';

// External data
import { messages } from '@data';
import { LoadingGalleryService } from 'src/app/components/loading-gallery/loading-gallery.service';
import { environment } from '@environments';
import { UndoRedoService } from 'src/app/shared/services/undo-redo.service';
import { DialogModule } from 'primeng/dialog';
import { AlignObjectComponent } from '../../../../components/align-object/align-object.component';
import { SliderModule } from 'primeng/slider';
import { InputNumberComponent } from '../../../../components/input-number/input-number.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-objects',
    templateUrl: './objects.component.html',
    styleUrls: ['./objects.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgIf, NgFor, FormsModule, ReactiveFormsModule, InputNumberComponent, SliderModule, AlignObjectComponent, DialogModule]
})
export class ObjectsComponent implements OnInit {

	public objectName:any = new FormControl("", [
		Validators.required,
		Validators.pattern(`^[^'"]*$`)
	]);
	public scalingValue: number = 0;
	public rotateX: number = 0;
	public rotateY: number = 0;
	public rotateZ: number = 0;

	public displayConfirmDelete: boolean = false;
	public env = environment;


	constructor(
		private alertMessageService: AlertMessageService,
		public editorService: EditorService,
		public mainService: MainService,
    public objectsService: ObjectsService,
		public loadingGalleryService: LoadingGalleryService,
		private _undoRedoService: UndoRedoService
	) {
		this._initReduxStatesWatchers();
	}

	ngOnInit(): void {
		this.initDetailDataAndEvents();
	}

	ngOnDestroy(): void {
		this._unsubscribeReduxStatesWatchers();
	}

	/**
	 * * ========================== *
	 * * FUNCTIONS LIST
	 * * ========================== *
	 * - ADJUST ROTATION ORDINARY OBJECT
	 * - ADJUST POSITION/ROTATION ORDINARY OBJECT VIA AXIS
	 * - ALIGN POSITION ORDINARY OBJECT
	 * - INIT KEYBOARD EVEN
	 * - UNSELECT ORDINARY OBJECT
	 * - INIT DETAIL DATA & EVENT
	 * - INIT INPUTS VALUE
	 * - UPDATE INPUT VALUE
	 * - SELECT ORDINARY OBJECT VIA LIST
	 * - UPLOAD GLB FILE
	 * - GET POSITION IN FRONT OF CAMERA
	 * - VALIDATION UPLOADED FILE
	 * - ADJUST SCALING ORDINARY OBJECT
	 * - DELETE ORDINARY OBJECT
	 * - RENAME ORDINARY OBJECT
	 * - INIT KEYBOARD EVENT
	 */

	/**
	 * * ADJUST ROTATION ORDINARY OBJECT *
	 * Todo: to ajust rotation ordinary object
	 */
	adjustRotation(){
		this.editorService.updateOrdinaryObjectData(
			this.editorService.activeOrdinaryObjectNode,
			this.editorService.activeOrdinaryObject
		)

		this.editorService.dataHasChanges = true;
		this.editorService.updateLogActivity("Update object rotation");
	}

	

	/**
     * * ADJUST POSITION/ROTATION ORDINARY OBJECT VIA AXIS *
     * Todo: show/hide rotation or position object axis
     */
	adjustPositionOrRotationByAxis(action: "rotation" | "position"){
		if(!this.editorService.gizmos[action].attachedMesh){
      this.editorService.hideAxis();
			this.editorService.showAxis(action,this.editorService.activeOrdinaryObjectNode);
			this.editorService.updateLogActivity("Show "+action+" axis")
		}else{
			this.editorService.updateLogActivity("Hide axis")
			this.editorService.hideAxis();
		}
    }

	/** 
	 * * SHOW/HIDE ALIGN LIMIT *
	 * Todo: for show and hide align limit object
	 */
	showAlignLimitMesh(event){
		// change value 'showAlignLimit' variable 
		this.editorService.topLimit.visibility = event.visibility;
		this.editorService.bottomLimit.visibility = event.visibility;
		this.editorService.showAlignLimit =  event.visibility;

		this.editorService.updateLogActivity(event.update);
	}

	adjustAlignLimit(event){
		if(event.topLimit !== undefined) this.editorService.topLimit.position.y = event.topLimit;
		if(event.bottomLimit !== undefined) this.editorService.bottomLimit.position.y = event.bottomLimit;
	}

  	/**
	 * * ALIGN POSITION ORDINARY OBJECT  *
	 * Todo: align position object
	 */
  alignOrdinaryObject(event){
		// align object based on position
		const activeOrdinaryObject = this.editorService.activeOrdinaryObjectNode;
		// activeOrdinaryObject.position.y = event.alignValues[0].value;
		this.editorService.updateLogActivity("Align ordinary object to " + event.position);
		this.editorService.updateOrdinaryObjectData(activeOrdinaryObject,this.editorService.activeOrdinaryObject);
		this.editorService.dataHasChanges = true;
	}

	/**
	 * * INIT KEYBOARD EVENT *
	 * Todo: to initialize keyboard event
	 */
	initKeyboardEvent(){
		// Create handle event
		window.onkeydown = (e)=>{
			if(!this.editorService.onInput){
				const key = e.keyCode;
				const deleteKeys = [46,8];

				// Open confirm delete popup
				if(deleteKeys.includes(key) && this.editorService.activeOrdinaryObjectNode) {
					this.displayConfirmDelete = true;
				}

				// Delete ordinary object uing "enter" key
				if(key==13&&this.displayConfirmDelete) {
					this.deleteOrdinaryObject();
				}
			}
		}
	}

	/**
	 * * UNSELECT ORDINARY OBJECT *
	 * Todo: to unselect ordinary object
	 */
	 unselectObject(){
		if(this.editorService.ordinaryObjectDataValid){
			this.editorService.showAlignLimit = false;
			this.editorService.topLimit.visibility = this.editorService.bottomLimit.visibility = 0;
			this._undoRedoService.clearInvalidStates();
			this.editorService.updateLogActivity('Unselect object')
			this.editorService.unselectExhibitAsset()
		}else{
			this.alertMessageService.add({severity:"warn",summary:"Warning", detail:"The Objects tab contains invalid data. Please update the data."})
		}
	}

	/**
	 * * INIT DETAIL DATA & EVENT *
	 * Todo: to init data and event for detail object based on active object
	 */
	initDetailDataAndEvents(){
		if(this.editorService.activeOrdinaryObjectNode){
			this.initInputsValue();
			this.initKeyboardEvent();
		}
	}

	/**
	 * * INIT INPUTS VALUE *
	 * Todo: to initialize inputs value
	 */
	initInputsValue(){
		this.objectName.setValue(this.editorService.activeOrdinaryObject.name);
		this.scalingValue = Math.round(this.editorService.activeOrdinaryObjectNode.scaling.x*10);
		this.rotateX = Math.round(this.editorService.activeOrdinaryObjectNode.rotation.x * (180/Math.PI))
		this.rotateY = Math.round(this.editorService.activeOrdinaryObjectNode.rotation.y * (180/Math.PI))
		this.rotateZ = Math.round(this.editorService.activeOrdinaryObjectNode.rotation.z * (180/Math.PI))
		this.editorService.ordinaryObjectDataValid = this.objectName.valid;
		this._setLightIntensityValue();
	}

	/**
	 * * UPDATE INPUT VALUE *
	 * Todo: to update input value
	 */
	updateInputValue(value,type,min,max){
		// validate input value
		value = this.editorService.validateSliderManualInput(value,min,max);

		// Update ordinary object data
		switch (type) {
			case 'rotateX': 
				this.editorService.activeOrdinaryObjectNode.rotation.x = value / (180/Math.PI); 
				this.adjustRotation();
			break;
			case 'rotateY': 
				this.editorService.activeOrdinaryObjectNode.rotation.y = value / (180/Math.PI); 
				this.adjustRotation();
				break;
			case 'rotateZ': 
				this.editorService.activeOrdinaryObjectNode.rotation.z = value / (180/Math.PI); 
				this.adjustRotation();
				break;
			case 'scaling':
				this.adjustScaling("start");
				this.editorService.activeOrdinaryObjectNode.scaling.x = value / 10;
				this.adjustScaling("on");
				this.adjustScaling("end");
			break;
		}
	}

	updateInputValueWithDelay(value,type,min,max){
		setTimeout(()=>{
			this.updateInputValue(value,type,min,max);
			if(['rotateX','rotateY','rotateZ'].includes(type)) {
				this.adjustRotation();
				this.initInputsValue();
			}
		},100)
	}

	/**
	 * * SELECT ORDINARY OBJECT VIA LIST *
	 * Todo: to selecting ordinary object via list
	 */
	selectObject(object){
		if(!this.loadingGalleryService.show && this.objectsService.loadedOrdinaryObjects.includes(object.id)){
			// Get object mesh based on selected id
			const objectMesh = this.editorService.scene.getTransformNodeByID(`ordinaryObject-${object.id}`)
			// Select object
			this.editorService.selectExhibitAsset(objectMesh);
		}
	}

	/**
	 * * TOOGLE LOCK CAMERA *
	 * Todo: to toggle lock camera when drag ordinary object
	 */
	toggleLockCamera(){
		this.editorService.lockCameraWhenDragOrdinaryObject=!this.editorService.lockCameraWhenDragOrdinaryObject;
		if(this.editorService.lockCameraWhenDragOrdinaryObject){
			this.editorService.updateLogActivity("Lock Camera movement when drag ordinary object")
		}else{
			this.editorService.updateLogActivity("Unlock Camera movement when drag ordinary object")
		}
	}


	/**
	 * * RENAME ORD	NARY OBJECT *
	 * Todo: to deleting ordinary object
	 */
	renameOrdninaryObject(){
		this.editorService.ordinaryObjectDataValid = this.objectName.valid;
		this.editorService.activeOrdinaryObject.name = this.objectName.value;
		this.editorService.dataHasChanges = true;
		this.editorService.validateExhibitionData();
		store.dispatch({type:"VALIDATE_DATA", validateData: new Date().getTime() });
		this.editorService.updateLogActivityWithDelay("rename ordinary object");
    	this.editorService.updateUndoRedoStateWithDelay();
	}

	/**
	 * ANCHOR Get List Of Ordinary Objects
	 * @description to get list of ordinary objects that not deleted
	 * @returns : OrdinaryObject[]
	 */
	public getObjects(): OrdinaryObject[] {
		return this.editorService.ordinaryObjects.filter((object) => !object.deleted);
	}

	

		/**
  * * =============================================================================== *
  *   SECTION Scaling Ordinary Object Functions
  * * =============================================================================== *
  */

	//#region 
	private _ratioYX: number;
	private _ratioZY: number;

	/**
	 * ANCHOR Adjust Ordinary Object Scaling
	 * @description to adjust ordinary object scaling
	 * @param event : 'on' | 'start' | 'end'
	 */
	public adjustScaling(event: 'on' | 'start' | 'end'): void {
		switch(event){
			case "start": this._startScalingHandler(); break;
			case "on": this._onScalingHandler(); break;
			case "end": this._endScalingHandler(); break;
		}
	}

	/**
	 * ANCHOR Start Scaling Handler
	 * @description to handle start scaling
	 */
	private _startScalingHandler(): void {
		const objectMesh = this.editorService.activeOrdinaryObjectNode;
		this._ratioYX = objectMesh.scaling.y / objectMesh.scaling.x;
		this._ratioZY = objectMesh.scaling.z / objectMesh.scaling.y;
	}

	/**
	 * ANCHOR On Scaling Handler
	 * @description to handle on scaling
	 */
	private _onScalingHandler(): void {
		const objectMesh = this.editorService.activeOrdinaryObjectNode;
		objectMesh.scaling.y = objectMesh.scaling.x * this._ratioYX;
		objectMesh.scaling.z = objectMesh.scaling.y * this._ratioZY;
		this.initInputsValue();
	}

	private _endScalingHandler(): void {
		const objectMesh = this.editorService.activeOrdinaryObjectNode;
		this.editorService.dataHasChanges = true;
		this.editorService.updateOrdinaryObjectData(objectMesh,this.editorService.activeOrdinaryObject)
		this.editorService.updateLogActivityWithDelay("Update scaling ordinary object")
	}

	//#endregion
	//!SECTION


	/**
  * * =============================================================================== *
  *   SECTION Delete Ordinary Object Functions
  * * =============================================================================== *
  */

	//#region 
	public onDelete: boolean = false;

	/**
	 * ANCHOR Delete Ordinary Object
	 * @description to delete ordinary object
	 */
	public deleteOrdinaryObject(): void {
		this.editorService.deleteOrdinaryObjectFromScene(this.editorService.activeOrdinaryObject.id);
		this.alertMessageService.add({severity: "success", summary: "Success", detail: "Your ordinary object has been successfully deleted"})
		this.displayConfirmDelete = false;
	}

	//#endregion
	//!SECTION


	/*k*
  * * =============================================================================== *
  *   SECTION Add New Ordinary Object Functions
  * * =============================================================================== *
  */

	//#region 
	/**
	 * ANCHOR Upload Ordinary Object
	 * @description to upload ordinary object
	 * @param e : FileInputEvent
	 */
	public uploadObject(e: FileInputEvent): void {
		const file = e.target.files[0];
		if (file) {
			if (this._validationFile(file)) {
				const _loadingProgress = (condition): void => {
					this.loadingGalleryService.percent = 0;
					this.loadingGalleryService.show = condition;
					this.editorService.blockUserAction = condition;
				}

				_loadingProgress(true)
				const payload = this._createPayload(file);
				this.editorService.uploadOrdinaryObject(payload).subscribe((res:any)=>{
					if(res['status'] == 'progress'){
						this.loadingGalleryService.percent = res['data'];
					}

					if(res['status'] == 'response'){
						const newOrdinaryObject = res['data'].data.ordinary_data[0];
						this._appendToList(newOrdinaryObject);

						const onLoaded = () =>{
							_loadingProgress(false);
							this.alertMessageService.add({
								severity: 'success', 
								summary: 'Success',
								detail: messages.editor.ordinaryObject.add.success
							});
							this.editorService.updateUndoRedoState();
						}

						const onError = () => {
							_loadingProgress(false);
							this.alertMessageService.add({
								severity: 'error',
								summary: 'Failed',
								detail: messages.editor.ordinaryObject.add.failedToAdd
							});
						}

						const onLoad = (e) => {
							const loadPercentage = Math.round(e.loaded / (file.size * 2) * 100)
							this.loadingGalleryService.percent = 50 + loadPercentage;
						}

						this.objectsService.loadOrdinaryObject(newOrdinaryObject, { onLoaded, onLoad, onError });
					}
				},err=>{
					_loadingProgress(false);
					if(err.error.statusCode == 401){
						this.mainService.expiredSesionPopup = true;
					}else {
						this.alertMessageService.add({
							severity: 'error',
							summary: 'Failed',
							detail: messages.editor.ordinaryObject.add.failedToAdd
						});
					}
				})
			}
		}

		e.target.value = "";
	}

	/**
	 * ANCHOR Append New Ordinary Object To List
	 * @description to append new ordinary object to list
	 * @param ordinaryObject : OrdinaryObject
	 */
	private _appendToList(ordinaryObject: OrdinaryObject): void {
		ordinaryObject.model_path = this.mainService.setModelPath(ordinaryObject.model_path);
		ordinaryObject.deleted = false;
		this.editorService.ordinaryObjects.unshift(ordinaryObject);
	}

	/**
	 * ANCHOR Validation File
	 * @description to validate file before upload
	 * @param file: File
	 * @return : boolean
	 */
	private _validationFile(file: File): boolean {
		const extension = this.mainService.getFileExtension(file);
		const allowedFormat = ["glb","gltf"];
		if (allowedFormat.includes(extension)) {
			if (file.size < this.editorService.limitUploadSize) {
				return true
			} else {
				this.alertMessageService.add({ 
					severity: 'warn',
					summary: 'Warning',
					detail: messages.editor.ordinaryObject.add.tooLarge
				});

				return false
			}
		} else {
			this.alertMessageService.add({
				severity: 'warn',
				summary: 'Warning',
				detail: messages.editor.ordinaryObject.add.notSupportedFormat
			});

			return false
		}
	}

	/**
	 * ANCHOR Create Payload For Upload Ordinary Object API
	 * @description to create payload for upload ordinary object API
	 */
	private _createPayload(file: File): FormData {
		const { position } = this.editorService.getInitialPositionAssets('ordinary-object');

		const formData = new FormData();
		formData.append('object', file);
		formData.append('exhibition_id', this.editorService.exhibition.id);
		const objectData = {
			name: this.mainService.getFileName(file),
			position: {
				position_x: position.x,
				position_y: position.y,
				position_z: position.z
			},
			light_intensity: this.editorService.exhibition.config.defaultIntensityObject,
		}

		formData.append('ordinary_data', JSON.stringify(objectData).replace(/"/g,'\\"'));

		return formData;
	}
	//#endregion
	//!SECTION


	/**
  * * =============================================================================== *
  *   SECTION Light Intensity Setting Functions
  * * =============================================================================== *
  */

	//#region 
	public lightIntensityValue: number = 0;

	/**
	 * ANCHOR Set Light Intensity Value
	 * @description to set light intensity value for light intensity slider
	 */
	private _setLightIntensityValue(): void {
		this.lightIntensityValue = this.editorService.activeOrdinaryObject.light_intensity * 20;
	}

	/**
	 * ANCHOR Adjust Light Intensity
	 * @description to adjust light intensity for active ordinary object
	 */
	public ajudstLightIntensity(value: number): void {
		this.lightIntensityValue = value;
		this.editorService.activeOrdinaryObject.light_intensity = this.lightIntensityValue / 20;
		this.objectsService.adjustLighting(this.editorService.activeOrdinaryObject);
		this.editorService.updateUndoRedoStateWithDelay();
	}

	//#endregion
	//!SECTION


	/**
  * * =============================================================================== *
  *   SECTION Redux State Watcher Functions
  * * =============================================================================== *
  */

	//#region 
	private _stateWatchers: any[] = [];

	/**
	 * ANCHOR Init Redux States Watchers
	 * @description to init redux states watchers
	 */
	private _initReduxStatesWatchers(): void {
		const selectObjectWatch = watch(store.getState, "editor.objectHasSelected")
    this._stateWatchers.push(
			store.subscribe(selectObjectWatch(e => {
				this.initDetailDataAndEvents();
			}))
		);

		const updateObjectDataWatch = watch(store.getState, "editor.updateObjectData")
    this._stateWatchers.push(
			store.subscribe(updateObjectDataWatch(e => {
				if(this.editorService.activeOrdinaryObject){
					this.initInputsValue();
				}
			}))
		)

		const undoRedoWatch = watch(store.getState, "editor.undoRedo")
		this._stateWatchers.push(
      store.subscribe(undoRedoWatch(e => {
				if(this.editorService.activeOrdinaryObject){
					this.initInputsValue();
				}
			}))
		)
	}

	/**
	 * ANCHOR Unsubscribe Redux States Watchers
	 * @description to unsubscribe redux states watchers
	 */
	private _unsubscribeReduxStatesWatchers(): void {
		this._stateWatchers.forEach(watcher => watcher())
	}

	//#endregion
	//!SECTION
}
