import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditorService } from '../../editor.service';
import { debounce } from "lodash";
import moment from 'moment';
import { MainService,store } from 'src/app/shared/services';
import { ImagesService } from '../images.service';
import watch from 'redux-watch';
import { TextToSpeechService } from 'src/app/shared/services/text-to-speech.service';
import { CreateTextToSpeech } from 'src/app/shared/interfaces/create-text-to-speech';
import { InputSwitchModule } from 'primeng/inputswitch';
import { NgClass, NgIf, NgStyle } from '@angular/common';



@Component({
    selector: 'app-image-info',
    templateUrl: './image-info.component.html',
    styleUrls: ['./image-info.component.scss'],
    standalone: true,
    imports: [NgClass, InputSwitchModule, FormsModule, NgIf, ReactiveFormsModule, NgStyle]
})
export class ImageInfoComponent implements OnInit, OnDestroy {
  public artworkDesc: FormControl = new FormControl("");
	public artworkPricing: FormControl = new FormControl("", [Validators.maxLength(20)]);
  public artworkMaterial: FormControl = new FormControl("");
	public artworkDimension: FormControl = new FormControl("", [
		Validators.maxLength(20)
	]);
  public requestArtworkValid: boolean = false;
	public yearMaxLimit = Number(moment().format("YYYY"))
	private _selectObjectWatchSubscription: any;


  constructor(
    public editorService: EditorService,
		public mainService: MainService,
		private _artworkService: ImagesService,
		private _textToSpeechService: TextToSpeechService
  ) { 
		this._watchSelectedObjectState();
	}

  ngOnInit(): void {
		this._setValues()
  }

	ngOnDestroy(): void {
		this._selectObjectWatchSubscription();
	}

	/**
	 * * INIT ARTWORK INFO VALUES *
	 * Todo: to init artwork info values
	 */
	private _setValues(): void {
		const artwork = this.editorService.activeArtwork;
		this.artworkDesc.setValue(artwork.description);
		this.artworkPricing.setValue(artwork.pricing_amount);
		this.artworkDimension.setValue(artwork.dimension);
		this.artworkMaterial.setValue(artwork.material);
	}

	/**
   * * WATCH SELECTED OBJECT STATE *
   * Todo: to watch selected object state from redux store
   */
  private _watchSelectedObjectState(): void {
    const selectObjectWatch = watch(store.getState, 'editor.objectHasSelected')
		this._selectObjectWatchSubscription = store.subscribe(selectObjectWatch((e: boolean) => {
      if (e && this.editorService.activeArtworkNode) {
        this._setValues();
      }
		}));
  }

  /**
   * * HANDLE ON CHANGE SWITCH ARTWORK INFO
   * Todo : to handle on change event of switch input artwork info
   */
  public handleOnChangeSwitchArtworkInfo(): void {
    const status = this.editorService.activeArtwork.artwork_info ? 'Enabled' : 'Disabled';
		this.editorService.updateLogActivity(`${status} artwork info`);
		this.editorService.updateUndoRedoState();
	}

	/**
	 * * UPDATE ARTWORK INFO *
	 * Todo: to update artwork info
	 * @param prop : string -> property name of artwork data
	 * @param value : string -> value of property
	 */
  public updateArtworkInfo = debounce((prop: string, value: string) => {
		this._validateValues();
		this.editorService.activeArtwork[prop] = value.trim();
		this.editorService.activeArtwork.edited_description = true;
		this.editorService.updateLogActivity("Update artwork " + prop.replace(/_/g," "))
		this.editorService.updateUndoRedoState()
	},500)

	/**
	 * * BLOCK ALPHABET *
	 * Todo: to block alphabet when typing
	 * @param event : KeyboardEvent
	 * @returns : boolean
	 */
	public blockAlphabet(event: KeyboardEvent): boolean {
		const allowedChars = ["Enter","1","2","3","4","5","6","7","8","9","0"];
		return allowedChars.includes(event.key)
	}

  /**
	 * * UPDATE YEAR *
	 * Todo: to update
	 * @param value : string
	 */
	public updateYear(value: string){
		let tmpValue = parseInt(value);

		if(tmpValue && typeof tmpValue == "number"){
			if(tmpValue > this.yearMaxLimit) tmpValue = this.yearMaxLimit;
			if(tmpValue < 1) tmpValue = null;
		}else{
			tmpValue = null;
		}

		this.editorService.activeArtwork.year = String(tmpValue || '');
		this.editorService.updateLogActivity("update year")
		this.editorService.updateUndoRedoState();
	}

  /**
	 * * VALIDATE INPUT *
	 * Todo: to validate input value
	 */
	private _validateValues(): void {
    this._artworkService.artworkInfoValid = this._isValidValues();
		this.editorService.exhibitionDataValid = this._isValidValues()
		this._artworkService.validateAllArtworkData();
	}

	/**
	 * * IS VALID VALUES *
	 * Todo: to check if all values are valid
	 * @returns : boolean
	 */
  private _isValidValues(): boolean {
    return (
			this.artworkDesc.valid &&			
			this.artworkMaterial.valid &&
			this.artworkDimension.valid &&
			this.artworkPricing.valid
    )
  }

	/**
	 * * =============================================================== *
	 * * TEXT TO SPEECH FUNCTIONS
	 * * =============================================================== *
	 */

	/**
	 * * CREATE TEXT TO SPEECH *
	 * Todo: to create text to speech
	 * @param text : string
	 * @param sid : string
	 * @returns : Observable
	 */
	public createTextToSpeech(text: string): void {
	// 	if (!text) return;
	// 	const sid: string = this.editorService.exhibition.id;
	// 	const artworkId: string = this.editorService.activeArtwork.id;

	// 	this._textToSpeechService.createTextToSpeech(text, sid).subscribe({
	// 		next: (res: any) => {
	// 			this._textToSpeechService.streamUrlArtwork.push({
	// 				id: artworkId,
	// 				url: res['data'].stream
	// 			});
	// 		},
	// 		error: (err: any) => {
	// 			console.log(err);
	// 		}
	// 	})
	}
}
