import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { AllowedFile } from '../../images.interfaces';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from '@environments';
import { NgFor, NgIf } from '@angular/common';
import { ScrollPanelModule } from 'primeng/scrollpanel';

@Component({
    selector: 'multi-preview',
    templateUrl: './multi-preview.component.html',
    styleUrls: ['./multi-preview.component.scss'],
    standalone: true,
    imports: [
      FormsModule,
      ReactiveFormsModule,
      NgFor,
      NgIf,
      ScrollPanelModule
    ]
})
export class MultiPreviewComponent implements OnInit {
  @Input() disabled: boolean = false;
  @Input() files: AllowedFile[];

  @Output() onBack: EventEmitter<any> = new EventEmitter();
  @Output() onRename: EventEmitter<any> = new EventEmitter();
  @Output() onValidate: EventEmitter<any> = new EventEmitter();

  public artworkNames: FormGroup = new FormGroup({});
  public env = environment;
  
  ngOnInit(): void { this._createArtworkFileNameInputs() }

  /**
   * ANCHOR Create Artwork File Name Inputs
   * @description to create artwork file name inputs
   */
  private _createArtworkFileNameInputs(): void {
    this.files.forEach((file: AllowedFile) => {
      this.artworkNames.addControl(file.id, new FormControl('', Validators.required));
    });
  }

  /**
   * ANCHOR Get Control Names
   * @description to get control names
   * @returns : String[] -> control names
   */
  public getControlNames(): any {
    return Object.keys(this.artworkNames.controls);
  }

  /**
   * ANCHOR Rename Artwork
   * @description to rename artwork
   * @param controlName : string -> control name
   */
  public renameArtwork(controlName: string): void {
    const control = this.artworkNames.get(controlName);
    const value = control.value.trimStart();
    const newValue = value.replace(/\\/g, '').replace(/\"/g, '');
    control.setValue(newValue.trimStart());
    this.files.find((file: AllowedFile) => file.id === controlName).name = control.value;
    this.onValidate.emit(this.artworkNames.valid);
    this.onRename.emit(this.files);
  }
}
