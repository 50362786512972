<div class="button-name-figure">
  <div class="contant-name-figure">
      <div class="wrap-title-figure">
          <!-- ARTWORK THUMBNAIL -->
          <div class="wrap-image-figure">
              <img 
              [defaultImage]="env.staticAssets+'images/other/skeleton.svg?t='+mainService.appVersion" 
              [lazyLoad]="editorService.activeArtwork.image" 
              class="img-figure">
              <img 
                *ngIf="editorService.activeArtwork.file_type === 'figure-object'" 
                src="{{env.staticAssets}}images/other/badge3D.png?t={{mainService.appVersion}}" 
                class="badge"/>
              <a 
                  *ngIf="editorService.activeArtwork.file_type === 'figure-image'" 
                  (click)="uploadInput.click()" 
                  [class.procces]="onUploading"
                  href="javascript:;" 
                  class="edit-image"
                  [class.loading-button-primary]="onUploading">
                  Edit
              </a>
              <input 
                type="file" 
                [disabled]="onUploading" 
                hidden 
                #uploadInput 
                (change)="editArtwork($event)">
          </div>
          <!-- ARTWORK NAME -->
          <input 
              type="text"
              (focus)="editorService.onInput = true"
              (blur)="editorService.onInput = false"
              (input)="renameArtwork()" 
              #renameInput
              [formControl]="artworkName"
              [readonly]="editorService.blockUserAction"
              class="name-figure"
              (keydown)="mainService.blockChars($event); mainService.blockSpaceAtStart($event, renameInput)">
      </div> 
  </div>
  <div *ngIf="artworkName.errors" class="validation-error">
      <p *ngIf="artworkName.errors?.required">Name is required.</p>
  </div>
</div>