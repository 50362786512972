import { Component } from '@angular/core';
import { InputNumberComponent } from 'src/app/components/input-number/input-number.component';
import { TextService } from '../../../service/text-service.service';
import { EditorService } from '../../../../editor.service';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { NgStyle } from '@angular/common';
import { TextLoaderService } from '../../../service/text-loader.service';

@Component({
  selector: 'app-font-family',
  standalone: true,
  templateUrl: './font-family.component.html',
  styleUrl: './font-family.component.scss',
  imports: [
    NgStyle,
    FormsModule,
    InputNumberComponent,
    DropdownModule
  ],
})
export class FontFamilyComponent {

  public fontStyle: any = [
    { name: 'Regular' },
    { name: 'Bold' },
    { name: 'Italic' },
    { name: 'Bold Italic' },
  ];


  constructor(
    public textService: TextService,
    public editorService: EditorService,
    public textLoader: TextLoaderService
  ) { }

  /**
   * * EDIT FONT FAMILY *
   * ANCHOR Edit font family
   * @description for updated font family
   */
  public editFontFamily(): void {
    if (this.editorService.selectedExhibitAssets.length > 1) {
      this.editorService.selectedExhibitAssets.forEach((node: any) => {
        const textWallData = this.textService.getTextWallData(node);
        textWallData.font_family = this.textService.selectedFontFamily.value;
        node.textGUI.fontFamily = this.textService.selectedFontFamily.value;
        this.textService.updateLineheight(node);
        node.changed = true;
      })
    } else {
      this.textLoader.activeText.font_family = this.textService.selectedFontFamily.value;
      this.textLoader.activeTextNode.textGUI.fontFamily = this.textService.selectedFontFamily.value;
      this.textService.updateLineheight(this.textLoader.activeTextNode);
      this.textLoader.activeTextNode.changed = true;
    }

    this.editorService.updateLogActivity("Update text font family");
    this.editorService.updateUndoRedoState()
  }

  /**
  * * EDIT FONT STYLE *
  * ANCHOR Edit font style
  * @description for updated font style
  */
  public editFontStyle(): void {
    if (this.editorService.selectedExhibitAssets.length > 1) {
      this.editorService.selectedExhibitAssets.forEach((node: any) => {
        const textWallData = this.textService.getTextWallData(node);
        textWallData.font_style = this.textService.selectedFontStyle.name;
        node.textGUI.fontStyle = this.textService.selectedFontStyle.name == "Regular" ? "" : this.textService.selectedFontStyle.name;
        this.textService.updateLineheight(node);
        node.changed = true;
      })
    } else {
      this.textLoader.activeText.font_style = this.textService.selectedFontStyle.name;
      this.textLoader.activeTextNode.textGUI.fontStyle = this.textService.selectedFontStyle.name == "Regular" ? "" : this.textService.selectedFontStyle.name;
      this.textService.updateLineheight(this.textLoader.activeTextNode);
      this.textLoader.activeTextNode.changed = true;
    }

    this.editorService.updateLogActivity("Update text font style");
    this.editorService.updateUndoRedoState()
  }
}
