import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments';
import { Base64 } from 'js-base64';
import { SplashScreen } from '@interfaces';

@Injectable({
  providedIn: 'root'
})
export class SplashScreenService {
  public undoRedoStates = [];
  public undoRedoIndex = 0;
  public isSaved: boolean = false;
  public splashScreenData: any;

  constructor(
    private _http: HttpClient
  ) { }

  /**
   * ANCHOR update splash screen
   * Todo: to update splash screen
   */
  public updateSplashScreen(body){
    return this._http.post(`${environment.baseURL}/splash_screen/update`, body);
  }

  /**
   * ANCHOR delete splash screen
   * Todo: to delete splash screen
   */
  public deleteSplashScreen(id){
    return this._http.delete(`${environment.baseURL}/splash_screen/${id}?ec=true`);
  }

  /**
   * ANCHOR get splash screen
   */
  public fetchSplashScreen(id){
    return this._http.get(`${environment.baseURL}/splash_screen/${id}?v=1&t=${new Date().getTime()}`);
  }

  /**
   * ANCHOR: Convert path to image url
   */
  public convertPathImage(path: string): string {
		let imageUrl = '';
		if (!path.includes(environment.image_path)) imageUrl = environment.image_path + path;
		else imageUrl = path;

    const pathImg = Base64.encode('local:///'+ path);
    imageUrl = environment.image_path + pathImg;

		return imageUrl;
	}

  /**
  * ANCHOR: Convert base64 to blob
  */
  public base64ToBlob(base64String) {
    const byteString = atob(base64String.split(',')[1]);
    const mimeString = base64String.split(',')[0].split(':')[1].split(';')[0];
  
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
  
    return new Blob([ab], { type: mimeString });
  }

  	/**
	 * * GET SPLASH SCREEN *
	 * Todo: to get splash screen
	 */
	public getSplashScreen(sid: string): Promise<SplashScreen> {
    return new Promise((resolve, reject) => {
      this.fetchSplashScreen(sid).subscribe({
        next: (res:any)=>{
          const data = res['data']['data'];
          this.splashScreenData = data;
          if (!data) this.splashScreenData = null;
          resolve(this.splashScreenData);
        },
        error: (err)=>{
          if (err.status === 401) {
            reject(401);
          } else {
            reject(err);
          }
        }
      })
    });
	}

  
  /**
   * SAVE SPLASH SCREEN
   */
  public saveSplashScreen(exhibition): Promise<void> {
    return new Promise((resolve, reject) => {
      if (
        exhibition['enableSplashScreen'] ==
        this.splashScreenData['active']
      ) return resolve();

      const formData = new FormData();
      formData.append('v', '1');
      formData.append('active', exhibition['enableSplashScreen'].toString());
      formData.append('sid', exhibition.id);
      formData.append('old_image', true.toString());

      this.updateSplashScreen(formData).subscribe({
        next: (res) => {
          this.splashScreenData['active'] = exhibition['enableSplashScreen']
          resolve();
        },
        error: (err) => {
          if (err.status === 401) {
            reject(401);
          } else {
            reject(err);
          }
        }
      })
    });
  }
}
