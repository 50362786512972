// Angular packages
import { Component, ViewEncapsulation } from '@angular/core';

// User-defined services
import { MainService, store } from 'src/app/shared/services';
import { EditorService } from '../../editor.service';
import { LoadingGalleryService } from 'src/app/components/loading-gallery/loading-gallery.service';
import { environment } from '@environments';
import { Artwork } from '@interfaces';
import { TooltipModule } from 'primeng/tooltip';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { CdkDropList, CdkDrag, CdkDragPlaceholder } from '@angular/cdk/drag-drop';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-list-images',
    templateUrl: './list-images.component.html',
    styleUrls: ['./list-images.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgIf,
        CdkDropList,
        NgFor,
        CdkDrag,
        CdkDragPlaceholder,
        LazyLoadImageModule,
        TooltipModule,
    ],
})
export class ListImagesComponent {
	public env = environment;

  constructor(
    public editorService: EditorService,
		public mainService: MainService,
		public loadingGalleryService: LoadingGalleryService
  ) { }

	/**
	 * ANCHOR Sort Artwork By
	 * @description to sort artwork by property
	 * @param prop : string -> property name
	 * @returns Array of Objets -> sorted artwork
	 */
	public sortArtworkBy(prop: string): Artwork[] {
		const artworks = this.editorService.artworks.filter(artwork => !artwork.deleted);
		return artworks.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
	}

  /**
	 * ANCHOR On Drop Artwork
	 * @description to drop artwork handler
	 * @param e : CdkDragDrop -> event
	 */
	public dropArtwork(e): void {
		this.editorService.dataHasChanges = true;
		let artworks = this.editorService.artworks;
		let previousItem = artworks[e.previousIndex];
		let currentItem = artworks[e.currentIndex];
		previousItem['sequence'] = e.currentIndex + 1;
		currentItem['sequence'] = e.previousIndex + 1;
		artworks[e.previousIndex] = currentItem;
		artworks[e.currentIndex] = previousItem;
		this.editorService.artworks = artworks;
		this.editorService.updateLogActivity("Update artwork sequence");
		this.editorService.updateUndoRedoState()
	}

	/**
	 * ANCHOR Is Item Disabled
	 * @description to check if item is disabled 
	 * @param artworkId : string -> artwork id
	 * @returns : boolean -> true if item is disabled
	 */
	public isItemDisabled(artworkId): boolean {
		return (
			this.loadingGalleryService.show || 
			this.editorService.focusAnimationIsRun || 
			this.editorService.onPlacingArtwork || 
			!this.editorService.loadedArtworks.includes(artworkId)
		)
	}

  /**
	 * ANCHOR Select Artwork
	 * @description to select artwork
	 * @param figure : Object -> artwork
	 */
	public selectArtwork(figure): void {
		if(!this.isItemDisabled(figure.id)){
			const figureMesh = this.editorService.scene.getTransformNodeByID(`artwork-${figure.id}`);
			if(!this.editorService.previewMode){
				this.editorService.updateLogActivity("Select object");
				this.editorService.selectExhibitAsset(figureMesh, null, true);
				store.dispatch({type: "UPDATE_STAT_SELECT_OBJECT", objectHasSelected: true});
			}
			else{
				if(!this.editorService.focusAnimationIsRun){
					this.editorService.focusOnArtworkAnimation(figureMesh);
				}
			}
		}
	}

	/**
	 * ANCHOR Is Artworks Not Empty
	 * @description to check if artworks is not empty
	 * @returns : boolean -> true if artworks is empty
	 */
	public isArtworksNotEmpty(): boolean {
		return this.editorService.artworks.filter(artwork => !artwork.deleted).length > 0;
	}
}
