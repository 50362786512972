<app-layouts #layout>
    <div class="wrap-main" content>
        <!-- START HERO SECTION
        =================================== -->
        <header class="hero-section">
            <div class="row">
                <!-- CONTENT
                ============================ -->
                <div class="col col16-6 offset16-1 col-iped-6 offset-iped-1 col-mobile-4 offset-mobile-0 hero-content">
                    <div class="content">
                        <!-- TITLE HERO SECTION -->
                        <h1 class="title" [innerHTML]="'t_home_page.t_hero.t_title' | translate">
                            Your Art Space
                        </h1>

                        <!-- DESCRIPTION HERO SECTION -->
                        <div class="desc">
                            <p >Villume is a platform developed in collaboration with international galleries & artists, creating the ideal environments for artwork to be displayed in a unique digital exhibition.</p>
                            <div class="wrap-button">
                                <button class="btn btn-outline-primary btn-dark2 btn-lg" (click)="getStarted()">Get Started</button>
                                <div class="wrap-btn-info">
                                    <i class="vi vi-start" style="font-size: 8px;"></i>
                                    <p class="info-button">no credit card required</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 

                <!-- VIDEO SECTION
                ================================= -->
                <div class="col col16-9 col-iped-8 wrap-video-header">
                    <div class="overlay-video">
                        <img *ngIf="!videoPlayed" style="position: absolute;" src="{{env.staticAssets}}images/home/thumbnail-hero.png?t={{mainService.appVersion}}" alt="thumbnail-hero" />
                        <div id="villume-video"></div>
                    </div>
                </div>
            </div>

        </header>
        <!-- END HERO SECTION -->

        <!-- START PROMOTION -->
        <section class="about-section">
            <!-- WRAP CONTENT
            ============================== -->
            <div class="wrap-content">

                <div class="row justify-content-between">
                    <div class="col col16-7 offset16-1 offset-iped-0 col-iped-8 wrap-desc">
                        <div class="content">
                            <!-- TITLE CONTENT -->
                            <h1 class="title">Create your photorealistic art space easily using our templates.</h1>

                            <!-- DESCRIPTION -->
                            <div class="desc offset-iped-1">
                                <p>
                                    Using our vast collection of templates, decide what environment best fits your art and create a unique exhibition in a few clicks with templates featuring modifiable and fixed interiors.<br>Can’t find a template that suits your display? Have a particular idea for your exhibition space’s design? Contact us, and you will get a custom-made gallery according to your specific vision.<br>Have your own gallery? We can make a copy of your existing layout exactly to exhibit artwork and sell pieces digitally.<br>
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- WRAP IMAGE SECTION -->
                    <div class="col col16-7 col-iped-7 offset-iped-1 col-mobile-4 offset-mobile-0">
                        <p class="info-Photorealistic">Photorealistic Spaces ready in seconds.</p>
                        <div 
                            class="wrap-animated-image" 
                            (click)="clearIntervalCarousel()"
                            (mouseleave)="isMobile ? stopAutoplayCarousel() : startAnimationPhotorealistic()"
                            (mouseenter)="clearIntervalCarousel()">
                            <div class="owl-carousel images-photorealistic sliding slide">
                                <div class="item" *ngFor="let item of imagesPhotorealistic;let i = index">
                                    <img src="{{env.staticAssets}}images/home/{{item}}.jpg?t={{mainService.appVersion}}" alt="" class="img-Photorealistic">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- END PROMOTION -->

        <!-- START PROMOTION
        ====================================== -->
        <section class="creativity-section">

            <!-- TITLE CONTENT -->
            <div class="row">
                <div class="col col16-7 col-iped-8 col-mobile-4">
                    <h1 class="title">Unleash Your Creativity.</h1>
                </div>
            </div>
            <div class="row">
                <!-- DESCRIPTION CONTENT -->
                <div class="col col16-7 col-iped-8 col-mobile-4 wrap-content">
                    <div class="content offset16-2">
                        <div class="desc">
                            <p class="w-100">
                                Using our powerful editor, get all the tools you need to unleash your full potential. Now you can expand the creative process of your art beyond only the artwork, you also have the full power over how it’s displayed.
                            </p>
                        </div>
                    </div>
                    <img src="{{env.staticAssets}}images/home/img-3.png?t={{mainService.appVersion}}" alt="" class="image-content" />
                </div>

                <!-- START OPTION CREATIVITY
                =================================== -->
                <div class="col col16-8 col-iped-8 offset16-1 offset-iped-0 wrap-option">
                    <div class="option" [ngSwitch]="contentCreatifity">
                        <div class="item-option">
                            <a href="javascript:;" class="action-option" (mouseenter)="creatifity(1)" [class.active]="contentCreatifity===1">
                                Customizable Galleries<i class="pi pi-arrow-right ml5"></i>
                            </a>
                            <div *ngSwitchCase="1" class="desc">
                                <p [translate]="'t_home_page.t_creativity.t_options.t_customizable.t_desc'">Change the colors of gallery walls, customize your frames, control the lighting setups, add text, and much more!</p>
                            </div>
                        </div>
                        <div class="item-option">
                            <a href="javascript:;" class="action-option" (mouseenter)="creatifity(2)" [class.active]="contentCreatifity===2">
                                Add 3D Objects<i class="pi pi-arrow-right ml5"></i>
                            </a>
                            <div *ngSwitchCase="2" class="desc">
                                <p class="mb-0">Villume's virtual galleries are fully capable of exhibiting sculptures and installations, supporting .GLB format, you can seamlessly upload 3D models to your virtual gallery.</p>
                            </div>
                        </div>
                        <div class="item-option">
                            <a href="javascript:;" class="action-option" (mouseenter)="creatifity(3)" [class.active]="contentCreatifity===3">
                                Easily Shareable<i class="pi pi-arrow-right ml5"></i>
                            </a>
                            <div *ngSwitchCase="3" class="desc">
                                <p>Share your art with the world, embed your exhibition on any website, and promote it on social media platforms.</p>
                            </div>
                        </div>
                        <div class="item-option">
                            <a href="javascript:;" class="action-option" (mouseenter)="creatifity(4)" [class.active]="contentCreatifity===4">
                                Interactive Environments<i class="pi pi-arrow-right ml5"></i>
                            </a>
                            <div *ngSwitchCase="4" class="desc">
                                <p>Villume virtual galleries allow viewers to experience the atmosphere of the exhibition through high-quality interactive and realistic </p>
                            </div>
                        </div>
                        <div class="item-option">
                            <a href="javascript:;" class="action-option" (mouseenter)="creatifity(5)" [class.active]="contentCreatifity===5">
                                Mobile-Friendly<i class="pi pi-arrow-right ml5"></i>
                            </a>
                            <div *ngSwitchCase="5" class="desc">
                                <p>The Villume Virtual Exhibitions Viewer is compatible with all mobile devices, providing gorgeous quality on smartphone, tablet, and desktop screens.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- START OPTION CREATIFITY -->

            </div>
        </section>
        <!-- END PROMOTION -->

        <!-- START PROMOTION
        ======================================== -->
        <section class="artworks-section">
            <!-- WRAP CONTENT -->
            <div class="wrap-content">

                <div class="row">
                    <div class="col col16-7 offset16-1 offset-iped-0 col-iped-8 col-mobile-4 title-section">
                        <div class="content">
                            <!-- TITLE CONTENT -->
                            <h1 class="title">Sell Your Artwork Online.</h1>
                            <!-- DESCRIPTION CONTENT -->
                            <div class="desc offset-iped-1">
                                <p>
                                    Get your own virtual art marketplace with Villume! Create an online exhibition that perfectly showcases your work, and simply connect each artwork to your own website or social media. This means you can start selling your art directly to customers in just a few simple clicks. It’s easy to set your own prices, manage inventory, and track sales. Take advantage of the benefits of e-commerce and start earning money from your art today.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- IMAGE SECTION -->
                    <div class="col col16-7 offset16-1 col-iped-7 offset-iped-1 col-mobile-4 offset-mobile-0">
                        <img src="{{env.staticAssets}}images/home/img-4.png?t={{mainService.appVersion}}" alt="" class="img-section" />
                    </div>
                </div>
            </div>
        </section>
        <!-- END PROMOTION -->

        <!-- SATRT PROCEDURE VIEW
        ========================================== -->
        <section class="explore-section">

            <!-- TITLE PROCEDURE VIEW
            ==================================== -->
            <h1 class="title-more-axhibition">
                <span >See how it works: </span>
                <a routerLink="/catalogue">explore our galleries <i class="pi pi-arrow-right"></i></a>
            </h1>

            <!-- PROCEDURE VIEW
            ===================================== -->
            <div class="wrap-carousel" 
                (mousemove)="updateMousePosition($event)" 
                (mousedown)="clearIntervalCarousel()" 
                (mouseenter)="hoverCarousel()" 
                (mouseleave)="clearIntervalCarousel()">
                <div class="owl-carousel carousel-main sliding slide">
                    <div class="card" *ngFor="let item of mainCarousel">
                        <a class="card-link" [href]="item.link" target="_blank"></a>
                        <div class="wrap-image">
                            <div class="overlay"></div>
                            <img class="card-image" src="{{item.image+mainService.appVersion}}" [alt]="item.name">
                        </div>
                        <div class="card-body">
                            <h5 class="title">{{item.author}}</h5>
                            <p class="subtitle">{{item.name}}</p>
                        </div>
                    </div>
                </div>
            </div>
           
            <!-- LEARN MORE
            ================================ -->
            <div class="wrap-learn-more">
                <div class="row">
                    <div class="col col16-8 col-iped-8 col-mobile-4 wrap-content">
                        <div class="content">
                            <h1 class="title">Want to learn more?</h1>
                            <div class="desc offset-iped-1">
                                <p>Check our step-by-step guide to make the perfect project.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col col16-7 offset16-1 col-iped-7 offset-iped-1 col-mobile-4 offset-mobile-0 wrap-img-button">
                        <div class="img-button">
                            <a href="javascript:;" class="link-img" routerLink="/tutorials">Watch how to create your online gallery <i class="pi pi-arrow-right"></i></a>
                            <img src="{{env.staticAssets}}images/home/img-5.png?t={{mainService.appVersion}}" alt="" class="img-section" width="100%" height="248" />
                        </div>
                    </div>
                </div>
            </div>

        </section>
        <!-- END PROCEDURE VIEW -->

        <!-- LINK PRICING
        ================================== -->
        <div class="cta-promo">
            <!-- {{'t_home_page.t_promo.t_cta_promo' | translate}} -->
            <h2>
                Subscription plans from $9 per month.
                <span> Choose a suitable plan <i class="pi pi-arrow-right"></i></span>
            </h2>
            <a href="javascript:;" [routerLink]="['/pricing']"></a>
        </div>

        <!-- PROMOTION PRICING
        ================================== -->
        <section class="promo-section">

            <!-- CONTENT -->
            <div class="content">
                <h1 class="title">Try for free</h1>
                <div class="desc">
                    <p>Get a free trial with Villume. No credit card required.</p>
                    <button (click)="getStarted()" class="btn btn-dark2 btn-lg">Get Started</button>
                </div>
            </div>

        </section>

        <!-- FAQ
        =================================== -->
        <section class="faq-section">

            <!-- TITLE -->
            <div class="row">
                <div class="col col16-5 col-iped-8 wrap-title">
                    <h1 class="title">F</h1>
                    <h1 class="title">A</h1>
                    <h1 class="title">Q</h1>
                </div>
            </div>

            <!-- START ITEM FAQ -->
            <app-faq groupName="t_faq"></app-faq>
            <!-- END ITEM FAQ -->

        </section>
    </div>
</app-layouts>