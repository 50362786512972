import { Component, OnInit } from '@angular/core';
import { EditorService } from '../../editor.service';
import { AlignObjectComponent } from '../../../../../components/align-object/align-object.component';

@Component({
    selector: 'app-align-image',
    templateUrl: './align-image.component.html',
    styleUrls: ['./align-image.component.scss'],
    standalone: true,
    imports: [AlignObjectComponent]
})
export class AlignImageComponent implements OnInit {
  public alignNodes: any[];

  constructor(
    public editorService: EditorService
  ) { }

  ngOnInit(): void {
  }

  /**
   * * =================================================== *
   * * LIST OF FUNCTIONS
   * * =================================================== *
   * * 1. SHOW/HIDE ALIGN LIMIT
   * * 2. ADJUST ALIGN LIMIT
   * * 3. ALIGN ARTWORK NODES
   * * =================================================== *
   */

  //#region 

  /** 
	 * * SHOW/HIDE ALIGN LIMIT *
	 * Todo: for show and hide align limit object
   * @param event: any -> {visibility: boolean, update: boolean}
	 */
  public showAlignLimitMesh(event: any): void {
		this.editorService.topLimit.visibility = event.visibility;
		this.editorService.bottomLimit.visibility = event.visibility;
		this.editorService.showAlignLimit =  event.visibility;
		this.editorService.updateLogActivity(event.update);
	}

  /**
   * * ADJUST ALIGN LIMIT *
   * Todo: for adjust align limit marker
   * @param event : any -> { topLimit: number, bottomLimit: number}
   */
  public adjustAlignLimit(event: any): void {
		if(event.topLimit != null) this.editorService.topLimit.position.y = event.topLimit;
		if(event.bottomLimit != null) {
			this.editorService.bottomLimit.position.y = event.bottomLimit
		};
	}

  /**
	 * * ALIGN ARTWORK NODES *
	 * Todo: to align artwork node
   * @param event: any -> {alignValues: any[], position: string}
	 */
	public alignArtworkNodes(event: any): void {
    this.editorService.hideAxis();
		if(this.editorService.selectedExhibitAssets.length > 1){
			event.alignValues.map((data: any) => {
				const artworkNode = this.editorService.artworkNodes.find((x) => x.id == data.nodeId);
        const artworkData = artworkNode.metadata.artworkData;
        this.editorService.markForUpdate(artworkData, 'transform');
				this.editorService.updateArtworkData(artworkNode, artworkData, true, true);
			})
		}else{
      this.editorService.markForUpdate(this.editorService.activeArtwork, 'transform');
			this.editorService.updateArtworkData(this.editorService.activeArtworkNode, this.editorService.activeArtwork, true, true);
		}
		this.editorService.updateLogActivity("Align artwork to " + event.position)
		this.editorService.dataHasChanges = true;
	}

  //#endregion

}
