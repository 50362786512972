import { Component, OnInit } from '@angular/core';
import { EditorService } from '../../editor.service';
import { MediaSupportService } from './media-support.service';
import { audioFigureSupport, videoFigureSupport } from '../images.interfaces';
import { AlertMessageService } from 'src/app/components/alert-message/alert-message.service';
import { messages } from '@data';
import { MainService, store } from 'src/app/shared/services';
import watch from 'redux-watch';
import { DomSanitizer } from '@angular/platform-browser';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImagesService } from '../images.service';
import { UndoRedoService } from 'src/app/shared/services/undo-redo.service';
import { SliderModule } from 'primeng/slider';
import { DialogModule } from 'primeng/dialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NgIf } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';

@Component({
    selector: 'app-media-support',
    templateUrl: './media-support.component.html',
    styleUrls: ['./media-support.component.scss'],
    standalone: true,
    imports: [TooltipModule, NgIf, FormsModule, ReactiveFormsModule, ProgressSpinnerModule, DialogModule, SliderModule]
})
export class MediaSupportComponent implements OnInit {

  public showPreview: boolean = false;
  public fileType: string = '';
  public fileExisted: boolean = false;
  public videoUrl: string = '';
  public loading: boolean = false;
  public deleting: boolean = false;
  private _selectObjectWatchSubscription: any;

  public loadedAudio: boolean = false;
  public audioFile;
  private _mediaFile: File;
  private _timelineInterval: any;
  public durationAudio: string = '00:00';
  public timerAudio: string = '00:00';
  public fileName: string = '';
  public mediaName: FormControl = new FormControl('', Validators.required);

  constructor(
    public editorService: EditorService,
    private _mediaSupportService: MediaSupportService,
    private _alertMessageService: AlertMessageService,
    public sanitizer: DomSanitizer,
    private _artworkService: ImagesService,
    public mainService: MainService,
    public _undoRedoService: UndoRedoService
  ) { 
    this._watchSelectedObjectState();
  }

  ngOnInit(): void {
    this._getMediaSupport();
  }

  ngOnDestroy(): void {
    if (this.audioFile) this.audioFile.pause();
    clearInterval(this._timelineInterval);
    this._selectObjectWatchSubscription();
  }

  /**
	 * * ================================================================================================ *
	 *   SECTION Implement Audio Player
	 * * ================================================================================================ *
	 */

  //#region
  /**
	 * ANCHOR Init Audio Player with URL
	 * @description : init audio player with URL of audio file
	 * @param src: audio file URL
	 */
  private _initAudioPlayer(AudioFile:File): void {
    const src = URL.createObjectURL(AudioFile)
    this.audioFile = new Audio(src);

    this.audioFile.addEventListener(
      "loadeddata", () => {
        this.loadedAudio = true;
        this.durationAudio = `${this._getDurationAudio(this.audioFile.duration)}`
      },
      false
    );
  }

  /**
   * ANCHOR Play Audio
   * @description : play audio file 
   */
  public playAudio(): void {
    if (this.audioFile.paused) {
      this.audioFile.play();

      // set interval to update the time on the audio player
      this._timelineInterval = setInterval(() => {
        this.timerAudio = `${this._getDurationAudio(this.audioFile.currentTime)}`
      }, 1000);
    } else {
      this.audioFile.pause();
      clearInterval(this._timelineInterval);
    }
  }

  /**
   * ANCHOR Get Duration Audio
   * @description : get duration time of audio 
   * @param duration : audio duration 
   */
  private _getDurationAudio(duration:number): string {
    const minutes = Math.floor(duration / 60);
    const seconds = Math.round(duration) % 60;

    return `${minutes < 0 ? '0':minutes.toString()}:${seconds.toString().padStart(2, '0')}`;
  }

  //#endregion
  // !SECTION


    /**
	 * * ================================================================================================ *
	 *   SECTION Upload Media File
	 * * ================================================================================================ *
	 */

  //#region

  /**
   * ANCHOR Insert Media Support
   * @description : upload Video figure support
   * @param event : event of input file
   * */
  public insertMediaSupport(event:any) {
    if (
      event.target.files[0] &&
      event.target.files[0].type.includes('audio') ||
      event.target.files[0].type.includes('video')
    ) {
      this._mediaFile = event.target.files[0];
      this.fileType = this._mediaFile.type.includes('video') ? 'video' : this._mediaFile.type.includes('audio') ? 'audio' : '';
      if (this.fileExisted) this.fileName = this.mediaName.value;

      if (this.videoUrl) this.videoUrl = null;
      if(this.audioFile) {
        this.audioFile.pause();
        this.audioFile.currentTime = 0;
        this.audioFile = null;
      }

      switch (this.fileType) {
        case 'audio': {
          // audio file insert
          setTimeout(() => {
            this.showPreview = true;
            this._initAudioPlayer(this._mediaFile)
          }, 100);
          break;
        }

        case 'video': {
          // video file insert
          setTimeout(() => {
            this.showPreview = true;
            this.videoUrl = URL.createObjectURL(this._mediaFile);
          }, 100);
            break;
        }
      }

      event.target.value = '';
    } else {
      this._alertMessageService.add({ severity:'warn', summary: 'Warning', detail: 'Please select valid file'});
    }
  }

  /**
   * ANCHOR Upload Media Support File
   * @description : upload media support file
   * */
  public uploadMediaSupport(): void {
    switch (this.fileType) {
      case 'audio': {
        const payload: audioFigureSupport = {
          figure_id: this.editorService.activeArtwork.id,
          audio: this._mediaFile,
          audio_name: this.fileName,
        };
        
        this.loading = true;
        this._uploadAudioSupport(payload);
        break;
      }
    
      case 'video': {
        const payload: videoFigureSupport = {
          figure_id: this.editorService.activeArtwork.id,
          video: this._mediaFile,
          video_name: this.fileName,
        };

        this.loading = true;
        this._uploadVideoSupport(payload);
        break;
      }
    }
  }

  /**
   * ANCHOR Upload Video File
   * @description : upload Video file
   * @param payload : form data of video file
   * */
  private _uploadVideoSupport(payload: videoFigureSupport): void {
    this._mediaSupportService.uploadVideoFile(payload).subscribe(async (res) => {
      if (res['status'] == 'response') {
        const response = res['data'].data;
        const artwork = this.editorService.activeArtwork;
        if (artwork.av?.audio) await this._deleteAudio(artwork.id, false);

        this.mediaName.setValue(response.video_name);
        this.loading = false;
        this.showPreview = false;
        artwork.av = {video:response.url_video, video_name:response.video_name};

        this.editorService.updateLogActivity('Upload Video');
        this.editorService.updateExhibitionViewer().subscribe();
        this._updateStateMediaFile();
        this._validateName();

        this._alertMessageService.add({
          severity:'success', 
          summary: 'Success', 
          detail: messages.editor.artwork.mediaSupport.upload.success
        });
      }
    },
    (err) => {
      this.loading = false;
      console.log(err);
      if (err.error.statusCode === 400) {
        this._alertMessageService.add({
          severity:'error', 
          summary: 'Error', 
          detail: err.error.data.errors.messages
        });
      }
    });
  }

  /**
   * ANCHOR Upload Audio File
   * @description : upload Audio file
   * @param payload : form data of audio file
   * */
  private _uploadAudioSupport(payload: audioFigureSupport): void {
    this._mediaSupportService.uploadAudioFile(payload).subscribe(async (res) => {
      if (res['status'] == 'response') {
        const response = res['data'].data;
        const artwork = this.editorService.activeArtwork;
        if (artwork.av?.video) await this._deleteVideo(artwork.id, false);

        this.mediaName.setValue(response.audio_name);
        this.loading = false;
        this.showPreview = false;
        artwork.av = {audio:response.url_audio, audio_name:response.audio_name};
        this.editorService.updateLogActivity('Upload Audio');
        this.editorService.updateExhibitionViewer().subscribe();
        this._updateStateMediaFile();
        this._validateName();

        this._alertMessageService.add({
          severity:'success', 
          summary: 'Success', 
          detail: messages.editor.artwork.mediaSupport.upload.success
        });
      }
    },
    (err) => {
      this.loading = false;
      if (err.error.statusCode === 400) {
        this._alertMessageService.add({
          severity:'error',
          summary: 'Error',
          detail: err.error.data.errors.messages
        });
      }
    });
  }

  //#endregion
  // !SECTION
  

  /**
   * * ================================================================================================ *
   *  SECTION Delete Media File
   * * ================================================================================================ *
   * */

  //#region

  /**
   * ANCHOR DELETE MEDIA SUPPORT FILE
   * @description : delete video file or audio file
   * @param upload : hide alert message when upload
   * */
  public deleteMediaSupport(upload: boolean = false) {
    this.deleting = true;
    const artwork = this.editorService.activeArtwork;

    if (artwork.av?.audio) this._deleteAudio(artwork.id, !upload);
    else if (artwork.av?.video) this._deleteVideo(artwork.id, !upload);
  }

  
  /**
   * ANCHOR DELETE AUDIO SUPPORT FILE
   * @description : delete audio file
   * @param artworkId : artwork id, alert : show/hide alert message
   * */
  private _deleteAudio(artworkId: string, alert:boolean): Promise<any> {
    return new Promise((resolve:any,reject:any)=>{
      this._mediaSupportService.deleteAudioFile(artworkId).subscribe((res) => {
        if (alert) {
          this.mediaName.reset();
          this._alertMessageService.add({
            severity:'success',
            summary: 'Success',
            detail: messages.editor.artwork.mediaSupport.deleteAudio.success
          });

          this.editorService.updateLogActivity('Audio file deleted');
          this.editorService.updateExhibitionViewer().subscribe();
          this._updateStateMediaFile();
        }

        this.deleting = false;
        this.editorService.activeArtwork.av = null;
        this.fileExisted = false;
        resolve(null);
      }, (err) => {
        this.deleting = false;
        this._alertMessageService.add({
          severity:'error',
          summary: 'Error',
          detail: messages.editor.artwork.mediaSupport.deleteAudio.failed
        });
        reject(err);
      });
    });
  }

   /**
   * ANCHOR DELETE VIDEO SUPPORT FILE
   * @description : delete video file
   * @param artworkId : artwork id, 
   * @param alert : show/hide alert message
   * */
  private _deleteVideo(artworkId: string, alert: boolean): Promise<any> {
    return new Promise((resolve:any,reject:any)=>{
      this._mediaSupportService.deleteVideoFile(artworkId).subscribe((res) => {
        if (alert) {
          this.mediaName.reset();
          this._alertMessageService.add({
            severity:'success', 
            summary: 'Success', 
            detail: messages.editor.artwork.mediaSupport.deleteVideo.success
          });
          
          this.editorService.updateLogActivity('Video file deleted');
          this.editorService.updateExhibitionViewer().subscribe();
          this._updateStateMediaFile();
        }

        this.deleting = false;
        this.editorService.activeArtwork.av = null;
        this.fileExisted = false;
        resolve(null);
        
      }, (err) => {
        this.deleting = false;
        this._alertMessageService.add({
          severity:'error',
          summary: 'Error',
          detail: messages.editor.artwork.mediaSupport.deleteVideo.failed
        });
        reject(err);
      });
    });
  }

  //#endregion
  // !SECTION


  /**
   * * ================================================================================================ *
   *    SECTION Other
   * * ================================================================================================ *
   * */
  //#region

  
	/**
	 * * ANCHOR UPDATE STATE UNDO REDO MEDIA FILE *
	 * Todo: to updating state undo redo when set or delete media file
	 */
	private _updateStateMediaFile(): void {
    const artworkId = this.editorService.activeArtwork.id;
		this._undoRedoService.states.map((x)=>{
      if (x.state.artworks.find((artwork) => artwork.id === artworkId)) {
        x.state.artworks.find((artwork) => artwork.id === artworkId)['av'] = {...this.editorService.activeArtwork.av};
      }
		})
	}

   /**
   * ANCHOR GET MEDIA SUPPORT FILE
   * @description : get media support file
   * */
  private _getMediaSupport(): void {
    const mediaArtwork = this.editorService.activeArtwork.av;
    if (mediaArtwork?.audio) {
      this.fileType = 'audio';
      this.fileExisted = true;
      if (mediaArtwork?.audio_name) this.mediaName.setValue(mediaArtwork?.audio_name);
      else this.mediaName.setValue('Audio');
    } else if (mediaArtwork?.video) {
      this.fileType = 'video';
      this.fileExisted = true;
      if (mediaArtwork?.video_name) this.mediaName.setValue(mediaArtwork?.video_name);
      else this.mediaName.setValue('Video');
    } else {
      this.fileType = '';
      this.fileExisted = false;
    }
  }

  /**
   * * ANCHOR WATCH SELECTED OBJECT STATE *
   * Todo: to watch selected object state from redux store
   */
  private _watchSelectedObjectState(): void {
    const selectObjectWatch = watch(store.getState, 'editor.objectHasSelected')
		this._selectObjectWatchSubscription = store.subscribe(selectObjectWatch((e: boolean) => {
      if (e && this.editorService.activeArtworkNode) {
        this.mediaName.reset();

        this.videoUrl = '';
        this.audioFile = null;
        this._getMediaSupport();
      }
		}));
  }

  /**
   * * ANCHOR ON HIDE MEDIA SUPPORT PREVIEW*
   * Todo: on hide media support preview
   */
  public onHideMediaSupportPreview(): void {
    if (this.audioFile) this.audioFile.pause();
    this.videoUrl='';
    this.audioFile=null;
    this._getMediaSupport();
  }

  /**
	 * * ANCHOR RENAME MEDIA SUPPORT *
	 * Todo: to rename media support
	*/
	public renameMediaSupport(): void {
    switch (this.fileType) {
      case 'audio': this.editorService.activeArtwork.av.audio_name = this.mediaName.value; break;
      case 'video': this.editorService.activeArtwork.av.video_name = this.mediaName.value; break;
    }
		this._validateName();
    this.editorService.dataHasChanges = true;
		this.editorService.updateUndoRedoStateWithDelay();
		this.editorService.updateLogActivityWithDelay('Rename media support');
	}

  /**
	 * * ANCHOR VALIDATE MEDIA SUPPORT NAME *
	 * Todo: to validate media support name
	 */
	private _validateName(): void {
		this._artworkService.mediaSupportNameValid = this.mediaName.valid;
		this._artworkService.validateAllArtworkData();
		this.editorService.validateExhibitionData();
	}

  //#endregion
  // !SECTION
}
