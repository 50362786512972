import { Component, OnInit } from '@angular/core';

import { store, MainService } from 'src/app/shared/services';

import { AlertMessageService } from 'src/app/components/alert-message/alert-message.service';
import { FormsModule } from '@angular/forms';
import { InputSwitchModule } from 'primeng/inputswitch';


@Component({
    selector: 'app-notifications-setting',
    templateUrl: './notifications-setting.component.html',
    styleUrls: ['./notifications-setting.component.scss'],
    standalone: true,
    imports: [InputSwitchModule, FormsModule],
})
export class NotificationsSettingComponent implements OnInit {
    public turnOff:boolean = false;

    public privacyCookie:boolean = false;
    public pricingChanges:boolean = false;
    public exhibitionStart:boolean = false;
    public exhibitionEnded:boolean = false;
    public loading: boolean = false;

    public infoUser:any = this.mainService.userInfo;
    
    constructor(
        public mainService:MainService,
        private alertMessageService:AlertMessageService
    ) {}

    ngOnInit(): void {
        // this.fetchData();
    }

	// ======================== //
	// Init data notifications from user
	// ====================== //
    fetchData() {
        let query = `
            query{
                user_notification(where: {user_id: {_eq: "${this.infoUser.id}"}})  {
                exhibition_ended
                exhibition_start
                pricing
                privacy_cookie
                }
            }
        `;

        this.mainService.queryGraphql(query).subscribe((res) => {
            this.privacyCookie = res['data'].user_notification[0].privacy_cookie;
            this.pricingChanges = res['data'].user_notification[0].pricing;
            this.exhibitionStart = res['data'].user_notification[0].exhibition_start;
            this.exhibitionEnded = res['data'].user_notification[0].exhibition_ended;

            if (this.privacyCookie != this.pricingChanges != this.exhibitionStart != this.exhibitionEnded) {
                this.turnOff = false
            }else {
                this.turnOff = true;
            }
        });
    }

	// ======================== //
	// Save Notifications
	// ====================== //
    saveNotif() {

        this.loading = true;

        if (this.privacyCookie != this.pricingChanges != this.exhibitionStart != this.exhibitionEnded) {
            this.turnOff = false
        }

        // QUERY
        let query = `
            mutation {
                update_user_notification(where: {user_id: {_eq: "${this.infoUser.id}"}}, _set: {
                    exhibition_ended: ${this.exhibitionEnded}, 
                    exhibition_start: ${this.exhibitionStart}, 
                    pricing: ${this.pricingChanges}, 
                    privacy_cookie: ${this.privacyCookie}
                }) {
                    affected_rows
                }
            }     
        `;
        
        // HIT TO Database
        this.mainService.queryGraphql(query).subscribe((res) => {
            if (res['data']) {
				this.alertMessageService.add({ severity: 'success', summary: 'Changes', detail:  'were successfully saved' });
            }else{
				this.alertMessageService.add({ severity: 'error', summary: 'Failed', detail:  'data failed to update' });
            }
        });
    }


	// ======================== //
	// Action Turn Off All 
	// ====================== //
    actionTurnOff() {
        if (this.turnOff) {
            this.privacyCookie = false;
            this.pricingChanges = false;
            this.exhibitionStart = false;
            this.exhibitionEnded = false;
        }else {
            this.privacyCookie = true;
            this.pricingChanges = true;
            this.exhibitionStart = true;
            this.exhibitionEnded = true;
        }
    }
}
