<!-- Mobile -->
<ng-container *ngIf="mobileView">
  <div #wrapperMobile class="container-preview container-preview-m" [class.dark]="colorTone=='dark'" [class.light]="colorTone=='light'">
    <div class="overlay-block" [class.dark]="colorTone=='dark'" [class.light]="colorTone=='light'"></div>
    <div class="frame-device">
      <div #bgMobile class="content-device">
        <div
          class="overlay"[class.dark]="colorTone=='dark'" [class.light]="colorTone=='light'"
          (mousedown)="setBackgroundPositionMobile('down', $event)"
          (mouseup)="setBackgroundPositionMobile('up', $event)"
          [ngClass]="colorTone">
          <h1 
            #elHeaderMobile
            class="header splash-text"
            [class.text-left]="alignHeader=='left'"
            [class.text-center]="alignHeader=='center'"
            [class.text-right]="alignHeader=='right'">
            {{headerText}}
          </h1>
          <p 
            class="body splash-text"
            [class.text-left]="alignBody=='left'"
            [class.text-center]="alignBody=='center'"
            [class.text-right]="alignBody=='right'">
            {{bodyText}}
          </p>
          <button class="btn btn-primary btn-sm" [ngClass]="colorTone">
            {{buttonText}}
          </button>
        </div>
      </div>
    </div>
    <div class="overlay-block" [class.dark]="colorTone=='dark'" [class.light]="colorTone=='light'"></div>
  </div>
</ng-container>


<!-- Desktop -->
<ng-container *ngIf="!mobileView">
	<div #bgDesktop class="wrapper" [ngClass]="colorTone">
		<div *ngIf="backgroundImage"
			class="overlay" 
			[class.dark]="colorTone=='dark'" 
			[class.light]="colorTone=='light'"
			[class]="'place-' + positionText">
		</div>
		<div class="container-preview">

			<div
        *ngIf="positionText!='3'"
				#desc
				class="item-desc"
				[ngClass]="'position-'+positionText">
        <div #containerHeader class="container-header">
          <h1 
            #elHeaderDesktop
            class="header splash-text" 
            [ngClass]="colorTone"
            [class.text-left]="alignHeader=='left'"
            [class.text-center]="alignHeader=='center'"
            [class.text-right]="alignHeader=='right'">
            {{headerText}}
          </h1>
        </div>
        <div class="container-body">
          <p 
            class="body splash-text"
            [ngClass]="colorTone"
            [class.text-left]="alignBody=='left'"
            [class.text-center]="alignBody=='center'"
            [class.text-right]="alignBody=='right'">
            {{bodyText}}
          </p>
        </div>
			</div>

			<button
        *ngIf="positionText!='3'"
				#buttonEnter
				class="btn not-center"
				(click)="onHide.emit(true)"
				[ngClass]="colorTone">
				{{buttonText}}
			</button>

      <div class="center pos" *ngIf="positionText=='3'">
        <div
          #desc
          class="item-desc"
          [ngClass]="'position-'+positionText">
          <div #containerHeader class="container-header" [ngStyle]="{display:headerText?'flex':'none'}">
            <h1 
              #elHeaderDesktop3
              class="header splash-text" 
              [ngClass]="colorTone"
              [class.text-left]="alignHeader=='left'"
              [class.text-center]="alignHeader=='center'"
              [class.text-right]="alignHeader=='right'">
              {{headerText}}
            </h1>
          </div>
          <div class="container-body">
            <p 
              class="body splash-text"
              [ngClass]="colorTone"
              [class.text-left]="alignBody=='left'"
              [class.text-center]="alignBody=='center'"
              [class.text-right]="alignBody=='right'">
              {{bodyText}}
            </p>
          </div>
        </div>

        <button
          #buttonEnter
          class="btn"
          (click)="onHide.emit(true)"
          [ngClass]="colorTone">
          {{buttonText}}
        </button>
      </div>
		</div>
	</div>
</ng-container>