import { Injectable, inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { environment } from '@environments';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  private _cookieService: SsrCookieService;

  constructor() {
    this._cookieService = inject(SsrCookieService);
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const tokenFromRequest = request.headers.get('Authorization');
    const acceptFromRequest = request.headers.get('accept');

    if(request.url.includes(environment.exhibition_path)) return next.handle(request);

    let token;
    if(tokenFromRequest) token = tokenFromRequest;
    else  {
      token = this._cookieService.get('villume_token') || '-';
    }

    let accept;
    if(acceptFromRequest) accept = acceptFromRequest;
    else accept = 'application/json';

    const authRequest = request.clone({
      headers: request.headers.set('Authorization', token).set('accept', accept)
    });

    return next.handle(authRequest);
  }
}
