import { createStore, combineReducers } from 'redux';
import { mainReducer } from '../../layouts/layout.reducer';
import { userReducer } from "../reducers/user.reducer";
import { editorReducer } from "../../pages/virtual-gallery/editor/editor.reducer";

// declare var window: any;
const reducers = combineReducers({
    main: mainReducer,
    user: userReducer,
    editor: editorReducer
});
const store = createStore(
    reducers,
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
export { store };