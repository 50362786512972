import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from "@angular/core";
import {
	provideRouter,
	withComponentInputBinding,
	withEnabledBlockingInitialNavigation,
	withInMemoryScrolling,
	withRouterConfig
} from "@angular/router";
import { routes } from "./app.routes";
import { provideAnimations } from "@angular/platform-browser/animations";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { createTranslateLoader } from "./shared/translate/translate-browser.loader";
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withFetch, withInterceptorsFromDi } from "@angular/common/http";
import { AuthInterceptor } from "./shared/http-interceptor/auth.interceptor";
import { DataInterceptor } from "./shared/http-interceptor/data.interceptor";


export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(
			withInterceptorsFromDi()
		),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(
      routes,
      withComponentInputBinding(),
      withEnabledBlockingInitialNavigation(),
      withInMemoryScrolling(),
      withRouterConfig({ paramsInheritanceStrategy: 'always'})
    ),
		provideAnimations(),
    importProvidersFrom(TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
			  provide: TranslateLoader,
			  useFactory: (createTranslateLoader),
			  deps: [HttpClient]
			}
    })),
    {
			provide: 'req',
			useValue: null
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: DataInterceptor,
			multi: true
		}
  ]
}