<div class="profile-content">
    <div class="row-item">
        <!-- Add Exhibition -->
        <div *ngIf="isInside && !loading" class="col-item create">
            <div class="add-exhibition" #addExhbition [style.height.px]="addExhbition.offsetWidth*0.88">
                <p><i class="vi vi-plus"></i>New Gallery</p>
                <a (click)="startCreating();mainService.closeHeaderMessage()" class="link-add-exhibition"></a>
            </div>
        </div>

        <!-- EXHIBITIONS -->
        <div class="col-item" *ngFor="let item of exhibitions">
            <div class="post-item">
                <div class="wrap-image mb15" #wrapImage [style.height.px]="wrapImage.offsetWidth*0.88">
                    <img src="{{env.staticAssets}}images/other/skeleton.svg?t={{mainService.appVersion}}" [lazyLoad]="item.cover_image ? item.cover_image : item.thumbnail" alt="" />

                    <div *ngIf="!isInside" class="action">
                        <button (click)="openNewTab(viewerPath + '/' + item.share_string)" [tabIndex]="-1" class="btn-action border-left-0">
                            <i class="vi vi-eye"></i>
                            <span>View</span>
                        </button>
                        <button class="btn-action" [tabIndex]="-1" (click)="openShareLink(item);">
                            <i class="vi vi-share"></i>
                            <span>Share</span>
                        </button>
                    </div>

                    <div *ngIf="isInside" class="action">
                        <button (click)="openNewTab(viewerPath +'/' + item.share_string);mainService.closeHeaderMessage()" [tabIndex]="-1" class="btn-action border-left-0">
                            <i class="vi vi-eye"></i>
                            <span>View</span>
                        </button>
                        <button (click)="openNewTab('/editor/'+item.id);mainService.closeHeaderMessage()" class="btn-action edit" [tabIndex]="-1">
                            <i class="vi vi-modify"></i>
                            <span>Edit</span>
                        </button>
                        <button class="btn-action" (click)="openShareLink(item);mainService.closeHeaderMessage()" [tabIndex]="-1">
                            <i class="vi vi-share"></i>
                            <span>Share</span>
                        </button>
                        <button *ngIf="item.id!=mainService.exhibitIdTester" class="btn-action" [tabIndex]="-1" (click)="displayConfirmDelete = true; selectedExhibition = item;mainService.closeHeaderMessage()">
                            <i class="vi vi-close-circle"></i>
                            <span>Delete</span>
                        </button>
                    </div>

                    <div class="countdown d-flex justify-content-center align-items-center" *ngIf="item.show_countdown">
                        <div class="content">
                            <label class="title">Don't miss:</label> 
                            <div class="d-flex">
                                <div class="time">
                                    <div>{{item.time?.days}}</div> 
                                        days
                                </div>
                                <div class="time">
                                    <div>{{item.time?.hours}}</div> 
                                    hours
                                </div>
                                <div class="time">
                                    <div>{{item.time?.minutes}}</div> 
                                    minutes
                                </div>
                                <div class="time">
                                    <div>{{item.time?.seconds}}</div> 
                                    seconds
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-flex w-100">
                    <img *ngIf="item.published && isInside" src="{{env.staticAssets}}images/icon/published.svg?t={{mainService.appVersion}}" />
                    <img *ngIf="!item.published && isInside" src="{{env.staticAssets}}images/icon/not-published.svg?t={{mainService.appVersion}}" />
                    <a href="javascript:;" class="exhibition-name" (click)="openNewTab(viewerPath + '/' + item.share_string)">
                        {{item.name}}
                    </a>
                </div>
            </div>
        </div>

        <!-- LOADING -->
        <div *ngIf="loading" class="wrap-loading">
            <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="5" animationDuration=".5s"></p-progressSpinner>
        </div>
    </div>
    
    <!-- load more -->
    <div class="loadmore text-center" *ngIf="!loading">
        <button *ngIf="exhibitions.length < totalCount" (click)="getExhibitions(exhibitions.length)">Load More</button>
    </div>
</div>

<!-- ON SHARE -->
<p-dialog 
    [(visible)]="isShare" 
    [modal]="true" 
    [style]="{'width': '725px'}" 
    [baseZIndex]="10000"
    [draggable]="false" 
    [resizable]="false"
    styleClass="share-profile">
    <div class="wrap-share">
        <label for="" class="title">Share your gallery</label>
        <div class="form-group">
            <label>Shareable Link</label>
            <input type="text" id="sharelink" readonly [value]="shareableLink" class="form-control bg-dark md mb0">
            <div class="d-block text-center mt10">
                <button (click)="copyText('sharelink')" class="btn btn-primary" style="padding-top: 10px">Copy</button>
            </div>
        </div>
        <div class="form-group">
            <label>Iframe</label>
            <input type="text" id="embedLink" readonly [value]="iframe" class="form-control bg-dark md mb0">
            <div class="d-block text-center mt10">
                <button class="btn btn-primary" (click)="copyText('embedLink')" style="padding-top: 10px">Copy</button>
            </div>
        </div>
    </div>
</p-dialog>

<!-- COMFIRM DELETE  -->
<p-dialog 
    [(visible)]="displayConfirmDelete" 
    [modal]="true" 
    [style]="{maxWidth: '725px'}"
    [baseZIndex]="10000"
    [draggable]="false" 
    [closable]="!loading"
    [resizable]="false"
    styleClass="confirm-delete">
    <div class="wrap-content-confirm">
        <div class="desc">
            <h3>Are you sure you want to delete this gallery?</h3>
            <p>
                If you press “Yes”, your gallery will be deleted permanently. 
                <span *ngIf="selectedExhibition?.published">If you just want to move it to Inactive Galleries — please press “Unpublish” in the Editor.</span>
            </p>
        </div>
        <div class="wrap-btn">
            <button class="btn btn-light btn-sm" (click)="deleteExhibition()" [disabled]="onDelete" [class.loading-button-primary]="onDelete">
                Yes
            </button>
            <button class="btn btn-primary btn-sm mr0" (click)="displayConfirmDelete=false" [disabled]="onDelete">No</button>
        </div>
    </div>
</p-dialog>