<div class="wrap-name">
  <div class="wrap-title" [class.profile]="isProfile">
    <button *ngIf="isProfile" class="btn btn-light2 btn-sm" (click)="backOnProfile()" [disabled]="onCreating">
			<i class="vi vi-arrow-left"></i>
		</button>
    <h2 class="title">
      Add artworks to the gallery
    </h2>
    <span class="step">{{createService.stepWizard}} step of 3</span>
  </div>

  <div class="wrap-drag">
    <div class="wrap-item">
      <div id="dragArea" class="drop-area" [class.drop]="dropHere" (click)="inputFiles.click()">
        <div class="drop-area--dash">
          <ng-container *ngIf="!dropHere">
            <span>Drag and drop or click to upload artworks</span>
          </ng-container>
          <ng-container *ngIf="dropHere">
            Drop your files here
          </ng-container>
        </div>
      </div>
      <input type="file" (change)="handleFileInput($event)" #inputFiles [disabled]="false" hidden accept=".jpg, .jpeg, .png, .glb" multiple>
      <span class="label-sub">We support JPG, PNG, GLB files</span>
    </div>
  </div>
</div>