import { Component } from '@angular/core';
import { EditorService } from '../../../editor.service';
import { ReactiveFormsModule } from '@angular/forms';
import { TextService } from '../../service/text-service.service';
import { MainService, store } from '@services';
import { NgIf } from '@angular/common';
import { TextLoaderService } from '../../service/text-loader.service';

@Component({
  selector: 'app-text-title',
  standalone: true,
  templateUrl: './text-title.component.html',
  styleUrl: './text-title.component.scss',
  imports: [
    NgIf,
    ReactiveFormsModule
  ],
})
export class TextTitleComponent {


  constructor(
    public editorService: EditorService,
    public textService: TextService,
    public mainService: MainService,
    private _textLoader: TextLoaderService
  ) { }

  /**
    * * EDIT TEXT DEFINITION *
    * ANCHOR: Edit Text Definition
    * @description: to edit text definition
    */
  public editTextDefinition(): void {
    this.textService.textDefinition.setValue(this.textService.textDefinition.value.trimStart());
    this._textLoader.textDataValid = this.textService.textDefinition.valid;
    if (this._textLoader.textDataValid) {
      if (this.editorService.selectedExhibitAssets.length > 1) {
        this.editorService.selectedExhibitAssets.forEach(node => {
          const textWallData = this.textService.getTextWallData(node);
          textWallData.name = this.textService.textDefinition.value.trim();
        });
      } else {
        this._textLoader.activeText.name = this.textService.textDefinition.value.trim();
      }

      this.editorService.dataHasChanges = true;
      store.dispatch({ type: "VALIDATE_DATA", validateData: new Date().getTime() });
      this.editorService.updateLogActivityWithDelay("Update text definition");
      this.editorService.updateUndoRedoStateWithDelay();
    }
  }
}
