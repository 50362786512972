<div class="wrap-create">
	<div class="wrap-title" [class.profile]="isProfile">
		<button *ngIf="isProfile" class="btn btn-light2 btn-sm" (click)="backOnProfile()" [disabled]="onCreating">
			<i class="vi vi-arrow-left"></i>
		</button>
		<h2 class="title">
			Add artworks to the gallery
		</h2>
		<span class="step">{{createService.stepWizard}}  step of 3</span>
	</div>

	<!-- EXHIBITION CATALOGUE -->
	<div class="wrap-content" [class.profile]="isProfile">
		<!-- back -->
		<button class="btn btn-light2 btn-sm" (click)="backToUpload()" [disabled]="onCreating">
			<i class="vi vi-arrow-left"></i>
		</button>

		<div class="wrapper">
			<p-scrollPanel 
				[styleClass]="isProfile?'profile':''+'list-artwork'"
				[style]="{height: isProfile ? 'calc(100vh - 347px - var(--mainHeightHeader))':''}">
				<div class="wrap-content-scroll">
					<div class="wrap-artworks" [class.profile]="isProfile" [formGroup]="artworkNames">
						<div class="item-artworks" [class.profile]="isProfile" *ngFor="let controlName of getControlNames(); let i = index">
							<div class="item" *ngIf="artworkNames.get(controlName) as control">
								<!-- remove artwork -->
								<button class="btn-close" (click)="removeArtwork(artworks[i]?.id)">
									<i class="vi vi-x"></i>
								</button>

								<!-- title artworks medium screen and up-->
								<input
									type="text"
									class="form-control title"
									[class.is-invalid]="control.touched && control.invalid"
									[formControlName]="controlName" 
									(input)="renameArtwork(controlName)"
									placeholder="Add a title">
								<div class="body">
									<img class="thumb"
										[src]="artworks[i]?.thumbnailUrl"
										alt="">

									<!-- title for small screen -->
									<input
										type="text"
										class="form-control title mobile"
										[class.is-invalid]="control.touched && control.invalid"
										[formControlName]="controlName" 
										(input)="renameArtwork(controlName)"
										placeholder="Add a title">

									<div class="desc">
										<span>Dimensions Adjustment</span>
										<div class="size">
											<div class="width">
												<label>Width</label>
													<input-number
														[value]="artworks[i]?.width"
														(onChange)="changeDimensions($event, i, 'width')"
														[min]="0"
														[max]="500"
														[button]="false"
														[styleInput]="'add-artwork'"
														[disabled]="false">
													</input-number>
											</div>

											<div class="link">
												<a href="javascript:;" *ngIf="artworks[i]?.lockRatio" (click)="lockRatio(i)">
													<img src="{{env.staticAssets}}images/icon/link.svg?t={{mainService.appVersion}}" alt="">
												</a>
												<a  href="javascript:;" *ngIf="!artworks[i]?.lockRatio" (click)="lockRatio(i)">
													<img src="{{env.staticAssets}}images/icon/unlink.svg?t={{mainService.appVersion}}" alt="">
												</a>
											</div>

											<div class="height">
												<label>height</label>
												<input-number
													[value]="artworks[i]?.height"
													(onChange)="changeDimensions($event, i, 'height')"
													[min]="0"
													[max]="500"
													[button]="false"
													[styleInput]="'add-artwork'"
													[disabled]="false">
												</input-number>
											</div>

											<div class="length ml10">
												<p-dropdown
													[disabled]="false"
													[formControl]="unitLength.controls[artworks[i]?.id]"
													(onChange)="changeUnitWithDebounce($event, i)"
													[options]="length"
													styleClass="bg-dark length">
												</p-dropdown>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
				<!-- END ITEM EXHIBITON -->
			</p-scrollPanel>
		</div>
	</div>