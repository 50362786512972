// Angular packages
import { Component, OnInit } from '@angular/core';

// User-defined services
import { BillingService } from './../billing.service';
import { AlertMessageService } from 'src/app/components/alert-message/alert-message.service';
import { MainService } from 'src/app/shared/services';

// Third-party packages (npm)
import moment from 'moment';
import * as _ from 'lodash';

// Third-party packages (cdn)
declare const JSZip: any;

// User-defined interfaces
import { GetInvoicesParams, Invoice } from '../billing.interfaces';

// User-defined constants
import { messages } from '@data';
import { environment } from '@environments';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { PrimeTemplate } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { NgIf } from '@angular/common';
import { InputDateComponent } from '../../../../components/input-date/input-date.component';

@Component({
    selector: 'app-invoices',
    templateUrl: './invoices.component.html',
    styleUrls: ['./invoices.component.scss'],
    standalone: true,
    imports: [InputDateComponent, NgIf, TableModule, PrimeTemplate, ProgressSpinnerModule]
})
export class InvoicesComponent implements OnInit {

  public invoices: Invoice[] = [];
  public selectedInvoices: Invoice[] = [];

  public toDateMonth: string = moment().format('MM');
  public toDateYear: string = moment().format('YYYY');
  public fromDateYear: string = moment().format('YYYY');
  public fromDateMonth: string = moment().format('MM');
  public filterDateInvalid: boolean = false;
  public onFetchingInvoices: boolean = false;
  public onDownloadingInvoices: boolean = false;


  constructor(
    private _billingService: BillingService,
    private _messageService: AlertMessageService,
    public mainService: MainService,
  ) { 
    this.mainService.loadScripts([`${environment.staticAssets}plugins/jszip/jszip.min.js?t=${this.mainService.appVersion}`]);
  }

  ngOnInit(): void {
    this._getInvoices(true);
  }

  /**
   * * ===================================================================== *
   * * LIST OF FUNCTIONS
   * * ===================================================================== *
   * * - SELECT DATE FUNCTIONS
   * * - GET INVOICES FUNCTIONS
   * * - SELECT INVOICE FUNCTIONS
   * * - DOWNLOAD INVOICE FUNCTIONS
   * * ===================================================================== *
   */





  /**
   * * =================================================== *
   * * SELECT DATE FUNCTIONS
   * * =================================================== *
   * * - ON CHANGE DATE HANDLER
   * * - VALIDATE DATE
   * * =================================================== *
   */

  //#region

  /**
   * * ON SELECT DATE HANDLER *
   * Todo: to handle on select date
   * @param $event : { date: { month: string, year: string } }
   */
  public onSelectDateHandler($event, type: 'to' | 'from'): void {
    const { month, year } = $event.date;
    if(type === 'from') {
      this.fromDateMonth = month;
      this.fromDateYear = year;
    } else {
      this.toDateMonth = month;
      this.toDateYear = year;
    }

    if (this._validateDate()) {
      this._getInvoices();
      this.filterDateInvalid = false;
    } else {
      this.filterDateInvalid = true;
    }
  }

  /**
   * * VALIDATE DATE *
   * Todo: to validate date
   * @returns : boolean -> true if valid
   */
  private _validateDate(): boolean {
    const fromDate = moment(`${this.fromDateYear}-${this.fromDateMonth}`, 'YYYY-MM');
    const toDate = moment(`${this.toDateYear}-${this.toDateMonth}`, 'YYYY-MM');
    return fromDate.isSameOrBefore(toDate);
  }

  //#endregion





  /**
   * * =================================================== *
   * * GET INVOICES FUNCTIONS
   * * =================================================== *
   * * - GET INVOICES
   * * - PROCESS INVOICES DATA
   * * - CREATE GET INVOICES PARAMS
   * * - FORMAT DATE
   * * - GET INITIAL DATE
   * * =================================================== *
   */

  //#region 

  /**
   * * GET INVOICES *
   * Todo: to get invoices
   * @param firstLoad : boolean -> true if first load 
   */
  private _getInvoices = _.debounce((firstLoad: boolean = false) => {
    const params = this._createGetInvoicesParams(firstLoad);
    const getInvoicesMessages = messages.billing.invoices;
    this.onFetchingInvoices = true;

    this._billingService.getInvoices(params).subscribe((res: any) => {
      this.invoices = this._processInvoicesData(res.data.invoice_data)
      if(this.invoices.length > 0) {
        if (firstLoad) this._getInitialDate();
      }
      
      this.onFetchingInvoices = false;
    }, (err) => {
      this.onFetchingInvoices = false;
      this._messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: getInvoicesMessages.failed
      });
    });
  }, 100)

  /**
   * * PROCESS INVOICES DATA *
   * Todo: to process invoices data
   * @param data : any -> invoices data from response
   * @returns : Invoice[] -> processed invoices data
   */
  private _processInvoicesData(data): Invoice[] {
    return data.map((invoice: any): Invoice => {
      const download_pdf = this._processDownloadLink(invoice.download_pdf);
      return {
        amount: invoice.amount,
        download_pdf,
        period_end: this._formatDate(invoice.period_end),
        period_start: this._formatDate(invoice.period_start),
        product: invoice.product,
        select: false,
        status: invoice.status,
      }
    });
  }

  /**
   * ANCHOR Process Download Link
   * @description process download link
   * @param download_pdf : string -> download pdf
   * @returns : string -> processed download link
   */
  private _processDownloadLink(download_pdf: string): string {
    if(download_pdf == '-')  return download_pdf;
    if(download_pdf.includes('https://pay.stripe.com/')) return download_pdf;
    const baseUrl = environment.baseURL
    return `${baseUrl}/invoice/${download_pdf}`;
  }

  /**
   * * CREATE GET INVOICES PARAMS *
   * Todo: to create get invoices params
   * @returns : GetInvoicesParams -> get invoices params
   */
  private _createGetInvoicesParams(firstLoad: boolean): GetInvoicesParams {
    let from_date: string;
    let to_date: string;
    if (!firstLoad) {
      from_date = `${this.fromDateYear}-${this.fromDateMonth}-01`;
      to_date = `${this.toDateYear}-${this.toDateMonth}`;
      to_date = moment(to_date, 'YYYY-MM').endOf('month').format('YYYY-MM-DD');
    }

    return {
      from_date,
      to_date,
      limit: 1000,
      page: 1,
    }
  }

  /**
   * * FORMAT DATE *
   * Todo: to format date from YYYY MMM DD to MMM DD, YYYY
   * @param date : string -> date
   * @returns : string -> formatted date
   */
  private _formatDate(date: string): string {
    return moment(date, 'YYYY-MM-DD').format('MMM DD, YYYY');
  }

  /**
   * * GET INITIAL DATE *
   * Todo: to get initial date
   */
  private _getInitialDate(): void {
    const fistElement = this.invoices[0];
    const lastElement = this.invoices[this.invoices.length - 1];

    this.fromDateMonth = moment(lastElement.period_start, 'MMM DD, YYYY').format('MM');
    this.fromDateYear = moment(lastElement.period_start, 'MMM DD, YYYY').format('YYYY');
    this.toDateMonth = moment(fistElement.period_start, 'MMM DD, YYYY').format('MM');
    this.toDateYear = moment(fistElement.period_start, 'MMM DD, YYYY').format('YYYY');
  }

  //#endregion





  /**
   * * =================================================== *
   * * SELECT INVOICE FUNCTIONS
   * * =================================================== *
   * * - GET SELECTED INVOICES
   * * - SELECT INVOICE
   * * =================================================== *
   */

  //#region

  /**
   * * GET SELECTED INVOICES *
   * Todo: to get selected invoices
   */
  private _getSelectedInvoices(): void {
    this.selectedInvoices = this.invoices.filter((invoice: Invoice) => invoice.select);
  }

  /**
   * * SELECT INVOICE *
   * Todo: to select invoice
   * @param invoice : Invoice -> invoice
   */
  public selectInvoice(invoice: Invoice): void {
    if(invoice.download_pdf == '-' || invoice.status == 'Pending') return;
    invoice.select = !invoice.select;
    this._getSelectedInvoices();
  }

  //#endregion





  /**
   * * =================================================== *
   * * DOWNLOAD INVOICE FUNCTIONS
   * * =================================================== *
   * * - DOWNLOAD ALL OR SELECTED INVOICES
   * * - DOWNLOAD INVOICES
   * * - CREATE ZIP FILE
   * * - DOWNLOAD FILE
   * * =================================================== *
   */

  //#region

  /**
   * * DOWNLOAD ALL OR SELECTED INVOICES *
   * Todo: to download all or selected invoices
   * @param type : 'all' | 'selected' -> type of invoices to download
   */
  public downloadAllOrSelectedInvoices(type: 'all' | 'selected' ) {
    if (type === 'all') {
      this.downloadInvoices(this.invoices);
    } else {
      this.downloadInvoices(this.selectedInvoices)
    }
  }

  /**
   * * DOWNLOAD INVOICES *
   * Todo: to download invoices
   * @param invoices : Invoice[] -> array of invoice
   */
  public downloadInvoices(invoices: Invoice[]): void {
    if(invoices.length === 1) {
      this._downloadFile(invoices[0].download_pdf, `invoice-${Date.now()}.pdf`);
    } else {
      this._createZipFile(invoices);
    }
  }

  /**
   * * CREATE ZIP FILE *
   * Todo: to create zip file 
   * @param invoices : Invoice[] -> array of invoice
   */
  private _createZipFile(invoices: Invoice[]) : void {
    const urls = invoices.map((inv: Invoice) => {
      if(inv.download_pdf == '-') return null;
      return inv.download_pdf
    }).filter((url: string) => url !== null);

    if(urls.length === 0) {
      this._messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'There is no invoice that can be downloaded'
      });
      return;
    }
    
    this.onDownloadingInvoices = true;
    this._billingService.generateZipFile(urls).subscribe((res) => {
      const base64 = 'data:application/zip;base64,' + res.base64; 
      this._downloadFile(base64, `invoices-${Date.now()}.zip`);
      this.onDownloadingInvoices = false;
    }, (err) => {
      this.onDownloadingInvoices = false;
      this._messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to download invoices'
      });
    })
  }

  /**
   * * DOWNLOAD FILE *
   * Todo: to download file
   * @param url : string -> url of file
   * @param filename : string -> filename of file
   */
  private _downloadFile(url: string, filename: string = null): void {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    a.href = url;
    a.target = '_blank';
    a.download = filename; 
    a.click();
  }

  public showPendingAction(url_pdf): void {
    this.mainService.showPendingPayment = true;
    this.mainService.urlInvoice = url_pdf;
    this.mainService.productDetail = this.mainService.userInfo.billing_addresses.product_detail
  }


  //#endregion
}
