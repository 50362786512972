<!-- ROTATE X VIA SLIDER
============================== -->
<div class="form-group content-slide mb30">
      <div class="title-content">
            <label>Light Intensity</label>
            <input-number 
                  [value]="lightIntensityValue"
                  (onChange)="adjustLightIntensityValue($event)"
                  (onBlur)="editorService.onInput=$event"
                  (onFocus)="editorService.onInput=$event"
                  [disabled]="editorService.blockUserAction"
                  [max]="80"
                  [min]="1">
            </input-number>
      </div>
      <p-slider 
            [styleClass]="editorService.blockUserAction? 'disable-slider ml15' :'ml15'"
            (onChange)="adjustLightIntensityValue(lightIntensityValue)"
            [disabled]="editorService.blockUserAction"
            [(ngModel)]="lightIntensityValue" 
            [max]="80"
            [step]="1" 
            [min]="1">
      </p-slider>
</div>