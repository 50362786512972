<div class="wrapper">
    <!-- SETTINGS ALIGN LIMIT
    ==================================== -->
    <div class="wrap-align">
        <div class="form-group side-by-side" [ngStyle]="{'margin-bottom': '23px'}">
            <label class="mt5">Grid</label>
            <button 
                (click)="showAlignLimitMesh()"
                [disabled]="disabled" 
                [class.disable-icon]="disabled"
                class="field-icon">
                <i class="vi vi-eye" *ngIf="showAlignLimit"></i>
                <i class="vi vi-eye-closed" *ngIf="!showAlignLimit"></i>
            </button>
        </div>

        <ul>
            <li class="item-menu-icon" pTooltip="Align at Top" tooltipPosition="top" placeholder="top">
                <button 
                    class="item-btn" 
                    [class.disable-icon]="disabled"
                    [disabled]="disabled" 
                    (click)="alignObject('top')">
                    <i class="vi vi-align-top"></i>
                </button>
            </li>
            <!-- ALIGN CENTER
            ============================== -->
            <li class="item-menu-icon" pTooltip="Align at Center" tooltipPosition="top" placeholder="top">
                <button 
                    class="item-btn" 
                    [class.disable-icon]="disabled"
                    [disabled]="disabled" 
                    (click)="alignObject('center')">
                    <i class="vi vi-align-middle"></i>
                </button>
            </li>
            <!-- ALIGN BOTTON
            ============================== -->
            <li class="item-menu-icon" pTooltip="Align at Bottom" tooltipPosition="top" placeholder="top">
                <button
                    [disabled]="disabled" 
                    [class.disable-icon]="disabled"
                    class="item-btn" 
                    (click)="alignObject('bottom')">
                    <i class="vi vi-align-bottom"></i>
                </button>   
            </li>
        </ul>
    </div>

    <!-- SETTINGS TOP LIMIT
    ==================================== -->
    <div class="form-group content-slide mb30">
        <div class="title-content">
            <div class="bottom-limit">
                <label class="mr15">Bottom</label>
                <input-number
                    [value]="inputBottomLimit"
                    [disabled]="!showAlignLimit||disabled"
                    (onBlur)="inputFocus=false;onInput.emit(false)"
                    (onChange)="adjustAlignLimit($event,'bottom');inputBottomLimit=$event;limitValues=[$event,inputTopLimit]"
                    (onFocus)="inputFocus = true;onInput.emit(true)"
                    [min]="0" 
                    [max]="inputTopLimit-100">
                </input-number>
            </div>
            <div class="top-limit">
                <label class="mr15">Top</label>
                <input-number
                    [disabled]="!showAlignLimit||disabled"
                    (onBlur)="inputFocus=false;onInput.emit(false)"
                    (onChange)="adjustAlignLimit($event,'top');inputTopLimit=$event;limitValues=[inputBottomLimit,$event]"
                    (onFocus)="inputFocus = true;onInput.emit(true)"
                    [min]="inputBottomLimit+100" 
                    [max]="maxLimit"
                    [value]="inputTopLimit">
                </input-number>
            </div>
        </div>
        <p-slider 
            [disabled]="!showAlignLimit||disabled" 
            [(ngModel)]="limitValues" 
            (onChange)="adjustSliderAlignLimit($event.values)"
            [styleClass]="!showAlignLimit||disabled ? 'disable-slider ml15' : 'ml15'"
            [range]="true"
            [step]="1" 
            [min]="0" 
            [max]="maxLimit">
        </p-slider>
    </div>
</div>