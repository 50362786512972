import { ValidatorFn, AbstractControl } from '@angular/forms';

export function onlyLattersAndSpaces(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if(control.value) {
      const regex = /^[A-Za-z0-9\s]+$/;
      const isValid = regex.test(control.value);
      return isValid ? null : { containsSpecialChars: true };
    } else {
      return null;
    }
  };
}