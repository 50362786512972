import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/shared/services';
import { ModelTesterService } from './model-tester.service';

@Component({
	selector: 'app-model-tester',
	templateUrl: './model-tester.component.html',
	styleUrls: ['./model-tester.component.scss']
})
export class ModelTesterComponent implements OnInit {

	private iframe:any;
	public onUploadingLightmap:boolean = false;
	public onUploadingModel:boolean = false;
	public onResetCameraPosition:boolean = false;

	constructor(
		public mainService: MainService,
		public modelTester: ModelTesterService
	) {}

  	ngOnInit(): void {
		if(this.mainService.isBrowser){
			this.iframe = document.getElementById("live-preview");
		}
  	}

	/**
	 * * UPLOAD LIGHT MAP *
	 * Todo: to uploading lightmap
	 * @param e : Event
	 */
  	uploadLightMap(e){
		const file = e.target.files[0]
		if(file){
			const extension = file.name.split(".").slice(-1)[0];
			const allowedExtention = ['png','jpg','jpeg'];
			if(allowedExtention.includes(extension.toLowerCase())){
				const formData = new FormData();
				formData.append('file', file);
				this.onUploadingLightmap = true;
				this.modelTester.uploadModel("light-map",formData).subscribe((res)=>{
					this.onUploadingLightmap = false;
					this.iframe.contentWindow.location.reload();
				},err=>{
					this.onUploadingLightmap = false;
					window.alert("Failed to upload lightmap")
				})
			}else{
				window.alert("please enter png, jpg or jpeg file")
			}
		}
  	}

	  /**
	 * * UPLOAD MODEL *
	 * Todo: to uploading lightmap
	 * @param e : Event
	 */
	uploadModel(e){
		const file = e.target.files[0]
		if(file){
			const extension = file.name.split(".").slice(-1)[0];
			const allowedExtention = ['glb','gltf'];
			if(allowedExtention.includes(extension.toLowerCase())){
				const formData = new FormData();
				formData.append('file', file);
				this.onUploadingModel = true;
				this.modelTester.uploadModel("model-path",formData).subscribe((res)=>{
					this.onUploadingModel = false;
					this.iframe.contentWindow.location.reload();
				},err=>{
					this.onUploadingModel = false;
					window.alert("Failed to upload lightmap")
				})
			}else{
				window.alert("please enter png, jpg or jpeg file")
			}
		}
	}

	/**
	 * * RESET CAMERA POSITION *
	 * Todo: to reset camera position
	 */
	resetCameraPosition(){
		this.onResetCameraPosition = true;
		this.modelTester.resetCameraPosition().subscribe((res)=>{
			this.onResetCameraPosition = false;
			if(res['data']){
				this.iframe.contentWindow.location.reload();
				window.alert("reset camera succesful")
			}else{
				window.alert("failed to reset camera")
			}
		},err=>{
			window.alert("failed to reset camera")
			this.onResetCameraPosition = false;
		})
	}

}
