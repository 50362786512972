<div class="content-thumb">
  <button
      [disabled]="disabled" 
      (click)="onBack.emit()"
      class="btn btn-light2 btn-sm">
      <i class="vi vi-arrow-left"></i>
  </button>
  <div class="wrap-form">
      <input 
          type="text" 
          class="form-control"
          [formControl]="artworkName"
          (input)="renameArtwork()"
          [class.border-0]="artworkName.valid" 
          placeholder="Add a title">
      <div class="thumb">
          <video *ngIf="isVideo"  controls autoplay>
              <source [src]="videoData.video.stream" type="video/mp4">
              Your browser does not support HTML video.
          </video>

          <img *ngIf="!isVideo" [src]="file.thumbnailUrl">
      </div>
  </div>
</div>