<div class="wrap-item-faq">
    <div class="row">
        <div class="col col16-8 col-iped-8 pr-0 pl-0">
            <div class="accordion text-left" id="accordionExample">
                <!-- START ITEM FAQ -->
                <div *ngFor="let item of arrFAQ; let index=index;" class="card">
                    <div class="card-header">
                        <button class="btn-accordion" (click)="item.open = !item.open">
                            {{item.t_title}}
                            <div class="wrap-icon">
                                <i class="vi vi-plus" *ngIf="!item.open"></i>
                                <i class="vi vi-dash" *ngIf="item.open"></i>
                            </div>
                        </button>
                    </div>
                    <div [@slideUp] *ngIf="item.open" class="card-body" [innerHtml]="item.t_desc"></div>
                </div>
                <!-- END ITEM FAQ -->
            </div>
        </div>
    </div>
</div>