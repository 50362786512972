export const messages = {
  updateAccount: {
    failed: 'Failed to update account setting',
    success: 'Account setting updated successfully',
  },
  updatePassword: {
    failed: 'Failed to update password',
    success: 'Password updated successfully',
    wrongOldPassword: 'The old password is wrong',
    notSamePassword: 'The new password and confirm password do not match',
  },
  deleteAccount: { 
    failed: 'Failed to delete account',
    success: 'Account deleted successfully',
  },
  audioSupport: {
    upload: {
      failed: 'Failed to upload audio file',
      success: 'Audio file uploaded successfully',
    },
    delete: {
      failed: 'Failed to delete audio file',
      success: 'Audio file deleted successfully',
    }
  },

  // Editor
  editor: {
    global: {
      invalidData: {
        artwork: 'The Artworks tab contains invalid data. Please update the data.',
        text: 'The Text tab contains invalid data. Please update the data.',
        objects: 'The Objects tab contains invalid data. Please update the data.',
        publish: 'The Publish tab contains invalid data. Please update the data.',
      },
      deleteExhibition: {
        failed: 'Something went wrong. Failed to delete the exhibition.',
        success: 'Your exhibition has been successfully deleted.',
      },
      recommendBrowser: 'We recommend you to use Google Chrome browser for best performance.',
      loadExhibitionFailed: 'Something went wrong. The exhibition could not be opened.',
      differentExhibitAsset: 'Please select exhibit asset with the same type.',
    },
    artwork: {
      upload: {
        add: {
          failed: 'Something went wrong. Failed to add artwork',
          success: 'Adding artwork successful!. Please place the artwork against the wall',
          max: 'You have reached the maximum number of artworks for this exhibition',
        },
        replace: {
          failed: 'Something went wrong. Failed to replace artwork.',
          success: 'Replace artwork successful',
        },
        notAlowedFormat: 'This file format is not supported. Please upload .png, .jpeg, .jpg or .glb file.',
        tooLarge: 'The file size is too large. Please only upload files less than 10 MB for stable performance.',
        invalidArtworkObject: 'The artwork object is invalid. Please upload a valid artwork object.',
      },
      video: {
        invalidUrl: 'The video URL is invalid. Please enter a valid URL.',
      },
      delete: {
        failed: 'Something went wrong. Failed to delete the artwork.',
        success: 'Your artwork has been successfully deleted',
      },
      requestArtwork: {
        apply: 'Successfully applied to the artwork',
        applyAll: 'Successfully applied to all artwork', 
      },
      mediaSupport: {
        upload: {
          failed: 'Something went wrong. Failed to upload media file',
          success: 'Media file uploaded successfully',
        },
        deleteAudio: {
          success: 'Audio file deleted successfully',
          failed: 'Something went wrong. Failed to delete audio file',
        },
        deleteVideo: {
          success: 'Video file deleted successfully',
          failed: 'Something went wrong. Failed to delete video file',
        }
      }
    },

    // SECTION Text Wall
    textWall: {
      // ANCHOR General
      overlaping: 'The text is overlapping with another text. Please select on the left side the text you want to edit.',
      
      // ANCHOR Add Text Wall
      add: {
        failed: 'Something went wrong. Failed to add text',
        success: 'Adding text successful!',
      }
    },
    // !SECTION


    // SECTION Ordinary Object
    ordinaryObject: {
      // ANCHOR Add Ordinary Object
      add: {
        notSupportedFormat: 'This file format is not supported. Please upload .glb for 3D-objects.',
        tooLarge: 'The file size is too large. Please only upload files less than 10 MB for stable performance.',
        failedToLoad: 'Something went wrong. Failed to load ordinary object.',
        failedToAdd: 'Something went wrong. Failed to upload ordinary object.',
        success: 'Adding ordinary object successful!',
      }
    }
    // !SECTION
  },

  // Billing
  billing: {
    invoices: {
      failed: 'Something went wrong. Failed to get invoices',
    },
    subscriptionOverview: {
      failed: 'Something went wrong. Failed to get subscription overview',
    },
    productDetail: {
      failed: 'Something went wrong. Failed to get product detail',
    },
    card: {
      get:{
        failed: 'Something went wrong. Failed to get card',
      },
      update: {
        failed: 'Something went wrong. Failed to update card',
        success: 'Successfully updated card',
      },
      add: {
        failed: 'Something went wrong. Failed to add card',
        success: 'Successfully added card',
      },
      delete: {
        failed: 'Something went wrong. Failed to delete card',
        success: 'Successfully deleted card',
      }
    },
    subscribe: {
      failed: 'Something went wrong. Failed to subscribe',
      success: 'Successfully subscribed',
      sameProduct: 'You are already subscribed to this product',
      notConfirmedYet: 'Your subscription is not confirmed yet. Please make sure you have confirmed payment on the link we provided',
    },
    promocode: {
      success: 'Successfully applied promocode',
      invalid: 'Coupon Not Valid For This billing period',
    },
    billingInfo: {
      get: {
        failed: 'Something went wrong. Failed to get billing info',
      },
      add: {
        failed: 'Something went wrong. Failed to add billing info',
        success: 'Successfully added billing info',
      },
      update: {
        failed: 'Something went wrong. Failed to update billing info',
        success: 'Successfully updated billing info',
      }
    }
  },

  promoCodes: {
    delete: {
      failed: 'Something went wrong. Failed to delete promo code',
      success: 'Successfully deleted promo code',
    },
    add: {
      failed: 'Something went wrong. Failed to add promo code',
      success: 'Successfully added promo code',
    },
    edit: {
      failed: 'Something went wrong. Failed to edit promo code',
      success: 'Successfully edited promo code',
    },
  }
}