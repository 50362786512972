import { Component, Input, Output,EventEmitter } from '@angular/core';

import { MainService, UtilsService } from 'src/app/shared/services';
import { EditorService } from '../../../editor.service';
import { ImagesService } from '../../images.service';
import { AlertMessageService } from 'src/app/components/alert-message/alert-message.service';
import { AddImageService } from '../add-image.service';

import { ArtworkDataAndTypeParam, GetPositionMarkerReturn, OnFinishedEvent, SetTransformValuesParam, UsedMarker } from './submit.interfaces';
import { AllowedFile, ArtworkDimension, UploadArtworkPayload, VideoData } from '../../images.interfaces';
import { Artwork, Scaling } from '@interfaces';

import { debounce } from 'lodash';
import { LoadingGalleryService } from 'src/app/components/loading-gallery/loading-gallery.service';
import { TooltipModule } from 'primeng/tooltip';
declare const BABYLON: any;

@Component({
    selector: 'submit',
    templateUrl: './submit.component.html',
    styleUrls: ['./submit.component.scss'],
    standalone: true,
    imports: [TooltipModule]
})

export class SubmitComponent {
  @Input() disabled: boolean = false;
  @Input() isVideo: boolean = false;
  @Input() artworkFiles: AllowedFile[] = [];
  @Input() videoData: VideoData;
  
  @Output() onFinished: EventEmitter<OnFinishedEvent> = new EventEmitter();
  
  public onSubmit: boolean = false;
  private _cummulativeProgress: any = {};
  private _usedMarkerTemp: UsedMarker[] = [];
  
  constructor(
    public editorService: EditorService,
    private _mainService: MainService,
    private _artworkService: ImagesService,
    private _messageService: AlertMessageService,
    private _addArtworkService: AddImageService,
    private _utilsService: UtilsService,
    private _loadingGalleryService: LoadingGalleryService
  ) { }

  /**
   * ANCHOR Submit Artworks
   * @description to submit artworks
   */
  public submitArtworks = debounce((): void => {
    this._cummulativeProgress = {};
    this._addArtworkService.displayAddPopup = false;
    if(this.isVideo) this._submitArtworkVideo();
    else this._submitArtworkImageObject();
  }, 500)

  /**
   * ANCHOR Submit Artwork Image Object
   * @description to submit artwork image object
   */
  private _submitArtworkImageObject(): void {
    const requests = [];

    this.artworkFiles.forEach((file: AllowedFile) => {
      this._cummulativeProgress[file.id] = 0;
    })

    this.artworkFiles.forEach((file: AllowedFile) => {
      const type = this._getArtworkType(file.file);
      switch (type) {
        case 'image':
          requests.push(this._createUploadArtworkImageRequest(file));
        break;

        case 'object':
          requests.push(this._createUploadArtworkObjectRequest(file));
        break;
      }
    })

    this.editorService.blockUserAction = true;
    this._loadingGalleryService.show = true;
    this._loadingGalleryService.percent = 0;
    Promise.all(requests).then(async (responses: Artwork[]) => {
      this.onFinished.emit({
        usedMarkers: this._usedMarkerTemp,
        artworkData: responses
      });
      this.editorService.updateExhibitionViewer().subscribe();
    })
  }

  /**
   * ANCHOR Submit Artwork Video 
   * @description to submit artwork video
   */
  private async _submitArtworkVideo(): Promise<void> {
    this.onSubmit = true;
    const payload = await this._createUploadPayload({
      videoData: this.videoData,
      type: 'video'
    });
    this._artworkService.uploadArtworkVideo(payload).subscribe((res: any) => {
      if(res['status'] == "response") {
        this.onFinished.emit({
          usedMarkers: this._usedMarkerTemp,
          artworkData: res.data.data.data_figure
        })
        this.onSubmit = false;
        this.editorService.updateExhibitionViewer().subscribe();
      }
    }, err => {
      this._messageService.add({
        severity:'error', 
        summary: 'Error',
        detail: 'Something went wrong! falied to upload video'
      });
    })
  }

  /**
   * ANCHOR Get Artwork Type
   * @description to get artwork type
   * @param file : File -> artwork file
   * @returns : 'image' | 'object'
   */
  private _getArtworkType(file: File): 'image' | 'object' | 'other' {
    const extension = this._mainService.getFileExtension(file);
    const imageFormat = ['png', 'jpg', 'jpeg'];
    if (extension == 'glb') return 'object';
    if (imageFormat.includes(extension)) return 'image';
    return 'other';
  }

  /**
   * ANCHOR Create Upload Artwork Image Request
   * @description to create upload artwork image request
   * @param allowedFile : AllowedFile -> allowed file
   * @returns : Promise<any> -> upload artwork image request
   */
  private _createUploadArtworkImageRequest(allowedFile: AllowedFile): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const payload = await this._createUploadPayload({
        allowedFile,
        type: 'figure-image'
      });
      this._artworkService.uploadArtworkImage(payload).subscribe((res: any) => {
        if(res['status']=="progress") {
          this._calculateUploadProgress(allowedFile.id, res.data);
        }

        if(res['status'] == "response") {
          resolve(res.data.data.data_figure[0]);
        }
      }, err => {
        this.editorService.blockUserAction = false;
        this._loadingGalleryService.show = false;
        this._loadingGalleryService.percent = 0;
        if (err.status == 401) this._mainService.expiredSesionPopup = true;
        reject(err);
      })
    })
  }

  /**
   * ANCHOR Calculate Upload Progress
   * @description to calculate upload progress
   * @param fileId : string
   * @param progress : number
   */
  private _calculateUploadProgress(fileId: string, progress: number): void {
    this._cummulativeProgress[fileId] = progress;
    const arr = Object.values(this._cummulativeProgress);
    const total = arr.length * 100;
    const current = arr.reduce((a: number, b: number) =>  a + b, 0) as number;
    this._loadingGalleryService.percent = Math.round(current/total * 100);
  }
  

  /**
   * ANCHOR Create Upload Artwork Object Request
   * @description to create upload artwork object request
   * @param allowedFile : AllowedFile -> allowed file
   * @returns : Promise<any> -> upload artwork object request
   */
  private _createUploadArtworkObjectRequest(allowedFile: AllowedFile): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const payload = await this._createUploadPayload({
        allowedFile,
        type: 'figure-object'
      });
      this._artworkService.uploadArtworkObject(payload).subscribe((res: any) => {
        if(res['status']=="progress") {
          this._calculateUploadProgress(allowedFile.id, res.data);
        }
        if(res['status']=="response") {
          resolve(res.data.data.data_figure[0]);
        }
      }, err => {
        reject(err);
      })
    })
  }

  /**
   * ANCHOR Create Upload Artwork Payload
   * @description to create upload artwork payload
   * @param allowedFile : AllowedFile -> allowed file
   * @param type : 'figure-image' | 'figure-object' | 'video' -> type
   * @returns : Promise<UploadArtworkPayload> -> upload artwork payload
   */
  private async _createUploadPayload(params: ArtworkDataAndTypeParam): Promise<UploadArtworkPayload> {
    const { allowedFile, videoData, type } = params;
    const { width, height, realHeight, realWidth } = await this._getArtworkDimension(params);
    const name = type == 'video' ? videoData.name : allowedFile.name;
    const sequence = this._getArtworkSequence(allowedFile);

    await this._setInitialPosition(params);

    const payload: UploadArtworkPayload = {
      exhibition_id: this.editorService.exhibition.id,
      data_figure: {
        rotation: allowedFile?.rotation || videoData?.rotation,
        position: allowedFile?.position || videoData?.position,
        width,
        height,
        real_height: realHeight,
        real_width: realWidth,
        file_type: type,
        name,
        sequence
      },
    };

    switch (type) {
      case 'figure-image':
        payload.file_image = allowedFile.file;
        payload.data_figure.light_intensity = 1;
      break;
      
      case 'figure-object':
        payload.file_image = allowedFile.thumbnail;
        payload.file_object = allowedFile.file;
        payload.data_figure.light_intensity = this.editorService.exhibition.config.defaultIntensityObject;
        payload.data_figure.scaling = await this._getArtworkObjectScaling(allowedFile.file);
      break;
      
      case 'video':
        payload.image = this.videoData.thumbnail.url;
        payload.url = this.videoData.rawUrl;
        payload.data_figure.light_intensity = 1;
      break;
    }

    return payload;
  }

  /**
   * ANCHOR Get Artwork Sequence
   * @description to get artwork sequence
   * @param allowedFile : AllowedFile -> allowed file
   * @returns : number -> artwork sequence
   */
  private _getArtworkSequence(allowedFile: AllowedFile | null): number {
    const latestSequence = this.editorService.artworks.length;
    let newSequence: number;
    if(allowedFile == null) newSequence = 1;
    else {
      newSequence = this.artworkFiles.findIndex((file: AllowedFile) => file.id == allowedFile.id) + 1;
    }
    return latestSequence + newSequence;
  }

  /**
   * ANCHOR Get Artwork Dimension
   * @description to get artwork dimension
   * @param allowedFile : File -> file
   * @param type : 'figure-image' | 'figure-object' | 'video' 
   * @returns : Promise<ArtworkDimension> -> artwork dimension
   */
  private async _getArtworkDimension(params: ArtworkDataAndTypeParam): Promise<ArtworkDimension> {
    const { allowedFile, videoData, type } = params;
    let dimension: ArtworkDimension = {};
    switch (type) {
      case 'figure-image':
        dimension = await this._artworkService.getArtworkDimension(allowedFile.file);
      break;

      case 'video': 
        const videoResolution = videoData.video.resolution.split('x');
        dimension = this._artworkService.resizeArtworkDimension({
          width: parseInt(videoResolution[0]),
          height: parseInt(videoResolution[1])
        })
      break;

      case 'figure-object':
        dimension = await this._artworkService.getArtworkDimension(allowedFile.thumbnail);
      break;
    }
    return dimension;
  }

  /**
   * ANCHOR Get Artwork Object Scaling
   * @description to get artwork object scaling
   * @param file: File
   * @returns : Promise(Scaling)
   */
  private _getArtworkObjectScaling(file: File): Promise<Scaling> {
    return new Promise((resolve, reject) => {
      const objectUrl = URL.createObjectURL(file);
      BABYLON.SceneLoader.LoadAssetContainerAsync(objectUrl, '', this.editorService.scene, null, '.glb').then((container) => {
        const wrapNode = new BABYLON.TransformNode('wrapNode', this.editorService.scene);
        container.meshes.forEach((mesh) => {
          if(mesh.name != '__ROOT___') {
            mesh.setParent(wrapNode);
          }
        });
        const { width, height, depth } = this._utilsService.getNodeDimension(wrapNode);

        let sWidth:number, sHeight:number, sDepth:number;
        if (width > 5 || height > 5 || depth > 5) {
          const scale = 2.5 / width;
          sWidth = 2.5;
          sHeight = height * scale;
          sDepth = depth * scale;
        } else {
          sWidth = width;
          sHeight = height;
          sDepth = depth;
        }

        resolve({
          scaling_x: sWidth,
          scaling_y: sHeight,
          scaling_z: sDepth
        })
      }).catch((err) => {
        reject(err);
      })
    })
  }

  /**
   * ANCHOR Set Transform Values
   * @description to set transform values
   * @param params : SetTransformValuesParam
   */
  private _setTransformValues(params: SetTransformValuesParam): void {
    const { marker, position, rotation, isInside, artworkData } = params;
    const pos = marker ? marker.position : position;
    const rot = marker ? marker.rotation : rotation;
    const { x: position_x, y: position_y, z: position_z } = pos;
    const { x: rotation_x, y: rotation_y, z: rotation_z } = rot;
    artworkData.position = { position_x, position_y, position_z };
    artworkData.rotation = { rotation_x, rotation_y, rotation_z };
    if(marker) {
      this._usedMarkerTemp.push({
        mesh: marker,
        position: isInside ? 'inside' : 'outside'
      });
    }
  }

  /**
   * ANCHOR Set Initial Position
   * @description to set initial position
   * @param allowedFile : AllowedFile -> allowed file
   * @param params : SetTransformValuesParam
   * @returns : Promise<void>
   */
  private async _setInitialPosition(params: ArtworkDataAndTypeParam): Promise<void> {
    const { allowedFile, videoData, type } = params;
    const { insideCameraView, outsideCameraView } = await this._getUnusedPositionMarker();
    const artworkData = type == 'video' ? videoData : allowedFile;

    if(type !== 'figure-object') {
      for (let i = 0; i < insideCameraView.length; i++) {
        const marker = insideCameraView[i];
        marker.isPickable = true;

        const ray = this._utilsService.createRaycast({
          node: this.editorService.mainCameraNode,
          direction: marker.position,
          scene: this.editorService.scene
        })

        const picked = this.editorService.scene.pickWithRay(ray)
        marker.isPickable = false;

        if(picked.pickedMesh.name.includes('positionPlaceholder')) {
          const isUsed = this._usedMarkerTemp.find((x: UsedMarker) => x.mesh.id == marker.id);
          if(isUsed) continue;
        } else {
          outsideCameraView.unshift(marker);
          continue
        }
        
        this._setTransformValues({marker, isInside: true, artworkData});
        return;
      }

      if (
        outsideCameraView.length > 0 ||
        this.artworkFiles.length > 1
      ) {
        for (let i = 0; i < outsideCameraView.length; i++) {
          const marker = outsideCameraView[i];
          const isUsed = this._usedMarkerTemp.find((x: UsedMarker) => x.mesh.id == marker.id);
          if(isUsed) continue;
          this._setTransformValues({marker, isInside: false, artworkData});
          return;
        }
      }
    }
    
    const { position, rotation } = this.editorService.getInitialPositionAssets(
      type == 'figure-object' ? 'ordinary-object' : 'artwork'
    );
    this._setTransformValues({artworkData, position, rotation});
    return;
  }

  /**
   * ANCHOR Get Unused Position Marker
   * @description to get unused position marker
   * @returns : Promise<GetPositionMarkerReturn>
   */
  private async _getUnusedPositionMarker(): Promise<GetPositionMarkerReturn> {
    const positionMarker = this.editorService.getUnusedPositionPlaceholders();
    return await this._splitPositionMarker(positionMarker);
  }

  /**
   * ANCHOR Split Position Marker
   * @description to split position marker
   * @param positionMarker : BABYLON.Mesh[]
   * @returns : Promise<GetPositionMarkerReturn>
   */
  private async _splitPositionMarker(positionMarker: any[]): Promise<GetPositionMarkerReturn> {
    const insideCameraView = [];
    const outsideCameraView = [];
    await Promise.all(positionMarker.map(async (marker: any) => {
      await new Promise((resolve) => {
				setTimeout(() => {
					const isInside: boolean = this.editorService.mainCameraNode.isInFrustum(marker.getBoundingInfo().boundingSphere)
          if(isInside) insideCameraView.push(marker); 
          else outsideCameraView.push(marker);
					resolve(null);
				}, 50)
			})
    }))

    return { 
      insideCameraView: this.soringPositionMarker(insideCameraView),
      outsideCameraView: this.soringPositionMarker(outsideCameraView)
    };
  }

  /**
   * ANCHOR Sorting Position Marker
   * @description to sorting position marker by closest distance
   * @param positionMarker : BABYLON.Mesh[]
   * @returns : BABYLON.Mesh[]
   */
  private soringPositionMarker(positionMarker: any[]): any[] {
    const sorted = positionMarker.sort((a: any, b: any) => {
      const distanceA = this._utilsService.calculateDistanceCameraAndMesh(a, this.editorService.mainCameraNode);
      const distanceB = this._utilsService.calculateDistanceCameraAndMesh(b, this.editorService.mainCameraNode);
      return distanceA - distanceB;
    });
    return sorted;
  }
}
