
<!--
    = 
    DOCUMENTATION
    =============================================

    HOW TO USE
    ---------------------------------------------
    import { IconModule } from '@components';

    <app-icon name="XXXXXXXXXX" class="XXXXXXX" size="XX" color="XXXXXXX"></app-icon>

    ATTRIBUTE
    ---------------------------------------------
    - name
    - className
    - size
    - color
    - stroke

    MENU ICON
    ---------------------------------------------
    - share
    - circle-question
    - delete
    - align-bottom
    - align-center
    - align-top
    - align-vartical-bottom
    - align-vartical-center
    - align vatical-top
    - axio position
    - close
    - config-frame
    - lock
    - plus
    - minus
    - set-figure-posiiton
    - test-align-center
    - test-align-left
    - test-align-right
    - eye
    - close-eyes
    - facebook
    - instagram
    - exit
    - help
    - home
    - redo
    - undo
    - save
    - arrow-left
    - arrow-right
    - modify
 -->


<ng-container [ngSwitch]="name">

    <!-- 
        CIRCLE X
    =================================== -->
    <svg *ngSwitchCase="'circle-x'" [ngClass]="className" [attr.width]="size" [attr.width]="size"  viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8" cy="8" r="7.5" [attr.stroke]="stroke"/>
        <path d="M11 5L5 11M5 5L11 11" [attr.stroke]="stroke"/>
    </svg>    

    <!-- 
        SHARE
    =================================== -->
    <!-- <svg *ngSwitchCase="'share'" [ngClass]="className" [attr.width]="size" [attr.width]="size"  viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.9945 7.99573L8.70276 0V4.78955H7.85571C5.75745 4.78955 3.78467 5.60669 2.3009 7.09045C0.817139 8.57422 0 10.547 0 12.6454V16L1.39941 14.4667C3.27136 12.4158 5.92859 11.2294 8.70276 11.2024V15.9916L15.9945 7.99573ZM0.937622 13.5896V12.6454C0.937622 10.7975 1.65723 9.06006 2.96387 7.75342C4.27051 6.44678 6.00781 5.72717 7.85571 5.72717H9.64026V2.41956L14.7257 7.99573L9.64026 13.572V10.2644H8.79907C5.8396 10.2644 2.99365 11.4723 0.937622 13.5896Z" [attr.fill]="color"/>
    </svg> -->

    <!-- 
        CIRCLE QUESTION 
    =================================== -->
    <svg *ngSwitchCase="'circle-question'" [ngClass]="className" [attr.width]="size" [attr.width]="size" viewBox="0 0 41 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="20.5" cy="20.75" r="19.2188"/>
        <path d="M21.5048 21.0802C19.4932 22.7694 18.6608 24.177 18.6608 27.1682H20.9846C20.9846 25.0215 21.3314 24.2474 23.135 22.699C26.1871 19.8837 27.3317 18.265 27.3317 15.6609C27.3317 11.8251 24.2795 9.36182 20.5337 9.36182C16.337 9.36182 13.597 12.2122 13.6663 16.8574L15.9901 17.2445C15.9555 13.6199 17.6203 11.5436 20.5337 11.5436C23.1003 11.5436 24.8345 13.1976 24.8345 15.6961C24.8345 17.8427 23.8633 18.8984 21.5048 21.0802ZM18.6608 32.1396H20.9846V29.8618H18.6608V32.1396Z" fill="#FFDA16"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5 38.9722C30.5639 38.9722 38.7222 30.8139 38.7222 20.75C38.7222 10.6861 30.5639 2.52778 20.5 2.52778C10.4361 2.52778 2.27778 10.6861 2.27778 20.75C2.27778 30.8139 10.4361 38.9722 20.5 38.9722ZM20.5 41.25C31.8218 41.25 41 32.0718 41 20.75C41 9.42816 31.8218 0.25 20.5 0.25C9.17816 0.25 0 9.42816 0 20.75C0 32.0718 9.17816 41.25 20.5 41.25Z" fill="#FFDA16"/>
    </svg>


     <!-- 
       CLONE
    =================================== -->
    <svg *ngSwitchCase="'clone'" [ngClass]="className" [attr.width]="size" [attr.width]="size" viewBox="0 0 41 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 8H10C8.89543 8 8 8.89543 8 10V19C8 20.1046 8.89543 21 10 21H19C20.1046 21 21 20.1046 21 19V10C21 8.89543 20.1046 8 19 8Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4 14H3C2.46957 14 1.96086 13.7893 1.58579 13.4142C1.21071 13.0391 1 12.5304 1 12V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H12C12.5304 1 13.0391 1.21071 13.4142 1.58579C13.7893 1.96086 14 2.46957 14 3V4" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
        
    <!-- 
        DELETE
    =================================== -->
    <svg *ngSwitchCase="'delete'" [ngClass]="className" [attr.width]="size" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6 1H10V3H6V1ZM5 3V1C5 0.447715 5.44772 0 6 0H10C10.5523 0 11 0.447715 11 1V3H13.9911H15L16 3V4L14.8667 4L13 18H3L1.13333 4L0 4V3L1 3H2.00885H5ZM11 4H10H6H5H2.14218L3.87552 17H12.1245L13.8578 4H11Z" [attr.fill]="color"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.00424 14.87L10.0419 5.97254L11.0352 6.08838L9.99751 14.9859L9.00424 14.87Z" [attr.fill]="color" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.0296 14.8861L5.99327 5.99989L5 6.11572L6.03634 15.002L7.0296 14.8861Z" [attr.fill]="color" />
    </svg>

    <!-- 
        ALIGN BOTTOM
    ==================================== -->
    <svg *ngSwitchCase="'align-bottom'" [ngClass]="className" [attr.width]="size" [attr.height]="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M21 20H3V21H21V20Z" 
            [attr.fill]="color" 
            [attr.fill-opacity]="fillOpacity"/>
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M10 18H7V4H10V18ZM6 19V3H11V19H6Z" 
            [attr.fill]="color" 
            [attr.fill-opacity]="fillOpacity"/>
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M17 18H14V11H17V18ZM13 19V10H18V19H13Z" 
            [attr.fill]="color" 
            [attr.fill-opacity]="fillOpacity"/>
    </svg>

    <!-- 
        ALIGN CENTER
    ==================================== -->
    <svg *ngSwitchCase="'align-center'" [ngClass]="className" [attr.width]="size" [attr.height]="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M21 12H18V11H21V12Z" 
            [attr.fill]="color" />
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M13 12H11V11H13V12Z" 
            [attr.fill]="color" />
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M6 12H3V11H6V12Z" 
            [attr.fill]="color" />
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M10 5H7V19H10V5ZM6 4V20H11V4H6Z" 
            [attr.fill]="color" />
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M17 8H14V15H17V8ZM13 7V16H18V7H13Z" 
            [attr.fill]="color" />
    </svg>

    <!-- 
        ALIGN TOP
    ==================================== -->
    <svg *ngSwitchCase="'align-top'" [ngClass]="className" [attr.width]="size" [attr.height]="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M21 4H3V3H21V4Z" 
            [attr.fill]="color"/>
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M10 6H7V20H10V6ZM6 5V21H11V5H6Z" 
            [attr.fill]="color" />
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M17 6H14V13H17V6ZM13 5V14H18V5H13Z" 
            [attr.fill]="color" />
    </svg>

    <!-- 
        ALIGN VARTICAL BOTTOM
    ==================================== -->
    <svg *ngSwitchCase="'align-vartical-bottom'" [ngClass]="className" [attr.width]="size" [attr.height]="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            d="M11.501 3L12.5 3L12.5 15.892L17.268 11.228L18 12L12 18L6 12L6.83695 11.228L11.501 15.892L11.501 3Z" 
            [attr.fill]="color" />
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M21 20H3V21H21V20Z" 
            [attr.fill]="color" />
    </svg>

    <!-- 
        ALIGN VARTICAL CENTER
    ===================================== -->
    <svg *ngSwitchCase="'align-vartical-center'" [ngClass]="className" [attr.width]="size" [attr.height]="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M21 11.5H3V12.5H21V11.5Z" 
            [attr.fill]="color" />
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M11.9981 14.293L15.3516 17.6465L14.6445 18.3536L12.4981 16.2072V21.0001H11.4981V16.2072L9.35164 18.3536L8.64453 17.6465L11.9981 14.293Z" 
            [attr.fill]="color" />
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M11.9981 9.70703L15.3516 6.35348L14.6445 5.64637L12.4981 7.79282V2.99992H11.4981V7.79282L9.35164 5.64637L8.64453 6.35348L11.9981 9.70703Z" 
            [attr.fill]="color" />
    </svg>

    <!-- 
        ALIGN VARTICAL TOP
    ====================================== -->
    <svg *ngSwitchCase="'align-vartical-top'" [ngClass]="className" [attr.width]="size" [attr.height]="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            d="M11.501 21L12.5 21L12.5 8.108L17.268 12.772L18 12L12 6L6 12L6.83695 12.772L11.501 8.108L11.501 21Z" 
            [attr.fill]="color" />
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M21 4H3V3H21V4Z" 
            [attr.fill]="color" />
    </svg>

    <!-- 
       AXIS POSITION
    ====================================== -->

    <svg *ngSwitchCase="'axis-position'" [ngClass]="className" [attr.width]="size" [attr.height]="size" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.4782 5.47752L9.23799 7.69495L8.50001 6.96446L12 3.5L15.5 6.96446L14.762 7.69495L12.5218 5.47752L12.5218 19.5225L14.762 17.3051L15.5 18.0355L12 21.5L8.5 18.0355L9.23798 17.3051L11.4782 19.5225L11.4782 5.47752Z" fill="#979797" />
        <path d="M19.028 11.9782L16.8168 9.73798L17.5452 9L21 12.5L17.5452 16L16.8168 15.262L19.028 13.0218L4.97191 13.0218L7.18312 15.262L6.45468 16L2.99994 12.5L6.45468 9.00001L7.18312 9.738L4.97191 11.9782L19.028 11.9782Z" fill="#FFDA16" />
    </svg>

    <!-- 
        CLOSE
    ======================================= -->
    <svg *ngSwitchCase="'close'" [ngClass]="className" [attr.width]="size" [attr.height]="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            d="M4 4L20 20M20 4L4 20" 
            [attr.fill]="color" 
            [attr.stroke]="stroke" 
            [attr.fill-opacity]="fillOpacity" />
    </svg>

    <!-- 
        CONFIG FRAME
    ======================================= -->
    <svg *ngSwitchCase="'config-frame'" [ngClass]="className" [attr.width]="size" [attr.height]="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M20 4H4V20H20V4ZM3 3V21H21V3H3Z" 
            [attr.fill]="color"/>
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M17 7H7V17H17V7ZM6 6V18H18V6H6Z" 
            [attr.fill]="color" />
    </svg>

    <!-- 
        LOCK
    ======================================= -->
    <svg *ngSwitchCase="'lock'" [ngClass]="className" [attr.width]="size" [attr.height]="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M18 12H6V20H18V12ZM5 11V20C5 20.5523 5.44772 21 6 21H18C18.5523 21 19 20.5523 19 20V11H5Z" 
            [attr.fill]="color" />
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M16 11V8C16 5.79086 14.2091 4 12 4C9.79086 4 8 5.79086 8 8V11H16ZM12 3C9.23858 3 7 5.23858 7 8V12H17V8C17 5.23858 14.7614 3 12 3Z" 
            [attr.fill]="color" />
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17ZM12 18C13.1046 18 14 17.1046 14 16C14 14.8954 13.1046 14 12 14C10.8954 14 10 14.8954 10 16C10 17.1046 10.8954 18 12 18Z" 
            [attr.fill]="color" />
    </svg>

    <!-- 
        UNLOCK
    ======================================= -->
    <svg *ngSwitchCase="'unlock'" [ngClass]="className" [attr.width]="size" [attr.height]="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M18.8047 12H6.80469V20H18.8047V12ZM5.80469 11V20C5.80469 20.5523 6.2524 21 6.80469 21H18.8047C19.357 21 19.8047 20.5523 19.8047 20V11H5.80469Z" 
            fill="#979797"/>
        <path 
            d="M16.8047 11V8C16.8047 5.79086 15.0138 4 12.8047 4C10.5955 4 8.80469 5.79086 8.80469 8H7.80469C7.80469 5.23858 10.0433 3 12.8047 3C15.5661 3 17.8047 5.23858 17.8047 8V11.375L16.8047 11Z" 
            fill="#979797"/>
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M12.8047 17C13.357 17 13.8047 16.5523 13.8047 16C13.8047 15.4477 13.357 15 12.8047 15C12.2524 15 11.8047 15.4477 11.8047 16C11.8047 16.5523 12.2524 17 12.8047 17ZM12.8047 18C13.9093 18 14.8047 17.1046 14.8047 16C14.8047 14.8954 13.9093 14 12.8047 14C11.7001 14 10.8047 14.8954 10.8047 16C10.8047 17.1046 11.7001 18 12.8047 18Z" 
            fill="#979797"/>
    </svg>

    <!--
        PLUS 
    ======================================== -->
    <svg *ngSwitchCase="'plus'" [ngClass]="className" [attr.width]="size" [attr.height]="size" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            d="M13 21V12.5H21.5V11.5H13V3H12V11.5H3.5V12.5H12V21H13Z" 
            [attr.fill]="color" 
            [attr.stroke]="stroke" 
            [attr.fill-opacity]="fillOpacity"/>
    </svg>

    <!--
        MINUS 
    ======================================== -->
    <svg *ngSwitchCase="'minus'" [ngClass]="className" [attr.width]="size" [attr.height]="size" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M24.8047 12.5H0.804688V11.5H24.8047V12.5Z"
            [attr.fill]="color" 
            [attr.stroke]="stroke" 
            [attr.fill-opacity]="fillOpacity"/>
    </svg>

    <!-- 
        SET FIGURE POSITION
    ======================================== -->
    <svg *ngSwitchCase="'set-figure-rotation'" [ngClass]="className" [attr.width]="size" [attr.height]="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" 
            [attr.fill]="color"/>
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M5.94409 9.4814C4.57513 10.0949 4 10.8452 4 11.5C4 12.0342 4.32456 12.606 5.12943 13.1291C5.93377 13.6519 7.15755 14.0795 8.77589 14.3114L8.63402 15.3013C6.93099 15.0572 5.5523 14.5966 4.5845 13.9676C3.61723 13.339 3 12.4957 3 11.5C3 10.2218 4.07766 9.22206 5.5351 8.56886C7.03001 7.89886 9.10425 7.5 11.5 7.5C13.896 7.5 16.2081 7.89894 17.9384 8.55786C18.8016 8.88662 19.5461 9.28987 20.0838 9.76189C20.6191 10.2319 21 10.8177 21 11.5C21 12.1702 20.6507 12.7551 20.1253 13.2324C19.6012 13.7086 18.8662 14.1134 17.9882 14.443C16.4823 15.0082 14.4789 15.3803 12.1828 15.4757L13.3536 16.6464L12.6464 17.3536L10.2929 15L12.6464 12.6464L13.3536 13.3536L12.2346 14.4725C14.4118 14.3743 16.2725 14.0188 17.6368 13.5068C18.4463 13.2029 19.0551 12.8536 19.4529 12.4923C19.8493 12.1321 20 11.7963 20 11.5C20 11.2158 19.8427 10.8809 19.424 10.5134C19.0077 10.1479 18.3824 9.79701 17.5825 9.49239C15.9863 8.88456 13.7984 8.5 11.5 8.5C9.20133 8.5 7.27557 8.88464 5.94409 9.4814Z" 
            [attr.fill]="color" />
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M14.6614 6.12168C13.8348 4.63388 12.8526 4 12 4C11.1474 4 10.1652 4.63388 9.33862 6.12168C8.53089 7.57561 8 9.65195 8 12C8 14.3481 8.53089 16.4244 9.33862 17.8783C10.1652 19.3661 11.1474 20 12 20C12.8526 20 13.8348 19.3661 14.6614 17.8783C15.4691 16.4244 16 14.3481 16 12C16 9.65195 15.4691 7.57561 14.6614 6.12168ZM17 12C17 7.02944 14.7614 3 12 3C9.23858 3 7 7.02944 7 12C7 16.9706 9.23858 21 12 21C14.7614 21 17 16.9706 17 12Z" 
            [attr.fill]="color" />
    </svg>

    <!-- 
        TEXT ALIGN CENTER
    ======================================== -->
    <svg *ngSwitchCase="'text-align-center'" [ngClass]="className" [attr.width]="size" [attr.height]="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M21 4H3V3H21V4ZM18 9H6V8H18V9ZM20 14H4V13H20V14ZM19 19H5V18H19V19Z" 
            [attr.fill]="color" />
    </svg>

    <!-- 
        TEXT ALIGN LEFT
    ========================================= -->
    <svg *ngSwitchCase="'text-align-left'" [ngClass]="className" [attr.width]="size" [attr.height]="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M14 4H3V3H14V4ZM21 9H3V8H21V9ZM11 14H3V13H11V14ZM19 18V19H3V18H19Z" 
            [attr.fill]="color"/>
    </svg>

    <!-- 
        TEXT ALIGN RIGHT
    ========================================= -->
    <svg *ngSwitchCase="'text-align-right'" [ngClass]="className" [attr.width]="size" [attr.height]="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M10 4H21V3H10V4ZM3 9H21V8H3V9ZM13 14H21V13H13V14ZM5 18V19H21V18H5Z" 
            [attr.fill]="color"/>
    </svg>

    <!-- 
        EYE
    ========================================== -->
    <svg *ngSwitchCase="'eye'" [ngClass]="className" [attr.width]="size" [attr.height]="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3 12C3 12 6.15 17 12 17C17.85 17 21 12 21 12C21 12 17.85 7 12 7C6.15 7 3 12 3 12ZM3.84569 12.5337L3.84491 12.5349L3.84536 12.5342L3.84569 12.5337ZM4.23758 12C4.27419 11.954 4.31333 11.9056 4.35497 11.8551C4.70998 11.4248 5.24187 10.8481 5.94173 10.2721C7.34333 9.11854 9.37597 8 12 8C14.624 8 16.6567 9.11854 18.0583 10.2721C18.7581 10.8481 19.29 11.4248 19.645 11.8551C19.6867 11.9056 19.7258 11.954 19.7624 12C19.7258 12.046 19.6867 12.0944 19.645 12.1449C19.29 12.5752 18.7581 13.1519 18.0583 13.7279C16.6567 14.8815 14.624 16 12 16C9.37597 16 7.34333 14.8815 5.94173 13.7279C5.24187 13.1519 4.70998 12.5752 4.35497 12.1449C4.31333 12.0944 4.27419 12.046 4.23758 12ZM3.84491 11.4651L3.84485 11.465L3.84491 11.4651Z" [attr.fill]="color"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14ZM12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" [attr.fill]="color"/>
    </svg>

    <!-- 
        CLOSE EYES
    ========================================== -->
    <svg *ngSwitchCase="'close-eyes'" [ngClass]="className" [attr.width]="size" [attr.height]="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M5.62476 14.331C7.09041 15.6338 9.23117 16.9096 11.9985 16.9096C14.7659 16.9096 16.9066 15.6338 18.3723 14.331C19.104 13.6806 19.6603 13.0294 20.0329 12.5415C20.219 12.298 20.3586 12.096 20.4507 11.9566C20.4968 11.8869 20.5309 11.8329 20.553 11.7972C20.564 11.7794 20.5721 11.7661 20.5771 11.7578L20.5823 11.749L20.5832 11.7476C20.5831 11.7477 20.583 11.7478 21.0149 11.9998C21.4468 12.2517 21.4467 12.2519 21.4466 12.2521L21.4454 12.2541L21.4429 12.2584L21.4343 12.2728C21.427 12.2849 21.4166 12.302 21.4031 12.3238C21.3761 12.3674 21.3367 12.4296 21.285 12.5079C21.1817 12.6643 21.0289 12.885 20.8276 13.1486C20.4254 13.6751 19.8264 14.3764 19.0366 15.0784C17.4593 16.4805 15.0918 17.9096 11.9985 17.9096C8.90522 17.9096 6.53779 16.4805 4.9604 15.0784C4.17061 14.3764 3.57164 13.6751 3.16945 13.1486C2.9681 12.885 2.81535 12.6643 2.71201 12.5079C2.66032 12.4296 2.62093 12.3674 2.59395 12.3238C2.58045 12.302 2.57006 12.2849 2.56277 12.2728L2.55417 12.2584L2.55162 12.2541L2.55079 12.2526C2.55066 12.2524 2.55024 12.2517 2.98213 11.9998C3.41402 11.7478 3.41393 11.7477 3.41386 11.7476L3.41471 11.749L3.41996 11.7578C3.42497 11.7661 3.43301 11.7794 3.44406 11.7972C3.46616 11.8329 3.5003 11.8869 3.54634 11.9566C3.63847 12.096 3.77805 12.298 3.96412 12.5415C4.33678 13.0294 4.89304 13.6806 5.62476 14.331Z"
            [attr.fill]="color"
            [attr.stroke]="stroke" 
            [attr.fill-opacity]="fillOpacity"/>
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M0.999129 15.1637L3.88437 12.9998L4.48438 13.7998L1.59913 15.9637L0.999129 15.1637Z" 
            [attr.fill]="color"
            [attr.stroke]="stroke" 
            [attr.fill-opacity]="fillOpacity"/>
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M22.8837 15.1637L19.9984 12.9998L19.3984 13.7998L22.2837 15.9637L22.8837 15.1637Z" 
            [attr.fill]="color"
            [attr.stroke]="stroke" 
            [attr.fill-opacity]="fillOpacity"/>
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M18.9429 19.0375L16.9985 15.9999L16.1562 16.5391L18.1007 19.5766L18.9429 19.0375Z" 
            [attr.fill]="color"
            [attr.stroke]="stroke" 
            [attr.fill-opacity]="fillOpacity"/>
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M6.00048 19.0375L7.94489 15.9999L8.78711 16.5391L6.8427 19.5766L6.00048 19.0375Z" 
            [attr.fill]="color"
            [attr.stroke]="stroke" 
            [attr.fill-opacity]="fillOpacity"/>
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M11.5 20.5V17H12.5V20.5H11.5Z" 
            [attr.fill]="color"
            [attr.stroke]="stroke" 
            [attr.fill-opacity]="fillOpacity"/>
    </svg>

    <!-- 
        FACEBOOK
    ========================================== -->

    <svg *ngSwitchCase="'facebook'" [ngClass]="className" [attr.width]="size" [attr.height]="size" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.98814 6.80194H1H0.5V7.30194V9.14923V9.64923H1H3V17.0003V17.5003H3.5H6H6.5V17.0003V9.64923H9H9.5V9.14923V7.30194V6.80194H9H6.31603V6.00032C6.31603 5.46175 6.41623 5.10522 6.55351 4.86485C6.68709 4.63095 6.87513 4.47274 7.11847 4.36052C7.647 4.11679 8.37663 4.10736 9.22725 4.10736H9.72725V3.60736V2.00185V1.50185L9.22725 1.50185L7.42614 1.50187C7.37214 1.50181 7.31427 1.5012 7.25296 1.50056C6.56698 1.49338 5.45152 1.4817 4.53962 2.17511C3.57663 2.90736 2.96472 4.30267 2.98814 6.80194Z" [attr.stroke]="stroke" />
    </svg>

    <!-- 
        INSTAGRAM
    ========================================== -->
    <svg *ngSwitchCase="'instagram'" [ngClass]="className" [attr.width]="size" [attr.height]="size" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" [attr.stroke]="stroke"/>
        <path d="M11.5 8C11.5 9.933 9.933 11.5 8 11.5C6.067 11.5 4.5 9.933 4.5 8C4.5 6.067 6.067 4.5 8 4.5C9.933 4.5 11.5 6.067 11.5 8Z" [attr.stroke]="stroke"/>
        <ellipse cx="13" cy="3.3999" rx="1" ry="1" [attr.fill]="color"/>
    </svg>

    <!-- TWITTER
    ========================================== -->
    <svg *ngSwitchCase="'twitter'" [ngClass]="className" [attr.width]="size" [attr.height]="size" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.3909 1.00841C18.5904 1.57307 17.704 2.00495 16.766 2.28741C16.2625 1.70852 15.5934 1.29822 14.8492 1.112C14.1049 0.925781 13.3215 0.972624 12.6047 1.24619C11.888 1.51977 11.2725 2.00686 10.8416 2.64161C10.4107 3.27636 10.1852 4.02813 10.1954 4.79525L10.1954 5.6312C8.72637 5.6693 7.27068 5.34348 5.95802 4.68277C4.64536 4.02207 3.51647 3.04698 2.6719 1.84435C2.6719 1.84435 -0.671898 9.36789 6.85164 12.7117C5.13003 13.8803 3.07914 14.4663 1 14.3836C8.52354 18.5633 17.719 14.3836 17.719 4.77018C17.7182 4.53733 17.6958 4.30505 17.6521 4.07634C18.5053 3.23495 19.1073 2.17264 19.3909 1.00841V1.00841Z" [attr.stroke]="stroke" stroke-width="1.1" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
        

    <!-- 
        EXIT
    ========================================== -->
    <svg *ngSwitchCase="'exit'" [ngClass]="className" [attr.width]="size" [attr.height]="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M3 3H16V8H15V4H4V20H15V15.9737H16V21H3V3Z" 
            [attr.fill]="color"
            [attr.stroke]="stroke" 
            [attr.fill-opacity]="fillOpacity"/>
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M8.49935 12.5001L11.2993 14.6001L10.6993 15.4001L6.16602 12.0001L10.6993 8.6001L11.2993 9.4001L8.49935 11.5001H20.9993V12.5001H8.49935Z" 
            [attr.fill]="color"
            [attr.stroke]="stroke" 
            [attr.fill-opacity]="fillOpacity"/>
    </svg>

    <!-- 
        HELP
    ========================================== -->
    <svg *ngSwitchCase="'help'" [ngClass]="className" [attr.width]="size" [attr.height]="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            d="M12.4419 12.1447C11.5587 12.8862 11.1933 13.5042 11.1933 14.8174H12.2135C12.2135 13.875 12.3657 13.5351 13.1575 12.8553C14.4975 11.6194 15 10.9087 15 9.76545C15 8.08146 13.66 7 12.0155 7C10.173 7 8.97011 8.2514 9.00057 10.2907L10.0208 10.4607C10.0055 8.86938 10.7364 7.95786 12.0155 7.95786C13.1423 7.95786 13.9037 8.68399 13.9037 9.7809C13.9037 10.7233 13.4773 11.1868 12.4419 12.1447ZM11.1933 17H12.2135V16H11.1933V17Z" 
            fill="#FFFFFF" />
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" 
            fill="#FFDA16" class="primary-color"/>
    </svg>

    <!-- 
        HOME
    ========================================== -->
    <svg *ngSwitchCase="'home'" [ngClass]="className" [attr.width]="size" [attr.height]="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            d="M19.5 10.2269V20.5H13.5V15V14.5H13H11H10.5V15V20.5H4.5V10.2269L12 3.66438L19.5 10.2269Z" 
            [attr.fill]="color" 
            [attr.stroke]="stroke" 
            [attr.fill-opacity]="fillOpacity" />
    </svg>

    <!-- 
        PLAY
    ========================================== -->
    <svg *ngSwitchCase="'play'" [ngClass]="className" [attr.width]="size" [attr.height]="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M6 3V21L19 12L6 3ZM7 4.90857V19.0914L17.2432 12L7 4.90857Z" 
            [attr.fill]="color"/>
    </svg>

    <!-- 
        PAUSE
    ========================================== -->
    <svg *ngSwitchCase="'pause'" [ngClass]="className" [attr.width]="size" [attr.height]="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.5632 4.11163H6.56323V20.1116H10.5632V4.11163Z" [attr.stroke]="stroke" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M18.5632 4.11163H14.5632V20.1116H18.5632V4.11163Z" [attr.stroke]="stroke" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <!-- 
        REDO
    ========================================== -->
    <svg *ngSwitchCase="'redo'" [ngClass]="className" [attr.width]="size" [attr.height]="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M11.7311 3.94737C7.41664 3.94737 3.91906 7.55265 3.91906 12C3.91906 16.4473 7.41664 20.0526 11.7311 20.0526C14.4479 20.0526 16.8411 18.6235 18.2414 16.4525L19.0068 16.977C17.4433 19.4009 14.7685 21 11.7311 21C6.90905 21 3 16.9706 3 12C3 7.02944 6.90905 3 11.7311 3C14.6591 3 17.2497 4.48565 18.8334 6.7642L19.0863 4.36978L20 4.47232L19.5429 8.79978L15.2976 7.72312L15.5172 6.80319L18.1998 7.48353C16.7943 5.34883 14.4211 3.94737 11.7311 3.94737Z" 
            [attr.fill]="color"/>
    </svg>

    <!-- 
        UEDO
    ========================================== -->
    <svg *ngSwitchCase="'undo'" [ngClass]="className" [attr.width]="size" [attr.height]="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M11.2689 3.94737C15.5834 3.94737 19.0809 7.55265 19.0809 12C19.0809 16.4473 15.5834 20.0526 11.2689 20.0526C8.55208 20.0526 6.1589 18.6235 4.75857 16.4525L3.99323 16.977C5.55667 19.4009 8.23151 21 11.2689 21C16.0909 21 20 16.9706 20 12C20 7.02944 16.0909 3 11.2689 3C8.34095 3 5.75025 4.48565 4.16658 6.7642L3.91367 4.36978L3 4.47232L3.4571 8.79978L7.70241 7.72312L7.48284 6.80319L4.80022 7.48353C6.20572 5.34883 8.57885 3.94737 11.2689 3.94737Z" [attr.fill]="color"/>
    </svg>

    <!-- 
        SAVE
    ========================================== -->
    <svg *ngSwitchCase="'save'" [ngClass]="className" [attr.width]="size" [attr.height]="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            d="M3.5 5C3.5 4.17157 4.17157 3.5 5 3.5H16.7929L20.5 7.20711V19C20.5 19.8284 19.8284 20.5 19 20.5H5C4.17157 20.5 3.5 19.8284 3.5 19V5Z" 
            [attr.fill]="color" 
            [attr.stroke]="stroke" 
            [attr.fill-opacity]="fillOpacity"/>
        <path 
            d="M8.5 3.5H16.5V7C16.5 7.82843 15.8284 8.5 15 8.5H10C9.17157 8.5 8.5 7.82843 8.5 7V3.5Z" 
            [attr.fill]="color" 
            [attr.stroke]="stroke" 
            [attr.fill-opacity]="fillOpacity"/>
        <path 
            d="M17.5 20.5L6.5 20.5L6.5 13C6.5 12.7239 6.72386 12.5 7 12.5L17 12.5C17.2761 12.5 17.5 12.7239 17.5 13L17.5 20.5Z" 
            [attr.fill]="color" 
            [attr.stroke]="stroke" 
            [attr.fill-opacity]="fillOpacity"/>
    </svg>

    <!-- 
        ARROW LEFT
    ========================================== -->
    <svg *ngSwitchCase="'arrow-right'" [ngClass]="className" [attr.width]="size" [attr.height]="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)">
            <path 
                d="M8.18007e-07 12.6431L9.30448e-07 11.3569L21.5385 11.3569L12.9879 2.90032L13.9271 2L24 12L13.9271 22L12.9879 21.0997L21.5385 12.6431L8.18007e-07 12.6431Z" 
                [attr.fill]="color"/>
        </g>
        <defs>
            <clipPath id="clip0">
                <rect 
                    [attr.width]="size" 
                    [attr.height]="size"
                    [attr.fill]="color"
                    transform="matrix(1 8.74228e-08 8.74228e-08 -1 0 24)"/>
            </clipPath>
        </defs>
    </svg>

    <!-- 
        ARROW RIGHT
    ========================================== -->
    <svg *ngSwitchCase="'arrow-left'" [ngClass]="className" [attr.width]="size" [attr.height]="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)">
            <path 
                d="M24 12.6431L24 11.3569L2.46154 11.3569L11.0121 2.90032L10.0729 2L-8.74228e-07 12L10.0729 22L11.0121 21.0997L2.46154 12.6431L24 12.6431Z" 
                [attr.class]="className"
                [attr.fill]="color" 
                [attr.stroke]="stroke" 
                [attr.fill-opacity]="fillOpacity"/>
        </g>
        <defs>
            <clipPath id="clip0">
                <rect 
                    width="24" 
                    height="24" 
                    transform="translate(24 24) rotate(180)" 
                    [attr.class]="className"
                    [attr.fill]="color" 
                    [attr.stroke]="stroke" 
                    [attr.fill-opacity]="fillOpacity"/>
            </clipPath>
        </defs>
    </svg>

    <!-- 
        MODIFY
    ========================================== -->
    <svg *ngSwitchCase="'modify'" [ngClass]="className" [attr.width]="size" [attr.height]="size" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M9.98093 8.48077L10.0717 7.97029C10.565 5.19635 9.71776 3.5046 8.5596 2.6611C7.6932 2.03009 6.52884 1.77897 5.31258 1.9913L7.6581 4.41125V7.70388H4.34172L2.0336 5.32343C1.67359 7.08533 2.15348 8.35954 2.94429 9.14781C3.9874 10.1876 5.8273 10.6472 7.93031 10.0187L8.50503 9.84691L16.3673 17.7846C16.7589 18.18 17.3971 18.182 17.7913 17.7892C18.1836 17.3981 18.1856 16.7632 17.7958 16.3697L9.98093 8.48077ZM1.21432 4.47846C-0.235026 9.49057 3.7815 12.3024 8.21667 10.9768L15.6568 18.4883C16.4379 19.2769 17.7111 19.281 18.4972 18.4974C19.2799 17.7173 19.2839 16.451 18.5062 15.6659L11.0563 8.14536C12.0498 2.55776 8.17352 0.107088 4.5007 1.15366C4.15772 1.25139 3.81651 1.37962 3.48121 1.53864L6.6581 4.81634V6.70388H4.765L1.58721 3.4265C1.4366 3.78692 1.31283 4.13777 1.21432 4.47846Z" 
            [attr.fill]="color"/>
    </svg>
    

    <!-- 
        UNMODIFY
    ========================================== -->
    <svg *ngSwitchCase="'unmodify'" [ngClass]="className" [attr.width]="size" [attr.height]="size" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M12.0678 11.0632L12.1586 10.5527C12.6519 7.77881 11.8047 6.08706 10.6465 5.24356C9.78011 4.61255 8.61575 4.36143 7.39949 4.57375L9.74501 6.99371V10.2863H6.42863L4.12052 7.90589C3.7605 9.66779 4.2404 10.942 5.03121 11.7303C6.07431 12.77 7.91421 13.2297 10.0172 12.6011L10.5919 12.4294L18.4542 20.3671C18.8458 20.7624 19.4841 20.7645 19.8782 20.3716C20.2705 19.9805 20.2726 19.3457 19.8827 18.9521L12.0678 11.0632ZM3.30123 7.06092C1.85189 12.073 5.86842 14.8849 10.3036 13.5593L17.7437 21.0708C18.5249 21.8594 19.798 21.8635 20.5841 21.0799C21.3668 20.2997 21.3708 19.0334 20.5931 18.2483L13.1432 10.7278C14.1367 5.14022 10.2604 2.68955 6.58762 3.73611C6.24464 3.83385 5.90343 3.96208 5.56813 4.1211L8.74501 7.3988V9.28634H6.85191L3.67413 6.00896C3.52352 6.36937 3.39975 6.72023 3.30123 7.06092Z" 
            [attr.fill]="color" 
            [attr.stroke]="stroke" 
            [attr.fill-opacity]="fillOpacity"/>
        <path 
            d="M3 21.5L21 3.5" 
            stroke="#FF0000"/>
    </svg>

    <!-- 
        MAPS
    ========================================== -->
    <svg *ngSwitchCase="'maps'" [ngClass]="className" [attr.width]="size" [attr.height]="size" viewBox="0 0 14 18" xmlns="http://www.w3.org/2000/svg">
        <path 
            d="M13.25 6.75C13.25 8.47295 12.4747 10.1511 11.2295 11.9498C10.3472 13.2242 9.26299 14.5142 8.10053 15.8973C7.73998 16.3262 7.37191 16.7641 7 17.2133C6.62809 16.7641 6.26002 16.3262 5.89947 15.8972C4.73701 14.5142 3.65277 13.2242 2.77047 11.9498C1.52525 10.1511 0.75 8.47295 0.75 6.75C0.75 3.29822 3.54822 0.5 7 0.5C10.4518 0.5 13.25 3.29822 13.25 6.75Z" 
            [attr.stroke]="stroke"/>
        <circle 
            cx="6.99992" 
            cy="6.75012" 
            r="3.25" 
            [attr.stroke]="stroke"/>
    </svg>   
    
    <!-- 
        CAMERA
    ========================================== -->
    <svg *ngSwitchCase="'camera'" [ngClass]="className" [attr.width]="size" [attr.height]="size" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M15 6V3H16V6H15ZM7 8H6V9H7V8ZM8 9V8V7H7H6H5V8V9H4H3V10V20V21H4H20H21V20V10V9H20L18.3333 9L17 7H13L11.6667 9L8 9ZM17.1315 9L16.4648 8H13.5352L12.8685 9H17.1315ZM11 10H8H7H6H5H4V20H20V10L19 10H17.7981L12.2019 10H11ZM15 18C16.6569 18 18 16.6569 18 15C18 13.3431 16.6569 12 15 12C13.3431 12 12 13.3431 12 15C12 16.6569 13.3431 18 15 18ZM15 19C17.2091 19 19 17.2091 19 15C19 12.7909 17.2091 11 15 11C12.7909 11 11 12.7909 11 15C11 17.2091 12.7909 19 15 19ZM16 15C16 15.5523 15.5523 16 15 16C14.4477 16 14 15.5523 14 15C14 14.4477 14.4477 14 15 14C15.5523 14 16 14.4477 16 15ZM17 15C17 16.1046 16.1046 17 15 17C13.8954 17 13 16.1046 13 15C13 13.8954 13.8954 13 15 13C16.1046 13 17 13.8954 17 15ZM5 13H8V12H5V13ZM18 6.45746L19.7207 4L20.5399 4.57358L18.8191 7.03103L18 6.45746ZM10.8184 4L12.5391 6.45746L11.7199 7.03103L9.99921 4.57358L10.8184 4Z" 
            [attr.fill]="color" />
    </svg>

    <!-- TEXT START
    =========================== -->
    <svg *ngSwitchCase="'text-start'" [ngClass]="className" [attr.width]="size" [attr.height]="size" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 5.7797L7.41899 6.73002L4.49609 4.85529L4.55866 8H3.44134L3.49497 4.85529L0.589944 6.73866L0 5.78834L3.16425 4L0 2.21166L0.589944 1.26134L3.50391 3.15335L3.44134 0H4.55866L4.48715 3.15335L7.41899 1.26998L8 2.2203L4.83575 3.99136L8 5.7797Z" [attr.fill]="color"/>
    </svg>

    <!-- ARROW RIGHT LENGTH
    =========================== -->
    <svg *ngSwitchCase="'arrow-right-length'" [ngClass]="className" width="65" height="9" viewBox="0 0 65 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M64.3536 4.85355C64.5488 4.65829 64.5488 4.34171 64.3536 4.14645L61.1716 0.964466C60.9763 0.769204 60.6597 0.769204 60.4645 0.964466C60.2692 1.15973 60.2692 1.47631 60.4645 1.67157L63.2929 4.5L60.4645 7.32843C60.2692 7.52369 60.2692 7.84027 60.4645 8.03553C60.6597 8.2308 60.9763 8.2308 61.1716 8.03553L64.3536 4.85355ZM0 5H64V4H0V5Z" fill="#979797"/>
    </svg>

    <!-- SHARE
    =========================== -->
    <svg *ngSwitchCase="'share'" [ngClass]="className" [attr.width]="size" [attr.height]="size" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.8153 5.99639L8.01303 10.0599V8.1463V7.6463H7.51303H7.21292C4.89018 7.6463 2.66407 8.52732 1 10.0936V9.64822C1 6.73214 3.42905 4.34647 6.45276 4.34647H7.51303H8.01303V3.84647V1.93284L11.8153 5.99639Z" [attr.stroke]="stroke"/>
    </svg>

    <svg *ngSwitchCase="'full-screen'" [ngClass]="className" [attr.width]="size" [attr.height]="size" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_6419:5323" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" [attr.width]="size" [attr.height]="size">
            <rect [attr.width]="size" [attr.height]="size" [attr.fill]="color"/>
        </mask>
        <g mask="url(#mask0_6419:5323)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M17 1H1V17H17V1ZM0 0V18H18V0H0Z" [attr.fill]="color"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4 11H5V13H7V14H4V11Z" [attr.fill]="color"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7 4L7 5L5 5L5 7L4 7L4 4L7 4Z" [attr.fill]="color"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14 7L13 7L13 5L11 5L11 4L14 4L14 7Z" [attr.fill]="color"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11 14L11 13L13 13L13 11L14 11L14 14L11 14Z" [attr.fill]="color"/>
        </g>
    </svg>

    <svg *ngSwitchCase="'exit-full-screen'" [ngClass]="className" [attr.width]="size" [attr.height]="size"  viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17 1H1V17H17V1ZM0 0V18H18V0H0Z" [attr.fill]="color"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7 14H6V12H4V11H7V14Z" [attr.fill]="color"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4 7V6H6V4H7V7L4 7Z" [attr.fill]="color"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11 4H12L12 6H14V7H11L11 4Z" [attr.fill]="color"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14 11V12H12V14H11V11L14 11Z" [attr.fill]="color"/>
    </svg>

    <!-- NEXT PLAY
    ============================ -->
    <svg *ngSwitchCase="'next-play'" [ngClass]="className" [attr.width]="size" [attr.height]="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3 3V21L16 12L3 3ZM4 4.90857V19.0914L14.2432 12L4 4.90857Z" [attr.fill]="color"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M20 21L20 3L21 3L21 21L20 21Z" [attr.fill]="color"/>
    </svg>

    <!-- PREV PLAY
    ============================ -->
    <svg *ngSwitchCase="'prev-play'" [ngClass]="className" [attr.width]="size" [attr.height]="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M21 21L21 3L8 12L21 21ZM20 19.0914L20 4.90857L9.75682 12L20 19.0914Z" [attr.fill]="color"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4 3L4 21L3 21L3 3L4 3Z" [attr.fill]="color"/>
    </svg>
</ng-container>