import { Component, OnInit } from '@angular/core';
import { environment } from '@environments';
import { MainService } from 'src/app/shared/services';
import { LayoutsComponent } from '../../layouts/layouts.component';
declare var $: any;

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss'],
    standalone: true,
    imports: [LayoutsComponent]
})
export class AboutComponent implements OnInit {
  public env = environment;
  
  constructor(public mainService: MainService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
		const scriptPaths: string[] = [
			environment.staticAssets+'plugins/jquery/jquery-3.4.1.min.js?t='+this.mainService.appVersion,
			environment.staticAssets+'plugins/owl-carousel/owl.carousel.min.js?t='+this.mainService.appVersion,
		];
		this.mainService.loadScripts(scriptPaths).then(() => {
			if (this.mainService.isBrowser) {
        $(".owl-carousel").owlCarousel({
          margin: 10,
          items: 4,
          rewind: false,
          autoWidth: true,
          responsive: {
            600: {
              margin: 15
            },
          }
        });
			}
		});
	}
}
