import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments';

import { MainService } from "src/app/shared/services";
import { ProfileService } from '../profile/profile.service';
import { LayoutsService } from 'src/app/layouts/layouts.service';
import { AlertMessageService } from 'src/app/components/alert-message/alert-message.service';


@Injectable({
   providedIn: 'root'
})
export class CatalogueService {
	public onCreating: boolean = false;
	public displayBlockMsg: boolean = false;
	public createdId: number = 0;

	constructor(
		private http: HttpClient,
		public mainService: MainService,
		private profileService: ProfileService,
		private layoutsService: LayoutsService,
		private alertMessageService: AlertMessageService
	) {}

	/**
	 * * GET CATALOGUES *
	 * Todo: to get all the room catalog data
	 * @returns HttpObservable
	 */
	getCatalogue(renderer: 'browser' | 'server' ){
		let baseUrl: string;
		switch (renderer) {
			case 'browser':
				baseUrl = environment.baseURL
			break;
			case 'server':
				baseUrl = environment.baseURL_GET
			break;
		}
		return this.http.get(`${baseUrl}/room?v=1`);
	}

	/**
	 * * CREATE EXHIBITION *
	 * Todo: for creating Exhibition
	 * @returns HttpObservable
	 */
	createExhibition(sid){
		return this.http.post(environment.baseURL + '/exhibitions-create/' + sid, {});
	}

	createExhibitionUser(dataExhibition) {
		return new Promise((resolve, reject) => {
			this.onCreating = true;
			dataExhibition.onCreating = true;
			this.mainService.validationProcess('create').subscribe((res:any)=>{
				this.profileService.validationCreateExhibition().subscribe((res)=>{
					this.createExhibition(dataExhibition.sid || dataExhibition.key_room).subscribe((res:any)=>{
						this.onCreating = false;
						dataExhibition.onCreating = false;
						const id = res.data.exhibition_id;
						const newWin = window.open("/editor/"+id)
						this.alertMessageService.add({severity:"success",summary:"Success",detail: "Create Exhibition successful"})
		
						if(!newWin || newWin.closed || typeof newWin.closed=='undefined'){ 
							this.mainService.displayLoginDialog = false;
							this.createdId = id;
							this.displayBlockMsg = true;
						} else {
							if (this.layoutsService.loginFrom == 'catalogue-page') {
								window.location.href = '/catalogue';
							}
						}
						resolve(res);
					},err=>{
						if(err.name == "TimeoutError"){
							this.createExhibitionUser(dataExhibition);
						}else if(err.error.statusCode==403){
							this.alertMessageService.add({severity:"warn",summary:"Warning",detail: "Your exhibition has reached the limit"})
						}else{
							this.alertMessageService.add({severity:"error",summary:"Error",detail: "Failed to create an exhibition."})
						}
						this.onCreating = false;
						dataExhibition.onCreating = false;
						if (this.layoutsService.loginFrom == 'catalogue-page') {
							window.location.href = '/catalogue';
						}
						reject(err);
					})
				},err=>{
					this.onCreating = false;
					dataExhibition.onCreating = false;
					if(err.name == "TimeoutError"){
						this.createExhibitionUser(dataExhibition);
					}else if(err.error.statusCode==403){
						this.alertMessageService.add({severity:"warn",summary:"Warning",detail: "Your exhibition has reached the limit"})
					}else{
						this.alertMessageService.add({ severity: "error", summary: "Error", detail: "Something went wrong. Failed to create the exhibition."})
					}
					if (this.layoutsService.loginFrom == 'catalogue-page') {
						window.location.href = '/catalogue';
					}
					reject(err);
				}) 
			},err=>{
				this.onCreating = false;
				dataExhibition.onCreating = false;
				if(err.name == "TimeoutError"){
					this.createExhibitionUser(dataExhibition);
				}else if(err.error.statusCode==403){
					this.alertMessageService.add({severity:"warn",summary:"Warning",detail: "Please wait until the create exhibition process is complete"})		
				}else if(err.error.statusCode==401){
					this.mainService.expiredSesionPopup = true;
				}else{
					this.alertMessageService.add({severity:"error", summary:"System Error", detail: "Something went wrong. Failed to create the exhibition."})
				}
				if (this.layoutsService.loginFrom == 'catalogue-page') {
					window.location.href = '/catalogue';
				}
				reject(err);
			})
		})
	}
}
