import { AbstractControl, ValidatorFn } from '@angular/forms';
import moment from 'moment';

export function dateLessThanToday(format: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) return null;
    const selectedDate = moment(control.value, format);
    const today = moment();
    if (selectedDate.isValid() && selectedDate.isBefore(today, 'day')) {
      return { 'dateLessThanToday': true };
    }

    return null;
  };
}
