import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from '@environments';
import { MainService } from '@services';
import { AllowedFile } from 'src/app/pages/virtual-gallery/editor/images/images.interfaces';
import { CreateExhibitionService } from '../../services/create-exhibition.service';
import * as _ from 'lodash';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberComponent } from '../../../input-number/input-number.component';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { ScrollPanelModule } from 'primeng/scrollpanel';

@Component({
    selector: 'app-list-artwork',
    templateUrl: './list-artwork.component.html',
    styleUrls: ['./list-artwork.component.scss'],
    standalone: true,
    imports: [
      FormsModule,
      ReactiveFormsModule,
      CommonModule,
      InputNumberComponent,
      DropdownModule,
      ScrollPanelModule,
    ]
})
export class ListArtworkComponent {
  @Input() artworks: AllowedFile[] = [];
  @Input() onCreating: boolean = false;
  @Input() isProfile: boolean = false;
  @Output() onRename: EventEmitter<AllowedFile[]> = new EventEmitter<AllowedFile[]>();
  @Output() onValidate: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() dimensions: any = new EventEmitter();
  @Output() clearArtworks: EventEmitter<[]> = new EventEmitter<[]>();

  public artworkNames: FormGroup = new FormGroup({});
  public unitLength: FormGroup = new FormGroup({});
  public artworkImages: any = [];
  public artworkObjects: any = [];
  public env = environment;

  public length = [
    {label: 'cm', value: 'cm'},
    {label: 'inch', value: 'inch'}
  ]

  constructor(
    public mainService: MainService,
    public createService: CreateExhibitionService
  ) { }

  ngOnInit(): void {
    this._createArtworkFileNameInputs();
    for (let i = 0; i < this.artworks.length; i++) {
      const unit = this.artworks[i].length_unit || 'cm';
      this.changeUnit(unit, i);
    }
  }

  /**
   * ANCHOR BACK ON PROFILE
   */
  public backOnProfile() {
    this.clearArtworks.emit([]);
    this.onValidate.emit(false);
    this.createService.stepWizard--
  }

  /**
   * ANCHOR Create Artwork File Name Inputs
   * @description to create artwork file name inputs
   */
  private _createArtworkFileNameInputs(): void {
    this.artworks.forEach((file: AllowedFile) => {
      this.artworkNames.addControl(file.id, new FormControl('', Validators.required));
      this.unitLength.addControl(file.id, new FormControl('', Validators.required));
    });
  }

  public changeDimensions(e:number, i:number, type: 'height' | 'width') {
    switch (type) {
      case 'height':
        this.artworks[i].height = e;
        if (this.artworks[i].lockRatio) {
          this.artworks[i].width = Math.round(e * this.artworks[i].ratioYX);
        }
        break;

      case 'width':
        this.artworks[i].width = e;
        if (this.artworks[i].lockRatio) {
          this.artworks[i].height = Math.round(e * this.artworks[i].ratioXY);
        }
        break;
      }
  }

  public lockRatio(i: number) {
    this.artworks[i].lockRatio = !this.artworks[i].lockRatio;

    if (this.artworks[i].lockRatio) {
      this.artworks[i].height = Math.round(this.artworks[i].width * this.artworks[i].ratioXY);
    }
  }

  public changeUnit(unit: string, i: number) {
    this.artworks[i].length_unit = unit;
    this.unitLength.controls[this.artworks[i].id].setValue(unit);

    if (unit == 'inch') {
      this.artworks[i].height = Math.round(this.createService.convertCmToInch(this.artworks[i].height));
      this.artworks[i].width = Math.round(this.createService.convertCmToInch(this.artworks[i].width));
    }
  }

  public changeUnitWithDebounce = _.debounce((event: any, i: number) => {
    if (event.value == 'inch' && this.artworks[i].length_unit != 'inch') {
      this.artworks[i].height = Math.round(this.createService.convertCmToInch(this.artworks[i].height));
      this.artworks[i].width = Math.round(this.createService.convertCmToInch(this.artworks[i].width));
    }
    
    if (event.value == 'cm' && this.artworks[i].length_unit != 'cm') {
      this.artworks[i].height = Math.round(this.createService.convertInchToCm(this.artworks[i].height));
      this.artworks[i].width = Math.round(this.createService.convertInchToCm(this.artworks[i].width));
    }

    this.artworks[i].length_unit = event.value;
    this.unitLength.controls[this.artworks[i].id].setValue(event.value);
  }, 100);

  /**
   * ANCHOR Get Control Names
   * @description to get control names
   * @returns : String[] -> control names
   */
  public getControlNames(): any {
    return Object.keys(this.artworkNames.controls);
  }

  /**
   * ANCHOR Rename Artwork
   * @description to rename artwork
   * @param controlName : string -> control name
   */
  public renameArtwork(controlName: string): void {
    const control = this.artworkNames.get(controlName);
    const value = control.value.trimStart();
    const newValue = value.replace(/\\/g, '').replace(/\"/g, '');
    control.setValue(newValue.trimStart());
    this.artworks.find((file: AllowedFile) => file.id === controlName).name = control.value;
    this.onValidate.emit(this.artworkNames.valid);
    this.onRename.emit(this.artworks);
  }

  public backToUpload() {
    this.clearArtworks.emit([]);
    this.onValidate.emit(false);
  }

  public removeArtwork(id: string) {
    this.artworks = this.artworks.filter((file: AllowedFile) => file.id !== id);
    this.artworkNames.removeControl(id);
    this.onRename.emit(this.artworks);
    if (this.artworks.length === 0) this.onValidate.emit(false);
    else this.onValidate.emit(this.artworkNames.valid);

  }
}
