<div class="wrap-model-tester">
    <!-- Upload Section -->
    <div class="upload-section p-4">
        <!-- Upload LightMap -->
        <button 
            [disabled]="onUploadingModel||onUploadingLightmap||onResetCameraPosition"
            class="btn btn-sm btn-primary mr-2" 
            (click)="inputLightMap.click()">
            {{onUploadingLightmap? 'Uploading...':'Upload LightMap'}} 
        </button>
        <input #inputLightMap hidden type="file" (change)="uploadLightMap($event)">

        <!-- Upload Model -->
        <button 
            [disabled]="onUploadingModel||onUploadingLightmap||onResetCameraPosition"
            class="btn btn-sm btn-primary mr-2" 
            (click)="inputModel.click()">
            {{onUploadingModel? 'Uploading...':'Upload 3d Model'}} 
        </button>
        <input #inputModel hidden type="file" (change)="uploadModel($event)">

        <!-- Reset Camera Position -->
        <button 
            [disabled]="onUploadingModel||onUploadingLightmap||onResetCameraPosition"
            class="btn btn-sm btn-primary" 
            (click)="resetCameraPosition()">
            {{onResetCameraPosition? 'Loading...':'Reset Camera Position'}} 
        </button>
        <p class="mt-2">if the camera is out of the room, click the reset camera position button. the button will restore camera position value to (x = 0,y = 0, y = 0). make sure the point (x=0,y=0,z=0) that is in the model is in the room and if the room has more than one floor, set the point (x=0,y=0,z=0) on the floor the very bottom</p>
    </div>

    <!-- Preview Section -->
    <div class="preview-section">
        <div class="live-preview">
            <!-- Title -->
            <div class="pl-4">
                <h3>Preview on Editor</h3>
                <a [href]="'/editor/'+mainService.exhibitIdTester" target="_blink">Click here to show in new tab</a>
            </div>
            <!-- Iframe -->
            <iframe id="live-preview" src="/editor/1430d03d-1bfe-48be-a79e-a301517a8883"></iframe>
        </div>
    </div>
</div>
