<div class="wrap-item-text">
  <ul>
    <ng-container *ngIf="isTextWallsNotEmpty()">
      <li [class.disable-item]="editorService.blockUserAction" class="item-text" *ngFor="let text of textWallsData()">
        <a href="javascript:;" class="link-text" (click)="selectText(text.id)">
          <div class="text">{{text.name}}</div>
          <button class="btn-modify">
            <i class="vi vi-modify"></i>
          </button>
        </a>
      </li>
    </ng-container>
    <li *ngIf="!isTextWallsNotEmpty()&&!loading" class="item-text empty-text text-center">
      No text added
    </li>
  </ul>
</div>