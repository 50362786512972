import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/shared/services';
import { AlertMessageService } from './alert-message.service';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-alert-message',
    templateUrl: './alert-message.component.html',
    styleUrls: ['./alert-message.component.scss'],
    animations: [
        trigger('fadeAnimation', [
            transition(':enter', [
                style({
                    opacity: 0,
                    transform: 'translateY(74px)'
                }),
                animate(200, style({
                    opacity: 1,
                    transform: 'translateY(0)'
                }))
            ]),
            transition(':leave', [
                style({
                    opacity: 1,
                    transform: 'translateY(0)'
                }),
                animate(200, style({
                    opacity: 0,
                    transform: 'translateY(74px)'
                }))
            ])
        ])
    ],
    standalone: true,
    imports: [NgIf],
})
export class AlertMessageComponent implements OnInit {

	public showMessage: boolean = false;
	public title: string = '';
	public type: string = '';
	public message: string = '';

	constructor(
		private alertMessageService: AlertMessageService,
		public mainService: MainService
	) {
		this.alertMessageService.componentMethodCalled$.subscribe((config) => {
			this.title = config.summary;
			this.message = config.detail;
			this.type = config.severity;

			if (this.showMessage) {
				this.showMessage = false;
			}
			setTimeout(() => { this.showMessage = true }, 100);
			setTimeout(() => { this.showMessage = false }, 3000);
		});
	}

	ngOnInit(): void {
	}

}
