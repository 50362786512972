import { Component, Input, OnInit } from '@angular/core';
import { MainService } from 'src/app/shared/services';
import { LoadingGalleryService } from './loading-gallery.service';
import { environment } from '@environments';
import { NgIf } from '@angular/common';

@Component({
    selector: 'loading-gallery',
    templateUrl: './loading-gallery.component.html',
    styleUrls: ['./loading-gallery.component.scss'],
    standalone: true,
    imports: [NgIf]
})
export class LoadingGalleryComponent implements OnInit {
  public env = environment;
  constructor(
    public mainService: MainService,
    public loadingGalleryService: LoadingGalleryService
  ) { }

  ngOnInit(): void {
  }

}
