<app-layouts>
    <div class="privacy-policy" content>
        <!-- WRAP TITLE 
        ============================== -->
        <div class="privacy-policy-titles">
            <h1 class="privacy-policy-title">Privacy Policy </h1>
            <span class="privacy-policy-subtitle">Effective Date: January 2022</span>
        </div>

        <div class="row">
            <div class="col col16-12 col-iped-7 offset-iped-1 col-mobile-4 offset-mobile-0 m-auto">
                <!-- START DESCRIPTION
                ==================================== -->
                <div class="privacy-policy-content">

                    <p>This Privacy Policy applies to Galleria Media Inc. (“Galleria”, “we”, “our” or “us”) services, including but not limited to our website villume.com (the “Website”), our online art galleries currently titled “Villume” (“Villume”), our social media accounts and online forums, and any other product or service that we choose to apply this Privacy Policy to (collectively, the “Service”), and details how we collect, use, and disclose data about our users, including you.
                    <br><br>BY CLICKING “I AGREE” OR SIMILAR CONFIRMATION OR BY USING THE SERVICE YOU ARE AGREEING TO THE TERMS OF THIS PRIVACY POLICY AS APPLICABLE IN YOUR JURISDICTION.</p>
                    
                    <!-- TITLE DESCRIPTION 
                    ================================ -->
                    <h1 class="privacy-policy-content-title">1. How we obtain your data</h1>
                    <p>The Service collects and processes personal data, which is information about you or that identifies you, as well as anonymous data, which does not identify you.<br>
                        <br>We obtain your personal data directly from you or when you use the Service.  In many circumstances you may be required to submit personal data in order to access the Service.  Your provision of personal data to Galleria is governed by any terms of use, end-user license agreement or similar agreement that we may apply to the Service, which you must agree to in order to use the Service, and this Privacy Policy.  If you have any questions or concerns about the disclosure of your personal data please contact us at your convenience using the contact information found below.<br>
                        <br>Subject to legal, contractual and technical requirements, you may choose not to provide Galleria with certain data or request the deletion of certain data, which may impact the Service features available to you or essential operations of the Service.</p>
                    
                    <!-- TITLE DESCRIPTION 
                    ================================= -->
                    <h1 class="privacy-policy-content-title">2. Personal data we Collect</h1>
                    <p class="mb25">The following is a description of the personal data that we may collect, use and process in connection with the Service:</p>
                    <ul class="mb25">
                        <li>Your full name for the client account;</li>
                        <li>Your email address, should you decide to request communication from us;</li>
                        <li>Any other personal information that you choose to share with us through our social media accounts and online forums;</li>
                    </ul>
                    <p>We also collect anonymous data that does not identify you, including but not limited to responses to anonymous feedback requests, metrics and other types of statistical data, which could be derived from use of the Service and analytical data concerning the Service.</p>
                    
                    <!-- TITLE DESCRIPTION 
                    ================================= -->
                    <h1 class="privacy-policy-content-title">3. Processing of Your Personal and ANONYMOUS data</h1>
                    <p class="mb25">We process your personal and anonymous data primarily for our legitimate business purposes, such as providing the Service to you, to understand your needs and the needs of our users and to improve the quality of the Service and analyze the data we collect.  Specifically, these uses include but are not limited to:</p>
                    <ul class="mb25">
                        <li>providing the Service;</li>
                        <li>interacting with you through our social media accounts or profiles and providing customer support and feedback;</li>
                        <li>authenticating users;</li>
                        <li>maintaining and analyzing functionality of Villume and the Service, backing up data, testing and developing new aspects of Villume, researching new features of the Service, updating Villume and the Service and generating reports and data models that we use to improve the Service;</li>
                        <li>supporting the security and integrity of the Service and its users including but not limited to detecting and protecting Villume and other third-parties against error, fraud, theft and other illegal activity or for contractual, legal, regulatory or audit obligations; </li>
                        <li>investigating improper or suspicious activity; and</li>
                        <li>contacting you for various reasons including, but not limited to, user feedback requests or marketing purposes and events, if you previously consented to such contact. </li>
                    </ul>
                    <p class="mb25">We may disclose your personal and anonymous data to our parent companies, affiliates, subsidiaries, employees and contractors for the same purposes described above.  We may also disclose your personal and anonymous data to third-parties, who may be located in a foreign jurisdiction and subject to foreign laws.  The situations where we disclose your personal and anonymous information to third-parties include, but are not limited to:</p>
                    <ul>
                        <li>processing your information as described above as well as providing and enhancing the Service features;</li>
                        <li>providing customer support and feedback, analyzing the Service functionality and technical issues, tracking use of the Service and generating reports and data models that we use to improve the Service;</li>
                        <li>providing customized, tailored and personalized advertisements to users of the Service; and</li>
                        <li>responding to a request from law enforcement or a government that has asserted its lawful authority to obtain the data or where Galleria has reasonable grounds to believe the data could be useful in the investigation of unlawful activity, complying with a subpoena or warrant or an order made by a court, person or body with jurisdiction to compel the production of data, complying with court rules regarding the production of records and data, defending our rights and the rights of others or providing information to our legal counsel.</li>
                    </ul>

                    <!-- TITLE DESCRIPTION 
                    ================================= -->
                    <h1 class="privacy-policy-content-title">4. THIRD-PARTY SERVICES</h1>
                    <p>In accordance with this Policy, we may disclose other personal information to third-parties or service providers in other provinces or foreign countries, where it may be processed, stored, or transferred to a foreign country, with different privacy laws. By submitting your personal information to Galleria, you consent to this transfer, storage, and processing. We strongly recommend that you read each third-party privacy policy carefully before using the Service. Galleria may provide data to third-parties, including Stripe. Please contact us directly using the information found below if you would like to learn more about third-party data practices.</p>

                    <!-- TITLE DESCRIPTION 
                    ================================= -->
                    <h1 class="privacy-policy-content-title">5. Cookies and DIGITAL TRACKING Technologies</h1>
                    <p clas>The Service may use tracking technologies, such as cookies web beacons, JavaScript tags and clear GIFs to collect data about you, such as your IP address and device information.  Based on the data collected from the above listed tracking technologies, third-parties may be able to resolve your identity across multiple devices, for example, by matching your IP address, in order to target you based on your behavior.  This data is collected, used and disclosed in accordance with the terms of this Privacy Policy and the applicable third-party privacy policies.</p>

                     <!-- TITLE DESCRIPTION 
                    ================================= -->
                    <h1 class="privacy-policy-content-title">6. Obtaining, rectifying and controlling your personal data</h1>
                    <p class="mb25">You may contact Galleria to obtain a copy of any personal data we collected about you, the production of which may be subject to a fee as permitted by applicable law.  In addition, you may contact Galleria to correct inaccurate personal data or to complete incomplete personal data.
                    <br><br>You may be able to opt-out of some or all of the ways in which your personal data is processed, or request the deletion of certain personal data, except where the personal data is necessary or vital:</p>
                    <ul class="mb25">
                        <li>for the performance of contractual obligations, such as the terms of use;</li>
                        <li>to comply with our legal obligations;</li>
                        <li>to protect your interests or those of another person; or</li>
                        <li>for our legitimate interests or the legitimate interests of a third-party,</li>
                    </ul>
                    <p>and may do so by removing personal data, if the Service permits such removal, by ceasing your access to Galleria and contacting us using the contact information found below.</p>

                    <!-- TITLE DESCRIPTION 
                    ================================= -->
                    <h1 class="privacy-policy-content-title">7. Data Storage, Retention and Transfers</h1>
                    <p>While Galleria is a Canadian company, the data you provide through the Service may be stored and processed by third parties in countries around the world.  You authorize Galleria and third parties acting on our behalf to process your data in any country of their choosing, which may cause your data, including personal and anonymous data, to be subject to privacy protections and legal rights that may not be equivalent to those in your country.
                    <br>
                    <br>Galleria complies with international personal data transfer laws in applicable jurisdictions.  For EU residents, Counselling complies with Articles 44-49 of the European Union’s General Data Protection Regulation (the “GDPR”).  Galleria also follows UK laws equivalent to what is required by the GDPR for all data collected from UK data subjects. Currently, all transfers of personal data to Canada from residents of the EU are covered by an adequacy decision as described in Article 45 of the GDPR.  
                    <br>
                    <br>Your personal data is retained until you request its deletion or until Galleria no longer requires such data for the purpose for which it was collected or until required to be deleted by laws applicable in your jurisdiction.  Please email us at info&#64;villume.com to delete any personal data we hold.</p>

                    <!-- TITLE DESCRIPTION 
                    ================================= -->
                    <h1 class="privacy-policy-content-title">8. Children</h1>
                    <p>Galleria complies with the United States Children’s Online Privacy Protection Act and all other applicable privacy laws and regulations concerning children and the Internet including laws applicable to residents of the EEA. The Service and Villume are not directed to children under the age of 18 and although it may be possible that children below the age of 18 access certain features of the Service, we do not knowingly collect personal data from children under the age of 18 without first obtaining the appropriate parental consent required by law.  If we learn that we inadvertently collected personal data from a child under the age of 18, in a jurisdiction where consent of a parent or guardian is required to be collected prior to the collection of such personal data, without first obtaining such appropriate parental consent, we will delete that data as quickly as possible.  If you are a parent or guardian of a child under 18, who you believe provided Galleria with personal data without your consent, please contact us at info&#64;villume.com. </p>

                     <!-- TITLE DESCRIPTION 
                    ================================= -->
                    <h1 class="privacy-policy-content-title">9. DO NOT TRACK DISCLOSURE</h1>
                    <p>Do Not Track (“DNT”) is a web or device setting that allows users to request that receivers of personal data stop their tracking activities.  When you choose to turn on the DNT setting in your browser or device or use alternative consumer choice mechanisms, your browser or device sends a special signal to websites, analytics companies, advertising networks, plug in providers and other web services you encounter to stop tracking your activity.  Currently, there are no DNT technology standards and it is possible that there may never be any DNT technology standards.  As a result, we do not respond to DNT requests.</p>

                    <!-- TITLE DESCRIPTION 
                    ================================= -->
                    <h1 class="privacy-policy-content-title">10. CALIFORNIA “SHINE THE LIGHT” RIGHT</h1>
                    <p>If you are a California resident, California Civil Code § 1798.83 grants you the right to request our disclosure of the categories of personal information we provided to third parties, and the names and addresses of these third parties, for direct marketing purposes during the preceding calendar year.  If you are a California resident and would like to make this request, please contact us using the contact information set forth below.
                    <br>
                    <br>Please note that you may only make this request once per year.</p>

                    <!-- TITLE DESCRIPTION 
                    ================================= -->
                    <h1 class="privacy-policy-content-title">11. YOUR CALIFORNIA PRIVACY RIGHTS</h1>
                    <p>If you are a California resident, California Civil Code Section 1798.120 permits you to opt-out of the sale of your personal information to third parties.  Currently, Galleria does not sell your personal information to third parties and as such no opt-out of the sale of such personal information is necessary.  Although Galleria does not sell your personal information, Galleria does provide a general opt-out right to all of our clients and users as described in our Privacy Policy.  Furthermore, as Galleria already complies with Canadian and EU privacy laws, the rights required to be provided by law to Californian residents are provide to all our clients and users.  If you have any other questions about our privacy practices, please email us at info&#64;villume.com.</p>

                    <!-- TITLE DESCRIPTION 
                    ================================= -->
                    <h1 class="privacy-policy-content-title">12. European users and rights of european Economic Area residents</h1>
                    <p>Galleria has a legitimate interest, as described in Article 6(1)(f) of the GDPR, in collecting your personal data as described in section 2 of this Privacy Policy for the purposes described in detail in section 3 of this Privacy Policy.  In addition, when you provide consent to contact you using your email address or when providing us your contact information by linking a third-party Account to our Service, Galleria may contact and communicate with you using this information, pursuant to Article 6(1)(a) of the GDPR.
                    <br>
                    <br>If you are a resident of the European Union, you have certain rights in regard to your personal data. These rights include:
                    <br>
                    <br><span class="privacy-policy-content-subtitle">A Right of Access.</span>  You have the right to access your personal data that we hold about you free of charge in most circumstances;
                    <br>
                    <br><span class="privacy-policy-content-subtitle">A Right to Rectification.</span>  If your personal data is inaccurate or incomplete, you can change the information you provided by changing the information yourself on your Account or by contacting Galleria using the email listed below;
                    <br>
                    <br><span class="privacy-policy-content-subtitle">A Right to Erasure.</span>  You have the right to obtain deletion of personal data concerning you in many cases. Often you can just delete your information by yourself through your Account, but you can also request the deletion by using the contact information found below.  Please be careful as the deletion of your data in this manner is permanent and may never be restored; 
                    <br>  
                    <br><span class="privacy-policy-content-subtitle">A Right to Object.</span>  If the processing of your personal data is based on legitimate interests according to Article 6(1)(f) of the GDPR, you have the right to object to this processing in most cases.  If you object, we will no longer process your personal data unless there are compelling and prevailing legitimate grounds for the processing as described in Article 21 of the GDPR; in particular if the personal data is necessary for the establishment, exercise or defense of legal claims or in the case where the personal data is required for the provision of certain features of the Service, and you still wish to use such features of the Service;
                    <br> 
                    <br><span class="privacy-policy-content-subtitle">A Right to file a Complaint.</span>  You have the right to file a complaint with the appropriate supervisory authority in your jurisdiction;
                    <br> 
                    <br><span class="privacy-policy-content-subtitle">A Right to Restriction of Processing of your Personal Data.</span>  You have the right to obtain restrictions on the processing of your personal data as described in Article 18 of the GDPR;
                    <br>
                    <br><span class="privacy-policy-content-subtitle">A Right to Personal Data Portability.</span>  You have the right to receive your Personal Data in a structured, commonly used and machine-readable format and have the right to transmit such data to another controller under the conditions described in Article 20 of the GDPR. Although the personal data collected by Galleria is not particularly useful to other companies as the data collected largely relates to your usage of the Service, Galleria will comply will all requests for the portability of your personal data;
                    <br> 
                    <br><span class="privacy-policy-content-subtitle">A Right to Post-Mortem Control of Your Personal Data.</span>  If data protection legislation and related regulations in France are applicable to you, you have the right to establish guidelines for the preservation, the deletion and the transmission of your personal data after your death in accordance with Article 40-1 of the Act No. 78-17 of January 6, 1978 on Information, Technology, Data Files and Civil Liberties; and
                    <br>
                    <br><span class="privacy-policy-content-subtitle">A Right to Opt-out of Marketing Communications.</span>  You have the right to opt-out of marketing communications we send you at any time. You can exercise this right by clicking on the “unsubscribe” or “opt-out” link on any marketing e-mails Galleria sends you.  To opt-out of other forms of marketing, please contact us using the contact details provided below.</p>

                    <!-- TITLE DESCRIPTION 
                    ================================= -->
                    <h1 class="privacy-policy-content-title">13. Change of Ownership or Business Transition</h1>
                    <p>In the event of, or in preparation for, a change of ownership or control of Galleria or a business transition such as the sale of Galleria or the sale or license of some or all Galleria’s assets, we may disclose and/or transfer your personal and anonymous data to third-parties who will have the right to continue to collect and use such data in the manner set forth in this Privacy Policy.</p>

                    <!-- TITLE DESCRIPTION 
                    ================================= -->
                    <h1 class="privacy-policy-content-title">14. Security</h1>
                    <p>We are committed to ensuring that your data is secure.  To prevent unauthorized access, disclosure, or breach, we put in place suitable physical, electronic, and administrative procedures to safeguard and secure the data we collect and process.</p>

                    <!-- TITLE DESCRIPTION 
                    ================================= -->
                    <h1 class="privacy-policy-content-title">15. CONTACT PREFERENCES</h1>
                    <p>We may communicate with you through the Service itself, but we may sometimes collect your email address in order to respond to support requests or comments.  If you have provided us with your email address and would like to change the email preferences, we associate with you (for example, unsubscribing from receiving certain types of email) you may do so by clicking a link within certain types of emails that we send to you or, if no link is available, by replying with “unsubscribe” in the email title or body or by modifying your email settings within the Service.  On rare occasions, some types of email are necessary for the Service and cannot be unsubscribed from if you continue to use the Service.</p>

                    <!-- TITLE DESCRIPTION 
                    ================================= -->
                    <h1 class="privacy-policy-content-title">16. Updates</h1>
                    <p>Galleria reserves the right, in its sole discretion, to modify the Privacy Policy at any time (“Updates”) and shall make Updates available on the Website.  You are deemed to accept Updates by continuing to use the Service.  Unless Galleria states otherwise, Updates are automatically effective 30 days after posting on the Website.</p>
                    
                    <!-- TITLE DESCRIPTION 
                    ================================= -->
                    <h1 class="privacy-policy-content-title">17. Contact Us</h1>
                    <p>If you have requests, questions or comments about the Privacy Policy or our data collection in general, please contact our Data Protection Officer at info&#64;villume.com.</p>
                </div>

                <!-- END DESCRIPTION -->
            </div>
        </div>
        <div class="text-center">
            <button class="btn btn-primary" onclick="history.back()"><i class="vi vi-arrow-left"></i> Back</button>
        </div>
    </div>
</app-layouts>