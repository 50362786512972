// Angular packages
import { Component } from '@angular/core';

// User-defined services
import { EditorService } from '../../editor.service';
import { ImagesService } from '../images.service';
import { AddImageService } from './add-image.service';

// User-defined interfaces
import { AllowedFile, VideoData } from '../images.interfaces';

// Data
import { Artwork } from 'src/app/shared/interfaces';
import { OnFinishedEvent, UsedMarker } from './submit/submit.interfaces';
import { UtilsService } from '@services';
import { LoadingGalleryService } from 'src/app/components/loading-gallery/loading-gallery.service';
import { SubmitComponent } from './submit/submit.component';
import { MultiPreviewComponent } from './multi-preview/multi-preview.component';
import { SinglePreviewComponent } from './single-preview/single-preview.component';
import { AddVideoComponent } from './add-video/add-video.component';
import { FilesHandlerComponent } from './files-handler/files-handler.component';
import { NgSwitch, NgSwitchCase, NgIf } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { TooltipModule } from 'primeng/tooltip';

// Thrid-party packages (CDN)
declare const BABYLON: any;

@Component({
    selector: 'app-add-image',
    templateUrl: './add-image.component.html',
    styleUrls: ['./add-image.component.scss'],
    standalone: true,
    imports: [TooltipModule, DialogModule, NgSwitch, NgSwitchCase, FilesHandlerComponent, AddVideoComponent, SinglePreviewComponent, MultiPreviewComponent, NgIf, SubmitComponent]
})
export class AddImageComponent {
  public videoData: VideoData;
  public isVideo: boolean = false;
	public addArtworkSection: 'select-files' | 'single-preview' | 'multiple-preview' = 'select-files'; 
  public invalidNames: boolean = true;
  public artworkFiles: AllowedFile[] = [];
  public onFetchVideoData: boolean = false;

  private _placeholderMesh: any = null;
	private _isOnTheWall: boolean = false;
  private _meshMarker: any[] = [];
  private _uploadedArtworks: any[] = [];
  private _placedArtworks: any[] = [];


  constructor(
    public editorService: EditorService,
    public addArtworkService: AddImageService,
    private _artworkService: ImagesService,
    private _utilsService: UtilsService,
    private _loadingGalleryService: LoadingGalleryService
  ) { }
  
	/**
	 * * =============================================================================== * 
	 *   SECTION Placing Artwork Pointer Observable Functions
	 * * =============================================================================== * 
	 */
	//#region 

	/**
	 * ANCHOR Init Placing Artwork Pointer Observable *
   * @description to init placing artwork pointer observable
	 */
	public initPlacingArtworkPointerObservable(): void {
		if (!this.editorService.observables['placingArtworkPointerObservable']) {
			const pointerDownCoordinates = { x: 0, y: 0 }
			this.editorService.observables['placingArtworkPointerObservable'] = this.editorService.scene.onPointerObservable.add((pointerInfo) => {
				if (!this.editorService.previewAutoPlacedArtworks) {
          switch (pointerInfo.type) {
              case BABYLON.PointerEventTypes.POINTERDOWN: 
                pointerDownCoordinates.x = pointerInfo.event.clientX;
                pointerDownCoordinates.y = pointerInfo.event.clientY;
              break;
              case BABYLON.PointerEventTypes.POINTERUP: 
                const isTapEvent = (
                  pointerInfo.event.clientX === pointerDownCoordinates.x &&
                  pointerInfo.event.clientY === pointerDownCoordinates.y
                );
                if (isTapEvent) this._onPointerTapPlacingArtwork()
              break;
              case BABYLON.PointerEventTypes.POINTERMOVE: 
                this._onPointerMovePlacingArtwork(pointerInfo); 
              break;
            }
          }
			}, BABYLON.PointerEventTypes.POINTERDOWN | BABYLON.PointerEventTypes.POINTERUP | BABYLON.PointerEventTypes.POINTERMOVE );

			window.dispatchEvent(new Event('blur'));
			window.dispatchEvent(new Event('focus'));
		}
	}

	/**
	 * * ANCHOR ON POINTER MOVE PLACING ARTWORK *
	 * Todo: to handle pointer move
	 * @param pointerInfo : PointerInfo
	 */
	private _onPointerMovePlacingArtwork(pointerInfo): void {
		const artworkType = this.editorService.artworkToPlaced['artworkNode']['artworkType'];
    if (artworkType) {
      if (artworkType == 'figure-object') {
        const dragArea = pointerInfo.pickInfo.ray.intersectsMeshes(this.editorService.activeExhibitionNode.getChildren())[0];
        if(dragArea) this.editorService.artworkToPlaced.position = dragArea.pickedPoint;
      } else {
        const dragArea = pointerInfo.pickInfo.ray.intersectsMeshes(this.editorService.dragAreaMeshes)[0];
        this._isOnTheWall = dragArea?.pickedMesh.name.toLowerCase().includes('drag_area');
        if (this._isOnTheWall) {
          this.editorService.artworkToPlaced.shadow?.setEnabled(false);
          const { position, rotation } = this.editorService.getPositionOnWall(dragArea, this.editorService.artworkToPlaced.scaling.z);
          this.editorService.artworkToPlaced.position = position;
          this.editorService.artworkToPlaced.rotation = rotation;
        }
      }
    }
	}

	/**
	 * * ANCHOR ON POINTER TAP PLACING ARTWORK *
	 * Todo: to handle pointer tap
	 * @param pointerInfo : PointerInfo
	 */
	private _onPointerTapPlacingArtwork(): void {
		const artworkType = this.editorService.artworkToPlaced['artworkNode']['artworkType'];
		if (artworkType == 'figure-object') this._placeArtwork();
	  else {
			if (this._isOnTheWall) this._placeArtwork();
		}
	}

	/**
	 * ANCHOR Place Uploaded Artwork
	 * Todo: to place uploaded artwork
	 */
	private async _placeArtwork(): Promise<void> {
    this.editorService.blockUserAction = false;
    this.editorService.onPlacingArtwork = false;
    const artworkNode = this.editorService.artworkToPlaced['artworkNode'];
    artworkNode.position = this.editorService.artworkToPlaced.position.clone();
    artworkNode.rotation = this.editorService.artworkToPlaced.rotation.clone();

    const textBlock = this._meshMarker[0]['textBlock'];
    const advancedTexture = this._meshMarker[0]['advancedTexture'];

    if (textBlock) textBlock.dispose();
    if (advancedTexture) advancedTexture.dispose();
    this.editorService.artworkToPlaced.dispose();
    this.editorService.artworkToPlaced = null;

    this._meshMarker.shift();
    if (this._meshMarker.length >= 1) {
      await this._getMarkerArtwork();
    } else { 
      this.editorService.scene.onPointerObservable.clear();
      this.editorService.scene.onBeforeRenderObservable.clear();
      this.editorService.observables = {};
      this.editorService.initMainPointerObs();
      this.editorService.setHorizontalCameraMovementObs(this.editorService.exhibition.horizontal_view_movement);
    }
    this.editorService.updateArtworkData(artworkNode, this.editorService.getArtworkData(artworkNode.id), false);
    this.editorService.canvas.blur();
    this.editorService.canvas.focus();

    this._placedArtworks.push(this.editorService.getArtworkData(artworkNode.id));
    this._updateUndoRedoState();
	}

	//#endregion
  // !SECTION

  /**
	 * * =============================================================================== * 
	 *   SECTION Load Artwork Functions
	 * * =============================================================================== * 
	 */
  //#region 

  /**
   * ANCHOR Create Placing Marker
   * @description to create placing marker
   * @param artworkNode : BABYLON.TransformNode
   */
  private _createPlacingMarker(artworkNode): any {
    const meshParent = new BABYLON.Mesh("parent", this.editorService.scene);
		artworkNode.getChildMeshes().map((mesh) => {
			const clonedMesh = mesh.clone();
      clonedMesh.setParent(meshParent)
    })

		const childMeshes = meshParent.getChildMeshes();
		let min = childMeshes[0].getBoundingInfo().boundingBox.minimumWorld;
		let max = childMeshes[0].getBoundingInfo().boundingBox.maximumWorld;

		for(let i=0; i<childMeshes.length; i++){
			const meshMin = childMeshes[i].getBoundingInfo().boundingBox.minimumWorld;
			const meshMax = childMeshes[i].getBoundingInfo().boundingBox.maximumWorld;

			min = BABYLON.Vector3.Minimize(min, meshMin);
			max = BABYLON.Vector3.Maximize(max, meshMax);
		}

		meshParent.setBoundingInfo(new BABYLON.BoundingInfo(min, max));
		meshParent.showBoundingBox = true;

    const placingMarker = this.editorService.scene.getMeshByName('scalingTmp').clone();
    placingMarker.name = 'placingMarker';
    placingMarker.position.copyFrom(meshParent.getBoundingInfo().boundingBox.centerWorld);
    placingMarker.rotation = artworkNode.rotation.clone();
    placingMarker.scaling = artworkNode.scaling.clone();
    placingMarker.enableEdgesRendering();
    placingMarker.isPickable = false;

    const wrapPlacingMarker = new BABYLON.TransformNode(`wrapPlacingMarker`, this.editorService.scene);
    wrapPlacingMarker.position = artworkNode.position.clone();
    wrapPlacingMarker.rotation = artworkNode.rotation.clone();
    wrapPlacingMarker.scaling = artworkNode.scaling.clone();
    placingMarker.setParent(wrapPlacingMarker);
    meshParent.dispose(); 

    return wrapPlacingMarker;
  }

   /**
   * ANCHOR Processing New Artwork Data
   * @description to process new artwork data
   * @param artwork : Artwork
   */
   private _processingNewArtworkData(artwork: Artwork): void {
    artwork.video_stream = this.videoData?.video.stream;
    artwork.image = this._artworkService.getArtworkImage(artwork);
    artwork.model_path = this._artworkService.getModelPath(artwork);
  }

  /**
   * ANCHOR Create Artwork Node
   * @description to create artwork node
   * @param artwork : Artwork
   * @returns: Promise<any>
   */
  private async _createArtworkNode(artwork: Artwork): Promise<any> {
    let artworkNode: any;
    if(['figure-image', 'video'].includes(artwork.file_type)) {;
      artworkNode =  await this.editorService.createArtworkImageVideo(artwork);
      this._createDonutAndShadow(artworkNode);
    } else {
      artworkNode = await this.editorService.loadArtworkObject(artwork);
      artworkNode['shadowHasInitialized'] = true;
      artworkNode['donutHasInitialized'] = true;
    }
    return artworkNode;
  }

  /**
   * ANCHOR Get Marker Artwork For Placing
   * @description to place artwork to position
   */
  private async _getMarkerArtwork(): Promise<void> {
    this.editorService.onPlacingArtwork = true;
    this.editorService.artworkToPlaced = this._meshMarker[0];
    this.editorService.scene.onPointerObservable.clear();
    this.editorService.scene.onBeforeRenderObservable.clear();
    this.editorService.observables = {};

    this._createCountPlaceholderMarker(this._meshMarker[0]);
    this.editorService.scene.onReadyObservable.add(() => {
      this.initPlacingArtworkPointerObservable();
      this._enableLoading(false);
    })
  }

  /**
   * ANCHOR Create Count Number Multi Artwork
   * @description to create placing number for multi artwork
   * @param marker : BABYLON.TransformNode
   */
  private _createCountPlaceholderMarker(marker: any) {
    if (this._meshMarker.length > 1) {
      // create plane for number
      const wrapperCount = BABYLON.MeshBuilder.CreatePlane("plane", {size: 1}, this.editorService.scene);
      wrapperCount.position = marker.position.clone();
      wrapperCount.rotation = marker.rotation.clone();
      wrapperCount.scaling = new BABYLON.Vector3(1, 1, 1);
      wrapperCount.setParent(marker);
      wrapperCount.scaling.x = 1;
      wrapperCount.isPickable = false;

      const artworkType = marker['artworkNode']['artworkType'];
      switch (artworkType) {
        case 'figure-object':
          wrapperCount.position = marker.getChildMeshes()[0].position.clone();
          wrapperCount.billboardMode = BABYLON.Mesh.BILLBOARDMODE_ALL;
          break;
        case 'figure-image':
          wrapperCount.rotation.y = Math.PI;
          break;
      }

      // create GUI. textblock, and advanced texture
      const number = this._meshMarker.length;
      const advancedTexture = BABYLON.GUI.AdvancedDynamicTexture.CreateForMesh(wrapperCount);
      const countNumber = new BABYLON.GUI.TextBlock();
      countNumber.text = number.toString();
      countNumber.color = 'black';
      countNumber.fontSize = 200;
      countNumber.paddingRight = '20px';
      
      advancedTexture.addControl(countNumber);
      countNumber.linkWithMesh(wrapperCount);

      marker['textBlock'] = countNumber;
      marker['advancedTexture'] = advancedTexture;
    }
  }

  /**
   * ANCHOR Create Donut And Shadow
   * @description to create donut and shadow
   * @param artworkNode : BABYLON.TransformNode
   */
  private _createDonutAndShadow(artworkNode: any): void {
    this.editorService.createShadowCast(artworkNode);
    this.editorService.createShadowComponents(artworkNode);
    this.editorService.createArtworkDonut(artworkNode);
  }

  //#endregion
  // !SECTION

   /**
   * * =============================================================================== *
   *   SECTION On Submit Artwork Finished Functions 
   * * =============================================================================== *
   */
  //#region 

  /**
   * ANCHOR On Finished
   * @description to handle when submit is finished
   * @param e : Artwork[] || null
   */
  public onFinished(e: OnFinishedEvent): void {
    const { usedMarkers, artworkData } = e;
    this._setPlacementAction(usedMarkers, artworkData);
    this._uploadedArtworks = artworkData.map((artwork: Artwork) => artwork.id);
    this._placeMultiArtwork(artworkData);
  }

  /**
   * ANCHOR Place Multi Artwork
   * @description to place multi artwork
   * @param artwork : Artwork[]
   */
  private async _placeMultiArtwork(artwork: Artwork[]): Promise<void> {
    const autoPlaceArtworks = artwork.filter((artwork: Artwork) => artwork.placement_action.includes('auto'));
    const manualPlaceArtworks = artwork.filter((artwork: Artwork) => artwork.placement_action == 'manual');
    const multiArtwork: boolean = artwork.length > 1;

    if(manualPlaceArtworks.length > 0) {
      for(let i=0; i < manualPlaceArtworks.length; i++) {
        await this._manualPlaceArtwork(manualPlaceArtworks[i], i)
      }
      this._getMarkerArtwork();
    } else {
      this._enableLoading(false);
    }

    await Promise.all(autoPlaceArtworks.map(async (artwork: Artwork) => await this._autoPlaceArtwork(artwork, multiArtwork)));
  }

  /**
   * ANCHOR Auto Place Artwork
   * @description to auto place artwork
   * @param artwork : Artwork
   * @returns : Promise<void>
   */
  private async _autoPlaceArtwork(artwork: Artwork, multiArtwork:boolean): Promise<void> {
    artwork.deleted = false;
    this.editorService.artworks.unshift(artwork);
    this._processingNewArtworkData(artwork)
    await this._createArtworkNode(artwork);
    this.editorService.markPositionPlaceholderAsUsed(
      artwork.placement_marker,
      artwork.id
    );

    this.previewArtworkAutoPlace(artwork, multiArtwork);
    artwork.placement_marker = null;

    this._placedArtworks.push(artwork.id);
    this._updateUndoRedoState();
  }

  /**
   * ANCHOR Update Undo Redo State
   * @description to update undo redo state if all artworks are placed
   */
  private _updateUndoRedoState() {
    if(this._placedArtworks.length == this._uploadedArtworks.length) {
      setTimeout(() => {
        if (!this.editorService.previewAutoPlacedArtworks) this.editorService.updateUndoRedoState();
      }, 500);
      this._placedArtworks = [];
      this._uploadedArtworks = [];
    }
  }
  
  /**
   * ANCHOR Preview Artwork Auto Place
   * @description to auto place artwork
   * @param artwork : Artwork
   * @returns : Promise<void>
   */
  private positionArtwork: any[] = [];
  private lastCameraPosition: any;
  private previewArtworkAutoPlace(artwork:any, multiArtwork:boolean): void {
    const camera = this.editorService.scene.activeCamera;
    const artworkMesh = this.editorService.scene.getTransformNodeByID(`artwork-${artwork.id}`);
    const mesh = artworkMesh.getChildren()[3];
    if (!this.lastCameraPosition) this.lastCameraPosition = {position: camera.position.clone(), target: camera.target.clone()};

    setTimeout(() => {
      // check if artwork is in view
      let seen = true;
      if (camera.isInFrustum(mesh.getBoundingInfo().boundingSphere)) {
        const ray = this._utilsService.createRaycast({
          node: this.editorService.mainCameraNode,
          direction: artworkMesh.position,
          scene: this.editorService.scene
        })

        const pickInfo = this.editorService.scene.pickWithRay(ray);
        if (pickInfo.pickedMesh?.parent?.id.includes(artwork.id)) seen = true;
        else seen = false;
      } else {
        seen = false;
      }

      if (!seen) {
        this._enableLoading(false);
        this.positionArtwork.push(artworkMesh.position.clone());
        if (multiArtwork) {
          const folderName = this.editorService.exhibition.model_path.split("/").slice(-2)[0]
          if (folderName !== 'FOUR-WALL') this.editorService.scene.clearColor = BABYLON.Color4.FromHexString('#515151')
            this._setupCeilingExhibition(false);
          this.addToAnimationQueue(this.animationAfterAutoPlaceArtwork.bind(this, artworkMesh));
        } else {
          this._previewSingleArtwork(artworkMesh);
        }
      }
    }, 500);
  }

  private _previewSingleArtwork(artwork: Artwork) {
    this.editorService.focusOnArtworkAnimation(artwork);
  }

  /**
   * ANCHOR Animation After Auto Place Artwork
   * @description to preview auto place artwork
   * @param artwork : Artwork
   * @returns : Promise<void>
   */
  private animationAfterAutoPlaceArtwork(artwork:any): Promise<void> {
    return new Promise((resolve, reject) => {
      const camera = this.editorService.scene.activeCamera;
      camera.detachControl(this.editorService.canvas, true);
      this.editorService.blockUserAction = true;
      
      const centerPosition = this.editorService.exhibition.config.centerPosition;
      const previewPosition = new BABYLON.Vector3(centerPosition.x, 7.6, centerPosition.z);
      
      this.editorService.previewAutoPlacedArtworks = true;
      this._utilsService.enableHighlight({
        exhibitAsset: artwork,
        highlightLayer: this.editorService.highlightLayer,
        enable: true
      });
      this.editorService.setHorizontalCameraMovementObs(false);

      const ease = this._setEaseMode();
      BABYLON.Animation.CreateAndStartAnimation('flyingCameraAnimation', camera, 'position', 15, 60, camera.position, previewPosition, 0, ease );
      BABYLON.Animation.CreateAndStartAnimation('flyingRotationAnimation', camera, 'target', 15, 60, camera.target, this.positionArtwork[0], 0, ease, () => {
        this.positionArtwork.shift();
        this._utilsService.enableHighlight({
          exhibitAsset: artwork,
          highlightLayer: this.editorService.highlightLayer,
          enable: false
        });
        resolve(null);
      });
    });
  }

  /**
   * ANCHOR End Animation Preview
   * @description to end animation preview
   * @returns : Promise<void>
   */
  private endAnimationPreview(): Promise<void> {
    return new Promise((resolve, reject) => {
      const camera = this.editorService.scene.activeCamera;

      const ease = this._setEaseMode();
      BABYLON.Animation.CreateAndStartAnimation('landingCameraAnimation', camera, 'position', 15, 40, camera.position, this.lastCameraPosition.position, 0, ease);
      BABYLON.Animation.CreateAndStartAnimation('landingRotationAnimation', camera, 'target', 15, 60, camera.target, this.lastCameraPosition.target, 0, ease, () => {
        this._setupCeilingExhibition(true);
        camera.attachControl(this.editorService.canvas, true);
        this.editorService.setHorizontalCameraMovementObs(this.editorService.exhibition.horizontal_view_movement);
        this.editorService.previewAutoPlacedArtworks = false;
        this.lastCameraPosition = null;
        this.editorService.blockUserAction = false;
        this._updateUndoRedoState();
        resolve(null);
      });
    });
  }

  /**
   * ANCHOR Add To Animation Queue
   * @description add to animation queue
   * @returns : Promise<void>
   */
  private animationQueue: (() => Promise<void>)[] = [];
  public async addToAnimationQueue(animationFunction: () => Promise<void>): Promise<void> {
    this.animationQueue.push(animationFunction);
    if (this.animationQueue.length === 1) {
      await this.runAnimationQueue();
    }
  }

  /**
   * ANCHOR Run Animation Queue
   * @description run animation queue
   * @returns : Promise<void>
   */
  private async runAnimationQueue(): Promise<void> {
    while (this.animationQueue.length > 0) {
      const animationFunction = this.animationQueue[0];
      await animationFunction();
      this.animationQueue.shift();
    }

    if (this.animationQueue.length === 0) {
      await this.endAnimationPreview();
    }
  }

  /**
   * ANCHOR Set Ease Mode for Animation
   * @description : to set ease mode for animation
   * @returns : BABYLON.CubicEase
   */
  private _setEaseMode(): any {
    const ease = new BABYLON.CubicEase();
    ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT);
    return ease;
  }

  /**
   * * ANCHOR SETUP CEILING EXHIBITION *
   * Todo: to setup ceiling
   */
  private async _setupCeilingExhibition(enabled: boolean = true): Promise<void> {
      if (this.editorService.exhibition.config?.ceiling) {

        this.editorService.activeExhibitionNode.getChildren().map((node: any) => {
          if (this.editorService.exhibition.config?.ceiling.includes(node['name'])) {
            node.setEnabled(enabled);
          }
        })
      }
  }

  /**
   * ANCHOR Manual Place Artwork
   * @description to manual place artwork
   * @param artwork : Artwork
   * @returns : Promise<void>
   */
  private async _manualPlaceArtwork(artwork: Artwork, idx: number = 0): Promise<void> {
    artwork.deleted = false;
    this.editorService.artworks.unshift(artwork);
    this._processingNewArtworkData(artwork)
    const artworkNode = await this._createArtworkNode(artwork);
    const meshMarker = this._createPlacingMarker(artworkNode)
    this._meshMarker.push(meshMarker);
    this._meshMarker[idx]['artworkNode'] = artworkNode;
    meshMarker.position.y = -100;
    artworkNode.position.y = -100;
    artworkNode['shadow']?.setEnabled(false);
  }

  /**
   * ANCHOR Set Placement Action
   * @param usedMarkers : UsedMarker[]
   * @param artworkDatas : Artwork[]
   */
  private _setPlacementAction(usedMarkers: any[], artworkDatas: Artwork[]): void {
    artworkDatas.forEach((artwork: Artwork) => {
      const marker: UsedMarker = usedMarkers.find((usedMarker: UsedMarker) => {
        const { x, y, z } = usedMarker.mesh.position;
        const { position_x, position_y, position_z } = artwork.position;
        return ( x == position_x && y == position_y && z == position_z )
      })

      if(!marker) artwork.placement_action = 'manual';
      else {
        const isInside = marker.position == 'inside';
        artwork.placement_action = isInside ? 'auto-inside' : 'auto-outside';
        artwork.placement_marker = marker.mesh;
      }
    })
  }

  //#endregion
  //!SECTION

  /**
   * * =============================================================================== *
   *   SECTION Uncategorized Functions 
   * * =============================================================================== *
   */
  //#region

  /**
   * ANCHOR Is Add Artwork Disabled
   * @description to check if add artwork button is disabled
   * @returns : boolean
   */
  public isAddArtworkDisabled(): boolean {
    return (
      this._loadingGalleryService.show ||
      this.editorService.blockUserAction ||
      this.editorService.previewMode ||
      this.editorService.onPlacingArtwork
    )
  }

  /**
   * ANCHOR Open Add Artwork Modal
   * @description to open add artwork modal
   */
  public openAddArtworkModal(): void {
    this.addArtworkSection = 'select-files';
    this.videoData = null;
    this.addArtworkService.displayAddPopup = true;
    this.invalidNames = true;
  }

  /**
   * ANCHOR Back To Select Files
   * @description to back to select files
   */
  public backToSelectFiles(): void {
    this.addArtworkSection = 'select-files';
    this.videoData = null;
    this.isVideo = false;
    this.invalidNames = true;
  }

  /**
   * ANCHOR Enable Loading
   * @description to enable loading
   * @param enable : boolean
   */
  private _enableLoading(enable: boolean) {
    this.editorService.blockUserAction = enable;
    this._loadingGalleryService.show = enable;
    this._loadingGalleryService.percent = 0;
  }

  /**
   * ANCHOR On Select Files
   * @description to handle on select files
   * @param e: AllowedFile[]
   */
  public onSelectFiles(e): void {
    if(!e) return;

    this.artworkFiles = e;
    if(e.length > 1) this.addArtworkSection = 'multiple-preview';
    else this.addArtworkSection = 'single-preview';
  }

  /**
   * ANCHOR On Video Submited
   * @description to handle when video is submited
   * @param e : VideoData
   */
  public onVideoSubmited(e): void {
    this.videoData = e;
    this.isVideo = false;
    if(this.videoData) {
      this.addArtworkSection = 'single-preview';
      this.isVideo = true;
    }
  }

  /**
   * ANCHOR On Hide Add Artwork Modal
   */
  onOpenAddArtwork() {
    this.addArtworkSection = 'select-files';
    this.videoData = null;
    this.isVideo = false;
    this.artworkFiles = [];
  }

  //#endregion
  //!SECTION
}
