<app-layouts>
    <div content>
        <div class="wrap-notfound">
            <div class="row w-100">
                <div class="col col16-8 offset16-4 wrap-title">
                    <h1 class="title">
                        Page Not Found :(
                    </h1>
                </div>
            </div>
            <div class="row w-100">
                <div class="col col16-5 offset16-6 wrap-desc">
                    <div class="desc">
                        <p>
                            Sorry, the page you are looking for was moved, removed, renamed or might be under construction.
                        </p>
                        <a routerLink="/" class="btn btn-primary">
                            <i class="vi vi-arrow-left"></i> Back
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-layouts>