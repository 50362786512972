export const editorReducer = ( state = {
    objectHasSelected: false,
    displayDeleteExhibition: false,
    undoRedo:null,
    updateObjectData: null,
    validateData: null,
}, action ) =>{
	switch (action.type) {
        case "UPDATE_STAT_SELECT_OBJECT":
            state.objectHasSelected = action.objectHasSelected;
        break;
        case "UPDATE_DELETE_EXIHINITION_STAT":
            state.displayDeleteExhibition = action.displayDeleteExhibition;
        break;
        case "UNDO_REDO_ACTION":
            state.undoRedo = action.undoRedo;
        break;
        case "UPDATE_OBJECT_DATA":
            state.updateObjectData = action.updateObjectData;
        break;
        case "VALIDATE_DATA":
            state.validateData = action.validateData;
        break;
    }
    return state;
}