import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from '@environments';
import { MainService } from '@services';
import { AlertMessageService } from '../../alert-message/alert-message.service';
import { NotSubsDialogComponent } from '../../not-subs-dialog/not-subs-dialog.component';
import { ProfileService } from 'src/app/pages/profile/profile.service';
import { LayoutsService } from 'src/app/layouts/layouts.service';
import { DialogModule } from 'primeng/dialog';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-preview-exhibition',
    templateUrl: './preview-exhibition.component.html',
    styleUrls: ['./preview-exhibition.component.scss'],
    standalone: true,
    imports: [NotSubsDialogComponent, DialogModule, NgIf]
})
export class PreviewExhibitionComponent {
  @Input() shareString: string = "";
  @Input() exhibitionID: string = "";
  @Input() exhibitionName: string = "";
  @Input() isProfile: boolean = false;

  public shareUrl: SafeResourceUrl;
  public shareLink: string;
  public env = environment;
  public isShare: boolean = false;
  public shareableLink: string;
  public iframe: string;

  constructor(
    private sanitizer: DomSanitizer,
    public mainService: MainService,
    private _router: Router,
    private _messageService: AlertMessageService,
    private _profileService: ProfileService,
    public layoutsService: LayoutsService
  ) {
    this.shareUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.env.viewer_path+'/'+this.shareString+'?pv=true');
    this.shareLink = this.env.viewer_path+'/'+this.shareString;
    this.iframe = `<iframe width="560" height="315" src="${this.shareLink}" title="${this.exhibitionName}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
  }

  ngOnChanges() {
    this.shareUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.env.viewer_path+'/'+this.shareString+'?pv=true');
    this.shareLink = this.env.viewer_path+'/'+this.shareString;
    this.iframe = `<iframe width="560" height="315" src="${this.shareLink}" title="${this.exhibitionName}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
  }

  ngAfterViewInit() {
    if (this.isProfile) window.scrollTo(0, 0);
  }

  public closePreview() {
    if (this.isProfile) this._profileService.refetchExhibitions = true;
    if (this.isProfile) this._profileService.refetchExhibitions = true;
    this._router.navigate(['/'+this.mainService.userInfo.username]);
    this.mainService.createExhibitionWidget = false;
  }

  /**
	 * * OPEN LINK NEW TAB *
	 * Todo: for open link in new tab
	 */
	public openNewTab(url: string){
    const newWin = window.open(url, '_blank');
    if(!newWin || newWin.closed || typeof newWin.closed=='undefined'){ 
      window.open(url, '_self');
    } else {
      this._profileService.refetchExhibitions = true;
      this._router.navigate(['/'+this.mainService.userInfo.username]);
    }
  };

  /**
	 * * COPY TEXT *
	 * Todo: for copy text
	 */
	copyText(id){
		const copyText:any = document.getElementById(id);
		copyText.select();
		copyText.setSelectionRange(0, 99999);
		document.execCommand("copy");
    if (id === "sharelink") {
      this._messageService.add({severity:"success", detail: "The link has been copied."})
    } else {
      this._messageService.add({severity:"success", detail: "The code has been copied."})
    }
	}
}
