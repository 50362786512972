<div class="wrap-edit-frame" *ngIf="editorService.editFrameMode&&figureDataCloned">
    <h2 class="title-edit-frame">Edit Frame</h2>

    <div class="row">
        <!-- Start Preview Frame Scene -->
        <div class="col col16-8 modal-frame pr-0">
            <!-- Scene -->
            <canvas id="editFrameCanvas" style="width: 100%; height: 450px; outline:none"></canvas>
            <div *ngIf="camera" class="content-settings p-0 mt15">
                <div class="form-group side-by-side">
                    <button 
                        (mousedown)="zoom('out',true)" 
                        (mouseup)="zoom('out',false)" 
                        (mouseleave)="zoom('out',false)" 
                        class="btn btn-circle-icon mr15">
                        <i class="vi vi-dash"></i>
                    </button>
                    <p-slider
                        [(ngModel)]="cameraView"
                        (onChange)="camera.radius = -cameraView; figureMeshRadiusChange = true;"
                        styleClass="active-slider"
                        [step]="0.1"
                        [min]="-camera.upperRadiusLimit"
                        [max]="-camera.lowerRadiusLimit"
                        [ngStyle]="{'width' : '100%'}">
                    </p-slider>
                    <button 
                        (mousedown)="zoom('in',true)" 
                        (mouseup)="zoom('in',false)" 
                        (mouseleave)="zoom('in',false)"  
                        class="btn btn-circle-icon ml15">
                        <i class="vi vi-plus"></i>
                    </button>
                </div>
            </div>
            <!-- End Camera View Setting -->
        </div>
        <!-- End Preview Frame Scene -->

        <!-- Start Frame Setting -->
        <div class="col col16-8 wrap-content-settings">
            <div class="wrap-edit">
                <!-- Start Menu Tab -->
                <div class="menu">
                    <ul class="nav nav-tabs">
                        <!-- General Tab -->
                        <li class="nav-item flex-fill">
                            <a 
                                class="nav-link" 
                                href="javascript:;" 
                                [class.active]="tab=='general'" 
                                (click)="changeTab('general')" 
                                tooltipStyleClass="canvas-editframe"
                                pTooltip="Activates when Passepartout and Frame settings are inactive." 
                                tooltipPosition="top">
                                Canvas
                            </a>
                        </li>

                        <!-- Passepartout Tab -->
                        <li class="nav-item flex-fill">
                            <a class="nav-link" href="javascript:;" [class.active]="tab=='passepartout'" (click)="changeTab('passepartout')">
                                Passepartout
                                <p-inputSwitch
                                    [disabled]="onUploding || onLoadingArtwork"
                                    [(ngModel)]="figureDataCloned.frame.passepartout.passepartout"
                                    (onChange)="loadArtwork();"
                                    [styleClass]="onUploding || onLoadingArtwork ? 'input-switch-disable custom-tab-menu' : 'custom-tab-menu'">
                                </p-inputSwitch>
                            </a>
                        </li>

                        <!-- Frame Tab -->
                        <li class="nav-item flex-fill">
                            <a class="nav-link" href="javascript:;" [class.active]="tab=='frame'" (click)="changeTab('frame')">
                                Frame
                                <p-inputSwitch
                                    [disabled]="onUploding || onLoadingArtwork"
                                    [(ngModel)]="figureDataCloned.frame.frame.frame"
                                    (onChange)="loadArtwork();"
                                    [styleClass]="onUploding || onLoadingArtwork ? 'input-switch-disable custom-tab-menu' : 'custom-tab-menu'">>
                                </p-inputSwitch>
                            </a>
                        </li>

                        <!-- Frame Templates -->
                        <li class="nav-item flex-fill">
                            <a class="nav-link" href="javascript:;" [class.active]="tab=='saved'" (click)="changeTab('saved');">Saved</a>
                        </li>
                    </ul>
                </div>
                <!-- End Menu Tab -->

                <ng-container [ngSwitch]="tab">
                    <ng-container *ngSwitchCase="'general'">
                        <p-scrollPanel [style]="{height:'402px'}">
                            <!-- Start General Setting -->
                            <div class="content-settings">
                                <!-- Back Frame Thickness Setting -->
                                <div class="form-group content-slide">
                                    <div class="title-content">
                                        <label>Thickness</label>
                                        <input-number 
                                            [value]="generalThinkness"
                                            (onChange)="updateSliderManualInputValue($event,'generalThinkess', 1, 50)"
                                            (onBlur)="editorService.onInput=$event"
                                            (onFocus)="editorService.onInput=$event"
                                            [min]="1"
                                            [max]="50">
                                        </input-number>
                                    </div>
                                    <p-slider
                                        [step]="0.01"
                                        [min]="0.01"
                                        [max]="0.5"
                                        [(ngModel)]="figureDataCloned.frame.back_frame.back_frame_depth"
                                        (onChange)="updateSidesThickness('backFrame', figureDataCloned?.frame.back_frame.back_frame_depth)"
                                        styleClass="active-slider2 ml45">
                                    </p-slider>
                                </div>

                                <!-- Back Frame Color Setting -->
                                <div class="form-group">
                                    <label class="d-block mb-2">Color</label>
                                    <input-color
                                        [style]="{'margin-left' : '43px'}"
                                        (onBlur)="editorService.onInput = false"
                                        (onHide)="mainService.userInfo.user_details[0].fav_colors=$event"
                                        (onFocus)="editorService.onInput = true"
                                        [disabled]="figureDataCloned?.frame.frame.frame||figureDataCloned?.frame.passepartout.passepartout"
                                        (onChange)="figureDataCloned?.frame.back_frame.back_frame_color=$event;changeColor('backFrame')"
                                        [favoriteColor]="mainService.userInfo.user_details[0].fav_colors"
                                        [color]="figureDataCloned?.frame.back_frame.back_frame_color">
                                    </input-color>
                                </div>
                            </div>
                            <!-- End General Setting -->
                        </p-scrollPanel>
                    </ng-container>
                    <ng-container *ngSwitchCase="'passepartout'">
                        <p-scrollPanel [style]="{height:'402px'}">
                            <!-- Start Passepartout Setting -->
                            <div class="content-settings">
                                <!-- Start Passepartout Texture Setting -->
                                <div class="form-group side-by-side" style="margin-bottom: 3px;">
                                    <p-inputSwitch
                                        [disabled]="isPasseInputDisabled()"
                                        [(ngModel)]="figureDataCloned.frame.passepartout.passepartout_material_texture"
                                        (onChange)="enableTexureColor('passepartout','texture')"
                                        [style]="{'margin-right' : '15px','top':'3px'}"
                                        [styleClass]="isPasseInputDisabled() ? 'input-switch-disable' : ''">
                                    </p-inputSwitch>
                                    <label class="mt0">Texture</label>
                                </div>
                                <input hidden type="file" #uploadPasseTexture (change)="uploadTexture($event,'passepartout')">
                                <ng-container *ngIf="figureDataCloned.frame.passepartout.passepartout_material_texture&&!figureDataCloned.frame.passepartout?.passepartout_texture">
                                    <button
                                        [disabled]="onUploding||!figureDataCloned.frame.passepartout.passepartout_material_texture||!figureDataCloned?.frame.passepartout.passepartout"
                                        class="btn-upload-image"
                                        [class.on-dragover]="onDragover"
                                        (click)="uploadPasseTexture.click()"
                                        id="dropAreaPassepartout">
                                        <p *ngIf="onUploding">Uploading...</p>
                                        <p *ngIf="!onUploding"> Press to upload or drag and drop your image here</p>
                                    </button>
                                    <div class="massage"><app-icon name="text-start" size="8" className="d-flex mr5" color="#979797"></app-icon> your image should not weight more than 2 MB</div>
                                </ng-container>
                                <div class="wrap-img-upload" *ngIf="figureDataCloned.frame.passepartout.passepartout_texture&&figureDataCloned.frame.passepartout.passepartout_material_texture">
                                    <a href="javascript:;">
                                        <img [defaultImage]="env.staticAssets+'images/other/skeleton.svg?t='+mainService.appVersion" [lazyLoad]="figureDataCloned.frame.passepartout.passepartout_texture_url" alt="" class="img-upload" />
                                        <div
                                            *ngIf="onUploding||figureDataCloned.frame.passepartout.passepartout_material_texture||!figureDataCloned?.frame.passepartout.passepartout"
                                            (click)="uploadPasseTexture.click()" class="on-edit">
                                            Edit
                                        </div>
                                    </a>
                                </div>
                                <!-- End Passepartout Texture Setting -->

                                <!-- Start Passepartout Thickness Setting -->
                                <div class="form-group content-slide">
                                    <div class="title-content">
                                        <label>Thickness</label>
                                        <input-number [value]="passepartoutThinkness"
                                            (onChange)="updateSliderManualInputValue($event,'passepartoutThinkness', 1, 50)"
                                            (onBlur)="editorService.onInput=$event"
                                            (onFocus)="editorService.onInput=$event"
                                            [disabled]="!figureDataCloned?.frame.passepartout.passepartout"
                                            [min]="1"
                                            [max]="50">
                                        </input-number>
                                    </div>
                                    <p-slider
                                        [(ngModel)]="figureDataCloned.frame.passepartout.passepartout_depth"
                                        [step]="0.01"
                                        [min]="0.01"
                                        [max]="0.5"
                                        [disabled]="isPasseInputDisabled()"
                                        [styleClass]="isPasseInputDisabled()? 'disable-slider ml45' : 'ml45'"
                                        (onChange)="updateSidesThickness('passepartout', figureDataCloned?.frame.passepartout.passepartout_depth)">
                                    </p-slider>
                                </div>
                                <!-- End Passepartout Thickness Setting -->

                                <!-- Start Passepartout Width Setting -->
                                <div class="form-group content-slide">
                                    <div class="title-content">
                                        <label>Width</label>
                                        <input-number 
                                            [value]="passepartoutWidth"
                                            (onChange)="updateSliderManualInputValue($event,'passepartoutWidth', 1, 50)"
                                            (onBlur)="editorService.onInput=$event"
                                            (onFocus)="editorService.onInput=$event"
                                            [disabled]="!figureDataCloned?.frame.passepartout.passepartout"
                                            [min]="1"
                                            [max]="50">
                                        </input-number>
                                    </div>
                                    <p-slider
                                        [(ngModel)]="passepartoutWidthAllSlider"
                                        (onChange)="updateSidesWidth('passepartout', passepartoutWidthAllSlider)"
                                        [disabled]="isPasseInputDisabled()"
                                        [styleClass]="isPasseInputDisabled()? 'disable-slider ml45' : 'ml45'"
                                        [step]="0.01"
                                        [min]="0.01"
                                        [max]="0.5">
                                    </p-slider>
                                </div>
                                <!-- End Passepartout Width Setting -->

                            <div class="divider"></div>

                            <!-- Start Passepartout Measurements -->
                            <div class="form-group side-by-side mb10">
                                <p-inputSwitch
                                    [(ngModel)]="measurementsPass"
                                    [styleClass]="isPasseInputDisabled() ? 'input-switch-disable' : ''"
                                    [disabled]="isPasseInputDisabled()"
                                    [style]="{'margin-right' : '15px','top':'2px'}">
                                </p-inputSwitch>
                                <label class="mt0">Passepartout Measurements</label>
                            </div>
                            <div class="measure mb15" *ngIf="measurementsPass">
                                <input-number
                                    [value]="passepartoutWidthLeft"
                                    (onChange)="updateSliderManualInputValue($event,'passepartoutWidthLeft', 1, 50)"
                                    (onBlur)="editorService.onInput=$event"
                                    (onFocus)="editorService.onInput=$event"
                                    [disabled]="!figureDataCloned?.frame.passepartout.passepartout"
                                    [min]="1"
                                    [max]="50">
                                </input-number>
                                <div class="measure__vertical">
                                    <input-number
                                        [value]="passepartoutWidthTop"
                                        (onChange)="updateSliderManualInputValue($event,'passepartoutWidthTop', 1, 50)"
                                        (onBlur)="editorService.onInput=$event"
                                        (onFocus)="editorService.onInput=$event"
                                        [disabled]="!figureDataCloned?.frame.passepartout.passepartout"
                                        [min]="1"
                                        [max]="50">
                                    </input-number>

                                        <div class="measure__block">
                                            <span 
                                                (mousedown)="onMouseDownMeasurements($event, 'top')"
                                                (mouseup)="onMouseUpMeasurements($event)"
                                                [class.disabled]="!figureDataCloned?.frame.passepartout.passepartout"
                                                class="measure__resize measure__resize--top">
                                                <i class="vi vi-ns-resize top"></i>
                                            </span>
                                            <span
                                                (mousedown)="onMouseDownMeasurements($event, 'right')"
                                                (mouseup)="onMouseUpMeasurements($event)"
                                                [class.disabled]="!figureDataCloned?.frame.passepartout.passepartout"
                                                class="measure__resize measure__resize--right">
                                                <i class="vi vi-ew-resize right"></i>
                                            </span>
                                            <span
                                                (mousedown)="onMouseDownMeasurements($event, 'bottom')"
                                                (mouseup)="onMouseUpMeasurements($event)"
                                                [class.disabled]="!figureDataCloned?.frame.passepartout.passepartout"
                                                class="measure__resize measure__resize--bottom">
                                                <i class="vi vi-ns-resize bottom"></i>
                                            </span>
                                            <span
                                                (mousedown)="onMouseDownMeasurements($event, 'left')"
                                                (mouseup)="onMouseUpMeasurements($event)"
                                                [class.disabled]="!figureDataCloned?.frame.passepartout.passepartout"
                                                class="measure__resize measure__resize--left">
                                                <i class="vi vi-ew-resize left"></i>
                                            </span>
                                        </div>

                                        <input-number
                                            [value]="passepartoutWidthBottom"
                                            (onChange)="updateSliderManualInputValue($event,'passepartoutWidthBottom', 1, 50)"
                                            (onBlur)="editorService.onInput=$event"
                                            (onFocus)="editorService.onInput=$event"
                                            [disabled]="!figureDataCloned?.frame.passepartout.passepartout"
                                            [min]="1"
                                            [max]="50">
                                        </input-number>
                                    </div>

                                    <input-number
                                        [value]="passepartoutWidthRight"
                                        (onChange)="updateSliderManualInputValue($event,'passepartoutWidthRight', 1, 50)"
                                        (onBlur)="editorService.onInput=$event"
                                        (onFocus)="editorService.onInput=$event"
                                        [disabled]="!figureDataCloned?.frame.passepartout.passepartout"
                                        [min]="1"
                                        [max]="50">
                                    </input-number>
                                </div>
                                <!-- End Passepartout Measurements -->

                                <div class="divider"></div>

                                <!-- Passepartout Round Corners Setting -->
                                <div class="form-group content-slide">
                                    <div class="title-content">
                                        <label>Round Corners</label>
                                        <input-number 
                                            [value]="passepartoutRadius"
                                            (onChange)="updateSliderManualInputValue($event,'passepartoutRadius', 0, maxPasseRadius('inputNumber'))"
                                            (onBlur)="editorService.onInput=$event"
                                            (onFocus)="editorService.onInput=$event"
                                            [disabled]="!figureDataCloned?.frame.passepartout.passepartout"
                                            [min]="0"
                                            [max]="maxPasseRadius('inputNumber')">
                                        </input-number>
                                    </div>
                                    <p-slider
                                        [styleClass]="isPasseInputDisabled()? 'disable-slider ml45' : 'ml45'"
                                        [(ngModel)]="figureDataCloned.frame.passepartout.passepartout_radius"
                                        (onChange)="updateSidesWidth('passepartout_radius', $event.value);"
                                        [min]="0"
                                        [step]="0.001"
                                        [max]="maxPasseRadius('slider')">
                                    </p-slider>
                                </div>

                                <!-- Start Passepartout Color Setting -->
                                <div class="form-group side-by-side mb10">
                                    <p-inputSwitch
                                        [disabled]="isPasseInputDisabled()"
                                        [(ngModel)]="figureDataCloned.frame.passepartout.passepartout_material_color"
                                        (onChange)="enableTexureColor('passepartout','color')"
                                        [style]="{'margin-right' : '15px','top':'3px'}"
                                        [styleClass]="isPasseInputDisabled() ? 'input-switch-disable' : ''">
                                    </p-inputSwitch>
                                    <label class="mt0">Color</label>
                                </div>
                                <input-color
                                    [style]="{'margin-left' : '43px'}"
                                    (onBlur)="editorService.onInput = false"
                                    (onHide)="mainService.userInfo.user_details[0].fav_colors=$event"
                                    (onFocus)="editorService.onInput = true"
                                    [disabled]="isPasseInputDisabled() || !figureDataCloned.frame.passepartout.passepartout_material_color"
                                    (onChange)="figureDataCloned.frame.passepartout.passepartout_color=$event;changeColor('passepartout')"
                                    [favoriteColor]="mainService.userInfo.user_details[0].fav_colors"
                                    [color]="figureDataCloned.frame.passepartout.passepartout_color">
                                </input-color>
                                <!-- End Passepartout Color Setting -->
                            </div>
                            <!-- End Passepartout Setting -->
                        </p-scrollPanel>
                    </ng-container>
                    <ng-container *ngSwitchCase="'frame'">
                        <p-scrollPanel [style]="{height:'402px'}">
                            <!-- Start Frame Setting -->
                            <div class="content-settings">
                                <!-- Start Frame Texture Setting -->
                                <div class="form-group side-by-side" style="margin-bottom: 3px;">
                                    <label>Texture</label>
                                    <p-inputSwitch
                                        [disabled]="onUploding||!figureDataCloned?.frame.frame.frame"
                                        [(ngModel)]="figureDataCloned.frame.frame.frame_material_texture"
                                        (onChange)="enableTexureColor('frame','texture')"
                                        [styleClass]="onUploding||!figureDataCloned?.frame.frame.frame ? 'switch-sm input-switch-disable' : 'switch-sm'">>
                                    </p-inputSwitch>
                                </div>
                                <input hidden type="file" #uploadFrameTexture (change)="uploadTexture($event,'frame')">
                                <ng-container *ngIf="figureDataCloned.frame.frame.frame_material_texture&&!figureDataCloned.frame.frame?.frame_texture">
                                    <button
                                        [disabled]="onUploding||!figureDataCloned.frame.frame.frame_material_texture||!figureDataCloned?.frame.frame.frame"
                                        class="btn-upload-image"
                                        [class.on-dragover]="onDragover"
                                        (click)="uploadFrameTexture.click()"
                                        id="dropAreaFrame">
                                        <p *ngIf="onUploding">Uploading...</p>
                                        <p *ngIf="!onUploding"> Press to upload or drag and drop your image here</p>
                                    </button>
                                    <div class="massage"> your image should not weight more than 2 MB</div>
                                </ng-container>
                                <div class="wrap-img-upload" *ngIf="figureDataCloned.frame.frame.frame_texture&&figureDataCloned.frame.frame.frame_material_texture">
                                    <a href="javascript:;">
                                        <img [defaultImage]="env.staticAssets+'images/other/skeleton.svg?t='+mainService.appVersion" [lazyLoad]="figureDataCloned.frame.frame.frame_texture_url" alt="" class="img-upload" />
                                        <div *ngIf="onUploding||figureDataCloned.frame.frame.frame_material_texture||!figureDataCloned?.frame.frame.frame"
                                            (click)="uploadFrameTexture.click()" class="on-edit">
                                            Edit
                                        </div>
                                    </a>
                                </div>
                                <!-- End Frame Texture Setting -->

                                <!-- Start Frame Thickness Setting -->
                                <div class="form-group content-slide">
                                    <div class="title-content">
                                        <label>Thickness</label>
                                        <input-number 
                                            [value]="frameThinkness"
                                            (onChange)="updateSliderManualInputValue($event,'frameThinkness', 1, 50)"
                                            (onBlur)="editorService.onInput=$event"
                                            (onFocus)="editorService.onInput=$event"
                                            [disabled]="!figureDataCloned?.frame.frame.frame"
                                            [min]="1"
                                            [max]="50">
                                        </input-number>
                                    </div>
                                    <p-slider
                                        [(ngModel)]="figureDataCloned.frame.frame.frame_depth"
                                        (onChange)="updateSidesThickness('frame', figureDataCloned?.frame.frame.frame_depth)"
                                        [disabled]="onUploding||!figureDataCloned?.frame.frame.frame"
                                        [styleClass]="onUploding||!figureDataCloned?.frame.frame.frame? 'disable-slider ml45' : 'ml45'"
                                        [step]="0.01"
                                        [min]="0.01"
                                        [max]="0.5">
                                    </p-slider>
                                </div>
                                <!-- Edn Frame Thickness Setting -->

                                <!-- Start Frame Width Setting -->
                                <div class="form-group content-slide">
                                    <div class="title-content">
                                        <label>Width</label>
                                        <input-number 
                                            [value]="frameWidth"
                                            (onChange)="updateSliderManualInputValue($event,'frameWidth', 1, 50)"
                                            (onBlur)="editorService.onInput=$event"
                                            (onFocus)="editorService.onInput=$event"
                                            [disabled]="!figureDataCloned?.frame.frame.frame"
                                            [min]="1"
                                            [max]="50">
                                        </input-number>
                                    </div>
                                    <p-slider
                                        [(ngModel)]="frameWidthAllSlider"
                                        (onChange)="updateSidesWidth('frame', frameWidthAllSlider)"
                                        [step]="0.01"
                                        [min]="0.01"
                                        [max]="0.5"
                                        [disabled]="onUploding||!figureDataCloned?.frame.frame.frame"
                                        [styleClass]="onUploding||!figureDataCloned?.frame.frame.frame? 'disable-slider ml45' : 'ml45'">
                                    </p-slider>
                                </div>
                                <!-- End Frame Width Setting -->

                                <div class="divider"></div>

                                <!-- Start Passepartout Measurements -->
                                <div class="form-group side-by-side mb10">
                                    <p-inputSwitch
                                        [(ngModel)]="measurementsFrame"
                                        [styleClass]="!figureDataCloned?.frame.frame.frame ? 'input-switch-disable' : ''"
                                        [disabled]="!figureDataCloned?.frame.frame.frame"
                                        [style]="{'margin-right' : '15px','top':'2px'}">
                                    </p-inputSwitch>
                                    <label class="mt0">Frame Measurements</label>
                                </div>
                                <div class="measure mb15" *ngIf="measurementsFrame">
                                    <input-number
                                        [value]="frameWidthLeft"
                                        (onChange)="updateSliderManualInputValue($event,'frameWidthLeft', 1, 50)"
                                        (onBlur)="editorService.onInput=$event"
                                        (onFocus)="editorService.onInput=$event"
                                        [disabled]="!figureDataCloned?.frame.frame.frame"
                                        [min]="1"
                                        [max]="50">
                                    </input-number>
                                    <div class="measure__vertical">
                                        <input-number
                                            [value]="frameWidthTop"
                                            (onChange)="updateSliderManualInputValue($event,'frameWidthTop', 1, 50)"
                                            (onBlur)="editorService.onInput=$event"
                                            (onFocus)="editorService.onInput=$event"
                                            [disabled]="!figureDataCloned?.frame.frame.frame"
                                            [min]="1"
                                            [max]="50">
                                        </input-number>

                                        <div class="measure__block">
                                            <span 
                                                (mousedown)="onMouseDownMeasurements($event, 'top')"
                                                (mouseup)="onMouseUpMeasurements($event)"
                                                [class.disabled]="!figureDataCloned?.frame.frame.frame"
                                                class="measure__resize measure__resize--top">
                                                <i class="vi vi-ns-resize top"></i>
                                            </span>
                                            <span
                                                (mousedown)="onMouseDownMeasurements($event, 'right')"
                                                (mouseup)="onMouseUpMeasurements($event)"
                                                [class.disabled]="!figureDataCloned?.frame.frame.frame"
                                                class="measure__resize measure__resize--right">
                                                <i class="vi vi-ew-resize right"></i>
                                            </span>
                                            <span
                                                (mousedown)="onMouseDownMeasurements($event, 'bottom')"
                                                (mouseup)="onMouseUpMeasurements($event)"
                                                [class.disabled]="!figureDataCloned?.frame.frame.frame"
                                                class="measure__resize measure__resize--bottom">
                                                <i class="vi vi-ns-resize bottom"></i>
                                            </span>
                                            <span
                                                (mousedown)="onMouseDownMeasurements($event, 'left')"
                                                (mouseup)="onMouseUpMeasurements($event)"
                                                [class.disabled]="!figureDataCloned?.frame.frame.frame"
                                                class="measure__resize measure__resize--left">
                                                <i class="vi vi-ew-resize left"></i>
                                            </span>
                                        </div>

                                        <input-number
                                            [value]="frameWidthBottom"
                                            (onChange)="updateSliderManualInputValue($event,'frameWidthBottom', 1, 50)"
                                            (onBlur)="editorService.onInput=$event"
                                            (onFocus)="editorService.onInput=$event"
                                            [disabled]="!figureDataCloned?.frame.frame.frame"
                                            [min]="1"
                                            [max]="50">
                                        </input-number>
                                    </div>

                                    <input-number
                                        [value]="frameWidthRight"
                                        (onChange)="updateSliderManualInputValue($event,'frameWidthRight', 1, 50)"
                                        (onBlur)="editorService.onInput=$event"
                                        (onFocus)="editorService.onInput=$event"
                                        [disabled]="!figureDataCloned?.frame.frame.frame"
                                        [min]="1"
                                        [max]="50">
                                    </input-number>
                                </div>
                                <!-- End Passepartout Measurements -->

                                <div class="divider"></div>


                                <!-- Frame Round Corners Setting -->
                                <div class="form-group content-slide">
                                    <div class="title-content">
                                        <label>Round Corners</label>
                                        <input-number 
                                            [value]="frameRadius"
                                            (onChange)="updateSliderManualInputValue($event,'frameRadius', 0, maxFrameRadius('inputNumber'))"
                                            (onBlur)="editorService.onInput=$event"
                                            (onFocus)="editorService.onInput=$event"
                                            [disabled]="!figureDataCloned?.frame.frame.frame"
                                            [min]="0"
                                            [max]="maxFrameRadius('inputNumber')">
                                        </input-number>
                                    </div>
                                    <p-slider
                                        [styleClass]="onUploding||!figureDataCloned?.frame.frame.frame? 'disable-slider ml45' : 'ml45'"
                                        [(ngModel)]="figureDataCloned.frame.frame.frame_radius"
                                        (onChange)="updateSidesWidth('frame_radius', $event.value);"
                                        [min]="0"
                                        [step]="0.001"
                                        [max]="maxFrameRadius('slider')">
                                    </p-slider>
                                </div>

                                <!-- Start Frame Color Setting -->
                                <div class="form-group side-by-side">
                                    <label>Color</label>
                                    <p-inputSwitch
                                        [(ngModel)]="figureDataCloned.frame.frame.frame_material_color"
                                        [styleClass]="onUploding||!figureDataCloned?.frame.frame.frame ? 'switch-sm input-switch-disable' : 'switch-sm'"
                                        [disabled]="onUploding||!figureDataCloned?.frame.frame.frame"
                                        (onChange)="enableTexureColor('frame','color')">
                                    </p-inputSwitch>
                                </div>
                                <input-color
                                    [style]="{'margin-left' : '43px'}"
                                    (onBlur)="editorService.onInput = false"
                                    (onHide)="mainService.userInfo.user_details[0].fav_colors=$event"
                                    (onFocus)="editorService.onInput = true"
                                    [disabled]="onUploding||!figureDataCloned?.frame.frame.frame||!figureDataCloned.frame.frame.frame_material_color"
                                    (onChange)="figureDataCloned.frame.frame.frame_color=$event;changeColor('frame')"
                                    [favoriteColor]="mainService.userInfo.user_details[0].fav_colors"
                                    [color]="figureDataCloned.frame.frame.frame_color">
                                </input-color>
                                <!-- Start Frame Color Setting -->
                            </div>

                            <div></div>
                            <!-- End Frame Setting -->
                        </p-scrollPanel>
                    </ng-container>
                    <ng-container *ngSwitchCase="'saved'">
                        <p-scrollPanel [style]="{height:'402px'}">
                            <div class="content-settings">
                                <div *ngIf="!onFetchingFrameTemplate && frameTemplates.length > 0" class="wrap-list-frame">
                                    <div 
                                        *ngFor="let item of frameTemplates" 
                                        class="item-frame" 
                                        [class.on-hover]="item.hover" 
                                        [ngClass]="{'active' : figureDataCloned.frame.sid_frame === item.sid_template}">

                                        <div class="wrap-img-frame" (mouseleave)="item.hover = false" (mouseenter)="item.hover = true">
                                            <img 
                                                [defaultImage]="env.staticAssets+'images/other/skeleton.svg?t='+mainService.appVersion" 
                                                (click)="selectFrameTemplate(item)" 
                                                [lazyLoad]="item.thumbnail" 
                                                class="img-frame" />
                                            <a
                                                *ngIf="figureDataCloned.frame.sid_frame === item.sid_template" 
                                                (click)="showEditFrameTemplateModePopup(item)" href="javascript:;" 
                                                class="action-menu left">
                                                <i class="vi vi-modify"></i>
                                            </a>
                                            <a (click)="showDeleteFramePopup(item)" href="javascript:;" class="action-menu right">
                                                <i class="vi vi-delete"></i>
                                            </a>
                                        </div>

                                        <h5
                                            [pTooltip]="item.frame_name.length > 24 ? item.frame_name : ''"
                                            tooltipPosition="bottom"
                                            class="title-frame"
                                            (click)="selectFrameTemplate(item)" 
                                            (mouseleave)="item.hover = false"
                                            (mouseenter)="item.hover = true">
                                            {{item.frame_name}}
                                        </h5>
                                    </div>

                                </div>
                                <div *ngIf="onFetchingFrameTemplate || frameTemplates.length == 0" class="wrap-loading-frame-list">
                                    <p>{{ !onFetchingFrameTemplate && frameTemplates.length == 0 ? 'No data' : 'Loading ...' }}</p>
                                </div>
                            </div>
                        </p-scrollPanel>
                    </ng-container>
                </ng-container>
            </div>

            <div class="wrap-save-frame">
                <button 
                    [disabled]="onUploding"
                    (click)="showSaveFrameTemplateModePopup()"
                    class="btn btn-light2 btn-xs">
                
                    Save This Frame
                </button>
                
                <div class="all-artwork">
                    <input type="checkbox" [checked]="editorService.applyToAllFrameArtwork">
                    <label (click)="editorService.applyToAllFrameArtwork=!editorService.applyToAllFrameArtwork">Apply to all Artworks</label>
                </div>
            </div>

            <button
                class="btn btn-primary btn-xs d-block mt20"
                [style]="{'margin-left' : 'auto'}"
                [class.loading-button-primary]="isLoading"
                [disabled]="isLoading || onUploding"
                (click)="saveChangesFrame()">Done <i class="pi pi-arrow-right"></i>
            </button>
        </div>
        <!-- End Frame Setting -->
    </div>
</div>