<app-layouts #layouts>
    <div content>
        <!-- WRAP FORM EMAIL
         ========================= -->
        <div class="wrap-forgot-password col16-3 col-iped-4 offset16-6 offset-iped-2 offset-mobile-0" *ngIf="!showSentEmail">
            <h2 class="title-page">Update your password</h2>
            <div class="content-forgot-password">
                <p>Enter your email and we’ll send you a link to reset your password</p>
                <form class="form-group" [formGroup]="form">
                    <!-- INPUT EMAIL -->
                    <label for="">Email address</label>
                    <input 
                        type="email" 
                        class="form-control bg-gray" 
                        formControlName="email_forgot"
                        [ngClass]="{'is-invalid':form.controls.email_forgot.invalid&&form.controls.email_forgot.touched}">

                    <!-- INVALID FEEDBACK -->
                    <div class="invalid-feedback" *ngIf="form.controls.email_forgot.errors">
                        <ng-container *ngIf="form.controls.email_forgot.errors.required">Email is required</ng-container>
                        <ng-container *ngIf="form.controls.email_forgot.errors.email">Email is not valid</ng-container>
                    </div>

                    <div class="g-recaptcha mt-4" id="captchaForgot"></div>

                    <div class="wrap-button">
                        <button
                            type="button"
                            class="btn btn-light btn-sm" 
                            (click)="back()"
                            [disabled]="loading">
                            <i class="vi vi-arrow-left mr5"></i>
                            Back
                        </button>
                        <button
                            type="submit"
                            class="btn btn-primary btn-sm" 
                            [disabled]="form.invalid || loading || !captchaVerifed" 
                            [class.loading-button-primary]="loading"
                            (click)="forgotPassword()">
                            Request a Reset Link
                        </button>
                    </div>
                </form>
            </div>
        </div>

        <!-- EMAIL SENT
        ========================-->
        <div class="sent-email col16-4 col-iped-4 offset-iped-2 col-mobile-4 offset16-6 offset-mobile-0" *ngIf="showSentEmail">
            <h2 class="title-pages"><i class="vi vi-check-circle"></i>Email sent</h2>
            <p>Check your email and open the link we sent to continue</p>
        </div>
    </div>
</app-layouts>