import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { audioFigureSupport, videoFigureSupport } from '../images.interfaces';
import { Observable } from 'rxjs';
import { environment } from '@environments';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MediaSupportService {
  private _httpOptions: any;

  constructor(
    private _http: HttpClient,
  ) { 
    this._setHttpOptions();
  }

  /**
	 * * SET HTTP OPTIONS *
	 * Todo: to set http options
	 */
	private _setHttpOptions(): void {
		this._httpOptions = {
			reportProgress: true,
			observe: 'events',
		}
	}

  /**
	 * * CONVERT OBJECT TO FORM DATA *
	 * ANCHOR : to convert object to form data
	 * @description : to convert object to form data
	 * @param object : any -> object
	 * @returns : FormData -> form data
	 */
	private _convertObjectToFormData(object: any): FormData {
		const formData = new FormData();
		for (const key in object) {
			if (object.hasOwnProperty(key)) {
				const element = object[key];
				if (typeof element === "object" && !(element instanceof File)) {
					formData.append(key, JSON.stringify(element));
				}
				else {
					formData.append(key, element);
				}
			}
		}
		return formData;
	}
  
  /**
   * * UPLOAD VIDEO SUPPORT FOR FIGURE *
   * ANCHOR Upload Video Support For Figure
   * @param payload: videoFigureSupport
   * @returns : string
   */
	public uploadVideoFile(payload: videoFigureSupport): Observable<any> {
		const formData = this._convertObjectToFormData(payload);
		const apiEndpoint = `${environment.baseURL}/video/support-upload`;
		return this._http.post(apiEndpoint, formData, this._httpOptions).pipe(map((event: any) => {
			switch (event.type) {
				case HttpEventType.UploadProgress:
					const progress = Math.round(100 * event.loaded / event.total);
					return {status: 'progress', data: progress};

				case HttpEventType.Response:
					return {status: 'response', data: event.body};

				default:
					return `Unhandled event: ${event.type}`;
			}
		}));
	}

  /**
   * * DELETE AUDIO FILE *
   * ANCHOR: to delete audio file
   * @param artworkId : string
   * @returns : Observable<any>
   */
  public deleteVideoFile(artworkId: string): Observable<any> {
    return this._http.delete(`${environment.baseURL}/video/support-delete/${artworkId}`);
  }

   /**
   * * UPLOAD AUDIO FILE *
   * ANCHOR: to upload audio file
   * @param file : File
   * @returns : Observable<any>
   */
   public uploadAudioFile(payload: audioFigureSupport): Observable<any> {
    const formData = this._convertObjectToFormData(payload);
    const apiEndpoint = `${environment.baseURL}/audio/upload`;
    
    return this._http.post(apiEndpoint, formData, this._httpOptions).pipe(map((event: any) => {
			switch (event.type) {
				case HttpEventType.UploadProgress:
					const progress = Math.round(100 * event.loaded / event.total);
					return {status: 'progress', data: progress};

				case HttpEventType.Response:
					return {status: 'response', data: event.body};

				default:
					return `Unhandled event: ${event.type}`;
			}
		}));
  }

  /**
   * * DELETE AUDIO FILE *
   * ANCHOR: to delete audio file
   * @param artworkId : string
   * @returns : Observable<any>
   */
  public deleteAudioFile(artworkId: string): Observable<any> {
    return this._http.delete(`${environment.baseURL}/audio/delete/${artworkId}`);
  }
}
