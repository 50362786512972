
<div class="wrap-help" [class.on-help]="showHelp">
    <div class="content-help">
        <div class="row">
            <div class="col">
                <div style="position:relative; overflow:hidden; padding-bottom:62.5%">
                    <iframe src="https://cdn.jwplayer.com/players/PrSP2x4E-GiW59uqF.html" width="100%" height="100%" frameborder="0" scrolling="auto" title="Villume Tutorial" style="position:absolute;" allowfullscreen></iframe>
                </div>
            </div>
            <!-- <div class="col-lg-6">
                <img src="assets/img/image-help/img-2.png?t={{mainService.appVersion}}" width="100%">
            </div>
            <div class="col-lg-6">
                <img src="assets/img/image-help/img-3.png?t={{mainService.appVersion}}" width="100%">
            </div>
            <div class="col-lg-6">
                <img src="assets/img/image-help/img-4.png?t={{mainService.appVersion}}" width="100%">
            </div>
            <div class="col-lg-6">
                <img src="assets/img/image-help/img-5.png?t={{mainService.appVersion}}" width="100%">
            </div>
            <div class="col-lg-6">
                <img src="assets/img/image-help/img-6.png?t={{mainService.appVersion}}" width="100%">
            </div>
            <div class="col-lg-6">
                <img src="assets/img/image-help/img-7.png?t={{mainService.appVersion}}" width="100%">
            </div>
            <div class="col-lg-6">
                <img src="assets/img/image-help/img-8.png?t={{mainService.appVersion}}" width="100%">
            </div> -->
        </div>
    </div>
</div>

<div class="block-content"></div>