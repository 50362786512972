<div class="d-flex justify-content-between mb15">
  <div class="form-group side-by-side mb0">
    <label>Font Size</label>
    <input-number
      [value]="fontSize"
      [height]="35"
      (onChange)="updateInputValue($event,'fontSize')"
      [button]="true"
      [min]="1"
      [max]="9999">
    </input-number>
  </div>
  <div class="form-group side-by-side mb0">
    <label>Line Height</label>
    <input-number
      [value]="lineHeight"
      [height]="35"
      (onChange)="updateInputValue($event,'lineHeight')"
      [button]="true"
      [min]="1"
      [max]="9999">
    </input-number>
  </div>
</div>
